import { SelectionChangedEvent } from "ag-grid-community";
import { CompanyFspEntity } from "domain/entity/Company/CompanyFspEntity";
import _ from "lodash";
import { AclType } from "presentation/constant/ANAInfo/NbisivPermission";
import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { CompanyConstant } from "presentation/constant/Company/CompanyConstant";
import { INITIAL_COMPANY_FSP_COL_DEF, transferRowData } from "presentation/constant/Company/CompanyFspColumnDefinition";
import { useCompanyFspVM } from "presentation/hook/Company/useCompanyFspVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useCompanyDetailTracked } from "presentation/store/Company/CompanyDetailProvider";
import { isPermissionExist } from "presentation/utils/permissionUtils";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import RightClickMenuWithMoveAfter from "presentation/view/components/NbisRightClickMenu/RightClickMenuWithMoveAfter";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { createRef, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HPHButton, Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const CompanyFspTablePanel: React.FC = () => {
    const [companyDetailState] = useCompanyDetailTracked();
    const [anainfoState] = useANAInfoTracked();
    const companyFspVM = useCompanyFspVM();
    const messageBarVM = useMessageBarVM();

    const rightClickRef: any = createRef();
    const { allowUpdate } = anainfoState;
    const [isLoading, setIsLoading] = useState(false);
    const { companyId } = companyDetailState;
    const COMPANY_DTL_CONST = CompanyConstant.Detail;
    // const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    const { isAdd, isEditable, isEditCompDtlInfo, isShowFsp, isShowEmptyPool, isShowSuppleTerms, isShowCustAssign, isShowCustInfo, isShowFactor } = companyDetailState.viewState;
    const { selectedDatas } = companyDetailState.companyFspState

    // for right click menu with move after begin
    const [showMoveCursor, setShowMoveCursor] = useState<boolean>(false);
    const [allRows, setAllRows] = useState<CompanyFspEntity[]>([]);
    const [initialAllRows, setInitialAllRows] = useState<boolean>(true);

    const isReadReject = isPermissionExist(Permission.COMPANY_FSP_REJECT, AclType.READ, anainfoState.defaultOperatingCompany, anainfoState.allNbisivPermission);
    const isUpdateReject = isPermissionExist(Permission.COMPANY_FSP_REJECT, AclType.UPDATE, anainfoState.defaultOperatingCompany, anainfoState.allNbisivPermission);
    const isReadApprove = isPermissionExist(Permission.COMPANY_FSP_APPROVE, AclType.READ, anainfoState.defaultOperatingCompany, anainfoState.allNbisivPermission);
    const isUpdateApprove = isPermissionExist(Permission.COMPANY_FSP_APPROVE, AclType.UPDATE, anainfoState.defaultOperatingCompany, anainfoState.allNbisivPermission);

    let gridRef: any = useRef();

    // const getShowMoveCursor = useCallback(() => {
    //     return showMoveCursor;
    // }, [showMoveCursor]);

    // useEffect(() => {
    //     if (!onTableSelectionClicked) return;

    //     gridRef.current?.gridRef.current.api?.deselectAll();
    //     changeCursor("company-fsp-table", getShowMoveCursor());
    // }, [getShowMoveCursor, onTableSelectionClicked])

    useEffect(() => {
        if (isEditCompDtlInfo) {
            const handleRightClickMenu = (event: any) => {
                if (_.isEmpty(selectedDatas) || !allowUpdate) {
                    return;
                }
                event.preventDefault();
                rightClickRef.current.show(event);
            };
            document.getElementById('companyFspTable')?.addEventListener("contextmenu", handleRightClickMenu);

            return () => {
                document.getElementById('companyFspTable')?.removeEventListener("contextmenu", handleRightClickMenu);
            };
        }
    }, [rightClickRef, allowUpdate, selectedDatas, isEditCompDtlInfo]);

    // useEffect(() => {
    //     //const columnDefs = (INITIAL_COMPANY_FSP_COL_DEF.slice());

    //     //gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
    //     if (!onTableSelectionClicked) {
    //         gridRef.current?.gridRef.current.api?.deselectAll();
    //     }
    // })

    const memoTableBtns = useMemo(() => {
        const tableBtns = [];

        tableBtns.push({
            id: 'onRefreshButton',
            icon: 'Icon-reload',
            title: 'Refresh'
        })

        if (isEditCompDtlInfo) {
            if (!(isAdd || isEditable)) {
                tableBtns.push({
                    id: 'onDeleteButton',
                    icon: 'Icon-trash',
                    title: 'Delete'
                })

                tableBtns.push({
                    id: 'onAddButton',
                    icon: 'Icon-add',
                    title: 'Add'
                })
            }
        }

        return tableBtns
    }, [isAdd, isEditCompDtlInfo, isEditable])

    const handleRefresh = useCallback(() => {
        setIsLoading(true);
        companyDetailState.companyId && companyFspVM.initialFspTableData(companyDetailState.companyId).then(() => {
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false)
        })
    }, [companyDetailState.companyId, companyFspVM]);

    const handleAdd = useCallback(async () => {
        companyFspVM.onAdd();
    }, [companyFspVM])

    const handleEdit = useCallback((row: CompanyFspEntity) => {
        /*if (isEditCompDtlInfo && (isAdd || isEditable)) {
            return;
        }*/

        companyFspVM.onEdit(row);
    }, [companyFspVM/*, isAdd, isEditCompDtlInfo, isEditable*/]);

    const handleDelete = useCallback(() => {
        if (selectedDatas.length < 1) {
            messageBarVM.showError("Please select a record(s) to delete.");
            return;
        }

        const isAllowDelete = !(selectedDatas.find(selectedData => (selectedData.status === "FINALIZED" || selectedData.status === "PROPOSED")));
        if (!isAllowDelete) {
            messageBarVM.showError("Record status is PROPOSED/FINALIZED, cannot delete.");
            return;
        }

        companyFspVM.handleDelete();
    }, [companyFspVM, messageBarVM, selectedDatas]);

    const handleSubmit = useCallback(() => {
        if (selectedDatas.length < 1) {
            messageBarVM.showError("Please select a record(s) to submit.");
            return;
        }

        setIsLoading(true);
        companyFspVM.onSubmit(selectedDatas).then(() => {
            companyDetailState.companyId && companyFspVM.initialFspTableData(companyDetailState.companyId).then(() => {
                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false)
            })
        }).catch((error) => {
            setIsLoading(false)
            messageBarVM.showError(error.message)
        })

    }, [companyDetailState.companyId, companyFspVM, messageBarVM, selectedDatas]);

    const handleReject = useCallback(() => {
        if (selectedDatas.length < 1) {
            messageBarVM.showError("Please select a record(s) to reject.");
            return;
        }

        companyFspVM.handleReject();
    }, [companyFspVM, messageBarVM, selectedDatas]);

    const handleApprove = useCallback(() => {
        if (selectedDatas.length < 1) {
            messageBarVM.showError("Please select a record(s) to approve.");
            return;
        }

        setIsLoading(true);
        companyFspVM.onApprove(selectedDatas).then(() => {
            companyDetailState.companyId && companyFspVM.initialFspTableData(companyDetailState.companyId).then(() => {
                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false)
            })
        }).catch((error) => {
            setIsLoading(false)
            messageBarVM.showError(error.message)
        })

    }, [companyDetailState.companyId, companyFspVM, messageBarVM, selectedDatas]);

    // let performedClicks = 0;
    const handleSelectionChange = useCallback((e: SelectionChangedEvent, delay: number = 250) => {
        // performedClicks++;
        // let clickTimeout = setTimeout(() => {
        //     if (performedClicks === 1) {
        //         // eslint-disable-next-line react-hooks/exhaustive-deps
        //         performedClicks = 0;
        //         // setOnTableSelectionClicked(true);

        //         const selectedRows = e.api.getSelectedRows();
        //         if (selectedRows && selectedRows.length > 0) {
        //             companyFspVM.onSelectedCompFsps(selectedRows);
        //         }
        //     } else {
        //         performedClicks = 0;
        //     }
        // }, delay);

        // if (performedClicks > 1) {
        //     clearTimeout(clickTimeout);
        // }
        const selectedRows = e.api.getSelectedRows();
        companyFspVM.onSelectedCompFsps(selectedRows);
    }, [companyFspVM])

    const isDisableSubmit = useCallback(() => {
        if (selectedDatas.length < 1
            || selectedDatas.find(selectedData => (selectedData.status === "FINALIZED"))) {
            return true;
        }

        return false;
    }, [selectedDatas]);

    const isDisableReject = useCallback(() => {
        if (selectedDatas.length < 1
            || selectedDatas.find(selectedData => (
                !((selectedData.status === "FINALIZED" || selectedData.status === "PROPOSED") && selectedData.confirmedDate === null))
            )) {
            return true;
        }

        return false;
    }, [selectedDatas]);

    const isDisableApprove = useCallback(() => {
        if (selectedDatas.length < 1
            || selectedDatas.find(selectedData => (
                !((selectedData.status === "FINALIZED" || selectedData.status === "PROPOSED") && selectedData.confirmedDate === null))
            )) {
            return true;
        }

        return false;
    }, [selectedDatas]);

    const getAllRows = useCallback(() => {
        return allRows;
    }, [allRows]);

    const getSelectedRows = useCallback(() => {
        return selectedDatas;
    }, [selectedDatas]);

    // for right click menu with move after begin
    const onRefreshRow = useCallback((newAllRows: any[]) => {
        setAllRows(newAllRows);
        companyFspVM.onSelectedCompFsps([]);
        setShowMoveCursor(true);
    }, [companyFspVM]);

    const onMoveCancel = useCallback(() => {
        setInitialAllRows(true);
        setAllRows(companyDetailState.companyFsps);
        companyFspVM.onSelectedCompFsps([]);
        setShowMoveCursor(false);
    }, [companyDetailState.companyFsps, companyFspVM]);

    const onSaveMove = useCallback(async (newAllRows: any[]) => {
        if (_.isEmpty(newAllRows)) return;

        setIsLoading(true);

        companyFspVM.onApply(newAllRows).then((res) => {
            if (!res || !res.success) {
                messageBarVM.showError('Move failed.');
                setInitialAllRows(true);
                setIsLoading(false);

                companyFspVM.onSelectedCompFsps([]);
                setShowMoveCursor(false);
            } else {
                companyFspVM.initialFspTableData(companyId).then((data) => {
                    setInitialAllRows(true);
                    setIsLoading(false);

                    companyFspVM.onSelectedCompFsps([]);
                    setShowMoveCursor(false);
                }).catch((e) => {
                    setInitialAllRows(true);
                    setIsLoading(false);

                    companyFspVM.onSelectedCompFsps([]);
                    setShowMoveCursor(false);
                })
            }
        })
    }, [companyFspVM, companyId, messageBarVM]);

    const isRowSelectable = useCallback((params: any) => {
        if (selectedDatas.length && isShowFsp) {
            const findRow = selectedDatas.find((row: any) => row.id === params.data.id);
            if (findRow) {
                return true;
            } else {
                return !(isShowFsp || isShowEmptyPool || isShowSuppleTerms || isShowCustAssign || isShowCustInfo || isShowFactor);
            }
        } else {
            return !(isShowFsp || isShowEmptyPool || isShowSuppleTerms || isShowCustAssign || isShowCustInfo || isShowFactor);
        }
    }, [isShowCustAssign, isShowCustInfo, isShowEmptyPool, isShowFactor, isShowFsp, isShowSuppleTerms, selectedDatas]);

    const memoTableTitle = useMemo(() =>
        <Sidebarheader style={{ width: "100%", display: "flex", alignItems: "center" }}>
            <HeaderTitle>{COMPANY_DTL_CONST.FSP.COMPANY_FSP}</HeaderTitle>
            <StyledAction>
                {
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <HPHButton label={'Submit'} size={'Small'} disabled={isDisableSubmit() || !isEditCompDtlInfo} theme={'Secondary'} onClick={handleSubmit} />
                        {(isReadReject && isUpdateReject) && <HPHButton label={'Reject'} size={'Small'} disabled={isDisableReject()} theme={'Secondary'} onClick={handleReject} />}
                        {(isReadApprove && isUpdateApprove) && <HPHButton label={'Approve'} size={'Small'} disabled={isDisableApprove()} theme={'Primary'} onClick={handleApprove} />}
                    </div>
                }
            </StyledAction>
        </Sidebarheader>
        , [COMPANY_DTL_CONST.FSP.COMPANY_FSP, handleApprove, handleReject, handleSubmit, isDisableApprove, isDisableReject, isDisableSubmit, isEditCompDtlInfo, isReadApprove, isReadReject, isUpdateApprove, isUpdateReject])

    const memoCompanyFspTable = useMemo(() => {
        return (
            <>
                {(allowUpdate) && <RightClickMenuWithMoveAfter disabled={_.isEmpty(getSelectedRows())}
                    rightClickRef={rightClickRef} selectedRows={getSelectedRows()} allRows={getAllRows()}
                    onSaveMove={onSaveMove} onCancel={onMoveCancel} onRefreshRow={onRefreshRow}
                    showConfirmMove={showMoveCursor} priorityName="priority" />}
                <div id="companyFspTable">
                    {<NbisTable
                        id='company-fsp-table'
                        isNewColumnSetting={true}
                        columns={INITIAL_COMPANY_FSP_COL_DEF.slice()}
                        data={transferRowData(allRows) ?? []}
                        headerActionButtons={memoTableBtns}
                        showPaginator={false}
                        editable={false}
                        showHeaderIcons={true}
                        showAddIcon={false}
                        showDeleteButton={false}
                        showReloadIcon={false}
                        isScrollHighlighted={true}
                        isRowHighligted={true}
                        selectionMode={true}
                        onSelectionChanged={(e: any) => handleSelectionChange(e, 400)}
                        onRowDoubleClick={(e: any, row: CompanyFspEntity) => handleEdit(row)}
                        onAddButton={handleAdd}
                        onRefreshButton={handleRefresh}
                        onDeleteButton={handleDelete}
                        gridHeight="customHeight"
                        customHeight="calc(100vh - 700px)"
                        ref={gridRef}
                        headerLabel={memoTableTitle}
                        rowSelection={showMoveCursor ? "single" : "multiple"}
                        showCutCursor={showMoveCursor}
                        isRowSelectable={isRowSelectable}
                    />}
                </div>
            </>
        );
    }, [allRows, allowUpdate, getAllRows, getSelectedRows, handleAdd, handleDelete, handleEdit, handleRefresh, handleSelectionChange,
        isRowSelectable, memoTableBtns, memoTableTitle, onMoveCancel, onRefreshRow, onSaveMove, rightClickRef, showMoveCursor])

    useEffect(() => {
        if (initialAllRows && companyDetailState.companyFsps && !_.isEmpty(companyDetailState.companyFsps)) {
            setAllRows(companyDetailState.companyFsps?.map((companyFspEntity, index) => ({
                ...companyFspEntity,
                index: index ?? 0,
                flag_showCutCursor: false
            })));
            setInitialAllRows(false);
        }
    }, [allRows, companyDetailState.companyFsps, initialAllRows]);

    useEffect(() => {
        if (JSON.stringify(companyDetailState.companyFsps?.map((companyFspEntity, index) => ({
            ...companyFspEntity,
            index: index ?? 0,
            flag_showCutCursor: false
        }))) !== JSON.stringify(allRows)) {
            setAllRows(companyDetailState.companyFsps?.map((companyFspEntity, index) => ({
                ...companyFspEntity,
                index: index ?? 0,
                flag_showCutCursor: false
            })));
        }
    }, [allRows, companyDetailState.companyFsps]);

    useEffect(() => {
        if (gridRef.current && gridRef.current.gridRef && gridRef.current.gridRef.current && gridRef.current.gridRef.current.api) {
            if (!isShowFsp) {
                gridRef.current?.gridRef.current.api?.deselectAll();
            }
            gridRef.current.gridRef.current.api.redrawRows();
        }
    }, [isShowCustAssign, isShowCustInfo, isShowEmptyPool, isShowFactor, isShowFsp, isShowSuppleTerms])

    return <><TableWrapper>
        {isLoading && <Loader Indicator="Spinner" size="Medium" />}
        {memoCompanyFspTable}
    </TableWrapper>
    </>;
}

export default memo(CompanyFspTablePanel);
