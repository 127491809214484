import { SelectionChangedEvent } from "ag-grid-community";
import { TariffMappingEntity } from "domain/entity/TariffMapping/TariffMappingEntity";
import _ from "lodash";
import { INITIAL_TARIFF_MAPPING_COL_DEF } from "presentation/constant/TariffMapping/TariffMappingColumnDefinition";
import { TariffMappingConstant } from "presentation/constant/TariffMapping/TariffMappingConstant";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useTariffMappingVM } from "presentation/hook/TariffMapping/useTariffMappingVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useTariffMappingTracked } from "presentation/store/TariffMapping/TariffMappingProvider";
import FileUpload from "presentation/view/components/FileUpload";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import { NbisIconButton } from "presentation/view/components/NbisIconButton";
import RightClickMenuWithMoveAfter from "presentation/view/components/NbisRightClickMenu/RightClickMenuWithMoveAfter";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { createRef, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
const TariffMappingTablePanel = () => {
    const gridRef: any = useRef(null);
    const [tariffMappingState] = useTariffMappingTracked();
    const tariffMappingVM = useTariffMappingVM();
    const messageBarVM = useMessageBarVM();
    const TARIFF_MAPPING_CONSTANT = TariffMappingConstant.Title;
    const [anainfoState] = useANAInfoTracked();
    const { allowUpdate, allowCreate } = anainfoState;
    const rightClickRef: any = createRef();
    // for right click menu with move after begin
    const [showMoveCursor, setShowMoveCursor] = useState<boolean>(false);
    const [allRows, setAllRows] = useState<TariffMappingEntity[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [initialAllRows, setInitialAllRows] = useState<boolean>(true);

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();
        tariffMappingVM.updateSelectedRows(selectedRows);
    }, [tariffMappingVM])

    const handleRowDoubleClick = useCallback((entity: TariffMappingEntity) => {
        tariffMappingVM.onRowDoubleClick(entity);
    }, [tariffMappingVM])

    const getAllRows = useCallback(() => {
        return allRows;
    }, [allRows]);

    const getSelectedRows = useCallback(() => {
        return tariffMappingState.selectedRows;
    }, [tariffMappingState.selectedRows]);

    useEffect(() => {
        const handleRightClickMenu = (event: any) => {
            if (_.isEmpty(tariffMappingState.selectedRows) || !allowUpdate) {
                return;
            }
            event.preventDefault();
            rightClickRef.current.show(event);
        };
        document.getElementById('myDiv')?.addEventListener("contextmenu", handleRightClickMenu);

        return () => {
            document.getElementById('myDiv')?.removeEventListener("contextmenu", handleRightClickMenu);
        };
    }, [messageBarVM, rightClickRef, allowUpdate, tariffMappingState.selectedRows]);

    // for right click menu with move after begin
    const onRefreshRow = useCallback((newAllRows: any[]) => {
        setAllRows(newAllRows);
        tariffMappingVM.updateSelectedRows([]);
        setShowMoveCursor(true);
    }, [tariffMappingVM]);

    const onMoveCancel = useCallback(() => {
        setInitialAllRows(true);
        setAllRows(tariffMappingState.tariffMappingList);
        tariffMappingVM.updateSelectedRows([]);
        setShowMoveCursor(false);
    }, [tariffMappingState.tariffMappingList, tariffMappingVM]);

    const onSaveMove = useCallback(async (newAllRows: any[]) => {
        if (_.isEmpty(newAllRows)) return;

        setIsLoading(true);

        tariffMappingVM.onApply(newAllRows).then((res) => {
            if (!res || !res.success) {
                messageBarVM.showError('Move failed.');
                setInitialAllRows(true);
                setIsLoading(false);

                tariffMappingVM.updateSelectedRows([]);
                setShowMoveCursor(false);
            } else {
                tariffMappingVM.searchAllTariffMappingList().then((data) => {
                    setInitialAllRows(true);
                    setIsLoading(false);

                    tariffMappingVM.updateSelectedRows([]);
                    setShowMoveCursor(false);
                }).catch((e) => {
                    setInitialAllRows(true);
                    setIsLoading(false);

                    tariffMappingVM.updateSelectedRows([]);
                    setShowMoveCursor(false);
                })
            }
        })
    }, [messageBarVM, tariffMappingVM]);


    const handleAdd = useCallback(() => {
        tariffMappingVM.onAddClick();
    }, [tariffMappingVM]);

    const handleUpload = useCallback(async (e: any) => {

        const files = e.target?.files;
        const file = files[0];

        if (!file) {
            return;
        }
        if (file.type !== 'text/csv') {
            messageBarVM.showError('Please upload a .csv file');
            return
        }

        setIsLoading(true);
        await tariffMappingVM.onUpload(file).then(async res => {

            // file.current.value = null;
            e.target = null;

            if (res.success) {
                await tariffMappingVM.searchAllTariffMappingList().then(data => {
                    setInitialAllRows(true);
                    setIsLoading(false);
                    messageBarVM.showSuccess("Success!");
                });
            } else {
                setIsLoading(false);
                messageBarVM.showError(res.data);
            }
        });
    }, [messageBarVM, tariffMappingVM]);

    const memoTariffMappingTable = useMemo(() => {

        return (
            <>
                {(allowUpdate) && <RightClickMenuWithMoveAfter disabled={_.isEmpty(getSelectedRows())}
                    rightClickRef={rightClickRef} selectedRows={getSelectedRows()} allRows={getAllRows()}
                    onSaveMove={onSaveMove} onCancel={onMoveCancel} onRefreshRow={onRefreshRow}
                    showConfirmMove={showMoveCursor} priorityName="priority" />}
                <div id="myDiv">
                    <NbisTable
                        id='tariff-mapping-table'
                        isNewColumnSetting={true}
                        columns={INITIAL_TARIFF_MAPPING_COL_DEF}
                        data={allRows ?? []}
                        showPaginator={false}
                        editable={false}
                        showAddIcon={false}
                        showDeleteButton={false}
                        showReloadIcon={false}
                        isScrollHighlighted={true}
                        selectionMode={true}
                        showCutCursor={showMoveCursor}
                        isRowHighligted={true}
                        onSelectionChanged={handleSelectionChange}
                        onRowDoubleClick={(e: any, entity: TariffMappingEntity) => handleRowDoubleClick(entity)}
                        gridHeight="customHeight"
                        customHeight="calc(100vh - 125px)"
                        ref={gridRef}
                        rowSelection={showMoveCursor ? "single" : "multiple"}
                    />
                </div>
            </>
        );
    }, [allRows, allowUpdate, getAllRows, getSelectedRows, handleRowDoubleClick, handleSelectionChange, onMoveCancel, onRefreshRow, onSaveMove, rightClickRef, showMoveCursor])

    useEffect(() => {
        if (initialAllRows && tariffMappingState.tariffMappingList && !_.isEmpty(tariffMappingState.tariffMappingList)) {
            setIsLoading(true);
            setAllRows(tariffMappingState.tariffMappingList.map((tariffMappingEntity, index) => ({
                ...tariffMappingEntity,
                index: index || 0,
                flag_showCutCursor: false
            })));
            setInitialAllRows(false);
        }

        setIsLoading(false)
    }, [allRows, initialAllRows, tariffMappingState.tariffMappingList]);

    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;
    return <>
        <Sidebarheader style={{ width: '100%', display: "flex", alignItems: "center" }}>
            <HeaderTitle>{TARIFF_MAPPING_CONSTANT.TITLE}</HeaderTitle>
            {/*(isLoading) && <Loader Indicator="Spinner" size="Medium" />*/}
            <StyledAction className="tm-animated-wrapper">
                {(allowCreate) && <><NbisIconButton fileName='Icon-add' disabled={false} size='medium' toolTipText={'Add'} toolTipArrow={false} onClick={handleAdd} />
                    <div className="add-seperator" /></>}
                <FileUpload handleUpload={handleUpload} label={WorkspaceConstant.Common.BUTTON_UPLOAD} accept=".csv" size={"Small"} theme={"Primary"} disabled={false} />
            </StyledAction>
        </Sidebarheader>
        <TableWrapper>
            {(isLoading) && <Loader Indicator="Spinner" size="Medium" />}
            {memoTariffMappingTable}
            {tariffMappingState.forceRefresh && <></>}
        </TableWrapper>
    </>;
}

export default memo(TariffMappingTablePanel);
