
import { manHdrStateCheckboxOption } from "presentation/constant/CheckBox/StaticCheckboxOptions";
import { ManualChargeConstant } from "presentation/constant/ManualCharge/ManualChargeConstant";
import { useManualChargeHeaderMaintenanceVM } from "presentation/hook/ManualCharge/useManualChargeHeaderMaintenanceVM";
import { useManualChargeHeaderMaintenanceTracked } from "presentation/store/ManualCharge/ManualChargeHeaderMaintenanceProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DateRangePickerComponent from "presentation/view/components/DateRangePickerComponent";
import NbisInputDropDown from "presentation/view/components/NbisInputDropDown";
import { memo, useMemo } from "react";
import { GroupCheckboxList, HPHGroupCheckbox, HPHInputMask, InputField } from "veronica-ui-component/dist/component/core";

const ManualChargeHeaderSearchForm = () => {
    const [manualChargeHeaderState] = useManualChargeHeaderMaintenanceTracked();
    const manualChargeHeaderVM = useManualChargeHeaderMaintenanceVM();
    const MAN_CHG_HDR_CONST = ManualChargeConstant.Header;
    const headerSearchCriteria = manualChargeHeaderState.searchCriteria;
    //const enabledCriteria = useMemo(() => ManChargeHdrCriteriaDirectoryCheckboxOption.find(item => manualChargeHeaderState.enabledSearchCriteria[item.key]), [manualChargeHeaderState.enabledSearchCriteria]);

    const memoCoVslVoy = useMemo(() =>
        <div className='im-flex-row-item'>
            <HPHInputMask
                label={MAN_CHG_HDR_CONST.CO_VSL_VOY}
                width={'330px'}
                value={{
                    co: headerSearchCriteria.co ?? '',
                    vsl: headerSearchCriteria.vsl ?? '',
                    voy: headerSearchCriteria.voy ?? ''
                }}
                fieldConfiguration={{
                    co: { maxLength: 60, autoComplete: "off", valueInNormalCase: true, isNumeric: false },
                    vsl: { maxLength: 10, autoComplete: "off", valueInNormalCase: true, isNumeric: false },
                    voy: { maxLength: 10, autoComplete: "off", valueInNormalCase: true, isNumeric: false },
                }}
                onDataChange={(e: any) => manualChargeHeaderVM.onCoVslVoyChange(e, {
                    co: 'co',
                    vsl: 'vsl',
                    voy: 'voy'
                })} />
        </div>
        , [MAN_CHG_HDR_CONST.CO_VSL_VOY, headerSearchCriteria.co, headerSearchCriteria.vsl, headerSearchCriteria.voy, manualChargeHeaderVM])

    const memoChgType = useMemo(() =>
        <div className='im-flex-row-item'>
            <NbisInputDropDown
                label={MAN_CHG_HDR_CONST.CHARGE_TYPE}
                width='330px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={headerSearchCriteria.chargeTypeList?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => {
                    manualChargeHeaderVM.onMultipleDropdownChange(e, 'chargeTypeList')
                }}
                options={manualChargeHeaderState.dynamicOptions.chargeTypeDropdownOptions} />
        </div>
        , [MAN_CHG_HDR_CONST.CHARGE_TYPE, headerSearchCriteria.chargeTypeList, manualChargeHeaderState.dynamicOptions.chargeTypeDropdownOptions, manualChargeHeaderVM])

    // const memoState = useMemo(() =>
    //     <div className='im-flex-row-item'>
    //         <NbisInputDropDown
    //             label={MAN_CHG_HDR_CONST.STATE}
    //             inputType="freeText"
    //             width="150px"
    //             mode={'multiple'}
    //             sort={false}
    //             value={headerSearchCriteria.stateList?.map((item) => ({
    //                 value: item
    //             }))}
    //             onChange={(e:any) => manualChargeHeaderVM.onMultipleDropdownChange(e, 'stateList')}
    //             options={hdrStateDropdownOption}/>
    //     </div>
    // , [MAN_CHG_HDR_CONST.STATE, headerSearchCriteria.stateList, manualChargeHeaderVM])

    const memoState = useMemo(() =>
        <div className='im-flex-row-item'>
            <HPHGroupCheckbox
                label={MAN_CHG_HDR_CONST.STATE}
                checkboxData={manHdrStateCheckboxOption}
                selectedValues={headerSearchCriteria.stateList}
                orientation={"horizontal"}
                onChange={(e: (GroupCheckboxList | undefined)[]) => manualChargeHeaderVM.onGroupCheckboxChange(e, headerSearchCriteria, "stateList")}
            />
        </div>
        , [MAN_CHG_HDR_CONST.STATE, headerSearchCriteria, manualChargeHeaderVM])

    const memoBillToCompany = useMemo(() =>
        <div className='im-flex-row-item'>
            <NbisInputDropDown
                label={MAN_CHG_HDR_CONST.BILL_TO_COMPANY}
                inputType="freeText"
                width="150px"
                mode={'multiple'}
                sort={false}
                value={headerSearchCriteria.billToCompList?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => manualChargeHeaderVM.onMultipleDropdownChange(e, 'billToCompList')}
                options={manualChargeHeaderState.dynamicOptions.billToCompDropdownOptions} />
        </div>
        , [MAN_CHG_HDR_CONST.BILL_TO_COMPANY, headerSearchCriteria.billToCompList, manualChargeHeaderState.dynamicOptions.billToCompDropdownOptions, manualChargeHeaderVM])

    // const memoConsortiumDropDown = useMemo(() =>
    //     <div className='im-flex-row-item'>
    //         <NbisInputDropDown
    //             label={'Consortium Code'}
    //             inputType="freeText"
    //             width="100px"
    //             mode={'multiple'}
    //             sort={false}
    //             value={headerSearchCriteria.billToCompList?.map((item) => ({
    //                 value: item
    //             }))}
    //             onChange={(e:any) => manualChargeHeaderVM.onMultipleDropdownChange(e, 'billToCompList')}
    //             options={manualChargeHeaderState.dynamicOptions.billToCompDropdownOptions}/>
    //     </div>
    // , [headerSearchCriteria.billToCompList, manualChargeHeaderState.dynamicOptions.billToCompDropdownOptions, manualChargeHeaderVM])


    const memoOpsDateFromTo = useMemo(() =>
        <div className='im-flex-row-item'>
            <DateRangePickerComponent
                label={MAN_CHG_HDR_CONST.OPS_DATE_RANGE}
                width='330px'
                fieldNames={{ startField: "opsDateFrom", endField: "opsDateTo" }}
                dateRange={{ startDate: headerSearchCriteria.opsDateFrom, endDate: headerSearchCriteria.opsDateTo }}
                onDatesChange={manualChargeHeaderVM.onDateRangeChange}
            />
        </div>
        , [MAN_CHG_HDR_CONST.OPS_DATE_RANGE, headerSearchCriteria.opsDateFrom, headerSearchCriteria.opsDateTo, manualChargeHeaderVM.onDateRangeChange])

    const memoCreatedDateFromTo = useMemo(() =>
        <div className='im-flex-row-item'>
            <DateRangePickerComponent
                label={MAN_CHG_HDR_CONST.CREATED_DATE_RANGE}
                width='330px'
                fieldNames={{ startField: "createdDateFrom", endField: "createdDateTo" }}
                dateRange={{ startDate: headerSearchCriteria.createdDateFrom, endDate: headerSearchCriteria.createdDateTo }}
                onDatesChange={manualChargeHeaderVM.onDateRangeChange}
            />
        </div>
        , [MAN_CHG_HDR_CONST.CREATED_DATE_RANGE, headerSearchCriteria.createdDateFrom, headerSearchCriteria.createdDateTo, manualChargeHeaderVM.onDateRangeChange])

    const memoRefNo = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputField
                width='150px'
                maxLength={999}
                label={MAN_CHG_HDR_CONST.REF_NO}
                type="text"
                value={headerSearchCriteria.refNo || ''}
                onChange={(e: any) => manualChargeHeaderVM.onInputTextChange(e, 'refNo')} />
        </div>
        , [MAN_CHG_HDR_CONST.REF_NO, headerSearchCriteria.refNo, manualChargeHeaderVM])

    const memoInvCreditNoteNo = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputField
                width='150px'
                maxLength={999}
                label={MAN_CHG_HDR_CONST.INV_CR_NO}
                type="text"
                value={headerSearchCriteria.invCreditNoteNo || ''}
                onChange={(e: any) => manualChargeHeaderVM.onInputTextChange(e, 'invCreditNoteNo')} />
        </div>
        , [MAN_CHG_HDR_CONST.INV_CR_NO, headerSearchCriteria.invCreditNoteNo, manualChargeHeaderVM])

    const menoCntrNo = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputField
                width='150px'
                maxLength={999}
                label={MAN_CHG_HDR_CONST.CNTR_NO}
                type="text"
                value={headerSearchCriteria.cntrNo || ''}
                onChange={(e: any) => manualChargeHeaderVM.onInputTextChange(e, 'cntrNo')} />
        </div>
        , [MAN_CHG_HDR_CONST.CNTR_NO, headerSearchCriteria.cntrNo, manualChargeHeaderVM])

    return <>

        <CriteriaItemContainer>
            {manualChargeHeaderState.enabledSearchCriteria.stateList ? memoState : null}
        </CriteriaItemContainer>

        <CriteriaItemContainer>
            {manualChargeHeaderState.enabledSearchCriteria.chargeTypeList ? memoChgType : null}
        </CriteriaItemContainer>

        <CriteriaItemContainer>
            {manualChargeHeaderState.enabledSearchCriteria.billToCompList ? memoBillToCompany : null}
            {manualChargeHeaderState.enabledSearchCriteria.refNo ? memoRefNo : null}
        </CriteriaItemContainer>

        <CriteriaItemContainer>
            {
                //manualChargeHeaderState.enabledSearchCriteria.coVslVoy ? memoConsortiumDropDown : null
            }
            {manualChargeHeaderState.enabledSearchCriteria.coVslVoy ? memoCoVslVoy : null}
        </CriteriaItemContainer>

        <CriteriaItemContainer>
            {manualChargeHeaderState.enabledSearchCriteria.invCreditNoteNo ? memoInvCreditNoteNo : null}
            {manualChargeHeaderState.enabledSearchCriteria.cntrNo ? menoCntrNo : null}
        </CriteriaItemContainer>


        <CriteriaItemContainer>
            {manualChargeHeaderState.enabledSearchCriteria.opsDate ? memoOpsDateFromTo : null}
        </CriteriaItemContainer>

        <CriteriaItemContainer>
            {manualChargeHeaderState.enabledSearchCriteria.createdDate ? memoCreatedDateFromTo : null}
        </CriteriaItemContainer>
    </>;
}
export default memo(ManualChargeHeaderSearchForm);