import type { CustomCellRendererProps } from "ag-grid-react";
import { DECIMAL_2 } from "presentation/utils/numberUtil";
import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { CompanyConstant } from "./CompanyConstant";

const COMPANY_DETAIL_CONSTANT = CompanyConstant.Detail;
let dateFieldList: string[] = [];
let dateTimeFieldList: string[] = [];
let numberFieldList: string[] = [];
export const INITIAL_CUSTOMER_ASSIGNMENT_COL_DEF: any[] = [
    {
        headerName: COMPANY_DETAIL_CONSTANT.OPERATING_TML,
        field: 'operatingTml',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
        pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CUSTOMER.INTERCOMPANY_CODE,
        field: 'intercompanyCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.EFFECTIVE_DATE,
        field: 'effectiveDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
        dataType: "date"
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CUSTOMER.CUSTOMER_CODE,
        field: 'customer.customerCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CHARGE_TYPE,
        field: 'chargeType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CUSTOMER.CURRENCY_CODE,
        field: 'currencyCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CUSTOMER.CONTACT_PERSON,
        field: 'customer.contactPerson',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CUSTOMER.TYPE,
        field: 'customer.type',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 100,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CUSTOMER.CUSTOMER_NTN_NO,
        field: 'customer.ntnNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CUSTOMER.CUSTOMER_GST_NO,
        field: 'customer.gstNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CUSTOMER.CUSTOMER_ADDRESS,
        field: 'customer.customerAddress',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CUSTOMER.CUSTOMER_EMAIL_ADDRESS,
        field: 'customer.emailAddress',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 230,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CUSTOMER.CUSTOMER_NAME_1,
        field: 'customer.customerDesc1',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CUSTOMER.CUSTOMER_NAME_2,
        field: 'customer.customerDesc2',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CUSTOMER.N_I_F_NUMBER,
        field: 'customer.nif',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CUSTOMER.PAYMENT_TERM,
        field: 'customer.paymentTerm',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
        dataType: 'number',
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CUSTOMER.INTEREST_RATE,
        field: 'customer.interestRate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
        dataType: 'number',
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CUSTOMER.PAYMENT_CUT_OFF_DAY_1,
        field: 'customer.paymentCutoffDay1',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 220,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CUSTOMER.PAYMENT_CUT_OFF_DAY_2,
        field: 'customer.paymentCutoffDay2',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 220,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CUSTOMER.PAYMENT_CUT_OFF_DAY_3,
        field: 'customer.paymentCutoffDay3',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 220,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CUSTOMER.STATEMENT_ADDRESS_1,
        field: 'customer.statementAddress1',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CUSTOMER.STATEMENT_ADDRESS_2,
        field: 'customer.statementAddress2',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CUSTOMER.STATEMENT_ADDRESS_3,
        field: 'customer.statementAddress3',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CUSTOMER.BILLING_ADDRESS_1,
        field: 'customer.billingAddress1',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CUSTOMER.BILLING_ADDRESS_2,
        field: 'customer.billingAddress2',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CUSTOMER.BILLING_ADDRESS_3,
        field: 'customer.billingAddress3',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CUSTOMER.BILLING_ADDRESS_4,
        field: 'customer.billingAddress4',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CUSTOMER.CITY,
        field: 'customer.city',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 100,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CUSTOMER.COUNTY,
        field: 'customer.county',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 120,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CUSTOMER.STATE,
        field: 'customer.state',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 100,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CUSTOMER.PROVINCE,
        field: 'customer.province',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 120,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CUSTOMER.POSTAL_CODE,
        field: 'customer.postalCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CUSTOMER.COUNTRY,
        field: 'customer.country',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 120,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CUSTOMER.TEL,
        field: 'customer.telephoneNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 100,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CUSTOMER.FAX,
        field: 'customer.faxNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 100,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CUSTOMER.GL_ACCOUNT_CODE,
        field: 'customer.glAccount',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CUSTOMER.CREDIT_LIMIT,
        field: 'customer.creditLimit',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CUSTOMER.OUTSTANDING_BALANCE,
        field: 'customer.outstandingBal',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CUSTOMER.NET_BALANCE,
        field: 'customer.netBal',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CUSTOMER.UN_FINALIZED_AMOUNT,
        field: 'customer.unFinalizedAmount',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 220,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CUSTOMER.CREDIT_LIMIT_EXCEEDED,
        field: 'customer.creditLimitExceeded',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 220,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CUSTOMER.REGION,
        field: 'customer.region',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 120,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CUSTOMER.SHIPPING_LINE_ADDRESS_1,
        field: 'customer.shippingLineAddress1',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 220,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CUSTOMER.SHIPPING_LINE_ADDRESS_2,
        field: 'customer.shippingLineAddress2',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 220,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.CUSTOMER.SHIPPING_LINE_ADDRESS_3,
        field: 'customer.shippingLineAddress3',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 220,
    },
    {
        headerName: COMPANY_DETAIL_CONSTANT.ACTIVE_IND,
        field: 'customer.activeInd',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    }

].map((col, index) => {
    const cellRenderers: { [key: string]: ((params: CustomCellRendererProps) => {}) } = {};

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers, numberFieldList);
});

export const transferRowData = (data: any[]) => {
    const externalFnctions: { [key: string]: ((fieldName: string, row: any) => {}) } = {};

    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions, numberFieldList);
}