import { SelectionChangedEvent } from "ag-grid-community";
import { EMPTY_SEARCH_MODE_ENTITY } from "domain/entity/ChargeDataEnquiry/SearchModeEntity";
import { VesselVoyageMasterEntity } from "domain/entity/ChargeDetailMaintenance/VesselVoyageMasterEntity";
import { ChargeDetailConstant } from "presentation/constant/ChargeDetailMaintenance/ChargeDetailConstant";
import { INITIAL_VESSEL_VOYAGE_MASTER_COL_DEF, transferRowData } from "presentation/constant/ChargeDetailMaintenance/VesselVoyageMasterColumnDefinition";
import { useChargeDetailVM } from "presentation/hook/ChargeDetailMaintenance/useChargeDetailVM";
import { useChargeHeaderMaintenanceVM } from "presentation/hook/ChargeHeaderMaintenance/useChargeHeaderMaintenanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useChargeDetailTracked } from "presentation/store/ChargeDetailMaintenance/ChargeDetailProvider";
import { useChargeHeaderMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/ChargeHeaderMaintenanceProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HPHButton, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarActionBar, SidebarTitle, Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const UpdateVesselVoyageMasterTablePanel = () => {
    const [chargeDetailState] = useChargeDetailTracked();
    const [chargeHeaderState] = useChargeHeaderMaintenanceTracked();
    const chargeHeaderVM = useChargeHeaderMaintenanceVM();
    const { selectedVesselVoyageMasters, selectedChargeDetails, allFormState } = chargeDetailState;
    const chargeDetailVM = useChargeDetailVM();
    const tableId = 'charge-detail-table';
    const VESSEL_CONSTANT = ChargeDetailConstant.Vessel;
    const CHARGEDETAIL_CONSTANT = ChargeDetailConstant.Charge;
    const messageBarVM = useMessageBarVM();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const gridRef: any = useRef();
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);

    const handleRowDoubleClick = useCallback((vesselVoyage: VesselVoyageMasterEntity) => {
        chargeDetailVM.handleVslVoyRowDoubleClick(vesselVoyage);
    }, [chargeDetailVM]);

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        chargeDetailVM.updateSelectedVslVoys(selectedRows);
    }, [chargeDetailVM])

    const closeButtonClicked = useCallback(() => {
        chargeDetailVM.onVslVoyCloseClick();
    }, [chargeDetailVM]);

    const goBackToSearchButtonClicked = useCallback(() => {
        chargeDetailVM.onUpdateVslVoySearchClick();
    }, [chargeDetailVM]);

    const applyButtonClicked = useCallback(() => {
        let newSearchCriteria = chargeDetailState.searchCriteria;
        newSearchCriteria = { ...newSearchCriteria, chargeHdrId: Number(chargeDetailState?.currentChargeHeader?.id ?? chargeHeaderState?.currentChargeHeader?.id) ?? 0 }

        setOnTableSelectionClicked(true);
        setIsLoading(true);
        chargeDetailVM.onVslVoyApplyClick(selectedVesselVoyageMasters, selectedChargeDetails).then((data) => {
            Promise.allSettled([
                chargeDetailVM.getChargeDetails(newSearchCriteria),
                chargeHeaderVM.onSearch({
                    ...chargeDetailState.searchCriteria,
                    id: chargeDetailState?.currentChargeHeader?.id ?? chargeHeaderState?.currentChargeHeader?.id
                }, EMPTY_SEARCH_MODE_ENTITY, true, true)
            ]).finally(() => {
                if (allFormState && allFormState["updateNewVesselVoyageFail"]) {
                    messageBarVM.showError(allFormState["updateNewVesselVoyageFail"]?.toString());
                } else {
                    messageBarVM.showSuccess(allFormState["updateNewVesselVoyageSuccess"]?.toString());
                }
                setIsLoading(false);
            });
        }).catch(() => {
            setIsLoading(false);
        });
    }, [chargeDetailVM, selectedVesselVoyageMasters, selectedChargeDetails, allFormState, messageBarVM,
        chargeDetailState.searchCriteria, chargeDetailState?.currentChargeHeader?.id, chargeHeaderState?.currentChargeHeader?.id, chargeHeaderVM]);

    useEffect(() => {
        if (onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();
    }, [onTableSelectionClicked])

    useEffect(() => {
        // const columnDefs = (INITIAL_VESSEL_VOYAGE_MASTER_COL_DEF.slice());

        if (!selectedVesselVoyageMasters ||
            selectedVesselVoyageMasters.length <= 0) {
            //gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
            if (!onTableSelectionClicked) {
                gridRef.current?.gridRef.current.api?.deselectAll();
            }
        }
    })


    const memoGroupTable = useMemo(() => {
        return (
            <NbisTable
                id={tableId}
                isNewColumnSetting={true}
                columns={INITIAL_VESSEL_VOYAGE_MASTER_COL_DEF}
                headerLabel={VESSEL_CONSTANT.VSL_VOY_LIST}
                onRowDoubleClick={(e: any, vesselVoyage: VesselVoyageMasterEntity) => handleRowDoubleClick(vesselVoyage)}
                data={transferRowData(chargeDetailState.vesselVoyageMasters)}
                showPaginator={false}
                editable={false}
                showAddIcon={false}
                showDeleteButton={false}
                showReloadIcon={false}
                isScrollHighlighted={true}
                selectionMode={false}
                onSelectionChanged={handleSelectionChange}
                gridHeight="customHeight"
                customHeight="calc(66vh)"
                ref={gridRef}
            />

        );
    }, [VESSEL_CONSTANT.VSL_VOY_LIST, chargeDetailState.vesselVoyageMasters, handleRowDoubleClick, handleSelectionChange])

    return (
        <div className='side-form-content-wrapper'>
            {isLoading &&
                <Loader Indicator="Spinner" size="Medium" />
            }
            <div className={'im-flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>{CHARGEDETAIL_CONSTANT.UPDATE_VESSEL_VOYAGE}</SidebarTitle>
                    </Sidebarheader>
                </div>
            </div>
            <div className={'im-flex-row'}>
                <div className={'add-edit-form'} style={{ maxHeight: '71vh', overflow: 'auto' }}>
                    <div className='flex-row-item flex-row-item-full-width'>

                        {/* Stack Form Start */}
                        <TableWrapper>{memoGroupTable}</TableWrapper>
                    </div>
                </div>
            </div>
            <SidebarActionBar>
                <HPHButton label={'Close'} size={'Small'} theme={'Secondary'} onClick={closeButtonClicked} />
                <HPHButton label={'Go Back To Search'} size={'Small'} theme={'Secondary'} onClick={goBackToSearchButtonClicked} />
                <HPHButton label={'Apply'} size={'Small'} theme={'Primary'} onClick={() => applyButtonClicked()} />
            </SidebarActionBar>
        </div>
    );
}

export default memo(UpdateVesselVoyageMasterTablePanel);