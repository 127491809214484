import { CriteriaRequestEntity } from "domain/entity/Criteria/CriteriaRequestEntity";
import { useCriteriaVM } from "presentation/hook/Criteria/useCriteriaVM";
import { useCriteriaTracked } from "presentation/store/Criteria/CriteriaProvider";
import { memo, useEffect, useState } from "react";
import { Loader } from "veronica-ui-component/dist/component/core";
import CriteriaTablePanel from "./Table/CriteriaTablePanel";

const CriteriaMaintenance: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [criteriaState] = useCriteriaTracked();
    const criteriaVM = useCriteriaVM();
    const { isShowCriteriaPanel, isBackFromDetail, entrypoint, isLoadingMoveAfter } = criteriaState;


    useEffect(() => {
        let request: CriteriaRequestEntity = {
            criteriaTypeName: entrypoint
        }
        const initialScreen = async () => {
            setIsLoading(true);
            try {
                await Promise.allSettled([
                    criteriaVM.loadDropdownOption(),
                    criteriaVM.loadConsGrpTypeDropdownOption(request),
                    entrypoint && criteriaVM.searchCriteria(entrypoint),
                ]);

            } finally {
                setIsLoading(false);
            }
        }

        initialScreen();
    }, [isBackFromDetail, criteriaVM, entrypoint])



    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;

    return <>
        <div className={`main-comp-wrapper${isShowCriteriaPanel ? '' : ' im-hide-side-form-draggable'}`}>
            {isLoadingMoveAfter && <Loader Indicator="Spinner" size="Medium" />}
            <CriteriaTablePanel />
        </div>
    </>
}

export default memo(CriteriaMaintenance);