import { EMPTY_COMPANY_CHARGE_SUPPLE_TERMS_ENTITY } from "domain/entity/Company/CompanyChargeSuppleTermsEntity";
import { EMPTY_COMPANY_CUSTOMER_ENTITY } from "domain/entity/Company/CompanyCustomerEntity";
import { EMPTY_COMPANY_CUSTOMER_INFO_ENTITY } from "domain/entity/Company/CompanyCustomerInfoEntity";
import { EMPTY_COMPANY_EMPTY_POOL_ENTITY } from "domain/entity/Company/CompanyEmptyPoolEntity";
import { EMPTY_COMPANY_ENTITY } from "domain/entity/Company/CompanyEntity";
import { EMPTY_COMPANY_FSP_ENTITY } from "domain/entity/Company/CompanyFspEntity";
import { EMPTY_COMPANY_TEU_FACTOR_ENTITY } from "domain/entity/Company/CompanyTeuFactorEntity";
import { MasterDataType } from "domain/entity/MasterData/MasterDataEntity";
import { ChargeTypeRepository } from "domain/repository/ChargeType/ChargeTypeRepo";
import { SearchPreferenceRepository } from "domain/repository/Common/SearchPreferenceRepo";
import { CompanyRepository } from "domain/repository/Company/CompanyRepo";
import { CompanyTerminalRepository } from "domain/repository/Company/CompanyTerminalRepo";
import { MasterDataRepository } from "domain/repository/MasterData/MasterDataRepo";
import _ from "lodash";
import { CompanyEnabledSearchCriteria } from "presentation/constant/Company/CompanyEnabledSearchCriteria";
import { CompanySearchCriteria } from "presentation/constant/Company/CompanySearchCriteria";
import { ActiveIndDroOpts } from "presentation/constant/DropDownOptions/Common/ActiveIndDroOpts";
import { CompanyDetailModel, EMPTY_COMPANY_DETAIL_MODEL } from "presentation/model/Company/CompanyDetailModel";
import { CompanyMaintenanceModel } from "presentation/model/Company/CompanyMaintenanceModel";

import { DropdownProps } from "presentation/model/DropdownProps";
import BaseViewModel from "presentation/viewModel/BaseViewModel";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";

interface CompanyDetailVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<CompanyDetailModel>> | ((value: SetStateAction<CompanyDetailModel>) => void),
    ]
    companyMainDispatch: [
        Dispatch<SetStateAction<CompanyMaintenanceModel>> | ((value: SetStateAction<CompanyMaintenanceModel>) => void),
    ]
    companyRepo: CompanyRepository,
    chargeTypeRepo: ChargeTypeRepository,
    companyTerminalRepo: CompanyTerminalRepository,
    searchPreferenceRepo: SearchPreferenceRepository<CompanySearchCriteria, CompanyEnabledSearchCriteria>,
    masterDataRepo: MasterDataRepository
}

export const CompanyDetailVM = ({ dispatch, companyMainDispatch, chargeTypeRepo, companyTerminalRepo, masterDataRepo }: CompanyDetailVMProps) => {
    const [companyDetailDispatch] = dispatch;
    const [companyDispatch] = companyMainDispatch

    const onCloseScreen = () => {
        companyDetailDispatch(prevState => {
            return {
                ...EMPTY_COMPANY_DETAIL_MODEL,
            }
        })

        companyDispatch(prevState => {
            return {
                ...prevState,
                companys: [],
                currentCompany: EMPTY_COMPANY_ENTITY,
                selectCompanyRows: [],
                isShowDetail: false,
                isBackFromDetail: true,
                isAllowAutoSearch: true,
                isShowRightPanel: false,

                viewState: {
                    ...prevState.viewState,
                    isSliderOpen: false,
                    isTabularDataActive: false,
                    isAdd: false,
                    isRead: true,
                    isEditable: false,
                    isSaveClicked: false,
                    lastEditRowId: "",
                    isSelected: false,
                    allFormState: {},

                    slider: {
                        isAdd: false,
                        isRead: false,
                        isEditable: false
                    }
                }
            }
        })
    }

    const loadDropdownOption = async (companyId: number) => {
        companyDetailDispatch(prevState => ({
            ...prevState,
            dynamicOptions: {
                ...prevState.dynamicOptions,
                activeIndDropdownOptions: ActiveIndDroOpts().getDroOptsModel()
            }
        }))

        await masterDataRepo.getMasterDataByKey(MasterDataType.TAR_NATURE).then(
            tariffNatures => {
                let tariffNatureDropdownOptions: DropdownProps[] = [];
                for (let i = 0; i < tariffNatures.length; i++) {
                    if (tariffNatures[i].id) {
                        tariffNatureDropdownOptions.push({
                            dropdownLabel: tariffNatures[i].code,
                            tagLabel: tariffNatures[i].id.toString(),
                            value: tariffNatures[i].code
                        });
                    }
                }

                companyDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        tariffNatureDropdownOptions: [
                            ...tariffNatureDropdownOptions]
                    }
                }))
            }
        )

        await masterDataRepo.getMasterDataByKey(MasterDataType.TERMINAL).then(
            terminals => {
                let terminalDropdownOptions: DropdownProps[] = [];
                for (let i = 0; i < terminals.length; i++) {
                    if (terminals[i].id) {
                        terminalDropdownOptions.push({
                            dropdownLabel: terminals[i].code,
                            tagLabel: terminals[i].id.toString(),
                            value: terminals[i].code
                        });
                    }
                }

                companyDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        contractTmlDropdownOptions: [
                            ...terminalDropdownOptions],
                        custAssignOperatingTmlDropdownOptions: [
                            ...terminalDropdownOptions]
                    }
                }))
            }
        )

        await chargeTypeRepo.getAllChargeTypesForCombobox().then(
            chargeTypes => {
                let newChargeTypes = _.orderBy(chargeTypes, ["chargeType", "subChargeType"]);
                let chargeTypeDropdownOptions: DropdownProps[] = [];
                let subChargeTypeDropdownOptions: { [key: string]: DropdownProps[] } = {};
                newChargeTypes.forEach(chgTypeEty => {
                    const chgTypeExisted = chargeTypeDropdownOptions.find(chgType =>
                        chgType.value === chgTypeEty.chargeType);
                    if (!chgTypeExisted) {
                        chargeTypeDropdownOptions.push({
                            dropdownLabel: chgTypeEty.chargeType,
                            tagLabel: chgTypeEty.chargeType,
                            value: chgTypeEty.chargeType
                        })
                    }

                    if (chgTypeEty.subChargeType) {
                        if (!subChargeTypeDropdownOptions[chgTypeEty.chargeType]) {
                            subChargeTypeDropdownOptions[chgTypeEty.chargeType] = [];
                        }
                        subChargeTypeDropdownOptions[chgTypeEty.chargeType].push({
                            dropdownLabel: chgTypeEty.subChargeType,
                            tagLabel: chgTypeEty.subChargeType,
                            value: chgTypeEty.subChargeType
                        });
                    }
                });

                companyDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        chargeTypeDropdownOptions: chargeTypeDropdownOptions,
                        subChargeTypeDropdownOptions: subChargeTypeDropdownOptions
                    }
                }))
            }
        )

        await companyTerminalRepo.searchCompanyTerminal(companyId).then(
            companyTerminals => {
                const operatingTmlDropdownOptions = companyTerminals?.map((companyTerminal) => ({
                    dropdownLabel: companyTerminal.terminal?.terminalCode as string,
                    tagLabel: companyTerminal.terminal?.terminalCode as string,
                    value: companyTerminal.terminal?.terminalCode as string,
                })) ?? []

                companyDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        operatingTmlDropdownOptions: [
                            ...operatingTmlDropdownOptions]
                    }
                }))
            }
        )
    }

    const onEdit = () => {
        companyDetailDispatch(prevState => {
            return {
                ...prevState,
                //isShowRightPanel:false,
                viewState: {
                    ...prevState.viewState,

                    isEditCompDtlInfo: true,
                    isEditable: prevState.isShowRightPanel ? true : false
                }
            }
        })
    }

    const onReset = () => {
        companyDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowRightPanel: prevState.viewState.isAdd ? false : prevState.isShowRightPanel,
                viewState: {
                    ...prevState.viewState,
                    isEditCompDtlInfo: false,
                    isAdd: false,
                    isEditable: false,
                }
            }
        })
    }

    const onSaveClicked = () => {
        companyDetailDispatch(prevState => {
            return {
                ...prevState,
                viewState: {
                    ...prevState.viewState,
                    isSaveClicked: true,
                    allFormState: {},
                }
            }
        })
    }

    const onCancel = () => {
        companyDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowRightPanel: false,
                chargeSuppleTermsState: {
                    ...prevState.chargeSuppleTermsState,
                    selectedDatas: [],
                    currentCompChgSuppleTerm: EMPTY_COMPANY_CHARGE_SUPPLE_TERMS_ENTITY
                },
                companyEmptyPoolState: {
                    ...prevState.companyEmptyPoolState,
                    selectedDatas: [],
                    currentCompEmptyPool: EMPTY_COMPANY_EMPTY_POOL_ENTITY
                },
                companyFspState: {
                    ...prevState.companyFspState,
                    selectedDatas: [],
                    currentCompFsp: EMPTY_COMPANY_FSP_ENTITY
                },
                companyTeuFactorState: {
                    ...prevState.companyTeuFactorState,
                    selectedDatas: [],
                    currentCompTeuFactor: EMPTY_COMPANY_TEU_FACTOR_ENTITY
                },
                customerAssignmentState: {
                    ...prevState.customerAssignmentState,
                    selectedDatas: [],
                    currentCompanyCust: EMPTY_COMPANY_CUSTOMER_ENTITY
                },
                customerInformationState: {
                    ...prevState.customerInformationState,
                    selectedDatas: [],
                    currentCompanyCustInfo: EMPTY_COMPANY_CUSTOMER_INFO_ENTITY
                },
                viewState: {
                    ...prevState.viewState,
                    isAdd: false,
                    isEditable: false,
                    isSaveClicked: false,
                    isSliderOpen: false,
                    allowAutoRefresh: false,
                    allFormState: {},

                    isShowFsp: false,
                    isShowEmptyPool: false,
                    isShowSuppleTerms: false,
                    isShowCustAssign: false,
                    isShowFactor: false,
                    isShowCustInfo: false
                }
            }
        })
    }

    const onCancelDelete = () => {
        companyDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowRightPanel: false,
                viewState: {
                    ...prevState.viewState,
                    isShowDeleteModal: false,
                    dataType: ""
                }
            }
        })
    }

    const onRejectReasonTextChange = (fieldValue: IFieldValue) => {
        let val: any = fieldValue;
        return companyDetailDispatch(prevState => {
            return {
                ...prevState,
                viewState: {
                    ...prevState.viewState,
                    rejectReason: val
                }
            }
        });
    }

    const closeRejectReasonModal = () => {
        companyDetailDispatch(prevState => {
            return {
                ...prevState,
                viewState: {
                    ...prevState.viewState,
                    isShowRejectModal: false,
                    dataType: "",
                    rejectReason: ""
                }
            }
        });
    }

    return {
        onCloseScreen: onCloseScreen,
        loadDropdownOption: loadDropdownOption,
        onEdit: onEdit,
        onReset: onReset,
        onCancel: onCancel,
        onRejectReasonTextChange: onRejectReasonTextChange,
        closeRejectReasonModal: closeRejectReasonModal,
        onSaveClicked: onSaveClicked,
        onCancelDelete: onCancelDelete
    }
}