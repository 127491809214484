import { useChargeDetailVM } from "presentation/hook/ChargeDetailMaintenance/useChargeDetailVM";
import { ChargeDetailModel } from "presentation/model/ChargeDetailMaintenance/ChargeDetailModel";
import NbisInputDropDown from "presentation/view/components/NbisInputDropDown";

export const CntrSizeTypeAccordion = ({ chargeDetailState }: { chargeDetailState: ChargeDetailModel }) => {
    const chargeBatchUpdate = chargeDetailState.chargeBatchUpdate
    const chargeDetailVM = useChargeDetailVM();
    return (
        <>
            <div className={'im-flex-row'} style={{ marginBottom: '24px' }}>
                <div className='im-flex-row-item'>
                    <NbisInputDropDown
                        label='Size'
                        width='150px'
                        inputType="freeText"
                        mode={"single"}
                        value={chargeBatchUpdate.size?.toString()}
                        onChange={(e: any) => chargeDetailVM.onDropdownChange(e, 'size', chargeBatchUpdate, 'chargeBatchUpdate')}
                        options={chargeDetailState.dynamicOptions.sizeGroupDropdownOptions} />
                </div>

                <div className='im-flex-row-item'>
                    <NbisInputDropDown
                        label='Type'
                        width='150px'
                        inputType="freeText"
                        mode={"single"}
                        value={chargeBatchUpdate.cntrType}
                        onChange={(e: any) => chargeDetailVM.onDropdownChange(e, 'cntrType', chargeBatchUpdate, 'chargeBatchUpdate')}
                        options={chargeDetailState.dynamicOptions.typeDropdownOptions} />
                </div>
            </div>
        </>
    )
}