/* eslint-disable import/extensions */
import type { InputDropdownProps } from 'presentation/view/components/NbisInputDropDown';
import { AutoComplete } from 'primereact/autocomplete';
import styled, { createGlobalStyle, css } from 'styled-components';

import IconAutoText from 'veronica-ui-component/dist/assets/Icon/Icon-auto-text.svg';
import {
  HPHGreyDark,
  HPHGreyLight,
  HPHRed,
  HPHSeaBlue,
  HPHSkyBlue,
  MontserratFont,
} from 'veronica-ui-component/dist/component/core/Colors';
import { scrollbarStyles } from 'veronica-ui-component/dist/component/core/styled/scrollbar.styled';


interface StyledAutoCompleteGlobal {
  panelWidth?: string;
  [x: string]: any;
}

const arrowBackground = (props: any) => {
  if (props.arrowBackground === 'transparent') {
    return css`
      background: transparent;
    `;
  } else if (props.arrowBackground === 'white') {
    return css`
      background: white;
    `;
  }
};

const cursor = (props: any) => {
  if (props.disableInput) {
    return css`
      cursor: default;
    `;
  }
};

// const getPanelLeft = (props) => props.panelLeft && css`left: ${props.panelLeft}px !important`;
export const AutoCompleteGlobalStyles = createGlobalStyle<StyledAutoCompleteGlobal>`
  ${scrollbarStyles}
  .p-autocomplete{
    height:31px;
    font-family: ${MontserratFont}; 
  }
  .p-autocomplete-panel {
    border-radius: 0px !important;
    box-shadow: none !important;
    overflow: hidden !important;
    border:1px solid ${HPHGreyDark} !important;
    min-height: 50px !important;
    max-height: 225px !important;
    &:hover, &:focus{
      border-color: ${HPHGreyDark} !important;
    }
    .p-virtualscroller {
      cursor: pointer;
      width:100% !important;
    }  
    .p-autocomplete-items {
      padding: 0 !important;
      [aria-selected="true"] {
        color: ${HPHSkyBlue} !important;
      }
      .p-autocomplete-item {
        display: flex;
        min-height: 30px;
        white-space: initial;
        padding: 0px !important;
        word-break: break-word;
        .dropdownSeletcted {
          color: ${HPHSkyBlue} !important;
        }
        &:hover {
          background: #C5E0F2 !important;
        }
      }
    }
  }
  .inputDisable{
    li.p-autocomplete-input-token{
      display:none; 
    }
  }
`;

export const StyledInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  .p-inputtext:enabled:focus {
    outline: none;
    box-shadow: none;
    border-color: ${HPHSkyBlue};
  }
  .p-inputtext {
    &:enabled:hover {
      border-color: ${HPHSkyBlue};
    }
  }
  .p-inputtext:disabled {
    opacity: 1;
  }
`;

export const StyledMenuItemTemplate = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  padding: 0.0625rem 0.55rem !important;
    #LozengesContainer {
      margin-bottom: 5px;
      flex: initial;
    }
`;

export const StyledMenuItemTemplateValue = styled.div`
  font-size: 1rem;
  font-family: ${MontserratFont};
  flex: 1;

  & .highlight{
    font-weight:bold;
    color:${HPHSkyBlue};
  }
`;
export const StyledOptional = styled.div`
  margin-left: 2px
  span{
    background: rgb(245,245,245);
    color: rgb(180,180,180);
    font-size: 8px;
    font-weight: 600;
    letter-spacing: 0.025rem;
  }
`;

export const StyledRequired = styled.em`
  color: ${HPHRed};
  font-style: initial;
  margin-left: 2px;
`;

export const StyledLozengesContainer = styled.span`
  margin-left: 10px;
  flex: 1;
`;

export const StyledInputLabel = styled.label`
  color: ${HPHSeaBlue};
  display: block;
  font-family: ${MontserratFont};
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 1.2;
  text-transform: capitalize;
  display: flex;
  align-items: center;
`;

export const StyledMenuItemTemplateIcon = styled.div<InputDropdownProps>`
display: flex;
justify-content: center;
align-item: center;
svg{
  pointer-events: none;
  width:24px;
  height:24px;
  margin-right: 5px;
  padding: 0px;
}
`;

const getAutoCompleteWidth = (props: any) =>
  props.autoCompleteWidth &&
  css`
    width: ${props.autoCompleteWidth};
  `;

const InputErrMsgStyle = (props: any) => {
  if (props.errorMessage) {
    return css`
      .p-inputwrapper {
        border-color: ${HPHRed} !important;
        .p-inputtext,
        .p-button {
          border-color: ${HPHRed} !important;
        }
        &:hover {
          border-color: ${HPHRed} !important;
          .p-inputtext,
          .p-button {
            border-color: ${HPHRed} !important;
          }
        }
      }
    `;
  } else {
    return css`
      .p-inputwrapper {
        border-color: ${HPHGreyDark};
        .p-inputtext,
        .p-button {
          border-color: ${HPHGreyDark} !important;
        }
        &:hover,
        &:focus {
          border-color: ${HPHSkyBlue} !important;
        }
      }
    `;
  }
};

export const StyledAutoComplete = styled(AutoComplete) <InputDropdownProps>`   
  &.p-inputwrapper-focus{
    .p-autocomplete-multiple-container,
    .p-button-icon-only {
      border-color: ${HPHSkyBlue} !important;
    }
  }
  .p-component{    
    border:none;
    border-bottom: 1px solid ${HPHGreyDark};
  }
  .p-autocomplete-multiple-container {
    overflow: auto;
    flex-wrap: unset;
    ${getAutoCompleteWidth}
    ${cursor};
    padding: 0 !important;
    .p-autocomplete-input-token {
      input {
        font-family: ${MontserratFont} !important;
        width:100% !important;
        min-width: 100px;
      }
    }
    &:not(.p-disabled).p-focus {
      box-shadow: none !important;
      border-color: ${HPHSkyBlue} !important;
    }
  }
  &:hover{
    .p-autocomplete-multiple-container,
    .p-autocomplete-dd-input,
    .p-autocomplete-dropdown{
      border-color: ${HPHSkyBlue} !important; 
    }
  }
  ${InputErrMsgStyle};
  .p-inputtext {
    font-family: ${MontserratFont};
    border-radius: 0px !important;
    overflow-y:hidden;
    ${cursor};
  }
  .p-autocomplete-dd-input {
    padding: 0;
  }
  .p-button {
    color: ${HPHGreyDark};
    ${arrowBackground};
    border-radius: 0;
    &:focus{
      box-shadow: none !important;
    }
    &.p-button-icon-only {
      padding : 0;
    }
  }
  .p-button:enabled:hover {
    color: ${HPHGreyDark};
    ${arrowBackground};
    border-radius: 0px;
    border-color: ${HPHSkyBlue};
  }
  .p-button-icon.Icon-auto-text {
    mask-image: url(${IconAutoText});
    width: 24px;
    height: 20px;
    background: ${HPHGreyDark};
  }
  .p-autocomplete-multiple-container .p-autocomplete-token {
    background: #C5E0F2 !important;
    color: ${HPHGreyDark} !important;
    padding: 0.15rem 0.25rem !important;
    font-family: ${MontserratFont};
    input::placeholder {
    font-family: ${MontserratFont};
    color: ${HPHGreyLight} !important;
    font-size: 1rem;
  }
}
  .p-autocomplete-multiple-container .p-autocomplete-input-token {
    padding: 0 !important;
  }
  .p-disabled, .p-component:disabled {
    color: ${HPHGreyLight};
    border-color: ${HPHGreyLight} !important;
    opacity:1;   
    .Icon-auto-text {
      mask-image: url(${IconAutoText});
      width: 24px;
      height: 20px;
      background: ${HPHGreyLight};
  }
  .p-autocomplete-panel {
      border-color: ${HPHGreyDark} !important;
  }
  .p-autocomplete-panel:hover,
  .p-autocomplete-panel:focus{
    border-color: ${HPHGreyDark} !important;
  }
`;

export const StyledInputErrorMsg = styled.span`
  color: ${HPHRed};
  display: block;
  font-family: ${MontserratFont};
  font-size: 0.875rem;
  font-style: italic;
  font-weight: 400;
  line-height: 1.2;
  margin-top: 0.25rem;
  position: absolute;
  top: 100%;
  white-space: nowrap;
`;
