
import { DocumentConst } from "constants/document/DocumentConst";
import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { EdiItemCodeMappingEntity, EMPTY_EDI_ITEM_CODE_MAPPING_ENTITY } from "domain/entity/EdiItemCodeMapping/EdiItemCodeMappingEntity";
import { CompanyRepository } from "domain/repository/Company/CompanyRepo";
import { EdiItemCodeMappingRepository } from "domain/repository/EdiItemCodeMapping/EdiItemCodeMappingRepo";
import { ParameterDetailRepository } from "domain/repository/parameter/ParameterDetailRepo";
import { StandardTariffCodeRepository } from "domain/repository/TariffCode/StandardTariffCodeRepo";
import { TariffTypeRepository } from "domain/repository/TariffCode/TariffTypeRepo";
import _ from "lodash";
import { createEdiItemCodeMappingMaintenanceValidationSchema } from "presentation/constant/EdiItemCodeMapping/EdiItemCodeMappingMaintenanceValidationSchema";
import { Validation } from "presentation/constant/Validation";
import { DropdownProps } from "presentation/model/DropdownProps";
import { EdiItemCodeMappingMaintenanceModel } from "presentation/model/EdiItemCodeMapping/EdiItemCodeMappingMaintenanceModel";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";

interface EdiItemCodeMappingMaintenanceVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<EdiItemCodeMappingMaintenanceModel>> | ((value: SetStateAction<EdiItemCodeMappingMaintenanceModel>) => void),
    ],
    repo: EdiItemCodeMappingRepository,
    parameterDetailRepo: ParameterDetailRepository,
    companyRepo: CompanyRepository,
    standardTariffCodeRepo: StandardTariffCodeRepository,
    tariffTypeRepo: TariffTypeRepository,
}

export const EdiItemCodeMappingMaintenanceVM = ({ dispatch, repo, parameterDetailRepo, companyRepo, standardTariffCodeRepo, tariffTypeRepo }: EdiItemCodeMappingMaintenanceVMProps) => {
    const [ediItemCodeMappingMainDispatch] = dispatch;

    const loadDropdownOption = async () => {
        await parameterDetailRepo.getAllParameterDtlsByParameterCode("CNTR_SIZE").then(
            cntrSizeList => {
                const sizeDropdownOption = cntrSizeList?.map((cntrSize) => ({
                    dropdownLabel: cntrSize.parameterDtlCode,
                    tagLabel: cntrSize.parameterDtlCode,
                    value: cntrSize.parameterDtlCode,
                })) ?? []

                ediItemCodeMappingMainDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        cntrSizeDropdownOptions: sizeDropdownOption
                    }
                }))
            }
        )

        await companyRepo.getAllCompanyForCombobox().then(
            companies => {
                let companyCodeDropdownOption = companies?.map((company) => ({
                    dropdownLabel: company.companyCode,
                    tagLabel: company.companyCode,
                    value: company.companyCode,
                })) ?? []
                companyCodeDropdownOption = _.orderBy(companyCodeDropdownOption, "dropdownLabel");

                ediItemCodeMappingMainDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        companyCodeDropdownOptions: companyCodeDropdownOption,
                    }
                }))
            }
        )

        await tariffTypeRepo.getAllActiveTariffTypes().then(
            tariffs => {
                let newTariffs = _.orderBy(tariffs, ["tariffType"]);
                let tariffTypeDropdownOption = newTariffs?.map((tariff) => ({
                    dropdownLabel: tariff.tariffType,
                    tagLabel: tariff.tariffType,
                    value: tariff.tariffType,
                })) ?? []
                ediItemCodeMappingMainDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        tariffTypeDropdownOptions: tariffTypeDropdownOption,
                    }
                }))
            }
        );


        await standardTariffCodeRepo.getAllStandardTariffCodes().then(
            tariffs => {

                let newTariffs = _.orderBy(tariffs, ["tariffType", "tariffCode"]);
                // let tariffTypeDropdownOptions: DropdownProps[] = [];
                let tariffCodeDropdownOptions: { [key: string]: DropdownProps[] } = {};
                // let allTariffCodeeDropdownOptions: DropdownProps[] = [];

                newTariffs.forEach(tariff => {
                    // const isTariffTypeExisted = tariffTypeDropdownOptions.find(t =>
                    //     t.value === tariff.tariffType);
                    // if (!isTariffTypeExisted) {
                    //     tariffTypeDropdownOptions.push({
                    //         dropdownLabel: tariff.tariffType,
                    //         tagLabel: tariff.tariffType,
                    //         value: tariff.tariffType,
                    //     })
                    // }

                    // const isTariffCodeExisted = allTariffCodeeDropdownOptions.find(t =>
                    //     t.value === tariff.tariffCode);

                    // if (!isTariffCodeExisted) {
                    //     allTariffCodeeDropdownOptions.push({
                    //         dropdownLabel: tariff.tariffCode,
                    //         tagLabel: tariff.tariffCode,
                    //         value: tariff.tariffCode,
                    //     })
                    // }

                    if (tariff.tariffCode) {
                        if (!tariffCodeDropdownOptions[tariff.tariffType]) {
                            tariffCodeDropdownOptions[tariff.tariffType] = [];
                        }
                        tariffCodeDropdownOptions[tariff.tariffType].push({
                            dropdownLabel: tariff.tariffCode,
                            tagLabel: tariff.tariffCode,
                            value: tariff.tariffCode,
                        })

                    }
                })

                ediItemCodeMappingMainDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        // tariffTypeDropdownOptions: tariffTypeDropdownOptions,
                        tariffCodeDropdownOptions: tariffCodeDropdownOptions,
                    }
                }))
            }
        )

    }

    const onSearch = async () => {
        /*ediItemCodeMappingMainDispatch(prevState => ({
            ...prevState,
            isShowDtl: false,
            tableData: [],
        }))*/
        await repo.getEntities().then((entities) => {
            ediItemCodeMappingMainDispatch(prevState => ({
                ...prevState,
                tableData: _.sortBy(entities, ["priority"]),
                selectedRows: [],
                currentSelectedRow: EMPTY_EDI_ITEM_CODE_MAPPING_ENTITY,
                isShowDtl: false,
                // isBackMaster: false,
            }))

        })

    }

    const updateSelectedRows = async (selecedRows: EdiItemCodeMappingEntity[]) => {
        ediItemCodeMappingMainDispatch(prevState => {

            return {
                ...prevState,
                selectedRows: selecedRows,
                forceRefresh: !prevState.forceRefresh
            }
        })
    }

    const onAdd = () => {
        ediItemCodeMappingMainDispatch(prevState => {

            return {
                ...prevState,
                isShowDtl: true,
                masterState: {
                    ...prevState.masterState,
                    isAdd: true,
                    isEditable: false,
                    isRead: false,
                    editingEntity: EMPTY_EDI_ITEM_CODE_MAPPING_ENTITY,
                }
            }
        })
    }

    const onEdit = (currentEntity: EdiItemCodeMappingEntity) => {
        ediItemCodeMappingMainDispatch(prevState => {
            return {
                ...prevState,
                isShowDtl: true,
                currentSelectedRow: currentEntity,
                masterState: {
                    ...prevState.masterState,
                    isAdd: false,
                    isEditable: false,
                    isRead: false,
                    editingEntity: {
                        ...currentEntity
                    },
                }
            }
        })
    }

    const onReset = () => {
        ediItemCodeMappingMainDispatch(prevState => {
            const resetEntity = prevState.masterState.isAdd ? EMPTY_EDI_ITEM_CODE_MAPPING_ENTITY : prevState.currentSelectedRow;
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    editingEntity: {
                        ...resetEntity
                    },
                }
            }
        })
    }

    const onClose = () => {
        ediItemCodeMappingMainDispatch(prevState => {
            return {
                ...prevState,
                isShowDtl: false,
                currentSelectedRow: EMPTY_EDI_ITEM_CODE_MAPPING_ENTITY,
                selectedRows: [],
                masterState: {
                    ...prevState.masterState,
                    isAdd: false,
                    isEditable: false,
                    isRead: true,
                    editingEntity: EMPTY_EDI_ITEM_CODE_MAPPING_ENTITY,
                    allFormState: {}
                },
                // isBackMaster: true,
            }
        })
    }

    const onSaveClicked = () => {
        ediItemCodeMappingMainDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    isSaveClicked: true,
                    allFormState: {},
                }
            }
        })
    }

    const onSave = async (currentEntity: EdiItemCodeMappingEntity, isAdd: boolean) => {
        const valResult = await Validation(createEdiItemCodeMappingMaintenanceValidationSchema).ValidateFormOnly(currentEntity);
        let validatedResult: { [x: string]: string } = {};
        if (valResult) {
            validatedResult = { ...valResult, warningMessage: 'Please input the missing value.' };

            ediItemCodeMappingMainDispatch(prevState => {
                return {
                    ...prevState,
                    masterState: {
                        ...prevState.masterState,
                        allFormState: {
                            ...validatedResult
                        },
                    }
                }
            });

            const res: ResponseEntity = {
                code: "",
                success: false,
                msg: null,
                data: 'Please input the missing value.'
            }

            return res;
        }


        // check duplicated
        if (currentEntity.itemCodeType === DocumentConst.ediItemCodeTypePolicy.RECOMMENDATION23) {
            const checkdResponse = await repo.checkDuplicated(currentEntity);
            if (checkdResponse && !checkdResponse.success) {
                return checkdResponse;
            } else if (!checkdResponse) {
                const res: ResponseEntity = {
                    code: "",
                    success: false,
                    msg: null,
                    data: 'Failed to check duplicated data.'
                }
                return res;
            }
        }
        if (isAdd) {
            return await repo.createEntity(currentEntity);
        } else {
            return await repo.updateEntity(currentEntity);
        }
    }

    const onCheckboxChange = (checked: boolean, fieldName: string) => {
        ediItemCodeMappingMainDispatch(prevState => ({
            ...prevState,
            masterState: {
                ...prevState.masterState,
                editingEntity: {
                    ...prevState.masterState.editingEntity,
                    [fieldName]: checked ? "Y" : "N",
                }
            }
        }))
    }

    const onFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        if (fieldKey === 'ediItemCode') {
            val = val.toUpperCase().replace(/\s+/g, '');
        }

        ediItemCodeMappingMainDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    editingEntity: {
                        ...prevState.masterState.editingEntity,
                        [fieldKey]: val,
                    },
                    allFormState: {
                        ...prevState.masterState.allFormState,
                        [fieldKey]: '',
                    }
                }
            }
        })
    }

    const onItemCodeTypeChanged = () => {
        ediItemCodeMappingMainDispatch(prevState => {
            let cntrSize = prevState.masterState.editingEntity.cntrSize;
            let recommendation23Desc = prevState.masterState.editingEntity.recommendation23Desc;
            if (prevState.masterState.editingEntity.itemCodeType === DocumentConst.ediItemCodeTypePolicy.EDI_ITEM_CODE) {
                cntrSize = "";
                recommendation23Desc = "";
            }
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    editingEntity: {
                        ...prevState.masterState.editingEntity,
                        cntrSize: cntrSize,
                        recommendation23Desc: recommendation23Desc,
                    }
                }
            }
        })
    }

    const onApply = async (updatedRows: EdiItemCodeMappingEntity[]) => {

        return await repo.onApply(updatedRows);
    }

    const onEditClicked = () => {
        ediItemCodeMappingMainDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    isEditable: true,
                }
            }
        })
    }

    return {
        onEditClicked: onEditClicked,
        onApply: onApply,
        loadDropdownOption: loadDropdownOption,
        updateSelectedRows: updateSelectedRows,
        onAdd: onAdd,
        onEdit: onEdit,
        onReset: onReset,
        onClose: onClose,
        onSearch: onSearch,
        onSaveClicked: onSaveClicked,
        onSave: onSave,
        onCheckboxChange: onCheckboxChange,
        onFieldChange: onFieldChange,
        onItemCodeTypeChanged: onItemCodeTypeChanged,
    }
}