import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { memo, useCallback, useMemo, useState } from "react";
import { DialogModal, FieldType, HPHButton, IconButton, IFieldValue, Loader } from "veronica-ui-component/dist/component/core";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useCreditNoteHeaderMaintenanceTracked } from "presentation/store/CreditNote/CreditNoteHeaderMaintenanceProvider";
import { useCreditNoteHeaderVM } from "presentation/hook/CreditNote/useCreditNoteHeaderVM";
import { CreditNoteConstant } from "presentation/constant/CreditNote/CreditNoteConstant";

const CreditNoteHeaderPrintModal: React.FC = () => {
    const [creditNoteHeaderState] = useCreditNoteHeaderMaintenanceTracked();
    const creditNoteHeaderVM = useCreditNoteHeaderVM();
    const messageBarVM = useMessageBarVM();

    const CRE_PRINT_CONSTANT = CreditNoteConstant.Print;
    const { isShowPrintModal, printCriteria, dynamicOptions } = creditNoteHeaderState;    
    const { billToCompDropdownOptions, printerDropdownOptions } = dynamicOptions;
    const [isLoading, setIsLoading] = useState(false);

    const handleReprint = useCallback(async () => {  
        if(!(printCriteria?.printQueueName)){
            messageBarVM.showError("Please select a Printer.", true);
            return;
        }      
        setIsLoading(true);
 
        creditNoteHeaderVM.onReprint(printCriteria).then((data) => {
            if (data && data["printReportFail"]) {
                messageBarVM.showError(data["printReportFail"], true);
                return;
            }

            messageBarVM.showSuccess("Report is sent to printers successfully.", true);
        }).catch((error) => {            
            setIsLoading(false);
            messageBarVM.showError(error.message)
        }).finally(() => {
            setIsLoading(false);
        });
    }, [creditNoteHeaderVM, messageBarVM, printCriteria]);
    
    const handlePreview = useCallback(async () => {
        if(!(printCriteria?.printQueueName)){
            messageBarVM.showError("Please select a Printer.", true);
            return;
        }
        setIsLoading(true);

        creditNoteHeaderVM.onPreview(printCriteria).then((data) => {
            if (data && data["printReportFail"]) {
                messageBarVM.showError(data["printReportFail"], true);
                return;
            }

            messageBarVM.showSuccess("Preview report successfully.", true);
        }).catch((error) => {
            setIsLoading(false);
            messageBarVM.showError(error.message)
        }).finally(() => {
            setIsLoading(false);
        });
    }, [creditNoteHeaderVM, messageBarVM, printCriteria]);

    const refreshModal = () => {
        if (document) {
            const myModal = document.getElementById("creditNoteHeaderPrintModal");
            if (myModal) {
                const myContent = document.querySelector(".p-dialog-content");
                if (myContent) {
                    (myContent as HTMLElement).style.overflow = "visible";
                }
            }
        }
    }

    const memoBillToCompany = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}>
            <NbisCommonField
                //errorMessages={allFormState}
                isReadOnly={false}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={printCriteria.billToCompany ?? ""}
                fieldValue={printCriteria.billToCompany ?? ""}
                fieldLabel={CRE_PRINT_CONSTANT.BILL_TO_COMPANY}
                //isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'billToCompany'}
                isShowMissingError={true}
                maxLength={10}
                options={billToCompDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteHeaderVM.onPrintCriteriaFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CRE_PRINT_CONSTANT.BILL_TO_COMPANY, billToCompDropdownOptions, creditNoteHeaderVM, printCriteria.billToCompany])

    const memoReqNoFrom = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}>
            <NbisCommonField
                //errorMessages={allFormState}
                isReadOnly={false}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={printCriteria.reqNoFrom ?? ""}
                fieldValue={printCriteria.reqNoFrom ?? ""}
                fieldLabel={CRE_PRINT_CONSTANT.REQ_NO_FROM}
                //isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'reqNoFrom'}
                isShowMissingError={true}
                maxLength={10}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteHeaderVM.onPrintCriteriaFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CRE_PRINT_CONSTANT.REQ_NO_FROM, creditNoteHeaderVM, printCriteria.reqNoFrom])

    const memoReqNoTo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}>
            <NbisCommonField
                //errorMessages={allFormState}
                isReadOnly={false}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={printCriteria.reqNoTo ?? ""}
                fieldValue={printCriteria.reqNoTo ?? ""}
                fieldLabel={CRE_PRINT_CONSTANT.REQ_NO_TO}
                //isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'reqNoTo'}
                isShowMissingError={true}
                maxLength={10}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteHeaderVM.onPrintCriteriaFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CRE_PRINT_CONSTANT.REQ_NO_TO, creditNoteHeaderVM, printCriteria.reqNoTo])

    const memoCreChgNoFrom = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}>
            <NbisCommonField
                isReadOnly={false}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={printCriteria.creditChargeNoFrm ?? ""}
                fieldValue={printCriteria.creditChargeNoFrm ?? ""}
                fieldLabel={CRE_PRINT_CONSTANT.CREDIT_CHARGE_NO_FROM}
                fieldType={FieldType.TEXT}
                fieldKey={'creditChargeNoFrm'}
                isShowMissingError={true}
                maxLength={12}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteHeaderVM.onPrintCriteriaFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CRE_PRINT_CONSTANT.CREDIT_CHARGE_NO_FROM, creditNoteHeaderVM, printCriteria.creditChargeNoFrm])

    const memoCreChgNoTo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}>
            <NbisCommonField
                isReadOnly={false}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={printCriteria.creditChargeNoTo ?? ""}
                fieldValue={printCriteria.creditChargeNoTo ?? ""}
                fieldLabel={CRE_PRINT_CONSTANT.CREDIT_CHARGE_NO_TO}
                fieldType={FieldType.TEXT}
                fieldKey={'creditChargeNoTo'}
                isShowMissingError={true}
                maxLength={12}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteHeaderVM.onPrintCriteriaFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CRE_PRINT_CONSTANT.CREDIT_CHARGE_NO_TO, creditNoteHeaderVM, printCriteria.creditChargeNoTo])
    
        const memoCreNoteNoFrom = useMemo(() =>
            <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}>
                <NbisCommonField
                    isReadOnly={false}
                    isShowOptional={false}
                    isMultipleDropDown={false}
                    readOnlyValue={printCriteria.creditNoteNoFrm ?? ""}
                    fieldValue={printCriteria.creditNoteNoFrm ?? ""}
                    fieldLabel={CRE_PRINT_CONSTANT.CREDIT_NOTE_NO_FROM}
                    fieldType={FieldType.TEXT}
                    fieldKey={'creditNoteNoFrm'}
                    isShowMissingError={true}
                    maxLength={12}
                    requiredFieldList={[]}
                    onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                        creditNoteHeaderVM.onPrintCriteriaFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
            </div>
            , [CRE_PRINT_CONSTANT.CREDIT_NOTE_NO_FROM, creditNoteHeaderVM, printCriteria.creditNoteNoFrm])
    
        const memoCreNoteNoTo = useMemo(() =>
            <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}>
                <NbisCommonField
                    isReadOnly={false}
                    isShowOptional={false}
                    isMultipleDropDown={false}
                    readOnlyValue={printCriteria.creditNoteNoTo ?? ""}
                    fieldValue={printCriteria.creditNoteNoTo ?? ""}
                    fieldLabel={CRE_PRINT_CONSTANT.CREDIT_NOTE_NO_TO}
                    fieldType={FieldType.TEXT}
                    fieldKey={'creditNoteNoTo'}
                    isShowMissingError={true}
                    maxLength={12}
                    requiredFieldList={[]}
                    onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                        creditNoteHeaderVM.onPrintCriteriaFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
            </div>
            , [CRE_PRINT_CONSTANT.CREDIT_NOTE_NO_TO, creditNoteHeaderVM, printCriteria.creditNoteNoTo])

    const memoPrinter = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}>
            <NbisCommonField
                //errorMessages={allFormState}
                isReadOnly={false}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={printCriteria.printQueueName ?? ""}
                fieldValue={printCriteria.printQueueName ?? ""}
                fieldLabel={CRE_PRINT_CONSTANT.PRINTER}
                //isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'printQueueName'}
                isShowMissingError={true}
                maxLength={10}
                options={printerDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteHeaderVM.onPrintCriteriaFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CRE_PRINT_CONSTANT.PRINTER, creditNoteHeaderVM, printCriteria.printQueueName, printerDropdownOptions])

    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;
    
    return <DialogModal style={{ minWidth: '32vw', minHeight: '45vh' }}
        appendTo='self'
        showButton={false}
        visible={isShowPrintModal}
        positions="center"
        resizable={false}
        header={
            <div className="im-charge-data-search-confirm-modal-header">
                <span className="name">{CRE_PRINT_CONSTANT.TITLE}</span>
                <IconButton fileName='Icon-cross' size="medium" onClick={creditNoteHeaderVM.onClosePrintModal} tooltipDisable={true} />
            </div>
        }
        dialogContent={
            <div className="im-charge-data-search-confirm-modal-content">

                <CriteriaItemContainer>
                    {memoBillToCompany}                    
                    {memoReqNoFrom}
                    {memoReqNoTo}
                </CriteriaItemContainer>

                <CriteriaItemContainer>
                    {memoPrinter}
                    {memoCreChgNoFrom}
                    {memoCreChgNoTo}
                </CriteriaItemContainer>

                <CriteriaItemContainer>                    
                    {memoCreNoteNoFrom}
                    {memoCreNoteNoTo}
                </CriteriaItemContainer>
            </div>
        }
        className="im-charge-data-search-confirm-modal"
        id="creditNoteHeaderPrintModal"
        onShow={refreshModal}
        footer={
            <div className="im-charge-data-search-confirm-modal-footer">
                <HPHButton label={WorkspaceConstant.Common.BUTTON_CANCEL} size={'Small'} theme={'Secondary'} onClick={creditNoteHeaderVM.onClosePrintModal} />
                <HPHButton label={"Preview"} size={'Small'} theme={'Secondary'} onClick={handlePreview} />
                <HPHButton label={WorkspaceConstant.Common.BUTTON_PRINT} size={'Small'} theme={'Primary'} onClick={handleReprint} />
            </div>
        }
    />;
};

export default memo(CreditNoteHeaderPrintModal);