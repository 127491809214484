import { EmailTemplateMaintenanceConstant } from "presentation/constant/EmailTemplate/EmailTemplateMaintenanceConstant";
import { useEmailTemplateMaintenanceVM } from "presentation/hook/EmailTemplate/useEmailTemplateMaintenanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useEmailTemplateMaintenanceTracked } from "presentation/store/EmailTemplate/EmailTemplateMaintenanceProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { NbisIconButton } from "presentation/view/components/NbisIconButton";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FieldType, HPHButton, HPHCheckbox, IFieldValue, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarActionCross, SidebarCaption, Sidebarheader, SidebarTitle } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

export const EmailTemplateMaintenanceFormPanel = () => {
    const [emailTemplateState] = useEmailTemplateMaintenanceTracked();
    const emailTemplateVM = useEmailTemplateMaintenanceVM();
    const { currentSelectedRow, masterState } = emailTemplateState;
    const { editingEntity, isAdd, isEditable, isRead, isSaveClicked, allFormState } = masterState;
    const SCREEN_CONSTANT = EmailTemplateMaintenanceConstant;
    const currentEntity = isRead ? currentSelectedRow : editingEntity;

    const [subject, setSubject] = useState<string>();
    const [content, setContent] = useState<string>();
    const [isLoading, setIsLoading] = useState(false);
    const messageBarVM = useMessageBarVM();

    const handleToSubject = () => {
        setSubject(subject + '%' + currentEntity.variable + '%');
        emailTemplateVM.onFieldChange('subject', subject + '%' + currentEntity.variable + '%');
    }

    const handleToContent = () => {
        setContent(content + '%' + currentEntity.variable + '%');
        emailTemplateVM.onFieldChange('content', content + '%' + currentEntity.variable + '%');
    }

    const handleEdit = useCallback(() => {
        emailTemplateVM.onEdit(currentEntity);
    }, [emailTemplateVM, currentEntity])

    const handleCancelClick = () => {
        emailTemplateVM.onCancel();
    }

    const handleSaveClick = useCallback(async () => {
        setIsLoading(true);
        try {
            emailTemplateVM.onSaveClicked();
            const res = await emailTemplateVM.onSave(currentEntity, isAdd);
            if (!!!res || !res.success) {
                messageBarVM.showError(res?.data ?? 'Save data failed.');
            } else {
                emailTemplateVM.onClose();
                setIsLoading(true);
            }
        } catch (error) {

        } finally {
            setIsLoading(false)
        }

    }, [currentEntity, messageBarVM, emailTemplateVM, isAdd]);

    useEffect(() => {
        setSubject(currentEntity.subject?.toString());
        setContent(currentEntity.content?.toString());
    }, [currentEntity])

    const memoCustCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "330px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentEntity?.customerCode || ''}
                fieldValue={currentEntity?.customerCode || ''}
                fieldLabel={SCREEN_CONSTANT.CUST_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'customerCode'}
                maxLength={10}
                options={emailTemplateState.dynamicOptions.customerCodeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    emailTemplateVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentEntity?.customerCode, SCREEN_CONSTANT.CUST_CODE, isSaveClicked, emailTemplateState.dynamicOptions.customerCodeDropdownOptions, emailTemplateVM])

    const memoSubject = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "330px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={false}
                readOnlyValue={subject || ''}
                fieldValue={subject}
                fieldLabel={SCREEN_CONSTANT.SUBJECT}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXTAREA}
                fieldKey={'subject'}
                isShowMissingError={true}
                maxLength={500}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    emailTemplateVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, subject, SCREEN_CONSTANT.SUBJECT, isSaveClicked, emailTemplateVM])

    const memoContent = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "330px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={false}
                readOnlyValue={content || ''}
                fieldValue={content}
                fieldLabel={SCREEN_CONSTANT.CONTENT}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXTAREA}
                fieldKey={'content'}
                isShowMissingError={true}
                maxLength={1000}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    emailTemplateVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, content, SCREEN_CONSTANT.CONTENT, isSaveClicked, emailTemplateVM])

    const memoVar = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "330px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={false}
                readOnlyValue={currentEntity?.variable || ''}
                fieldValue={currentEntity?.variable}
                fieldLabel={SCREEN_CONSTANT.VARIABLE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'variable'}
                disableInput={true}
                isShowMissingError={true}                
                helpTextElement={"Add the pre-defined variable to the Subject or Content"}
                options={emailTemplateState.dynamicOptions.variableDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    emailTemplateVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentEntity?.variable, SCREEN_CONSTANT.VARIABLE, isSaveClicked, emailTemplateState.dynamicOptions.variableDropdownOptions, emailTemplateVM])

    const memoActiveInd = useMemo(() =>
        <>
            <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}>

                <NbisCommonField
                    fieldKey={"activeInd"}
                    requiredFieldList={[]}
                    fieldLabel={SCREEN_CONSTANT.ACTIVE_IND}
                    fieldType={FieldType.TEXT}
                    isReadOnly={true}
                    onFieldChange={() => { }} />
                <HPHCheckbox
                    label={""}
                    checked={currentEntity?.activeInd === 'Y'}
                    disabled={isRead}
                    onChange={(e) => emailTemplateVM.onCheckboxChange(e.checked, "activeInd")}
                />
            </div>
        </>
        , [SCREEN_CONSTANT.ACTIVE_IND, currentEntity?.activeInd, isRead, emailTemplateVM])


    return <div className='side-form-content-wrapper' style={{ width: '33vw', height: "99vh" }}>
        <div className={'flex-row'}>
            <div className={'flex-row-item flex-row-item-full-width'}>
                <Sidebarheader>
                    <SidebarTitle>
                        <SidebarTitle>{!isAdd ? "UPDATE TEMPLATE" : "NEW TEMPLATE"}</SidebarTitle>
                        {(isAdd || (isEditable && !isRead)) && <SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>}
                    </SidebarTitle>
                    <SidebarActionCross>
                        {(!isAdd && (isEditable && isRead)) &&
                            <>
                                {
                                    (
                                        (isEditable)
                                    )
                                    && <NbisIconButton fileName='Icon-pen' size='medium' toolTipText={'Edit'} toolTipArrow={false} onClick={handleEdit} disabled={false} />
                                }
                            </>}
                    </SidebarActionCross>
                </Sidebarheader>
            </div>
        </div>
        {isLoading && <Loader Indicator="Spinner" size="Medium" />}
        <div className={'add-edit-form'} style={{ height: '87vh', maxHeight: '87vh', overflow: 'auto' }}>
            <div className="flex-row-auto">
                <div className={'flex-row-item'} style={{ height: '79vh', maxHeight: '79vh' }}>
                    <CriteriaItemContainer>
                        {memoCustCode}
                    </CriteriaItemContainer>
                    <CriteriaItemContainer>
                        {memoSubject}
                    </CriteriaItemContainer>
                    <CriteriaItemContainer>
                        {memoContent}
                    </CriteriaItemContainer>
                    <CriteriaItemContainer>
                        {memoVar}
                        <HPHButton label={SCREEN_CONSTANT.TO_SUBJECT} disabled={isRead} size={'Small'} theme={'Secondary'} onClick={handleToSubject} />
                        <HPHButton label={SCREEN_CONSTANT.TO_CONTENT} disabled={isRead} size={'Small'} theme={'Secondary'} onClick={handleToContent} />
                    </CriteriaItemContainer>
                    <CriteriaItemContainer>
                        {memoActiveInd}
                    </CriteriaItemContainer>

                </div>
            </div>
            <div className='im-charge-data-search-criteria-buttons' >
                {
                    <>
                        <HPHButton label={isRead ? 'Close' : 'Cancel'} size={'Small'} theme={'Secondary'} onClick={handleCancelClick} />
                        {!isRead && <HPHButton label={isAdd ? 'Add' : "Update"} size={'Small'} theme={'Primary'} onClick={() => handleSaveClick()} />}
                    </>
                }
            </div>
        </div>
    </div>
}
