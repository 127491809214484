import { StandardProposalConstant } from "presentation/constant/StandardProposal/StandardProposalConstant";
import { useStandardProposalVM } from "presentation/hook/StandardProposal/useStandardProposalVM";
import { useStandardProposalTracked } from "presentation/store/StandardProposal/StandardProposalProvider";
import { useCallback, useState } from "react";
import { HPHButton, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarActionBar, SidebarActionCross, Sidebarheader, SidebarTitle } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import StandardProposalSearchForm from "./StandardProposalSearchForm";

const StandardProposalSearchPanel = () => {
    const STANDARD_PROPOSAL_CONSTANT = StandardProposalConstant.Search;
    const [standardProposalState] = useStandardProposalTracked();
    const standardProposalVM = useStandardProposalVM();
    const { searchCriteria } = standardProposalState;
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const resetButtonClicked = useCallback(() => {
        standardProposalVM.onSearchCriteriaResetClick();
    }, [standardProposalVM]);

    const searchButtonClicked = useCallback(() => {

        setIsLoading(true);
        standardProposalVM.searchStandardProposal(searchCriteria).then((data) => {
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
        })
    }, [searchCriteria, standardProposalVM])

    return (
        <div className='side-form-content-left-wrapper'>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>{STANDARD_PROPOSAL_CONSTANT.SEARCH_CRITERIA}</SidebarTitle>
                        <SidebarActionCross>
                            <IconButton
                                fileName="Icon-cross" size="medium" toolTipText='Close' toolTipArrow={false} onClick={standardProposalVM.onSearchClick} />
                        </SidebarActionCross>
                    </Sidebarheader>
                </div>
            </div>

            {isLoading && <Loader Indicator="Spinner" size="Medium" />}

            <div className={'add-edit-form'} style={{ maxHeight: '75vh', height: '75vh', overflow: 'auto' }}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>

                        {/* Stack Form Start */}
                        <StandardProposalSearchForm />
                    </div>
                </div>
            </div>
            <SidebarActionBar>
                <HPHButton label={'Reset'} size={'Small'} theme={'Secondary'} onClick={resetButtonClicked} />
                <HPHButton label={'Search'} size={'Small'} theme={'Primary'} onClick={() => searchButtonClicked()} />
            </SidebarActionBar>
        </div>
    );
}

export default StandardProposalSearchPanel;
