import { DocumentIssuanceConstant } from "presentation/constant/DocumentIssuance/DocumentIssuanceConstant";
import { useDocumentIssuanceVM } from "presentation/hook/DocumentIssuance/useDocumentIssuanceVM";
import { useDocumentIssuanceTracked } from "presentation/store/DocumentIssuance/DocumentIssuanceProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import NumberInputComponent from "presentation/view/components/NumberInputComponent";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FieldType, HPHCheckbox, IFieldValue, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarCaption, StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import DocumentIssuanceEditPanelTitleBar from "../DocumentIssuanceEditPanelTitleBar";

export const DocumentIssuanceHeaderFormPanel = () => {
    const [documentIssuanceState] = useDocumentIssuanceTracked();
    const documentIssuanceVM = useDocumentIssuanceVM();
    const { currentSelectedRow, masterState, dynamicOptions } = documentIssuanceState;
    const { editingEntity, isAdd, isEditable, isRead, isSaveClicked, allFormState } = masterState;
    const SCREEN_CONSTANT = DocumentIssuanceConstant;

    const currentEntity = isRead ? currentSelectedRow : editingEntity;
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isAdd || isEditable) {
            const initialScreen = async () => {
                setIsLoading(true);
                try {
                    const results = await Promise.allSettled([
                        documentIssuanceVM.loadDropdownOption(),
                    ]);
                    results.forEach((result, index) => {
                        if (index === 0 && result.status === 'fulfilled') {

                        }
                    })
                } catch (error) {
                    setIsLoading(false);
                }
            }
            initialScreen().then(async () => {
                setIsLoading(false)
            }).catch(error => {
                setIsLoading(false)
            });
        }

    }, [documentIssuanceVM, isAdd, isEditable])

    const onNumberFieldChange = useCallback((e: any, fieldName: string, fieldValue: any) => {
        documentIssuanceVM.onFieldChange(fieldName, fieldValue);
    }, [documentIssuanceVM]);

    const memoBillToCompany = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!isAdd}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={/*currentEntity?.billToCompany?.companyCode ?? ''*/
                    dynamicOptions.billToCompanyDropdownOptions?.find(item => Number(item.tagLabel) === currentEntity?.billToCompanyId)?.value ?? ""
                }
                fieldValue={/*currentEntity?.billToCompany?.companyCode ?? ''*/
                    dynamicOptions.billToCompanyDropdownOptions?.find(item => Number(item.tagLabel) === currentEntity?.billToCompanyId)?.value ?? ""
                }
                fieldLabel={SCREEN_CONSTANT.BILL_TO_COMPANY}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'billToCompanyId'}
                isShowMissingError={true}
                maxLength={10}
                options={dynamicOptions.billToCompanyDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    documentIssuanceVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isAdd, dynamicOptions.billToCompanyDropdownOptions, SCREEN_CONSTANT.BILL_TO_COMPANY, isSaveClicked, currentEntity?.billToCompanyId, documentIssuanceVM])

    const memoCustomerCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!isAdd}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={/*currentEntity?.customer?.customerCode ?? ''*/
                    dynamicOptions.customerCodeDropdownOptions?.find(item => Number(item.tagLabel) === currentEntity?.customerId)?.value ?? ""
                }
                fieldValue={/*currentEntity?.customer?.customerCode ?? ''*/
                    dynamicOptions.customerCodeDropdownOptions?.find(item => Number(item.tagLabel) === currentEntity?.customerId)?.value ?? ""
                }
                fieldLabel={SCREEN_CONSTANT.CUSTOMER_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'customerId'}
                isShowMissingError={true}
                maxLength={20}
                options={dynamicOptions.customerCodeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    documentIssuanceVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isAdd, dynamicOptions.customerCodeDropdownOptions, SCREEN_CONSTANT.CUSTOMER_CODE, isSaveClicked, currentEntity?.customerId, documentIssuanceVM])

    const memoChargeType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "220px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!isAdd}
                isShowOptional={true}
                isMultipleDropDown={false}
                readOnlyValue={currentEntity?.chargeType ?? ''}
                fieldValue={currentEntity?.chargeType ?? ''}
                fieldLabel={SCREEN_CONSTANT.CHARGE_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'chargeType'}
                isShowMissingError={true}
                maxLength={10}
                options={dynamicOptions.chargeTypeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    documentIssuanceVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isAdd, currentEntity?.chargeType, SCREEN_CONSTANT.CHARGE_TYPE, isSaveClicked, dynamicOptions.chargeTypeDropdownOptions, documentIssuanceVM])

    const memoIssueType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "430px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={false}
                isMultipleDropDown={true}
                readOnlyValue={currentEntity?.issueType ?? ""}
                fieldValue={currentEntity?.issueType?.toString().split(";").filter(item => item.trim() !== '') ?? ''}
                fieldLabel={SCREEN_CONSTANT.ISSUE_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'issueType'}
                isShowMissingError={true}
                maxLength={200}
                options={dynamicOptions.issueTypeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    documentIssuanceVM.onFieldChange(fieldKey, fieldValue && fieldValue as string[], fFullValue)} />
        </div>
        , [allFormState, isRead, currentEntity?.issueType, SCREEN_CONSTANT.ISSUE_TYPE, isSaveClicked, dynamicOptions.issueTypeDropdownOptions, documentIssuanceVM])

    const memoDocType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!isAdd}
                isShowOptional={true}
                isMultipleDropDown={false}
                readOnlyValue={currentEntity?.docType ?? ''}
                fieldValue={currentEntity?.docType ?? ''}
                fieldLabel={SCREEN_CONSTANT.DOC_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'docType'}
                isShowMissingError={true}
                maxLength={10}
                options={dynamicOptions.docTypeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    documentIssuanceVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isAdd, currentEntity?.docType, SCREEN_CONSTANT.DOC_TYPE, isSaveClicked, dynamicOptions.docTypeDropdownOptions, documentIssuanceVM])

    const memoNoOfCopies = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>

            <NumberInputComponent
                label={SCREEN_CONSTANT.NO_OF_COPIES}
                maxLength={60}
                disabled={isRead}
                width='180px'
                fieldName={"noOfCopy"}
                value={currentEntity?.noOfCopy ?? ''}
                errorMessage={allFormState?.noOfCopy}
                //decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
        </div>
        , [SCREEN_CONSTANT.NO_OF_COPIES, isRead, currentEntity?.noOfCopy, allFormState?.noOfCopy, onNumberFieldChange])

    const memoNoOfOriginal = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>

            <NumberInputComponent
                label={SCREEN_CONSTANT.NO_OF_ORIGINAL}
                maxLength={60}
                disabled={isRead}
                width='180px'
                fieldName={"noOfOriginal"}
                value={currentEntity?.noOfOriginal ?? ''}
                errorMessage={allFormState?.noOfOriginal}
                //decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
        </div>
        , [SCREEN_CONSTANT.NO_OF_ORIGINAL, isRead, currentEntity?.noOfOriginal, allFormState?.noOfOriginal, onNumberFieldChange])

    const memoNoOfSupportingDoc = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "220px", marginBottom: "24px" }}>

            <NumberInputComponent
                label={SCREEN_CONSTANT.NO_OF_SUPPORTING_DOC}
                maxLength={60}
                disabled={isRead}
                width='220px'
                optional={true}
                fieldName={"noOfSupportingDoc"}
                value={currentEntity?.noOfSupportingDoc ?? ''}
                errorMessage={allFormState?.noOfSupportingDoc}
                //decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
        </div>
        , [SCREEN_CONSTANT.NO_OF_SUPPORTING_DOC, isRead, currentEntity?.noOfSupportingDoc, allFormState?.noOfSupportingDoc, onNumberFieldChange])

    const memoAsteriskLabel = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                isMultipleDropDown={false}
                readOnlyValue={currentEntity?.asteriskLabel ?? ''}
                fieldValue={currentEntity?.asteriskLabel ?? ''}
                fieldLabel={SCREEN_CONSTANT.ASTERISK_LABEL}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'asteriskLabel'}
                isShowMissingError={true}
                maxLength={200}
                options={dynamicOptions.indDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    documentIssuanceVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentEntity?.asteriskLabel, SCREEN_CONSTANT.ASTERISK_LABEL, isSaveClicked, dynamicOptions.indDropdownOptions, documentIssuanceVM])

    const memoLanguage = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={currentEntity?.docLanguage ?? ''}
                fieldValue={currentEntity?.docLanguage ?? ''}
                fieldLabel={SCREEN_CONSTANT.LANGUAGE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'docLanguage'}
                isShowMissingError={true}
                maxLength={10}
                options={dynamicOptions.languageDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    documentIssuanceVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentEntity?.docLanguage, SCREEN_CONSTANT.LANGUAGE, isSaveClicked, dynamicOptions.languageDropdownOptions, documentIssuanceVM])

    const memoAmountPrinting = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "220px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={currentEntity?.amountPrinting ?? ''}
                fieldValue={currentEntity?.amountPrinting ?? ''}
                fieldLabel={SCREEN_CONSTANT.AMOUNT_PRINTING}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'amountPrinting'}
                isShowMissingError={true}
                maxLength={200}
                options={dynamicOptions.amountPrintingDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    documentIssuanceVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentEntity?.amountPrinting, SCREEN_CONSTANT.AMOUNT_PRINTING, isSaveClicked, dynamicOptions.amountPrintingDropdownOptions, documentIssuanceVM])

    const memoDisplayBillingMsg = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={currentEntity?.displayBillingMsg ?? ''}
                fieldValue={currentEntity?.displayBillingMsg ?? ''}
                fieldLabel={SCREEN_CONSTANT.DISPLAY_BILLING_MSG}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'displayBillingMsg'}
                isShowMissingError={true}
                maxLength={200}
                options={dynamicOptions.indDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    documentIssuanceVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentEntity?.displayBillingMsg, SCREEN_CONSTANT.DISPLAY_BILLING_MSG, isSaveClicked, dynamicOptions.indDropdownOptions, documentIssuanceVM])

    const memoDisplay2ndCurrency = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "220px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={currentEntity?.display2ndCurrency ?? ''}
                fieldValue={currentEntity?.display2ndCurrency ?? ''}
                fieldLabel={SCREEN_CONSTANT.DISPLAY_2ND_CURRENCY}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'display2ndCurrency'}
                isShowMissingError={true}
                maxLength={10}
                options={dynamicOptions.indDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    documentIssuanceVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentEntity?.display2ndCurrency, SCREEN_CONSTANT.DISPLAY_2ND_CURRENCY, isSaveClicked, dynamicOptions.indDropdownOptions, documentIssuanceVM])

    const memoEmailAddress = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                isMultipleDropDown={false}
                readOnlyValue={currentEntity?.emailAddress ?? ''}
                fieldValue={currentEntity?.emailAddress ?? ''}
                fieldLabel={SCREEN_CONSTANT.EMAIL_ADDRESS}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'emailAddress'}
                isShowMissingError={true}
                maxLength={200}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    documentIssuanceVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentEntity?.emailAddress, SCREEN_CONSTANT.EMAIL_ADDRESS, isSaveClicked, documentIssuanceVM])

    const memoCc = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                isMultipleDropDown={false}
                readOnlyValue={currentEntity?.copyTo ?? ''}
                fieldValue={currentEntity?.copyTo ?? ''}
                fieldLabel={SCREEN_CONSTANT.CC}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'copyTo'}
                isShowMissingError={true}
                maxLength={200}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    documentIssuanceVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentEntity?.copyTo, SCREEN_CONSTANT.CC, isSaveClicked, documentIssuanceVM])

    const memoEdiEmailAddress = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "220px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                isMultipleDropDown={false}
                readOnlyValue={currentEntity?.ediEmailAddress ?? ''}
                fieldValue={currentEntity?.ediEmailAddress ?? ''}
                fieldLabel={SCREEN_CONSTANT.EDI_EMAIL_ADDRESS}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'ediEmailAddress'}
                isShowMissingError={true}
                maxLength={200}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    documentIssuanceVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentEntity?.ediEmailAddress, SCREEN_CONSTANT.EDI_EMAIL_ADDRESS, isSaveClicked, documentIssuanceVM])

    const memoReportTemplate = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "390px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={dynamicOptions.reportTemplateDropdownOptions?.find(item => item.tagLabel === currentEntity?.reportId)?.value ?? ""}
                fieldValue={dynamicOptions.reportTemplateDropdownOptions?.find(item => item.tagLabel === currentEntity?.reportId)?.value ?? ""}
                fieldLabel={SCREEN_CONSTANT.REPORT_TEMPLATE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'reportId'}
                isShowMissingError={true}
                maxLength={200}
                options={dynamicOptions.reportTemplateDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    documentIssuanceVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentEntity?.reportId, SCREEN_CONSTANT.REPORT_TEMPLATE, isSaveClicked, dynamicOptions.reportTemplateDropdownOptions, documentIssuanceVM])

    const memoInvoiceCurrency = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={currentEntity?.invoiceCurrency ?? ''}
                fieldValue={currentEntity?.invoiceCurrency ?? ''}
                fieldLabel={SCREEN_CONSTANT.INVOICE_CURRENCY}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'invoiceCurrency'}
                isShowMissingError={true}
                maxLength={10}
                options={dynamicOptions.invoiceCurrencyDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    documentIssuanceVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentEntity?.invoiceCurrency, SCREEN_CONSTANT.INVOICE_CURRENCY, isSaveClicked, dynamicOptions.invoiceCurrencyDropdownOptions, documentIssuanceVM])


    const memoActiveInd = useMemo(() =>
        <>
            <div className='im-flex-row-item' style={{ width: "220px", marginBottom: "24px" }}>

                <NbisCommonField
                    fieldKey={"activeInd"}
                    requiredFieldList={[]}
                    fieldLabel={SCREEN_CONSTANT.ACTIVE_IND}
                    fieldType={FieldType.TEXT}
                    isReadOnly={true}
                    onFieldChange={() => { }} />
                <HPHCheckbox
                    label={""}
                    checked={currentEntity?.activeInd === "N" ? false : true}
                    disabled={isRead}
                    onChange={(e) => documentIssuanceVM.onCheckboxChange(e.checked, "activeInd")}
                />
            </div>
        </>
        , [SCREEN_CONSTANT.ACTIVE_IND, currentEntity?.activeInd, isRead, documentIssuanceVM])

    const memoEditPanelTitleBar = useMemo(() => {
        return <DocumentIssuanceEditPanelTitleBar />
    }, [])

    //if (isLoading) return <Loader Indicator="Stripe" size="Large" />;
    return <StyledSidebar style={{ width: '100%', height: "100%", border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'>
        {/* Header part of add or edit record */}
        {memoEditPanelTitleBar}
        {isLoading && <Loader Indicator="Spinner" size="Medium" />}
        {/* Readable and Editable Form */}
        <div className={'add-edit-form'} style={{ height: 'calc(100vh - 125px)', maxHeight: 'calc(100vh - 125px)', overflow: 'auto' }}>
            <div className="flex-row-auto">
                <div className={'flex-row-item'}>
                    <div className={'im-flex-row'}>
                        <div className={'flex-row-item-stretch'}>
                            {/*<SidebarTitle>{!!currentEntity?.tariffCode?currentEntity?.tariffCode : "NEW TARIFF CODE"}</SidebarTitle>*/}

                            {(isAdd || (isEditable && !isRead)) && <SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>}
                        </div>
                    </div>

                    <CriteriaItemContainer>
                        {memoBillToCompany}
                        {memoCustomerCode}
                        {memoChargeType}
                    </CriteriaItemContainer>

                    <CriteriaItemContainer>
                        {memoDocType}
                        {memoIssueType}
                    </CriteriaItemContainer>

                    <CriteriaItemContainer>
                        {memoNoOfCopies}
                        {memoNoOfOriginal}
                        {memoNoOfSupportingDoc}
                    </CriteriaItemContainer>

                    <CriteriaItemContainer>
                        {memoInvoiceCurrency}
                        {memoLanguage}
                        {memoAmountPrinting}
                    </CriteriaItemContainer>

                    <CriteriaItemContainer>
                        {memoAsteriskLabel}
                        {memoDisplayBillingMsg}
                        {memoDisplay2ndCurrency}
                    </CriteriaItemContainer>

                    <CriteriaItemContainer>
                        {memoEmailAddress}
                        {memoCc}
                        {memoEdiEmailAddress}
                    </CriteriaItemContainer>

                    <CriteriaItemContainer>
                        {memoReportTemplate}
                        {memoActiveInd}
                    </CriteriaItemContainer>

                </div>
            </div>
        </div>
        {/* Readable and Editable Form */}
    </StyledSidebar>
}