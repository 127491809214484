import { EmptyPoolStorageGenConstant } from "presentation/constant/EmptyPoolStorageGeneration/EmptyPoolStorageGenConstant";
import { MessageConstant } from "presentation/constant/MessageConstant";
import { useEmptyPoolStorageGenVM } from "presentation/hook/EmptyPoolStorageGeneration/useEmptyPoolStorageGenVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useEmptyPoolStorageGenTracked } from "presentation/store/EmptyPoolStorageGeneration/EmptyPoolStorageGenProvider";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import { memo, useCallback, useState } from "react";
import { HPHButton, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const EmptyPoolStorageGenTitleBar: React.FC = () => {
    const emptyPoolStorageGenVM = useEmptyPoolStorageGenVM();
    const messageBarVM = useMessageBarVM();
    const [emptyPoolStorageGenState] = useEmptyPoolStorageGenTracked();
    const { allFormState, selectedEmptyPoolDatas } = emptyPoolStorageGenState;
    const EMP_POOL_STORAGE_GEN_CONSTANT = EmptyPoolStorageGenConstant.Charge;
    const [isLoading, setIsLoading] = useState(false);
    const [anaInfoState] = useANAInfoTracked();
    const { allowUpdate } = anaInfoState;

    const handleSearch = useCallback(() => {
        emptyPoolStorageGenVM.onSearchClick();
    }, [emptyPoolStorageGenVM]);

    const handleGenerate = useCallback(async () => {
        //setIsSave(true);
        if (selectedEmptyPoolDatas.length < 1) {
            messageBarVM.showError(MessageConstant.common.SELECT_A_RECORD_AT_LEAST);
            return;
        }

        if (selectedEmptyPoolDatas.some(selRow => !selRow.chargeOnCompany || selRow.chargeOnCompany.trim() === '' || !selRow.billToCompany || selRow.billToCompany.trim() === '')) {
            messageBarVM.showError('Charge On Company, Bill To Company is mandatory.')
            return;
        }

        setIsLoading(true);
        emptyPoolStorageGenVM.onGenerate(selectedEmptyPoolDatas).then((data) => {

            if (allFormState && allFormState["genEmptyPoolFail"]) {
                messageBarVM.showError(allFormState["genEmptyPoolFail"]?.toString());
            } else {
                emptyPoolStorageGenVM.onHideLoading();
                messageBarVM.showSuccess("Empty Pool is generated successfully.");

                emptyPoolStorageGenVM.searchEmptyPoolStorageDatas(emptyPoolStorageGenState.searchCriteria).then((data) => {
                    setIsLoading(false);
                }).catch(error => {
                    setIsLoading(false);
                })
            }
        }).finally(() => {
            emptyPoolStorageGenVM.onHideLoading();
        })
    }, [selectedEmptyPoolDatas, emptyPoolStorageGenVM, messageBarVM, allFormState, emptyPoolStorageGenState.searchCriteria]);

    const handleDeactivate = useCallback(async () => {

        if (selectedEmptyPoolDatas.length < 1) {
            messageBarVM.showError(MessageConstant.common.SELECT_A_RECORD_AT_LEAST);
            return;
        }

        if (selectedEmptyPoolDatas.some(selRow => !selRow.chargeOnCompany || selRow.chargeOnCompany.trim() === '' || !selRow.billToCompany || selRow.billToCompany.trim() === '')) {
            messageBarVM.showError('Charge On Company, Bill To Company is mandatory.')
            return;
        }

        setIsLoading(true);
        emptyPoolStorageGenVM.onDeactivate(selectedEmptyPoolDatas).then((data) => {
            emptyPoolStorageGenVM.onHideLoading();
            messageBarVM.showSuccess("Empty Pool is de-activated successfully.");

            emptyPoolStorageGenVM.searchEmptyPoolStorageDatas(emptyPoolStorageGenState.searchCriteria).then((data) => {
                setIsLoading(false);
            }).catch(error => {
                setIsLoading(false);
            })

        }).finally(() => {
            emptyPoolStorageGenVM.onHideLoading();
        })
    }, [selectedEmptyPoolDatas, emptyPoolStorageGenVM, messageBarVM, emptyPoolStorageGenState.searchCriteria]);


    const isDisable = () => {
        if (selectedEmptyPoolDatas && selectedEmptyPoolDatas.length > 0) {
            return false;
        }
        return true;
    }

    return <Sidebarheader style={{ width: '100%', display: "flex", alignItems: "center" }}>
        {isLoading && <Loader Indicator="spinner" size="Medium" />}
        <HeaderTitle>{EmptyPoolStorageGenConstant.title}</HeaderTitle>
        <StyledAction className="tm-animated-wrapper">
            <IconButton fileName='Icon-search' size='medium' toolTipText={'Search'} toolTipArrow={false} onClick={handleSearch} />
            {(allowUpdate) && <><div className="add-seperator" />
                <HPHButton label={EMP_POOL_STORAGE_GEN_CONSTANT.DEACTIVATE} disabled={isDisable()} size={'Small'} theme={'Secondary'} onClick={handleDeactivate} />
                <HPHButton label={EMP_POOL_STORAGE_GEN_CONSTANT.GENERATE} disabled={isDisable()} size={'Small'} theme={'Primary'} onClick={handleGenerate} /></>}
        </StyledAction>
        {emptyPoolStorageGenState.forceRefresh && <></>}
    </Sidebarheader>
}

export default memo(EmptyPoolStorageGenTitleBar);