import type { CustomCellRendererProps } from "ag-grid-react";
import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { RequisitionFormConstant } from "./RequisitionFormConstant";
import { DECIMAL_2 } from "presentation/utils/numberUtil";

const REQ_FORM_CONSTANT = RequisitionFormConstant;
let dateFieldList: string[] = [];
let dateTimeFieldList: string[] = [];
let numberFieldList: string[] = [];
export const INITIAL_REQUISITION_FORM_COL_DEF: any[] = [
    {
        headerName: REQ_FORM_CONSTANT.COMMON.STATE,
        field: 'state',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 120,
        pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
    },
    {
        headerName: REQ_FORM_CONSTANT.COMMON.REQ_NO,
        field: 'reqFormNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: REQ_FORM_CONSTANT.COMMON.REQ_MODE,
        field: 'reqMode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 120,
    },
    {
        headerName: REQ_FORM_CONSTANT.COMMON.BILL_TO_COMPANY,
        field: 'billToCompany',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: REQ_FORM_CONSTANT.COMMON.CUSTOMER_CODE,
        field: 'customerCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: REQ_FORM_CONSTANT.COMMON.CURRENCY,
        field: 'currencyCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 120,
    },
    {
        headerName: REQ_FORM_CONSTANT.Header.AMOUNT,
        field: 'amount',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: REQ_FORM_CONSTANT.COMMON.REQ_NATURE,
        field: 'natureDesc',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: REQ_FORM_CONSTANT.COMMON.SHORT_DESC,
        field: 'reqFormDesc',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: REQ_FORM_CONSTANT.Header.CREATED_BY,
        field: 'createdBy',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: REQ_FORM_CONSTANT.Header.CREATED_DATE_TIME,
        field: 'createdDateTime',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
        dataType: "dateTime"
    },
    {
        headerName: REQ_FORM_CONSTANT.COMMON.REVENUE_CTRL_IND,
        field: 'revenueControlInd',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: REQ_FORM_CONSTANT.Header.REF_REMARKS,
        field: 'reqRemarks',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: REQ_FORM_CONSTANT.Header.MANAGER_IC,
        field: 'managerIc',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
        dataType: "dateTime"
    },
    {
        headerName: REQ_FORM_CONSTANT.Header.AC_MGR,
        field: 'acMgr',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
        dataType: "dateTime"
    },    
    {
        headerName: REQ_FORM_CONSTANT.COMMON.FIN,
        field: 'fin',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
        dataType: "dateTime"
    },
    {
        headerName: REQ_FORM_CONSTANT.COMMON.COM,
        field: 'com',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
        dataType: "dateTime"
    },
    {
        headerName: REQ_FORM_CONSTANT.COMMON.MD,
        field: 'md',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
        dataType: "dateTime"
    },
    {
        headerName: REQ_FORM_CONSTANT.COMMON.GMD,
        field: 'gmd',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
        dataType: "dateTime"
    },    

].map((col, index) => {
    const cellRenderers: { [key: string]: ((params: CustomCellRendererProps) => {}) } = {};
    //cellRenderers['hdrState'] = cellRenderWithColorAndCircle;
    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers, numberFieldList);
}
);

export const transferRowData = (data: any[]) => {
    const externalFnctions: { [key: string]: ((fieldName: string, row: any) => {}) } = {};
    //externalFnctions['hdrState'] = calculateStateColor;
    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions, numberFieldList);
}