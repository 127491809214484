import { useANAInfoTracked } from 'presentation/store/ANAInfo';
import React, { useEffect, useState } from 'react';
import { IconButton, IconButtonsProps } from 'veronica-ui-component/dist/component/core';
export interface NbisIconButtonsProps extends IconButtonsProps {
  disabled: boolean | false;
  toolTipText: string;
}

export const NbisIconButton: React.FC<NbisIconButtonsProps> = (props: NbisIconButtonsProps) => {
  const { disabled, toolTipText, ...restProps } = props;
  const [anaInfoState] = useANAInfoTracked();
  const [disabledState, setDisabledState] = useState(true);

  useEffect(() => {
    if ("ADD" === toolTipText.toLocaleUpperCase()) {
      setDisabledState(anaInfoState.allowCreate ? disabled : true);
    } else if ("EDIT" === toolTipText.toLocaleUpperCase()) {
      setDisabledState(anaInfoState.allowUpdate ? disabled : true);
    } else if ("DELETE" === toolTipText.toLocaleUpperCase()) {
      setDisabledState(anaInfoState.allowDelete ? disabled : true);
    } else {
      setDisabledState(disabled);
    }
  }, [anaInfoState.allowCreate, anaInfoState.allowDelete, anaInfoState.allowUpdate, disabled, toolTipText]);


  return (
    /*disabledState ? <></> :*/ <IconButton
      {...restProps}
      disabled={disabledState}
      toolTipText={toolTipText}
    />
  );
};
