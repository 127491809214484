import { CompanyConstant } from "presentation/constant/Company/CompanyConstant";
import { MessageConstant } from "presentation/constant/MessageConstant";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useCompanyMaintenanceVM } from "presentation/hook/Company/useCompanyMaintenanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useCompanyMaintenanceTracked } from "presentation/store/Company/CompanyMaintenanceProvider";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import { useCallback, useMemo, useState } from "react";
import { HPHButton, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const CompanyTitleBar: React.FC = () => {
    const [companyState] = useCompanyMaintenanceTracked();
    const companyMaintenanceVM = useCompanyMaintenanceVM();
    const messageBarVM = useMessageBarVM();

    const [isLoading, setIsLoading] = useState(false);
    const { isAdd, isEditable } = companyState.viewState

    const [anainfoState] = useANAInfoTracked();
    const { allowCreate, allowUpdate, allowDelete } = anainfoState;

    const handleSearchClick = useCallback(() => {
        companyMaintenanceVM.onSearchClick();
    }, [companyMaintenanceVM]);

    const handleAdd = useCallback(() => {
        companyMaintenanceVM.onAdd();
    }, [companyMaintenanceVM]);

    const handleEdit = useCallback(() => {
        if (companyState.selectCompanyRows.length !== 1) {
            messageBarVM.showError(MessageConstant.common.SELECT_A_RECORD_AT_LEAST);
            companyMaintenanceVM.onCancel();
            return;
        }
        companyMaintenanceVM.onEdit();
    }, [companyMaintenanceVM, companyState.selectCompanyRows, messageBarVM]);

    const handleConfirm = useCallback(() => {
        companyMaintenanceVM.onConfirm(companyState.selectCompanyRows).then((data) => {
            if (data) {
                setIsLoading(false);
                companyMaintenanceVM.onSearch(companyState.searchCriteria).then(() => {
                    setIsLoading(false);
                }).catch((error) => {
                    setIsLoading(false);
                });
            }
        }).catch(() => {
            setIsLoading(false);
            messageBarVM.showError('Save Data Failed.');
        });

    }, [companyMaintenanceVM, companyState.searchCriteria, messageBarVM, companyState.selectCompanyRows]);

    const isDisable = useCallback(() => {
        const confirmedRows = companyState.selectCompanyRows?.filter(row => row.status === 'CONFIRMED');
        if (companyState.selectCompanyRows && companyState.selectCompanyRows.length > 0 && !(confirmedRows.length > 0)) {
            return false;
        }
        return true;
    }, [companyState.selectCompanyRows]);

    const getSelectedRows = useCallback(() => {
        return companyState.selectCompanyRows;
    }, [companyState.selectCompanyRows])


    const memoCompanyTitleBar = useMemo(() => {
        return <Sidebarheader style={{ width: '100%', display: "flex", alignItems: "center" }}>
            <HeaderTitle>{CompanyConstant.Header.COMPANY_MAIN}</HeaderTitle>
            <StyledAction className="tm-animated-wrapper">
                <IconButton fileName='Icon-search' disabled={isAdd || isEditable} size='medium' toolTipText={'Search'} toolTipArrow={false} onClick={handleSearchClick} />
                {(allowCreate) && <IconButton fileName='Icon-add' disabled={isAdd || isEditable} size='medium' toolTipText={'Add'} toolTipArrow={false} onClick={handleAdd} />}
                {(allowUpdate) && <IconButton fileName='Icon-pen' disabled={(isAdd || isEditable || getSelectedRows().length !== 1)} size='medium' toolTipText={'Edit'} toolTipArrow={false} onClick={handleEdit} />}
                {(allowDelete || allowUpdate) && <div className="add-seperator" />}

                {(allowUpdate) && <>
                    <HPHButton label={WorkspaceConstant.Common.BUTTON_CONFIRM} size={'Small'} disabled={isDisable()} theme={'Primary'} onClick={handleConfirm} /></>}

            </StyledAction>
            {isLoading && <Loader Indicator="Spinner" size="Medium" />}
            {companyState.forceRefresh && <></>}
        </Sidebarheader>
    }, [allowCreate, allowDelete, allowUpdate, companyState.forceRefresh, handleAdd, handleConfirm, handleEdit, handleSearchClick, isAdd,
        isDisable, isEditable, isLoading, getSelectedRows]);

    return memoCompanyTitleBar;
}

export default CompanyTitleBar;