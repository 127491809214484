import _ from "lodash";
import { TariffCodeConstant, tariffCodeMainRequiredFieldList } from "presentation/constant/TariffCode/TariffCodeConstant";
import { useTariffCodeVM } from "presentation/hook/TariffCode/useTariffCodeVM";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useTariffCodeTracked } from "presentation/store/TariffCode/TariffCodeProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import NumberInputComponent from "presentation/view/components/NumberInputComponent";
import { useCallback, useMemo } from "react";
import { FieldType, HPHButton, HPHCheckbox, IFieldValue } from "veronica-ui-component/dist/component/core";
import { SidebarCaption, SidebarTitle, StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import TariffCodeEditPanelTitleBar from "../TariffCodeEditPanelTitleBar";

export const TariffCodeHeaderFormPanel = () => {
    const [tariffCodeState] = useTariffCodeTracked();
    const tariffCodeVM = useTariffCodeVM();
    const { currentSelectedRow, masterState, componentState, dynamicOptions } = tariffCodeState;
    const { editingTariffCodeEntity, isAdd, isEditable, isRead, isSaveClicked, allFormState } = masterState;
    const { tariffCodeComponentList } = componentState;
    const TARIFF_CODE_CONSTANT = TariffCodeConstant.Table;

    const [anainfoState] = useANAInfoTracked();
    const { allowUpdate } = anainfoState;

    const currentEntity = isRead ? currentSelectedRow : editingTariffCodeEntity;

    const memoParentTariffTypeOptions = useMemo(() => {
        return currentEntity?.tariffType
            ? Array.of({
                dropdownLabel: currentEntity?.tariffType,
                tagLabel: currentEntity?.tariffType,
                value: currentEntity?.tariffType,
            })
            : [];
    }, [currentEntity?.tariffType]);

    const memoParentTariffCodeOptions = useMemo(() => {
        return currentEntity?.parentTariffType
            ? dynamicOptions.parentTariffCodeDropdownOptions[currentEntity?.parentTariffType]
            : [];
    }, [currentEntity?.parentTariffType, dynamicOptions.parentTariffCodeDropdownOptions]);


    const onNumberFieldChange = useCallback((e: any, fieldName: string, fieldValue: any) => {
        tariffCodeVM.onHeaderFieldChange(fieldName, fieldValue);
    }, [tariffCodeVM]);

    const isDisableGenerate = () => {
        return !isAdd || _.isEmpty(tariffCodeComponentList);
    }

    const handleGenerate = useCallback(() => {
        tariffCodeVM.onGenerate();
    }, [tariffCodeVM])

    const memoSeq = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentEntity?.seq?.toString() || ''}
                //fieldValue={currentEntity?.seq?.toString()}
                fieldLabel={TARIFF_CODE_CONSTANT.SEQ}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'seq'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={() => { }} />
        </div>
        , [TARIFF_CODE_CONSTANT.SEQ, currentEntity?.seq, isSaveClicked])

    const memoActiveInd = useMemo(() =>
        <>
            <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px" }}>

                <NbisCommonField
                    fieldKey={"activeInd"}
                    requiredFieldList={[]}
                    fieldLabel={TARIFF_CODE_CONSTANT.ACTIVE_IND}
                    fieldType={FieldType.TEXT}
                    isReadOnly={true}
                    onFieldChange={() => { }} />
                <HPHCheckbox
                    label={""}
                    checked={currentEntity?.activeInd === 'Y'}
                    disabled={isRead}
                    onChange={(e) => tariffCodeVM.onCheckboxChange(e.checked, "activeInd")}
                />
            </div>
        </>
        , [TARIFF_CODE_CONSTANT.ACTIVE_IND, currentEntity?.activeInd, isRead, tariffCodeVM])


    const memoTarType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!isAdd}
                isShowOptional={false}
                readOnlyValue={currentEntity?.tariffType || ''}
                fieldValue={currentEntity?.tariffType}
                fieldLabel={TARIFF_CODE_CONSTANT.TARIFF_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'tariffType'}
                isShowMissingError={true}
                maxLength={60}
                options={dynamicOptions.tariffTypeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffCodeVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isAdd, currentEntity?.tariffType, TARIFF_CODE_CONSTANT.TARIFF_TYPE, isSaveClicked, dynamicOptions.tariffTypeDropdownOptions, tariffCodeVM])

    const memoTarCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={false}
                readOnlyValue={currentEntity?.tariffCode || ''}
                fieldValue={currentEntity?.tariffCode}
                fieldLabel={TARIFF_CODE_CONSTANT.TARIFF_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'tariffCode'}
                isShowMissingError={true}
                maxLength={60}
                requiredFieldList={tariffCodeMainRequiredFieldList}
                onFieldChange={() => { }} />
        </div>
        , [TARIFF_CODE_CONSTANT.TARIFF_CODE, allFormState, currentEntity?.tariffCode, isSaveClicked])

    const memoTarCodeDesc = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "615px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={false}
                readOnlyValue={currentEntity?.tariffCodeDesc || ''}
                fieldValue={currentEntity?.tariffCodeDesc}
                fieldLabel={TARIFF_CODE_CONSTANT.TARIFF_CODE_DESC}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'tariffCodeDesc'}
                isShowMissingError={true}
                maxLength={60}
                requiredFieldList={tariffCodeMainRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffCodeVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_CODE_CONSTANT.TARIFF_CODE_DESC, allFormState, currentEntity?.tariffCodeDesc, isRead, isSaveClicked, tariffCodeVM])

    const memoTarCodeDescOther = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "615px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentEntity?.tariffCodeDescOther || ''}
                fieldValue={currentEntity?.tariffCodeDescOther}
                fieldLabel={TARIFF_CODE_CONSTANT.TARIFF_CODE_DESCOTHER}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'tariffCodeDescOther'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffCodeVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_CODE_CONSTANT.TARIFF_CODE_DESCOTHER, currentEntity?.tariffCodeDescOther, isRead, isSaveClicked, tariffCodeVM])

    const memoTarGpIdOne = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentEntity?.tariffGroupId1 || ''}
                fieldValue={currentEntity?.tariffGroupId1}
                fieldLabel={TARIFF_CODE_CONSTANT.TARIFF_GROUP_ID_ONE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'tariffGroupId1'}
                isShowMissingError={false}
                isTextFieldCapitalize={true}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffCodeVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_CODE_CONSTANT.TARIFF_GROUP_ID_ONE, currentEntity?.tariffGroupId1, isRead, isSaveClicked, tariffCodeVM])

    const memoTarGpIdTwo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentEntity?.tariffGroupId2 || ''}
                fieldValue={currentEntity?.tariffGroupId2}
                fieldLabel={TARIFF_CODE_CONSTANT.TARIFF_GROUP_ID_TWO}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'tariffGroupId2'}
                isShowMissingError={false}
                isTextFieldCapitalize={true}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffCodeVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_CODE_CONSTANT.TARIFF_GROUP_ID_TWO, currentEntity?.tariffGroupId2, isRead, isSaveClicked, tariffCodeVM])

    const memoTarGpIdThree = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentEntity?.tariffGroupId3 || ''}
                fieldValue={currentEntity?.tariffGroupId3}
                fieldLabel={TARIFF_CODE_CONSTANT.TARIFF_GROUP_ID_THREE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'tariffGroupId3'}
                isShowMissingError={false}
                isTextFieldCapitalize={true}
                type="number"
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffCodeVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_CODE_CONSTANT.TARIFF_GROUP_ID_THREE, currentEntity?.tariffGroupId3, isRead, isSaveClicked, tariffCodeVM])

    const memoTarSeqOne = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={false}
                readOnlyValue={currentEntity?.tariffSeq1?.toString() || ''}
                fieldValue={currentEntity?.tariffSeq1?.toString()}
                fieldLabel={TARIFF_CODE_CONSTANT.TARIFF_SEQ_ONE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'tariffSeq1'}
                isShowMissingError={true}
                maxLength={60}
                requiredFieldList={tariffCodeMainRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffCodeVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_CODE_CONSTANT.TARIFF_SEQ_ONE, allFormState, currentEntity?.tariffSeq1, isRead, isSaveClicked, tariffCodeVM])

    const memoTarSeqTwo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={false}
                readOnlyValue={currentEntity?.tariffSeq2?.toString() || ''}
                fieldValue={currentEntity?.tariffSeq2?.toString()}
                fieldLabel={TARIFF_CODE_CONSTANT.TARIFF_SEQ_TWO}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'tariffSeq2'}
                isShowMissingError={true}
                maxLength={60}
                requiredFieldList={tariffCodeMainRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffCodeVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_CODE_CONSTANT.TARIFF_SEQ_TWO, allFormState, currentEntity?.tariffSeq2, isRead, isSaveClicked, tariffCodeVM])

    const memoTarSeqThree = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={false}
                readOnlyValue={currentEntity?.tariffSeq3?.toString() || ''}
                fieldValue={currentEntity?.tariffSeq3?.toString()}
                fieldLabel={TARIFF_CODE_CONSTANT.TARIFF_SEQ_THREE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'tariffSeq3'}
                isShowMissingError={true}
                maxLength={60}
                requiredFieldList={tariffCodeMainRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffCodeVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_CODE_CONSTANT.TARIFF_SEQ_THREE, allFormState, currentEntity?.tariffSeq3, isRead, isSaveClicked, tariffCodeVM])

    const memoTarScheme = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={false}
                readOnlyValue={currentEntity?.tarSchemeCode || ''}
                fieldValue={currentEntity?.tarSchemeCode || ''}
                fieldLabel={TARIFF_CODE_CONSTANT.TARIFF_SCHEME}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'tarSchemeCode'}
                isShowMissingError={true}
                maxLength={60}
                options={dynamicOptions.tariffSchemeDropdownOptions}
                requiredFieldList={tariffCodeMainRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => tariffCodeVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_CODE_CONSTANT.TARIFF_SCHEME, allFormState, currentEntity?.tarSchemeCode, dynamicOptions.tariffSchemeDropdownOptions, isRead, isSaveClicked, tariffCodeVM])

    const memoNature = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "400px", marginBottom: "24px" }}>
            {/* <NbisInputDropDown
                    label={TARIFF_CODE_CONSTANT.TARIFF_NATURE}
                    width='395px'
                    inputType="freeText"
                    mode={'multiple'}
                    sort={false}
                    disabled={isRead}
                    value={currentEntity?.tariffNatureList?.map((item) => ({
                        value: item
                    }))}
                    onChange={(e) => tariffCodeDtlVM.onHeaderMultipleDropdownChange(e, 'tariffNatureList')}
                    options={dynamicOptions.tariffNatureDropdownOptions}/> */}

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={false}
                readOnlyValue={currentEntity?.tarNature && currentEntity?.tarNature?.length <= 20 ? currentEntity?.tarNature : (currentEntity?.tarNature?.substring(0, 20) + '...') || ''}
                fieldValue={currentEntity?.tariffNatureList}
                fieldLabel={TARIFF_CODE_CONSTANT.TARIFF_NATURE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'tariffNatureList'}
                maxLength={600}
                isMultipleDropDown={true}
                options={dynamicOptions.tariffNatureDropdownOptions}
                requiredFieldList={tariffCodeMainRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffCodeVM.onHeaderMultipleDropdownChange(fieldKey, fieldValue && fieldValue as string[], fFullValue)} />

        </div>
        , [allFormState, isRead, currentEntity?.tarNature, currentEntity?.tariffNatureList, TARIFF_CODE_CONSTANT.TARIFF_NATURE, isSaveClicked, dynamicOptions.tariffNatureDropdownOptions, tariffCodeVM])

    const memoPercentage = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px" }}>
            <NumberInputComponent
                label={TARIFF_CODE_CONSTANT.PERCENTAGE}
                maxLength={60}
                fieldName={"percentage"}
                value={currentEntity?.percentage || ''}
                errorMessage={''}
                disabled={isRead}
                optional={true}
                decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())} />
        </div>
        , [TARIFF_CODE_CONSTANT.PERCENTAGE, currentEntity?.percentage, isRead, onNumberFieldChange])


    const memoParentTarType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentEntity?.parentTariffType || ''}
                fieldValue={currentEntity?.parentTariffType ?? ''}
                fieldLabel={TARIFF_CODE_CONSTANT.PARENT_TARIFF_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'parentTariffType'}
                isShowMissingError={false}
                maxLength={60}
                options={memoParentTariffTypeOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffCodeVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_CODE_CONSTANT.PARENT_TARIFF_TYPE, currentEntity?.parentTariffType, isRead, isSaveClicked, memoParentTariffTypeOptions, tariffCodeVM])

    const memoParentTarCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={{}}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentEntity?.parentTariffCode || ''}
                fieldValue={currentEntity?.parentTariffCode || ''}
                fieldLabel={TARIFF_CODE_CONSTANT.PARENT_TARIFF_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'parentTariffCode'}
                isShowMissingError={false}
                maxLength={60}
                options={memoParentTariffCodeOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffCodeVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_CODE_CONSTANT.PARENT_TARIFF_CODE, currentEntity?.parentTariffCode, isRead, isSaveClicked, memoParentTariffCodeOptions, tariffCodeVM])

    // const memoActiveInd = useMemo(() =>
    //     <>
    //     <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px" }}>
    //         <HPHGroupRadioButton
    //             label={TARIFF_CODE_CONSTANT.ACTIVE_IND}                
    //             disabled={false}
    //             radioOptions={yesOrNoRadioOptions}
    //             value={currentEntity?.activeInd??''}
    //             onChange={(e) => tariffCodeVM.onRadioChange(e,'activeInd')}
    //             checked={yesOrNoRadioOptions.find(item => item.key === currentEntity?.activeInd)?.name}/>
    //     </div>
    //     </>
    // , [TARIFF_CODE_CONSTANT.ACTIVE_IND, currentEntity?.activeInd, tariffCodeVM])

    const memoTputInd = useMemo(() =>
        <>
            <div className='im-flex-row-item'>
                {/* <HPHGroupRadioButton
                    label={TARIFF_CODE_CONSTANT.INCLUDE_IN_THROUGHPUT}
                    disabled={isRead}
                    radioOptions={yesOrNoRadioOptions}
                    value={currentEntity?.includeTputInd??''}
                    onChange={(e) => tariffCodeVM.onRadioChange(e,'includeTputInd')}
                    checked={yesOrNoRadioOptions.find(item => item.key === currentEntity?.includeTputInd)?.name}/> */}

                <NbisCommonField
                    fieldKey={"includeTputInd"}
                    requiredFieldList={[]}
                    fieldLabel={TARIFF_CODE_CONSTANT.INCLUDE_IN_THROUGHPUT}
                    fieldType={FieldType.TEXT}
                    isReadOnly={true}
                    onFieldChange={() => { }} />
                <HPHCheckbox
                    label={""}
                    checked={currentEntity?.includeTputInd === 'Y'}
                    disabled={isRead}
                    onChange={(e) => tariffCodeVM.onCheckboxChange(e.checked, "includeTputInd")} />
            </div>
        </>
        , [TARIFF_CODE_CONSTANT.INCLUDE_IN_THROUGHPUT, currentEntity?.includeTputInd, isRead, tariffCodeVM])

    const memoEditPanelTitleBar = useMemo(() => {
        return <TariffCodeEditPanelTitleBar />
    }, [])


    return <StyledSidebar style={{ width: '100%', height: "100%", border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'>
        {/* Header part of add or edit record */}
        {memoEditPanelTitleBar}

        {/* Readable and Editable Form */}
        <div className={'add-edit-form'} style={{ height: 'calc(100vh - 125px)', maxHeight: 'calc(100vh - 125px)', overflow: 'auto' }}>
            <div className="flex-row-auto">
                <div className={'flex-row-item'}>
                    <div className={'im-flex-row'}>
                        <div className={'flex-row-item-stretch'}>
                            <SidebarTitle>{!!currentEntity?.tariffCode ? currentEntity?.tariffCode : "NEW TARIFF CODE"}</SidebarTitle>

                            {(isAdd || (isEditable && !isRead)) && <SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>}
                        </div>
                    </div>

                    <CriteriaItemContainer>
                        {memoSeq}
                        {memoActiveInd}
                        {memoTputInd}
                    </CriteriaItemContainer>

                    <CriteriaItemContainer>
                        {memoTarType}
                        {memoTarCode}
                        {(allowUpdate) && <HPHButton disabled={isDisableGenerate()} label={"Generate"} size={'Small'} theme={'Primary'} onClick={handleGenerate} />}
                    </CriteriaItemContainer>


                    <CriteriaItemContainer>
                        {memoParentTarType}
                        {memoParentTarCode}
                        {memoTarScheme}
                    </CriteriaItemContainer>

                    <CriteriaItemContainer>
                        {memoTarCodeDesc}
                    </CriteriaItemContainer>

                    <CriteriaItemContainer>
                        {memoTarCodeDescOther}
                    </CriteriaItemContainer>

                    <CriteriaItemContainer>
                        {memoNature}
                        {memoPercentage}
                    </CriteriaItemContainer>

                    <CriteriaItemContainer>
                        {memoTarGpIdOne}
                        {memoTarGpIdTwo}
                        {memoTarGpIdThree}
                    </CriteriaItemContainer>

                    <CriteriaItemContainer>
                        {memoTarSeqOne}
                        {memoTarSeqTwo}
                        {memoTarSeqThree}
                    </CriteriaItemContainer>

                </div>
            </div>
        </div>
        {/* Readable and Editable Form */}
    </StyledSidebar>
}
