import { ChargeQtyAdjMaintenanceConstant } from "presentation/constant/ChargeQtyAdj/ChargeQtyAdjMaintenanceConstant";
import { uomDropdownOption } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { useChargeQtyAdjMaintenanceVM } from "presentation/hook/ChargeQtyAdj/useChargeQtyAdjMaintenanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useChargeQtyAdjMaintenanceTracked } from "presentation/store/ChargeQtyAdj/ChargeQtyAdjMaintenanceProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DatePickerComponent from "presentation/view/components/DatePickerComponent";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import NumberInputComponent from "presentation/view/components/NumberInputComponent";
import { useCallback, useMemo, useRef, useState } from "react";
import { FieldType, HPHCheckbox, HPHInputMask, HPHInputTextarea, IconButton, IFieldValue, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarCaption, SidebarTitle, StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import ChargeQtyAdjVesselComp from "./ArrivalAndDepaVessel/ChargeQtyAdjVesselComp";
import ChargeQtyAdjEditPanelTitleBar from "./ChargeQtyAdjEditPanelTitleBar";
export const ChargeQtyAdjMaintenanceFormPanel = () => {
    const [chargeQtyAdjState] = useChargeQtyAdjMaintenanceTracked();
    const chargeQtyAdjVM = useChargeQtyAdjMaintenanceVM();
    const messageBarVM = useMessageBarVM();
    const { currentSelectedRow, masterState, dynamicOptions } = chargeQtyAdjState;
    const { editingEntity, isAdd, isEditable, isRead, isSaveClicked, allFormState } = masterState;
    const [isLoading, setLoading] = useState<boolean>(false);
    const SCREEN_CONSTANT = ChargeQtyAdjMaintenanceConstant;
    const uploadRef = useRef<any>();

    const currentEntity = isRead ? currentSelectedRow : editingEntity;
    const memoSubChgTypeOptions = useMemo(() => {
        const subChgTypeOptions = currentEntity.chargeType ? dynamicOptions.subChargeTypeDropdownOptions[currentEntity.chargeType] : [];
        return subChgTypeOptions;
    }, [currentEntity.chargeType, dynamicOptions.subChargeTypeDropdownOptions])

    const handleUpload = (event: any) => {
        uploadRef.current?.click();
    }

    const handleFileChange = useCallback(async (e: any) => {

        const files = e.target?.files;
        const file = files[0];

        if (!file) {
            return;
        }
        if (file.type !== 'text/csv') {
            messageBarVM.showError('Please upload a .csv file');
            return
        }

        setLoading(true);
        await chargeQtyAdjVM.onUploadCntr(currentEntity.cntrList ?? [], file);
        uploadRef.current.value = null;

        setLoading(false);

    }, [chargeQtyAdjVM, currentEntity.cntrList, messageBarVM]);

    const onNumberFieldChange = useCallback((e: any, fieldName: string, fieldValue: any) => {
        chargeQtyAdjVM.onFieldChange(fieldName, fieldValue);
    }, [chargeQtyAdjVM]);

    const handleCoVslVoy = () => {
        chargeQtyAdjVM.onShowPanel();
    }

    const memoChargeType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "220px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={false}
                readOnlyValue={currentEntity?.chargeType || ''}
                fieldValue={currentEntity?.chargeType}
                fieldLabel={SCREEN_CONSTANT.CHARGE_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'chargeType'}
                isShowMissingError={true}
                maxLength={60}
                options={dynamicOptions.chargeTypeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    chargeQtyAdjVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [SCREEN_CONSTANT.CHARGE_TYPE, allFormState, chargeQtyAdjVM, currentEntity?.chargeType, dynamicOptions.chargeTypeDropdownOptions, isRead, isSaveClicked])

    const memoSubChargeType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "220px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentEntity?.subChargeType || ''}
                fieldValue={currentEntity?.subChargeType}
                fieldLabel={SCREEN_CONSTANT.SUB_CHARGE_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'subChargeType'}
                isShowMissingError={true}
                maxLength={60}
                options={memoSubChgTypeOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    chargeQtyAdjVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [SCREEN_CONSTANT.SUB_CHARGE_TYPE, allFormState, chargeQtyAdjVM, currentEntity?.subChargeType, isRead, isSaveClicked, memoSubChgTypeOptions])

    const memoChargeOnCompany = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "220px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentEntity?.chargeOnCompany || ''}
                fieldValue={currentEntity?.chargeOnCompany}
                fieldLabel={SCREEN_CONSTANT.CHARGEON_COMPANY}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'chargeOnCompany'}
                isShowMissingError={true}
                maxLength={60}
                options={dynamicOptions.chargeOnCompDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    chargeQtyAdjVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentEntity?.chargeOnCompany, SCREEN_CONSTANT.CHARGEON_COMPANY, isSaveClicked, dynamicOptions.chargeOnCompDropdownOptions, chargeQtyAdjVM])

    const memoCoVslVoy = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "475px", marginBottom: "24px" }}>
            <HPHInputMask
                width='475px'
                disabled={isRead}
                label={SCREEN_CONSTANT.CO_VSL_VOY}
                value={{
                    co: currentEntity.consortiumCode ?? '',
                    vsl: currentEntity.vesselCode ?? '',
                    voy: currentEntity.voyageCode ?? ''
                }}
                fieldConfiguration={{
                    co: { maxLength: 60, autoComplete: "off", valueInNormalCase: true, isNumeric: false },
                    vsl: { maxLength: 10, autoComplete: "off", valueInNormalCase: true, isNumeric: false },
                    voy: { maxLength: 10, autoComplete: "off", valueInNormalCase: true, isNumeric: false },
                }}
                onDataChange={(e: any) => chargeQtyAdjVM.onCoVslVoyChange(e, {
                    co: 'consortiumCode',
                    vsl: 'vesselCode',
                    voy: 'voyageCode'
                })} />
        </div>
        , [isRead, SCREEN_CONSTANT.CO_VSL_VOY, currentEntity.consortiumCode, currentEntity.vesselCode, currentEntity.voyageCode, chargeQtyAdjVM])

    /*       
       const memoConsCode = useMemo(() =>
           <div className='im-flex-row-item' style={{ width: "220px", marginBottom: "24px" }}>
   
               <NbisCommonField
                   errorMessages={allFormState}
                   isReadOnly={isRead}
                   isShowOptional={true}
                   readOnlyValue={currentEntity?.consortiumCode || ''}
                   fieldValue={currentEntity?.consortiumCode}
                   fieldLabel={SCREEN_CONSTANT.CONSORTIUM_CODE}
                   isSaveClicked={isSaveClicked}
                   fieldType={FieldType.DROPDOWN}
                   fieldKey={'consortiumCode'}
                   isShowMissingError={true}
                   maxLength={60}
                   options={dynamicOptions.consortiumCodeDropdownOptions}
                   requiredFieldList={[]}
                   onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                       chargeQtyAdjVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
           </div>
           , [allFormState, isRead, currentEntity?.consortiumCode, SCREEN_CONSTANT.CONSORTIUM_CODE, isSaveClicked, dynamicOptions.consortiumCodeDropdownOptions, chargeQtyAdjVM])
   
       const memoVslCode = useMemo(() =>
           <div className='im-flex-row-item' style={{ width: "220px", marginBottom: "24px" }}>
   
               <NbisCommonField
                   errorMessages={allFormState}
                   isReadOnly={isRead}
                   isShowOptional={true}
                   readOnlyValue={currentEntity?.vesselCode || ''}
                   fieldValue={currentEntity?.vesselCode}
                   fieldLabel={SCREEN_CONSTANT.VESSEL_CODE}
                   isSaveClicked={isSaveClicked}
                   fieldType={FieldType.TEXT}
                   fieldKey={'vesselCode'}
                   isShowMissingError={true}
                   maxLength={60}
                   requiredFieldList={[]}
                   onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                       chargeQtyAdjVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
           </div>
           , [allFormState, isRead, currentEntity?.vesselCode, SCREEN_CONSTANT.VESSEL_CODE, isSaveClicked, chargeQtyAdjVM])
   
       const memoVoyCode = useMemo(() =>
           <div className='im-flex-row-item' style={{ width: "220px", marginBottom: "24px" }}>
   
               <NbisCommonField
                   errorMessages={allFormState}
                   isReadOnly={isRead}
                   isShowOptional={true}
                   readOnlyValue={currentEntity?.voyageCode || ''}
                   fieldValue={currentEntity?.voyageCode}
                   fieldLabel={SCREEN_CONSTANT.VOYAGE_CODE}
                   isSaveClicked={isSaveClicked}
                   fieldType={FieldType.TEXT}
                   fieldKey={'voyageCode'}
                   isShowMissingError={true}
                   maxLength={60}
                   requiredFieldList={[]}
                   onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                       chargeQtyAdjVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
           </div>
           , [allFormState, isRead, currentEntity?.voyageCode, SCREEN_CONSTANT.VOYAGE_CODE, isSaveClicked, chargeQtyAdjVM])
   
   */
    const memoCntrNos = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "720px", marginBottom: "24px" }}>

            {/* <NbisCommonField
                style={{ width: "600px" }}
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={false}
                readOnlyValue={currentEntity?.cntrList || ''}
                fieldValue={currentEntity?.cntrList}
                fieldLabel={SCREEN_CONSTANT.CONTAINER_ID}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXTAREA}
                fieldKey={'cntrList'}
                isShowMissingError={true}
                helpTextElement={"Use comma ( , ) as delimiter"}
                helpIcon={true}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    chargeQtyAdjVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} /> */}

            <HPHInputTextarea
                label={SCREEN_CONSTANT.CONTAINER_ID}
                width="720px"
                rows={3}
                resizable={false}
                helpIcon={true}
                toolTipText={"Use comma ( , ) as delimiter"}
                errorMessage={allFormState ? allFormState['cntrList'] : ''}
                value={currentEntity.cntrList?.join(',') ?? []}
                onChange={(e) => { chargeQtyAdjVM.onTextAreaChange(e, "cntrList", true) }} />
        </div>
        , [SCREEN_CONSTANT.CONTAINER_ID, allFormState, currentEntity.cntrList, chargeQtyAdjVM])

    const memoEtd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "220px", marginBottom: "24px" }}>
            <DatePickerComponent
                disabled={isRead}
                label={SCREEN_CONSTANT.ETD}
                width="220px"
                date={currentEntity?.etd}
                fieldName="etd"
                optional={true}
                errorMessage={allFormState ? allFormState["etd"] : ""}
                onDateChange={(fieldKey: string, fieldValue: IFieldValue) =>
                    chargeQtyAdjVM.onFieldChange(fieldKey, fieldValue)} />
        </div>
        , [isRead, SCREEN_CONSTANT.ETD, currentEntity?.etd, allFormState, chargeQtyAdjVM])

    const memoAllowance = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "220px", marginBottom: "24px" }}>
            <NumberInputComponent
                label={SCREEN_CONSTANT.ALLOWANCE}
                maxLength={6}
                fieldName={"allowance"}
                value={currentEntity?.allowance ?? ''}
                errorMessage={''}
                disabled={isRead}
                decimalPlaces={0}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue)}
            />
            {/* <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={false}
                readOnlyValue={currentEntity?.allowance?.toString() || ''}
                fieldValue={currentEntity?.allowance?.toString() || ''}
                fieldLabel={SCREEN_CONSTANT.ALLOWANCE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'allowance'}
                isShowMissingError={true}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    chargeQtyAdjVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} /> */}
        </div>
        , [SCREEN_CONSTANT.ALLOWANCE, currentEntity?.allowance, isRead, onNumberFieldChange])


    const memoUom = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "220px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={false}
                readOnlyValue={currentEntity?.uom || ''}
                fieldValue={currentEntity?.uom || ''}
                fieldLabel={SCREEN_CONSTANT.UOM}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'uom'}
                isShowMissingError={true}
                maxLength={60}
                options={uomDropdownOption}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    chargeQtyAdjVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentEntity?.uom, SCREEN_CONSTANT.UOM, isSaveClicked, chargeQtyAdjVM])

    const memoWaiverNo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "220px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={false}
                readOnlyValue={currentEntity?.waiverNo || ''}
                fieldValue={currentEntity?.waiverNo || ''}
                fieldLabel={SCREEN_CONSTANT.WAIVER_NO}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'waiverNo'}
                isShowMissingError={true}
                maxLength={20}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    chargeQtyAdjVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentEntity?.waiverNo, SCREEN_CONSTANT.WAIVER_NO, isSaveClicked, chargeQtyAdjVM])

    const memoBlNo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "220px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentEntity?.blNo || ''}
                fieldValue={currentEntity?.blNo || ''}
                fieldLabel={SCREEN_CONSTANT.BILL_OF_LADING_NO}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'blNo'}
                isShowMissingError={true}
                maxLength={20}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    chargeQtyAdjVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentEntity?.blNo, SCREEN_CONSTANT.BILL_OF_LADING_NO, isSaveClicked, chargeQtyAdjVM])

    const memoCustRefNo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "220px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentEntity?.customReferenceNo || ''}
                fieldValue={currentEntity?.customReferenceNo || ''}
                fieldLabel={SCREEN_CONSTANT.CUSTOMER_REF_NO}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'customReferenceNo'}
                isShowMissingError={true}
                maxLength={100}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    chargeQtyAdjVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentEntity?.customReferenceNo, SCREEN_CONSTANT.CUSTOMER_REF_NO, isSaveClicked, chargeQtyAdjVM])
    const memoCfsServType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "220px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentEntity?.serviceType || ''}
                fieldValue={currentEntity?.serviceType || ''}
                fieldLabel={SCREEN_CONSTANT.CFS_SERVICE_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'serviceType'}
                isShowMissingError={true}
                maxLength={10}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    chargeQtyAdjVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentEntity?.serviceType, SCREEN_CONSTANT.CFS_SERVICE_TYPE, isSaveClicked, chargeQtyAdjVM])
    const memoSoNo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "220px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentEntity?.soNo || ''}
                fieldValue={currentEntity?.soNo || ''}
                fieldLabel={SCREEN_CONSTANT.SO_NUMBER}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'soNo'}
                isShowMissingError={true}
                maxLength={20}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    chargeQtyAdjVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentEntity?.soNo, SCREEN_CONSTANT.SO_NUMBER, isSaveClicked, chargeQtyAdjVM])

    const memoHandlingTml = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "220px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isEditable}
                isShowOptional={true}
                readOnlyValue={currentEntity?.handlingTerminal || ''}
                fieldValue={currentEntity?.handlingTerminal || ''}
                fieldLabel={SCREEN_CONSTANT.HANDLING_TML}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'handlingTerminal'}
                isShowMissingError={true}
                maxLength={60}
                options={dynamicOptions.handlingTmlDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    chargeQtyAdjVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isEditable, currentEntity?.handlingTerminal, SCREEN_CONSTANT.HANDLING_TML, isSaveClicked, dynamicOptions.handlingTmlDropdownOptions, chargeQtyAdjVM])
    const memoActiveInd = useMemo(() =>
        <>
            <div className='im-flex-row-item' style={{ width: "220px", marginBottom: "24px" }}>

                <NbisCommonField
                    fieldKey={"activeInd"}
                    requiredFieldList={[]}
                    fieldLabel={SCREEN_CONSTANT.ACTIVE_IND}
                    fieldType={FieldType.TEXT}
                    isReadOnly={true}
                    onFieldChange={() => { }} />
                <HPHCheckbox
                    label={""}
                    checked={currentEntity?.activeInd === 'Y'}
                    disabled={isRead}
                    onChange={(e) => chargeQtyAdjVM.onCheckboxChange(e.checked, "activeInd")}
                />
            </div>
        </>
        , [SCREEN_CONSTANT.ACTIVE_IND, currentEntity?.activeInd, isRead, chargeQtyAdjVM])

    const memoEditPanelTitleBar = useMemo(() => {
        return <ChargeQtyAdjEditPanelTitleBar />
    }, [])
    return <StyledSidebar style={{ width: '100%', height: "100%", border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'>
        {(isLoading) && <Loader Indicator="Spinner" size="Medium" />}
        {/* Header part of add or edit record */}
        {memoEditPanelTitleBar}
        {/* Readable and Editable Form */}
        <div className={'add-edit-form'} style={{ height: 'calc(100vh - 125px)', maxHeight: 'calc(100vh - 125px)', overflow: 'auto' }}>
            <div className="flex-row-auto">
                <div className={'flex-row-item'}>
                    <div className={'im-flex-row'}>
                        <div className={'flex-row-item-stretch'}>
                            <SidebarTitle>{isAdd ? "NEW RECORD" : "EDIT RECORD"}</SidebarTitle>
                            {(isAdd || (isEditable && isRead)) && <SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>}
                        </div>
                    </div>


                    <CriteriaItemContainer>
                        {memoChargeType}{memoSubChargeType}{memoChargeOnCompany}
                    </CriteriaItemContainer>

                    <CriteriaItemContainer>
                        {/* {memoConsCode}{memoVslCode}{memoVoyCode} */}
                        {memoEtd}
                        {memoCoVslVoy}
                        {<div className='password-eye manual-dtl-coVslVoy'>
                            <IconButton fileName="Icon-more" tooltipDisable={true} size="medium" disabled={false} onClick={handleCoVslVoy} /></div>}
                    </CriteriaItemContainer>
                    <CriteriaItemContainer>
                        <div className={"customContainer"}>
                            {memoCntrNos}
                            {/* {!isRead && <><div className='password-eye manual-dtl-upload-cntr'>
                                <FileUpload handleUpload={handleFileChange} label={WorkspaceConstant.Common.BUTTON_UPLOAD} accept=".csv" size={"Small"} disabled={false} /></div></>} */}
                            {(!isRead) && <><div className='password-eye charge-qty-adj-upload-cntr'>
                                <IconButton fileName="Icon-upload" size="medium" toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Upload Container'} onClick={handleUpload} />
                                <input ref={uploadRef} type="file" accept=".csv" style={{ display: 'none' }} onChange={handleFileChange} /></div></>}


                        </div>
                    </CriteriaItemContainer>
                    <CriteriaItemContainer>
                        {memoAllowance} {memoUom}{memoWaiverNo}
                    </CriteriaItemContainer>
                    <CriteriaItemContainer>
                        {memoBlNo} {memoSoNo}{memoCustRefNo}
                    </CriteriaItemContainer>
                    <CriteriaItemContainer>
                        {memoCfsServType}  {memoHandlingTml}
                        {memoActiveInd}
                    </CriteriaItemContainer>
                </div>
                {chargeQtyAdjState.isShowFindVesselPanel && <ChargeQtyAdjVesselComp />}
            </div>
        </div>
        {/* Readable and Editable Form */}
    </StyledSidebar>
} 
