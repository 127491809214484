import { CompanyEntity, EMPTY_COMPANY_ENTITY } from "./CompanyEntity"
import { CustomerEntity, EMPTY_CUSTOMER_ENTITY } from "./CustomerEntity"

export interface CompanyCustomerInfoEntity {
    id: number,
    versionIdentifier: {
        version?: string
    },
	companyId: number,
	customerId: number,
	chargeType: string | null,
	operatingTml: string | null,
	effectiveDate: Date | null,
	intercompanyCode: number | null,
	currencyCode: string | null,
	emailAddress: string | null,
	company: CompanyEntity,
	customer: CustomerEntity,


    [key: string]: string | boolean | number | null | undefined | Object
}

export const EMPTY_COMPANY_CUSTOMER_INFO_ENTITY : CompanyCustomerInfoEntity = {
    id: 0,
    versionIdentifier: {},
    companyId: 0,
    customerId: 0,
    chargeType: null,
    operatingTml: null,
    effectiveDate: null,
    intercompanyCode: null,
    currencyCode: null,
    emailAddress: null,
    company: EMPTY_COMPANY_ENTITY,
    customer: EMPTY_CUSTOMER_ENTITY
}