import { SelectionChangedEvent } from "ag-grid-community";
import _ from "lodash";
import { approvalProcessStatusDropdownOption } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { GeneralProposalConstant, generalProposalRequiredFieldList } from "presentation/constant/GeneralProposal/GeneralProposalConstant";
import { INITIAL_GENERAL_PROPOSAL_TARIFF_ITEM_TIER_COL_DEF, transferRowData } from "presentation/constant/GeneralProposal/GeneralProposalTariffItemTierColumnDefinition";
import { useGeneralProposalVM } from "presentation/hook/GeneralProposal/useGeneralProposalVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useGeneralProposalTracked } from "presentation/store/GeneralProposal/GeneralProposalProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DatePickerComponent from "presentation/view/components/DatePickerComponent";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useMemo, useRef, useState } from "react";
import { FieldType, IconButton, IFieldValue, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarActionCross, Sidebarheader, SidebarTitle } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import TariffItemIsTablePanel from "./TariffItemIsTablePanel";


const TariffItemTierTablePanel: React.FC = () => {
    const GENERAL_PROPOSAL_CONSTANT = GeneralProposalConstant.Table;
    const [generalProposalState] = useGeneralProposalTracked();
    const generalProposalVM = useGeneralProposalVM();
    const { isSaveClicked } = generalProposalState.gnProposalState;
    const { isShowEditPanel, isRowUpdate, searchStatus, searchCriteria, generalProposalItemTierList, generalProposalItemIsList, tariffItemTierSelectedRows, currentTariffItemTier, currentSelectedRow, isEditingProposal } = generalProposalState;
    let gridRef: any = useRef();
    const messageBarVM = useMessageBarVM();

    const [isLoading, setIsLoading] = useState(false);

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        // if ("NEW" === currentSelectedRow.status || "REJECTED" === currentSelectedRow.status) {
        const selectedRows = e.api.getSelectedRows();
        generalProposalVM.updateSelectedTariffItemTierRows(selectedRows);
        // }

    }, [generalProposalVM])

    const handleAdd = useCallback(() => {
        if ("FINALIZED" === currentSelectedRow.status || "PROPOSED" === currentSelectedRow.status) {
            messageBarVM.showError("General Proposal status is \"PROPOSED\" or \"FINALIZED\", cannot Add.");
            return;
        }
        if (!_.isEmpty(generalProposalItemTierList) && generalProposalItemTierList.length === 1 && !_.isEmpty(generalProposalItemIsList)) {
            messageBarVM.showError("Intensive surcharge does not support multiple charge tier.");
            return;
        }

        generalProposalVM.onAddTier();
    }, [messageBarVM, currentSelectedRow, generalProposalItemIsList, generalProposalItemTierList, generalProposalVM]);

    const handleClose = useCallback(() => {
        generalProposalVM.onTariffItemTierCloseClick();
    }, [generalProposalVM]);


    const handleEdit = useCallback(() => {
        if ("FINALIZED" === currentSelectedRow.status || "PROPOSED" === currentSelectedRow.status) {
            messageBarVM.showError("General Proposal status is \"PROPOSED\" or \"FINALIZED\", cannot Edit.");
            return;
        }
        if (currentTariffItemTier?.tierNo == null) {
            messageBarVM.showError("Please select a Tier record.");
            return;
        }
        generalProposalVM.onTariffItemTierEidtClick();
    }, [currentSelectedRow, currentTariffItemTier?.tierNo, generalProposalVM, messageBarVM]);

    const recordValidate = useCallback(() => {
        if (!currentSelectedRow.effectiveDate) {
            messageBarVM.showError("effective Date is mandatory.");
            return false;
        }
        if (!currentSelectedRow.expiryDate) {
            messageBarVM.showError("expiry Date is mandatory.");
            return false;

        }
        if (!currentSelectedRow.currencyCode) {
            messageBarVM.showError("currency is mandatory.");
            return false;
        }
        return true;
    }, [messageBarVM, currentSelectedRow])


    const handleSave = useCallback(() => {

        // if(generalProposalItemTierList.length !== 1 && !_.isEmpty(generalProposalItemIsList)){
        //     messageBarVM.showError("Intensive surcharge does not support multiple charge tier.");
        //     return;
        // }

        if (recordValidate()) {
            setIsLoading(true);
            generalProposalVM.onSaveAll(currentSelectedRow, generalProposalItemTierList, generalProposalItemIsList).then(() => {
                setIsLoading(false);
                if (searchStatus !== "") {
                    generalProposalVM.onGroupButtonClick(searchStatus, "");
                } else {
                    generalProposalVM.searchGeneralProposal(searchCriteria, "");
                }
            }).catch((error) => {
                setIsLoading(false);
                messageBarVM.showError("Failed in save data.")
            })
        }
    }, [currentSelectedRow, generalProposalItemIsList, generalProposalItemTierList, generalProposalVM, messageBarVM, recordValidate, searchCriteria, searchStatus]);

    const handleDelete = useCallback(() => {
        if ("FINALIZED" === currentSelectedRow.status || "PROPOSED" === currentSelectedRow.status) {
            messageBarVM.showError("General Proposal status is \"PROPOSED\" or \"FINALIZED\", cannot Delete.");
            return;
        }

        if (_.isEmpty(tariffItemTierSelectedRows)) {
            messageBarVM.showError("Please select a Tier record.");
            return;
        }

        if (_.isEmpty(generalProposalItemTierList) || generalProposalItemTierList.length === 1
            || generalProposalItemTierList.length === tariffItemTierSelectedRows.length) {
            messageBarVM.showError('At least one tier is required.')
            return;
        }
        generalProposalVM.onDeleteTier();
    }, [currentSelectedRow, generalProposalItemTierList, generalProposalVM, messageBarVM, tariffItemTierSelectedRows])

    const memoBtns = useMemo(() => {

        const hdrBtns = [];
        if (!isShowEditPanel) {
            hdrBtns.push({
                id: 'onEditButton',
                icon: 'Icon-pen',
                title: 'Edit'
            })
        }

        if (!isShowEditPanel) {
            hdrBtns.push({
                id: 'onDeleteButton',
                icon: 'Icon-trash',
                title: 'Delete'
            })
        }

        // hdrBtns.push(
        //     {
        //         id: 'onCloseButton',
        //         icon: 'Icon-cross',
        //         title: 'Close'
        //     }
        // )

        // if((isEditingProposal)){
        //     hdrBtns.push({
        //         id: 'onTickButton',
        //         icon: 'Icon-tick',
        //         title: 'Save'
        //     })
        // }

        return hdrBtns
    }, [isShowEditPanel])

    const handleRowClick = useCallback(() => {

    }, [])

    const memoGeneralProposalTariffItemTierTable = useMemo(() => {

        return (
            <NbisTable
                id='general-proposal-detail-table'
                isNewColumnSetting={true}
                columns={INITIAL_GENERAL_PROPOSAL_TARIFF_ITEM_TIER_COL_DEF.slice()}
                data={transferRowData(generalProposalItemTierList ?? [])}
                headerActionButtons={memoBtns}
                onEditButton={handleEdit}
                onDeleteButton={handleDelete}
                // onCloseButton={handleClose}
                // onTickButton={handleSave}
                showPaginator={false}
                showAddIcon={!isShowEditPanel}
                onAddClick={handleAdd}
                showUploadIcon={false}
                showReloadIcon={false}
                isScrollHighlighted={true}
                selectionMode={false}
                rowSelection={"multiple"}
                isRowHighligted={true}
                onRowClick={handleRowClick}
                onSelectionChanged={handleSelectionChange}
                gridHeight="customHeight"
                customHeight="calc(100vh - 675px)"
                ref={gridRef}
            />
        );
    }, [generalProposalItemTierList, memoBtns, handleEdit, handleDelete, isShowEditPanel, handleAdd, handleRowClick, handleSelectionChange])

    const memoTarType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px", marginLeft: "15px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentSelectedRow?.tariffType ?? ''}
                fieldValue={currentSelectedRow?.tariffType}
                fieldLabel={GENERAL_PROPOSAL_CONSTANT.TARIFF_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'tariffType'}
                maxLength={60}
                options={[]}
                requiredFieldList={generalProposalRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    generalProposalVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [currentSelectedRow?.tariffType, GENERAL_PROPOSAL_CONSTANT.TARIFF_TYPE, isSaveClicked, generalProposalVM])

    const memoTarCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px", marginLeft: "15px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentSelectedRow?.tariffCode ?? ''}
                fieldValue={currentSelectedRow?.tariffCode}
                fieldLabel={GENERAL_PROPOSAL_CONSTANT.TARIFF_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'tariffCode'}
                maxLength={60}
                options={[]}
                requiredFieldList={generalProposalRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    generalProposalVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [currentSelectedRow?.tariffCode, GENERAL_PROPOSAL_CONSTANT.TARIFF_CODE, isSaveClicked, generalProposalVM])

    const memoEffectiveDate = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px", marginLeft: "15px" }}>
            <DatePickerComponent
                disabled={!isEditingProposal}
                label={GENERAL_PROPOSAL_CONSTANT.EFFECTIVE_DATE}
                width="185px"
                date={currentSelectedRow?.effectiveDate}
                fieldName="effectiveDate"
                errorMessage={""}
                onDateChange={generalProposalVM.onHeaderFieldChange} />
        </div>
        , [isEditingProposal, GENERAL_PROPOSAL_CONSTANT.EFFECTIVE_DATE, currentSelectedRow?.effectiveDate, generalProposalVM.onHeaderFieldChange])

    const memoExpiryDate = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px", marginLeft: "15px" }}>
            <DatePickerComponent
                disabled={!isEditingProposal}
                label={GENERAL_PROPOSAL_CONSTANT.EXPIRY_DATE}
                width="185px"
                date={currentSelectedRow?.expiryDate}
                fieldName="expiryDate"
                errorMessage={""}
                onDateChange={generalProposalVM.onHeaderFieldChange} />
        </div>
        , [isEditingProposal, GENERAL_PROPOSAL_CONSTANT.EXPIRY_DATE, currentSelectedRow?.expiryDate, generalProposalVM.onHeaderFieldChange])

    const memoCurrency = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px", marginLeft: "15px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!isEditingProposal}
                isShowOptional={true}
                readOnlyValue={currentSelectedRow?.currencyCode ?? ''}
                fieldValue={currentSelectedRow?.currencyCode}
                fieldLabel={GENERAL_PROPOSAL_CONSTANT.CURRENCY}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'currencyCode'}
                maxLength={60}
                options={generalProposalState.dynamicOptions.currencyCodeDropdownOptions}
                requiredFieldList={generalProposalRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    generalProposalVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [isEditingProposal, currentSelectedRow?.currencyCode, GENERAL_PROPOSAL_CONSTANT.CURRENCY, isSaveClicked, generalProposalState.dynamicOptions.currencyCodeDropdownOptions, generalProposalVM])

    const memoStatus = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px", marginLeft: "15px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentSelectedRow?.status ?? ''}
                fieldValue={currentSelectedRow?.status}
                fieldLabel={GENERAL_PROPOSAL_CONSTANT.STATUS}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'status'}
                maxLength={60}
                options={approvalProcessStatusDropdownOption}
                requiredFieldList={generalProposalRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    generalProposalVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [GENERAL_PROPOSAL_CONSTANT.STATUS, currentSelectedRow?.status, isSaveClicked, generalProposalVM])
    
    const memoConfirmedDate = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px", marginLeft: "15px" }}>
            <DatePickerComponent
                disabled={true}
                label={GENERAL_PROPOSAL_CONSTANT.CONFIRMED_DATE}
                width="185px"
                date={currentSelectedRow?.confirmedDate}
                fieldName="confirmedDate"
                errorMessage={""}
                onDateChange={() => { }} />
        </div>
        , [GENERAL_PROPOSAL_CONSTANT.CONFIRMED_DATE, currentSelectedRow?.confirmedDate])

    const memoRejectReason = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px", marginLeft: "15px" }} >
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentSelectedRow?.rejectReason ?? ''}
                fieldValue={currentSelectedRow?.rejectReason}
                fieldLabel={GENERAL_PROPOSAL_CONSTANT.REJECT_REASON}
                isSaveClicked={false}
                fieldType={FieldType.TEXTAREA}
                fieldKey={'rejectReason'}
                maxLength={200}
                requiredFieldList={generalProposalRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    generalProposalVM.onHeaderTextAreaChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)}
            />
        </div>
        , [GENERAL_PROPOSAL_CONSTANT.REJECT_REASON, currentSelectedRow?.rejectReason, generalProposalVM])

    const memoRemarks = useMemo(() =>
        <div className='im-flex-row-item' id="std-proposal-edit-remarks" style={{ width: "405px", marginBottom: "24px", marginLeft: "15px" }} >
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!isEditingProposal}
                isShowOptional={true}
                readOnlyValue={currentSelectedRow?.remarks ?? ''}
                fieldValue={currentSelectedRow?.remarks}
                fieldLabel={GENERAL_PROPOSAL_CONSTANT.REMARKS}
                isSaveClicked={false}
                fieldType={FieldType.TEXTAREA}
                fieldKey={'remarks'}
                maxLength={200}
                rows={1}
                requiredFieldList={generalProposalRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    generalProposalVM.onHeaderTextAreaChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)}
            />
        </div>
        , [isEditingProposal, currentSelectedRow?.remarks, GENERAL_PROPOSAL_CONSTANT.REMARKS, generalProposalVM])



    const memoTariffItemISTable = useMemo(() => {
        return <TariffItemIsTablePanel />
    }, [])

    // const memoTariffItemIsEditPanel = useMemo(() => {
    //     return <TariffItemIsEditPanel />
    // }, [])

    const handEditHeader = useCallback(() => {
        if ("FINALIZED" === currentSelectedRow.status || "PROPOSED" === currentSelectedRow.status) {
            messageBarVM.showError("General Proposal status is \"PROPOSED\" or \"FINALIZED\", cannot Edit.");
            return;
        }
        generalProposalVM.onGeneralProposalEidtClick();
    }, [currentSelectedRow, generalProposalVM, messageBarVM]);

    const handSave = useCallback(() => {
        if (isRowUpdate) {
            handleSave();
        } else {
            if (recordValidate()) {
                setIsLoading(true);
                generalProposalVM.saveGeneralProposal(currentSelectedRow).then(() => {
                    setIsLoading(false);
                    if (searchStatus !== "") {
                        generalProposalVM.onGroupButtonClick(searchStatus, "");
                    } else {
                        generalProposalVM.searchGeneralProposal(searchCriteria, "");
                    }
                }).catch((error) => {
                    setIsLoading(false);
                    messageBarVM.showError("Failed in save data.")
                })
            }
        }
    }, [currentSelectedRow, generalProposalVM, handleSave, isRowUpdate, messageBarVM, recordValidate, searchCriteria, searchStatus]);

    return <><div className="main-comp-wrapper">
        {/*<div style={{ width: '100%', height: '100%', maxHeight: '85%' }}>*/}
        {(generalProposalState.isLoading || isLoading) && <Loader Indicator="Spinner" size="Medium" />}
        <div style={{ height: '20%' }}>
            <div style={{ height: '15%' }}>
                <Sidebarheader>
                    <SidebarTitle></SidebarTitle>
                    <SidebarActionCross>
                        {(!isShowEditPanel && !isEditingProposal && !isRowUpdate) && <IconButton
                            fileName="Icon-pen" size="medium" toolTipText='Edit' toolTipArrow={false} onClick={handEditHeader} />}
                        {(!isShowEditPanel && (isEditingProposal || isRowUpdate)) && <IconButton
                            fileName="Icon-tick" size="medium" toolTipText='Save' toolTipArrow={false} onClick={handSave} />}
                        {(!isShowEditPanel) && <IconButton
                            fileName="Icon-cross" size="medium" toolTipText='Close' toolTipArrow={false} onClick={handleClose} />}
                    </SidebarActionCross>
                </Sidebarheader>
            </div>
            <div style={{ height: '85%' }}>
                <Sidebarheader style={{ width: '100%' }}>
                    <CriteriaItemContainer>
                        {memoTarType}
                        {memoTarCode}
                        {memoEffectiveDate}
                        {memoExpiryDate}
                        {memoCurrency}
                        {memoStatus}
                        {memoConfirmedDate}
                        {memoRejectReason}
                        {memoRemarks}
                    </CriteriaItemContainer>
                </Sidebarheader>
            </div>
        </div>
        {/* {memoGeneralProposalHeaderTable} */}
        <div style={{ height: '35%' }}>
            <TableWrapper>
                {memoGeneralProposalTariffItemTierTable}
            </TableWrapper>
        </div>
        <div style={{ height: '45%' }}>
            {memoTariffItemISTable}
        </div>
        {/*<div className={`child-div${(isAddIs || isAddTier || isEditIs || isEditTier) ? " child-div-gen-tariff-item-edit-expanded " : ""}`}>
                {memoTariffItemIsEditPanel}
            </div>*/}
        {/*</div>*/}
        {/* {
            isEditingProposal && <SidebarActionBar>
            <HPHButton label={WorkspaceConstant.Common.BUTTON_SAVE} size={'Small'} theme={'Primary'} onClick={handleSave} />
        </SidebarActionBar>
        }    */}

    </div></>;
}

export default memo(TariffItemTierTablePanel);
