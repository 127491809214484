
import { ActivityLogEntity } from "domain/entity/ActivityLog/ActivityLogEntity";
import { EMPTY_MONTH_END_CLOSURE_ENTITY, MonthEndClosureEntity } from "domain/entity/MonthEndClosure/MonthEndClosureEntity";
import { EMPTY_RESERVATION_ITEM_ENTITY, ReservationItemEntity } from "domain/entity/MonthEndClosure/ReservationItemEntity";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";

export interface MonthEndClosureDropdownOptions {
    tariffTypeDropdownOptions: DropdownProps[],
    tariffCodeDropdownOptions: { [key: string]: DropdownProps[] },
    companyCodeDropdownOptions: DropdownProps[],
}
export interface MonthEndClosureState extends BaseViewChangeSate {
}

export interface MonthEndClosureModel {
    isLoading: boolean,
    isShowDtlPanel: boolean,
    isShowActivityLogPanel: boolean,
    isAddReservation: boolean,
    isEditReservation: boolean,
    isShowUpdatePanel: boolean,
    isEdit: boolean,
    currentSelectedRow: MonthEndClosureEntity,
    currentEditRow: MonthEndClosureEntity,
    currentReservationSelectedRow: ReservationItemEntity,
    currentReservationEditRow: ReservationItemEntity,
    selectedRows: MonthEndClosureEntity[],
    selectedReservationRows: ReservationItemEntity[],
    monthEndClosureList: MonthEndClosureEntity[],
    reservationItemList: ReservationItemEntity[],
    activityLogList: ActivityLogEntity[],
    dynamicOptions: MonthEndClosureDropdownOptions,
    forceRefresh: boolean,
}

export const EMPTY_MONTH_END_CLOSURE_MODEL: MonthEndClosureModel = {
    isLoading: false,
    currentSelectedRow: { ...EMPTY_MONTH_END_CLOSURE_ENTITY },
    currentEditRow: { ...EMPTY_MONTH_END_CLOSURE_ENTITY },
    selectedRows: [],
    dynamicOptions: {
        tariffTypeDropdownOptions: [],
        tariffCodeDropdownOptions: {},
        companyCodeDropdownOptions: []
    },
    monthEndClosureList: [],
    isShowDtlPanel: false,
    reservationItemList: [],
    currentReservationSelectedRow: { ...EMPTY_RESERVATION_ITEM_ENTITY },
    currentReservationEditRow: { ...EMPTY_RESERVATION_ITEM_ENTITY },
    selectedReservationRows: [],
    isAddReservation: false,
    isEditReservation: false,
    isShowUpdatePanel: false,
    isShowActivityLogPanel: false,
    activityLogList: [],
    isEdit: false,
    forceRefresh: false
}