import { useCompanyDetailTracked } from "presentation/store/Company/CompanyDetailProvider";
import { memo, useMemo } from "react";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import CompanyDetailFormPanel from "./RightPanel/CompanyDetailFormPanel";
import CompanyDetailTablePanel from "./Table/CompanyDetailTablePanel";

const CompanyDetailContent: React.FC = () => {
        const [companyDtlState] = useCompanyDetailTracked();
        const { isShowRightPanel } = companyDtlState;
        const { isShowFsp, isShowEmptyPool, isShowSuppleTerms, isShowCustAssign, isShowFactor, isShowCustInfo } = companyDtlState.viewState

        const showProportionOfLeftPanel = useMemo(() => {
                let rightWidth;
                if (isShowRightPanel) {
                        if (isShowFsp) {
                                rightWidth = '45%';
                        } else if (isShowFactor) {
                                rightWidth = '70%';
                        } else if (isShowSuppleTerms) {
                                rightWidth = '60%';
                        } else if (isShowCustAssign || isShowEmptyPool || isShowCustInfo) {
                                rightWidth = '65%';
                        } else {
                                rightWidth = '55%'
                        }

                } else {
                        rightWidth = '45%';
                }

                return rightWidth;
        }, [isShowCustAssign, isShowCustInfo, isShowEmptyPool, isShowFactor, isShowFsp, isShowRightPanel, isShowSuppleTerms])

        const showProportionOfRightPanel = useMemo(() => {
                let rightWidth;
                if (isShowRightPanel) {
                        if (isShowFsp) {
                                rightWidth = '55%';
                        } else if (isShowFactor) {
                                rightWidth = '30%';
                        } else if (isShowSuppleTerms) {
                                rightWidth = '40%';
                        } else if (isShowCustAssign || isShowEmptyPool || isShowCustInfo) {
                                rightWidth = '35%';
                        } else {
                                rightWidth = '45%'
                        }

                } else {
                        rightWidth = '55%';
                }

                return rightWidth;
        }, [isShowCustAssign, isShowCustInfo, isShowEmptyPool, isShowFactor, isShowFsp, isShowRightPanel, isShowSuppleTerms])


        return <div className={`main-comp-wrapper${isShowRightPanel ? '' : ' im-hide-side-form-draggable'}`}>
                <SliderPanel
                        isOpen={isShowRightPanel}
                        leftSectionWidth={showProportionOfLeftPanel}
                        rightSectionWidth={showProportionOfRightPanel}
                        draggable={true}
                        leftChildren={<div style={{ marginLeft: "15px" }}><CompanyDetailTablePanel /></div>}
                        rightChildren={<CompanyDetailFormPanel />} />
        </div>
}

export default memo(CompanyDetailContent);