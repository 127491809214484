import { useNonOpsDetailTracked } from "presentation/store/NonOps/NonOpsDetailProvider";
import { memo } from "react";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import { NonOpsAdjustmentPanel } from "./Form/NonOpsAdjustmentPanel";
import { NonOpsDetailFormPanel } from "./Form/NonOpsDetailFormPanel";
import NonOpsDetailTablePanel from "./Table/NonOpsDetailTablePanel";

const NonOpsDetailContent: React.FC = () => {
        const [nonOpsDetailState] = useNonOpsDetailTracked();
        const { isShowDetailInfo } = nonOpsDetailState.viewState;
        const { isShowAdjustmentPanel } = nonOpsDetailState

        return <div className={`main-comp-wrapper${isShowDetailInfo || isShowAdjustmentPanel ? '' : ' im-hide-side-form-draggable'}`}>
                <SliderPanel
                        isOpen={isShowDetailInfo || isShowAdjustmentPanel}
                        leftSectionWidth={isShowAdjustmentPanel ? "65%" : "20%"}
                        rightSectionWidth={isShowAdjustmentPanel ? "35%" : "80%"}
                        draggable={true}
                        leftChildren={<div style={{ marginLeft: "15px" }}><NonOpsDetailTablePanel /></div>}
                        rightChildren={isShowAdjustmentPanel ? <NonOpsAdjustmentPanel /> : <NonOpsDetailFormPanel />} />
        </div>
}

export default memo(NonOpsDetailContent);