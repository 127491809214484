import { EmptyPoolStorageEntity } from "domain/entity/EmptyPoolStorageGeneration/EmptyPoolStorageEntity";
import { EMPTY_EMP_POOL_STORAGE_GEN_SEARCH_CRITERIA, EmptyPoolStorageGenSearchCriteria } from "domain/entity/EmptyPoolStorageGeneration/EmptyPoolStorageGenSearchCriteria";
import { DEFAULT_ENABLED_EMPTY_POOL_STORAGE_GEN_SEARCH_CRITERIA, EmptyPoolStorageGenEnabledSearchCriteria } from "presentation/constant/EmptyPoolStorageGeneration/EmptyPoolStorageGenEnabledSearchCriteria";
import { RefObject } from "react";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";


export interface EmptyPoolStorageGenDropdownOptions {
    chargeOnCompanyDropdownOptions: DropdownProps[],
    billToCompanyDropdownOptions: DropdownProps[]
}
export interface EmptyPoolStorageGenViewChangeState extends BaseViewChangeSate {
    currentSelectedData: EmptyPoolStorageEntity
}

export interface EmptyPoolStorageGenModel {
    isLoading: boolean,
    isShowRightPanel: boolean,
    isFilterSearchCriteria: boolean,
    isEditSearchCriteria: boolean,
    checkboxRef: { [key: string]: HTMLElement | null },
    enabledSearchCriteria: EmptyPoolStorageGenEnabledSearchCriteria,
    checkboxValue: EmptyPoolStorageGenEnabledSearchCriteria,
    searchCriteria: EmptyPoolStorageGenSearchCriteria,
    highlightedCheckboxKey?: string | null
    currentTableRef?: RefObject<any>,
    searchCounter: number,
    dynamicOptions: EmptyPoolStorageGenDropdownOptions,
    isShowDetail: boolean,
    emptyPoolDatas: EmptyPoolStorageEntity[],
    selectedEmptyPoolDatas: EmptyPoolStorageEntity[],
    isShowCriteriaPanel: boolean,
    allFormState?: { [x: string]: string },
    forceRefresh: boolean,

}

export const EMPTY_EMP_POOL_STORAGE_GEN_MODEL: EmptyPoolStorageGenModel = {
    isLoading: false,
    isShowRightPanel: false,
    isFilterSearchCriteria: false,
    isEditSearchCriteria: false,
    checkboxRef: {},
    enabledSearchCriteria: { ...DEFAULT_ENABLED_EMPTY_POOL_STORAGE_GEN_SEARCH_CRITERIA },
    checkboxValue: { ...DEFAULT_ENABLED_EMPTY_POOL_STORAGE_GEN_SEARCH_CRITERIA },
    searchCriteria: { ...EMPTY_EMP_POOL_STORAGE_GEN_SEARCH_CRITERIA },
    searchCounter: 0,
    dynamicOptions: {
        chargeOnCompanyDropdownOptions: [],
        billToCompanyDropdownOptions: []
    },
    isShowDetail: false,
    emptyPoolDatas: [],
    selectedEmptyPoolDatas: [],
    isShowCriteriaPanel: false,
    allFormState: {},
    forceRefresh: false
}