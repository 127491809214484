import { GeneralReportEntity } from "domain/entity/GeneralReport/GeneralReportEntity";
import { ActiveIndDroOpts } from "presentation/constant/DropDownOptions/Common/ActiveIndDroOpts";
import { approvalProcessStatusDropdownOption, ediViewDropdownOption, exceptionTypeDropdownOption, monthDropdownOption } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { GeneralReportConstant } from "presentation/constant/GeneralReport/GeneralReportConstant";
import { proposalTypeRadioOptions, versionTypeRadioOptions } from "presentation/constant/RadioOptions/StaticRadioOptions";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useGeneralReportVM } from "presentation/hook/GeneralReport/useGeneralReportVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useGeneralReportTracked } from "presentation/store/GeneralReport/GeneralReportProvider";
import { generalReportCheckFieldMandatory, isDocDateShowOptional } from "presentation/utils/report/GeneralReportCheckFieldUtils";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DatePickerComponent from "presentation/view/components/DatePickerComponent";
import DateTimePickerComponent from "presentation/view/components/DateTimePickerComponent";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { OverflowMenuButton } from "presentation/view/components/OverflowMenuButton";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { FieldType, HPHButton, HPHCheckbox, IFieldValue, Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, StyledAction, StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const GeneralReportPanel = () => {
    const GENERAL_REPORT_CONSTANT = GeneralReportConstant.Table;
    const [generalReportState] = useGeneralReportTracked();
    const generalReportVM = useGeneralReportVM();
    const { currentReport, criteriaTypeIdMap, reportIdTypeMap } = generalReportState;
    const [isUseStatic, setIsUseStatic] = useState(false);
    const messageBarVM = useMessageBarVM();
    const [isLoading, setIsLoading] = useState(false);
    const [initialScreen, setInitialScreen] = useState<boolean>(true);

    useEffect(() => {
        if (!initialScreen) return;

        generalReportVM.onReportTypeChanged(generalReportState.currentReport.reportType);
        setInitialScreen(false);
    }, [generalReportState.currentReport.reportType, generalReportVM, initialScreen]);

    const reportTypeChanged = useCallback((reportType: string) => {
        generalReportVM.onReportTypeChanged(reportType);
        if ((reportType === "Monthly Revenue Report" || reportType === "Monthly Invoice Summary" ||
            reportType === "Monthly Outstanding Turnover Summary" || reportType === "Monthly Turnover Check")) {
            setIsUseStatic(false);
        } else {
            setIsUseStatic(true);
        }
    }, [generalReportVM]);

    const CriteriaTypeChanged = useCallback((criteriaType: string) => {
        (currentReport.reportType === "Criteria Checklist" && criteriaType) &&
            generalReportVM.onCriteriaTypeChanged(criteriaTypeIdMap[criteriaType]);
    }, [criteriaTypeIdMap, currentReport.reportType, generalReportVM])

    const yearDropChanged = useCallback((year: string) => {
        if ((currentReport.reportType === "Monthly Invoice Summary" || currentReport.reportType === "Monthly Outstanding Turnover Summary" ||
            currentReport.reportType === "Monthly Turnover Check") && year) {
            generalReportVM.onYearDropChanged(year);
        }
    }, [currentReport.reportType, generalReportVM])

    const yearFromChanged = useCallback((yearFrom: string) => {
        if ((currentReport.reportType === "Monthly Revenue Report")
            && yearFrom) {
            generalReportVM.onFromYearDropChanged(yearFrom);
        }
    }, [currentReport.reportType, generalReportVM])

    const yearToChanged = useCallback((yearTo: string) => {
        if ((currentReport.reportType === "Monthly Revenue Report")
            && yearTo) {
            generalReportVM.onToYearDropChanged(yearTo);
        }
    }, [currentReport.reportType, generalReportVM])

    const companyFromChanged = useCallback((companyCodeFrom: string) => {
        if ((currentReport.reportType === "Tariff Adjustment Report")
            && companyCodeFrom) {
            generalReportVM.onCompanyFromDropChanged(companyCodeFrom);
        }
    }, [currentReport.reportType, generalReportVM])

    const companyToChanged = useCallback((companyCodeTo: string) => {
        if ((currentReport.reportType === "Tariff Adjustment Report")
            && companyCodeTo) {
            generalReportVM.onCompanyToDropChanged(companyCodeTo);
        }
    }, [currentReport.reportType, generalReportVM])

    const handleReset = useCallback(() => {
        generalReportVM.onResetClick();
    }, [generalReportVM]);

    const checkFieldMandatory = useCallback((currentReport: GeneralReportEntity) => {
        const ret = generalReportCheckFieldMandatory(currentReport);
        if (ret) {
            messageBarVM.showError(ret);
            return true;
        }
        return false;
    }, [messageBarVM]);

    const isFieldDisable = useCallback(() => {
        if (currentReport.reportType === "Proposal Master Report") {
            if (currentReport.proposalType === "GN") return true
        }
        return false;
    }, [currentReport.proposalType, currentReport.reportType]);

    const handlePrint = useCallback(() => {
        if (checkFieldMandatory(currentReport)) return;
        setIsLoading(true);
        generalReportVM.onPrintClick(reportIdTypeMap, currentReport).then(data => {
            if (data && data.success) {
                setIsLoading(false);
                messageBarVM.showSuccess("Report is sent to printers successfully.");
            } else {
                setIsLoading(false);
                messageBarVM.showError(data.data ?? "Report print failed.");
            }
        });
    }, [checkFieldMandatory, currentReport, generalReportVM, reportIdTypeMap, messageBarVM]);

    const handleExportClick = useCallback((exportType: string) => {
        if (checkFieldMandatory(currentReport)) return;
        setIsLoading(true);
        generalReportVM.onExportClick(exportType, reportIdTypeMap, currentReport).then(data => {
            if (data && data.success) {
                setIsLoading(false);
                messageBarVM.showSuccess("Export successfully.");
            } else {
                setIsLoading(false);
                messageBarVM.showError(data.data ?? "Export failure.");
            }
        });
    }, [checkFieldMandatory, currentReport, generalReportVM, reportIdTypeMap, messageBarVM]);

    const handleView = useCallback(() => {
        if (checkFieldMandatory(currentReport)) return;
        setIsLoading(true);
        generalReportVM.onViewClick(reportIdTypeMap, currentReport).then(data => {
            if (data) {
                setIsLoading(false);
            } else {
                setIsLoading(false);
                messageBarVM.showError("View " + currentReport.reportType + " failure.");
            }
        });
    }, [checkFieldMandatory, currentReport, generalReportVM, messageBarVM, reportIdTypeMap]);


    const memoExportMenu = useMemo(() => {
        return [
            {
                title: GeneralReportConstant.Title.PDF,
                disabled: false,
                command: () => {
                    handleExportClick(GeneralReportConstant.Title.PDF);
                }
            },
            {
                title: GeneralReportConstant.Title.CSV,
                disabled: false,
                command: () => {
                    handleExportClick(GeneralReportConstant.Title.CSV);
                }
            },
            {
                title: GeneralReportConstant.Title.RTF,
                disabled: false,
                command: () => {
                    handleExportClick(GeneralReportConstant.Title.RTF);
                }
            }
        ]
    }, [handleExportClick])

    const memoReportType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "630px", marginBottom: "20px", marginTop: "10px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={false}
                readOnlyValue={currentReport.reportType ?? ''}
                fieldValue={currentReport.reportType}
                fieldLabel={GENERAL_REPORT_CONSTANT.REPORT_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'reportType'}
                maxLength={60}
                options={generalReportState.dynamicOptions.reportTypeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
                    generalReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue);
                    fieldValue && reportTypeChanged(fieldValue?.toString());
                }} />
        </div>
        , [currentReport.reportType, GENERAL_REPORT_CONSTANT.REPORT_TYPE, generalReportState.dynamicOptions.reportTypeDropdownOptions,
            generalReportVM, reportTypeChanged])

    const memoPrinter = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={false}
                readOnlyValue={currentReport.printer ?? ''}
                fieldValue={currentReport.printer}
                fieldLabel={GENERAL_REPORT_CONSTANT.PRINTER}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'printer'}
                maxLength={60}
                options={generalReportState.dynamicOptions.printQueueDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    generalReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [GENERAL_REPORT_CONSTANT.PRINTER, generalReportState.dynamicOptions.printQueueDropdownOptions, generalReportVM, currentReport.printer])

    const memoFileLocation = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={false}
                readOnlyValue={currentReport.fileLocation ?? ''}
                fieldValue={currentReport.fileLocation}
                fieldLabel={GENERAL_REPORT_CONSTANT.FILE_LOCATION}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'fileLocation'}
                maxLength={60}
                options={generalReportState.dynamicOptions.fileLocationDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    generalReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [GENERAL_REPORT_CONSTANT.FILE_LOCATION, generalReportState.dynamicOptions.fileLocationDropdownOptions, generalReportVM, currentReport.fileLocation])

    const memoEdiView = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={currentReport?.ediView ?? ''}
                fieldValue={currentReport?.ediView}
                fieldLabel={GENERAL_REPORT_CONSTANT.EDI_VIEW}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'ediView'}
                maxLength={60}
                options={ediViewDropdownOption}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    generalReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [currentReport?.ediView, GENERAL_REPORT_CONSTANT.EDI_VIEW, generalReportVM])

    const memoCompanyCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={currentReport?.companyCode ?? ''}
                fieldValue={currentReport?.companyCode}
                fieldLabel={GENERAL_REPORT_CONSTANT.COMPANY_CODE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'companyCode'}
                maxLength={60}
                options={generalReportState.dynamicOptions.companyCodeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    generalReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [currentReport?.companyCode, GENERAL_REPORT_CONSTANT.COMPANY_CODE, generalReportState.dynamicOptions.companyCodeDropdownOptions, generalReportVM])

    const memoCompanyCodeFrom = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={false}
                readOnlyValue={currentReport?.companyCodeFrom ?? ''}
                fieldValue={currentReport?.companyCodeFrom}
                fieldLabel={GENERAL_REPORT_CONSTANT.COMPANY_CODE_FROM}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'companyCodeFrom'}
                maxLength={60}
                options={generalReportState.dynamicOptions.companyCodeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
                    generalReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue);
                    fieldValue && companyFromChanged(fieldValue.toString())
                }} />
        </div>
        , [currentReport?.companyCodeFrom, GENERAL_REPORT_CONSTANT.COMPANY_CODE_FROM, generalReportState.dynamicOptions.companyCodeDropdownOptions,
            generalReportVM, companyFromChanged])

    const memoCompanyCodeTo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={false}
                readOnlyValue={currentReport?.companyCodeTo ?? ''}
                fieldValue={currentReport?.companyCodeTo}
                fieldLabel={GENERAL_REPORT_CONSTANT.COMPANY_CODE_TO}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'companyCodeTo'}
                maxLength={60}
                options={generalReportState.dynamicOptions.companyCodeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
                    generalReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue);
                    fieldValue && companyToChanged(fieldValue.toString())
                }} />
        </div>
        , [currentReport?.companyCodeTo, GENERAL_REPORT_CONSTANT.COMPANY_CODE_TO, generalReportState.dynamicOptions.companyCodeDropdownOptions,
            generalReportVM, companyToChanged])

    const memoProposalFrom = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={false}
                readOnlyValue={currentReport?.proposalFrom ?? ''}
                fieldValue={currentReport?.proposalFrom}
                fieldLabel={GENERAL_REPORT_CONSTANT.PROPOSAL_FROM}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'proposalFrom'}
                maxLength={60}
                options={generalReportState.dynamicOptions.proposalNoFromDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    generalReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [currentReport?.proposalFrom, GENERAL_REPORT_CONSTANT.PROPOSAL_FROM, generalReportState.dynamicOptions.proposalNoFromDropdownOptions, generalReportVM])

    const memoProposalTo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={false}
                readOnlyValue={currentReport?.proposalTo ?? ''}
                fieldValue={currentReport?.proposalTo}
                fieldLabel={GENERAL_REPORT_CONSTANT.PROPOSAL_TO}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'proposalTo'}
                maxLength={60}
                options={generalReportState.dynamicOptions.proposalNoToDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    generalReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [currentReport?.proposalTo, GENERAL_REPORT_CONSTANT.PROPOSAL_TO, generalReportState.dynamicOptions.proposalNoToDropdownOptions, generalReportVM])

    const memoChargeOnCompany = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={currentReport?.chargeOnCompany ?? ''}
                fieldValue={currentReport?.chargeOnCompany}
                fieldLabel={GENERAL_REPORT_CONSTANT.CHARGE_ON_COMPANY}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'chargeOnCompany'}
                maxLength={60}
                options={generalReportState.dynamicOptions.chargeOnCompanyDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    generalReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [currentReport?.chargeOnCompany, GENERAL_REPORT_CONSTANT.CHARGE_ON_COMPANY, generalReportState.dynamicOptions.chargeOnCompanyDropdownOptions, generalReportVM])

    const memoActiveInd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={currentReport?.activeInd ?? ''}
                fieldValue={currentReport?.activeInd}
                fieldLabel={GENERAL_REPORT_CONSTANT.ACTIVE_IND}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'activeInd'}
                maxLength={60}
                options={ActiveIndDroOpts().getDroOptsModel()}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    generalReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [currentReport?.activeInd, GENERAL_REPORT_CONSTANT.ACTIVE_IND, generalReportVM])


    const memoChargeType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={currentReport?.chargeType ?? ''}
                fieldValue={currentReport?.chargeType}
                fieldLabel={GENERAL_REPORT_CONSTANT.CHARGE_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'chargeType'}
                maxLength={60}
                options={generalReportState.dynamicOptions.chargeTypeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    generalReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [currentReport?.chargeType, GENERAL_REPORT_CONSTANT.CHARGE_TYPE, generalReportState.dynamicOptions.chargeTypeDropdownOptions, generalReportVM])

    const memoMasterCompanyCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={currentReport?.masterCompanyCode ?? ''}
                fieldValue={currentReport?.masterCompanyCode}
                fieldLabel={GENERAL_REPORT_CONSTANT.MASTER_COMPANY_CODE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'masterCompanyCode'}
                maxLength={60}
                options={generalReportState.dynamicOptions.masterCompanyCodeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    generalReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [currentReport?.masterCompanyCode, GENERAL_REPORT_CONSTANT.MASTER_COMPANY_CODE, generalReportState.dynamicOptions.masterCompanyCodeDropdownOptions, generalReportVM])

    const memoCustomerCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={currentReport?.customerCode ?? ''}
                fieldValue={currentReport?.customerCode}
                fieldLabel={GENERAL_REPORT_CONSTANT.CUSTOMER_CODE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'customerCode'}
                maxLength={60}
                options={generalReportState.dynamicOptions.customerCodeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    generalReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [currentReport?.customerCode, GENERAL_REPORT_CONSTANT.CUSTOMER_CODE, generalReportState.dynamicOptions.customerCodeDropdownOptions, generalReportVM])

    const memoCriteriaType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={currentReport?.criteriaType ?? ''}
                fieldValue={currentReport?.criteriaType}
                fieldLabel={GENERAL_REPORT_CONSTANT.CRITERIA_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'criteriaType'}
                maxLength={60}
                options={generalReportState.dynamicOptions.criteriaTypeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
                    generalReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue);
                    fieldValue && CriteriaTypeChanged(fieldValue.toString());
                }} />
        </div>
        , [currentReport?.criteriaType, GENERAL_REPORT_CONSTANT.CRITERIA_TYPE, generalReportState.dynamicOptions.criteriaTypeDropdownOptions, generalReportVM,
            CriteriaTypeChanged
        ])

    const memoCriteriaName = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={currentReport?.criteriaName ?? ''}
                fieldValue={currentReport?.criteriaName}
                fieldLabel={GENERAL_REPORT_CONSTANT.CRITERIA_NAME}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'criteriaName'}
                maxLength={60}
                options={generalReportState.dynamicOptions.criteriaNameDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    generalReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [currentReport?.criteriaName, GENERAL_REPORT_CONSTANT.CRITERIA_NAME, generalReportState.dynamicOptions.criteriaNameDropdownOptions, generalReportVM])

    const memoProposalNo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={currentReport?.proposalNo ?? ''}
                fieldValue={currentReport?.proposalNo}
                fieldLabel={GENERAL_REPORT_CONSTANT.PROPOSAL_NO}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'proposalNo'}
                maxLength={60}
                isTextFieldCapitalize={true}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    generalReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [currentReport?.proposalNo, GENERAL_REPORT_CONSTANT.PROPOSAL_NO, generalReportVM])

    const memoDocNoFrom = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={currentReport?.docNoFrom ?? ''}
                fieldValue={currentReport?.docNoFrom}
                fieldLabel={GENERAL_REPORT_CONSTANT.DOC_NO_FROM}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'docNoFrom'}
                maxLength={60}
                isTextFieldCapitalize={true}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    generalReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [GENERAL_REPORT_CONSTANT.DOC_NO_FROM, generalReportVM, currentReport?.docNoFrom])

    const memoDocNoTo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={currentReport?.docNoTo ?? ''}
                fieldValue={currentReport?.docNoTo}
                fieldLabel={GENERAL_REPORT_CONSTANT.DOC_NO_TO}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'docNoTo'}
                maxLength={60}
                isTextFieldCapitalize={true}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    generalReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [GENERAL_REPORT_CONSTANT.DOC_NO_TO, generalReportVM, currentReport?.docNoTo])

    const memoDocDateFrom = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <DatePickerComponent
                disabled={false}
                optional={isDocDateShowOptional(currentReport.reportType)}
                label={GENERAL_REPORT_CONSTANT.DOC_DATE_FROM}
                width="300px"
                date={currentReport?.docDateFrom}
                fieldName="docDateFrom"
                errorMessage={""}
                onDateChange={generalReportVM.onHeaderFieldChange} />
        </div>
        , [GENERAL_REPORT_CONSTANT.DOC_DATE_FROM, currentReport?.docDateFrom, currentReport.reportType, generalReportVM.onHeaderFieldChange])

    const memoDocDateTo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <DatePickerComponent
                disabled={false}
                optional={isDocDateShowOptional(currentReport.reportType)}
                label={GENERAL_REPORT_CONSTANT.DOC_DATE_TO}
                width="300px"
                date={currentReport?.docDateTo}
                fieldName="docDateTo"
                errorMessage={""}
                onDateChange={generalReportVM.onHeaderFieldChange} />
        </div>
        , [GENERAL_REPORT_CONSTANT.DOC_DATE_TO, currentReport?.docDateTo, currentReport.reportType, generalReportVM.onHeaderFieldChange])

    const memoGlDateFrom = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <DatePickerComponent
                disabled={false}
                optional={true}
                label={GENERAL_REPORT_CONSTANT.GL_DATE_FROM}
                width="300px"
                date={currentReport?.glDateFrom}
                fieldName="glDateFrom"
                errorMessage={""}
                onDateChange={generalReportVM.onHeaderFieldChange} />
        </div>
        , [GENERAL_REPORT_CONSTANT.GL_DATE_FROM, currentReport?.glDateFrom, generalReportVM.onHeaderFieldChange])

    const memoGlDateTo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <DatePickerComponent
                disabled={false}
                optional={true}
                label={GENERAL_REPORT_CONSTANT.GL_DATE_TO}
                width="300px"
                date={currentReport?.glDateTo}
                fieldName="glDateTo"
                errorMessage={""}
                onDateChange={generalReportVM.onHeaderFieldChange} />
        </div>
        , [GENERAL_REPORT_CONSTANT.GL_DATE_TO, currentReport?.glDateTo, generalReportVM.onHeaderFieldChange])

    const memoOpsDateFrom = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <DatePickerComponent
                disabled={false}
                label={GENERAL_REPORT_CONSTANT.OPS_DATE_FROM}
                width="300px"
                optional={true}
                date={currentReport?.opsDateFrom}
                fieldName="opsDateFrom"
                errorMessage={""}
                onDateChange={generalReportVM.onHeaderFieldChange} />
        </div>
        , [GENERAL_REPORT_CONSTANT.OPS_DATE_FROM, currentReport?.opsDateFrom, generalReportVM.onHeaderFieldChange])

    const memoOpsDateTo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <DatePickerComponent
                disabled={false}
                label={GENERAL_REPORT_CONSTANT.OPS_DATE_TO}
                width="300px"
                optional={true}
                date={currentReport?.opsDateTo}
                fieldName="opsDateTo"
                errorMessage={""}
                onDateChange={generalReportVM.onHeaderFieldChange} />
        </div>
        , [GENERAL_REPORT_CONSTANT.OPS_DATE_TO, currentReport?.opsDateTo, generalReportVM.onHeaderFieldChange])

    const memoEffectiveDateFrom = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <DatePickerComponent
                disabled={false}
                label={GENERAL_REPORT_CONSTANT.EFFECTIVE_DATE_FROM}
                width="300px"
                optional={true}
                date={currentReport?.effectiveDateFrom}
                fieldName="effectiveDateFrom"
                errorMessage={""}
                onDateChange={generalReportVM.onHeaderFieldChange} />
        </div>
        , [GENERAL_REPORT_CONSTANT.EFFECTIVE_DATE_FROM, currentReport?.effectiveDateFrom, generalReportVM.onHeaderFieldChange])

    const memoEffectiveDateTo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <DatePickerComponent
                disabled={false}
                label={GENERAL_REPORT_CONSTANT.EFFECTIVE_DATE_TO}
                width="300px"
                optional={true}
                date={currentReport?.effectiveDateTo}
                fieldName="effectiveDateTo"
                errorMessage={""}
                onDateChange={generalReportVM.onHeaderFieldChange} />
        </div>
        , [GENERAL_REPORT_CONSTANT.EFFECTIVE_DATE_TO, currentReport?.effectiveDateTo, generalReportVM.onHeaderFieldChange])

    const memoExpiryDateFrom = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <DatePickerComponent
                disabled={false}
                label={GENERAL_REPORT_CONSTANT.EXPIRY_DATE_FROM}
                width="300px"
                optional={true}
                date={currentReport?.expiryDateFrom}
                fieldName="expiryDateFrom"
                errorMessage={""}
                onDateChange={generalReportVM.onHeaderFieldChange} />
        </div>
        , [GENERAL_REPORT_CONSTANT.EXPIRY_DATE_FROM, currentReport?.expiryDateFrom, generalReportVM.onHeaderFieldChange])

    const memoExpiryDateTo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <DatePickerComponent
                disabled={false}
                label={GENERAL_REPORT_CONSTANT.EXPIRY_DATE_TO}
                width="300px"
                optional={true}
                date={currentReport?.expiryDateTo}
                fieldName="expiryDateTo"
                errorMessage={""}
                onDateChange={generalReportVM.onHeaderFieldChange} />
        </div>
        , [GENERAL_REPORT_CONSTANT.EXPIRY_DATE_TO, currentReport?.expiryDateTo, generalReportVM.onHeaderFieldChange])

    const memoExceptionDateFrom = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <DateTimePickerComponent
                disabled={false}
                label={GENERAL_REPORT_CONSTANT.EXCEPTION_DATE_FROM}
                width="300px"
                optional={true}
                date={currentReport?.exceptionDateFrom}
                fieldName="exceptionDateFrom"
                errorMessage={""}
                onDateChange={generalReportVM.onHeaderFieldChange} />
        </div>
        , [GENERAL_REPORT_CONSTANT.EXCEPTION_DATE_FROM, currentReport?.exceptionDateFrom, generalReportVM.onHeaderFieldChange])

    const memoExceptionDateTo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <DateTimePickerComponent
                disabled={false}
                label={GENERAL_REPORT_CONSTANT.EXCEPTION_DATE_TO}
                width="300px"
                optional={true}
                date={currentReport?.exceptionDateTo}
                fieldName="exceptionDateTo"
                errorMessage={""}
                onDateChange={generalReportVM.onHeaderFieldChange} />
        </div>
        , [GENERAL_REPORT_CONSTANT.EXCEPTION_DATE_TO, currentReport?.exceptionDateTo, generalReportVM.onHeaderFieldChange])

    const memoDate = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <DatePickerComponent
                disabled={false}
                label={GENERAL_REPORT_CONSTANT.DATE}
                width="300px"
                optional={false}
                date={currentReport?.date}
                fieldName="date"
                errorMessage={""}
                onDateChange={generalReportVM.onHeaderFieldChange} />
        </div>
        , [GENERAL_REPORT_CONSTANT.DATE, currentReport?.date, generalReportVM.onHeaderFieldChange])

    const memoExceptionType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={false}
                readOnlyValue={currentReport?.exceptionType ?? ''}
                fieldValue={currentReport?.exceptionType}
                fieldLabel={GENERAL_REPORT_CONSTANT.EXCEPTION_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'exceptionType'}
                maxLength={60}
                options={exceptionTypeDropdownOption}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    generalReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [currentReport?.exceptionType, GENERAL_REPORT_CONSTANT.EXCEPTION_TYPE, generalReportVM])

    const memoYear = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={false}
                readOnlyValue={currentReport?.year ?? ''}
                fieldValue={currentReport?.year}
                fieldLabel={GENERAL_REPORT_CONSTANT.YEAR}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'year'}
                maxLength={4}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    generalReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [GENERAL_REPORT_CONSTANT.YEAR, currentReport?.year, generalReportVM])

    const memoYearDrop = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={false}
                readOnlyValue={currentReport?.year ?? ''}
                fieldValue={currentReport?.year}
                fieldLabel={GENERAL_REPORT_CONSTANT.YEAR}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'year'}
                maxLength={60}
                options={generalReportState.dynamicOptions.yearDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
                    generalReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue);
                    fieldValue && yearDropChanged(fieldValue.toString());
                }} />
        </div>
        , [currentReport?.year, GENERAL_REPORT_CONSTANT.YEAR, generalReportState.dynamicOptions.yearDropdownOptions, generalReportVM, yearDropChanged])

    const memoMonth = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={false}
                readOnlyValue={currentReport?.month ?? ''}
                fieldValue={currentReport?.month}
                fieldLabel={GENERAL_REPORT_CONSTANT.MONTH}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'month'}
                maxLength={60}
                options={isUseStatic ? monthDropdownOption : generalReportState.dynamicOptions.monthDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    generalReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [currentReport?.month, GENERAL_REPORT_CONSTANT.MONTH, isUseStatic, generalReportState.dynamicOptions.monthDropdownOptions, generalReportVM])


    const memoFromYear = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={false}
                readOnlyValue={currentReport?.fromYear ?? ''}
                fieldValue={currentReport?.fromYear}
                fieldLabel={GENERAL_REPORT_CONSTANT.FROM_YEAR}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'fromYear'}
                maxLength={60}
                options={generalReportState.dynamicOptions.yearDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
                    generalReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue);
                    fieldValue && yearFromChanged(fieldValue.toString());
                }} />
        </div>
        , [currentReport?.fromYear, GENERAL_REPORT_CONSTANT.FROM_YEAR, generalReportState.dynamicOptions.yearDropdownOptions, generalReportVM, yearFromChanged])

    const memoToYear = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={false}
                readOnlyValue={currentReport?.toYear ?? ''}
                fieldValue={currentReport?.toYear}
                fieldLabel={GENERAL_REPORT_CONSTANT.TO_YEAR}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'toYear'}
                maxLength={60}
                options={generalReportState.dynamicOptions.yearDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
                    generalReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue);
                    fieldValue && yearToChanged(fieldValue.toString());
                }} />
        </div>
        , [currentReport?.toYear, GENERAL_REPORT_CONSTANT.TO_YEAR, generalReportState.dynamicOptions.yearDropdownOptions, generalReportVM, yearToChanged])

    const memoFromMonth = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={false}
                readOnlyValue={currentReport?.fromMonth ?? ''}
                fieldValue={currentReport?.fromMonth}
                fieldLabel={GENERAL_REPORT_CONSTANT.FROM_MONTH}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'fromMonth'}
                maxLength={60}
                options={generalReportState.dynamicOptions.monthDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    generalReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [currentReport?.fromMonth, GENERAL_REPORT_CONSTANT.FROM_MONTH, generalReportState.dynamicOptions.monthDropdownOptions, generalReportVM])

    const memoToMonth = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={false}
                readOnlyValue={currentReport?.toMonth ?? ''}
                fieldValue={currentReport?.toMonth}
                fieldLabel={GENERAL_REPORT_CONSTANT.TO_MONTH}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'toMonth'}
                maxLength={60}
                options={generalReportState.dynamicOptions.monthDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    generalReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [currentReport?.toMonth, GENERAL_REPORT_CONSTANT.TO_MONTH, generalReportState.dynamicOptions.monthDropdownOptions, generalReportVM])

    const memoBillingClerk = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={currentReport?.billingClerk ?? ''}
                fieldValue={currentReport?.billingClerk}
                fieldLabel={GENERAL_REPORT_CONSTANT.BILLING_CLERK}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'billingClerk'}
                maxLength={60}
                options={generalReportState.dynamicOptions.billingClerkDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    generalReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [currentReport?.billingClerk, GENERAL_REPORT_CONSTANT.BILLING_CLERK, generalReportState.dynamicOptions.billingClerkDropdownOptions, generalReportVM])

    const memoCheckbox1 = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "630px", marginBottom: "20px" }}>
            <HPHCheckbox
                label={GENERAL_REPORT_CONSTANT.ISSUED_TURNOVER_INVOICE}
                checked={currentReport?.issuedTurnoverInvoice === 'Y'}
                disabled={false}
                onChange={(e) => generalReportVM.onCheckboxChange(e.checked, "issuedTurnoverInvoice")}
            />
        </div>
        , [GENERAL_REPORT_CONSTANT.ISSUED_TURNOVER_INVOICE, currentReport?.issuedTurnoverInvoice, generalReportVM])

    const memoCheckbox2 = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "630px", marginBottom: "20px" }}>
            <HPHCheckbox
                label={GENERAL_REPORT_CONSTANT.OUTSTANDING_TURNOVER_TO_BE_CHARGED}
                checked={currentReport?.outstandingTurnover === 'Y'}
                disabled={false}
                onChange={(e) => generalReportVM.onCheckboxChange(e.checked, "outstandingTurnover")}
            />
        </div>
        , [GENERAL_REPORT_CONSTANT.OUTSTANDING_TURNOVER_TO_BE_CHARGED, currentReport?.outstandingTurnover, generalReportVM])

    const memoCheckbox3 = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "630px", marginBottom: "20px" }}>
            <HPHCheckbox
                label={GENERAL_REPORT_CONSTANT.ISSUED_OUTSTANDING_TURNOVER_INVOICE}
                checked={currentReport?.issuedAndOutstadningTurnover === 'Y'}
                disabled={false}
                onChange={(e) => generalReportVM.onCheckboxChange(e.checked, "issuedAndOutstadningTurnover")}
            />
        </div>
        , [GENERAL_REPORT_CONSTANT.ISSUED_OUTSTANDING_TURNOVER_INVOICE, currentReport?.issuedAndOutstadningTurnover, generalReportVM])

    const memoCheckbox4 = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "630px", marginBottom: "20px" }}>
            <HPHCheckbox
                label={GENERAL_REPORT_CONSTANT.RESERVATION}
                checked={currentReport?.reservation === 'Y'}
                disabled={false}
                onChange={(e) => generalReportVM.onCheckboxChange(e.checked, "reservation")}
            />
        </div>
        , [GENERAL_REPORT_CONSTANT.RESERVATION, currentReport?.reservation, generalReportVM])

    const memoCheckbox5 = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "630px", marginBottom: "20px" }}>
            <HPHCheckbox
                label={GENERAL_REPORT_CONSTANT.ISSUED_OUTSTANDING_RESERVATION_TURNOVER_INVOICE}
                checked={currentReport?.issuedAndOustandingAndReservation === 'Y'}
                disabled={false}
                onChange={(e) => generalReportVM.onCheckboxChange(e.checked, "issuedAndOustandingAndReservation")}
            />
        </div>
        , [GENERAL_REPORT_CONSTANT.ISSUED_OUTSTANDING_RESERVATION_TURNOVER_INVOICE, currentReport?.issuedAndOustandingAndReservation, generalReportVM])

    const memoProposalType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={false}
                readOnlyValue={currentReport?.proposalType || ''}
                fieldValue={currentReport?.proposalType}
                fieldLabel={GENERAL_REPORT_CONSTANT.PROPOSAL_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.RADIO_GROUP}
                fieldKey={'proposalType'}
                isShowMissingError={true}
                maxLength={10}
                options={proposalTypeRadioOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    generalReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [currentReport?.proposalType, GENERAL_REPORT_CONSTANT.PROPOSAL_TYPE, generalReportVM])

    const memoVersionType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={false}
                readOnlyValue={currentReport?.version || ''}
                fieldValue={currentReport?.version}
                fieldLabel={GENERAL_REPORT_CONSTANT.VERSION}
                isSaveClicked={false}
                fieldType={FieldType.RADIO_GROUP}
                fieldKey={'version'}
                isShowMissingError={true}
                maxLength={10}
                options={versionTypeRadioOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    generalReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [currentReport?.version, GENERAL_REPORT_CONSTANT.VERSION, generalReportVM])


    const memoProposalStatus = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={currentReport?.proposalStatus ?? ''}
                fieldValue={currentReport?.proposalStatus}
                fieldLabel={GENERAL_REPORT_CONSTANT.PROPOSAL_STATUS}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'proposalStatus'}
                maxLength={60}
                options={approvalProcessStatusDropdownOption}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    generalReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [currentReport?.proposalStatus, GENERAL_REPORT_CONSTANT.PROPOSAL_STATUS, generalReportVM])

    const memoTariffNature = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={currentReport?.tariffNature ?? ''}
                fieldValue={currentReport?.tariffNature}
                fieldLabel={GENERAL_REPORT_CONSTANT.TARIFF_NATURE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'tariffNature'}
                maxLength={60}
                options={generalReportState.dynamicOptions.tariffNatureDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    generalReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [currentReport?.tariffNature, GENERAL_REPORT_CONSTANT.TARIFF_NATURE, generalReportState.dynamicOptions.tariffNatureDropdownOptions, generalReportVM])

    const memoTariffType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={currentReport?.tariffType ?? ''}
                fieldValue={currentReport?.tariffType}
                fieldLabel={GENERAL_REPORT_CONSTANT.TARIFF_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'tariffType'}
                maxLength={60}
                options={generalReportState.dynamicOptions.tariffTypeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    generalReportVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [currentReport?.tariffType, GENERAL_REPORT_CONSTANT.TARIFF_TYPE, generalReportState.dynamicOptions.tariffTypeDropdownOptions, generalReportVM])

    return <>

        <Sidebarheader style={{ width: '100%', display: "flex", alignItems: "center" }}>
            <HeaderTitle>{GeneralReportConstant.Title.TITLE}</HeaderTitle>
            {(isLoading) && <Loader Indicator="Spinner" size="Medium" />}
            <StyledAction className="tm-animated-wrapper">
                <HPHButton label={WorkspaceConstant.Common.BUTTON_CLEAR} size={"Small"} theme={"Secondary"} disabled={false} onClick={handleReset} />
                <div className="add-seperator" />
                <HPHButton label={WorkspaceConstant.Common.BUTTON_PRINT} size={"Small"} theme={"Secondary"} disabled={false} onClick={handlePrint} />
                <OverflowMenuButton disabled={false} buttonLabel={WorkspaceConstant.Common.BUTTON_EXPORT_AS} navigationList={memoExportMenu} />
                <HPHButton label={WorkspaceConstant.Common.BUTTON_VIEW} size={"Small"} theme={"Primary"} disabled={false} onClick={handleView} />
            </StyledAction>
        </Sidebarheader>
        <StyledSidebar style={{ width: '100%', height: "100%", border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'>

            <div className={'add-edit-form'} style={{ height: 'calc(100vh - 125px)', maxHeight: 'calc(100vh - 125px)', overflow: 'auto' }}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        <CriteriaItemContainer>
                            {memoReportType}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoPrinter}
                            {memoFileLocation}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {generalReportState.enabledField.proposalType ? memoProposalType : null}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {generalReportState.enabledField.billingClerk ? memoBillingClerk : null}
                            {generalReportState.enabledField.date ? memoDate : null}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {generalReportState.enabledField.proposalFromTo ? memoCompanyCodeFrom : null}
                            {generalReportState.enabledField.proposalFromTo ? memoProposalFrom : null}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {generalReportState.enabledField.proposalFromTo ? memoCompanyCodeTo : null}
                            {generalReportState.enabledField.proposalFromTo ? memoProposalTo : null}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {generalReportState.enabledField.exceptionType ? memoExceptionDateFrom : null}
                            {generalReportState.enabledField.exceptionType ? memoExceptionDateTo : null}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {generalReportState.enabledField.exceptionType ? memoExceptionType : null}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {generalReportState.enabledField.tariff ? memoTariffNature : null}
                            {generalReportState.enabledField.tariff ? memoTariffType : null}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {generalReportState.enabledField.ediView ? memoEdiView : null}
                            {generalReportState.enabledField.companyCode && !isFieldDisable() ? memoCompanyCode : null}
                            {generalReportState.enabledField.customerCode ? memoCustomerCode : null}
                            {generalReportState.enabledField.activeInd ? memoActiveInd : null}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {generalReportState.enabledField.criteriaType ? memoCriteriaType : null}
                            {generalReportState.enabledField.criteriaType ? memoCriteriaName : null}
                        </CriteriaItemContainer>


                        <CriteriaItemContainer>
                            {generalReportState.enabledField.docNo ? memoDocNoFrom : null}
                            {generalReportState.enabledField.docNo ? memoDocNoTo : null}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {generalReportState.enabledField.docDate ? memoDocDateFrom : null}
                            {generalReportState.enabledField.docDate ? memoDocDateTo : null}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {generalReportState.enabledField.glDate ? memoGlDateFrom : null}
                            {generalReportState.enabledField.glDate ? memoGlDateTo : null}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {generalReportState.enabledField.opsDate ? memoOpsDateFrom : null}
                            {generalReportState.enabledField.opsDate ? memoOpsDateTo : null}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {generalReportState.enabledField.fromYearMonth ? memoFromYear : null}
                            {generalReportState.enabledField.fromYearMonth ? memoFromMonth : null}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {generalReportState.enabledField.fromYearMonth ? memoToYear : null}
                            {generalReportState.enabledField.fromYearMonth ? memoToMonth : null}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {generalReportState.enabledField.monthlyRevenueCheckBox ? memoCheckbox1 : null}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {generalReportState.enabledField.monthlyRevenueCheckBox ? memoCheckbox2 : null}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {generalReportState.enabledField.monthlyRevenueCheckBox ? memoCheckbox3 : null}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {generalReportState.enabledField.monthlyRevenueCheckBox ? memoCheckbox4 : null}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {generalReportState.enabledField.monthlyRevenueCheckBox ? memoCheckbox5 : null}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {generalReportState.enabledField.yearInput ? memoYear : null}
                            {generalReportState.enabledField.yearMonth ? memoYearDrop : null}
                            {(generalReportState.enabledField.month || generalReportState.enabledField.yearMonth) ? memoMonth : null}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {generalReportState.enabledField.chargeOnCompany ? memoChargeOnCompany : null}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {generalReportState.enabledField.chargeType ? memoChargeType : null}
                            {generalReportState.enabledField.masterCompanyCode ? memoMasterCompanyCode : null}
                            {generalReportState.enabledField.proposalNo && !isFieldDisable() ? memoProposalNo : null}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {generalReportState.enabledField.effectiveDate ? memoEffectiveDateFrom : null}
                            {generalReportState.enabledField.effectiveDate ? memoEffectiveDateTo : null}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {generalReportState.enabledField.expiryDate ? memoExpiryDateFrom : null}
                            {generalReportState.enabledField.expiryDate ? memoExpiryDateTo : null}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {generalReportState.enabledField.version && !isFieldDisable() ? memoVersionType : null}
                            {generalReportState.enabledField.proposalStatus ? memoProposalStatus : null}
                        </CriteriaItemContainer>

                    </div>
                </div>
            </div>
        </StyledSidebar >
    </>
}

export default memo(GeneralReportPanel);
