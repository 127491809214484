import { EMPTY_CHARGE_DETAIL_SEARCH_CRITERIA } from "domain/entity/ChargeDetailMaintenance/ChargeDetailSearchCriteria";
import _ from "lodash";
import { ChargeDetailConstant } from "presentation/constant/ChargeDetailMaintenance/ChargeDetailConstant";
import { CriteriaDirectoryChargeCheckboxOption } from "presentation/constant/ChargeDetailMaintenance/ChargeDetailCriteriaDirectoryCheckboxOption";
import { adjTypeForSearchDropdownOption, bundleIndDropdownOption, emptyIndDropdownOption, modalityDropdownOption, operationTypeDropdownOption, stateDropdownOption, statusDropdownOptions } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { useChargeDetailVM } from "presentation/hook/ChargeDetailMaintenance/useChargeDetailVM";
import { useChargeDetailTracked } from "presentation/store/ChargeDetailMaintenance/ChargeDetailProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import NbisInputDropDown from "presentation/view/components/NbisInputDropDown";
import { memo, useMemo } from "react";
import { HPHInputTextarea, InputField } from "veronica-ui-component/dist/component/core";

const ChargeSearchForm = () => {
    const CONTAINER_CONSTANT = ChargeDetailConstant.Charge;
    const [chargeDetailState] = useChargeDetailTracked();
    const chargeDetailsearchCriteria = chargeDetailState.searchCriteria;
    const chargeDetailVM = useChargeDetailVM();
    const enabledCriteria = useMemo(() => CriteriaDirectoryChargeCheckboxOption.find(item => chargeDetailState.enabledSearchCriteria[item.key]), [chargeDetailState.enabledSearchCriteria])
    const memoSubChgTypeOptions = useMemo(() => {
        const groupOfSubChgTypeOptions = chargeDetailsearchCriteria.chargeTypes?.map(chgType => chargeDetailState.dynamicOptions.subChgTypeDropdownOptions[chgType]);
        const commonSubChgTypeOptions = _.orderBy(_.uniqBy(_.flatMap(_.concat(groupOfSubChgTypeOptions ?? [])), 'value'), 'dropdownLabel');
        return commonSubChgTypeOptions;
    }, [chargeDetailsearchCriteria.chargeTypes, chargeDetailState.dynamicOptions.subChgTypeDropdownOptions])

    const memoChgType = useMemo(() =>
        <div className='flex-row-item'>
            <NbisInputDropDown
                label={CONTAINER_CONSTANT.CHARGE_TYPE}
                width='175px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={chargeDetailsearchCriteria.chargeTypes?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => {
                    chargeDetailVM.onMultipleDropdownChange(e, 'chargeTypes')
                    chargeDetailVM.onSearchDropdownChange(EMPTY_CHARGE_DETAIL_SEARCH_CRITERIA.subChargeTypes, 'subChargeTypes')
                }}
                options={chargeDetailState.dynamicOptions.chgTypeDropdownOptions} />
        </div>
        , [chargeDetailsearchCriteria.chargeTypes, chargeDetailState.dynamicOptions.chgTypeDropdownOptions, chargeDetailVM, CONTAINER_CONSTANT.CHARGE_TYPE])
    const memoSubChgType = useMemo(() =>
        <div className='flex-row-item'>
            <NbisInputDropDown
                label={CONTAINER_CONSTANT.SUB_CHARGE_TYPE}
                width='175px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={chargeDetailsearchCriteria.subChargeTypes?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => chargeDetailVM.onMultipleDropdownChange(e, 'subChargeTypes')}
                options={memoSubChgTypeOptions} />
        </div>
        , [chargeDetailsearchCriteria.subChargeTypes, chargeDetailVM, memoSubChgTypeOptions, CONTAINER_CONSTANT.SUB_CHARGE_TYPE])
    const memoBillToCompany = useMemo(() =>
        <div className='flex-row-item'>
            <NbisInputDropDown
                label={CONTAINER_CONSTANT.BILL_TO_COMPANY}
                inputType="freeText"
                width="175px"
                mode={'multiple'}
                sort={false}
                value={chargeDetailsearchCriteria.billToCompanies?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => chargeDetailVM.onMultipleDropdownChange(e, 'billToCompanies')}
                options={chargeDetailState.dynamicOptions.billToCompDropdownOptions} />
        </div>
        , [chargeDetailsearchCriteria.billToCompanies, chargeDetailVM, chargeDetailState.dynamicOptions.billToCompDropdownOptions, CONTAINER_CONSTANT.BILL_TO_COMPANY])
    const memoChargeOnCompany = useMemo(() =>
        <div className='flex-row-item'>
            <NbisInputDropDown
                label={CONTAINER_CONSTANT.CHARGE_ON_COMPANY}
                width='175px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={chargeDetailsearchCriteria.chargeOnCompanies?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => chargeDetailVM.onMultipleDropdownChange(e, 'chargeOnCompanies')}
                options={chargeDetailState.dynamicOptions.chgOnCompDropdownOptions} />
        </div>
        , [chargeDetailsearchCriteria.chargeOnCompanies, chargeDetailVM, chargeDetailState.dynamicOptions.chgOnCompDropdownOptions, CONTAINER_CONSTANT.CHARGE_ON_COMPANY])
    const memoState = useMemo(() =>
        <div className='flex-row-item'>
            <NbisInputDropDown
                label={CONTAINER_CONSTANT.STATE}
                width='175px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={chargeDetailsearchCriteria.states?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => chargeDetailVM.onMultipleDropdownChange(e, 'states')}
                options={stateDropdownOption} />
        </div>
        , [chargeDetailsearchCriteria.states, chargeDetailVM, CONTAINER_CONSTANT.STATE])
    const memoTarCode = useMemo(() =>
        <div className='flex-row-item'>
            <NbisInputDropDown
                label={CONTAINER_CONSTANT.TARIFF_CODE}
                width='175px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={chargeDetailsearchCriteria.tariffCodes?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => chargeDetailVM.onMultipleDropdownChange(e, 'tariffCodes')}
                options={chargeDetailState.dynamicOptions.tarCodeDropdownOptions} />
        </div>
        , [chargeDetailsearchCriteria.tariffCodes, chargeDetailVM, chargeDetailState.dynamicOptions.tarCodeDropdownOptions, CONTAINER_CONSTANT.TARIFF_CODE])
    const memoCntrNo = useMemo(() =>
        <div className='flex-row-item'>
            <HPHInputTextarea
                label={CONTAINER_CONSTANT.CNTR_NO}
                width="300px"
                rows={3}
                resizable={false}
                helpIcon={true}
                toolTipText={"Use comma ( , ) as delimiter"}
                value={chargeDetailsearchCriteria.cntrNos?.join(",")}
                onChange={(e: any) => { chargeDetailVM.onTextAreaChange(e, "cntrNos", true) }} />
        </div>
        , [chargeDetailsearchCriteria.cntrNos, chargeDetailVM, CONTAINER_CONSTANT.CNTR_NO])
    const memoOperationType = useMemo(() =>
        <div className='flex-row-item'>
            <NbisInputDropDown
                label={CONTAINER_CONSTANT.OPERATION_TYPE}
                width='175px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={chargeDetailsearchCriteria.operationTypes?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => chargeDetailVM.onMultipleDropdownChange(e, 'operationTypes')}
                options={operationTypeDropdownOption} />
        </div>
        , [chargeDetailsearchCriteria.operationTypes, chargeDetailVM, CONTAINER_CONSTANT.OPERATION_TYPE])
    const memoBundleInd = useMemo(() =>
        <div className='flex-row-item'>
            <NbisInputDropDown
                label={CONTAINER_CONSTANT.BUNDLE_IND}
                inputType="freeText"
                width="175px"
                mode={'multiple'}
                sort={false}
                value={chargeDetailsearchCriteria.bundleInds?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => chargeDetailVM.onMultipleDropdownChange(e, 'bundleInds')}
                options={bundleIndDropdownOption} />
        </div>
        , [chargeDetailsearchCriteria.bundleInds, chargeDetailVM, CONTAINER_CONSTANT.BUNDLE_IND])
    const memoSize = useMemo(() =>
        <div className='flex-row-item'>
            <NbisInputDropDown
                label={CONTAINER_CONSTANT.SIZE}
                width='175px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={chargeDetailsearchCriteria.cntrSizes?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => chargeDetailVM.onMultipleDropdownChange(e, 'cntrSizes')}
                options={chargeDetailState.dynamicOptions.sizeGroupDropdownOptions} />
        </div>
        , [chargeDetailsearchCriteria.cntrSizes, chargeDetailVM, chargeDetailState.dynamicOptions.sizeGroupDropdownOptions, CONTAINER_CONSTANT.SIZE])
    const memoType = useMemo(() =>
        <div className='flex-row-item'>
            <NbisInputDropDown
                label={CONTAINER_CONSTANT.TYPE}
                width='175px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={chargeDetailsearchCriteria.cntrTypes?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => chargeDetailVM.onMultipleDropdownChange(e, 'cntrTypes')}
                options={chargeDetailState.dynamicOptions.typeDropdownOptions} />
        </div>
        , [chargeDetailsearchCriteria.cntrTypes, chargeDetailVM, chargeDetailState.dynamicOptions.typeDropdownOptions, CONTAINER_CONSTANT.TYPE])
    const memoModalityOfArrival = useMemo(() =>
        <div className='flex-row-item'>
            <NbisInputDropDown
                label={CONTAINER_CONSTANT.MODALITY_OF_ARRIVAL}
                width='175px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={chargeDetailsearchCriteria.modalityArrivals?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => chargeDetailVM.onMultipleDropdownChange(e, 'modalityArrivals')}
                options={modalityDropdownOption} />
        </div>
        , [chargeDetailsearchCriteria.modalityArrivals, chargeDetailVM, CONTAINER_CONSTANT.MODALITY_OF_ARRIVAL])
    const memoModalityOfDeparture = useMemo(() =>
        <div className='flex-row-item'>
            <NbisInputDropDown
                label={CONTAINER_CONSTANT.MODALITY_OF_DEPARTURE}
                width='175px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={chargeDetailsearchCriteria.modalityDepartures?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => chargeDetailVM.onMultipleDropdownChange(e, 'modalityDepartures')}
                options={modalityDropdownOption} />
        </div>
        , [chargeDetailsearchCriteria.modalityDepartures, chargeDetailVM, CONTAINER_CONSTANT.MODALITY_OF_DEPARTURE])
    const memoEmptyInd = useMemo(() =>
        <div className='flex-row-item'>
            <NbisInputDropDown
                label={CONTAINER_CONSTANT.EMPTY_IND}
                width='175px'
                inputType="freeText"
                mode={'single'}
                sort={false}
                value={chargeDetailsearchCriteria.emptyInd ?? null}
                onChange={(e: any) => chargeDetailVM.onSearchDropdownChange(e, 'emptyInd')}
                options={emptyIndDropdownOption} />
        </div>
        , [CONTAINER_CONSTANT.EMPTY_IND, chargeDetailsearchCriteria.emptyInd, chargeDetailVM])
    const memoSpecialHandlingInd = useMemo(() =>
        <div className='flex-row-item'>
            <NbisInputDropDown
                label={CONTAINER_CONSTANT.SPECIAL_HANDLING_IND}
                width='175px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={chargeDetailsearchCriteria.specialHandlingInd?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => chargeDetailVM.onMultipleDropdownChange(e, 'specialHandlingInd')}
                options={chargeDetailState.dynamicOptions.specHandingCodeDropdownOptions} />
        </div>
        , [CONTAINER_CONSTANT.SPECIAL_HANDLING_IND, chargeDetailsearchCriteria.specialHandlingInd, chargeDetailState.dynamicOptions.specHandingCodeDropdownOptions, chargeDetailVM])
    const memoSpecialHandlingIndD1 = useMemo(() =>
        <div className='flex-row-item'>
            <NbisInputDropDown
                label={CONTAINER_CONSTANT.SPECIAL_HANDLING_IND_D1}
                width='175px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={chargeDetailsearchCriteria.specialHandlingIndD1?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => chargeDetailVM.onMultipleDropdownChange(e, 'specialHandlingIndD1')}
                options={chargeDetailState.dynamicOptions.specHandingCodeD1DropdownOptions} />
        </div>
        , [chargeDetailsearchCriteria.specialHandlingIndD1, chargeDetailVM, chargeDetailState.dynamicOptions.specHandingCodeD1DropdownOptions, CONTAINER_CONSTANT.SPECIAL_HANDLING_IND_D1])
    const memoInvNo = useMemo(() =>
        <div className='flex-row-item'>
            <InputField
                width='175px'
                maxLength={50}
                label={CONTAINER_CONSTANT.INV_CRE_NOTE_NO}
                type="text"
                value={chargeDetailsearchCriteria.docNo ?? ''}
                onChange={(e: any) => chargeDetailVM.onSearchInputTextChange(e, 'docNo', true)} />
        </div>
        , [chargeDetailsearchCriteria.docNo, chargeDetailVM, CONTAINER_CONSTANT.INV_CRE_NOTE_NO])
    const memobundleMasterNo = useMemo(() =>
        <div className='flex-row-item'>
            <InputField
                width='175px'
                maxLength={50}
                label={CONTAINER_CONSTANT.BUNDLE_NUMBER}
                type="text"
                value={chargeDetailsearchCriteria.bundleMasterNo ?? ''}
                onChange={(e: any) => chargeDetailVM.onSearchInputTextChange(e, 'bundleMasterNo')} />
        </div>
        , [chargeDetailsearchCriteria.bundleMasterNo, chargeDetailVM, CONTAINER_CONSTANT.BUNDLE_NUMBER])
    const memoAdjType = useMemo(() =>
        <div className='flex-row-item'>
            <NbisInputDropDown
                label={CONTAINER_CONSTANT.ADJ_TYPE}
                width='175px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={chargeDetailsearchCriteria.adjTypes?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => chargeDetailVM.onMultipleDropdownChange(e, 'adjTypes')}
                options={adjTypeForSearchDropdownOption} />
        </div>
        , [chargeDetailsearchCriteria.adjTypes, chargeDetailVM, CONTAINER_CONSTANT.ADJ_TYPE])

    const memoArrivalVesselCode = useMemo(() =>
        <div className='flex-row-item'>
            <InputField
                maxLength={6}
                style={{ "text-transform": "uppercase", width: '175px' }}
                label={CONTAINER_CONSTANT.VESSEL_CODE_ARRIVAL}
                type="text"
                value={chargeDetailsearchCriteria.arrivalVesselCode ?? ''}
                onChange={(e: any) => chargeDetailVM.onSearchInputTextChange(e, 'arrivalVesselCode', true)} />
        </div>
        , [chargeDetailsearchCriteria.arrivalVesselCode, chargeDetailVM, CONTAINER_CONSTANT.VESSEL_CODE_ARRIVAL])
    const memoArrivalVoyageCode = useMemo(() =>
        <div className='flex-row-item'>
            <InputField
                maxLength={10}
                style={{ "text-transform": "uppercase", width: '175px' }}
                label={CONTAINER_CONSTANT.VOYAGE_CODE_ARRIVAL}
                type="text"
                value={chargeDetailsearchCriteria.arrivalVoyageCode ?? ''}
                onChange={(e: any) => chargeDetailVM.onSearchInputTextChange(e, 'arrivalVoyageCode', true)} />
        </div>
        , [chargeDetailsearchCriteria.arrivalVoyageCode, chargeDetailVM, CONTAINER_CONSTANT.VOYAGE_CODE_ARRIVAL])
    const memoDepartureVesselCode = useMemo(() =>
        <div className='flex-row-item'>
            <InputField
                maxLength={6}
                style={{ "text-transform": "uppercase", width: '175px' }}
                label={CONTAINER_CONSTANT.VESSEL_CODE_DEPARTURE}
                type="text"
                value={chargeDetailsearchCriteria.departureVesselCode ?? ''}
                onChange={(e: any) => chargeDetailVM.onSearchInputTextChange(e, 'departureVesselCode', true)} />
        </div>
        , [chargeDetailsearchCriteria.departureVesselCode, chargeDetailVM, CONTAINER_CONSTANT.VESSEL_CODE_DEPARTURE])
    const memodepartureVoyageCode = useMemo(() =>
        <div className='flex-row-item'>
            <InputField
                maxLength={10}
                style={{ "text-transform": "uppercase", width: '175px' }}
                label={CONTAINER_CONSTANT.VOYAGE_CODE_DEPARTURE}
                type="text"
                value={chargeDetailsearchCriteria.departureVoyageCode ?? ''}
                onChange={(e: any) => chargeDetailVM.onSearchInputTextChange(e, 'departureVoyageCode', true)} />
        </div>
        , [chargeDetailsearchCriteria.departureVoyageCode, chargeDetailVM, CONTAINER_CONSTANT.VOYAGE_CODE_DEPARTURE])

    const memoStateCokd = useMemo(() =>
        <div className='flex-row-item'>
            <NbisInputDropDown
                label={CONTAINER_CONSTANT.COKD_STATUS}
                width='175px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={chargeDetailsearchCriteria.statusCOKDs?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => chargeDetailVM.onMultipleDropdownChange(e, 'statusCOKDs')}
                options={chargeDetailState.dynamicOptions.statusCOKDDropdownOptions} />
        </div>
        , [CONTAINER_CONSTANT.COKD_STATUS, chargeDetailsearchCriteria.statusCOKDs, chargeDetailState.dynamicOptions.statusCOKDDropdownOptions, chargeDetailVM])
    const memoCntrStatus = useMemo(() =>
        <div className='flex-row-item'>
            <NbisInputDropDown
                label={CONTAINER_CONSTANT.CNTR_STATUS}
                width='175px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={chargeDetailsearchCriteria.cntrStatus?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => chargeDetailVM.onMultipleDropdownChange(e, 'cntrStatus')}
                options={statusDropdownOptions} />
        </div>
        , [CONTAINER_CONSTANT.CNTR_STATUS, chargeDetailsearchCriteria.cntrStatus, chargeDetailVM])
    const memoOpsLine = useMemo(() =>
        <div className='flex-row-item'>
            <NbisInputDropDown
                label={CONTAINER_CONSTANT.OPS_LINE}
                width='175px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={chargeDetailsearchCriteria.opsLines?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => chargeDetailVM.onMultipleDropdownChange(e, 'opsLines')}
                options={chargeDetailState.dynamicOptions.opsLineDropdownOptions} />
        </div>
        , [CONTAINER_CONSTANT.OPS_LINE, chargeDetailsearchCriteria.opsLines, chargeDetailState.dynamicOptions.opsLineDropdownOptions, chargeDetailVM])
    const memoSeqNo = useMemo(() =>
        <div className='flex-row-item'>
            <InputField
                width='175px'
                maxLength={50}
                label={CONTAINER_CONSTANT.SEQ}
                type="text"
                value={chargeDetailsearchCriteria.displaySequence ?? ''}
                onChange={(e: any) => chargeDetailVM.onSearchInputTextChange(e, 'displaySequence')} />
        </div>
        , [chargeDetailsearchCriteria.displaySequence, chargeDetailVM, CONTAINER_CONSTANT.SEQ])
    const memoMarshallingTypes = useMemo(() =>
        <div className='flex-row-item'>
            <NbisInputDropDown
                label={CONTAINER_CONSTANT.MARSHALLING_TYPE}
                width='175px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={chargeDetailsearchCriteria.marshallingTypes?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => chargeDetailVM.onMultipleDropdownChange(e, 'marshallingTypes')}
                options={chargeDetailState.dynamicOptions.marshallingTypeDropdownOptions} />
        </div>
        , [CONTAINER_CONSTANT.MARSHALLING_TYPE, chargeDetailsearchCriteria.marshallingTypes, chargeDetailState.dynamicOptions.marshallingTypeDropdownOptions, chargeDetailVM])

    if (!enabledCriteria) return null;

    return <>
        <CriteriaItemContainer>
            {chargeDetailState.enabledSearchCriteria.states ? memoState : null}
            {chargeDetailState.enabledSearchCriteria.tariffCodes ? memoTarCode : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {chargeDetailState.enabledSearchCriteria.chargeOnCompanies ? memoChargeOnCompany : null}
            {chargeDetailState.enabledSearchCriteria.billToCompanies ? memoBillToCompany : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {chargeDetailState.enabledSearchCriteria.chargeTypes ? <>{memoChgType}</> : null}
            {chargeDetailState.enabledSearchCriteria.chargeTypes ? <>{memoSubChgType}</> : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {chargeDetailState.enabledSearchCriteria.cntrNos ? memoCntrNo : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {chargeDetailState.enabledSearchCriteria.cntrSizes ? memoSize : null}
            {chargeDetailState.enabledSearchCriteria.cntrTypes ? memoType : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {chargeDetailState.enabledSearchCriteria.cntrStatus ? memoCntrStatus : null}
            {chargeDetailState.enabledSearchCriteria.emptyInd ? memoEmptyInd : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {chargeDetailState.enabledSearchCriteria.bundleInds ? memoBundleInd : null}
            {chargeDetailState.enabledSearchCriteria.bundleMasterNo ? memobundleMasterNo : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {chargeDetailState.enabledSearchCriteria.modalityArrivals ? memoModalityOfArrival : null}
            {chargeDetailState.enabledSearchCriteria.modalityDepartures ? memoModalityOfDeparture : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {chargeDetailState.enabledSearchCriteria.arrivalVesselCode ? memoArrivalVesselCode : null}
            {chargeDetailState.enabledSearchCriteria.arrivalVoyageCode ? memoArrivalVoyageCode : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {chargeDetailState.enabledSearchCriteria.departureVesselCode ? memoDepartureVesselCode : null}
            {chargeDetailState.enabledSearchCriteria.departureVoyageCode ? memodepartureVoyageCode : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {chargeDetailState.enabledSearchCriteria.specialHandlingInd ? memoSpecialHandlingInd : null}
            {chargeDetailState.enabledSearchCriteria.specialHandlingIndD1 ? memoSpecialHandlingIndD1 : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {chargeDetailState.enabledSearchCriteria.adjTypes ? memoAdjType : null}
            {chargeDetailState.enabledSearchCriteria.statusCOKDs ? memoStateCokd : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {chargeDetailState.enabledSearchCriteria.docNo ? memoInvNo : null}
            {chargeDetailState.enabledSearchCriteria.operationTypes ? memoOperationType : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {chargeDetailState.enabledSearchCriteria.opsLines ? memoOpsLine : null}
            {chargeDetailState.enabledSearchCriteria.marshallingTypes ? memoMarshallingTypes : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {chargeDetailState.enabledSearchCriteria.seqNo ? memoSeqNo : null}
        </CriteriaItemContainer>
    </>;
}
export default memo(ChargeSearchForm);