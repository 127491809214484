import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { ManualChargeHeaderEntity } from "domain/entity/ManualCharge/ManualChargeHeaderEntity";
import { ManualChargeHeaderRequestEntity } from "domain/entity/ManualCharge/ManualChargeHeaderRequestEntity";
import { axiosGetData, axiosPostData } from "../axios/AxiosBasicImpl";
import chargeAxiosInstance from "../axios/chargeAxiosInstance";
import { ManualChargeHeaderRepository } from "./ManualChargeHeaderRepo";
import { ManualChargePrintCriteria } from "domain/entity/ManualCharge/ManualChargePrintCriteria";

export const ManualChargeHeaderRepoImpl = () : ManualChargeHeaderRepository => {
    const url = "/v1/manualchargeHeaderSearch";
    const accTmlUrl = "/v1/manualchargeHeaderAccTml";
    const resumeAccTmlUrl = "/v1/manualchargeHeaderResumeAccTml";
    const confrimUrl = "/v1/manualchargeHeaderConfirm";
    const unConfrimUrl = "/v1/manualchargeHeaderUnConfirm";
    const genStdBillUrl = "/v1/manualchargeHeaderGenStdBill";
    const deleteUrl = "/v1/manualchargeHeaderDelete";

    /**
     * Search the manual charge header by criteria
     * @param request 
     * @returns 
     */
    const getManualChargeHeader = async (request: ManualChargeHeaderRequestEntity) : Promise<ManualChargeHeaderEntity[]> => {
        let res = null;
        try {
            res = await axiosPostData(chargeAxiosInstance, `${url}`, request) as ResponseEntity;            
        } catch (err) {
            throw err;
        }
        if(res.success){
            const data = res.data;
            return data;
        }else{
            throw new Error(res.msg as string)
        }
            
    }

    /**
     * Account Terminal the manual charge(S) and Non-Ops charge(s)
     * @param manChargeHdrs 
     * @returns 
     */
    const accTml = async (manChargeHdrs: ManualChargeHeaderEntity[]) : Promise<ResponseEntity> => {
        try {
            return await axiosPostData(chargeAxiosInstance, `${accTmlUrl}`, manChargeHdrs) as ResponseEntity;            
        } catch (err) {
            throw err;
        }
    }

    /**
     * Resume Account Terminal the manual charge(s) and Non-Ops charge(s)
     * @param manChargeHdrs 
     * @returns 
     */
    const resumeAccTml = async (manChargeHdrs: ManualChargeHeaderEntity[]) : Promise<ResponseEntity> => {
        try {
            return await axiosPostData(chargeAxiosInstance, `${resumeAccTmlUrl}`, manChargeHdrs) as ResponseEntity;            
        } catch (err) {
            throw err;
        }
    }

    /**
     * Confirm the manual charge(s) and Non-Ops charge(s)
     * @param manChargeHdrs 
     * @returns 
     */
    const confirm = async (manChargeHdrs: ManualChargeHeaderEntity[]) : Promise<ResponseEntity> => {
        try {
            return await axiosPostData(chargeAxiosInstance, `${confrimUrl}`, manChargeHdrs) as ResponseEntity;            
        } catch (err) {
            throw err;
        }
    }

    /**
     * UnConfirm the manual charge(s) and Non-Ops charge(s)
     * @param manChargeHdrs 
     * @returns 
     */
    const unConfirm = async (manChargeHdrs: ManualChargeHeaderEntity[]) : Promise<ResponseEntity> => {
        try {
            return await axiosPostData(chargeAxiosInstance, `${unConfrimUrl}`, manChargeHdrs) as ResponseEntity;            
        } catch (err) {
            throw err;
        }
    }

    /**
     * Get the manual charge header by id
     * @param id 
     * @returns 
     */
    const getManualChargeHeaderById = async(id: number) : Promise<ManualChargeHeaderEntity> => {
        let res = null;
        try {
            res = await axiosGetData(chargeAxiosInstance, `${url}`, [id?.toString()]) as ResponseEntity;            
        } catch (err) {
            throw err;
        }
        if(res.success){
            const data = res.data;
            return data;
        }else{
            throw new Error(res.msg as string)
        }
    }

    /**
     * Generate the Non-Ops Charge from Standar Bill
     * @param manChargeHdrs 
     * @returns 
     */
    const genStdBillNonOps = async (manChargeNos: string[]) : Promise<ResponseEntity> => {
        try {
            return await axiosPostData(chargeAxiosInstance, `${genStdBillUrl}`, manChargeNos) as ResponseEntity;            
        } catch (err) {
            throw err;
        }
    }

    /**
     * Delete Manual Charge or Non Ops Charge
     * @param manChargeHdrs 
     * @returns 
     */
    const deleteManCharge = async (manChgHdrIds: number[]) : Promise<string> => {
        return await axiosPostData(chargeAxiosInstance, `${deleteUrl}`, manChgHdrIds).then(res => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "Delete Non Ops/Manual Charge Failure."
                if (res.data && typeof (res.data) === "string") {
                    errorMessage = res.data;
                }
                return "Error:" + errorMessage; 
            }
        }).catch(err => {
            return "Error:" + err.Message;
        });
    }

    const reprintReport= async(printCriteria: ManualChargePrintCriteria): Promise<ResponseEntity> => {
        const newUrl = '/v1/manualchargeHeader/reprintReport'
        return await axiosPostData(chargeAxiosInstance, `${newUrl}`, printCriteria);
    }

    const previewReport = async (printCriteria: ManualChargePrintCriteria): Promise<any> => {
        const newUrl = "/v1/manualchargeHeader/previewReport";

        return axiosPostData(chargeAxiosInstance, `${newUrl}`, printCriteria).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }

    return {
        getManualChargeHeader: getManualChargeHeader,      
        accTml: accTml,  
        resumeAccTml: resumeAccTml,
        confirm: confirm,
        unConfirm: unConfirm,
        genStdBillNonOps: genStdBillNonOps,
        deleteManCharge: deleteManCharge,
        getManualChargeHeaderById: getManualChargeHeaderById,
        reprintReport: reprintReport,
        previewReport: previewReport
    }
}