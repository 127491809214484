import { SearchPreferenceEntity } from "domain/entity/Common/SearchPreferenceEntity";
import { CompanyEntity, EMPTY_COMPANY_ENTITY } from "domain/entity/Company/CompanyEntity";
import { CompanyEnabledSearchCriteria, DEFAULT_ENABLED_COMPANY_SEARCH_CRITERIA } from "presentation/constant/Company/CompanyEnabledSearchCriteria";
import { CompanySearchCriteria, EMPTY_COMPANY_SEARCH_CRITERIA } from "presentation/constant/Company/CompanySearchCriteria";
import { RefObject } from "react";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";


export interface CompanyMaintenanceDropdownOptions {
    companyTypeDropdownOptions: DropdownProps[],
    activeIndDropdownOptions: DropdownProps[],
    masterCompanyDropdownOptions: DropdownProps[],
    allianceCompanyDropdownOptions: DropdownProps[],
    countryCodeDropdownOptions: DropdownProps[]
}

export interface CompanyMaintenanceModel {
    isLoading: boolean,
    isShowRightPanel: boolean,
    isFilterSearchCriteria: boolean,
    isEditSearchCriteria: boolean,
    checkboxRef: { [key: string]: HTMLElement | null },
    enabledSearchCriteria: CompanyEnabledSearchCriteria,
    checkboxValue: CompanyEnabledSearchCriteria,
    searchCriteria: CompanySearchCriteria,
    highlightedCheckboxKey?: string | null
    currentTableRef?: RefObject<any>,
    searchCounter: number,
    dynamicOptions: CompanyMaintenanceDropdownOptions,
    searchPreferences: SearchPreferenceEntity<CompanySearchCriteria, CompanyEnabledSearchCriteria>[],
    currentSearchPreference: SearchPreferenceEntity<CompanySearchCriteria, CompanyEnabledSearchCriteria> | null,
    isShowDetail: boolean,
    viewState: CompanyMaintenanceChangeState,
    currentCompany: CompanyEntity,
    selectCompanyRows: CompanyEntity[],
    companys: CompanyEntity[],
    isShowCriteriaPanel: boolean,
    isBackFromDetail: boolean,
    isAllowAutoSearch: boolean,
    forceRefresh: boolean,
}

export interface CompanyMaintenanceChangeState extends BaseViewChangeSate {

}

export const EMPTY_COMPANY_MAINTENANCE_MODEL: CompanyMaintenanceModel = {
    isLoading: false,
    isShowRightPanel: false,
    isFilterSearchCriteria: false,
    isEditSearchCriteria: false,
    checkboxRef: {},
    enabledSearchCriteria: { ...DEFAULT_ENABLED_COMPANY_SEARCH_CRITERIA },
    checkboxValue: { ...DEFAULT_ENABLED_COMPANY_SEARCH_CRITERIA },
    searchCriteria: { ...EMPTY_COMPANY_SEARCH_CRITERIA },
    searchCounter: 0,
    dynamicOptions: {
        companyTypeDropdownOptions: [],
        activeIndDropdownOptions: [],
        masterCompanyDropdownOptions: [],
        allianceCompanyDropdownOptions: [],
        countryCodeDropdownOptions: []
    },
    searchPreferences: [],
    currentSearchPreference: null,
    isShowDetail: false,
    viewState: {
        isSliderOpen: false,
        isTabularDataActive: false,
        isAdd: false,
        isRead: true,
        isEditable: false,
        isSaveClicked: false,
        lastEditRowId: "",
        isSelected: false,
        slider: {
            isAdd: false,
            isRead: false,
            isEditable: false
        }
    },
    currentCompany: EMPTY_COMPANY_ENTITY,
    selectCompanyRows: [],
    companys: [],
    isShowCriteriaPanel: false,
    isBackFromDetail: false,
    isAllowAutoSearch: true,
    forceRefresh: false
}