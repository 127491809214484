import { useChargeDetailTracked } from "presentation/store/ChargeDetailMaintenance/ChargeDetailProvider";
import { useMemo } from "react";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import UpdateCntrStatusPanel from "../LeftPanel/UpdateCntrStatusPanel";
import UpdateVesselVoyageMasterSearchPanel from "../LeftPanel/UpdateVesselVoyageMasterSearchPanel";
import UpdateVesselVoyageMasterTablePanel from "../LeftPanel/UpdateVesselVoyageMasterTablePanel";
import AssignSeqNoPanel from "./AssignSeqNoPanel";
import ChargeBatchUpdatePanel from "./ChargeBatchUpdatePanel";
import CntrInfoPanel from "./CntrInfoPanel";

interface IContentHtml {
    leftWidth: string;
    rightWidth: string;
}

const ChargeDetailMaintenancePanel = () => {
    const [chargeDetailState] = useChargeDetailTracked();
    const { isShowBatchUpdatePanel, isShowRightEditPanel, isShowCntrInfoPanel, isShowUpdateVesselVoyageMasterSearchPanel,
        isShowUpdateVesselVoyageMasterTablePanel, isShowAssignSeqNoPanel, isShowUpdateStatus } = chargeDetailState;

    const memoBatchUpdatePanel = useMemo(() => {
        return <ChargeBatchUpdatePanel />
    }, []);

    const memoCntrInfoPanel = useMemo(() => {
        return <CntrInfoPanel />
    }, []);

    const memoUpdateVesselVoyageSearchPanel = useMemo(() => {
        return <UpdateVesselVoyageMasterSearchPanel />
    }, []);

    const memoUpdateVesselVoyageTablePanel = useMemo(() => {
        return <UpdateVesselVoyageMasterTablePanel />
    }, []);

    const memoAssignSeqNoPanel = useMemo(() => {
        return <AssignSeqNoPanel />
    }, []);

    const memoUpdateCntrStatusPanel = useMemo(() => {
        return <UpdateCntrStatusPanel />
    }, []);


    const getSliderContent = (): IContentHtml => {
        if (isShowBatchUpdatePanel || isShowCntrInfoPanel || isShowUpdateVesselVoyageMasterTablePanel || isShowAssignSeqNoPanel) {
            return { leftWidth: '0%', rightWidth: '100%' };
        } else if ((isShowRightEditPanel || isShowUpdateVesselVoyageMasterSearchPanel)) {
            return { leftWidth: '100%', rightWidth: '0%' };
        } else {
            return { leftWidth: '100%', rightWidth: '0%' };
        }
    }

    const content: IContentHtml = getSliderContent();

    let memoRightChild;
    if (isShowBatchUpdatePanel) {
        memoRightChild = memoBatchUpdatePanel;
    } else if (isShowCntrInfoPanel) {
        memoRightChild = memoCntrInfoPanel;
    } else if (isShowUpdateVesselVoyageMasterTablePanel) {
        memoRightChild = memoUpdateVesselVoyageTablePanel;
    } else if (isShowAssignSeqNoPanel) {
        memoRightChild = memoAssignSeqNoPanel;
    } else {
        <></>
    }

    return <div className={`main-comp-wrapper${isShowUpdateVesselVoyageMasterSearchPanel || isShowUpdateStatus ? '' : ' im-hide-side-form-draggable'}`}>
        <SliderPanel
            isOpen={true}
            leftSectionWidth={content.leftWidth}
            rightSectionWidth={content.rightWidth}
            draggable={false}
            leftChildren={isShowUpdateVesselVoyageMasterSearchPanel ? memoUpdateVesselVoyageSearchPanel :
                (isShowUpdateStatus ? memoUpdateCntrStatusPanel : <></>)}
            rightChildren={memoRightChild}
        />
    </div>
}

export default ChargeDetailMaintenancePanel;