import { EMPTY_TARIFF_COMPONENT_ENTITY, TariffComponentEntity } from "domain/entity/TariffCode/TariffComponentEntity";
import { EMPTY_TARIFF_COMP_VALUE_ENTITY, TariffComponentValueEntity } from "domain/entity/TariffCode/TariffComponentValueEntity";
import { BaseViewChangeSate } from "../BaseViewChangeState";

export interface TariffComponentDropdownOptions {

}
export interface MasterChangeState extends BaseViewChangeSate {
    editingEntity: TariffComponentEntity,
}
export interface DetailChangeState extends BaseViewChangeSate {
    currentSelectItem: TariffComponentValueEntity,
    editingEntity: TariffComponentValueEntity,
    detailList: TariffComponentValueEntity[],
    selectedRows: TariffComponentValueEntity[],
}

export interface TariffComponentMaintenanceModel {
    isLoading: boolean,
    tableData: TariffComponentEntity[],
    currentSelectedRow: TariffComponentEntity,
    selectedRows: TariffComponentEntity[],
    dynamicOptions: TariffComponentDropdownOptions,
    masterState: MasterChangeState,
    detailState: DetailChangeState,
    isBackMaster: boolean,
    forceRefresh: boolean,
}

export const EMPTY_MASTER_TARIFF_COMPONENTANY_MODEL: TariffComponentMaintenanceModel = {
    isLoading: false,
    tableData: [],
    currentSelectedRow: { ...EMPTY_TARIFF_COMPONENT_ENTITY },
    selectedRows: [],
    dynamicOptions: {},
    masterState: {
        isSliderOpen: false,
        isTabularDataActive: false,
        isAdd: false,
        isRead: false,
        isEditable: false,
        isSaveClicked: false,
        lastEditRowId: "",
        isSelected: false,
        slider: {
            isAdd: false,
            isRead: false,
            isEditable: false
        },
        editingEntity: EMPTY_TARIFF_COMPONENT_ENTITY,
    },
    isBackMaster: false,
    detailState: {
        detailList: [],
        selectedRows: [],
        currentSelectItem: EMPTY_TARIFF_COMP_VALUE_ENTITY,
        editingEntity: EMPTY_TARIFF_COMP_VALUE_ENTITY,
        isSliderOpen: false,
        isTabularDataActive: false,
        isAdd: false,
        isRead: false,
        isEditable: false,
        isSaveClicked: false,
        lastEditRowId: "",
        isSelected: false,
        slider: {
            isAdd: false,
            isRead: false,
            isEditable: false
        },
    },
    forceRefresh: false
}