import { SelectionChangedEvent } from "ag-grid-community";
import { TariffCodeComponentEntity } from "domain/entity/TariffCode/TariffCodeComponentEntity";
import _ from "lodash";
import { transferRowData } from "presentation/constant/ManualCharge/ManChargeDtlColumnDefinition";
import { INITIAL_TARIFF_CODE_COMP_COL_DEF } from "presentation/constant/TariffCode/TariffCodeComponentColumnDefinition";
import { TariffCodeConstant } from "presentation/constant/TariffCode/TariffCodeConstant";
import { useTariffCodeVM } from "presentation/hook/TariffCode/useTariffCodeVM";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useTariffCodeTracked } from "presentation/store/TariffCode/TariffCodeProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";

const TariffCodeComponentTablePanel: React.FC = () => {
    const [tariffCodeState] = useTariffCodeTracked();;
    const tariffCodeVM = useTariffCodeVM();
    const { masterState, componentState } = tariffCodeState;
    const { tariffCodeComponentList, selectedRows } = componentState;
    const { isAdd, isEditable, isRead } = masterState;
    const { isAdd: isCompAdd, isEditable: isCompEdit } = componentState;
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    const [anainfoState] = useANAInfoTracked();
    const { allowCreate } = anainfoState;
    let gridRef: any = useRef();

    useEffect(() => {
        if (!onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();
    }, [onTableSelectionClicked])

    useEffect(() => {
        // const columnDefs = (INITIAL_TARIFF_CODE_COMP_COL_DEF.slice());

        //gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
        if (!onTableSelectionClicked) {
            gridRef.current?.gridRef.current.api?.deselectAll();
        }
    })

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        tariffCodeVM.updateSelectedComponentRows(selectedRows);
    }, [tariffCodeVM])

    const handleRowDoubleClick = useCallback((tarCodeComp: TariffCodeComponentEntity) => {
        tariffCodeVM.onComponentRowClick(tarCodeComp);
    }, [tariffCodeVM])

    const handleDelete = useCallback(async () => {
        if (_.isEmpty(selectedRows)) return;
        tariffCodeVM.onComponentDelete(selectedRows);
    }, [selectedRows, tariffCodeVM])

    const handleAddClick = useCallback(async () => {
        tariffCodeVM.onComponentAdd();
    }, [tariffCodeVM])


    const tbBtns = useMemo(() => {
        let btns = [];
        if (allowCreate && isAdd && !isCompAdd && !isCompEdit) {
            btns.push({
                id: 'onDeleteButton',
                icon: 'Icon-trash',
                title: 'Delete'
            })
        }
        return btns;
    }, [allowCreate, isAdd, isCompAdd, isCompEdit])

    const memoTariffCodeComponentTable = useMemo(() => {
        return <NbisTable
            id='TariffCodeComponent-table'
            headerLabel={TariffCodeConstant.Table.TARIFF_COMP}
            columns={INITIAL_TARIFF_CODE_COMP_COL_DEF.slice()}
            data={transferRowData(tariffCodeComponentList ?? [])}
            headerActionButtons={tbBtns}
            showPaginator={false}
            editable={false}
            showAddIcon={allowCreate && isAdd && !isCompAdd && !isCompEdit}
            showUploadIcon={!(isEditable && isRead) && !isCompAdd && !isCompEdit}
            showDeleteButton={false}
            showReloadIcon={false}
            isScrollHighlighted={true}
            selectionMode={false}
            rowSelection={"multiple"}
            onAddClick={handleAddClick}
            onSelectionChanged={handleSelectionChange}
            onDeleteButton={handleDelete}
            onRowDoubleClick={(e: any) => handleRowDoubleClick(e.data)}
            gridHeight="customHeight"
            customHeight="calc(100vh - 75px)"
            ref={gridRef} />;
    }, [tariffCodeComponentList, tbBtns, allowCreate, isAdd, isCompAdd, isCompEdit, isEditable, isRead, handleAddClick, handleSelectionChange, handleDelete, handleRowDoubleClick])

    return <>
        {<TableWrapper>{memoTariffCodeComponentTable}</TableWrapper>}
    </>;
}

export default memo(TariffCodeComponentTablePanel);