import { useChargeDetailVM } from "presentation/hook/ChargeDetailMaintenance/useChargeDetailVM";
import { ChargeDetailModel } from "presentation/model/ChargeDetailMaintenance/ChargeDetailModel";
import NbisInputDropDown from "presentation/view/components/NbisInputDropDown";
import NumberInputComponent from "presentation/view/components/NumberInputComponent";
import { useCallback } from "react";

export const OneOffRateAccordion = ({ chargeDetailState }: { chargeDetailState: ChargeDetailModel }) => {
    const chargeBatchUpdate = chargeDetailState.chargeBatchUpdate
    const chargeDetailVM = useChargeDetailVM();
    const onNumberFieldChange = useCallback((e: any, fieldName: string, fieldValue: any) => {
        chargeDetailVM.onNumberFieldChange(fieldName, fieldValue, chargeBatchUpdate, 'chargeBatchUpdate');
    }, [chargeDetailVM, chargeBatchUpdate]);
    return (
        <>
            <div className={'im-flex-row'} style={{ marginBottom: '24px' }}>
                <div className='flex-row-item'>
                    <NumberInputComponent
                        label='Assign One-off Rate'
                        width='330px'
                        fieldName={"rate"}
                        value={chargeBatchUpdate.rate ?? ''}
                        disabled={false}
                        decimalPlaces={2}
                        onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
                    />
                    {/*<InputField
                        label='Assign One-off Rate'
                        width='330px'
                        type="number"
                        value={chargeBatchUpdate.rate || ''}
                        onChange={(e:any) => chargeDetailVM.onInputTextChange(e, 'rate', chargeBatchUpdate, 'chargeBatchUpdate')}
                        errorMessage={chargeBatchUpdate?.allFormState?.rate??""}
                        />*/}
                </div>
            </div>

            <div className={'im-flex-row'} style={{ marginBottom: '24px' }}>
                <div className='flex-row-item'>
                    <NbisInputDropDown
                        label='Reason Code'
                        width='330px'
                        inputType="freeText"
                        mode={"single"}
                        value={chargeBatchUpdate.reasonCode}
                        onChange={(e: any) => chargeDetailVM.onDropdownChange(e, 'reasonCode', chargeBatchUpdate, 'chargeBatchUpdate')}
                        options={chargeDetailState.dynamicOptions.reasonCodeDropdownOptions}
                        errorMessage={chargeBatchUpdate?.allFormState?.reasonCode ?? ""}
                    />
                </div>
            </div>
        </>
    )
}