import { RecalProcessAndGenConstant } from "presentation/constant/Recalculation/RecalProcessAndGenConstant";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useRecalProcessAndGenVM } from "presentation/hook/Recalculation/useRecalProcessAndGenVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useRecalProcessAndGenMaintenanceTracked } from "presentation/store/Recalculation/RecalProcessAndGenMaintenanceProvider";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import { memo, useCallback, useState } from "react";
import { HPHButton, Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const RecalProcessAndGenTitleBar: React.FC = () => {
    const recalProcessAndGenVM = useRecalProcessAndGenVM();
    const RECAL_PROCESS_GEN_CONSTANT = RecalProcessAndGenConstant;
    const [recalProcessAndGenState] = useRecalProcessAndGenMaintenanceTracked();
    const messageBarVM = useMessageBarVM();

    const { searchCriteria } = recalProcessAndGenState;

    //const [ isShowDelete, setIsShowDelete ] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = useCallback(async () => {
        setIsLoading(true);
        // recalProcessAndGenVM.handleSubmitClick()

        try {
            recalProcessAndGenVM.handleSubmitClick()
            await recalProcessAndGenVM.onSubmit(searchCriteria).then((data) => {
                if (data && (data["mandatoryCheckFail"] || data["recalSubmitFailed"])) {
                    messageBarVM.showError(data["mandatoryCheckFail"]?.toString() || data["recalSubmitFailed"]?.toString());
                    return;
                } else {
                    messageBarVM.showSuccess("The selected Recal submission job(s) is/are submitted to the server.");
                }
            })

        } catch (error) {
            setIsLoading(false);
            messageBarVM.showError('Recalculation submit Failed.');
        } finally {
            setIsLoading(false)
        }

    }, [messageBarVM, recalProcessAndGenVM, searchCriteria]);

    const handleSearchCriteriaResetClick = useCallback(() => {
        recalProcessAndGenVM.onSearchCriteriaResetClick();
    }, [recalProcessAndGenVM]);

    return <Sidebarheader style={{ width: '100%', display: "flex", alignItems: "center" }}>
        {(isLoading) && <Loader Indicator="Spinner" size="Medium" />}
        <HeaderTitle>{RECAL_PROCESS_GEN_CONSTANT.TITLE}</HeaderTitle>
        <StyledAction className="tm-animated-wrapper">
            {/*<div className="add-seperator"/>*/}
            {<HPHButton disabled={false} label={WorkspaceConstant.Common.BUTTON_CLEAR} size={'Small'} theme={'Secondary'} onClick={handleSearchCriteriaResetClick} />}
            {<HPHButton disabled={false} label={'Submit'} size={'Small'} theme={'Primary'} onClick={handleSubmit} />}

            {/*isShowDelete && <ConfirmDeleteModal headerText={`${WorkspaceConstant.Common.BUTTON_DELETE} ${RECAL_PROCESS_GEN_CONSTANT.TITLE}`} 
            contentText={`${MessageConstant.common.DELETE_ALERT_TITLE}\n${MessageConstant.common.DELETE_ALERT_MESSAGE}`}
            visible={isShowDelete}
            onCancelClick={handleCancel}
            onDeleteClick={handleDelete}
        />*/}
        </StyledAction>
    </Sidebarheader>
}

export default memo(RecalProcessAndGenTitleBar);