import { SelectionChangedEvent } from "ag-grid-community";
import { TariffComponentEntity } from "domain/entity/TariffCode/TariffComponentEntity";
import _ from "lodash";
import { INITIAL_TARIFF_COMPONENT_COL_DEF } from "presentation/constant/TariffComponent/TariffComponentMaintenanceColumnDefinition";
import { TariffComponentMaintenanceConstant } from "presentation/constant/TariffComponent/TariffComponentMaintenanceConstant";
import { useTariffComponentMaintenanceVM } from "presentation/hook/TariffComponent/useTariffComponentMaintenanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useTariffComponentMaintenanceTracked } from "presentation/store/TariffComponent/TariffComponentMaintenanceProvider";
import RightClickMenuWithMoveAfter from "presentation/view/components/NbisRightClickMenu/RightClickMenuWithMoveAfter";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { createRef, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Loader } from "veronica-ui-component/dist/component/core";


const TariffComponentTablePanel: React.FC = () => {
    const [tariffComponentState] = useTariffComponentMaintenanceTracked();
    const tariffComponentVM = useTariffComponentMaintenanceVM();
    const gridRef: any = useRef(null);
    // const rightClickRef: any = createRef();
    // const [anainfoState] = useANAInfoTracked();
    // const {allowUpdate} = anainfoState;
    const messageBarVM = useMessageBarVM();
    const [anainfoState] = useANAInfoTracked();
    const { allowUpdate } = anainfoState;
    const rightClickRef: any = createRef();
    // for right click menu with move after begin
    const [showMoveCursor, setShowMoveCursor] = useState<boolean>(false);
    const [allRows, setAllRows] = useState<TariffComponentEntity[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [initialAllRows, setInitialAllRows] = useState<boolean>(true);


    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();
        tariffComponentVM.updateSelectedRows(selectedRows);
    }, [tariffComponentVM])

    const handleAddClick = useCallback(() => {
        tariffComponentVM.onAdd();
    }, [tariffComponentVM])

    const handleRowDoubleClick = useCallback((entity: TariffComponentEntity) => {
        tariffComponentVM.onRowDoubleClick(entity);
    }, [tariffComponentVM])

    const getAllRows = useCallback(() => {
        return allRows;
    }, [allRows]);

    const getSelectedRows = useCallback(() => {
        return tariffComponentState.selectedRows;
    }, [tariffComponentState.selectedRows]);

    useEffect(() => {
        const handleRightClickMenu = (event: any) => {
            if (_.isEmpty(tariffComponentState.selectedRows) || !allowUpdate) {
                return;
            }
            event.preventDefault();
            rightClickRef.current.show(event);
        };
        document.getElementById('myDiv')?.addEventListener("contextmenu", handleRightClickMenu);

        return () => {
            document.getElementById('myDiv')?.removeEventListener("contextmenu", handleRightClickMenu);
        };
    }, [messageBarVM, rightClickRef, allowUpdate, tariffComponentState.selectedRows]);

    // for right click menu with move after begin
    const onRefreshRow = useCallback((newAllRows: any[]) => {
        setAllRows(newAllRows);
        tariffComponentVM.updateSelectedRows([]);
        setShowMoveCursor(true);
    }, [tariffComponentVM]);

    const onMoveCancel = useCallback(() => {
        setInitialAllRows(true);
        setAllRows(tariffComponentState.tableData);
        tariffComponentVM.updateSelectedRows([]);
        setShowMoveCursor(false);
    }, [tariffComponentState.tableData, tariffComponentVM]);

    const onSaveMove = useCallback(async (newAllRows: any[]) => {
        if (_.isEmpty(newAllRows)) return;

        setIsLoading(true);

        tariffComponentVM.onApply(newAllRows).then((res) => {
            if (!res || !res.success) {
                messageBarVM.showError('Move failed.');
                setInitialAllRows(true);
                setIsLoading(false);

                tariffComponentVM.updateSelectedRows([]);
                setShowMoveCursor(false);
            } else {
                tariffComponentVM.onSearch().then((data) => {
                    setInitialAllRows(true);
                    setIsLoading(false);

                    tariffComponentVM.updateSelectedRows([]);
                    setShowMoveCursor(false);
                }).catch((e) => {
                    setInitialAllRows(true);
                    setIsLoading(false);

                    tariffComponentVM.updateSelectedRows([]);
                    setShowMoveCursor(false);
                })
            }
        })
    }, [messageBarVM, tariffComponentVM]);

    const memoTable = useMemo(() => {
        return (
            <>
                {(allowUpdate) && <RightClickMenuWithMoveAfter disabled={_.isEmpty(getSelectedRows())}
                    rightClickRef={rightClickRef} selectedRows={getSelectedRows()} allRows={getAllRows()}
                    onSaveMove={onSaveMove} onCancel={onMoveCancel} onRefreshRow={onRefreshRow}
                    showConfirmMove={showMoveCursor} priorityName="seq" />}
                <div id="myDiv">
                    <NbisTable
                        id='tariff-omponent-table'
                        headerLabel={TariffComponentMaintenanceConstant.TITLE}
                        isNewColumnSetting={true}
                        columns={INITIAL_TARIFF_COMPONENT_COL_DEF}
                        data={allRows ?? []}
                        showPaginator={false}
                        editable={false}
                        showAddIcon={true}
                        onAddClick={handleAddClick}
                        showDeleteButton={false}
                        showReloadIcon={false}
                        isScrollHighlighted={true}
                        selectionMode={true}
                        showCutCursor={showMoveCursor}
                        isRowHighligted={true}
                        onSelectionChanged={handleSelectionChange}
                        onRowDoubleClick={(e: any, entity: TariffComponentEntity) => handleRowDoubleClick(entity)}
                        gridHeight="customHeight"
                        customHeight="calc(100vh - 75px)"
                        ref={gridRef}
                        rowSelection={showMoveCursor ? "single" : "multiple"}
                    />
                </div>
            </>
        );
    }, [allowUpdate, getSelectedRows, rightClickRef, getAllRows, onSaveMove, onMoveCancel, onRefreshRow, showMoveCursor, allRows, handleAddClick, handleSelectionChange, handleRowDoubleClick])

    useEffect(() => {
        if (initialAllRows && tariffComponentState.tableData && !_.isEmpty(tariffComponentState.tableData)) {
            setIsLoading(true);
            setAllRows(tariffComponentState.tableData.map((tariffComponentEntity, index) => ({
                ...tariffComponentEntity,
                index: index || 0,
                flag_showCutCursor: false
            })));
            setInitialAllRows(false);
        }

        setIsLoading(false);
    }, [allRows, initialAllRows, tariffComponentState.tableData]);

    return <><TableWrapper>
        {(isLoading) && <Loader Indicator="Spinner" size="Medium" />}
        {memoTable}
        {tariffComponentState.forceRefresh && <></>}
    </TableWrapper>
    </>;
}

export default memo(TariffComponentTablePanel);
