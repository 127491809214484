import { useGeneralProposalTracked } from "presentation/store/GeneralProposal/GeneralProposalProvider";
import { memo, useMemo } from "react";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import GeneralProposalTablePanel from "../Table/GeneralProposalTablePanel";
import TariffItemRightPanel from "./TariffItemRightPanel";

const GeneralProposalRightPanel = () => {
    const [generalProposalState] = useGeneralProposalTracked();
    // const generalProposalVM = useGeneralProposalVM();
    const { isShowTariffItemTierPanel } = generalProposalState;

    const memoGeneralProposalTable = useMemo(() => {
        return <div style={isShowTariffItemTierPanel ? { marginLeft: "15px" } : {}}><GeneralProposalTablePanel /></div>
    }, [isShowTariffItemTierPanel])

    // const memoTariffItemRightTable = useMemo(() => {
    //     return <TariffItemRightPanel/>
    // },[])

    const memoTariffItemTierTablePanel = useMemo(() => {
        return <div style={{ marginLeft: "15px" }}><TariffItemRightPanel /></div>
    }, [])

    return <>
        <div className={`main-comp-wrapper${(isShowTariffItemTierPanel) ? '' : ' im-hide-side-form-draggable'}`}>

            <SliderPanel
                isOpen={true}
                draggable={true}
                leftSectionWidth={isShowTariffItemTierPanel ? "30%" : "100%"}
                rightSectionWidth={isShowTariffItemTierPanel ? "70%" : "0%"}
                leftChildren={memoGeneralProposalTable}
                rightChildren={memoTariffItemTierTablePanel}
            />
        </div>
    </>
}

export default memo(GeneralProposalRightPanel);