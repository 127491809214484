/* eslint-disable import/extensions */
import styled, { createGlobalStyle } from 'styled-components';
import IconAngleDown from 'veronica-ui-component/dist/assets/Icon/Icon-angle-down.svg';
import IconDrag from 'veronica-ui-component/dist/assets/Icon/Icon-drag.svg';
import IconFilterFill from 'veronica-ui-component/dist/assets/Icon/Icon-filter-fill.svg';
import IconSearch from 'veronica-ui-component/dist/assets/Icon/Icon-search.svg';
import IconSortAlphaAlt from 'veronica-ui-component/dist/assets/Icon/Icon-sort-alpha-alt.svg';
import IconSortAlpha from 'veronica-ui-component/dist/assets/Icon/Icon-sort-alpha.svg';
import {
  Background1,
  Background5,
  Background8,
  HPHBlack,
  HPHGrey,
  HPHGreyDark,
  HPHGreyLight,
  HPHSeaBlue,
  HPHSeaBlueDark,
  HPHSkyBlue,
  HPHWhite,
  MontserratFont
} from 'veronica-ui-component/dist/component/core/Colors';
import { scrollbarStyles } from 'veronica-ui-component/dist/component/core/styled/scrollbar.styled';
// import { TableQuickSearchProps } from '../Table/Table';

interface StyledGrid {
  [x: string]: any
}

export const ColorPickerWrapper = styled.div`
  display:flex;
  width:100%;
  align-items:center;
`;
export const ColorCircle = styled.div`
  width:15px;
  height:15px;
  border-radius:50%;
  margin-right:5px;
`;
export const ColorLabel = styled.div`
`;

export const StyledSearch = styled.button`
  background: ${HPHWhite};
  mask-image: url(${IconSearch});
  -webkit-mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
    background:  ${HPHSeaBlue};
    width: 24px !important;
    height: 24px;
    padding:0;
  &:hover{
    background: ${HPHSkyBlue} !important;
  }
  `;

export const SortingBox = styled.div`
  display: flex;
  margin-bottom: 10px;  
  > div{
    margin-right: 10px;  
  }
`;

export const StyledSortingDiv = styled.div`
  &.isSortActive {
    background: ${HPHSeaBlueDark};
    border-radius: 50%; 
  }
`;

export const TableFilters = styled.div`
  display: flex;
  align-items: center;
`;

export const FormElement = styled.div`
  display: flex;
  position: relative;
  > div {
    width: 100%;
  }
  button {
    padding: 0;
    position: absolute;
    right: 0;
    top:5px;
    cursor: pointer;
    > div {
      padding: 0;
    }
  }
`;

export const TableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  padding: 5px;
`;

export const TableQuickSearch = styled.div<any>`
  display: flex;
  align-items: center;
  padding: 0;
  padding: 5px;
  position: relative;
  background: ${HPHWhite};
  margin-bottom: 5px;
  .Icon-search {
    position: absolute;
    right: 0;
    bottom: 6px;
    background: ${HPHWhite};
    height: 30px;
    display: flex;
    align-items: center;
    > div {
      padding: 2px;
    }
  }
  &:focus {
    color: ${HPHSkyBlue};
  }
  & input {
    outline: none;
    border: none;
    font-size: 1rem;
    padding: 0.375rem 0;
    color: ${HPHGreyDark};
    box-sizing: border-box;
    font-family: ${MontserratFont};
    line-height: 30px;
    &:disabled {
      background: none;
      pointer-events: none;
      color: ${HPHGreyLight};
    }
    @media (max-width: 1600px) and (min-width: 1281px) {
      font-size: 0.875rem;
    }
    @media (max-width: 1280px) and (min-width: 1201px) {
      font-size: 0.75rem;
    }
    @media (max-width: 1200px) and (min-width: 1025px) {
      font-size: 0.875rem;
    }
    @media (max-width: 1024px) and (min-width: 961px) {
      font-size: 0.75rem;
    }
    @media (max-width: 960px) and (min-width: 769px) {
      font-size: 1rem;
    }
    @media (max-width: 768px) {
      font-size: 0.875rem;
    }
  }
  & span {
    padding: 0 5px;
    font-weight: 700;
    width: 15px;
    font-family: ${MontserratFont};
`;

export const StyledHeaderLabel = styled.div`
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 700;
  color:${HPHGreyDark};
  font-family: ${MontserratFont};
  margin-left:5px;
  display: flex;
  align-items: center;
  justify-content:space-between;
  @media(max-width:1600px) and (min-width:1281px){
    font-size: 0.875rem;
  }
  @media(max-width:1280px) and (min-width:1201px){
      font-size: 0.75rem;
  }
  @media(max-width:1200px) and (min-width:1025px){
      font-size: 0.875rem;
  }
  @media(max-width:1024px) and (min-width:961px){
      font-size: 0.75rem;
  }
  @media(max-width:960px) and (min-width:769px){
      font-size: 1rem;
  }
  @media(max-width:768px) {
      font-size: 0.875rem;
  }
`;

export const GridStyles = createGlobalStyle<StyledGrid>`
#actionTooltip {
  .p-tooltip-text {
    padding: 0.45rem 0.625rem;
    line-height: 1;
  }
}
${scrollbarStyles};

  .table-actionbar{
    display:flex;
    justify-content:flex-end;
    align-items:center;
    height:38px;
  }

  .ag-theme-alpine{
    .ag-table{
      position: relative;
      height: 100%;
      &.ag-grid-width {
        padding-right: 15px;

        &.pivot-mode-off .ag-tool-panel-wrapper {
          & .ag-column-drop.ag-column-drop-vertical.ag-focus-managed {
            display: none !important;
          }
        }
      }
    }
    .ag-side-bar {
      .ag-tool-panel-wrapper{
        .ag-drag-handle{
          margin-left:5px;
          margin-right:0 !important;
          &:before{
            content: " ";
            mask-image: url(${IconDrag});
            background-color: ${HPHSeaBlue};
            display: inline-block;
            mask-repeat: no-repeat;
            mask-size: 100%;
            width: 16px;
            height: 16px;
          }
        }
      }
      .ag-side-bar{
        border:0;
      }
      .ag-side-buttons{
        display:none;
      }
    }
    .ag-side-panel {
      cursor: pointer;
      border-left: 1px solid #ccc;
      min-width: 15px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      background: ${HPHWhite};
      z-index: 10;

      .ag-side-panel-icon {
        display: none;
      }

      &:hover {
        width: auto;
        padding: 0px 5px;
        
        .ag-side-panel-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        }
      }
    }
  }
 
  .ag-theme-alpine{
    font-family: ${MontserratFont}; 
    &.grid-full-height{
      height:calc(100vh - 42px);
      width: 100%;
    }
    .ag-root-wrapper{
      border:0;
    }
    .ag-tabs-header{
      display:none;
    }
    .ag-popup-child{
      z-index:10;
    } 
    .ag-tabs{
      // top:-6px !important;
      box-shadow: none !important;
      // border-top: 0 !important;
      .ag-filter{
        font-family: ${MontserratFont};
      }
      .ag-icon-filter{
        display: flex;          
        mask-image: url(${IconFilterFill});
        background:${HPHSeaBlue};
        -webkit-mask-size: 100%;
        width: 20px;
        height: 20px;     
      }
    }
    .ag-center-cols-container {
      min-width: 100%;
    }
    .ag-header{
      text-align: left;
      padding: 0rem;
      border: none;
      // min-height: 36px !important;
      // height: 36px !important;
      max-height: fit-content !important;
      top: 0;
      width: auto;
      background: ${Background1};
      border-bottom: 2px solid ${HPHSkyBlue};
      z-index: 2;
      min-width: 70px !important;
      white-space: nowrap;
      display: flex;
      flex: 1 1;
      align-items: flex-end;
      cursor: pointer;
      user-select: none;
      z-index: 9;
      .ag-header-cell{
        display: flex;
        align-items: center;
        padding: 5px 10px;
      }
      .ag-header-cell[col-id="action"]{
        .ag-cell-label-container{
          flex-direction:row;
        }
      }
      .ag-header-cell-text {
        color: ${HPHSeaBlue};
        font-size: 0.875rem;
        font-weight: 700;
        white-space: normal;
        font-family: ${MontserratFont}; 
      }
      .ag-cell-label-container { 
        align-items: flex-end;
        padding:0;
      }
      .ag-header-cell-resize::after{
        top: auto;
        bottom: 10px;
      }
      .ag-header-icon{
        opacity: 1;
        .ag-icon-filter{
          display: flex;          
          mask-image: url(${IconFilterFill});
          background:${HPHSeaBlue};
          -webkit-mask-size: 100%;
          width: 20px;
          height: 20px;     
        }
        .ag-icon-menu{
          display: flex;          
          mask-image: url(${IconAngleDown});
          background:${HPHSeaBlue};
          width: 24px;
          height: 24px;
          cursor: pointer;
          &:hover{
            background: ${HPHSkyBlue};
          }
          &:before{
            display:none;
          }
        }
      }
    }
    .ag-sort-indicator-container{
      padding-left: 6px;
      .ag-sort-ascending-icon {
        .ag-icon-asc{
          display: flex;          
          mask-image: url(${IconSortAlpha});
          background:${HPHSeaBlue};
          -webkit-mask-size: 20px;
          width: 20px;
          height: 20px;
          cursor: pointer;
          &:hover{
            background: ${HPHSkyBlue};
          }
          &:before{
            display:none;
          }
        } 
      }
      .ag-sort-descending-icon{
        .ag-icon-desc{
          display: flex;          
          mask-image: url(${IconSortAlphaAlt});
          background:${HPHSeaBlue};
          -webkit-mask-size: 20px;
          width: 20px;
          height: 20px;
          cursor: pointer;
          &:hover{
            background: ${HPHSkyBlue};
          }
          &:before{
            display:none;
          }
        }
      }
    }
    
    .ag-filter {
      .ag-text-field-input{
        border:0;      
        border-bottom:1px solid ${HPHGreyDark};
        border-radius: 0 !important;     
        background-color:${HPHWhite};
        color:${HPHGreyDark};
        font-size:1rem;
        font-weight:400;
        font-family: ${MontserratFont};    
        padding: 0.375rem 0rem;
        height: 31px;
        &:hover,&:focus{
          border-color: ${HPHSkyBlue};
          box-shadow: none;
        }
        @media(max-width:1600px) and (min-width:1281px){
          font-size: 0.875rem;
        }
        @media(max-width:1280px) and (min-width:1201px){
          font-size: 0.75rem;
        }
        @media(max-width:1200px) and (min-width:1025px){
          font-size: 0.875rem;
        }
        @media(max-width:1024px) and (min-width:961px){
          font-size: 0.75rem;
        }
        @media(max-width:960px) and (min-width:769px){
          font-size: 1rem;
        }
        @media(max-width:768px) {
          font-size: 0.875rem;
        }
        &::-webkit-input-placeholder {
          color: ${HPHGreyLight};
          font-size:1rem;
          font-weight:400;
          @media(max-width:1600px) and (min-width:1281px){
            font-size: 0.875rem;
          }
          @media(max-width:1280px) and (min-width:1201px){
            font-size: 0.75rem;
          }
          @media(max-width:1200px) and (min-width:1025px){
            font-size: 0.875rem;
          }
          @media(max-width:1024px) and (min-width:961px){
            font-size: 0.75rem;
          }
          @media(max-width:960px) and (min-width:769px){
            font-size: 1rem;
          }
          @media(max-width:768px) {
            font-size: 0.875rem;
          }
        }    
        &::placeholder {
          color: ${HPHGreyLight};
          font-size:1rem;
          font-weight:400;
          @media(max-width:1600px) and (min-width:1281px){
            font-size: 0.875rem;
          }
          @media(max-width:1280px) and (min-width:1201px){
            font-size: 0.75rem;
          }
          @media(max-width:1200px) and (min-width:1025px){
            font-size: 0.875rem;
          }
          @media(max-width:1024px) and (min-width:961px){
            font-size: 0.75rem;
          }
          @media(max-width:960px) and (min-width:769px){
            font-size: 1rem;
          }
          @media(max-width:768px) {
            font-size: 0.875rem;
          }
        }    
        &:disabled{
          border-color: ${HPHGreyLight};
          color:${HPHGreyLight};
        }
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          margin: 0; 
        }
      } 


      .ag-filter-select{
        .ag-picker-field-wrapper{
          border:0;      
          border-bottom:1px solid ${HPHGreyDark};
          border-radius: 0 !important;     
          background-color:${HPHWhite};
          color:${HPHGreyDark};
          font-size:1rem;
          font-weight:400;
          font-family: ${MontserratFont};    
          padding: 0.375rem 0rem;
          height: 31px;
          &:hover,&:focus{
            border-color: ${HPHSkyBlue};
            box-shadow: none;
          }
          @media(max-width:1600px) and (min-width:1281px){
            font-size: 0.875rem;
          }
          @media(max-width:1280px) and (min-width:1201px){
            font-size: 0.75rem;
          }
          @media(max-width:1200px) and (min-width:1025px){
            font-size: 0.875rem;
          }
          @media(max-width:1024px) and (min-width:961px){
            font-size: 0.75rem;
          }
          @media(max-width:960px) and (min-width:769px){
            font-size: 1rem;
          }
          @media(max-width:768px) {
            font-size: 0.875rem;
          }
          .ag-picker-field-display{
            @media(max-width:1600px) and (min-width:1281px){
              font-size: 0.875rem;
            }
            @media(max-width:1280px) and (min-width:1201px){
              font-size: 0.75rem;
            }
            @media(max-width:1200px) and (min-width:1025px){
              font-size: 0.875rem;
            }
            @media(max-width:1024px) and (min-width:961px){
              font-size: 0.75rem;
            }
            @media(max-width:960px) and (min-width:769px){
              font-size: 1rem;
            }
            @media(max-width:768px) {
              font-size: 0.875rem;
            }
          }
          &::-webkit-input-placeholder {
            color: ${HPHGreyLight};
            font-size:1rem;
            font-weight:400;
            @media(max-width:1600px) and (min-width:1281px){
              font-size: 0.875rem;
            }
            @media(max-width:1280px) and (min-width:1201px){
              font-size: 0.75rem;
            }
            @media(max-width:1200px) and (min-width:1025px){
              font-size: 0.875rem;
            }
            @media(max-width:1024px) and (min-width:961px){
              font-size: 0.75rem;
            }
            @media(max-width:960px) and (min-width:769px){
              font-size: 1rem;
            }
            @media(max-width:768px) {
              font-size: 0.875rem;
            }
          }    
          &::placeholder {
            color: ${HPHGreyLight};
            font-size:1rem;
            font-weight:400;
            @media(max-width:1600px) and (min-width:1281px){
              font-size: 0.875rem;
            }
            @media(max-width:1280px) and (min-width:1201px){
              font-size: 0.75rem;
            }
            @media(max-width:1200px) and (min-width:1025px){
              font-size: 0.875rem;
            }
            @media(max-width:1024px) and (min-width:961px){
              font-size: 0.75rem;
            }
            @media(max-width:960px) and (min-width:769px){
              font-size: 1rem;
            }
            @media(max-width:768px) {
              font-size: 0.875rem;
            }
          }    
          &:disabled{
            border-color: ${HPHGreyLight};
            color:${HPHGreyLight};
          }
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            margin: 0; 
          }
        }
      }

      .ag-filter-apply-panel-button{
        color: ${HPHWhite};
        background: ${HPHSkyBlue};
        border: 1px solid ${HPHSkyBlue};
        font-weight: bold;
        border-radius: 4px;
        font-family: ${MontserratFont};
        transition:all 0.2s ease-in-out;
        height: 1.875rem;
        padding: 0rem 0.75rem;
        font-size: 0.875rem;
        cursor:pointer;
          &:enabled:hover{
            background: ${HPHSeaBlue} !important;
            transform: scale(1.1);
            border-color:  ${HPHSeaBlue} !important;
            transition:all 0.2s ease-in-out;
          }
      }
    }

    .ag-header-cell[col-id="action"]{
      .ag-header-icon {
        display:none;
      }
    }
    .ag-header-cell[col-id="frontaction"]{
      .ag-header-icon {
        display:none;
      }
    }
    .ag-header-cell[col-id="selectionCol"]{
      width:24px !important;
      padding: 0 5px;
      .ag-header-icon{
        display:none
      }
      .ag-checkbox{
        margin:0 !important;
      }
    }
    .ag-header-cell[col-id="dragCol"]{
      width:24px !important;
      padding: 0 5px;
      .ag-header-icon{
        display:none
      }
    }

    .ag-menu-column-select-wrapper{
      .ag-column-select-header{
        display:none
      }
      .ag-virtual-list-item:last-child{
        display:none;
      }
    }
    .ag-column-select-checkbox{
      margin-right: 0 !important;
    }
    .ag-checkbox-input-wrapper:focus-within, .ag-checkbox-input-wrapper:active{
      box-shadow:none !important
    }
    .ag-virtual-list-item{
      .ag-column-select-column-label{
        font-weight: 400;
        font-size: 1rem;
        letter-spacing: 0px;
        line-height: 1.2;
        text-align: left;
        font-family:${MontserratFont};
        color: ${HPHGreyDark};
        padding: 2px 0px 1px 5px;
        @media(max-width:1600px) and (min-width:1281px){
          font-size: 0.875rem;
        }
        @media(max-width:1280px) and (min-width:1201px){
          font-size: 0.75rem;
        }
        @media(max-width:1200px) and (min-width:1025px){
          font-size: 0.875rem;
        }
        @media(max-width:1024px) and (min-width:961px){
          font-size: 0.75rem;
        }
        @media(max-width:960px) and (min-width:769px){
          font-size: 1rem;
        }
        @media(max-width:768px) {
          font-size: 0.875rem;
        }
      }
    }    

    .ag-body-viewport{
      .ag-row-odd{
        background-color: #fff;
      }
      .ag-row{
        cursor:pointer;
        border-bottom: 1px solid #f3f3f3;
        .ag-cell {
          font-size: 0.875rem;
          color: ${HPHSeaBlue};
          border: 0 !important;
          cursor:pointer;
          font-weight: 400;
          font-family: ${MontserratFont};
          padding-left: 10px;
          padding-right: 10px;
          .actionIcons{
            display: flex;
          }
          .tick-icon{
            background:transparent;
          }
          #colorPicker{
            > div{
              margin: 0.25rem 0rem;
            }
          }
        }
        .ag-cell[col-id="action"]{
          overflow:visible
        }
        .ag-cell[col-id="selectionCol"]{
          width:24px !important;
          padding:0 !important;
          padding-left: 5px !important;
          .ag-selection-checkbox{
            margin-right:0 !important;
          }
        }
        .ag-cell[col-id="dragCol"]{
          width:24px !important;
          padding:0 !important;
          padding-left: 5px !important;
          .ag-drag-handle{
            margin-right:0 !important;
          }
          .ag-icon-grip{
            &:before{
              content: " ";
              mask-image: url(${IconDrag});
              background-color: ${HPHSeaBlue};
              display: inline-block;
              mask-repeat: no-repeat;
              mask-size: 100%;
              width: 22px;
              height: 22px;
            }
          }
        }
        .ag-cell-inline-editing{
          height: 33px !important;
          background-color: none;
          box-shadow: none;
          background: none;
          .tableEditor{
            padding-left: 17px;
            .inputField{
              height: 31px;
              font-family: ${MontserratFont};
              width: auto;
              border: 1px solid  ${HPHSkyBlue};
              &:focus{
                box-shadow: none
              }
            }
            .p-inputtext{
              font-size: 0.75rem;
              color: ${HPHBlack};
              font-weight: 400;
            }
            .p-dropdown{
              margin-top: 2px;
              font-family: ${MontserratFont};
              padding: 0.375rem 0rem 0.375rem 0.735rem;
              width: 100%;
              height: 31px;
              display: flex;
              align-items: center;
              border: 1px solid  ${HPHSkyBlue};
              &:focus,&:hover{
                outline: none;
                box-shadow: none;
              }
              .p-dropdown-label{
                padding:0;
                font-family: ${MontserratFont};
                color: ${HPHBlack};
              }
              &.p-inputwrapper-focus{
                outline: none;
                box-shadow: none;
                border-color: ${HPHSkyBlue};
              }
              .p-dropdown-trigger {
                width: 2rem;
              }
            }
            .p-calendar{
              margin-top: 2px;
              font-family: ${MontserratFont};
              padding: 0.375rem 0rem 0.375rem 0.735rem;
              width: 100%;
              height: 31px;
              display: flex;
              align-items: center;
              border: 1px solid ${HPHSkyBlue};
              border-radius: 6px;
              .p-button{
                top:-1px;
              }
            }
            .p-checkbox{
              margin: 6px;
            }
            #colorPicker{
              > div{
                margin: 0.3rem 0rem;
              }
            }
          }
        }
      }
      .ag-row-selected::before{
        background: ${Background8} !important ;
      }
      .ag-row-selected{
        .ag-cell{
          color: ${HPHSkyBlue} !important;
          .edit-icons{
            display: flex;
          }
        }
      }
      .edited-row{
        background:${Background8};
        .ag-cell{
          color: ${HPHSkyBlue} !important;
        }
      }
      .disabled-row{
        pointer-events: none;
        .ag-cell-value{
          opacity:0.6;
          color: ${HPHGrey} !important;
        }
        .ag-selection-checkbox{
          pointer-events: none;
          opacity:0.6;
        }
        .ag-checkbox-input-wrapper.ag-checked::after{
          color: #999999;
        }
        .ag-cell[col-id="dragCol"]{
          .ag-icon-grip{
            &:before{
              background-color: ${HPHGrey};
              opacity:0.6;
            }
          }
        }
      }
      .ag-row-hover:not(.ag-full-width-row)::before,
      .ag-row-hover.ag-full-width-row.ag-row-group::before{
        background: ${Background5} !important;
        .ag-cell{
          color: ${HPHSeaBlue}
        }
      }
      .ag-row-selected:hover::before{
        background: ${Background8} !important;
      }
      .edited-row:hover::before{
        background: ${Background8} !important;
      }
      .ag-row-hover.disabled-row:hover:before{
        background-color:${HPHWhite} !important;
      }
    }

    &.remove-highlight .ag-row-selected::before{
      background: transparent !important;
    }
    &.remove-highlight .ag-row-selected{
      .ag-cell{
        color: ${HPHSeaBlue} !important;
      }
    }
    &.remove-highlight .ag-row-selected:hover::before{
      background: ${Background5}  !important;
    }

    .ag-paging-panel {
      position: relative;
      background: ${Background1};
      border-top: 2px solid ${HPHSkyBlue};
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      overflow: visible;
      padding: 23px 20px;
      .ag-paging-row-summary-panel {
        font-size: 13px;
        letter-spacing: 0px;
        line-height: 1.2;
        font-weight: 400 !important;
        color: ${HPHGrey};
        margin-right: 1rem;
        margin: 0;
        font-family: ${MontserratFont};   
        span{
          font-weight: 400 !important;
          color: ${HPHGrey};
          font-size: 13px;
        } 
      }
      .ag-paging-page-summary-panel{
        font-family: ${MontserratFont};
      }
    }
  }
  .ag-theme-alpine .ag-body-viewport .ag-row .ag-cell-inline-editing .tableEditor {
    padding-left: 0 !important;
  }
  .ag-selection-checkbox.ag-invisible {
    display: none !important;
  }
  .p-dropdown-panel{
    .p-dropdown-items{
      .p-dropdown-item{
        color: ${HPHSeaBlue} !important;
        font-family:${MontserratFont};
        font-size: 0.875rem;
        font-weight: 400;
      }
      .p-dropdown-item.p-highlight{
        color: ${HPHSkyBlue}; !important;
        background: ${Background8} !important;
      }
      .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
        color: ${HPHSeaBlue} !important;
        background: ${Background5} !important;
      }
    }
  }
  .ag-header-cell[col-id="action"] .ag-header-cell-label {
    display: none;
  }
`;