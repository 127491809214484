import { TaxMappingConstant } from "presentation/constant/TaxMapping/TaxMappingConstant";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useTaxMappingVM } from "presentation/hook/TaxMapping/useTaxMappingVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import FileUpload from "presentation/view/components/FileUpload";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import { NbisIconButton } from "presentation/view/components/NbisIconButton";
import { memo, useCallback, useState } from "react";
import { Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const TaxMappingTitleBar: React.FC = () => {
    const TAX_MAPPING_CONSTANT = TaxMappingConstant.Title;
    const taxMappingVM = useTaxMappingVM();
    const [isLoading, setIsLoading] = useState(false);
    const [anaInfoState] = useANAInfoTracked();
    const { allowCreate } = anaInfoState;
    const messageBarVM = useMessageBarVM();

    const handleAdd = useCallback(() => {
        taxMappingVM.onAddClick();
    }, [taxMappingVM]);


    const handleUpload = useCallback(async (e: any) => {

        const files = e.target?.files;
        const file = files[0];

        if (!file) {
            return;
        }
        if (file.type !== 'text/csv') {
            messageBarVM.showError('Please upload a .csv file');
            return
        }

        setIsLoading(true);
        /*const res = await taxMappingVM.onUpload(file);

        file.current.value = null;
        e.target = null;

        if (res.success) {
            messageBarVM.showSuccess("Success!");
        } else {
            messageBarVM.showError(res.data);
        }

        setIsLoading(false);

        taxMappingVM.searchAllTaxMappingList();*/

        await taxMappingVM.onUpload(file).then(async res => {

            // file.current.value = null;
            e.target = null;

            if (res.success) {
                await taxMappingVM.searchAllTaxMappingList().then(data => {
                    setIsLoading(false);
                    messageBarVM.showSuccess("Success!");
                });
            } else {
                setIsLoading(false);
                messageBarVM.showError(res.data);
            }
        });

    }, [messageBarVM, taxMappingVM]);


    return <Sidebarheader style={{ width: '100%', display: "flex", alignItems: "center" }}>
        <HeaderTitle>{TAX_MAPPING_CONSTANT.TITLE}</HeaderTitle>
        {(isLoading) && <Loader Indicator="Spinner" size="Medium" />}
        <StyledAction className="tm-animated-wrapper">
            {(allowCreate) && <><NbisIconButton fileName='Icon-add' disabled={false} size='medium' toolTipText={'Add'} toolTipArrow={false} onClick={handleAdd} />
                <div className="add-seperator" /></>}
            <FileUpload handleUpload={handleUpload} label={WorkspaceConstant.Common.BUTTON_UPLOAD} theme={"Primary"} accept=".csv" size={"Small"} disabled={false} />
        </StyledAction>
    </Sidebarheader>
}

export default memo(TaxMappingTitleBar);