export interface RecalProcessAndGenSearchCriteria{

    consolidationType: string | null,
    chargeOnCompany: string | null,
    opsDateFrom: Date | null,
    opsDateTo: Date | null,
    
    [key: string]: string[] | string | boolean | number | Date | null | undefined
}

export const EMPTY_RECAL_PROCESS_AND_GEN_SEARCH_CRITERIA : RecalProcessAndGenSearchCriteria = {
    
    consolidationType: "1",
    chargeOnCompany: null,
    opsDateFrom: null,
    opsDateTo: null,
}