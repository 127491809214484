import { uomDropdownOption } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { useChargeDetailVM } from "presentation/hook/ChargeDetailMaintenance/useChargeDetailVM";
import { ChargeDetailModel } from "presentation/model/ChargeDetailMaintenance/ChargeDetailModel";
import NbisInputDropDown from "presentation/view/components/NbisInputDropDown";
import { InputField } from "veronica-ui-component/dist/component/core";

export const ManualAdjAccordion = ({ chargeDetailState }: { chargeDetailState: ChargeDetailModel }) => {
    const chargeBatchUpdate = chargeDetailState.chargeBatchUpdate
    const chargeDetailVM = useChargeDetailVM();
    return (
        <>
            <div className={'im-flex-row'} style={{ marginBottom: '24px' }}>
                <div className='im-flex-row-item'>
                    <NbisInputDropDown
                        label='Manual Adj Uom'
                        width='150px'
                        inputType="freeText"
                        mode={"single"}
                        value={chargeBatchUpdate.manualAdjChargeQtyUom}
                        onChange={(e: any) => chargeDetailVM.onDropdownChange(e, 'manualAdjChargeQtyUom', chargeBatchUpdate, 'chargeBatchUpdate')}
                        options={uomDropdownOption} />
                </div>
                <div className='im-flex-row-item'>
                    <InputField
                        label='Manual Adj'
                        width='150px'
                        type="text"
                        value={chargeBatchUpdate.manualAdjChargeQty || ''}
                        onChange={(e: any) => chargeDetailVM.onInputTextChange(e, 'manualAdjChargeQty', chargeBatchUpdate, 'chargeBatchUpdate')} />
                </div>
            </div>

            <div className={'im-flex-row'} style={{ marginBottom: '24px' }}>
                <div className='im-flex-row-item'>
                    <NbisInputDropDown
                        label='Reason Code'
                        width='330px'
                        inputType="freeText"
                        mode={"single"}
                        value={chargeBatchUpdate.reasonCode}
                        onChange={(e: any) => chargeDetailVM.onDropdownChange(e, 'reasonCode', chargeBatchUpdate, 'chargeBatchUpdate')}
                        options={chargeDetailState.dynamicOptions.reasonCodeDropdownOptions} />
                </div>
            </div>
        </>
    )
}