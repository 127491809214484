import { CriteriaDirectoryDropdownOption } from "presentation/constant/ChargeDataEnquiry/CriteriaDirectoryDropdownOption";
import { useChargeDataSearchVM } from "presentation/hook/ChargeDataEnquiry/useChargeDataSearchVM";
import { useChargeDataSearchTracked } from "presentation/store/ChargeDataEnquiry/ChargeDataSearchProvider";
import NbisInputDropDown from "presentation/view/components/NbisInputDropDown";
import { HPHButton } from "veronica-ui-component/dist/component/core";
import { ChargeDataSearchCriteriaCheckboxList } from "./ChargeDataSearchCriteriaCheckboxList";

export const ChargeDataSearchCriteriaDirectoryPanel = () => {
    const chargeDataSearchVM = useChargeDataSearchVM();
    const [chargeDataSearchState] = useChargeDataSearchTracked();

    return <div className='im-charge-data-search-criteria-panel'>
        <div className='im-charge-data-search-criteria-directory-content'>
            <NbisInputDropDown
                field='dropdownLabel'
                mode='single'
                label=''
                onChange={(e: any) => chargeDataSearchVM.onCriteriaDirectoryDropdown(e)}
                options={CriteriaDirectoryDropdownOption}
                value={chargeDataSearchState.highlightedCheckboxKey}
                width={' calc(100% - 35px)'} />
            <ChargeDataSearchCriteriaCheckboxList />
        </div>
        <div className='im-charge-data-search-criteria-directory-buttons'>
            <HPHButton label={'Reset'} size={'Small'} theme={'Secondary'} onClick={chargeDataSearchVM.onResetCheckboxValue} />
            <HPHButton label={'Confirm'} size={'Small'} theme={'Primary'} onClick={chargeDataSearchVM.onSaveCheckboxValue} />
        </div>
    </div>;
};