import { useTariffCoaMappingVM } from "presentation/hook/TariffCoaMapping/useTariffCoaMappingVM";
import { useTariffCoaMappingTracked } from "presentation/store/TariffCoaMapping/TariffCoaMappingProvider";
import { memo, useEffect, useState } from "react";
import { Loader } from "veronica-ui-component/dist/component/core";
import TariffCoaMappingEditPanel from "./RightPanel/TariffCoaMappingEditPanel";
import TariffCoaMappingTablePanel from "./Table/TariffCoaMappingTablePanel";


const TariffCoaMappingMaintenance: React.FC = () => {
    const [tariffCoaMappingState] = useTariffCoaMappingTracked();
    const [isLoading, setIsLoading] = useState(true);
    const tariffCoaMappingVM = useTariffCoaMappingVM();
    const { isShowEditPanel } = tariffCoaMappingState;


    useEffect(() => {
        const initialScreen = async () => {
            setIsLoading(true);
            Promise.allSettled([
                tariffCoaMappingVM.loadDropdownOption(),
                tariffCoaMappingVM.searchAllTariffCoaMappingList()
            ]).then(data => {
                setIsLoading(false);
            }).catch(e => {
                setIsLoading(false);
            });
        }

        initialScreen();
    }, [tariffCoaMappingVM])

    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;

    return <>
        <div className={`main-comp-wrapper im-hide-side-form-draggable`}>

            {!(isShowEditPanel) && <TariffCoaMappingTablePanel />}
            {(isShowEditPanel) && <TariffCoaMappingEditPanel />}
        </div>
    </>
}

export default memo(TariffCoaMappingMaintenance);