import { SearchModeEntity } from "domain/entity/ChargeDataEnquiry/SearchModeEntity";
import { EMPTY_MANUAL_CHARGE_HEADER_ENTITY, ManualChargeHeaderEntity } from "domain/entity/ManualCharge/ManualChargeHeaderEntity";
import { ManualChargeHeaderSearchCriteriaEntity } from "domain/entity/ManualCharge/ManualChargeHeaderSearchCriteriaEntity";
import { PrintQueueRepository } from "domain/repository/Common/PrintQueue";
import { CustomerRepository } from "domain/repository/Company/CustomerRepo";
import { ManualChargeHeaderRepository } from "domain/repository/ManualCharge/ManualChargeHeaderRepo";
import { ParameterDetailRepository } from "domain/repository/parameter/ParameterDetailRepo";
import _ from "lodash";
import { ManChargeHdrSearchCriteriaCheckboxRelatedField } from "presentation/constant/ManualCharge/ManChargeHdrSearchCriteriaCheckboxRelatedField";
import { EMPTY_NON_OPS_PRINT_CRITERIA, NonOpsPrintCriteria } from "presentation/constant/NonOps/NonOpsPrintCriteria";
import { EMPTY_NON_OPS_SEARCH_CRITERIA, NonOpsSearchCriteria } from "presentation/constant/NonOps/NonOpsSearchCriteria";
import { DropdownProps } from "presentation/model/DropdownProps";
import { NonOpsHeaderModel } from "presentation/model/NonOpsMaintenance/NonOpsHeaderModel";
import BaseViewModel from "presentation/viewModel/BaseViewModel";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { GroupCheckboxList, IFieldValue } from "veronica-ui-component/dist/component/core";

interface NonOpsHeaderVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<NonOpsHeaderModel>> | ((value: SetStateAction<NonOpsHeaderModel>) => void),
    ]
    parameterDetailRepo: ParameterDetailRepository,
    manualChargeHeaderRepo: ManualChargeHeaderRepository
    customerRepo: CustomerRepository,
    printQueueRepo: PrintQueueRepository
}

export const NonOpsHeaderVM = ({ dispatch, parameterDetailRepo, manualChargeHeaderRepo, customerRepo, printQueueRepo }: NonOpsHeaderVMProps) => {
    const [nonOpsHdrDispatch] = dispatch;


    const onCloseScreen = () => {
        document.dispatchEvent(new CustomEvent('closeNonOpsHeader'))
    }

    const onShowLoading = () => {
        nonOpsHdrDispatch(prevState => {
            return {
                ...prevState,
                isLoading: true,
            }
        })
    }

    const onHideLoading = () => {
        nonOpsHdrDispatch(prevState => {
            return {
                ...prevState,
                isLoading: false,
            }
        })
    }

    const updateSelectedManualChargeHeaders = (rows: any[]) => {
        nonOpsHdrDispatch(prevState => {
            return {
                ...prevState,
                selectedManualChargeHeaderRows: rows,
                forceRefresh: !prevState.forceRefresh
            }
        })
    }

    const onRemoveSearchCriteria = (fieldName: string, isSearch: boolean = true) => {
        //By default, we use disabled field name to retrieve default value from EMPTY_INVENTORY_SEARCH_CRITERIA,
        //If related field found, we use related field to retrieve default value.
        let resetSearchCriteriaPerField = { [fieldName]: EMPTY_NON_OPS_SEARCH_CRITERIA[fieldName] }
        if (ManChargeHdrSearchCriteriaCheckboxRelatedField[fieldName]) {
            resetSearchCriteriaPerField = ManChargeHdrSearchCriteriaCheckboxRelatedField[fieldName].reduce((objPerField, fieldNamePerField) => {
                return {
                    ...objPerField,
                    [fieldNamePerField]: EMPTY_NON_OPS_SEARCH_CRITERIA[fieldNamePerField],
                }
            }, {})
        }

        nonOpsHdrDispatch(prevState => {
            return {
                ...prevState,
                searchCounter: isSearch ? prevState.searchCounter + 1 : prevState.searchCounter,
                searchCriteria: { ...prevState.searchCriteria, ...resetSearchCriteriaPerField }
            }
        })
    }

    const onRemoveAllSearchCriteria = () => {
        nonOpsHdrDispatch(prevState => {
            return {
                ...prevState, searchCounter: 0, searchCriteria: { ...EMPTY_NON_OPS_SEARCH_CRITERIA }
            }
        })
    }

    // Open search criteria panel not preview panel
    const onEditSearchCriteria = () => {
        // DomUtils.addWithSearchClass();
        nonOpsHdrDispatch(prevState => {
            // if (prevState.searchCounter === 0) DomUtils.addOnlySearchClass();
            // else DomUtils.addWithSearchClass();
            return { ...prevState, isShowRightPanel: true, isFilterSearchCriteria: true, isEditSearchCriteria: true, }
        });
    }

    const onSearch = async (searchCriteria: NonOpsSearchCriteria, searchModeEntity: SearchModeEntity) => {
        // DomUtils.addWithSearchClass();

        const groupCriteriaList: ManualChargeHeaderSearchCriteriaEntity[] = searchModeEntity.groupKeys.map((item, index) => ({
            key: searchModeEntity.rowGroupCols[index], value: item
        }));

        nonOpsHdrDispatch(prevState => {
            return {
                ...prevState,
                searchCounter: prevState.searchCounter + 1,
                manualChargeHeaders: [],
                selectedManualChargeHeaderRows: [],
            };
        });

        return await manualChargeHeaderRepo.getManualChargeHeader({
            manualChargeSearchCriteria: null,
            nonOpsSearchCriteria: searchCriteria,
            startRow: searchModeEntity.startRow,
            endRow: searchModeEntity.endRow,
            searchSort: searchModeEntity.searchSort,
            currentGroup: searchModeEntity.currentGroup,
            groupCriteria: groupCriteriaList,
        }).then((data) => {
            nonOpsHdrDispatch(prevState => {
                return {
                    ...prevState,
                    searchCounter: prevState.searchCounter + 1,
                    manualChargeHeaders: data,
                    searchCriteria: { ...searchCriteria },
                    currentManualChargeHeader: null,
                    selectedManualChargeHeaderRows: [],
                    isAllowAutoSearch: false,
                };
            });
        }).catch((error) => {
        })

    }

    const onSearchById = async (id: number) => {
        return await manualChargeHeaderRepo.getManualChargeHeaderById(id).then((data) => {
            return data;
        }).catch((error) => {
            return null;
        })

    }

    const loadDropdownOption = async () => {
        await parameterDetailRepo.getAllParameterDtlsByParameterCode("BILLING_CYCLE").then(
            parDtls => {
                const billingCycleDropdownOptions = parDtls?.map((parDtl) => ({
                    dropdownLabel: parDtl.parameterDtlCode,
                    tagLabel: parDtl.parameterDtlCode,
                    value: parDtl.parameterDtlCode,
                })) ?? []

                nonOpsHdrDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        billingCycleDropdownOptions: [
                            ...billingCycleDropdownOptions]
                    }
                }))
            }
        )

        await customerRepo.getAllCustomers().then(
            customers => {
                let customerCodeDropdownOptions = customers?.map((customer) => ({
                    dropdownLabel: customer.customerCode + '-' + customer.customerDesc1,
                    tagLabel: customer.customerCode,
                    value: customer.customerCode,
                })) ?? []
                customerCodeDropdownOptions = _.orderBy(customerCodeDropdownOptions, "value");

                nonOpsHdrDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        customerCodeDropdownOptions: [
                            ...customerCodeDropdownOptions],
                    }
                }))
            }
        )

        await printQueueRepo.getPrintQueueForCombox().then(
            printQueues => {
                let printQueueDropdownOptions = printQueues?.map((printQueue) => ({
                    dropdownLabel: printQueue.printQueueName,
                    tagLabel: printQueue.printQueueName,
                    value: printQueue.printQueueName,
                })) ?? []
                printQueueDropdownOptions = _.orderBy(printQueueDropdownOptions, "dropdownLabel");

                nonOpsHdrDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        printerDropdownOptions: printQueueDropdownOptions,
                    },
                    printCriteria: {
                        ...prevState.printCriteria,
                        printQueueName: printQueueDropdownOptions.length > 0 ? printQueueDropdownOptions[0].value : '',
                    }
                }))
            }
        )
    }


    const onDropdownChange = (e: any, fieldName: string) => {
        nonOpsHdrDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: e?.value ?? (_.isEmpty(e) ?
                    (fieldName === "subChgTypeList" ? [] : '') : e),
            }
        }))
    }

    const onDateRangeChange = (startDate: any, endDate: any, dateFields: { startField: string, endField: string }) => {
        nonOpsHdrDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [dateFields.startField]: startDate,
                [dateFields.endField]: endDate,
            }
        }))
    }

    const onMultipleDropdownChange = (e: any, fieldName: string) => {
        nonOpsHdrDispatch(prevState => {
            return {
                ...prevState,
                searchCriteria: {
                    ...prevState.searchCriteria,
                    [fieldName]: _.uniq(e?.map((item: DropdownProps) => item.value)),
                }
            }
        });
    };

    const onInputTextChange = (e: ChangeEvent<HTMLInputElement>, fieldName: string) => {
        let val: string = e.target.value;
        if (fieldName !== 'orderNo') {
            val = val.toUpperCase().replace(/\s+/g, '');
        }
        nonOpsHdrDispatch(prevState => (
            {
                ...prevState,
                searchCriteria: {
                    ...prevState.searchCriteria,
                    [fieldName]: val,
                }
            }))
    };


    const onGroupCheckboxChange = (e: (GroupCheckboxList | undefined)[], searchCriteria: NonOpsSearchCriteria, fieldName: string) => {
        let selectedValue: string[] = [];
        if (e) {
            e.forEach(function (value, index) {
                if (value) {
                    selectedValue.push(value.key);
                }
            });
        }
        searchCriteria = { ...searchCriteria, [fieldName]: selectedValue }
        nonOpsHdrDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                ...searchCriteria,
            }
        }));
    };

    const onSearchClick = async () => {
        nonOpsHdrDispatch(prevState => {
            return {
                ...prevState,
                isShowCriteriaPanel: !prevState.isShowCriteriaPanel
            }
        });
    }

    const onAccTml = async (currentRows: ManualChargeHeaderEntity[]) => {
        await manualChargeHeaderRepo.accTml(currentRows)
    }

    const onResumeAccTml = async (currentRows: ManualChargeHeaderEntity[]) => {
        await manualChargeHeaderRepo.resumeAccTml(currentRows)
    }

    const onConfirm = async (currentRows: ManualChargeHeaderEntity[]) => {
        return await manualChargeHeaderRepo.confirm(currentRows)
    }

    const onUnConfirm = async (currentRows: ManualChargeHeaderEntity[]) => {
        await manualChargeHeaderRepo.unConfirm(currentRows)
    }


    const onStdBill = async (currentRows: ManualChargeHeaderEntity[]) => {
        await manualChargeHeaderRepo.genStdBillNonOps(currentRows.map(row => row.manChargeNo).filter((manChargeNo): manChargeNo is string => manChargeNo !== null));
    }

    const onDelete = async (currentRows: ManualChargeHeaderEntity[]) => {
        return await manualChargeHeaderRepo.deleteManCharge(currentRows.map(row => row.id).filter((id): id is number => id !== null)).then((data) => {
            if (data && data.toString().startsWith("Error:")) {
                nonOpsHdrDispatch(prevState => {
                    return {
                        ...prevState,
                        allFormState: { "deleteNonOpsChargeFail": data.toString() }
                    };
                });
                return { "deleteNonOpsChargeFail": data.toString() };
            } else {
                nonOpsHdrDispatch(prevState => {
                    return {
                        ...prevState,
                        allFormState: { "deleteNonOpsChargeSuccess": "successful" }
                    };
                });
                return { "deleteNonOpsChargeSuccess": "successful" };
            }
        }).catch(error => {
            nonOpsHdrDispatch(prevState => {
                return {
                    ...prevState,
                    allFormState: { "deleteNonOpsChargeFail": error.message }
                }
            });
            return { "deleteNonOpsChargeFail": error.message };
        })
    }



    const onAdd = () => {
        nonOpsHdrDispatch(prevState => {
            return {
                ...prevState,
                isShowDetail: !prevState.isShowDetail,
                currentManualChargeHeader: {
                    ...EMPTY_MANUAL_CHARGE_HEADER_ENTITY,
                    chargeCategory: 'NO',
                    chargeType: 'NO'
                }

            }
        });
    }

    const onDetail = (currManChgHdr: ManualChargeHeaderEntity) => {
        nonOpsHdrDispatch(prevState => {
            return {
                ...prevState,
                isShowDetail: !prevState.isShowDetail,
                currentManualChargeHeader: currManChgHdr,
            }
        });
    }

    const onSelectedChargeHeader = (data: ManualChargeHeaderEntity) => {
        nonOpsHdrDispatch(prevState => {
            const obj: NonOpsHeaderModel = {
                ...prevState,
                currentManualChargeHeader: data,
            };
            return { ...obj }
        })
    }

    const onPrintCriteriaFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        nonOpsHdrDispatch(prevState => {
            return {
                ...prevState,
                printCriteria: {
                    ...prevState.printCriteria,
                    [fieldKey]: val
                }
            }
        })
    }

    const onRadioChange = (e: any, fieldName: string) => {
        if(e.checked){
            nonOpsHdrDispatch(prevState => {
                const criteria = {...prevState.printCriteria};

                return {
                    ...prevState,
                    printCriteria: {
                        ...criteria,
                        [fieldName]: e.key
                    }
                };                
            })
        }
    };

    const onShowPrintModal = () => {
        nonOpsHdrDispatch(prevState => ({
            ...prevState,
            isShowPrintModal: true,
            printCriteria: {
                ...prevState.printCriteria,
                adjInd: 'O'
            }
        }))
    };

    const onClosePrintModal = () => {
        nonOpsHdrDispatch(prevState => ({
            ...prevState,
            isShowPrintModal: false,
            printCriteria: {
                ...EMPTY_NON_OPS_PRINT_CRITERIA,
                printQueueName: prevState.dynamicOptions.printerDropdownOptions.length > 0 ? prevState.dynamicOptions.printerDropdownOptions[0].value : '',
            }
        }))
    };

    const onReprint = async (printCriteria: NonOpsPrintCriteria) => {
        let message;
        await manualChargeHeaderRepo.reprintReport(printCriteria).then((res) => {
            if (!res.success) {
                message = "Print/Send failed.";
            }
        }).catch((error) => {
            message = "Print/Send failed."
        })
        onClosePrintModal();
        return { "printReportFail": message }
    }

    const onPreview = async (printCriteria: NonOpsPrintCriteria) => {
        let message;
        await manualChargeHeaderRepo.previewReport(printCriteria).then((data) => {

            const isPreviewRpt = prevOrDownloadRpt(data, false);
            if (!isPreviewRpt) {
                message = "The Non-Ops Checklist report cannot be found.";
            }

        }).catch((error) => {
            message = "Print Non-Ops Checklist report failed."
        })

        onClosePrintModal();
        return { "printReportFail": message }
    }

    const prevOrDownloadRpt = (data: any, isDownloadRpt: boolean) => {
        if (data != null) {
            const tempData = data as unknown as string;

            let base64 = tempData
            let bstr = atob(base64)
            let n = bstr.length
            let u8arr = new Uint8Array(n)
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n)
            }
            const blob = new Blob([u8arr], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);

            window.open(url, '_blank');
            URL.revokeObjectURL(url);

            return true;
        }

        return false;
    }

    const onPrintDateRangeChange = (startDate: any, endDate: any, dateFields: { startField: string, endField: string }) => {
        nonOpsHdrDispatch(prevState => ({
            ...prevState,
            printCriteria: {
                ...prevState.printCriteria,
                [dateFields.startField]: startDate,
                [dateFields.endField]: endDate,
            }
        }))
    }

    return {
        onCloseScreen: onCloseScreen,
        onShowLoading: onShowLoading,
        onHideLoading: onHideLoading,
        updateSelectedManualChargeHeaders: updateSelectedManualChargeHeaders,
        onRemoveSearchCriteria: onRemoveSearchCriteria,
        onRemoveAllSearchCriteria: onRemoveAllSearchCriteria,
        onEditSearchCriteria: onEditSearchCriteria,
        onSearch,
        loadDropdownOption: loadDropdownOption,
        onDateRangeChange: onDateRangeChange,
        onDropdownChange: onDropdownChange,
        onMultipleDropdownChange: onMultipleDropdownChange,
        onInputTextChange: onInputTextChange,
        onGroupCheckboxChange: onGroupCheckboxChange,
        onSearchClick: onSearchClick,
        onAccTml: onAccTml,
        onResumeAccTml: onResumeAccTml,
        onConfirm: onConfirm,
        onUnConfirm: onUnConfirm,
        onStdBill: onStdBill,
        onDelete: onDelete,
        onAdd: onAdd,
        onDetail: onDetail,
        onSelectedChargeHeader: onSelectedChargeHeader,
        onSearchById: onSearchById,
        onPrintCriteriaFieldChange: onPrintCriteriaFieldChange,
        onShowPrintModal: onShowPrintModal,
        onClosePrintModal: onClosePrintModal,
        onReprint: onReprint,
        onPreview: onPreview,
        onRadioChange: onRadioChange,
        onPrintDateRangeChange: onPrintDateRangeChange
    }
}