import { EMPTY_USER_ENTITY, UserEntity } from "domain/entity/User/UserEntity";
import { EMPTY_USER_GROUP_ENTITY, UserGroupEntity } from "domain/entity/userGroup/UserGroupEntity";
import { MenuRepository } from "domain/repository/Menu/MenuRepo";
import { UserRepository } from "domain/repository/User/UserRepo";
import { UserGroupRepository } from "domain/repository/UserGroup/UserGroupRepo";
import _ from "lodash";
import { changePasswordValidationSchema } from "presentation/constant/ChangePassword/ChangePasswordSchema";
import { MessageConstant } from "presentation/constant/MessageConstant";
import { createUserValidationSchema, updateUserValidationSchema } from "presentation/constant/User/UserValidationSchema";
import { Validation } from "presentation/constant/Validation";
import { ChangePasswordModel } from "presentation/model/ChangePassword/ChangePasswordModel";
import { UserModel } from "presentation/model/User/UserModel";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";

interface UserVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<UserModel>> | ((value: SetStateAction<UserModel>) => void),
        Dispatch<SetStateAction<ChangePasswordModel>> | ((value: SetStateAction<ChangePasswordModel>) => void),
    ],
    userGroupRepo: UserGroupRepository,
    menuRepo: MenuRepository,
    userRepo: UserRepository,
}

export const UserVM = ({ dispatch, userGroupRepo, menuRepo, userRepo }: UserVMProps) => {
    const [userDispatch, changePasswordDispatch] = dispatch;

    //initial
    const loadAllUsers = async () => {
        userDispatch(prevState => {
            return {
                ...prevState,
                users: [],
            }
        })
        const result = await userRepo.getAllUsers();
        if (result) {
            const filterSupport = result.filter((user) => user.name !== "support");
            userDispatch(prevState => {
                return {
                    ...prevState,
                    users: filterSupport ?? [],
                }
            })
        }
    }

    const loadDropdownOptions = async () => {
        userGroupRepo.getAllUserGroups().then(userGroups => {
            let userGroupDropdownOptions = userGroups?.map((group) => ({
                dropdownLabel: `${group.name} - ${group.description}`,
                tagLabel: `${group.name} - ${group.description}`,
                value: group.name,
            })) ?? [];
            userGroupDropdownOptions = _.orderBy(userGroupDropdownOptions, "dropdownLabel");

            userDispatch(prevState => ({
                ...prevState,
                dynamicOptions: {
                    ...prevState.dynamicOptions,
                    userGroupDropdownOptions: userGroupDropdownOptions
                }
            }))
        });

        menuRepo.getMenuByKey("nbisiv-main-menu").then(menus => {
            let flattenNavigations = menus?.navigation?.flatMap(test => test.submenu ?? test)
                .flatMap(test => test.submenu ?? test);
            flattenNavigations = flattenNavigations?.filter(nav => nav.entrypoint !== "" && nav.entrypoint !== null && nav.isVisiable !== false)

            let menuDropdownOptions = flattenNavigations?.map((nav) => ({
                dropdownLabel: `${nav.entrypoint} - ${nav.title}`,
                tagLabel: `${nav.entrypoint} - ${nav.title}`,
                value: nav.entrypoint ?? "",
            })) ?? [];
            menuDropdownOptions = _.orderBy(menuDropdownOptions, "dropdownLabel");

            userDispatch(prevState => ({
                ...prevState,
                dynamicOptions: {
                    ...prevState.dynamicOptions,
                    menuDropdownOptions: menuDropdownOptions
                }
            }))
        });
    }

    const onShowLoading = () => {
        userDispatch(prevState => {
            return {
                ...prevState,
                isLoading: true
            }
        })
    }

    const onHideLoading = () => {
        userDispatch(prevState => {
            return {
                ...prevState,
                isLoading: false
            }
        })
    }
    //initial

    //user
    const onUserAddClick = () => {
        userDispatch(prevState => {
            return {
                ...prevState,
                userState: {
                    ...prevState.userState,
                    isTabularDataActive: false,
                    isAdd: true,
                    isRead: false,
                    isEditable: false,
                    isSaveClicked: false,
                    currentSelectedData: EMPTY_USER_ENTITY,
                    lastEditRowId: '',
                    isSliderOpen: true,
                    allFormState: {}
                }
            }
        })
    }

    const onUserRenewClick = () => {
        userDispatch(prevState => {
            return {
                ...prevState,
                userState: {
                    ...prevState.userState,
                    isTabularDataActive: false,
                    isAdd: true,
                    isRead: false,
                    isEditable: false,
                    isSaveClicked: false,
                    currentSelectedData: {
                        ...prevState.userState.currentSelectedData,
                        name: "",
                        emailAddress: "",                  
                    },
                    lastEditRowId: '',
                    isSliderOpen: true,
                    allFormState: {}
                }
            }
        })
    }

    const onSelectedUsers = (selectedRows: UserEntity[]) => {
        userDispatch(prevState => { 
            return {
                ...prevState,
                userState: {
                    ...prevState.userState,                    
                    currentSelectedData: selectedRows?selectedRows[0]:{...EMPTY_USER_ENTITY}
                }
            }
        })
    }

    const onUserRowDoubleClick = (user: UserEntity) => {
        userDispatch(prevState => {
            return {
                ...prevState,
                userState: {
                    ...prevState.userState,
                    isTabularDataActive: false,
                    isAdd: false,
                    isRead: true,
                    isEditable: true,
                    isSaveClicked: false,
                    currentSelectedData: user,
                    lastEditRowId: user.name,
                    isSliderOpen: true,
                    allFormState: {}
                },
                userGroupState: {
                    ...prevState.userGroupState,
                    isTabularDataActive: false,
                    isAdd: false,
                    isRead: true,
                    isEditable: true,
                    isSliderOpen: false,
                    isSelected: false,
                    allFormState: {}
                }
            }
        })
    }

    const onUserRowDeleteClick = (user: UserEntity) => {
        return userRepo.deleteUserByName(user.name).then((result) => {
            if (result) {
                userDispatch(prevState => {
                    const filterUers = prevState.users?.filter((curUser) =>
                        curUser.name !== user.name);
                    return {
                        ...prevState,
                        users: filterUers,
                        userState: {
                            ...prevState.userState,
                            allFormState: { "DeleteUserSuccess": "" }
                        }
                    };
                });
            }
        }).catch((error) => {
            return userDispatch(prevState => {
                return {
                    ...prevState,
                    userState: {
                        ...prevState.userState,
                        allFormState: { "DeleteUserFail": error.message }
                    }
                };
            });
        });
    }

    const onCloseClick = () => {
        userDispatch(prevState => {
            return {
                ...prevState,
                userState: {
                    ...prevState.userState,
                    isRead: true,
                    isAdd: false,
                    isEditable: false,
                    isTabularDataActive: true,
                    currentSelectedData: EMPTY_USER_ENTITY
                },
                userGroupState: {
                    ...prevState.userGroupState,
                    isRead: true,
                    isEditable: false,
                    isAdd: false,
                    isSliderOpen: false,
                    currentSelectedData: EMPTY_USER_GROUP_ENTITY
                }
            }
        });
    }

    const onResetClick = () => {
        userDispatch(prevState => {
            const originalUser = prevState.userState.lastEditRowId === "" ? EMPTY_USER_ENTITY :
                prevState.users.filter((user) => user.name === prevState.userState.lastEditRowId)[0];
            return {
                ...prevState,
                userState: {
                    ...prevState.userState,
                    currentSelectedData: originalUser,
                    allFormState: {}

                }
            }
        })
    }

    const onEditClick = () => {
        userDispatch(prevState => {
            return {
                ...prevState,
                userState: {
                    ...prevState.userState,
                    isEditable: true,
                    isRead: false,
                },
                userGroupState: {
                    ...prevState.userGroupState,
                    isRead: prevState.userGroupState.isSliderOpen ? false : true,
                    isEditable: false,
                    isSliderOpen: false,
                }
            }
        })
    }

    const onUserFieldChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }
        return userDispatch(prevState => {
            return {
                ...prevState,
                userState: {
                    ...prevState.userState,
                    currentSelectedData: {
                        ...prevState.userState.currentSelectedData,
                        [fieldKey]: val
                    },
                    allFormState: {
                        ...prevState.userState.allFormState,
                        [fieldKey]: ''
                    }
                }
            }
        });
    }

    const onUserSaveClicked = () => {
        userDispatch(prevState => {
            return {
                ...prevState,
                userState: {
                    ...prevState.userState,
                    isSaveClicked: true
                }
            }
        })
    }

    const onUserSave = async (user: UserEntity, isAdd: boolean, userName: string) => {
        const res = await Validation(isAdd ? createUserValidationSchema : updateUserValidationSchema).ValidateFormOnly(user);
        if (res) {
            userDispatch(prevState => {
                return {
                    ...prevState,
                    userState: {
                        ...prevState.userState,
                        allFormState: res
                    }
                }
            });
            return res;
        } else {
            user = { ...user, password: generateUniquePassword(), lastUpdatedBy: userName };
            if (isAdd) {
                return await userRepo.createNewUser(user).then((result) => {
                    if (result && result.toString().startsWith("Error:")) {
                        userDispatch(prevState => {
                            return {
                                ...prevState,
                                allFormState: { "SaveUserFail": result.toString() }
                            };
                        });
                        return { "SaveUserFail": result.toString() };
                    } else {
                        userDispatch(prevState => {
                            return {
                                ...prevState,
                                userState: {
                                    ...prevState.userState,
                                    isAdd: false,
                                    isRead: false,
                                    isEditable: false,
                                    isSelected: false,
                                    isSaveClicked: false,
                                    isSliderOpen: false,
                                    isTabularDataActive: true,
                                    allFormState: {},
                                    currentSelectedData: EMPTY_USER_ENTITY
                                },
                                userGroupState: {
                                    ...prevState.userGroupState,
                                    isSliderOpen: false,
                                    isAdd: false,
                                    isRead: true,
                                    isEditable: false,
                                    currentSelectedData: EMPTY_USER_GROUP_ENTITY
                                }
                            };
                        });
                    }
                }).catch((error) => {
                    throw new Error(error.messge);
                });
            } else {
                return await userRepo.updateUser(user).then((result) => {
                    if (result && result.toString().startsWith("Error:")) {
                        userDispatch(prevState => {
                            return {
                                ...prevState,
                                allFormState: { "SaveUserFail": result.toString() }
                            };
                        });
                        return { "SaveUserFail": result.toString() };
                    } else {
                        userDispatch(prevState => {
                            return {
                                ...prevState,
                                userState: {
                                    ...prevState.userState,
                                    isAdd: false,
                                    isRead: false,
                                    isEditable: false,
                                    isSelected: false,
                                    isSaveClicked: false,
                                    isSliderOpen: false,
                                    isTabularDataActive: true,
                                    allFormState: {},
                                    currentSelectedData: EMPTY_USER_ENTITY
                                },
                                userGroupState: {
                                    ...prevState.userGroupState,
                                    isSliderOpen: false,
                                    isAdd: false,
                                    isRead: true,
                                    isEditable: false,
                                    currentSelectedData: EMPTY_USER_GROUP_ENTITY
                                }
                            };
                        });
                    }
                }).catch((error) => {
                    throw new Error(error.messge);
                });
            }
        }
    }

    /**
     * update default operating company by user selected
     * @param userName 
     * @param operatingCompany 
     */
    const setDefaultOperatingCompany = (userName: string, operatingCompany: string) => {
        userRepo.setDefaultOperatingCompany(userName, operatingCompany).then(data => {
            if (data) {
                return true;
            }
        }).catch(error => {
            return false;
        })
    }

    /**
     * update the latest login date time by user name
     * @param userName 
     */
    const updateLatestLoginDatetime = (userName: string) => {
        const user: UserEntity = EMPTY_USER_ENTITY;
        user.name = userName;
        userRepo.updateLatestLoginDatetime(userName).then(data => {
            if (data) {
                return true;
            }
        }).catch(error => {
            return false;
        })
    }


    const switchOperatingCompany = async (userName: string, switchOperatingCompany: string) => {
        userRepo.switchOperatingCompany(userName, switchOperatingCompany).then(data => {
            if (data) {
                return true;
            }
            return false;
        })
    }
    //user

    //group
    const onGroupFieldChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }
        return userDispatch(prevState => {
            // let newVal:any = null;
            // if (_.isArray(val) && fieldKey === "nameList") {
            //     newVal = val?.map((item:any) => ({
            //         name: item,
            //         description: prevState.dynamicOptions.userGroupDropdownOptions?.find((group) => group.value === item)?.dropdownLabel
            //     }));
            // }
            return {
                ...prevState,
                userGroupState: {
                    ...prevState.userGroupState,
                    currentSelectedData: {
                        ...prevState.userGroupState.currentSelectedData,
                        [fieldKey]: val
                    }
                }
            }
        });
    }

    //user group
    const onGroupAddClick = () => {
        userDispatch(prevState => {
            return {
                ...prevState,
                userGroupState: {
                    ...prevState.userGroupState,
                    isTabularDataActive: false,
                    isAdd: true,
                    isRead: false,
                    isEditable: false,
                    isSliderOpen: true,
                    isSelected: false,
                    currentSelectedData: EMPTY_USER_GROUP_ENTITY,
                    lastEditRowId: ""
                },
            }
        })
    }

    const onGroupCloseClick = () => {
        userDispatch(prevState => {
            return {
                ...prevState,
                userGroupState: {
                    ...prevState.userGroupState,
                    isTabularDataActive: false,
                    isAdd: false,
                    isRead: false,
                    isEditable: false,
                    isSliderOpen: false,
                    isSelected: false,
                    currentSelectedData: EMPTY_USER_GROUP_ENTITY
                },
            }
        })
    }

    const onGroupSave = async (group: UserGroupEntity, isAdd: boolean) => {
        userDispatch(prevState => {
            let newUserGroups: UserGroupEntity[] = [];
            newUserGroups = group.nameList?.map((name) => ({
                ...EMPTY_USER_GROUP_ENTITY,
                name: name,
                description: prevState.dynamicOptions.userGroupDropdownOptions?.find(group => group.value === name)?.dropdownLabel
            })) ?? [];

            let finalUserGroups = [];
            const existedUserGroups = prevState.userState.currentSelectedData.groups?.filter((group) =>
                !newUserGroups.find((newGroup) => group.name === newGroup.name)
            )
            finalUserGroups = [
                ...existedUserGroups?.slice() ?? [],
                ...newUserGroups
            ]

            return {
                ...prevState,
                userState: {
                    ...prevState.userState,
                    currentSelectedData: {
                        ...prevState.userState.currentSelectedData,
                        groups: finalUserGroups
                    },
                },
                userGroupState: {
                    ...prevState.userGroupState,
                    isAdd: false,
                    isEditable: false,
                    isRead: false,
                    isSliderOpen: false,
                    isSelected: false,
                    isSaveClicked: true,
                    currentSelectedData: EMPTY_USER_GROUP_ENTITY
                }

            };
        });
    }

    const onGroupRowDoubleClick = (userGroup: UserGroupEntity) => {
        userDispatch(prevState => {
            return {
                ...prevState,
                userGroupState: {
                    ...prevState.userGroupState,
                    isTabularDataActive: false,
                    isAdd: false,
                    isRead: true,
                    isEditable: true,
                    isSliderOpen: true,
                    isSelected: false,
                    currentSelectedData: userGroup,
                    lastEditRowId: userGroup.name
                },
            }
        })
    }

    const onGroupRowDeleteClick = (userGroup: UserGroupEntity) => {
        userDispatch(prevState => {
            const newGroups = prevState.userState.currentSelectedData.groups?.filter((group: any) => group.name !== userGroup.name);
            return {
                ...prevState,
                userState: {
                    ...prevState.userState,
                    /*isTabularDataActive: false,
                    isAdd: false,
                    isRead: false,
                    isEditable: true,
                    isSliderOpen: true,
                    isSelected: true,*/
                    currentSelectedData: {
                        ...prevState.userState.currentSelectedData,
                        groups: newGroups,
                    }
                }
            }
        })
    }

    const onGroupSaveClicked = () => {
        userDispatch(prevState => {
            return {
                ...prevState,
                userGroupState: {
                    ...prevState.userGroupState,
                    isSaveClicked: true,
                }
            }
        })
    }

    const generateUniquePassword = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_*@#$%^&';
        let result = '';
        const charactersLength = characters.length;
        const array = new Uint32Array(8);

        window.crypto.getRandomValues(array);
        for (let i = 0; i < 8; i++) {
            // result += characters.charAt(Math.floor(Math.random() * charactersLength));
            result += characters.charAt(array[i] % charactersLength);
        }
        return result;
    };

    //user group    

    const onDateRangeChange = (startDate: any, endDate: any, dateFields: { startField: string, endField: string }) => {
        userDispatch(prevState => ({
            ...prevState,
            userState: {
                ...prevState.userState,
                currentSelectedData: {
                    ...prevState.userState.currentSelectedData,
                    [dateFields.startField]: startDate,
                    [dateFields.endField]: endDate,
                },
                allFormState: {
                    ...prevState.userState.allFormState,
                    validityFromDate: '',
                    validityToDate: '',
                }

            }
        }))
    }

    const onChangePassword = async (user: UserEntity) => {
        const res = await Validation(changePasswordValidationSchema).ValidateFormOnly(user);
        if (res) {
            changePasswordDispatch(prevState => {
                return {
                    ...prevState,
                    allFormState: res
                }
            });
            return res;
        } else {
            return userRepo.changePassword(user).then((result) => {
                if (result && result.toString().startsWith("Error:")) {
                    changePasswordDispatch(prevState => {
                        return {
                            ...prevState,
                            allFormState: { "ChangePasswordFail": result.toString() }
                        };
                    });
                    return { "ChangePasswordFail": result.toString() };
                } else {
                    changePasswordDispatch(prevState => {
                        return {
                            ...prevState,
                            user: EMPTY_USER_ENTITY,
                            allFormState: { "ChangePasswordSuccess": MessageConstant.common.CHANGEPASSWORDSUCCESSFUL }
                        };
                    });
                    return { "ChangePasswordSuccess": MessageConstant.common.CHANGEPASSWORDSUCCESSFUL };
                }
            }).catch((error) => {
                throw new Error(error.messge);
            });
        }
    }

    const onChangePasswordFieldChange = (fieldKey: string, fieldValue: any) => {
        changePasswordDispatch(prevState => {
            return {
                ...prevState,
                user: {
                    ...prevState.user ?? EMPTY_USER_ENTITY,
                    [fieldKey]: fieldValue
                }
            }
        });
    }

    return {
        loadAllUsers: loadAllUsers,
        loadDropdownOptions: loadDropdownOptions,
        onUserAddClick: onUserAddClick,
        onUserRowDoubleClick: onUserRowDoubleClick,
        onUserRowDeleteClick: onUserRowDeleteClick,
        onResetClick: onResetClick,
        onUserFieldChange: onUserFieldChange,
        onGroupFieldChange: onGroupFieldChange,
        onShowLoading: onShowLoading,
        onHideLoading: onHideLoading,
        onCloseClick: onCloseClick,
        onGroupAddClick: onGroupAddClick,
        onGroupCloseClick: onGroupCloseClick,
        onUserSave: onUserSave,
        onGroupSave: onGroupSave,
        onEditClick: onEditClick,
        onGroupRowDoubleClick: onGroupRowDoubleClick,
        onGroupRowDeleteClick: onGroupRowDeleteClick,
        onUserSaveClicked: onUserSaveClicked,
        onGroupSaveClicked: onGroupSaveClicked,
        generateUniquePassword: generateUniquePassword,
        setDefaultOperatingCompany: setDefaultOperatingCompany,
        updateLatestLoginDatetime: updateLatestLoginDatetime,
        switchOperatingCompany: switchOperatingCompany,
        onDateRangeChange: onDateRangeChange,
        onChangePassword: onChangePassword,
        onChangePasswordFieldChange: onChangePasswordFieldChange,
        onUserRenewClick: onUserRenewClick,
        onSelectedUsers: onSelectedUsers
    }
}