import { EMPTY_EQUIPMENT_CODE_IND_MAPPING_ENTITY, EquipmentCodeIndMappingEntity } from "domain/entity/EquipmentCodeIndMapping/EquipmentCodeIndMappingEntity";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";

export interface EquipmentCodeIndMappingDropdownOptions {
    equipmentCodeDropdownOptions: DropdownProps[],
}
export interface EquipmentCodeIndMappingViewChangeState extends BaseViewChangeSate {
}

export interface EquipmentCodeIndMappingModel {
    isLoading: boolean,
    isShowEditPanel: boolean,
    isAdd: boolean,
    isEdit: boolean,
    dynamicOptions: EquipmentCodeIndMappingDropdownOptions,
    equipmentCodeIndMappingList: EquipmentCodeIndMappingEntity[],
    selectedRows: EquipmentCodeIndMappingEntity[],
    updatedRows: EquipmentCodeIndMappingEntity[],
    currentRow: EquipmentCodeIndMappingEntity,
    currentEditRow: EquipmentCodeIndMappingEntity,
    forceRefresh: boolean,

}

export const EMPTY_EQUIPMENT_CODE_IND_MAPPING_MODEL: EquipmentCodeIndMappingModel = {
    isLoading: false,
    isShowEditPanel: false,
    isAdd: false,
    isEdit: false,
    dynamicOptions: {
        equipmentCodeDropdownOptions: [],
    },
    equipmentCodeIndMappingList: [],
    selectedRows: [],
    updatedRows: [],
    currentRow: { ...EMPTY_EQUIPMENT_CODE_IND_MAPPING_ENTITY },
    currentEditRow: { ...EMPTY_EQUIPMENT_CODE_IND_MAPPING_ENTITY },
    forceRefresh: false
}