import * as yup from 'yup';


export const commonTariffCodeValidation: { [x: string]: any; } = {
  tarSchemeCode: yup.string().required("Missing"),
  tariffCodeDesc: yup.string().required("Missing"),
  tariffType: yup.string().required("Missing"),
  tariffSeq1: yup.string().required("Missing"),
  tariffSeq2: yup.string().required("Missing"),
  tariffSeq3: yup.string().required("Missing"),
  tariffNatureList: yup.array().min(1, "Missing").required("Missing"),
};

export const commonTariffCodeComponentValidation: { [x: string]: any; } = {
  tariffCompCode: yup.string().required("Missing"),
  tariffCompValueCode: yup.string().required("Missing"),
};

export const createTariffCodeValidation: { [x: string]: any; } = {
  ...commonTariffCodeValidation,
};

export const updateTariffCodeValidation: { [x: string]: any; } = {
  ...commonTariffCodeValidation,
};

export const createTariffCodeComponentValidation: { [x: string]: any; } = {
  ...commonTariffCodeComponentValidation,
};

export const createTariffCodeValidationSchema = yup.object().shape(createTariffCodeValidation);
export const updateTariffCodeValidationSchema = yup.object().shape(updateTariffCodeValidation);
export const createTariffCodeComponentValidationSchema = yup.object().shape(createTariffCodeComponentValidation);
