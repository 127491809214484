import { EMPTY_COMPANY_CUSTOMER_INFO_ENTITY } from "domain/entity/Company/CompanyCustomerInfoEntity";
import { CustomerEntity } from "domain/entity/Company/CustomerEntity";
import { CustomerRepository } from "domain/repository/Company/CustomerRepo";
import { ParameterDetailRepository } from "domain/repository/parameter/ParameterDetailRepo";
import _ from "lodash";
import { CompanyDetailModel } from "presentation/model/Company/CompanyDetailModel";

import BaseViewModel from "presentation/viewModel/BaseViewModel";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";

interface CustomerVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<CompanyDetailModel>> | ((value: SetStateAction<CompanyDetailModel>) => void),
    ]
    customerRepo: CustomerRepository,
    parameterDetailRepo: ParameterDetailRepository

}

export const CustomerVM = ({ dispatch, customerRepo, parameterDetailRepo }: CustomerVMProps) => {
    const [companyDetailDispatch] = dispatch;

    const loadDropdownOption = async () => {
        await parameterDetailRepo.getAllParameterDtlsByParameterCode("REGION").then(
            regions => {
                let newRegions = _.orderBy(regions, ["parameterDtlCode"]);
                const regionDroOpts = newRegions?.map((region) => ({
                    dropdownLabel: region.parameterDtlCode,
                    tagLabel: region.parameterDtlCode,
                    value: region.parameterDtlCode,
                })) ?? [];

                companyDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        regionDropdownOptions: regionDroOpts
                    }
                }))
            }
        )
    }

    const onFieldChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        return companyDetailDispatch(prevState => {
            var regex = null;
            if (fieldKey === 'creditLimit') {
                regex = /^\d*(\.\d{0,2})?$/;
                if (!regex.test(val)) {
                    val = prevState.customerInformationState.currentCustomer[fieldKey];
                }
            }

            return {
                ...prevState,
                customerInformationState: {
                    ...prevState.customerInformationState,
                    currentCustomer: {
                        ...prevState.customerInformationState.currentCustomer,
                        [fieldKey]: val
                    }
                }
            }
        });
    }

    const onCheckboxChange = (checked: boolean, fieldName: string) => {
        companyDetailDispatch(prevState => ({
            ...prevState,
            customerInformationState: {
                ...prevState.customerInformationState,
                currentCustomer: {
                    ...prevState.customerInformationState.currentCustomer,
                    [fieldName]: checked ? "Y" : "N"
                }
            }
        }))
    }

    const onSave = async (currentCustomer: CustomerEntity) => {
        return await customerRepo.updateCustomer(currentCustomer).then((data) => {
            if (data && data.toString().startsWith("Error:")) {
                companyDetailDispatch(prevState => {
                    return {
                        ...prevState,
                        allFormState: { "saveCustomerInfoFailed": data.toString() }
                    };
                });
                return { "saveCustomerInfoFailed": data.toString() };
            } else {
                return companyDetailDispatch(prevState => {
                    return {
                        ...prevState,
                        isShowRightPanel: false,
                        customerInformationState: {
                            ...prevState.customerInformationState,
                            selectedDatas: [],
                            currentCompanyCustInfo: EMPTY_COMPANY_CUSTOMER_INFO_ENTITY
                        },
                        viewState: {
                            ...prevState.viewState,
                            isAdd: false,
                            isRead: true,
                            isEditable: false,
                            isSaveClicked: false,
                            lastEditRowId: '',
                            isSliderOpen: false,
                            allFormState: {},

                            isShowFsp: false,
                            isShowEmptyPool: false,
                            isShowSuppleTerms: false,
                            isShowCustAssign: false,
                            isShowFactor: false,
                            isShowCustInfo: false
                        },
                    }
                })


            }
        })

    }


    return {
        loadDropdownOption: loadDropdownOption,
        onFieldChange: onFieldChange,
        onCheckboxChange: onCheckboxChange,
        onSave: onSave,
    }
}