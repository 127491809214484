import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { CreditNoteHeaderEntity } from "domain/entity/CreditNote/CreditNoteHeaderEntity";
import { CreditNoteHeaderSearchCriteria } from "domain/entity/CreditNote/CreditNoteHeaderSearchCriteria";
import { NewCreditNoteData, NewCreditNoteDataList } from "domain/entity/CreditNote/NewCreditNoteData";
import { axiosPostData } from "../axios/AxiosBasicImpl";
import chargeAxiosInstance from "../axios/chargeAxiosInstance";
import { CreditNoteHeaderRepository } from "./CreditNoteHeaderRepo";
import { CreditNoteHeaderPrintCriteria } from "domain/entity/CreditNote/CreditNoteHeaderPrintCriteria";

export const CreditNoteHeaderRepoImpl = () : CreditNoteHeaderRepository => {
    const url = "/v1/creditNoteHeaderSearch"
    const getCreditNoteHeaders = async(searchCriteria: CreditNoteHeaderSearchCriteria) : Promise<CreditNoteHeaderEntity[]> => {
        return axiosPostData(chargeAxiosInstance, `${url}`, searchCriteria).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }
    const saveNewCreditNoteData= async(creditNoteData: NewCreditNoteData): Promise<CreditNoteHeaderEntity> => {
        const newUrl = "/v1/saveNewCreditNoteData"
        return axiosPostData(chargeAxiosInstance, `${newUrl}`, creditNoteData).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }
    const saveCreditNoteDataList= async(creditNoteData: NewCreditNoteDataList): Promise<CreditNoteHeaderEntity> => {
        const newUrl = "/v1/saveCreditNoteDataList"
        return axiosPostData(chargeAxiosInstance, `${newUrl}`, creditNoteData).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }
    const updateCreditNoteData= async(creditNoteData: NewCreditNoteData): Promise<CreditNoteHeaderEntity> => {
        const newUrl = "/v1/updateCreditNoteData"
        return axiosPostData(chargeAxiosInstance, `${newUrl}`, creditNoteData).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }

    const confirm = async (creHdrIds: number[]) : Promise<string> => {
        const newUrl = "/v1/creditChargeConfirm"
        return axiosPostData(chargeAxiosInstance, `${newUrl}`, creHdrIds).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return null;
        });
    }
    const unConfirm = async (creHdrIds: number[]) : Promise<string> => {
        const newUrl = "/v1/creditChargeUnConfirm"
        return axiosPostData(chargeAxiosInstance, `${newUrl}`, creHdrIds).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return null;
        });
    }

    const deleteCreditHdrs = async (creHdrIds: number[]) : Promise<ResponseEntity> => {
        const newUrl = "/v1/deleteCreditHdrs"
        try {
            return await axiosPostData(chargeAxiosInstance, `${newUrl}`, creHdrIds) as ResponseEntity;            
        } catch (err) {
            throw err;
        }
    }

    const reprintReport= async(printCriteria: CreditNoteHeaderPrintCriteria): Promise<ResponseEntity> => {
        const newUrl = '/v1/creditNoteHeader/reprintReport'
        return await axiosPostData(chargeAxiosInstance, `${newUrl}`, printCriteria);
    }

    const previewReport = async (printCriteria: CreditNoteHeaderPrintCriteria): Promise<any> => {
        const newUrl = "/v1/creditNoteHeader/previewReport";

        return axiosPostData(chargeAxiosInstance, `${newUrl}`, printCriteria).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }

    return {
        getCreditNoteHeaders: getCreditNoteHeaders,
        saveNewCreditNoteData: saveNewCreditNoteData,
        saveCreditNoteDataList: saveCreditNoteDataList,
        confirm: confirm,
        unConfirm: unConfirm,
        updateCreditNoteData: updateCreditNoteData,
        deleteCreditHdrs: deleteCreditHdrs,
        reprintReport: reprintReport,
        previewReport: previewReport
    }
}


