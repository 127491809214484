import { useProposalEnquiryVM } from "presentation/hook/ProposalEnquiry/useProposalEnquiryVM";
import { useProposalEnquiryTracked } from "presentation/store/ProposalEnquiry/ProposalEnquiryProvider";
import { useState } from "react";
import { DialogModal, HPHButton, IconButton, InputField } from "veronica-ui-component/dist/component/core";

export interface IPreferenceModal {
    visible: boolean;
}

export const SaveNewPreferenceModal: React.FC<IPreferenceModal> = (props: IPreferenceModal) => {
    const { visible } = props;
    const [preferenceName, setPreferenceName] = useState('');
    const [proposalEnquiryState] = useProposalEnquiryTracked();
    const proposalEnquiryVM = useProposalEnquiryVM();
    const userEmail = proposalEnquiryState.userEmail;
    const searchCriteria = proposalEnquiryState.searchCriteria;
    const enabledSearchCriteria = proposalEnquiryState.enabledSearchCriteria;

    const onConfirmClicked = () => {        
        proposalEnquiryVM.onShowLoading();
        proposalEnquiryVM.saveNewSearchPreference(userEmail, searchCriteria, enabledSearchCriteria, preferenceName).then(data => {
            proposalEnquiryVM.loadAllSearchPreference(userEmail).then(data => {
                proposalEnquiryVM.onHideLoading();
            }).catch(error => {
                proposalEnquiryVM.onHideLoading();
            })
        }).catch(error => {
            proposalEnquiryVM.onHideLoading();
        })
    }
    
    return <DialogModal
        appendTo='self'
        showButton={false}
        visible={visible}
        positions="center"
        resizable={false}
        header={
            <div className="im-charge-data-search-confirm-modal-header">
                <span className="name">NEW PREFERENCE</span>
                <IconButton fileName='Icon-cross' size="medium" onClick={proposalEnquiryVM.closeConfirmModal} tooltipDisable={true} />
            </div>
        }
        dialogContent={
            <div className="im-charge-data-search-confirm-modal-content">
                <InputField maxLength={999} label="Name" type="text" value={preferenceName} onChange={(e) => setPreferenceName(e.target.value)} />
            </div>
        }
        className="im-charge-data-search-confirm-modal"
        footer={
            <div className="im-charge-data-search-confirm-modal-footer">
                <HPHButton label={'Confirm'} size={'Small'} theme={'Primary'} onClick={onConfirmClicked} />
            </div>
        }
    />;
};
