import { GeneralProposalConstant } from "presentation/constant/GeneralProposal/GeneralProposalConstant";
import { useGeneralProposalVM } from "presentation/hook/GeneralProposal/useGeneralProposalVM";
import { useGeneralProposalTracked } from "presentation/store/GeneralProposal/GeneralProposalProvider";
import { useCallback, useState } from "react";
import { HPHButton, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarActionBar, SidebarActionCross, Sidebarheader, SidebarTitle } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import GeneralProposalSearchForm from "./GeneralProposalSearchForm";

const GeneralProposalSearchPanel = () => {
    const GENERAL_PROPOSAL_CONSTANT = GeneralProposalConstant.Search;
    const [generalProposalState] = useGeneralProposalTracked();
    const generalProposalVM = useGeneralProposalVM();
    const { searchCriteria } = generalProposalState;
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const resetButtonClicked = useCallback(() => {
        generalProposalVM.onSearchCriteriaResetClick();
    }, [generalProposalVM]);

    const searchButtonClicked = useCallback(() => {

        setIsLoading(true);
        generalProposalVM.searchGeneralProposal(searchCriteria, "").then((data) => {
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
        })
    }, [searchCriteria, generalProposalVM])

    return (
        <div className='side-form-content-left-wrapper'>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>{GENERAL_PROPOSAL_CONSTANT.SEARCH_CRITERIA}</SidebarTitle>
                        <SidebarActionCross>
                            <IconButton
                                fileName="Icon-cross" size="medium" toolTipText='Close' toolTipArrow={false} onClick={generalProposalVM.onSearchClick} />
                        </SidebarActionCross>
                    </Sidebarheader>
                </div>
            </div>

            {isLoading && <Loader Indicator="Spinner" size="Medium" />}

            <div className={'add-edit-form'} style={{ maxHeight: '75vh', height: '75vh', overflow: 'auto' }}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>

                        {/* Stack Form Start */}
                        <GeneralProposalSearchForm />
                    </div>
                </div>
            </div>
            <SidebarActionBar>
                <HPHButton label={'Reset'} size={'Small'} theme={'Secondary'} onClick={resetButtonClicked} />
                <HPHButton label={'Search'} size={'Small'} theme={'Primary'} onClick={() => searchButtonClicked()} />
            </SidebarActionBar>
        </div>
    );
}

export default GeneralProposalSearchPanel;
