import { EMPTY_TARIFF_MAPPING_ENTITY, TariffMappingEntity } from "domain/entity/TariffMapping/TariffMappingEntity";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";

export interface TariffMappingDropdownOptions {
    tariffTypeDropdownOptions: DropdownProps[],
    tariffCodeDropdownOptions: { [key: string]: DropdownProps[] },
    chargeTypeDropdownOptions: DropdownProps[],
    subChargeTypeDropdownOptions: { [key: string]: DropdownProps[] },
    opsLineDropdownOptions: DropdownProps[],
    companyCodeDropdownOptions: DropdownProps[],
    cntrSizeDropdownOptions: DropdownProps[],
    cntrTypeDropdownOptions: DropdownProps[],
    shipmentTypeDropdownOptions: DropdownProps[],
    serviceCodeDropdownOptions: DropdownProps[],
    modalityDropdownOptions: DropdownProps[],
    marshalCodeDropdownOptions: DropdownProps[],
    terminalCodeDropdownOptions: DropdownProps[],
    marshallingTypeDropdownOptions: DropdownProps[],
    chargeIndDropdownOptions: DropdownProps[],
    specHandingIndDropdownOptions: DropdownProps[],
    operationTypeDropdownOptions: DropdownProps[],
    forwarderCodeDropdownOptions: DropdownProps[],
    consortiumCodeDropdownOptions: DropdownProps[],
    cntrTypeGroupDropdownOptions: DropdownProps[],
    physicalHandlingCodeDropdownOptions: DropdownProps[],
    serviceOrderCodeDropdownOptions: DropdownProps[],
    cargoTypeDropdownOptions: DropdownProps[],
    cargoCodeDropdownOptions: DropdownProps[],
    specialAreaDropdownOptions: DropdownProps[],
    specialIndDropdownOptions: DropdownProps[],
    transhipmentLegChargeIndDropdownOptions: DropdownProps[],
    equipmentCodeIndDropdownOptions: DropdownProps[],
    shiftCodeDropdownOptions: DropdownProps[],

}
export interface TariffMappingViewChangeState extends BaseViewChangeSate {
}

export interface TariffMappingModel {
    isLoading: boolean,
    isShowEditPanel: boolean,
    isAdd: boolean,
    isEdit: boolean,
    dynamicOptions: TariffMappingDropdownOptions,
    tariffMappingList: TariffMappingEntity[],
    selectedRows: TariffMappingEntity[],
    updatedRows: TariffMappingEntity[],
    currentRow: TariffMappingEntity,
    currentEditRow: TariffMappingEntity,
    forceRefresh: boolean,

}

export const EMPTY_TARIFF_MAPPING_MODEL: TariffMappingModel = {
    isLoading: false,
    isShowEditPanel: false,
    isAdd: false,
    isEdit: false,
    dynamicOptions: {
        chargeTypeDropdownOptions: [],
        subChargeTypeDropdownOptions: {},
        opsLineDropdownOptions: [],
        companyCodeDropdownOptions: [],
        cntrSizeDropdownOptions: [],
        cntrTypeDropdownOptions: [],
        shipmentTypeDropdownOptions: [],
        serviceCodeDropdownOptions: [],
        modalityDropdownOptions: [],
        marshalCodeDropdownOptions: [],
        terminalCodeDropdownOptions: [],
        marshallingTypeDropdownOptions: [],
        chargeIndDropdownOptions: [],
        specHandingIndDropdownOptions: [],
        operationTypeDropdownOptions: [],
        tariffTypeDropdownOptions: [],
        tariffCodeDropdownOptions: {},
        forwarderCodeDropdownOptions: [],
        consortiumCodeDropdownOptions: [],
        cntrTypeGroupDropdownOptions: [],
        physicalHandlingCodeDropdownOptions: [],
        serviceOrderCodeDropdownOptions: [],
        cargoTypeDropdownOptions: [],
        cargoCodeDropdownOptions: [],
        specialAreaDropdownOptions: [],
        specialIndDropdownOptions: [],
        transhipmentLegChargeIndDropdownOptions: [],
        equipmentCodeIndDropdownOptions: [],
        shiftCodeDropdownOptions: [],
    },
    tariffMappingList: [],
    selectedRows: [],
    updatedRows: [],
    currentRow: { ...EMPTY_TARIFF_MAPPING_ENTITY },
    currentEditRow: { ...EMPTY_TARIFF_MAPPING_ENTITY },
    forceRefresh: false
}