import { ManualChargeConstant } from "presentation/constant/ManualCharge/ManualChargeConstant";
import { adjIndOptions } from "presentation/constant/RadioOptions/StaticRadioOptions";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useManualChargeHeaderMaintenanceVM } from "presentation/hook/ManualCharge/useManualChargeHeaderMaintenanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useManualChargeHeaderMaintenanceTracked } from "presentation/store/ManualCharge/ManualChargeHeaderMaintenanceProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DateRangePickerComponent from "presentation/view/components/DateRangePickerComponent";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { memo, useCallback, useMemo, useState } from "react";
import { DialogModal, FieldType, HPHButton, HPHGroupRadioButton, HPHInputMask, IconButton, IFieldValue, Loader } from "veronica-ui-component/dist/component/core";

const ManualChargeHeaderPrintModal: React.FC = () => {
    const [manChgHdrState] = useManualChargeHeaderMaintenanceTracked();
    const manChgHdrVM = useManualChargeHeaderMaintenanceVM();
    const messageBarVM = useMessageBarVM();

    const MAN_CHG_HDR_CONSTANT = ManualChargeConstant.Print;
    const { isShowPrintModal, printCriteria, dynamicOptions } = manChgHdrState;
    const { chargeTypeDropdownOptions, billToCompDropdownOptions, printerDropdownOptions } = dynamicOptions;
    const [isLoading, setIsLoading] = useState(false);

    const handleReprint = useCallback(async () => {
        if (!(printCriteria?.printQueueName)) {
            messageBarVM.showError("Please select a Printer.", true);
            return;
        }
        setIsLoading(true);

        manChgHdrVM.onReprint(printCriteria).then((data) => {
            if (data && data["printReportFail"]) {
                messageBarVM.showError(data["printReportFail"], true);
                return;
            }

            messageBarVM.showSuccess("Report is sent to printers successfully.", true);
        }).catch((error) => {
            setIsLoading(false);
            messageBarVM.showError(error.message)
        }).finally(() => {
            setIsLoading(false);
        });
    }, [messageBarVM, manChgHdrVM, printCriteria]);

    const handlePreview = useCallback(async () => {
        if (!(printCriteria?.printQueueName)) {
            messageBarVM.showError("Please select a Printer.", true);
            return;
        }
        setIsLoading(true);

        manChgHdrVM.onPreview(printCriteria).then((data) => {
            if (data && data["printReportFail"]) {
                messageBarVM.showError(data["printReportFail"], true);
                return;
            }

            messageBarVM.showSuccess("Preview report successfully.", true);
        }).catch((error) => {
            setIsLoading(false);
            messageBarVM.showError(error.message)
        }).finally(() => {
            setIsLoading(false);
        });
    }, [messageBarVM, manChgHdrVM, printCriteria]);

    const memoAdjInd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <HPHGroupRadioButton
                label="Option"
                radioOptions={adjIndOptions}
                value={printCriteria.adjInd ?? ''}
                onChange={(e) => manChgHdrVM.onRadioChange(e, 'adjInd')}
                checked={adjIndOptions.find(item => item.key === printCriteria.adjInd)?.name}
            />
        </div>
        , [manChgHdrVM, printCriteria.adjInd])

    const memoBillToCompany = useMemo(() =>
        <div className='im-flex-row-item' style={{ width:"150px", marginBottom: "24px" }}>
            <NbisCommonField
                //errorMessages={allFormState}
                isReadOnly={false}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={printCriteria.billToCompany ?? ""}
                fieldValue={printCriteria.billToCompany ?? ""}
                fieldLabel={MAN_CHG_HDR_CONSTANT.BILL_TO_COMPANY}
                //isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'billToCompany'}
                isShowMissingError={true}
                maxLength={10}
                options={billToCompDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    manChgHdrVM.onPrintCriteriaFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [MAN_CHG_HDR_CONSTANT.BILL_TO_COMPANY, billToCompDropdownOptions, manChgHdrVM, printCriteria.billToCompany])

    const memoChargeType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width:"150px", marginBottom: "24px" }}>
            <NbisCommonField
                //errorMessages={allFormState}
                isReadOnly={false}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={printCriteria.chargeType ?? ""}
                fieldValue={printCriteria.chargeType ?? ""}
                fieldLabel={MAN_CHG_HDR_CONSTANT.CHARGE_TYPE}
                //isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'chargeType'}
                isShowMissingError={true}
                maxLength={10}
                options={chargeTypeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    manChgHdrVM.onPrintCriteriaFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [MAN_CHG_HDR_CONSTANT.CHARGE_TYPE, chargeTypeDropdownOptions, manChgHdrVM, printCriteria.chargeType])

    const memoRefNoFrom = useMemo(() =>
        <div className='im-flex-row-item' style={{ width:"150px", marginBottom: "24px" }}>
            <NbisCommonField
                //errorMessages={allFormState}
                isReadOnly={false}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={printCriteria.refNoFrom ?? ""}
                fieldValue={printCriteria.refNoFrom ?? ""}
                fieldLabel={MAN_CHG_HDR_CONSTANT.REF_NO_FROM}
                //isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'refNoFrom'}
                isShowMissingError={true}
                maxLength={10}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    manChgHdrVM.onPrintCriteriaFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [MAN_CHG_HDR_CONSTANT.REF_NO_FROM, manChgHdrVM, printCriteria.refNoFrom])

    const memoRefNoTo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width:"150px", marginBottom: "24px" }}>
            <NbisCommonField
                //errorMessages={allFormState}
                isReadOnly={false}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={printCriteria.refNoTo ?? ""}
                fieldValue={printCriteria.refNoTo ?? ""}
                fieldLabel={MAN_CHG_HDR_CONSTANT.REF_NO_TO}
                //isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'refNoTo'}
                isShowMissingError={true}
                maxLength={10}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    manChgHdrVM.onPrintCriteriaFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [MAN_CHG_HDR_CONSTANT.REF_NO_TO, manChgHdrVM, printCriteria.refNoTo])

    const memoDocDateRange = useMemo(() =>
        <div className='im-flex-row-item' style={{ width:"330px", marginBottom: "24px" }}>
            <DateRangePickerComponent
                label={MAN_CHG_HDR_CONSTANT.OPS_DATE_RANGE}
                width='330px'
                fieldNames={{ startField: "opsDateFrom", endField: "opsDateTo" }}
                dateRange={{ startDate: printCriteria.opsDateFrom, endDate: printCriteria.opsDateTo }}
                //errorMessage={allFormState?.opsDateFrom || allFormState?.opsDateTo}
                onDatesChange={manChgHdrVM.onPrintDateRangeChange}
            />
        </div>
        , [MAN_CHG_HDR_CONSTANT.OPS_DATE_RANGE, printCriteria.opsDateFrom, printCriteria.opsDateTo, manChgHdrVM.onPrintDateRangeChange])

    const memoPrinter = useMemo(() =>
        <div className='im-flex-row-item' style={{ width:"150px", marginBottom: "24px" }}>
            <NbisCommonField
                //errorMessages={allFormState}
                isReadOnly={false}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={printCriteria.printQueueName ?? ""}
                fieldValue={printCriteria.printQueueName ?? ""}
                fieldLabel={MAN_CHG_HDR_CONSTANT.PRINTER}
                //isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'printQueueName'}
                isShowMissingError={true}
                maxLength={10}
                options={printerDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    manChgHdrVM.onPrintCriteriaFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [MAN_CHG_HDR_CONSTANT.PRINTER, manChgHdrVM, printCriteria.printQueueName, printerDropdownOptions])

    const memoCoVslVoy = useMemo(() =>
        <div className='im-flex-row-item'>
            <HPHInputMask
                label={MAN_CHG_HDR_CONSTANT.CO_VSL_VOY}
                value={{
                    co: printCriteria.consortiumCode ?? '',
                    vsl: printCriteria.vesselCode ?? '',
                    voy: printCriteria.voyageCode ?? ''
                }}
                width="330px"
                fieldConfiguration={{
                    co: { maxLength: 60, autoComplete: "off", valueInNormalCase: true, isNumeric: false },
                    vsl: { maxLength: 10, autoComplete: "off", valueInNormalCase: true, isNumeric: false },
                    voy: { maxLength: 10, autoComplete: "off", valueInNormalCase: true, isNumeric: false },
                }}
                onDataChange={(e: any) => manChgHdrVM.onPrintCoVslVoyChange(e, {
                    co: 'consortiumCode',
                    vsl: 'vesselCode',
                    voy: 'voyageCode'
                })} />
        </div>
        , [MAN_CHG_HDR_CONSTANT.CO_VSL_VOY, printCriteria.consortiumCode, printCriteria.vesselCode, printCriteria.voyageCode, manChgHdrVM])

    const refreshModal = () => {
        if (document) {
            const myModal = document.getElementById("manualChargeHeaderPrintModal");
            if (myModal) {
                const myContent = document.querySelector(".p-dialog-content");
                if (myContent) {
                    (myContent as HTMLElement).style.overflow = "visible";
                }
            }
        }
    }

    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;

    return <DialogModal style={{ minWidth: '32vw', minHeight: '55vh' }}
        appendTo='self'
        showButton={false}
        visible={isShowPrintModal}
        positions="center"
        resizable={false}
        header={
            <div className="im-charge-data-search-confirm-modal-header">
                <span className="name">{MAN_CHG_HDR_CONSTANT.TITLE}</span>
                <IconButton fileName='Icon-cross' size="medium" onClick={manChgHdrVM.onClosePrintModal} tooltipDisable={true} />
            </div>
        }
        dialogContent={
            <div className={"im-charge-data-search-confirm-modal-content"}>

                <CriteriaItemContainer>
                    {memoAdjInd}
                </CriteriaItemContainer>

                <CriteriaItemContainer>
                    {memoBillToCompany}
                    {memoRefNoFrom}
                    {memoRefNoTo}
                </CriteriaItemContainer>

                <CriteriaItemContainer>
                    {memoChargeType}
                    {memoDocDateRange}
                </CriteriaItemContainer>

                <CriteriaItemContainer>
                    {memoPrinter}
                    {memoCoVslVoy}
                </CriteriaItemContainer>
            </div>
        }
        className="im-charge-data-search-confirm-modal"
        id="manualChargeHeaderPrintModal"
        onShow={refreshModal}
        footer={
            <div className="im-charge-data-search-confirm-modal-footer">
                <HPHButton label={WorkspaceConstant.Common.BUTTON_CANCEL} size={'Small'} theme={'Secondary'} onClick={manChgHdrVM.onClosePrintModal} />
                <HPHButton label={"Preview"} size={'Small'} theme={'Secondary'} onClick={handlePreview} />
                <HPHButton label={WorkspaceConstant.Common.BUTTON_PRINT} size={'Small'} theme={'Primary'} onClick={handleReprint} />
            </div>
        }
    />;
};

export default memo(ManualChargeHeaderPrintModal);