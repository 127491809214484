export interface CreditNoteHeaderPrintCriteria{
    
    billToCompany?: string | null,
    reqNoFrom?: string | null,
    reqNoTo?: string | null,
    creditNoteNoFrm?: string | null,
    creditNoteNoTo?: string | null,
    creditChargeNoFrm?: string | null,
    creditChargeNoTo?: string | null,
    printQueueName?: string | null,

    [key: string]: string[] | string | boolean | number | Date | null | undefined
}

export const EMPTY_CREDIT_NOTE_HEADER_PRINT_CRITERIA : CreditNoteHeaderPrintCriteria = {

    billToCompany: null,
    reqNoFrom: null,
    reqNoTo: null,
    creditNoteNoFrm: null,
    creditNoteNoTo: null,
    creditChargeNoFrm: null,
    creditChargeNoTo: null,
    printQueueName: null,
}