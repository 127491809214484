import { adjTypeDropdownOption } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { useChargeDetailVM } from "presentation/hook/ChargeDetailMaintenance/useChargeDetailVM";
import { ChargeDetailModel } from "presentation/model/ChargeDetailMaintenance/ChargeDetailModel";
import NbisInputDropDown from "presentation/view/components/NbisInputDropDown";

export const AdjustmentAccordion = ({ chargeDetailState }: { chargeDetailState: ChargeDetailModel }) => {
    const chargeBatchUpdate = chargeDetailState.chargeBatchUpdate
    const chargeDetailVM = useChargeDetailVM();
    return (
        <>
            <div className={'im-flex-row'} style={{ marginBottom: '24px' }}>
                <div className='im-flex-row-item'>
                    <NbisInputDropDown
                        label='Adjustment Type'
                        width='330px'
                        inputType="freeText"
                        mode={"single"}
                        value={chargeBatchUpdate.adjustmentType}
                        onChange={(e: any) => chargeDetailVM.onDropdownChange(e, 'adjustmentType', chargeBatchUpdate, 'chargeBatchUpdate')}
                        options={adjTypeDropdownOption.filter(type => (type.value !== "NORMAL" && type.value !== "RECAL"))}
                        errorMessage={chargeBatchUpdate?.allFormState?.adjustmentType ?? ""}
                    />
                </div>
            </div>

            <div className={'im-flex-row'} style={{ marginBottom: '24px' }}>
                <div className='im-flex-row-item'>
                    <NbisInputDropDown
                        label='Reason Code'
                        width='330px'
                        inputType="freeText"
                        mode={"single"}
                        value={chargeBatchUpdate.adjustmentReasonCode}
                        onChange={(e: any) => chargeDetailVM.onDropdownChange(e, 'reasonCode', chargeBatchUpdate, 'chargeBatchUpdate')}
                        options={chargeDetailState.dynamicOptions.reasonCodeDropdownOptions}
                        errorMessage={chargeBatchUpdate?.allFormState?.reasonCode ?? ""}
                    />
                </div>
            </div>
        </>
    )
}