import { FinancialReportEntity } from "domain/entity/FinancialReport/FinancialReportEntity";
import { GeneralReportEntity } from "domain/entity/GeneralReport/GeneralReportEntity";

export const generalReportCheckFieldMandatory = (entity: GeneralReportEntity): string | null => {
    let ret = null;
    switch (entity.reportType) {
        case "Company Master Report":
            break;
        case "Credit Note Summary":
            break;
        case "Criteria Checklist":
            break;
        case "Daily Invoice Summary":
            if (!entity.docDateFrom || !entity.docDateTo) {
                ret = "Doc Date (From) and Doc Date (To) is mandatory.";
            }
            break;
        case "Dayend Report":
            break;
        case "Empty Container Summary Report":
            break;
        case "Empty Pool Container List Report":
            if (!entity.year || !entity.month) {
                ret = "Year and Month is mandatory.";
            }
            break;
        case "Exception Report":
            if (!entity.exceptionType) {
                ret = "Exception Type is mandatory.";
            }
            break;
        case "Invoice Register":
            if (!entity.docDateFrom || !entity.docDateTo) {
                ret = "Doc Date (From) and Doc Date (To) is mandatory.";
            }
            break;
        case "Invoice Summary (Non Operation) Report":
            break;
        case "Invoice Summary (Operation) Report":
            break;
        case "Manual/Adjustment Credit Notes Summary":
            break;
        case "Monthly Invoice Summary":
            if (!entity.year || !entity.month) {
                ret = "Year and Month is mandatory.";
            }
            break;
        case "Monthly Outstanding Turnover Summary":
            if (!entity.year || !entity.month) {
                ret = "Year and Month is mandatory.";
            }
            break;
        case "Monthly Revenue Report":
            if (!entity.fromYear || !entity.toYear || !entity.fromMonth || !entity.toMonth) {
                ret = "From Year, To Year, From Month and To Month is mandatory.";
            }
            break;
        case "Monthly Turnover Check":
            if (!entity.year || !entity.month) {
                ret = "Year and Month is mandatory.";
            }
            break;
        case "Outstanding Turnover By Clerk":
            if (!entity.date) {
                ret = "Date is mandatory.";
            }
            break;
        case "Outstanding Turnover Summary":
            if (!entity.date) {
                ret = "Date is mandatory.";
            }
            break;
        case "Proposal Master Report":
            break;
        case "Summary of Customer Monthly Balance":
            if (!entity.docDateFrom || !entity.docDateTo) {
                ret = "Doc Date (From) and Doc Date (To) is mandatory.";
            }
            break;
        case "T3 Invoice Revenue Report":
            break;
        case "Tariff Adjustment Report":
            if (!entity.companyCodeFrom || !entity.companyCodeTo || !entity.proposalFrom || !entity.proposalTo) {
                ret = "Company Code (From), Company Code (To), Proposal (From), Proposal (To) is mandatory.";
            }
            if (entity.companyCodeFrom === entity.companyCodeTo && entity.proposalFrom === entity.proposalTo) {
                ret = "Proposal (From) can not be the same with Proposal (To).";
            }
            break;
        case "Tariff item Mapping List":
            break;
        case "Tax Report":
            if (!entity.docDateFrom || !entity.docDateTo) {
                ret = "Doc Date (From) and Doc Date (To) is mandatory.";
            }
            break;
        default:
            break;
    }
    if (entity.docDateFrom && entity.docDateTo && entity.docDateFrom > entity.docDateTo) {
        ret = "Doc Date (To) should be later than or equals to Doc Date (From).";
    }
    if (entity.opsDateFrom && entity.opsDateTo && entity.opsDateFrom > entity.opsDateTo) {
        ret = "Ops Date (To) should be later than or equals to Ops Date (From).";
    }
    if (entity.effectiveDateFrom && entity.effectiveDateTo && entity.effectiveDateFrom > entity.effectiveDateTo) {
        ret = "Effective Date (To) should be later than or equals to Effective Date (From).";
    }
    if (entity.expiryDateFrom && entity.expiryDateTo && entity.expiryDateFrom > entity.expiryDateTo) {
        ret = "Expiry Date (To) should be later than or equals to Expiry Date (From).";
    }
    if (entity.exceptionDateFrom && entity.exceptionDateTo && entity.exceptionDateFrom > entity.exceptionDateTo) {
        ret = "Exception Date (To) should be later than or equals to Exception Date (From).";
    }
    if (entity.glDateFrom && entity.glDateTo && entity.glDateFrom > entity.glDateTo) {
        ret = "GL Date (To) should be later than or equals to GL Date (From).";
    }
    if (entity.fromYear && entity.fromMonth && entity.toYear && entity.toMonth) {
        if (entity.fromYear >= entity.toYear) {
            if (entity.fromYear === entity.toYear) {
                if (entity.fromMonth > entity.toMonth) {
                    ret = "To Month should be later than or equals to From Month.";
                }
            } else {
                ret = "To Year should be later than or equals to From Year.";
            }
        }

    }
    return ret;
}

export const isDocDateShowOptional = (reportType: string): boolean => {
    let ret = true;
    switch (reportType) {
        case "Daily Invoice Summary":
            ret = false;
            break;
        case "Invoice Register":
            ret = false;
            break;
        case "Summary of Customer Monthly Balance":
            ret = false;
            break;
        case "Tax Report":
            ret = false;
            break;
        default:
            break;
    }
    return ret;
}


export const financialReportCheckDocAndGLDateField = (entity: FinancialReportEntity): boolean => {


    if (entity.docDateFrom && entity.docDateTo && entity.glDateFrom && entity.glDateTo) {
        return true;
    }
    if (entity.docDateFrom && entity.docDateTo && !entity.glDateFrom && !entity.glDateTo) {
        return true;
    }
    if (!entity.docDateFrom && !entity.docDateTo && entity.glDateFrom && entity.glDateTo) {
        return true;
    }
    return false;

}
export const financialReportCheckFieldMandatory = (entity: FinancialReportEntity): string | null => {
    let ret = null;
    switch (entity.reportType) {
        case "Billing By Client and Concept":
            if (!financialReportCheckDocAndGLDateField(entity)) {
                ret = "Please input either Doc Date or GL Date or both in the criteria.";
            }
            break;
        case "Billing Comparative":
            if (!financialReportCheckDocAndGLDateField(entity)) {
                ret = "Please input either Doc Date or GL Date or both in the criteria.";
            }
            break;
        case "Comparative between TEUs":
            if (!entity.p1OperationDateFrom || !entity.p1OperationDateTo || !entity.p2OperationDateFrom || !entity.p2OperationDateTo) {
                ret = "VSL Start Operation Date From, VSL Start Operation Date To is mandatory.";
            }
            break;
        case "Movement List":
            if (!entity.vslOperationDateFrom || !entity.vslOperationDateTo) {
                ret = "VSL Start Operation Date From, VSL Start Operation Date To is mandatory.";
            }
            break;
        case "Movements Per Line":
            if (!entity.vslOperationDateFrom || !entity.vslOperationDateTo) {
                ret = "VSL Start Operation Date From, VSL Start Operation Date To is mandatory.";
            }
            break;
        case "Movements Per Ship Owner":
            if (!entity.vslOperationDateFrom || !entity.vslOperationDateTo) {
                ret = "VSL Start Operation Date From, VSL Start Operation Date To is mandatory.";
            }
            break;
        case "Output Statistics":
            if (!entity.etdFrom || !entity.etdTo) {
                ret = "ETD From, ETD To is mandatory.";
            }
            break;
        case "Price Changes List":
            if (!entity.chargeOnCompany || !entity.effectiveDateFrom || !entity.effectiveDateTo) {
                ret = "Charge On Company, Effective Date From, Effective Date To is mandatory.";
            }
            break;
        case "Rail Movement":
            if (!entity.operationDateFrom || !entity.operationDateTo) {
                ret = "Operation Date From, Operation Date To is mandatory.";
            }
            break;
        case "Revenue By Tariff And Customer":
            if (!entity.year || !entity.month || !entity.printer) {
                ret = "Year and Month is mandatory.";
            }
            break;
        case "TEUs Billed":
            if (!financialReportCheckDocAndGLDateField(entity)) {
                ret = "Please input either Doc Date or GL Date or both in the criteria.";
            }
            break;
        case "Vessel movements report":
            if (!entity.vslOperationDateFrom || !entity.vslOperationDateTo) {
                ret = "VSL Start Operation Date From, VSL Start Operation Date To is mandatory.";
            }
            break;
        default:
            break;
    }
    if (entity.docDateFrom && entity.docDateTo && entity.docDateFrom > entity.docDateTo) {
        ret = "Doc Date (To) should be later than or equals to Doc Date (From).";
    }
    if (entity.glDateFrom && entity.glDateTo && entity.glDateFrom > entity.glDateTo) {
        ret = "GL Date (To) should be later than or equals to GL Date (From).";
    }
    if (entity.etdFrom && entity.etdTo && entity.etdFrom > entity.etdTo) {
        ret = "ETD (To) should be later than or equals to ETD (From).";
    }
    if (entity.p1OperationDateFrom && entity.p1OperationDateTo && entity.p1OperationDateFrom > entity.p1OperationDateTo) {
        ret = "VSL Start Operation Date1 (To) should be later than or equal to VSL Start Operation Date1 (From).";
    }
    if (entity.p2OperationDateFrom && entity.p2OperationDateTo && entity.p2OperationDateFrom > entity.p2OperationDateTo) {
        ret = "VSL Start Operation Date2 (To) should be later than or equal to VSL Start Operation Date2 (From).";
    }
    if (entity.vslOperationDateFrom && entity.vslOperationDateTo && entity.vslOperationDateFrom > entity.vslOperationDateTo) {
        ret = "VSL Start Operation Date (To) should be later than or equal to VSL Start Operation Date (From).";
    }
    if (entity.effectiveDateFrom && entity.effectiveDateTo && entity.effectiveDateFrom > entity.effectiveDateTo) {
        ret = "Effective Date (To) should be later than or equals to Effective Date (From).";
    }
    if (entity.operationDateFrom && entity.operationDateTo && entity.operationDateFrom > entity.operationDateTo) {
        ret = "Operation Date (To) should be later than or equals to Operation Date (From).";
    }
    return ret;
}