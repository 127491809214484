import { EMPTY_GENERAL_PROPOSAL_ENTITY, GeneralProposalEntity } from "domain/entity/GeneralProposal/GeneralProposalEntity";
import { EMPTY_GEN_PROPOSAL_IS_ENTITY, GeneralProposalIsEntity } from "domain/entity/GeneralProposal/GeneralProposalIsEntity";
import { EMPTY_GENERAL_PROPOSAL_SEARCH_CRITERIA, GeneralProposalSearchCriteria } from "domain/entity/GeneralProposal/GeneralProposalSearchCriteria";
import { EMPTY_GEN_PROPOSAL_TARIFF_ITEM_TIER_ENTITY, GenProposalTariffItemTierEntity } from "domain/entity/GeneralProposal/GeneralProposalTariffItemTierEntity";

import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";

export interface GeneralProposalDropdownOptions {

    statusDropdownOptions: DropdownProps[],
    tariffTypeDropdownOptions: DropdownProps[],
    tariffCodeDropdownOptions: { [key: string]: DropdownProps[] },
    forwarderCodeDropdownOptions: DropdownProps[],
    currencyCodeDropdownOptions: DropdownProps[],
    tariffNatureDropdownOptions: DropdownProps[],
    shiftCodeDropdownOptions: DropdownProps[],

}
export interface GeneralProposalChangeState extends BaseViewChangeSate {
}

export interface GeneralProposalModel {
    gnProposalState: GeneralProposalChangeState,
    isLoading: boolean,
    isShowDetail: boolean,
    isShowCriteriaPanel: boolean,
    generalProposalList: GeneralProposalEntity[],
    generalProposalAllList: GeneralProposalEntity[],
    currentSelectedRow: GeneralProposalEntity,
    selectedRows: GeneralProposalEntity[],
    isRejected: boolean,
    isRejectedCount: number,
    isPendingApproval: boolean,
    isPendingApprovalCount: number,
    // isAll: boolean,
    // isAllCount: number,
    searchCriteria: GeneralProposalSearchCriteria,
    dynamicOptions: GeneralProposalDropdownOptions,
    isAllowAutoSearch: boolean,
    isBackFromDetail: boolean,
    searchStatus: string,
    isShowRejectPad: boolean,
    isRejecting: boolean,
    rejectReason: string,
    isShowTariffItemPanel: boolean,
    isShowTariffItemTierPanel: boolean,
    generalProposalItemTierList: GenProposalTariffItemTierEntity[],
    generalProposalItemIsList: GeneralProposalIsEntity[],
    tariffItemTierSelectedRows: GenProposalTariffItemTierEntity[],
    tariffItemIsSelectedRows: GeneralProposalIsEntity[],
    currentTariffItemTier: GenProposalTariffItemTierEntity | null,
    currentEditTariffItemTier: GenProposalTariffItemTierEntity | null,
    currentTariffItemIs: GeneralProposalIsEntity | null,
    currentEditTariffItemIs: GeneralProposalIsEntity | null,
    isAddTier: boolean,
    isEditTier: boolean,
    isAddIs: boolean,
    isEditIs: boolean,
    isRowUpdate: boolean,
    isEditingProposal: boolean,
    isShowEditPanel: boolean,
    forceRefresh: boolean,

    [key: string]: any
}

export const EMPTY_GENERAL_PROPOSAL_MODEL: GeneralProposalModel = {
    isLoading: false,
    isShowEditPanel: false,
    isShowDetail: false,
    isShowCriteriaPanel: false,
    generalProposalList: [],
    generalProposalAllList: [],
    isRejected: false,
    isRejectedCount: 0,
    isPendingApproval: false,
    isPendingApprovalCount: 0,
    // isAll: false,
    // isAllCount: 0,
    currentSelectedRow: { ...EMPTY_GENERAL_PROPOSAL_ENTITY },
    selectedRows: [],
    searchCriteria: { ...EMPTY_GENERAL_PROPOSAL_SEARCH_CRITERIA },
    dynamicOptions: {
        statusDropdownOptions: [],
        tariffTypeDropdownOptions: [],
        tariffCodeDropdownOptions: {},
        forwarderCodeDropdownOptions: [],
        currencyCodeDropdownOptions: [],
        tariffNatureDropdownOptions: [],
        shiftCodeDropdownOptions: []
    },
    isAllowAutoSearch: true,
    isBackFromDetail: false,
    searchStatus: '',
    isShowRejectPad: false,
    isRejecting: false,
    rejectReason: '',
    isShowTariffItemPanel: false,
    isShowTariffItemTierPanel: false,
    gnProposalState: {
        isSliderOpen: false,
        isTabularDataActive: false,
        isAdd: false,
        isRead: true,
        isEditable: false,
        isSaveClicked: false,
        lastEditRowId: "",
        isSelected: false,
        slider: {
            isAdd: false,
            isRead: false,
            isEditable: false
        }
    },
    generalProposalItemTierList: [],
    currentTariffItemTier: { ...EMPTY_GEN_PROPOSAL_TARIFF_ITEM_TIER_ENTITY },
    currentTariffItemIs: null,
    isAddTier: false,
    isEditTier: false,
    isAddIs: false,
    isEditIs: false,
    tariffItemTierSelectedRows: [],
    generalProposalItemIsList: [],
    tariffItemIsSelectedRows: [],
    currentEditTariffItemIs: { ...EMPTY_GEN_PROPOSAL_IS_ENTITY },
    currentEditTariffItemTier: { ...EMPTY_GEN_PROPOSAL_TARIFF_ITEM_TIER_ENTITY },
    isEditingProposal: false,
    isRowUpdate: false,
    forceRefresh: false
}