import { SelectionChangedEvent } from "ag-grid-community";
import { UserEntity } from "domain/entity/User/UserEntity";
import { MessageConstant } from "presentation/constant/MessageConstant";
import { INITIAL_USER_COL_DEF, transferRowData } from "presentation/constant/User/UserColumnDefinition";
import { UserConStant } from "presentation/constant/User/UserConstant";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useUserVM } from "presentation/hook/User/useUserVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useUserTracked } from "presentation/store/User/UserProvider";
import { ConfirmDeleteModal } from "presentation/view/components/ConfirmDeleteModal";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Loader } from "veronica-ui-component/dist/component/core";

const UserTablePanel: React.FC = () => {
    const [userState] = useUserTracked();
    const [anaInfoSate] = useANAInfoTracked();
    const messageBarVM = useMessageBarVM();
    const userVM = useUserVM();
    const USER_CONSTANT = UserConStant.user;
    const { isTabularDataActive, currentSelectedData, lastEditRowId } = userState.userState;
    const allFormState = userState.userState.allFormState;
    const [isShowDelete, setIsShowDelete] = useState<boolean>(false);
    const [deletedUser, setDeletedUser] = useState<UserEntity>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    let gridRef: any = useRef();

    useEffect(() => {
        if (!onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();        
    }, [onTableSelectionClicked])

    useEffect(() => {        
        if (!onTableSelectionClicked) {
            gridRef.current?.gridRef.current.api?.deselectAll();
        }
    })

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        //if (selectedRows && selectedRows.length > 0) {
            userVM.onSelectedUsers(selectedRows);
        //}
    }, [userVM])

    const handleAddClick = useCallback(() => {
        userVM.onUserAddClick();
    }, [userVM])

    const handleRowDoubleClick = useCallback((user: UserEntity) => {
        userVM.onUserRowDoubleClick(user);
    }, [userVM])

    const handleRowDeleteClick = useCallback(async (user: UserEntity) => {
        setIsShowDelete(true);
        setDeletedUser(user);
    }, []);

    const handleCancel = () => {
        setIsLoading(true);
        setIsShowDelete(false);
        userVM.loadAllUsers().then(data => {
            setIsLoading(false);
        });
    }

    const handleDelete = () => {
        setIsLoading(true);
        setIsShowDelete(false);
        if (!deletedUser) return;

        userVM.onUserRowDeleteClick(deletedUser).then((data) => {
            if (allFormState && allFormState["DeleteUserFail"]) {
                messageBarVM.showError(allFormState["DeleteUserFail"]?.toString());
            } else {
                messageBarVM.showSuccess(MessageConstant.common.DELETED_DATA_SUCCESSFUL)
                userVM.loadAllUsers().then(data => {
                    setIsLoading(false);
                });
            }
        }).catch((error) => {
            setIsLoading(false);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const isCopyBtnDisable = useCallback(() => {
        if (anaInfoSate.allowCreate && currentSelectedData && currentSelectedData.name) return false
        return true;
    },[anaInfoSate.allowCreate, currentSelectedData])

    const handleCopy = useCallback(() => {
        userVM.onUserRenewClick();
    },[userVM])    
    
    const memoTableBtns = useMemo(() => {
        const tableBtns = [];

        tableBtns.push({
            id: 'onCopyButton',
            icon: 'Icon-copy',
            title: 'Copy User',
            disable: isCopyBtnDisable
        })
        return tableBtns
    }, [isCopyBtnDisable])

    const memoUserTable = useMemo(() => {

        return <NbisTable
            id='user-table'
            columns={INITIAL_USER_COL_DEF.slice()}
            headerLabel={USER_CONSTANT.USER}
            headerActionButtons={memoTableBtns}
            onAddClick={handleAddClick}
            onSelectionChanged={handleSelectionChange}
            onRowDoubleClick={(e: any) => handleRowDoubleClick(e.data)}
            data={transferRowData(userState.users.map((user: UserEntity) => ({
                ...user,
                flag_edit: user.name === lastEditRowId,
            })))}
            showPaginator={false}
            editable={false}
            showAddIcon={anaInfoSate.allowCreate}
            showDeleteButton={anaInfoSate.allowDelete}
            showActionColumn={true}
            showReloadIcon={false}
            selectionMode={true}
            isNewColumnSetting={true}
            isScrollHighlighted={true}
            isRowHighligted={true}
            onDelete={(deleteUser: UserEntity) => handleRowDeleteClick(deleteUser)}
            onCopyButton={handleCopy}
            rowSelection={"single"}
            gridHeight="customHeight"
            customHeight="calc(100vh - 72px)" />;
    }, [USER_CONSTANT.USER, memoTableBtns, handleAddClick, handleSelectionChange, userState.users, anaInfoSate.allowCreate, anaInfoSate.allowDelete, handleCopy, handleRowDoubleClick, lastEditRowId, handleRowDeleteClick])

    useEffect(() => {
        userVM.onShowLoading();
        const initialScreen = async () => {
            try {
                const results = await Promise.allSettled([
                    userVM.loadAllUsers(),
                    userVM.loadDropdownOptions()
                ]);
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {

                    } else if (index === 1 && result.status === 'rejected') {

                    }
                })
            } catch (error) {
            }
        }
        initialScreen().then((data) => {
            userVM.onHideLoading();
        }).catch(error => {
            userVM.onHideLoading();
        });
    }, [userVM])

    if (userState.isLoading) return <Loader Indicator="Stripe" size="Large" />;

    return <>{isTabularDataActive && <TableWrapper>{memoUserTable}</TableWrapper>}
        {isLoading && <Loader Indicator="Spinner" size="Medium" />}
        <ConfirmDeleteModal headerText={`${WorkspaceConstant.Common.BUTTON_DELETE} ${UserConStant.user.USER}`}
            contentText={`${MessageConstant.common.DELETE_ALERT_TITLE}\n${MessageConstant.common.DELETE_ALERT_MESSAGE}`}
            visible={isShowDelete}
            onCancelClick={handleCancel}
            onDeleteClick={handleDelete}
        />
    </>;
}

export default UserTablePanel;