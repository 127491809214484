
import { ChargeConstantCollection } from "constants/charge/ChargeConstantCollection";
import { ChargeConstant } from "presentation/constant/ChargeHeader/ChargeConstant";
import { MessageConstant } from "presentation/constant/MessageConstant";
import { NonOpsConstant } from "presentation/constant/NonOps/NonOpsConstant";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useNonOpsDetailVM } from "presentation/hook/NonOps/useNonOpsDetailVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useNonOpsDetailTracked } from "presentation/store/NonOps/NonOpsDetailProvider";
import { ConfirmDeleteModal } from "presentation/view/components/ConfirmDeleteModal";
import { HeaderWithBackButton } from "presentation/view/components/HeaderWithBackButton/HeaderWithBackButton";
import { memo, useCallback, useState } from "react";
import { HPHBreadcrumb, HPHButton, Loader } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const NonOpsDetailTitleBar: React.FC = () => {
    const ADJ_TYPE_CONST = ChargeConstantCollection.adjType;
    const [nonOpsDtlState] = useNonOpsDetailTracked();
    const nonOpsDtlVM = useNonOpsDetailVM();
    const messageBarVM = useMessageBarVM();

    const [isLoading, setIsLoading] = useState(false);

    const currentRows = nonOpsDtlState.selectedManualChargeDetailRows;
    const selectedRowsCount = currentRows.length;
    const { isRead } = nonOpsDtlState.viewState;

    const manHdr = nonOpsDtlState.currentManualChargeHeader;
    const tableData = nonOpsDtlState.manualChargeDetails;

    const [anainfoState] = useANAInfoTracked();
    const { allowUpdate, allowDelete } = anainfoState;
    const [isShowDelete, setIsShowDelete] = useState<boolean>(false);

    let applyBtnEnabled = false;
    let adjBtnEnabled = false;
    let delBtnEnabled = false;

    if ((manHdr.hdrState !== ChargeConstantCollection.manHdrState.NIL && manHdr.hdrState !== ChargeConstantCollection.manHdrState.STD)) {
        applyBtnEnabled = false;
        delBtnEnabled = false;
    } else {
        applyBtnEnabled = true;
        if (selectedRowsCount <= 0) {
            delBtnEnabled = false;
        } else {
            delBtnEnabled = true;
        }
    }

    if (tableData && tableData.length > 0 && selectedRowsCount > 0) {
        const adjType = tableData[0].adjType;
        if (adjType !== ADJ_TYPE_CONST.NORMAL) {
            applyBtnEnabled = false;
            delBtnEnabled = false;
        }

        if (adjType === ADJ_TYPE_CONST.CANCEL_REBILL) {
            applyBtnEnabled = true;
        } else if (adjType === ADJ_TYPE_CONST.ADJUSTMENT) {
            applyBtnEnabled = true;
        } else if (adjType === ADJ_TYPE_CONST.CANCEL) {
            applyBtnEnabled = true;
        } else if (adjType === ADJ_TYPE_CONST.CANCEL) {
            delBtnEnabled = false;
        }
    }

    if (manHdr.hdrState === ChargeConstantCollection.hdrState.INV && selectedRowsCount > 0) {
        adjBtnEnabled = true;
    }

    //const handleClose = () => {
    //    nonOpsDtlVM.onCloseScreen();
    //}

    // const handleEditClick = () => {

    //     if(selectedRowsCount === 1){
    //         nonOpsDtlVM.onSelectedSingleChargeDetail(currentRows[0])
    //         nonOpsDtlVM.onEdit(manHdr); 
    //     }else{
    //         messageBarVM.showError('Please select a record!');  
    //     }    
    // }

    // const handleAddClick = () => {
    //     nonOpsDtlVM.onAdd(manHdr);       
    // }

    const handleApplyClick = () => {
        if (tableData.length > 0) {
            setIsLoading(true);
            nonOpsDtlVM.onApply(manHdr, nonOpsDtlState.manualChargeDetails).then(() => {
                manHdr.id && nonOpsDtlVM.onSearch(manHdr.id).then(() => {
                    setIsLoading(false);
                }).catch((error) => {
                    setIsLoading(false)
                })
            }).catch((error) => {
                setIsLoading(false)
                messageBarVM.showError(error.message)
            })
        } else {
            messageBarVM.showError('No details under this header,Please input 1 detail at least.')
        }
    }

    const handleAdjustmentClick = () => {
        if (selectedRowsCount <= 0) {
            messageBarVM.showError('Please select a record(s) to delete.');
            return;
        }
        nonOpsDtlVM.handleAdjustmentButtonClick();
    }


    const handleDelete = () => {
        if (selectedRowsCount <= 0) {
            messageBarVM.showError('Please select a record(s) to delete.');
            return;
        }

        if (tableData.length === 1) {
            messageBarVM.showError('There is only one Non Ops charge detail, it is not allowed to delete.');
            return;
        }

        if (tableData.length === selectedRowsCount) {
            messageBarVM.showError('Cannot delete all Non Ops charge detail.')
            return;
        }

        // show confirm
        setIsShowDelete(false);
        setIsLoading(true);
        nonOpsDtlVM.onDelete(currentRows).then(() => {
            manHdr.id && nonOpsDtlVM.onSearch(manHdr.id).then(() => {
                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false)
            })
        }).catch((error) => {
            setIsLoading(false)
            messageBarVM.showError(error.message)
        })

    }

    const handleDeleteClick = useCallback(() => {
        setIsShowDelete(true);
    }, []);

    const handleCancel = () => {
        setIsShowDelete(false);
    }

    return <Sidebarheader style={{ width: '100%' }}>
        <HeaderWithBackButton callback={nonOpsDtlVM.onCloseScreen}>
            <Breadcrumb>
                <HPHBreadcrumb breadcrumbData={[{ title: ChargeConstant.Charge.CHARGE }, { title: NonOpsConstant.Header.NON_OPS_STD_BILL }]} onCurrentClick={() => { }} ></HPHBreadcrumb>
            </Breadcrumb>
        </HeaderWithBackButton>
        <StyledAction className="tm-animated-wrapper">
            {
                <>
                    {/* <IconButton fileName='Icon-add' tooltipDisable={false} toolTipText='Add' size="medium" disabled={!addBtnEnabled} onClick={handleAddClick}/> 
                    <IconButton fileName='Icon-pen' tooltipDisable={false} toolTipText='Edit' size="medium" disabled={!editBtnEnabled} onClick={handleEditClick}/>
                    <div className="add-seperator"/>*/}
                    {(allowUpdate) && <HPHButton label={WorkspaceConstant.Common.BUTTON_ADJUSTMENT} size={'Small'} theme={'Secondary'} disabled={!adjBtnEnabled} onClick={handleAdjustmentClick} />}
                    {(allowDelete) && <HPHButton label={WorkspaceConstant.Common.BUTTON_DELETE} size={'Small'} theme={'Secondary'} disabled={!delBtnEnabled || !isRead} onClick={handleDeleteClick} />}
                    {(allowUpdate) && <HPHButton label={WorkspaceConstant.Common.BUTTON_UPDATEALL} size={'Small'} theme={'Primary'} disabled={!applyBtnEnabled || !isRead} onClick={handleApplyClick} />}
                </>
            }
            {/* {!(localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_NON_OPS_DETAIL_FROM_CHARGE_ITEM_SEARCH)) && 
             <IconButton fileName="Icon-cross" toolTipText="Close" tooltipDisable={false} size="medium" onClick={handleClose} />} */}

            {isShowDelete && <ConfirmDeleteModal headerText={`${WorkspaceConstant.Common.BUTTON_DELETE} ${NonOpsConstant.Detail.NON_OPS_DTL}`}
                contentText={`${MessageConstant.common.DELETE_ALERT_TITLE}\n${MessageConstant.common.DELETE_ALERT_MESSAGE}`}
                visible={isShowDelete}
                onCancelClick={handleCancel}
                onDeleteClick={handleDelete}
            />}
        </StyledAction>

        {isLoading && <Loader Indicator="Spinner" size="Medium" />}
    </Sidebarheader>
}

export default memo(NonOpsDetailTitleBar);