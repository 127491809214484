import { CustomCellRendererProps } from "ag-grid-react";
import { DECIMAL_2 } from "presentation/utils/numberUtil";
import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { GeneralProposalConstant } from "./GeneralProposalConstant";

const GENERAL_PROPOSAL_CONSTANT = GeneralProposalConstant.Table;
let dateFieldList: string[] = [];
let dateTimeFieldList: string[] = [];
let numberFieldList: string[] = [];
export const INITIAL_GENERAL_PROPOSAL_TARIFF_ITEM_IS_COL_DEF: any[] = [
    {
        headerName: GENERAL_PROPOSAL_CONSTANT.SHIFT_CODE,
        field: 'shiftCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        // pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        filter: true,
        width: 180,
    },
    {
        headerName: GENERAL_PROPOSAL_CONSTANT.SURCHARGE_P,
        field: 'percentage',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
    },

].map((col, index) => {
    const cellRenderers: { [key: string]: ((params: CustomCellRendererProps) => {}) } = {};

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers, numberFieldList);
});

export const transferRowData = (data: any[]) => {
    const externalFnctions: { [key: string]: ((fieldName: string, row: any) => {}) } = {};

    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions, numberFieldList);
}
