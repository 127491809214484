import { SelectionChangedEvent } from "ag-grid-community";
import { DocumentPreviewConstant } from "presentation/constant/DocumentPreview/DocumentPreviewConstant";
import { INITIAL_DOCUMENT_PREVIEW_MOVE_TO_COL_DEF, transferRowData } from "presentation/constant/DocumentPreview/DocumentPreviewMoveToColumnDefinition";
import { useDocumentPreviewDetailVM } from "presentation/hook/DocumentPreview/useDocumentPreviewDetailVM";
import { useDocumentPreviewDetailTracked } from "presentation/store/DocumentPreview/DocumentPreviewDetailProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";


const DocumentPreviewDraftInvoiceListTable: React.FC = () => {
    const gridRef: any = useRef(null);
    const documentPreviewDetailVM = useDocumentPreviewDetailVM();
    const [documentPreviewDetailState] = useDocumentPreviewDetailTracked();
    const DOCUMENT_PREVIEW_CONSTANT = DocumentPreviewConstant.Detail;
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);

    useEffect(() => {
        (INITIAL_DOCUMENT_PREVIEW_MOVE_TO_COL_DEF.slice());

        //gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
        if (!onTableSelectionClicked) {
            gridRef.current?.gridRef.current.api?.deselectAll();
        }
    })

    useEffect(() => {
        if (!onTableSelectionClicked) return;

        gridRef?.current?.gridRef.current.api?.deselectAll();
    }, [onTableSelectionClicked])


    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        documentPreviewDetailVM.updateSelectedMoveRows(selectedRows);
    }, [documentPreviewDetailVM]);

    const memoDocumentPreviewDraftInvoiceListTable = useMemo(() => {
        return (
            <NbisTable
                id='change-key-data-charge-info-table'
                isNewColumnSetting={true}
                columns={INITIAL_DOCUMENT_PREVIEW_MOVE_TO_COL_DEF.slice()}
                headerLabel={DOCUMENT_PREVIEW_CONSTANT.MOVE_TO_ITEM_LIST}
                data={transferRowData(documentPreviewDetailState.moveToItemList) || []}
                showPaginator={false}
                editable={false}
                showAddIcon={false}
                showDeleteButton={false}
                showReloadIcon={false}
                isScrollHighlighted={true}
                isRowHighligted={true}
                selectionMode={false}
                rowSelection={"single"}
                showActionButtons={true}
                onSelectionChanged={handleSelectionChange}
                gridHeight="customHeight"
                customHeight="calc(100vh - 275px)"
                ref={gridRef}
            />
        );
    }, [DOCUMENT_PREVIEW_CONSTANT.MOVE_TO_ITEM_LIST, documentPreviewDetailState.moveToItemList, handleSelectionChange])

    return <><TableWrapper>{memoDocumentPreviewDraftInvoiceListTable}</TableWrapper></>;
}

export default memo(DocumentPreviewDraftInvoiceListTable);
