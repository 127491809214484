export const RequisitionFormConstant = {
    TITLE: "Requisition Form",
    COMMON: {
        STATE: "State",
        REQ_NO: "Req. No.",
        BILL_TO_COMPANY: "Bill To Company",
        CUSTOMER_CODE: "Customer Code",
        REQ_MODE: "Req Mode",
        CURRENCY: "Currency",
        REQ_NATURE: "Req. Nature",
        SHORT_DESC: "Short Desc",
        REVENUE_CTRL_IND: "Revenue Ctrl Ind.",
        FIN: "FIN",
        COM: "COM",
        MD: "MD",
        GMD: "GMD",
        REQ_NO_FROM: "Req. No. From",
        REQ_NO_TO: "Req. No. to",
    },
    Header: {
        SEARCH_CRITERIA: "Search Criteria",
        AMOUNT: "Amount",        
        CREATED_BY: "Created By",
        CREATED_DATE_TIME: "Created Date Time",        
        MANAGER_IC: "Manager-IC",
        AC_MGR: "A/C Mgr",        
        REF_REMARKS: "Ref. Remarks"
    }, 
    Detail: {
        TOTAL_AMOUNT: "Total Amount",
        EXPLANATION_1: "Explanation 1",
        EXPLANATION_2: "Explanation 2",
        EXPLANATION_3: "Explanation 3",
        EXPLANATION_4: "Explanation 4",
        EXPLANATION_5: "Explanation 5",
        EXPLANATION_6: "Explanation 6",
        REFERENCE: "Reference",
        SUPPORTING: "Supporting",
        PROBLEM_LOG_NO: "Problem Log No.",
        REMARKS: "Remarks",
        REUISITION_FORM_CUST: "Requisition Form Customer List",
        MANAGER_APPROVAL: "Manager Approval",
        MANAGER_IN_CHARGE:"Manager-In-Charge",
        ACCOUNT_MANAGER: "Account Manager",
        CONFIRM_DATE_TIME: "Confirmed Date/Time"
    },
    Print: {
        TITLE: "Print Requisition Form",
        WITH_CRE_CHG_CHECK_LIST: "With Credit Charge CheckList",
        CREDIT_CHARGE_NO_FROM: "Credit Charge No.(Fr)",
        CREDIT_CHARGE_NO_TO: "Credit Charge No.(To)",
        PRINTER: "Printer"
    }
}

export const reqForm2dpNumberFieldList: string[] = [
    'amount'    
];

export const reqForm4dpNumberFieldList: string[] = [
];

export const reqFormPositiveIntegerNumberFieldList: string[] = [
];