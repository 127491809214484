import { CompanyConstant } from "presentation/constant/Company/CompanyConstant";
import { ActiveIndDroOpts } from "presentation/constant/DropDownOptions/Common/ActiveIndDroOpts";
import { useCompanyMaintenanceVM } from "presentation/hook/Company/useCompanyMaintenanceVM";

import { useCompanyMaintenanceTracked } from "presentation/store/Company/CompanyMaintenanceProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import NbisInputDropDown from "presentation/view/components/NbisInputDropDown";
import { memo, useCallback, useMemo } from "react";
import { InputField } from "veronica-ui-component/dist/component/core";

const CompanySearchForm = () => {
    const [companyState] = useCompanyMaintenanceTracked();
    const companyMaintenanceVM = useCompanyMaintenanceVM();
    const COMPANY_CONST = CompanyConstant.Header;
    const companySearchCriteria = companyState.searchCriteria;

    const onInputTextChange = useCallback((e: any, fieldName: string) => {
        const fieldValue = e.target.value;
        companyMaintenanceVM.onInputTextChange(fieldName, fieldValue);
    }, [companyMaintenanceVM]);

    const memoCompanyCode = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputField
                width='150px'
                maxLength={4}
                label={COMPANY_CONST.COMPANY_CODE}
                type="text"
                value={companySearchCriteria.companyCode ?? ''}
                onChange={(e: any) => onInputTextChange(e, 'companyCode')} />
        </div>
        , [COMPANY_CONST.COMPANY_CODE, companySearchCriteria.companyCode, onInputTextChange])

    const memoCompanyName = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputField
                width='150px'
                maxLength={50}
                label={COMPANY_CONST.COMPANY_NAME}
                type="text"
                value={companySearchCriteria.companyName ?? ''}
                onChange={(e: any) => onInputTextChange(e, 'companyName')} />
        </div>
        , [COMPANY_CONST.COMPANY_NAME, companySearchCriteria.companyName, onInputTextChange])

    const memoActiveInd = useMemo(() =>
        <div className='im-flex-row-item'>
            <NbisInputDropDown
                label={COMPANY_CONST.ACTIVE_IND}
                width='150px'
                inputType="freeText"
                mode={'single'}
                sort={false}
                value={companySearchCriteria.activeInd ?? ''}
                onChange={(e: any) => companyMaintenanceVM.onDropdownChange(e, 'activeInd')}
                options={ActiveIndDroOpts().getDroOptsModel()} />
        </div>
        , [COMPANY_CONST.ACTIVE_IND, companySearchCriteria.activeInd, companyMaintenanceVM])

    return <>

        <CriteriaItemContainer>
            {companyState.enabledSearchCriteria.companyCode ? memoCompanyCode : null}
            {companyState.enabledSearchCriteria.companyName ? memoCompanyName : null}
        </CriteriaItemContainer>

        <CriteriaItemContainer>
            {companyState.enabledSearchCriteria.activeInd ? memoActiveInd : null}
        </CriteriaItemContainer>

    </>;
}
export default memo(CompanySearchForm);