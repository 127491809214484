import { EMPTY_STANDARD_PROPOSAL_ENTITY, StandardProposalEntity } from "domain/entity/StandardProposal/StandardProposalEntity";
import { EMPTY_STANDARD_PROPOSAL_ITEM_ENTITY, StandardProposalItemEntity } from "domain/entity/StandardProposal/StandardProposalItemEntity";
import { EMPTY_STANDARD_PROPOSAL_SEARCH_CRITERIA, StandardProposalSearchCriteria } from "domain/entity/StandardProposal/StandardProposalSearchCriteria";
import { EMPTY_STD_PROPOSAL_COPY_TO_ENTITY, StdProposalCopyToEntity } from "domain/entity/StandardProposal/StdProposalCopyToEntity";
import { EMPTY_STD_PROPOSAL_COPY_TO_NEW_PROPOSAL_ENTITY, StdProposalCopyToNewProposalEntity } from "domain/entity/StandardProposal/StdProposalCopyToNewProposalEntity";
import { EMPTY_STD_PROPOSAL_COPY_TO_PROPOSAL_ENTITY, StdProposalCopyToProposalEntity } from "domain/entity/StandardProposal/StdProposalCopyToProposalEntity";
import { EMPTY_STD_PROPOSAL_IS_ENTITY, StdProposalIsEntity } from "domain/entity/StandardProposal/StdProposalIsEntity";
import { EMPTY_STD_PROPOSAL_TARIFF_ITEM_TIER_ENTITY, StdProposalTariffItemTierEntity } from "domain/entity/StandardProposal/StdProposalTariffItemTierEntity";
import { DEFAULT_ENABLED_STANDARD_PROPOSAL_SEARCH_CRITERIA, StandardProposalEnabledSearchCriteria } from "presentation/constant/StandardProposal/StandardProposalEnabledSearchCriteria";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";

export interface StandardProposalDropdownOptions {

    companyCodeDropdownOptions: DropdownProps[],
    operatingTmlDropdownOptions: DropdownProps[],
    statusDropdownOptions: DropdownProps[],
    tariffTypeDropdownOptions: DropdownProps[],
    tariffCodeDropdownOptions: DropdownProps[],
    // tariffCodeDropdownOptions: { [key: string]: DropdownProps[] },
    forwarderCodeDropdownOptions: DropdownProps[],
    currencyCodeDropdownOptions: DropdownProps[],
    tariffNatureDropdownOptions: DropdownProps[],
    shiftCodeDropdownOptions: DropdownProps[],
    companyCodeProposalNoDropdownOptions: DropdownProps[],

}
export interface StandardProposalChangeState extends BaseViewChangeSate {
}

export interface StandardProposalModel {
    stdProposalState: StandardProposalChangeState,
    isLoading: boolean,
    isShowDetail: boolean,
    isShowCriteriaPanel: boolean,
    standardProposalList: StandardProposalEntity[],
    copyToStandardProposalList: StandardProposalEntity[],
    selectCopyToStdProposalRows: StandardProposalEntity[],
    standardProposalAllList: StandardProposalEntity[],
    currentSelectedRow: StandardProposalEntity,
    selectedRows: StandardProposalEntity[],
    isRejected: boolean,
    isRejectedCount: number,
    isPendingApproval: boolean,
    isPendingApprovalCount: number,
    // isAll: boolean,
    // isAllCount: number,
    searchCriteria: StandardProposalSearchCriteria,
    dynamicOptions: StandardProposalDropdownOptions,
    enabledSearchCriteria: StandardProposalEnabledSearchCriteria,
    isAllowAutoSearch: boolean,
    isBackFromDetail: boolean,
    searchStatus: string,
    isShowRejectPad: boolean,
    isRejecting: boolean,
    rejectReason: string,
    isShowTariffItemPanel: boolean,
    isShowTariffItemTierPanel: boolean,
    standardProposalItemList: StandardProposalItemEntity[],
    deleteItemList: StandardProposalItemEntity[],
    standardProposalItemTIerList: StandardProposalItemEntity[],
    tariffItemSelectedRows: StandardProposalItemEntity[],
    currentTariffItem: StandardProposalItemEntity,
    currentTariffItemTier: StdProposalTariffItemTierEntity,
    tariffItemTierSelectedRows: StdProposalTariffItemTierEntity[],
    currentEditTariffItemTier: StdProposalTariffItemTierEntity,
    currentTariffItemIs: StdProposalIsEntity,
    tariffItemIsSelectedRows: StdProposalIsEntity[],
    currentEditTariffItemIs: StdProposalIsEntity,
    isAddTier: boolean,
    isEditTier: boolean,
    isAddIs: boolean,
    isEditIs: boolean,
    isAddTariffItem: boolean,
    isEditStdProposal: boolean,
    isUpdateOrAdd: boolean,
    isShowCopyPanel: boolean,
    isCopyToNewProposal: boolean,
    currentSelectedRowKey: string,
    tariffCodeIds: string[],
    copyToCompanyList: string[],
    chargePercentage: number,
    roundMethod: string,
    decimalPlace: string,
    copyToPercentageList: StdProposalCopyToEntity[],
    copyToPercentage: StdProposalCopyToEntity,
    copyToEditPercentage: StdProposalCopyToEntity,
    copyToProposalList: StdProposalCopyToProposalEntity[],
    copyToNewProposalList: StdProposalCopyToNewProposalEntity[],
    copyToEditNewProposal: StdProposalCopyToNewProposalEntity,
    copyToEditProposalNo: StdProposalCopyToProposalEntity,
    copyToProposalNo: StdProposalCopyToProposalEntity,
    isEditProposalNo: boolean,
    isEditNewProposalNo: boolean,
    isEditPercentage: boolean,
    forceRefresh: boolean,
}

export const EMPTY_STANDARD_PROPOSAL_MODEL: StandardProposalModel = {
    isLoading: false,
    isShowDetail: false,
    isShowCriteriaPanel: false,
    standardProposalList: [],
    standardProposalAllList: [],
    isRejected: false,
    isRejectedCount: 0,
    isPendingApproval: false,
    isPendingApprovalCount: 0,
    // isAll: false,
    // isAllCount: 0,
    currentSelectedRow: { ...EMPTY_STANDARD_PROPOSAL_ENTITY },
    selectedRows: [],
    searchCriteria: { ...EMPTY_STANDARD_PROPOSAL_SEARCH_CRITERIA },
    dynamicOptions: {
        companyCodeDropdownOptions: [],
        operatingTmlDropdownOptions: [],
        statusDropdownOptions: [],
        tariffTypeDropdownOptions: [],
        // tariffCodeDropdownOptions: {},
        tariffCodeDropdownOptions: [],
        forwarderCodeDropdownOptions: [],
        currencyCodeDropdownOptions: [],
        tariffNatureDropdownOptions: [],
        shiftCodeDropdownOptions: [],
        companyCodeProposalNoDropdownOptions: []
    },
    enabledSearchCriteria: { ...DEFAULT_ENABLED_STANDARD_PROPOSAL_SEARCH_CRITERIA },
    isAllowAutoSearch: true,
    isBackFromDetail: false,
    searchStatus: '',
    isShowRejectPad: false,
    isRejecting: false,
    rejectReason: '',
    isShowTariffItemPanel: false,
    isShowTariffItemTierPanel: false,
    standardProposalItemList: [],
    tariffItemSelectedRows: [],
    currentTariffItem: { ...EMPTY_STANDARD_PROPOSAL_ITEM_ENTITY },
    stdProposalState: {
        isSliderOpen: false,
        isTabularDataActive: false,
        isAdd: false,
        isRead: true,
        isEditable: false,
        isSaveClicked: false,
        lastEditRowId: "",
        isSelected: false,
        slider: {
            isAdd: false,
            isRead: false,
            isEditable: false
        }
    },
    standardProposalItemTIerList: [],
    currentTariffItemTier: { ...EMPTY_STD_PROPOSAL_TARIFF_ITEM_TIER_ENTITY },
    currentEditTariffItemTier: { ...EMPTY_STD_PROPOSAL_TARIFF_ITEM_TIER_ENTITY },
    currentTariffItemIs: { ...EMPTY_STD_PROPOSAL_IS_ENTITY },
    currentEditTariffItemIs: { ...EMPTY_STD_PROPOSAL_IS_ENTITY },
    isAddTier: false,
    isEditTier: false,
    isAddIs: false,
    isEditIs: false,
    isEditStdProposal: false,
    isAddTariffItem: false,
    isUpdateOrAdd: false,
    currentSelectedRowKey: '',
    tariffCodeIds: [],
    tariffItemTierSelectedRows: [],
    tariffItemIsSelectedRows: [],
    deleteItemList: [],
    copyToStandardProposalList: [],
    isShowCopyPanel: false,
    isCopyToNewProposal: false,
    selectCopyToStdProposalRows: [],
    copyToCompanyList: [],
    chargePercentage: 100,
    roundMethod: "ROUND",
    decimalPlace: '2',
    copyToPercentageList: [],
    copyToProposalList: [],
    copyToPercentage: { ...EMPTY_STD_PROPOSAL_COPY_TO_ENTITY },
    copyToProposalNo: { ...EMPTY_STD_PROPOSAL_COPY_TO_PROPOSAL_ENTITY },
    isEditProposalNo: false,
    isEditPercentage: false,
    copyToEditPercentage: { ...EMPTY_STD_PROPOSAL_COPY_TO_ENTITY },
    copyToEditProposalNo: { ...EMPTY_STD_PROPOSAL_COPY_TO_PROPOSAL_ENTITY },
    copyToNewProposalList: [],
    copyToEditNewProposal: { ...EMPTY_STD_PROPOSAL_COPY_TO_NEW_PROPOSAL_ENTITY },
    isEditNewProposalNo: false,
    forceRefresh: false
}