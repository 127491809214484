import { CustomCellRendererProps } from "ag-grid-react";
import { ChargeConfirmedEntity } from "domain/entity/ChargeConfirmed/ChargeConfirmedEntity";
import { DynamicTableEntity } from "domain/entity/Common/DynamicTableEntity";
import { ChargeConfirmedRepository } from "domain/repository/ChargeConfirmed/ChargeConfirmedRepo";
import { DynamicTableRepository } from "domain/repository/Common/DynamicTableRepo";
import _ from "lodash";
import { DynamicColumnNameMapping, getChargeConfirmedValue } from "presentation/constant/Common/DynamicColumnNameMapping";
import { ChargeConfirmedModel } from "presentation/model/ChargeConfirmed/ChargeConfirmedModel";
import { defineColumn } from "presentation/view/components/TableWrapper/BasicTableConstants";
import BaseViewModel from "presentation/viewModel/BaseViewModel";
import { Dispatch, SetStateAction } from "react";

interface ChargeConfirmedVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<ChargeConfirmedModel>> | ((value: SetStateAction<ChargeConfirmedModel>) => void),
    ]
    dynamicTableRepo: DynamicTableRepository,
    chargeConfirmedRepo: ChargeConfirmedRepository,
}

export const ChargeConfirmedVM = ({ dispatch, dynamicTableRepo, chargeConfirmedRepo }: ChargeConfirmedVMProps) => {
    const [chargeConfirmedDispatch] = dispatch;

    const onTableInit = () => {
        dynamicTableRepo.getDynamicTableCols('DOC_GEN').then((cols) => {
            let dateFieldList: string[] = [];
            let dateTimeFieldList: string[] = [];
            cols.unshift({
                headerName: "No. of Charges",
                colIndex: 0,
                field: "dtlCount",
                enableRowGroup: false,
                enablePivot: false,
                enableValue: false,
                rowGroup: false,
                filter: true,
                width: 200,
                dataType: "",
                pinned: "left",
                checkboxSelection: true,
                headerCheckboxSelection: true,
            });
            let newCols = _.orderBy((cols).map((col): DynamicTableEntity => {
                return {
                    ...col,
                    headerName: getChargeConfirmedValue(col['headerName'] as keyof typeof DynamicColumnNameMapping.ChargeConfirmed) ?? '',
                    filter: true,
                }
            }), ['colIndex']).map((col, index) => {
                const cellRenderers: { [key: string]: ((params: CustomCellRendererProps) => {}) } = {};

                return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
            });

            chargeConfirmedDispatch(prevState => {
                return {
                    ...prevState,
                    dynamicTableColumns: newCols,
                }
            })
        })
    }

    const onShowLoading = () => {
        chargeConfirmedDispatch(prevState => {
            return {
                ...prevState,
                isLoading: true,
            }
        })
    }

    const onHideLoading = () => {
        chargeConfirmedDispatch(prevState => {
            return {
                ...prevState,
                isLoading: false,
            }
        })
    }

    const updateSelectedRows = (rows: any[]) => {
        chargeConfirmedDispatch(prevState => {
            return {
                ...prevState,
                selectedRows: rows,
                isDeselectAll: false,
                forceRefresh: !prevState.forceRefresh
            }
        })
    }

    const onSearch = async (userName?: string) => {
        // DomUtils.addWithSearchClass();
        chargeConfirmedDispatch(prevState => {
            return {
                ...prevState,
                chargeConfirmedList: [],
                searchCounter: prevState.searchCounter + 1,
                isShowRightPanel: false,
                selectedRows: [],
                // isShowAll: false,
                detailState: {
                    ...prevState.detailState,
                    details: []
                }
            }
        });

        if (userName) {
            return await chargeConfirmedRepo.getChargeConfrimedListByUser(userName).then((data) => {
                chargeConfirmedDispatch(prevState => {
                    return {
                        ...prevState,
                        chargeConfirmedList: data,
                        searchCounter: prevState.searchCounter + 1,
                        isShowRightPanel: false,
                        selectedRows: [],
                        // isShowAll: false,
                        detailState: {
                            ...prevState.detailState,
                            details: []
                        }
                    }
                })
            })
        } else {
            return await chargeConfirmedRepo.getAllChargeConfirmedList().then((data) => {
                chargeConfirmedDispatch(prevState => {
                    return {
                        ...prevState,
                        chargeConfirmedList: data,
                        searchCounter: prevState.searchCounter + 1,
                        isShowRightPanel: false,
                        selectedRows: [],
                        // isShowAll: true,
                        detailState: {
                            ...prevState.detailState,
                            details: []
                        }
                    }
                })
            })
        }
    }

    const onRowClick = async (row: ChargeConfirmedEntity) => {
        chargeConfirmedDispatch(prevState => {
            return {
                ...prevState,
                currentRow: row,
                isShowRightPanel: true,
                detailState: {
                    ...prevState.detailState,
                    isFetching: true,
                    details: [],
                },
            }
        })
    }

    const onDetailSearch = async (row: ChargeConfirmedEntity) => {

        chargeConfirmedRepo.getChargeConfirmedListDetails(Array.of(row)).then((res) => {
            let data: any = [];
            if (res.success && !_.isEmpty(res.data)) {
                data = res.data;
            }
            chargeConfirmedDispatch(prevState => {
                return {
                    ...prevState,
                    detailState: {
                        ...prevState.detailState,
                        details: data,
                    }
                }
            })
        }).finally(() => {
            chargeConfirmedDispatch(prevState => {
                return {
                    ...prevState,
                    detailState: {
                        ...prevState.detailState,
                        isFetching: false,
                    }
                }
            })
        })
    }


    const onCloseDetail = () => {
        chargeConfirmedDispatch(prevState => {
            return {
                ...prevState,
                isShowRightPanel: false,
                selectedRows: [],
                detailState: {
                    ...prevState.detailState,
                    details: [],
                    isFetching: false,
                },
                isDeselectAll: true,
            }
        })
    }

    const onToggle = () => {
        chargeConfirmedDispatch(prevState => {
            return {
                ...prevState,
                isShowAll: !prevState.isShowAll,
                selectedRows: []
            }
        })
    }

    const onSumbit = async (rows: ChargeConfirmedEntity[]) => {
        return await chargeConfirmedRepo.submitToGenDraftInv(rows);
    }

    return {
        onTableInit: onTableInit,
        onShowLoading: onShowLoading,
        onHideLoading: onHideLoading,
        updateSelectedRows: updateSelectedRows,
        onSearch,
        onDetailSearch: onDetailSearch,
        onRowClick: onRowClick,
        onCloseDetail: onCloseDetail,
        onToggle: onToggle,
        onSumbit: onSumbit,
    }
}