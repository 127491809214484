import { useChargeDataSearchVM } from "presentation/hook/ChargeDataEnquiry/useChargeDataSearchVM";
import { useChargeDataSearchTracked } from "presentation/store/ChargeDataEnquiry/ChargeDataSearchProvider";
import NbisInputDropDown from "presentation/view/components/NbisInputDropDown";
import { useState } from "react";
import { DialogModal, DropdownOptions, HPHButton, IconButton } from "veronica-ui-component/dist/component/core";
import { IPreferenceModal } from "./SaveNewPreferenceModal";

export const DeletePreferenceModal: React.FC<IPreferenceModal> = (props: IPreferenceModal) => {
    const { visible } = props;
    const [chargeDataSearchState] = useChargeDataSearchTracked();
    const chargeDataSearchVM = useChargeDataSearchVM();
    const [preferenceKey, setPreferenceKey] = useState('');
    const userEmail = chargeDataSearchState.userEmail;

    const onConfirmClicked = () => {
        chargeDataSearchVM.showLoading();
        chargeDataSearchVM.deleteSearchPreference(userEmail, preferenceKey).then(data => {
            chargeDataSearchVM.loadAllSearchPreference(userEmail).then(data => {
                chargeDataSearchVM.hideLoading();
            }).catch(error => {
                chargeDataSearchVM.hideLoading();
            })
        }).catch(error => {
            chargeDataSearchVM.hideLoading();
        })
    }

    return <DialogModal
        appendTo='self'
        showButton={false}
        visible={visible}
        positions="center"
        resizable={false}
        header={
            <div className="im-charge-data-search-confirm-modal-header">
                <span className="name">DELETE PREFERENCE</span>
                <IconButton fileName='Icon-cross' size="medium" onClick={chargeDataSearchVM.closeConfirmModal} tooltipDisable={true} />
            </div>
        }
        dialogContent={
            <div className="im-charge-data-search-confirm-modal-content">
                <NbisInputDropDown label='Name' inputType="freeText" value={preferenceKey} onChange={(e: DropdownOptions) => setPreferenceKey(e?.value ?? '')}
                    options={chargeDataSearchState.searchPreferences.filter((searchPreference) => searchPreference.userEmail === userEmail).map(searchPreference => ({
                        dropdownLabel: searchPreference.preferenceName,
                        tagLabel: searchPreference.preferenceName,
                        value: searchPreference.preferenceName,
                    }))} />
            </div>
        }
        className="im-charge-data-search-confirm-modal"
        footer={
            <div className="im-charge-data-search-confirm-modal-footer">
                <HPHButton label={'Confirm'} size={'Small'} theme={'Primary'} onClick={onConfirmClicked} />
            </div>
        }
    />;
};
