import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { RecalProcessAndGenMaintenanceProvider } from "presentation/store/Recalculation/RecalProcessAndGenMaintenanceProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import { RecalProcessAndGenMasterView } from "presentation/view/section/Recalculation/RecalProcessAndGenMasterView";

import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const RecalProcessAndGenContMain = () => {
    return <ANAInfoWrapper permission={Permission.RECAL_PROCESSGEN}>
        <MessageBarWrapper>
            <RecalProcessAndGenMaintenanceProvider>
                <GridStyles/>
                <RecalProcessAndGenMasterView/>
            </RecalProcessAndGenMaintenanceProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default RecalProcessAndGenContMain;