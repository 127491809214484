export interface EnabledProposalEnquiryCriteria {
    all: boolean,    
    companyCode: boolean,	
	proposalNo: boolean,	
	operatingTml: boolean,
    effectiveDate: boolean,		
	tariffType: boolean,	
	tariffCode: boolean,	
	proposalStatus: boolean,	
	activeInd: boolean,	

    [key: string]: boolean,
}

export const DEFAULT_ENABLED_PROPOSAL_ENQUIRY_CRITERIA: EnabledProposalEnquiryCriteria = {
    all: false,
    companyCode: true,
    proposalNo: true,
    operatingTml: true,
    effectiveDate: true,
    tariffType: true,
    tariffCode: true,
    proposalStatus: true,
    activeInd: true
}