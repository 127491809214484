import { MonthEndStatusPolicy } from "constants/monthEnd/MonthEndStatusPolicy";
import { MonthEndClosureConstant } from "presentation/constant/MonthEndClosure/MonthEndClosureConstant";
import { useMonthEndClosureVM } from "presentation/hook/MonthEndClosure/useMonthEndClosureVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { MonthEndClosureModel } from "presentation/model/MonthEndClosure/MonthEndClosureModel";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DatePickerComponent from "presentation/view/components/DatePickerComponent";
import DateTimePickerComponent from "presentation/view/components/DateTimePickerComponent";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import NumberInputComponent from "presentation/view/components/NumberInputComponent";
import { memo, useCallback, useMemo } from "react";
import { FieldType, HPHCheckbox, IconButton, IFieldValue } from "veronica-ui-component/dist/component/core";
import { SidebarActionCross, Sidebarheader, SidebarTitle, StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const MonthEndClosureEditPanel = ({ monthEndClosureState }: { monthEndClosureState: MonthEndClosureModel }) => {
    const MONTH_END_CLOSURE_CONSTANT = MonthEndClosureConstant.Table;
    const messageBarVM = useMessageBarVM();
    const monthEndClosureVM = useMonthEndClosureVM();
    const { currentEditRow, isEdit, currentSelectedRow } = monthEndClosureState;
    const currentRow = isEdit ? currentEditRow : currentSelectedRow;


    const handleSave = useCallback(async () => {
        if (!currentEditRow.fromDate) {
            messageBarVM.showError("From Date is mandatory.");
            return;
        }
        if (!currentEditRow.toDate) {
            messageBarVM.showError("To Date is mandatory.");
            return;
        }
        monthEndClosureVM.onShowLoading();
        monthEndClosureVM.onSave(currentEditRow).then((data) => {
            if (data === "success") {
                messageBarVM.showSuccess("Update Successfully.");
                monthEndClosureVM.initMonthEndClosureList().then((data) => {
                    monthEndClosureVM.onHideLoading();
                }).catch(error => {
                    monthEndClosureVM.onHideLoading();
                });
            } else {
                messageBarVM.showError(data);
                monthEndClosureVM.onHideLoading();
            }
        })
    }, [currentEditRow, messageBarVM, monthEndClosureVM]);

    const handleReset = useCallback(() => {
        monthEndClosureVM.onResetClick();
    }, [monthEndClosureVM]);

    const handleEdit = useCallback(() => {
        monthEndClosureVM.onEditClick();
    }, [monthEndClosureVM]);

    const handClose = useCallback(() => {
        monthEndClosureVM.onCloseClick();
    }, [monthEndClosureVM]);

    const isFieldDisable = useCallback(() => {
        if ((currentRow.status === MonthEndStatusPolicy.STATUS_ERROR
            || currentRow.status === MonthEndStatusPolicy.STATUS_WIP
            || currentRow.status === MonthEndStatusPolicy.STATUS_NEW)
        ) return true;
        return false;
    }, [currentRow.status]);

    const onNumberFieldChange = useCallback((e: any, fieldName: string, fieldValue: any) => {
        monthEndClosureVM.onHeaderFieldChange(fieldName, fieldValue);
    }, [monthEndClosureVM]);

    const memoYear = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "220px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentRow?.year?.toString() ?? ""}
                fieldValue={currentRow?.year}
                fieldLabel={MONTH_END_CLOSURE_CONSTANT.YEAR}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'year'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    monthEndClosureVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue, fFullValue)} />
        </div>
        , [MONTH_END_CLOSURE_CONSTANT.YEAR, currentRow?.year, monthEndClosureVM])

    const memoMonth = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "220px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentRow?.month?.toString() ?? ""}
                fieldValue={currentRow?.month}
                fieldLabel={MONTH_END_CLOSURE_CONSTANT.MONTH}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'month'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    monthEndClosureVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue, fFullValue)} />
        </div>
        , [MONTH_END_CLOSURE_CONSTANT.MONTH, currentRow?.month, monthEndClosureVM])

    const memoFromDate = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "220px", marginBottom: "20px" }}>
            <DatePickerComponent
                disabled={!(isEdit && isFieldDisable())}
                optional={false}
                label={MONTH_END_CLOSURE_CONSTANT.FROM_DATE}
                width="220px"
                date={currentRow?.fromDate}
                fieldName="fromDate"
                errorMessage={""}
                onDateChange={monthEndClosureVM.onHeaderFieldChange} />
        </div>
        , [isEdit, MONTH_END_CLOSURE_CONSTANT.FROM_DATE, currentRow?.fromDate, isFieldDisable, monthEndClosureVM.onHeaderFieldChange])

    const memoToDate = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "220px", marginBottom: "20px" }}>
            <DatePickerComponent
                disabled={!(isEdit && isFieldDisable())}
                optional={false}
                label={MONTH_END_CLOSURE_CONSTANT.TO_DATE}
                width="220px"
                date={currentRow?.toDate}
                fieldName="toDate"
                errorMessage={""}
                onDateChange={monthEndClosureVM.onHeaderFieldChange} />
        </div>
        , [isEdit, MONTH_END_CLOSURE_CONSTANT.TO_DATE, currentRow?.toDate, isFieldDisable, monthEndClosureVM.onHeaderFieldChange])


    const memoStatus = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "220px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentRow?.status}
                fieldValue={currentRow?.status}
                fieldLabel={MONTH_END_CLOSURE_CONSTANT.STATUS}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'status'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    monthEndClosureVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue, fFullValue)} />
        </div>
        , [MONTH_END_CLOSURE_CONSTANT.STATUS, currentRow?.status, monthEndClosureVM])

    const memoTurnover = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "220px", marginBottom: "24px" }}>
            <NumberInputComponent
                label={MONTH_END_CLOSURE_CONSTANT.TURNOVER}
                maxLength={60}
                fieldName={"turnover"}
                value={currentRow.turnover ?? ''}
                errorMessage={''}
                disabled={true}
                decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue)}
            />
        </div>
        , [MONTH_END_CLOSURE_CONSTANT.TURNOVER, currentRow.turnover, onNumberFieldChange])

    const memoEstimation = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "220px", marginBottom: "24px" }}>
            <NumberInputComponent
                label={MONTH_END_CLOSURE_CONSTANT.ESTIMATION}
                maxLength={60}
                fieldName={"estimation"}
                value={currentRow.estimation ?? ''}
                errorMessage={''}
                disabled={true}
                decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue)}
            />
        </div>
        , [MONTH_END_CLOSURE_CONSTANT.ESTIMATION, currentRow.estimation, onNumberFieldChange])


    const memoUpdatedDateTime = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "220px", marginBottom: "20px" }}>
            <DateTimePickerComponent
                disabled={true}
                optional={true}
                label={MONTH_END_CLOSURE_CONSTANT.UPDATE_DATETIME}
                width="220px"
                date={currentRow?.updatedDateTime}
                fieldName="updatedDateTime"
                errorMessage={""}
                onDateChange={monthEndClosureVM.onHeaderFieldChange} />
        </div>
        , [MONTH_END_CLOSURE_CONSTANT.UPDATE_DATETIME, currentRow?.updatedDateTime, monthEndClosureVM.onHeaderFieldChange])

    const memoActiveInd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "220px", marginBottom: "24px" }}>
            <NbisCommonField
                fieldKey={"activeInd"}
                requiredFieldList={[]}
                fieldLabel={MONTH_END_CLOSURE_CONSTANT.ACTIVE_IND}
                fieldType={FieldType.TEXT}
                isReadOnly={true}
                isShowOptional={false}
                onFieldChange={() => { }} />
            <HPHCheckbox
                label={""}
                checked={currentRow?.activeInd === 'Y'}
                disabled={!(isEdit && isFieldDisable())}
                onChange={(e) => monthEndClosureVM.onCheckboxChange(e.checked, "activeInd")}
            />
        </div>
        , [isEdit, MONTH_END_CLOSURE_CONSTANT.ACTIVE_IND, currentRow?.activeInd, isFieldDisable, monthEndClosureVM])

    return <>
        <div className='side-form-content-left-wrapper'>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <StyledSidebar style={{ width: '100%', height: "100%", border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'>
                        <Sidebarheader style={{ width: '100%' }}>
                            <SidebarTitle>
                                {isFieldDisable() ? "Update Month End" : "Month End Info"}
                            </SidebarTitle>
                            <SidebarActionCross>
                                <>
                                    {isEdit && <IconButton fileName="Icon-reset" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Reset'} onClick={() => handleReset()} />}
                                    {isEdit && <IconButton fileName="Icon-tick" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Save'} onClick={() => handleSave()} />}
                                    {(!isEdit && isFieldDisable()) && <IconButton fileName="Icon-pen" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Edit'} onClick={() => handleEdit()} />}
                                    <IconButton fileName="Icon-cross" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement='bottom' toolTipText={'Close'} onClick={() => handClose()} />
                                </>
                            </SidebarActionCross>

                        </Sidebarheader>

                    </StyledSidebar>
                </div>
            </div>
            <div className={'add-edit-form'} style={{ height: '78vh', maxHeight: '78vh', overflow: 'auto' }}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        <CriteriaItemContainer>
                            {memoYear}
                            {memoMonth}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoStatus}
                            {memoUpdatedDateTime}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoTurnover}
                            {memoEstimation}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoFromDate}
                            {memoToDate}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoActiveInd}
                        </CriteriaItemContainer>


                    </div>
                </div>
            </div>

        </div>
    </>
}

export default memo(MonthEndClosureEditPanel);
