import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { memo, useCallback, useMemo, useState } from "react";
import { DialogModal, FieldType, HPHButton, HPHGroupRadioButton, IconButton, IFieldValue, Loader } from "veronica-ui-component/dist/component/core";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useNonOpsHeaderTracked } from "presentation/store/NonOps/NonOpsHeaderProvider";
import { useNonOpsHeaderVM } from "presentation/hook/NonOps/useNonOpsHeaderVM";
import { NonOpsConstant } from "presentation/constant/NonOps/NonOpsConstant";
import { adjIndOptions } from "presentation/constant/RadioOptions/StaticRadioOptions";
import DateRangePickerComponent from "presentation/view/components/DateRangePickerComponent";

const NonOpsHeaderPrintModal: React.FC = () => {
    const [nonOpsHdrState] = useNonOpsHeaderTracked();
    const nonOpsHdrVM = useNonOpsHeaderVM();
    const messageBarVM = useMessageBarVM();

    const NON_OPS_CONSTANT = NonOpsConstant.Print;
    const { isShowPrintModal, printCriteria, dynamicOptions } = nonOpsHdrState;    
    const { customerCodeDropdownOptions, printerDropdownOptions } = dynamicOptions;
    const [isLoading, setIsLoading] = useState(false);

    const handleReprint = useCallback(async () => {  
        if(!(printCriteria?.printQueueName)){
            messageBarVM.showError("Please select a Printer.", true);
            return;
        }      
        setIsLoading(true);
 
        nonOpsHdrVM.onReprint(printCriteria).then((data) => {
            if (data && data["printReportFail"]) {
                messageBarVM.showError(data["printReportFail"], true);
                return;
            }

            messageBarVM.showSuccess("Report is sent to printers successfully.", true);
        }).catch((error) => {            
            setIsLoading(false);
            messageBarVM.showError(error.message)
        }).finally(() => {
            setIsLoading(false);
        });
    }, [messageBarVM, nonOpsHdrVM, printCriteria]);
    
    const handlePreview = useCallback(async () => {
        /*if(!(printCriteria?.printQueueName)){
            messageBarVM.showError("Please select a Printer.", true);
            return;
        }*/
        setIsLoading(true);

        nonOpsHdrVM.onPreview(printCriteria).then((data) => {
            if (data && data["printReportFail"]) {
                messageBarVM.showError(data["printReportFail"], true);
                return;
            }

            messageBarVM.showSuccess("Preview report successfully.", true);
        }).catch((error) => {
            setIsLoading(false);
            messageBarVM.showError(error.message)
        }).finally(() => {
            setIsLoading(false);
        });
    }, [messageBarVM, nonOpsHdrVM, printCriteria]);

    const refreshModal = () => {
        if (document) {
            const myModal = document.getElementById("nonOpsHeaderPrintModal");
            if (myModal) {
                const myContent = document.querySelector(".p-dialog-content");
                if (myContent) {
                    (myContent as HTMLElement).style.overflow = "visible";
                }
            }
        }
    }

    const memoAdjInd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <HPHGroupRadioButton
                label="Option"
                radioOptions={adjIndOptions}
                value={printCriteria.adjInd ?? ''}
                onChange={(e) => nonOpsHdrVM.onRadioChange(e, 'adjInd')}
                checked={adjIndOptions.find(item => item.key === printCriteria.adjInd)?.name}
            />
        </div>
        , [nonOpsHdrVM, printCriteria.adjInd])

    const memoCustomerCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}>
            <NbisCommonField
                //errorMessages={allFormState}
                isReadOnly={false}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={printCriteria.customerCode ?? ""}
                fieldValue={printCriteria.customerCode ?? ""}
                fieldLabel={NON_OPS_CONSTANT.CUSTOMER_CODE}
                //isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'customerCode'}
                isShowMissingError={true}
                //maxLength={10}
                options={customerCodeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    nonOpsHdrVM.onPrintCriteriaFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [NON_OPS_CONSTANT.CUSTOMER_CODE, customerCodeDropdownOptions, nonOpsHdrVM, printCriteria.customerCode])

    const memoRefNoFrom = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}>
            <NbisCommonField
                //errorMessages={allFormState}
                isReadOnly={false}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={printCriteria.refNoFrom ?? ""}
                fieldValue={printCriteria.refNoFrom ?? ""}
                fieldLabel={NON_OPS_CONSTANT.REF_NO_FROM}
                //isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'refNoFrom'}
                isShowMissingError={true}
                maxLength={10}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    nonOpsHdrVM.onPrintCriteriaFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [NON_OPS_CONSTANT.REF_NO_FROM, nonOpsHdrVM, printCriteria.refNoFrom])

    const memoRefNoTo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}>
            <NbisCommonField
                //errorMessages={allFormState}
                isReadOnly={false}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={printCriteria.refNoTo ?? ""}
                fieldValue={printCriteria.refNoTo ?? ""}
                fieldLabel={NON_OPS_CONSTANT.REF_NO_TO}
                //isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'refNoTo'}
                isShowMissingError={true}
                maxLength={10}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    nonOpsHdrVM.onPrintCriteriaFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [NON_OPS_CONSTANT.REF_NO_TO, nonOpsHdrVM, printCriteria.refNoTo])

    const memoDocDateRange = useMemo(() =>
            <div className='im-flex-row-item' style={{ width: "330px", marginBottom: "24px" }}>
                <DateRangePickerComponent
                    label={NON_OPS_CONSTANT.DOC_DATE_RANGE}
                    width='330px'
                    fieldNames={{ startField: "docDateFrom", endField: "docDateTo" }}
                    dateRange={{ startDate: printCriteria.docDateFrom, endDate: printCriteria.docDateTo }}
                    //errorMessage={allFormState?.opsDateFrom || allFormState?.opsDateTo}
                    onDatesChange={nonOpsHdrVM.onPrintDateRangeChange}
                />
            </div>
            , [NON_OPS_CONSTANT.DOC_DATE_RANGE, printCriteria.docDateFrom, printCriteria.docDateTo, nonOpsHdrVM.onPrintDateRangeChange])
    
    const memoPrinter = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}>
            <NbisCommonField
                //errorMessages={allFormState}
                isReadOnly={false}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={printCriteria.printQueueName ?? ""}
                fieldValue={printCriteria.printQueueName ?? ""}
                fieldLabel={NON_OPS_CONSTANT.PRINTER}
                //isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'printQueueName'}
                isShowMissingError={true}
                //maxLength={10}
                options={printerDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    nonOpsHdrVM.onPrintCriteriaFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [NON_OPS_CONSTANT.PRINTER, nonOpsHdrVM, printCriteria.printQueueName, printerDropdownOptions])

    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;
    
    return <DialogModal style={{ minWidth: '32vw', minHeight: '45vh' }}
        appendTo='self'
        showButton={false}
        visible={isShowPrintModal}
        positions="center"
        resizable={false}
        header={
            <div className="im-charge-data-search-confirm-modal-header">
                <span className="name">{NON_OPS_CONSTANT.TITLE}</span>
                <IconButton fileName='Icon-cross' size="medium" onClick={nonOpsHdrVM.onClosePrintModal} tooltipDisable={true} />
            </div>
        }
        dialogContent={
            <div className="im-charge-data-search-confirm-modal-content">

                <CriteriaItemContainer>
                    {memoAdjInd}
                </CriteriaItemContainer>

                <CriteriaItemContainer>
                    {memoCustomerCode}
                    {memoRefNoFrom}
                    {memoRefNoTo}
                </CriteriaItemContainer>

                <CriteriaItemContainer>                    
                    {memoPrinter}
                    {memoDocDateRange}
                </CriteriaItemContainer>
            </div>
        }
        className="im-charge-data-search-confirm-modal"
        id="nonOpsHeaderPrintModal"
        onShow={refreshModal}
        footer={
            <div className="im-charge-data-search-confirm-modal-footer">
                <HPHButton label={WorkspaceConstant.Common.BUTTON_CANCEL} size={'Small'} theme={'Secondary'} onClick={nonOpsHdrVM.onClosePrintModal} />
                <HPHButton label={"Preview"} size={'Small'} theme={'Secondary'} onClick={handlePreview} />
                <HPHButton label={WorkspaceConstant.Common.BUTTON_PRINT} size={'Small'} theme={'Primary'} onClick={handleReprint} />
            </div>
        }
    />;
};

export default memo(NonOpsHeaderPrintModal);