export interface RequisitionFormCustomerEntity {
  id: number,
  reqFormId: number | null,
  customerCode: string | null,
  billToCompany: string | null, 
  dummyKey: string | null,

  [key: string]: string | boolean | number | null | undefined | Object

}

export const EMPTY_REQUISITION_FORM_CUSTOMER_ENTITY: RequisitionFormCustomerEntity = {
  id: 0,
  reqFormId: null,
  customerCode: null,
  billToCompany: null,
  dummyKey: null,
}