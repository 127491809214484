import { ChargeDataSearchRequestEntity } from "domain/entity/ChargeDataEnquiry/ChargeDataSearchRequestEntity";
import { ManualSystemChargeEntity } from "domain/entity/ChargeDataEnquiry/ManualSystemChargeEntity";
import { axiosPostData } from "../axios/AxiosBasicImpl";
import chargeAxiosInstance from "../axios/chargeAxiosInstance";
import { ChargeDataEnquiryRepository } from "./ChargeDataEnquiryRepo";

export const ChargeDataEnquiryRepoImpl = (): ChargeDataEnquiryRepository => {
    const url = "/v1/chargeDataEnquirySearch"

    const getManualSystemCharges = (request: ChargeDataSearchRequestEntity): Promise<ManualSystemChargeEntity[]> => {
        return axiosPostData(chargeAxiosInstance, `${url}`, request).then(res => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "";
                if (res.data && typeof (res.data) === "string") {
                    errorMessage = res.data;
                }
                return "Error:" + errorMessage;
            }
        }).catch(err => {
            return [];
        });
    }

    return {
        getManualSystemCharges: getManualSystemCharges
    }
}