import { ManualChargePrintCriteria } from "domain/entity/ManualCharge/ManualChargePrintCriteria"

export interface ManualChargeHeaderPrintCriteria extends ManualChargePrintCriteria {    
    consortiumCode?: string | null,
    vesselCode?: string | null,
    voyageCode?: string | null,
    billToCompany?: string | null,
    chargeType?: string | null,
    opsDateFrom?: Date | null,
    opsDateTo?: Date | null,

    [key: string]: string[] | string | boolean | number | Date | null | undefined
}

export const EMPTY_MANUAL_CHARGE_HEADER_PRINT_CRITERIA : ManualChargeHeaderPrintCriteria = {
    adjInd: null,
    refNoFrom: null,
    refNoTo: null,
    printQueueName: null,
    chargeCategory: "MC"
}