import { MessageConstant, formatMessage } from "presentation/constant/MessageConstant";
import { UserConStant } from "presentation/constant/User/UserConstant";
import { useUserVM } from "presentation/hook/User/useUserVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useUserTracked } from "presentation/store/User/UserProvider";
import { memo, useCallback, useState } from "react";
import { HPHBreadcrumb, IconButton } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const UserTitleBar: React.FC = () => {
    const [userState] = useUserTracked();
    const [anainfoState] = useANAInfoTracked();
    const userVM = useUserVM();
    const messageBarVM = useMessageBarVM();
    const { isAdd, isEditable, isRead, currentSelectedData } = userState.userState;
    const { isAdd: isGroupAdd, isRead: isGroupRead, isEditable: isGroupEditable } = userState.userGroupState;
    const { allowCreate, allowUpdate, userName } = anainfoState;
    const [, setIsSave] = useState(false);
    const USER_CONSTANT = UserConStant.user;

    const isDisable = () => {
        if (isGroupAdd || (!isGroupRead && isGroupEditable)) return true;

        return false;
    }

    const handleClose = useCallback(() => {
        userVM.onCloseClick();
    }, [userVM]);

    const handleEdit = useCallback(() => {
        userVM.onEditClick();
    }, [userVM]);

    const handleReset = useCallback(() => {
        userVM.onResetClick();
    }, [userVM]);

    const handleSave = useCallback(async (isAdd: boolean) => {
        setIsSave(true);
        userVM.onShowLoading();
        userVM.onUserSaveClicked();
        await userVM.onUserSave(currentSelectedData, isAdd, userName).then((data) => {
            if (data && data["SaveUserFail"]) {
                const err: string = data["SaveUserFail"]?.toString() ?? "";
                messageBarVM.showError(err.replace("Error:", ""));
            } else if (!data) {
                let msg = MessageConstant.common.SAVED_DATA_SUCCESSFUL;
                if (!isAdd) {
                    msg = MessageConstant.common.UPDATED_DATA_SUCCESSFUL
                }
                messageBarVM.showSuccess(formatMessage(msg,
                    { "{recordName}": currentSelectedData.name }));
                userVM.loadAllUsers();
            }
        }).catch((error) => {
            messageBarVM.showError(error.message?.toString());
            userVM.onHideLoading();
        }).finally(() => {
            userVM.onHideLoading();
        })
    }, [userVM, currentSelectedData, userName, messageBarVM]);

    return <Sidebarheader style={{ width: '100%' }}>
        <Breadcrumb>
            <HPHBreadcrumb breadcrumbData={[{ title: USER_CONSTANT.USERGROUPSETTING }, { title: USER_CONSTANT.USER }]} onCurrentClick={handleClose}></HPHBreadcrumb>
        </Breadcrumb>
        <StyledAction>
            {(!isAdd && (isEditable && isRead)) &&
                <>
                    {/* Edit Button */}
                    {
                        (
                            (isEditable && allowUpdate)
                        )
                        && <IconButton fileName='Icon-pen' size='medium' toolTipText={'Edit'} toolTipArrow={false} onClick={handleEdit} />
                    }
                    {/* Close Button */}
                    <IconButton fileName='Icon-cross' size='medium' toolTipText={'Close'} toolTipArrow={false} onClick={handleClose} />
                </>}
            {(isAdd || (isEditable && !isRead)) &&
                <>
                    {/* Reset Form Button */}
                    <IconButton fileName="Icon-reset" size="medium" toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Reset'} onClick={handleReset} />
                    {/* Close Button */}
                    <IconButton fileName="Icon-cross" size="medium" disabled={isDisable()} toolTipArrow={false} toolTipPlacement="left" toolTipText={'Close'} onClick={handleClose} />
                    {/* Save Button */}
                    {
                        (
                            (isAdd && allowCreate) ||
                            (isEditable && allowUpdate)
                        )
                        && <IconButton fileName="Icon-tick" size="medium" disabled={isDisable()} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Save'} onClick={() => handleSave(isAdd)} />
                    }
                </>}
        </StyledAction>
    </Sidebarheader>
}

export default memo(UserTitleBar);