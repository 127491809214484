import _ from "lodash";
import { billingTypeCheckboxOption } from "presentation/constant/CheckBox/StaticCheckboxOptions";
import { CriteriaDirectoryChargeCheckboxOption } from "presentation/constant/DocumentEnquiry/CriteriaDirectoryCheckboxOption";
import { DocumentEnquiryConstant } from "presentation/constant/DocumentEnquiry/DocumentEnquiryConstant";
import { EMPTY_DOCUMENT_ENQUIRY_CRITERIA } from "presentation/constant/DocumentEnquiry/DocumentEnquiryCriteria";
import { useDocumentEnquiryVM } from "presentation/hook/DocumentEnquiry/useDocumentEnquiryVM";
import { useDocumentEnquiryTracked } from "presentation/store/DocumentEnquiry/DocumentEnquiryProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DateRangePickerComponent from "presentation/view/components/DateRangePickerComponent";
import NbisInputDropDown from "presentation/view/components/NbisInputDropDown";
import { useMemo } from "react";
import {
    GroupCheckboxList,
    HPHGroupCheckbox,
    HPHGroupTitle,
    InputField
} from "veronica-ui-component/dist/component/core";

export const DocumentEnquiryCriteriaEditChargePanel = () => {
    const DOC_ENQ_HDR_CONST = DocumentEnquiryConstant.Header;
    const [docEnquiryState] = useDocumentEnquiryTracked();
    const searchCriteria = docEnquiryState.searchCriteria
    const docEnquiryVM = useDocumentEnquiryVM();
    const enabledCriteria = useMemo(() => CriteriaDirectoryChargeCheckboxOption.find(item => docEnquiryState.enabledSearchCriteria[item.key]), [docEnquiryState.enabledSearchCriteria])

    const memoSubChgTypeOptions = useMemo(() => {
        const groupOfSubChgTypeOptions = searchCriteria.chgTypeList?.map(chgType => docEnquiryState.dynamicOptions.subChgTypeDropdownOptions[chgType]);
        const commonSubChgTypeOptions = _.orderBy(_.uniqBy(_.flatMap(_.concat(groupOfSubChgTypeOptions ?? [])), 'value'), 'dropdownLabel');
        return commonSubChgTypeOptions;
    }, [searchCriteria.chgTypeList, docEnquiryState.dynamicOptions.subChgTypeDropdownOptions])

    const memoChgType = useMemo(() =>
        <div className='im-flex-row-item'>
            <NbisInputDropDown
                label={DOC_ENQ_HDR_CONST.CHARGE_TYPE}
                width='150px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={searchCriteria.chgTypeList?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => {
                    docEnquiryVM.onMultipleDropdownChange(e, 'chgTypeList')
                    docEnquiryVM.onDropdownChange(EMPTY_DOCUMENT_ENQUIRY_CRITERIA.subChgTypeList, 'subChgTypeList')
                }}
                options={docEnquiryState.dynamicOptions.chgTypeDropdownOptions} />
        </div>
        , [searchCriteria.chgTypeList, docEnquiryState.dynamicOptions.chgTypeDropdownOptions, docEnquiryVM, DOC_ENQ_HDR_CONST.CHARGE_TYPE])

    const memoSubChgType = useMemo(() =>
        <div className='im-flex-row-item'>
            <NbisInputDropDown
                label={DOC_ENQ_HDR_CONST.SUB_CHARGE_TYPE}
                width='150px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={searchCriteria.subChgTypeList?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => docEnquiryVM.onMultipleDropdownChange(e, 'subChgTypeList')}
                options={memoSubChgTypeOptions} />
        </div>
        , [DOC_ENQ_HDR_CONST.SUB_CHARGE_TYPE, searchCriteria.subChgTypeList, memoSubChgTypeOptions, docEnquiryVM])

    const memoBillToCompany = useMemo(() =>
        <div className='im-flex-row-item'>
            <NbisInputDropDown
                label={DOC_ENQ_HDR_CONST.BILL_TO_COMPANY}
                inputType="freeText"
                width="150px"
                mode={'multiple'}
                sort={false}
                value={searchCriteria.billToCompList?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => docEnquiryVM.onMultipleDropdownChange(e, 'billToCompList')}
                options={docEnquiryState.dynamicOptions.billToCompDropdownOptions} />
        </div>
        , [searchCriteria.billToCompList, docEnquiryVM, docEnquiryState.dynamicOptions.billToCompDropdownOptions, DOC_ENQ_HDR_CONST.BILL_TO_COMPANY])


    const memoOpsFromTo = useMemo(() =>
        <div className='im-flex-row-item'>
            <DateRangePickerComponent
                label={DOC_ENQ_HDR_CONST.OPS_DATE_RANGE}
                width="330px"
                fieldNames={{ startField: "opsDateFrom", endField: "opsDateTo" }}
                dateRange={{ startDate: searchCriteria.opsDateFrom, endDate: searchCriteria.opsDateTo }}
                onDatesChange={docEnquiryVM.onDateRangeChange}
            />
        </div>
        , [searchCriteria.opsDateFrom, searchCriteria.opsDateTo, docEnquiryVM.onDateRangeChange, DOC_ENQ_HDR_CONST.OPS_DATE_RANGE])

    const memoBillingCycleCode = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputField
                width='330px'
                maxLength={999}
                label={DOC_ENQ_HDR_CONST.BILLING_CYCLE_CODE}
                type="text"
                value={searchCriteria.billingCycleCode || ''}
                onChange={(e: any) => docEnquiryVM.onInputTextChange(e, 'billingCycleCode')} />
        </div>
        , [searchCriteria.billingCycleCode, docEnquiryVM, DOC_ENQ_HDR_CONST.BILLING_CYCLE_CODE])

    // const memoBillingCycle = useMemo(() =>
    //     <div className='im-flex-row-item'>
    //         <NbisInputDropDown
    //             label={DOC_ENQ_HDR_CONST.BILL_CYCLE}
    //             width='150px'
    //             inputType="freeText"
    //             mode={'multiple'}
    //             sort={false}
    //             value={searchCriteria.billingCycleList?.map((item) => ({
    //                 value: item
    //             }))}
    //             onChange={(e:any) => docEnquiryVM.onMultipleDropdownChange(e, 'billingCycleList')}
    //             options={billingTypeDropdownOption}/>
    //     </div>
    // , [searchCriteria.billingCycleList, docEnquiryVM, DOC_ENQ_HDR_CONST.BILL_CYCLE])

    const memoBillingCycle = useMemo(() =>
        <div className='im-flex-row-item'>
            <HPHGroupCheckbox
                label={DOC_ENQ_HDR_CONST.BILL_CYCLE}
                checkboxData={docEnquiryState.dynamicGroupCheckboxs.billingCycleCheckBoxOptions}
                selectedValues={searchCriteria.billingCycleList}
                orientation={"horizontal"}
                onChange={(e: (GroupCheckboxList | undefined)[]) => docEnquiryVM.onGroupCheckboxChange(e, "billingCycleList")}
            />
        </div>
        , [DOC_ENQ_HDR_CONST.BILL_CYCLE, docEnquiryState.dynamicGroupCheckboxs.billingCycleCheckBoxOptions, docEnquiryVM, searchCriteria.billingCycleList])

    // const memoBillingType = useMemo(() =>
    //         <div className='im-flex-row-item'>
    //             <NbisInputDropDown
    //                 label={DOC_ENQ_CONSTANT.BILLING_TYPE}
    //                 width='150px'
    //                 inputType="freeText"
    //                 mode={'multiple'}
    //                 sort={false}
    //                 value={searchCriteria.billingTypeList?.map((item) => ({
    //                     value: item
    //                 }))}
    //                 onChange={(e:any) => docEnquiryVM.onMultipleDropdownChange(e, 'billingTypeList')}
    //                 options={billingTypeDropdownOption}/>
    //         </div>
    //     , [searchCriteria.billingTypeList, docEnquiryVM, DOC_ENQ_CONSTANT.BILLING_TYPE])

    const memoBillingType = useMemo(() =>
        <>
            <div className='im-flex-row-item'>
                <HPHGroupCheckbox
                    label={DOC_ENQ_HDR_CONST.BILLING_TYPE}
                    checkboxData={billingTypeCheckboxOption}
                    selectedValues={searchCriteria.billingTypeList}
                    orientation={"horizontal"}
                    onChange={(e: (GroupCheckboxList | undefined)[]) => docEnquiryVM.onGroupCheckboxChange(e, "billingTypeList")}
                />
            </div>
        </>
        , [DOC_ENQ_HDR_CONST.BILLING_TYPE, searchCriteria, docEnquiryVM])

    const memoCustCode = useMemo(() =>
        <div className='im-flex-row-item'>
            <NbisInputDropDown
                label={DOC_ENQ_HDR_CONST.CUST_CODE}
                inputType="freeText"
                width="330px"
                mode={'multiple'}
                sort={false}
                value={searchCriteria.customerCodeList?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => docEnquiryVM.onMultipleDropdownChange(e, 'customerCodeList')}
                options={docEnquiryState.dynamicOptions.customerCodeDropdownOptions} />
        </div>
        , [DOC_ENQ_HDR_CONST.CUST_CODE, searchCriteria.customerCodeList, docEnquiryState.dynamicOptions.customerCodeDropdownOptions, docEnquiryVM])

    const memoHandlingTml = useMemo(() =>
        <div className='im-flex-row-item'>
            <NbisInputDropDown
                label={DOC_ENQ_HDR_CONST.HANDLING_TML}
                width='330px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={searchCriteria.handlingTmlList?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => {
                    docEnquiryVM.onMultipleDropdownChange(e, 'handlingTmlList')
                }}
                options={docEnquiryState.dynamicOptions.handlingTmlDropdownOptions} />
        </div>
        , [DOC_ENQ_HDR_CONST.HANDLING_TML, searchCriteria.handlingTmlList, docEnquiryState.dynamicOptions.handlingTmlDropdownOptions, docEnquiryVM])

    const memoPoNumber = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputField
                width='330px'
                maxLength={999}
                label={DOC_ENQ_HDR_CONST.PO_NUMBER}
                type="text"
                value={searchCriteria.poNumber || ''}
                onChange={(e: any) => docEnquiryVM.onInputTextChange(e, 'poNumber')} />
        </div>
        , [DOC_ENQ_HDR_CONST.PO_NUMBER, searchCriteria.poNumber, docEnquiryVM])

    if (!enabledCriteria) return null;

    return <div className='im-charge-data-search-criteria-group'>
        <HPHGroupTitle titleText={DOC_ENQ_HDR_CONST.CHARGE} />
        <CriteriaItemContainer>
            {docEnquiryState.enabledSearchCriteria.chgTypeList ? <>{memoChgType}</> : null}
            {docEnquiryState.enabledSearchCriteria.subChgTypeList ? <>{memoSubChgType}</> : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {docEnquiryState.enabledSearchCriteria.billToCompList ? memoBillToCompany : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {docEnquiryState.enabledSearchCriteria.opsDate ? <>{memoOpsFromTo}</> : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {docEnquiryState.enabledSearchCriteria.customerCodeList ? memoCustCode : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {docEnquiryState.enabledSearchCriteria.billingCycleList ? memoBillingCycle : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {docEnquiryState.enabledSearchCriteria.billingCycleCode ? memoBillingCycleCode : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {docEnquiryState.enabledSearchCriteria.billingTypeList ? memoBillingType : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {docEnquiryState.enabledSearchCriteria.handlingTmlList ? memoHandlingTml : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {docEnquiryState.enabledSearchCriteria.poNumber ? memoPoNumber : null}
        </CriteriaItemContainer>
    </div>;
};
