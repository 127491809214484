import { EMPTY_REQUISITION_FORM_CUSTOMER_ENTITY, RequisitionFormCustomerEntity } from "domain/entity/RequisitionForm/RequisitionFormCustomerEntity";
import { EMPTY_REQUISITION_FORM_ENTITY, RequisitionFormEntity } from "domain/entity/RequisitionForm/RequisitionFormEntity";
import { RequisitionFormMgrEntity } from "domain/entity/RequisitionForm/RequisitionFormMgrEntity";
import { EMPTY_REQUISITION_FORM_SEARCH_CRITERIA, RequisitionFormSearchCriteria } from "domain/entity/RequisitionForm/RequisitionFormSearchCriteria";
import { DEFAULT_ENABLED_REQUISITION_FORM_SEARCH_CRITERIA, RequisitionFormEnabledSearchCriteria } from "presentation/constant/RequisitionForm/RequisitionFormEnabledSearchCriteria";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";
import { EMPTY_REQUISITION_FORM_PRINT_CRITERIA, RequisitionFormPrintCriteria } from "domain/entity/RequisitionForm/RequisitionFormPrintCriteria";

export interface RequisitionFormDropdownOptions {

    stateDropdownOptions: DropdownProps[],
    billToCompanyDropdownOptions: DropdownProps[],
    customerCodeDropdownOptions: DropdownProps[],
    reqModeDropdownOptions: DropdownProps[],
    reqNatureDropdownOptions: DropdownProps[],
    currencyDropdownOptions: DropdownProps[],
    managerInChgDropdownOptions: DropdownProps[],
    accountManagerDropdownOptions: DropdownProps[],
    finDropdownOptions: DropdownProps[],
    comDropdownOptions: DropdownProps[],
    mdDropdownOptions: DropdownProps[],
    gmdDropdownOptions: DropdownProps[],
    printerDropdownOptions: DropdownProps[],
}

export interface RequisitionFormViewChangeState extends BaseViewChangeSate {
    editingEntity: RequisitionFormEntity,
}

export interface RequisitionFormDtlViewChangeState extends BaseViewChangeSate {
    reqFormMgrs: RequisitionFormMgrEntity[],
    reqFormCusts: RequisitionFormCustomerEntity[],
    selectedReqFormCusts: RequisitionFormCustomerEntity[],
    currentReqFormCust: RequisitionFormCustomerEntity,
}

export interface RequisitionFormMaintenanceModel {

    currentRequisitionForm: RequisitionFormEntity,
    isLoading: boolean,
    isShowCriteriaPanel: boolean,
    isShowPrintModal: boolean,
    searchCriteria: RequisitionFormSearchCriteria,
    reqFormPrintCriteria: RequisitionFormPrintCriteria,
    dynamicOptions: RequisitionFormDropdownOptions,
    requisitionForms: RequisitionFormEntity[],
    selectedRequisitionFormRows: RequisitionFormEntity[],
    allFormState?: { [x: string]: string },
    enabledSearchCriteria: RequisitionFormEnabledSearchCriteria,
    isShowDetail: boolean,
    isBackFromDetail: boolean,
    isAllowAutoSearch: boolean,
    masterState: RequisitionFormViewChangeState,
    detailState: RequisitionFormDtlViewChangeState,
    forceRefresh: boolean,
}

export const EMPTY_REQUISITION_FORM_MAINTENANCE_MODEL: RequisitionFormMaintenanceModel = {
    isLoading: false,
    isShowCriteriaPanel: false,
    isShowPrintModal: false,
    isBackFromDetail: false,
    isAllowAutoSearch: true,
    searchCriteria: { ...EMPTY_REQUISITION_FORM_SEARCH_CRITERIA },
    reqFormPrintCriteria: { ...EMPTY_REQUISITION_FORM_PRINT_CRITERIA},
    dynamicOptions: {
        stateDropdownOptions: [],
        billToCompanyDropdownOptions: [],
        customerCodeDropdownOptions: [],
        reqModeDropdownOptions: [],
        reqNatureDropdownOptions: [],
        currencyDropdownOptions: [],
        managerInChgDropdownOptions: [],
        accountManagerDropdownOptions: [],
        finDropdownOptions: [],
        comDropdownOptions: [],
        mdDropdownOptions: [],
        gmdDropdownOptions: [],
        printerDropdownOptions: [],
    },
    requisitionForms: [],
    selectedRequisitionFormRows: [],
    allFormState: {},
    enabledSearchCriteria: { ...DEFAULT_ENABLED_REQUISITION_FORM_SEARCH_CRITERIA },
    isShowDetail: false,
    currentRequisitionForm: { ...EMPTY_REQUISITION_FORM_ENTITY },
    masterState: {
        isSliderOpen: false,
        isTabularDataActive: false,
        isAdd: false,
        isRead: false,
        isEditable: false,
        isSaveClicked: false,
        lastEditRowId: "",
        isSelected: false,
        slider: {
            isAdd: false,
            isRead: false,
            isEditable: false
        },

        editingEntity: EMPTY_REQUISITION_FORM_ENTITY
    },
    detailState: {
        isSliderOpen: false,
        isTabularDataActive: false,
        isAdd: false,
        isRead: false,
        isEditable: false,
        isSaveClicked: false,
        lastEditRowId: "",
        isSelected: false,
        slider: {
            isAdd: false,
            isRead: false,
            isEditable: false
        },
        reqFormMgrs: [],
        reqFormCusts: [],
        selectedReqFormCusts: [],
        currentReqFormCust: EMPTY_REQUISITION_FORM_CUSTOMER_ENTITY,
    },
    forceRefresh: false
}