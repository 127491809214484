import { EMPTY_GENERAL_PROPOSAL_SEARCH_CRITERIA } from "domain/entity/GeneralProposal/GeneralProposalSearchCriteria";
import _ from "lodash";
import { approvalProcessStatusDropdownOption } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { GeneralProposalConstant } from "presentation/constant/GeneralProposal/GeneralProposalConstant";
import { useGeneralProposalVM } from "presentation/hook/GeneralProposal/useGeneralProposalVM";
import { useGeneralProposalTracked } from "presentation/store/GeneralProposal/GeneralProposalProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DateRangePickerComponent from "presentation/view/components/DateRangePickerComponent";
import NbisInputDropDown from "presentation/view/components/NbisInputDropDown";
import { memo, useMemo } from "react";

const GeneralProposalSearchForm = () => {
    const GENERAL_PROPOSAL_CONSTANT = GeneralProposalConstant.Search;
    const [generalProposalState] = useGeneralProposalTracked();
    const generalProposalVM = useGeneralProposalVM();
    const searchCriteria = generalProposalState.searchCriteria;

    const memoTariffCodeOptions = useMemo(() => {
        const groupOfTariffCodeOptions = searchCriteria.tariffTypeList?.map(tariffType => generalProposalState.dynamicOptions.tariffCodeDropdownOptions[tariffType]);
        const commonTariffCodeOptions = _.orderBy(_.uniqBy(_.flatMap(_.concat(groupOfTariffCodeOptions ?? [])), 'value'), 'dropdownLabel');
        return commonTariffCodeOptions;
    }, [searchCriteria.tariffTypeList, generalProposalState.dynamicOptions.tariffCodeDropdownOptions])

    const memoTarType = useMemo(() =>
        <div className='flex-row-item'>
            <NbisInputDropDown
                label={GeneralProposalConstant.Table.TARIFF_TYPE}
                width='180px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={searchCriteria.tariffTypeList?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => {
                    generalProposalVM.onMultipleDropdownChange(e, 'tariffTypeList')
                    generalProposalVM.onDropdownChange(EMPTY_GENERAL_PROPOSAL_SEARCH_CRITERIA.tariffCodeList, 'tariffCodeList')
                }}
                options={generalProposalState.dynamicOptions.tariffTypeDropdownOptions} />
        </div>
        , [searchCriteria.tariffTypeList, generalProposalState.dynamicOptions.tariffTypeDropdownOptions, generalProposalVM])

    const memoTarCode = useMemo(() =>
        <div className='flex-row-item'>
            <NbisInputDropDown
                label={GeneralProposalConstant.Table.TARIFF_CODE}
                width='180px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={searchCriteria.tariffCodeList?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => generalProposalVM.onMultipleDropdownChange(e, 'tariffCodeList')}
                options={memoTariffCodeOptions} />
        </div>
        , [searchCriteria.tariffCodeList, memoTariffCodeOptions, generalProposalVM])

    const memoStatus = useMemo(() =>
        <div className='flex-row-item'>
            <NbisInputDropDown
                label={GENERAL_PROPOSAL_CONSTANT.STATUS}
                width='390px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={searchCriteria.statusList?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => generalProposalVM.onMultipleDropdownChange(e, 'statusList')}
                options={approvalProcessStatusDropdownOption} />
        </div>
        , [GENERAL_PROPOSAL_CONSTANT.STATUS, searchCriteria.statusList, generalProposalVM])


    const memoEffectiveDateFromTo = useMemo(() =>
        <div className='im-flex-row-item'>
            <DateRangePickerComponent
                label={GENERAL_PROPOSAL_CONSTANT.EFFECTIVE_DATE_RANGE}
                width='390px'
                fieldNames={{ startField: "effectiveDateFrom", endField: "effectiveDateTo" }}
                dateRange={{ startDate: searchCriteria.effectiveDateFrom, endDate: searchCriteria.effectiveDateTo }}
                onDatesChange={generalProposalVM.onDateRangeChange}
            />
        </div>
        , [GENERAL_PROPOSAL_CONSTANT.EFFECTIVE_DATE_RANGE, searchCriteria.effectiveDateFrom, searchCriteria.effectiveDateTo, generalProposalVM.onDateRangeChange])

    return <>

        <CriteriaItemContainer>
            {memoTarType}
            {memoTarCode}
        </CriteriaItemContainer>

        <CriteriaItemContainer>
            {memoStatus}
        </CriteriaItemContainer>

        <CriteriaItemContainer>
            {memoEffectiveDateFromTo}
        </CriteriaItemContainer>

    </>;
}
export default memo(GeneralProposalSearchForm);