import React from "react";
import RecalProcessAndGenMaintenance from "./RecalProcessAndGenMaintenance";

export const RecalProcessAndGenMasterView: React.FC = () => {

    return <>
        <div className="main-comp-wrapper im-hide-side-form-draggable">
            <RecalProcessAndGenMaintenance />
        </div>

    </>
}
