
import { useTariffComponentMaintenanceVM } from "presentation/hook/TariffComponent/useTariffComponentMaintenanceVM";
import { useTariffComponentMaintenanceTracked } from "presentation/store/TariffComponent/TariffComponentMaintenanceProvider";
import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import TariffComponentTablePanel from "./TariffComponentTablePanel";

const TariffComponentMaintenance: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [tariffComponentState] = useTariffComponentMaintenanceTracked();
    const tariffComponentVM = useTariffComponentMaintenanceVM();
    const { isBackMaster } = tariffComponentState;


    useEffect(() => {
        const initialScreen = async () => {
            setIsLoading(true);
            try {
                const results = await Promise.allSettled([
                    tariffComponentVM.loadDropdownOption(),
                    tariffComponentVM.onSearch()
                ]);
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {

                    }
                })
            } catch (error) {
                setIsLoading(false);
            }
        }
        initialScreen().then(async () => {
            /*tariffComponentVM.onSearch().then((data) => {
                setIsLoading(false)
            }).catch(error => {
                setIsLoading(false)
            });*/
            setIsLoading(false)
        }).catch(error => {
            setIsLoading(false)
        });

    }, [isBackMaster, tariffComponentVM])



    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;

    return <>
        <div className={`main-comp-wrapper im-hide-side-form-draggable`}>
            <SliderPanel
                isOpen={false}
                draggable={false}
                leftSectionWidth={"0%"}
                rightSectionWidth={"100%"}
                leftChildren={<TariffComponentTablePanel />}
            />
        </div>
    </>
}

export default memo(TariffComponentMaintenance);