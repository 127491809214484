import { ChargeTypeRepoImpl } from "domain/repository/ChargeType/ChargeTypeRepoImpl";
import { CompanyFspRepoImpl } from "domain/repository/Company/CompanyFspRepoImpl";
import { CompanyTerminalRepoImpl } from "domain/repository/Company/CompanyTerminalRepoImpl";
import { ForwarderCodeRepoImpl } from "domain/repository/ForwarderCode/ForwarderCodeRepoImpl";
import { MasterDataRepoImpl } from "domain/repository/MasterData/MasterDataRepoImpl";
import { OpsLineRepoImpl } from "domain/repository/OpsLine/OpsLineRepoImpl";
import { ParameterDetailRepoImpl } from "domain/repository/parameter/ParameterDetailRepoImpl";
import { useCompanyDetailTracked } from "presentation/store/Company/CompanyDetailProvider";
import { CompanyFspVM } from "presentation/viewModel/Company/CompanyFspVM";
import { useMemo } from "react";

export const useCompanyFspVM = () => {
    const [, setCompanyDetailState] = useCompanyDetailTracked();
    const companyFspVM = useMemo(() =>
        CompanyFspVM({
            dispatch: [setCompanyDetailState],
            companyFspRepo: CompanyFspRepoImpl(),
            parameterDetailRepo: ParameterDetailRepoImpl(),
            opsLineRepo: OpsLineRepoImpl(),
            chargeTypeRepo: ChargeTypeRepoImpl(),
            forwarderCodeRepo: ForwarderCodeRepoImpl(),
            masterDataRepo: MasterDataRepoImpl(),
            companyTerminalRepo: CompanyTerminalRepoImpl(),
        }), [setCompanyDetailState])

    return companyFspVM
}