import { ChargeConfirmedEntity } from "domain/entity/ChargeConfirmed/ChargeConfirmedEntity";
import { RefObject } from "react";
import { DynamicTableEntity } from "../../../domain/entity/Common/DynamicTableEntity";

export interface ChargeConfirmedDetailChangeState {
    details: any[],
    isFetching: boolean,
}

export interface ChargeConfirmedModel {
    isLoading: boolean,
    isShowRightPanel: boolean,
    currentTableRef?: RefObject<any>,
    dynamicTableColumns: DynamicTableEntity[],//{[key:string]: any}[],
    chargeConfirmedList: ChargeConfirmedEntity[],
    currentRow: ChargeConfirmedEntity | null,
    selectedRows: ChargeConfirmedEntity[],
    searchCounter: number,
    isShowAll: boolean,
    detailState: ChargeConfirmedDetailChangeState,
    isDeselectAll: boolean,
    forceRefresh: boolean,
}

export const EMPTY_CHARGE_CONFIRMED_MODEL: ChargeConfirmedModel = {
    isLoading: false,
    isShowRightPanel: false,
    dynamicTableColumns: [],
    chargeConfirmedList: [],
    currentRow: null,
    selectedRows: [],
    searchCounter: 0,
    isShowAll: false,
    detailState: {
        details: [],
        isFetching: false,
    },
    isDeselectAll: false,
    forceRefresh: false
}