import { SelectionChangedEvent } from "ag-grid-community";
import { BillingTypeMappingEntity } from "domain/entity/BillingTypeMapping/BillingTypeMappingEntity";
import _ from "lodash";
import { INITIAL_BILLING_TYPE_MAPPING_COL_DEF } from "presentation/constant/BillingTypeMapping/BillingTypeMappingColumnDefinition";
import { BillingTypeMappingConstant } from "presentation/constant/BillingTypeMapping/BillingTypeMappingConstant";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useBillingTypeMappingVM } from "presentation/hook/BillingTypeMapping/useBillingTypeMappingVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useBillingTypeMappingTracked } from "presentation/store/BillingTypeMapping/BillingTypeMappingProvider";
import FileUpload from "presentation/view/components/FileUpload";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import { NbisIconButton } from "presentation/view/components/NbisIconButton";
import RightClickMenuWithMoveAfter from "presentation/view/components/NbisRightClickMenu/RightClickMenuWithMoveAfter";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { createRef, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
const BillingTypeMappingTablePanel = () => {
    const gridRef: any = useRef(null);
    const [billingTypeMappingState] = useBillingTypeMappingTracked();
    const billingTypeMappingVM = useBillingTypeMappingVM();
    const messageBarVM = useMessageBarVM();
    const BILLING_TYPE_MAPPING_CONSTANT = BillingTypeMappingConstant.Title;
    const [anainfoState] = useANAInfoTracked();
    const { allowUpdate, allowCreate } = anainfoState;
    const rightClickRef: any = createRef();
    const { updatedRows } = billingTypeMappingState;
    // for right click menu with move after begin
    const [showMoveCursor, setShowMoveCursor] = useState<boolean>(false);
    const [allRows, setAllRows] = useState<BillingTypeMappingEntity[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [initialAllRows, setInitialAllRows] = useState<boolean>(true);

    const getAllRows = useCallback(() => {
        return allRows;
    }, [allRows]);

    const getSelectedRows = useCallback(() => {
        return billingTypeMappingState.selectedRows;
    }, [billingTypeMappingState.selectedRows]);
    //for right click menu with move after end

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();
        billingTypeMappingVM.updateSelectedRows(selectedRows);
    }, [billingTypeMappingVM])

    const handleRowDoubleClick = useCallback((entity: BillingTypeMappingEntity) => {
        billingTypeMappingVM.onRowDoubleClick(entity);
    }, [billingTypeMappingVM])

    useEffect(() => {
        const handleRightClickMenu = (event: any) => {
            if (_.isEmpty(billingTypeMappingState.selectedRows) || !allowUpdate) {
                return;
            }
            event.preventDefault();
            rightClickRef.current.show(event);
        };
        document.getElementById('myDiv')?.addEventListener("contextmenu", handleRightClickMenu);

        return () => {
            document.getElementById('myDiv')?.removeEventListener("contextmenu", handleRightClickMenu);
        };
    }, [messageBarVM, rightClickRef, allowUpdate, billingTypeMappingState.selectedRows]);

    // for right click menu with move after begin
    const onRefreshRow = useCallback((newAllRows: any[]) => {
        setAllRows(newAllRows);
        billingTypeMappingVM.updateSelectedRows([]);
        setShowMoveCursor(true);
    }, [billingTypeMappingVM]);

    const onMoveCancel = useCallback(() => {
        setInitialAllRows(true);
        setAllRows(billingTypeMappingState.billingTypeMappingList);
        billingTypeMappingVM.updateSelectedRows([]);
        setShowMoveCursor(false);
    }, [billingTypeMappingState.billingTypeMappingList, billingTypeMappingVM]);

    const onSaveMove = useCallback(async (newAllRows: any[]) => {
        if (_.isEmpty(newAllRows)) return;

        setIsLoading(true);

        billingTypeMappingVM.onApply(newAllRows).then((res) => {
            if (!res || !res.success) {
                messageBarVM.showError('Move failed.');
                setInitialAllRows(true);
                setIsLoading(false);

                billingTypeMappingVM.updateSelectedRows([]);
                setShowMoveCursor(false);
            } else {
                billingTypeMappingVM.searchAllBillingTypeMappingList().then((data) => {
                    setInitialAllRows(true);
                    setIsLoading(false);

                    billingTypeMappingVM.updateSelectedRows([]);
                    setShowMoveCursor(false);
                }).catch((e) => {
                    setInitialAllRows(true);
                    setIsLoading(false);

                    billingTypeMappingVM.updateSelectedRows([]);
                    setShowMoveCursor(false);
                })
            }
        })
    }, [messageBarVM, billingTypeMappingVM]);


    const handleAdd = useCallback(() => {
        billingTypeMappingVM.onAddClick();
    }, [billingTypeMappingVM]);

    const handleUpload = useCallback(async (e: any) => {

        const files = e.target?.files;
        const file = files[0];

        if (!file) {
            return;
        }
        if (file.type !== 'text/csv') {
            messageBarVM.showError('Please upload a .csv file');
            return
        }

        setIsLoading(true);
        await billingTypeMappingVM.onUpload(file).then(async res => {

            //file.current.value = null;
            e.target = null;

            if (res.success) {
                await billingTypeMappingVM.searchAllBillingTypeMappingList().then(data => {
                    setInitialAllRows(true);
                    setIsLoading(false);
                    messageBarVM.showSuccess("Success!");
                });
            } else {
                setIsLoading(false);
                messageBarVM.showError(res.data);
            }
        });
    }, [messageBarVM, billingTypeMappingVM]);

    const isApplyDisable = () => {
        if (updatedRows.length !== 0) return false
        return true;
    }

    const memoBillingTypeMappingTable = useMemo(() => {

        return (
            <>
                {(allowUpdate) && <RightClickMenuWithMoveAfter disabled={_.isEmpty(getSelectedRows())}
                    rightClickRef={rightClickRef} selectedRows={getSelectedRows()} allRows={getAllRows()}
                    onSaveMove={onSaveMove} onCancel={onMoveCancel} onRefreshRow={onRefreshRow}
                    showConfirmMove={showMoveCursor} priorityName="priority" />}
                <div id="myDiv">
                    <NbisTable
                        id='billing-type-mapping-table'
                        isNewColumnSetting={true}
                        columns={INITIAL_BILLING_TYPE_MAPPING_COL_DEF}
                        data={allRows ?? []}
                        showPaginator={false}
                        editable={false}
                        showAddIcon={false}
                        showDeleteButton={false}
                        showReloadIcon={false}
                        isScrollHighlighted={true}
                        selectionMode={true}
                        showCutCursor={showMoveCursor}
                        isRowHighligted={true}
                        onSelectionChanged={handleSelectionChange}
                        onRowDoubleClick={(e: any, entity: BillingTypeMappingEntity) => handleRowDoubleClick(entity)}
                        gridHeight="customHeight"
                        customHeight="calc(100vh - 125px)"
                        ref={gridRef}
                        rowSelection={showMoveCursor ? "single" : "multiple"}
                    />
                </div>
            </>
        );
    }, [allRows, allowUpdate, getAllRows, getSelectedRows, handleRowDoubleClick, handleSelectionChange, onMoveCancel, onRefreshRow, onSaveMove, rightClickRef, showMoveCursor])

    useEffect(() => {
        if (initialAllRows && billingTypeMappingState.billingTypeMappingList && !_.isEmpty(billingTypeMappingState.billingTypeMappingList)) {
            setAllRows(billingTypeMappingState.billingTypeMappingList.map((billingTypeMappingEntity, index) => ({
                ...billingTypeMappingEntity,
                index: index || 0,
                flag_showCutCursor: false
            })));
            setInitialAllRows(false);
        }
    }, [allRows, initialAllRows, billingTypeMappingState.billingTypeMappingList]);

    return <>
        <Sidebarheader style={{ width: '100%', display: "flex", alignItems: "center" }}>
            <HeaderTitle>{BILLING_TYPE_MAPPING_CONSTANT.TITLE}</HeaderTitle>
            {(isLoading) && <Loader Indicator="Spinner" size="Medium" />}
            <StyledAction className="tm-animated-wrapper">
                {(allowCreate) && <><NbisIconButton fileName='Icon-add' disabled={!isApplyDisable()} size='medium' toolTipText={'Add'} toolTipArrow={false} onClick={handleAdd} />
                    <div className="add-seperator" /></>}
                <FileUpload handleUpload={handleUpload} label={WorkspaceConstant.Common.BUTTON_UPLOAD} accept=".csv" size={"Small"} theme={"Primary"} disabled={!isApplyDisable()} />
            </StyledAction>
        </Sidebarheader>
        <TableWrapper>
            {(isLoading) && <Loader Indicator="Spinner" size="Medium" />}
            {memoBillingTypeMappingTable}
            {billingTypeMappingState.forceRefresh && <></>}
        </TableWrapper>
    </>;
}

export default memo(BillingTypeMappingTablePanel);
