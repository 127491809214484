import { ChargeHeaderEntity } from "domain/entity/ChargeHeader/ChargeHeaderEntity";
import { DivertShipRequestEntity } from "domain/entity/ChargeHeader/DivertShipRequestEntity";
import { SearchPreferenceEntity } from "domain/entity/Common/SearchPreferenceEntity";
import { ChargeHeaderEnabledSearchCriteria, DEFAULT_ENABLED_CHARGE_HEADER_SEARCH_CRITERIA } from "presentation/constant/ChargeHeader/ChargeHeaderEnabledSearchCriteria";
import { ChargeHeaderSearchCriteria, EMPTY_CHARGE_HEADER_SEARCH_CRITERIA } from "presentation/constant/ChargeHeader/ChargeHeaderSearchCriteria";
import { RefObject } from "react";
import { DropdownProps } from "../DropdownProps";


export interface ChargeHeaderMaintenanceDropdownOptions {
    billToCompDropdownOptions: DropdownProps[],
    billingCycleDropdownOptions: DropdownProps[],
    stateDropdownOptions: DropdownProps[],
    serviceCodeDropdownOptions: DropdownProps[],
    vesselTypeDropdownOptions: DropdownProps[],
    divertShipFromDropdownOptions: DropdownProps[],

}

export interface ChargeHeaderMaintenanceModel {
    isLoading: boolean,
    isShowRightPanel: boolean,
    isFilterSearchCriteria: boolean,
    isEditSearchCriteria: boolean,
    checkboxRef: { [key: string]: HTMLElement | null },
    enabledSearchCriteria: ChargeHeaderEnabledSearchCriteria,
    checkboxValue: ChargeHeaderEnabledSearchCriteria,
    searchCriteria: ChargeHeaderSearchCriteria,
    highlightedCheckboxKey?: string | null
    currentTableRef?: RefObject<any>,
    searchCounter: number,
    dynamicOptions: ChargeHeaderMaintenanceDropdownOptions,
    documentActive: boolean,
    isShowSaveNewConfirmModal: boolean,
    isShowDeleteConfirmModal: boolean,
    userEmail: string,
    searchPreferences: SearchPreferenceEntity<ChargeHeaderSearchCriteria, ChargeHeaderEnabledSearchCriteria>[],
    currentSearchPreference: SearchPreferenceEntity<ChargeHeaderSearchCriteria, ChargeHeaderEnabledSearchCriteria> | null,
    openedContainerNo?: string,
    isShowContainerDetail: boolean,
    isShowVesselSummary: boolean,
    isShowInboundContainerSummary: boolean,
    isShowDeliveryChargeSummary: boolean,
    isShowDivertShipPanel: boolean,
    currentChargeHeader: ChargeHeaderEntity | null,
    chargeHeaderEntities: ChargeHeaderEntity[],
    divertShipRequest: DivertShipRequestEntity,
    selectedChargeHeaderRows: ChargeHeaderEntity[],
    isShowCriteriaPanel: boolean,
    isRedirectFromChargeDetail: boolean,
    forceRefresh: boolean,
}

export const EMPTY_CHARGE_HEADER_MAINTENANCE_MODEL: ChargeHeaderMaintenanceModel = {
    isLoading: false,
    isShowRightPanel: false,
    isFilterSearchCriteria: false,
    isEditSearchCriteria: false,
    checkboxRef: {},
    enabledSearchCriteria: { ...DEFAULT_ENABLED_CHARGE_HEADER_SEARCH_CRITERIA },
    checkboxValue: { ...DEFAULT_ENABLED_CHARGE_HEADER_SEARCH_CRITERIA },
    searchCriteria: { ...EMPTY_CHARGE_HEADER_SEARCH_CRITERIA },
    searchCounter: 1,
    dynamicOptions: {
        billToCompDropdownOptions: [],
        billingCycleDropdownOptions: [],
        stateDropdownOptions: [],
        serviceCodeDropdownOptions: [],
        vesselTypeDropdownOptions: [],
        divertShipFromDropdownOptions: []
    },
    documentActive: false,
    isShowSaveNewConfirmModal: false,
    isShowDeleteConfirmModal: false,
    userEmail: "",
    searchPreferences: [],
    currentSearchPreference: null,
    isShowContainerDetail: false,
    isShowVesselSummary: false,
    isShowInboundContainerSummary: false,
    isShowDeliveryChargeSummary: false,
    isShowDivertShipPanel: false,
    currentChargeHeader: null,
    chargeHeaderEntities: [],
    divertShipRequest: {
        chargeHdrDtos: [],
        divertShipFrom: "",
    },
    selectedChargeHeaderRows: [],
    isShowCriteriaPanel: false,
    isRedirectFromChargeDetail: false,
    forceRefresh: false
}