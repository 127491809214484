import { DataMappingEntity } from "../Common/DataMappingEntity"

export interface MasterHoldEntity extends DataMappingEntity {
    id: number,
    chargeOnCompanyCode: string | null,
    billToCompanyCode: string | null,
    chargeType: string | null,
    tariffType: string | null,
    tariffCode: string | null,
    shipmentType: string | null,
    masterChargeOnCompanyCode: string | null,
    masterBillToCompanyCode: string | null,
    subChargeType: string | null,
    cntrState: string | null,
    target: string | null,
    modalityOfArrival: string | null,
    modalityOfDeparture: string | null,
    cntrStatus: string | null,
    endVisitInd: string | null

    [key: string]: string | number | Date | null | undefined | boolean
}

export const EMPTY_MASTER_HOLD_ENTITY: MasterHoldEntity = {
    id: 0,
    masterHold: "",
    masterHoldCode: null,
    fromDate: "",
    toDate: "",
    priority: 0,
    effectiveDate: null,
    expiryDate: null,
    confirmedDate: null,
    mappingType: "MasterHoldEntity",
    activeInd: "Y",
    chargeOnCompanyCode: null,
    billToCompanyCode: null,
    chargeType: null,
    tariffType: null,
    tariffCode: null,
    shipmentType: null,
    masterChargeOnCompanyCode: null,
    masterBillToCompanyCode: null,
    subChargeType: null,
    cntrState: "MH",
    target: null,
    modalityOfArrival: null,
    modalityOfDeparture: null,
    cntrStatus: null,
    endVisitInd: null
}

