import { EMPTY_GENERAL_PROPOSAL_ENTITY, GeneralProposalEntity } from "domain/entity/GeneralProposal/GeneralProposalEntity";
import { EMPTY_GEN_PROPOSAL_IS_ENTITY, GeneralProposalIsEntity } from "domain/entity/GeneralProposal/GeneralProposalIsEntity";
import { EMPTY_GENERAL_PROPOSAL_SEARCH_CRITERIA, GeneralProposalSearchCriteria } from "domain/entity/GeneralProposal/GeneralProposalSearchCriteria";
import { EMPTY_GEN_PROPOSAL_TARIFF_ITEM_TIER_ENTITY, GenProposalTariffItemTierEntity } from "domain/entity/GeneralProposal/GeneralProposalTariffItemTierEntity";
import { ShiftRepository } from "domain/repository/Common/ShiftRepo";
import { ExchangeRateRepository } from "domain/repository/ExchangeRate/ExchangeRateRepo";
import { GeneralProposalRepository } from "domain/repository/GeneralProposal/GeneralProposalRepo";
import { StandardTariffCodeRepository } from "domain/repository/TariffCode/StandardTariffCodeRepo";
import _ from "lodash";
import { DropdownProps } from "presentation/model/DropdownProps";
import { GeneralProposalModel } from "presentation/model/GeneralProposal/GeneralProposalModel";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";

interface GeneralProposalVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<GeneralProposalModel>> | ((value: SetStateAction<GeneralProposalModel>) => void),
    ],
    generalProposalRepo: GeneralProposalRepository,
    exchangeRateRepo: ExchangeRateRepository,
    standardTariffCodeRepo: StandardTariffCodeRepository,
    shiftRepo: ShiftRepository
}

export const GeneralProposalVM = ({ dispatch, generalProposalRepo, exchangeRateRepo, standardTariffCodeRepo, shiftRepo }: GeneralProposalVMProps) => {
    const [generalProposalDispatch] = dispatch;

    const onShowLoading = () => {
        generalProposalDispatch(prevState => {
            return {
                ...prevState,
                isLoading: true,
            }
        })
    }

    const onHideLoading = () => {
        generalProposalDispatch(prevState => {
            return {
                ...prevState,
                isLoading: false,
            }
        })
    }

    const updateSelectedRows = async (rows: any[]) => {
        generalProposalDispatch(prevState => {
            return {
                ...prevState,
                selectedRows: rows,
                currentSelectedRow: rows[0],
                forceRefresh: !prevState.forceRefresh
            }
        })
    }

    const updateSelectedTariffItemTierRows = async (rows: any[]) => {
        generalProposalDispatch(prevState => {
            return {
                ...prevState,
                currentTariffItemTier: rows[0],
                tariffItemTierSelectedRows: rows
            }
        })
    }

    const updateSelectedTariffItemIsRows = async (rows: any[]) => {
        generalProposalDispatch(prevState => {
            return {
                ...prevState,
                currentTariffItemIs: rows[0],
                tariffItemIsSelectedRows: rows
            }
        })
    }

    const onRowDoubleClick = (entity: GeneralProposalEntity) => {
        generalProposalDispatch(prevState => {
            return {
                ...prevState,
                isShowCriteriaPanel: false,
                isShowTariffItemTierPanel: true,
                generalProposalItemList: [],
                currentSelectedRow: entity,
                isAddTier: false,
                isEditTier: false,
                isEditIs: false,
                isAddIs: false,
                isEditingProposal: false,
                isRowUpdate: false,
                currentTariffItemTier: null,
                currentEditTariffItemTier: null,
                currentTariffItemIs: null,
                currentEditTariffItemIs: null,
                tariffItemTierSelectedRows: [],
                tariffItemIsSelectedRows: [],
                gnProposalState: {
                    ...prevState.gnProposalState,
                    isRead: true,
                }
            }
        })
    }

    const onTariffItemTierCloseClick = () => {
        generalProposalDispatch(prevState => {
            return {
                ...prevState,
                isShowTariffItemTierPanel: false,
                gnProposalState: {
                    ...prevState.gnProposalState,
                    isRead: true,
                },
                isEditTier: false,
                isEditIs: false,
                isAddIs: false,
                isAddTier: false,
                isEditingProposal: false,
                isRowUpdate: false,
            }
        })
    }


    const onGeneralProposalEidtClick = () => {
        generalProposalDispatch(prevState => {
            return {
                ...prevState,
                isEditTier: false,
                isEditIs: false,
                isAddIs: false,
                isAddTier: false,
                isEditingProposal: true,
                currentEditTariffItemTier: {
                    ...prevState.currentTariffItemTier,
                },
                gnProposalState: {
                    ...prevState.gnProposalState,
                    isRead: false,
                }
            }
        })
    }

    const onTariffItemTierEidtClick = () => {
        generalProposalDispatch(prevState => {
            return {
                ...prevState,
                isShowEditPanel: true,
                isEditTier: true,
                isEditIs: false,
                isAddIs: false,
                isAddTier: false,
                isEditingProposal: false,
                currentEditTariffItemTier: {
                    ...prevState.currentTariffItemTier,
                },
                gnProposalState: {
                    ...prevState.gnProposalState,
                    isRead: false,
                }
            }
        })
    }

    const onTariffItemISEidtClick = () => {
        generalProposalDispatch(prevState => {
            return {
                ...prevState,
                isShowEditPanel: true,
                isEditIs: true,
                isEditTier: false,
                isAddIs: false,
                isAddTier: false,
                isEditingProposal: false,
                currentEditTariffItemIs: prevState.currentTariffItemIs ?? EMPTY_GEN_PROPOSAL_IS_ENTITY,
                gnProposalState: {
                    ...prevState.gnProposalState,
                    isRead: false,
                }
            }
        })
    }

    const loadDropdownOption = async () => {

        await exchangeRateRepo.getAllCurrencies().then(
            currencies => {
                let currencyDropdownOptions = currencies?.map((currency) => ({
                    dropdownLabel: currency.currencyCode,
                    tagLabel: currency.currencyCode,
                    value: currency.currencyCode,
                })) ?? []

                generalProposalDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        currencyCodeDropdownOptions: currencyDropdownOptions,
                    }
                }))
            }
        )

        await standardTariffCodeRepo.getAllStandardTariffCodes().then(
            tariffs => {

                let newTariffs = _.orderBy(tariffs, ["tariffType", "tariffCode"]);
                let tariffTypeDropdownOptions: DropdownProps[] = [];
                let tariffCodeDropdownOptions: { [key: string]: DropdownProps[] } = {};

                newTariffs.forEach(tariff => {
                    const isTariffTypeExisted = tariffTypeDropdownOptions.find(t =>
                        t.value === tariff.tariffType);
                    if (!isTariffTypeExisted) {
                        tariffTypeDropdownOptions.push({
                            dropdownLabel: tariff.tariffType,
                            tagLabel: tariff.tariffType,
                            value: tariff.tariffType,
                        })
                    }

                    if (tariff.tariffCode) {
                        if (!tariffCodeDropdownOptions[tariff.tariffType]) {
                            tariffCodeDropdownOptions[tariff.tariffType] = [];
                        }
                        tariffCodeDropdownOptions[tariff.tariffType].push({
                            dropdownLabel: tariff.tariffCode,
                            tagLabel: tariff.tariffCode,
                            value: tariff.tariffCode,
                        })
                    }
                })

                generalProposalDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        tariffTypeDropdownOptions: tariffTypeDropdownOptions,
                        tariffCodeDropdownOptions: tariffCodeDropdownOptions,
                    }
                }))
            }
        )

        await shiftRepo.getShiftByType('INTENSIVE').then(
            shiftCodes => {

                let newShifts = _.orderBy(shiftCodes, ["shiftCode"]);
                let shiftCodeDropdownOptions: DropdownProps[] = [];
                newShifts.forEach(shift => {
                    const isShiftCodeExisted = shiftCodeDropdownOptions.find(t =>
                        t.value === shift.shiftCode);
                    if (!isShiftCodeExisted) {
                        shiftCodeDropdownOptions.push({
                            dropdownLabel: shift.shiftCode,
                            tagLabel: shift.shiftCode,
                            value: shift.shiftCode,
                        })
                    }
                })

                generalProposalDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        shiftCodeDropdownOptions: shiftCodeDropdownOptions,
                    }
                }))
            }
        )
    }

    const onSearchCriteriaResetClick = () => {
        generalProposalDispatch(prevState => {
            return {
                ...prevState,
                searchCriteria: EMPTY_GENERAL_PROPOSAL_SEARCH_CRITERIA
            }
        })
    }

    const onSearchClick = async () => {
        generalProposalDispatch(prevState => {
            return {
                ...prevState,
                isShowCriteriaPanel: !prevState.isShowCriteriaPanel
            }
        });
    }


    const searchAllGeneralProposal = async () => {
        let newSearchCriteria = { ...EMPTY_GENERAL_PROPOSAL_SEARCH_CRITERIA }
        await generalProposalRepo.searchGeneralProposal(newSearchCriteria).then((data) => {
            if (data && data.length > 0) {
                let isRejectedCount = 0;
                let isPendingApprovalCount = 0;
                // let isAllCount = 0;
                // isAllCount = data.length;
                isRejectedCount = data.filter(entity => entity.status === "REJECTED").length;
                isPendingApprovalCount = data.filter(entity => entity.status === "FINALIZED" && !entity.confirmedDate).length;
                const newData = data.map(entity => ({
                    ...entity,
                }));
                generalProposalDispatch(prevState => {
                    return {
                        ...prevState,
                        generalProposalAllList: newData,
                        // isAllCount: isAllCount,
                        isPendingApprovalCount: isPendingApprovalCount,
                        isRejectedCount: isRejectedCount,
                        selectedRows: [],
                        isAllowAutoSearch: false,
                    }
                })
            } else {
                generalProposalDispatch(prevState => {
                    return {
                        ...prevState,
                        generalProposalAllList: [],
                        isPendingApproval: false,
                        isRejected: false,
                        // isAll: false,
                        isPendingApprovalCount: 0,
                        isRejectedCount: 0,
                        // isAllCount: 0,
                    }
                })
            }
        }).catch((error) => {
            return [];
        })
    }

    const searchGeneralProposal = async (searchCriteria: GeneralProposalSearchCriteria, lastEditRowId: string) => {
        generalProposalDispatch(prevState => {
            return {
                ...prevState,
                gnProposalState: {
                    ...prevState.gnProposalState,
                    lastEditRowId: lastEditRowId,
                },
                selectedRows: [],
                generalProposalList: [],
                isPendingApproval: false,
                isRejected: false,
                isShowTariffItemTierPanel: false,
                generalProposalItemList: [],
                currentSelectedRow: { ...EMPTY_GENERAL_PROPOSAL_ENTITY }
                // isAll: false,
            }
        })
        await searchAllGeneralProposal();
        await generalProposalRepo.searchGeneralProposal(searchCriteria).then((data) => {
            if (data) {
                data = _.orderBy(data, ["companyCode", "proposalNo"], ['asc', 'asc']);
                const newData = data.map(entity => ({
                    ...entity,
                }));
                generalProposalDispatch(prevState => {
                    return {
                        ...prevState,
                        generalProposalList: newData,
                        searchCriteria: searchCriteria,
                        selectedRows: [],
                        isAllowAutoSearch: false,
                        searchStatus: ''
                    }
                })
            } else {
                generalProposalDispatch(prevState => {
                    return {
                        ...prevState,
                        generalProposalList: [],
                        searchCriteria: searchCriteria,
                        selectedRows: [],
                        isAllowAutoSearch: false,
                        searchStatus: ''
                    }
                })
            }
        }).catch((error) => {
            return [];
        })

    }

    const onSearchInputTextChange = (e: ChangeEvent<HTMLInputElement>, fieldName: string) => {
        generalProposalDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: e.target.value,
            }
        }))
    };

    const onMultipleDropdownChange = (e: any, fieldName: string) => {
        generalProposalDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: _.uniq(e?.map((item: DropdownProps) => item.value)),
            }
        }));
    };

    const onDropdownChange = (e: any, fieldName: string) => {
        generalProposalDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: e?.value ?? (_.isEmpty(e) ?
                    (fieldName === "tariffCodeList" ? [] : '') : e),
            }
        }))
    }


    const onDateRangeChange = (startDate: any, endDate: any, dateFields: { startField: string, endField: string }) => {
        generalProposalDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [dateFields.startField]: startDate,
                [dateFields.endField]: endDate,
            }
        }))
    }

    const onGroupButtonClick = async (buttonId: string, lastEditRowId: string) => {
        generalProposalDispatch(prevState => {
            return {
                ...prevState,
                gnProposalState: {
                    ...prevState.gnProposalState,
                    lastEditRowId: lastEditRowId,
                },
                selectedRows: [],
                generalProposalList: [],
                isShowTariffItemTierPanel: false,
                generalProposalItemList: [],
                currentSelectedRow: { ...EMPTY_GENERAL_PROPOSAL_ENTITY }
            }
        })
        let isPendingApproval = false;
        let isRejected = false;
        // let isAll = false;
        let status = "";
        switch (buttonId) {
            case 'isPendingApproval':
                isPendingApproval = true;
                isRejected = false;
                // isAll = false;
                status = "FINALIZED";
                break;
            case 'isRejected':
                // isAll = false;
                isPendingApproval = false;
                isRejected = true;
                status = "REJECTED";
                break;
            // case 'isAll':  
            //     isAll = true;
            //     isPendingApproval = false;
            //     isRejected = false;
            //     status = "ALL";
            //     break;  
            default:
        }
        await searchAllGeneralProposal().then((data) => {
            generalProposalDispatch(prevState => {
                let newList: GeneralProposalEntity[] = [];
                if (status !== "") {
                    if (status === "FINALIZED") {
                        newList = prevState.generalProposalAllList.filter(entity => entity.status === "FINALIZED" && !entity.confirmedDate);
                    } else {
                        newList = prevState.generalProposalAllList.filter(entity => entity.status === status);
                    }
                    newList = _.orderBy(newList, ["companyCode", "proposalNo"], ['asc', 'asc']);
                }
                return {
                    ...prevState,
                    selectedRows: [],
                    // isAll: isAll,
                    isPendingApproval: isPendingApproval,
                    isRejected: isRejected,
                    generalProposalList: newList,
                    searchStatus: buttonId,
                    isAllowAutoSearch: false
                }
            })
        })
    }

    const onDeleteClick = async (entities: GeneralProposalEntity[]) => {
        return await generalProposalRepo.deleteGNProposal(entities.map(entity => entity.key));
    }

    const onSubmit = async (selectedRows: GeneralProposalEntity[]) => {
        return await generalProposalRepo.submitGeneralProposal(selectedRows);
    }

    const onUnconfirm = async (selectedRows: GeneralProposalEntity[]) => {
        return await generalProposalRepo.unconfirmGeneralProposal(selectedRows);
    }

    const onRenew = async (searchStatus: string, searchCriteria: GeneralProposalSearchCriteria, selectedRows: GeneralProposalEntity[]) => {
        let msg = "success";
        await generalProposalRepo.renewGeneralProposal(selectedRows.map(entity => Number(entity.key))).then(async (data) => {
            if (data && _.isArray(data)) {
                if (searchStatus !== "") {
                    await onGroupButtonClick(searchStatus, data[0].toString());
                } else {
                    await searchGeneralProposal(searchCriteria, data[0].toString());
                }
            } else {
                msg = data.toString();
            }
        })
        return msg;
    }

    const onTextAreaChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        return generalProposalDispatch(prevState => {
            return {
                ...prevState,
                [fieldKey]: val
            }
        });
    }

    const closeRejectReasonModal = () => {
        generalProposalDispatch(prevState => ({
            ...prevState,
            isShowRejectPad: false,
            isRejecting: true,
        }))
    };
    const cancelRejectReasonModal = () => {
        generalProposalDispatch(prevState => ({
            ...prevState,
            isShowRejectPad: false,
            isRejecting: false,
        }))
    };

    const onRejectClick = () => {
        generalProposalDispatch(prevState => ({
            ...prevState,
            isShowRejectPad: true,
        }))
    };
    const onReject = async (searchCriteria: GeneralProposalSearchCriteria, searchStatus: string, selectedRow: GeneralProposalEntity) => {
        closeRejectReasonModal();
        await generalProposalRepo.onRejectGeneralProposal(selectedRow).then(async (data) => {
            if (searchStatus !== "") {
                onGroupButtonClick(searchStatus, "");
            } else {
                await searchGeneralProposal(searchCriteria, "");
            }
            generalProposalDispatch(prevState => ({
                ...prevState,
                isRejecting: false,
                rejectReason: ""
            }))
        });
    };

    const onApprove = async (selectedRows: GeneralProposalEntity[]) => {
        return await generalProposalRepo.approveGeneralProposal(selectedRows);
    }

    const onAddClick = () => {
        generalProposalDispatch(prevState => ({
            ...prevState,
            isShowDetail: true,
        }))
    };

    const searchGeneralProposalItem = async (entity: GeneralProposalEntity) => {
        if (!entity.key) {
            return;
        }
        await generalProposalRepo.searchGeneralProposal({
            id: entity.key
        }).then((data) => {
            if (!_.isEmpty(data)) {
                generalProposalDispatch(prevState => {
                    return {
                        ...prevState,
                        generalProposalItemTierList: data[0].tarItemTierList?.map(e => ({ ...e, tempKey: _.uniqueId() })),
                        generalProposalItemIsList: data[0].tarItemIsList?.map(e => ({ ...e, tempKey: _.uniqueId() })),
                        tariffItemTierSelectedRows: [],
                        isAddIs: false,
                        isEditIs: false,
                        isAddTier: false,
                        isEditTier: false,
                        isEditingProposal: false,
                        isRowUpdate: false,
                    }
                })
            }
        })
    }

    const onHeaderSingleDropdownChange = (e: any, fieldName: string) => {
        generalProposalDispatch(prevState => {
            return {
                ...prevState,
                currentSelectedRow: {
                    ...prevState.currentSelectedRow,
                    [fieldName]: e.value,
                }
            }
        });
    };

    const onHeaderMultipleDropdownChange = (e: any, fieldName: string) => {
        generalProposalDispatch(prevState => ({
            ...prevState,
            currentSelectedRow: {
                ...prevState.currentSelectedRow,
                [fieldName]: _.uniq(e?.map((item: DropdownProps) => item.value)),
            }
        }));
    };

    const onHeaderFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        generalProposalDispatch(prevState => {
            return {
                ...prevState,
                currentSelectedRow: {
                    ...prevState.currentSelectedRow,
                    [fieldKey]: val
                },
            }
        })
    }

    const onTierFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        generalProposalDispatch(prevState => {
            if (prevState.currentEditTariffItemTier && (fieldKey === 'cycle' || fieldKey === 'forevery')) {
                var posIntReg = /^\d*$/;
                if (!posIntReg.test(val)) {
                    val = prevState.currentEditTariffItemTier[fieldKey];
                }
            }

            return {
                ...prevState,
                currentEditTariffItemTier: {
                    ...prevState.currentEditTariffItemTier,
                    [fieldKey]: val
                },
            }
        })
    }

    const onIsFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        generalProposalDispatch(prevState => {
            return {
                ...prevState,
                currentEditTariffItemIs: {
                    ...prevState.currentEditTariffItemIs,
                    [fieldKey]: val
                },
            }
        })
    }

    const onHeaderDateRangeChange = (startDate: any, endDate: any, dateFields: { startField: string, endField: string }) => {
        generalProposalDispatch(prevState => ({
            ...prevState,
            currentSelectedRow: {
                ...prevState.currentSelectedRow,
                [dateFields.startField]: startDate,
                [dateFields.endField]: endDate,
            }
        }))
    }

    const onHeaderTextAreaChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        return generalProposalDispatch(prevState => {
            return {
                ...prevState,
                currentSelectedRow: {
                    ...prevState.currentSelectedRow,
                    [fieldKey]: val,
                }
            }
        });
    }

    const cancelAddOrEdit = () => {
        return generalProposalDispatch(prevState => {
            return {
                ...prevState,
                isShowEditPanel: false,
                isAddIs: false,
                isEditIs: false,
                isAddTier: false,
                isEditTier: false,
                // isEditingProposal: false,
                currentTariffItemTier: prevState.currentTariffItemTier,
                currentTariffItemIs: prevState.currentTariffItemIs
            }
        });
    }


    const onAddIs = () => {
        return generalProposalDispatch(prevState => {
            return {
                ...prevState,
                isShowEditPanel: true,
                isAddIs: true,
                isEditIs: false,
                isAddTier: false,
                isEditTier: false,
                isEditingProposal: false,
                currentEditTariffItemIs: {
                    ...EMPTY_GEN_PROPOSAL_IS_ENTITY,
                    tempKey: _.uniqueId(),
                }
            }
        });
    }

    const onAddTier = () => {
        return generalProposalDispatch(prevState => {
            return {
                ...prevState,
                isShowEditPanel: true,
                isAddIs: false,
                isEditIs: false,
                isAddTier: true,
                isEditTier: false,
                isEditingProposal: false,
                currentEditTariffItemTier: {
                    ...EMPTY_GEN_PROPOSAL_TARIFF_ITEM_TIER_ENTITY,
                    tierNo: _.isEmpty(prevState.generalProposalItemTierList) ? 1 : prevState.generalProposalItemTierList.length + 1,
                    tempKey: _.uniqueId(),
                }
            }
        });
    }

    const onTempSaveClick = (fieldName: string, currentEditing: GenProposalTariffItemTierEntity | GeneralProposalIsEntity | null) => {
        if (currentEditing === null) return;
        return generalProposalDispatch(prevState => {
            const origList = prevState[fieldName] ?? [];
            let newList = origList.filter((e: { tempKey: string; }) => (e.tempKey !== currentEditing.tempKey));
            newList.push(currentEditing);
            return {
                ...prevState,
                [fieldName]: _.sortBy(newList, "tierNo"),
                isRowUpdate: true,
                isAddIs: false,
                isEditIs: false,
                isAddTier: false,
                isEditTier: false,
                isShowEditPanel: false,
                currentTariffItemTier: (fieldName === "generalProposalItemTierList"?currentEditing:prevState.currentTariffItemTier),
                currentTariffItemIs: (fieldName === "generalProposalItemIsList"?currentEditing:prevState.currentTariffItemIs),
                currentEditTariffItemIs: { ...EMPTY_GEN_PROPOSAL_IS_ENTITY },
                currentEditTariffItemTier: { ...EMPTY_GEN_PROPOSAL_TARIFF_ITEM_TIER_ENTITY },
                /*tariffItemTierSelectedRows: [],
                tariffItemIsSelectedRows: [],*/
            }
        });
    }

    const onSaveAll = async (gnPropsal: GeneralProposalEntity, generalProposalItemTierList: GenProposalTariffItemTierEntity[], generalProposalItemIsList: GeneralProposalIsEntity[]) => {
        await generalProposalRepo.saveProposalItemDetail({
            ...gnPropsal,
            tarItemTierList: generalProposalItemTierList,
            tarItemIsList: generalProposalItemIsList,
        }).then((res) => {

            return generalProposalDispatch(prevState => {
                return {
                    ...prevState,
                    isRowUpdate: false,
                    isAddIs: false,
                    isEditIs: false,
                    isAddTier: false,
                    isEditTier: false,
                    isEditingProposal: false,
                    currentTariffItemTier: { ...EMPTY_GEN_PROPOSAL_TARIFF_ITEM_TIER_ENTITY },
                    currentTariffItemIs: { ...EMPTY_GEN_PROPOSAL_IS_ENTITY },
                    currentEditTariffItemIs: { ...EMPTY_GEN_PROPOSAL_IS_ENTITY },
                    currentEditTariffItemTier: { ...EMPTY_GEN_PROPOSAL_TARIFF_ITEM_TIER_ENTITY },
                    tariffItemTierSelectedRows: [],
                    tariffItemIsSelectedRows: [],
                    gnProposalState: {
                        ...prevState.gnProposalState,
                        isRead: true,
                    }
                }
            });

        })
    }
    const saveGeneralProposal = async (gnPropsal: GeneralProposalEntity) => {
        await generalProposalRepo.saveGeneralProposal(gnPropsal).then((res) => {

            return generalProposalDispatch(prevState => {
                return {
                    ...prevState,
                    isRowUpdate: false,
                    isAddIs: false,
                    isEditIs: false,
                    isAddTier: false,
                    isEditTier: false,
                    isEditingProposal: false,
                    currentTariffItemTier: { ...EMPTY_GEN_PROPOSAL_TARIFF_ITEM_TIER_ENTITY },
                    currentTariffItemIs: { ...EMPTY_GEN_PROPOSAL_IS_ENTITY },
                    currentEditTariffItemIs: { ...EMPTY_GEN_PROPOSAL_IS_ENTITY },
                    currentEditTariffItemTier: { ...EMPTY_GEN_PROPOSAL_TARIFF_ITEM_TIER_ENTITY },
                    tariffItemTierSelectedRows: [],
                    tariffItemIsSelectedRows: [],
                    gnProposalState: {
                        ...prevState.gnProposalState,
                        isRead: true,
                    }
                }
            });

        })
    }

    const onDeleteTier = async () => {
        return generalProposalDispatch(prevState => {
            const delTierKeys = prevState.tariffItemTierSelectedRows.map(t => t.tempKey);

            const retainTierList = prevState.generalProposalItemTierList
                .filter(t => !delTierKeys.includes(t.tempKey))
                .map((t, i) => ({ ...t, tierNo: i + 1 }))

            return {
                ...prevState,
                generalProposalItemTierList: retainTierList,
                isRowUpdate: true,
            }
        });
    }

    const onDeleteIs = async () => {
        return generalProposalDispatch(prevState => {
            const delIsKeys = prevState.tariffItemIsSelectedRows.map(t => t.tempKey);

            const retainIsList = prevState.generalProposalItemIsList
                .filter(t => !delIsKeys.includes(t.tempKey))

            return {
                ...prevState,
                deleteIsList: prevState.tariffItemIsSelectedRows.filter(t => !t.newRecord),
                generalProposalItemIsList: retainIsList,
                isRowUpdate: true,
            }
        });
    }





    return {
        saveGeneralProposal: saveGeneralProposal,
        onGeneralProposalEidtClick: onGeneralProposalEidtClick,
        onHeaderMultipleDropdownChange: onHeaderMultipleDropdownChange,
        onHeaderSingleDropdownChange: onHeaderSingleDropdownChange,
        onHeaderTextAreaChange: onHeaderTextAreaChange,
        onHeaderDateRangeChange: onHeaderDateRangeChange,
        onHeaderFieldChange: onHeaderFieldChange,
        onTierFieldChange: onTierFieldChange,
        onIsFieldChange: onIsFieldChange,
        loadDropdownOption: loadDropdownOption,
        onShowLoading: onShowLoading,
        onHideLoading: onHideLoading,
        onRowDoubleClick: onRowDoubleClick,
        updateSelectedRows: updateSelectedRows,
        onSearchCriteriaResetClick: onSearchCriteriaResetClick,
        onSearchClick: onSearchClick,
        searchGeneralProposal: searchGeneralProposal,
        searchAllGeneralProposal: searchAllGeneralProposal,
        onSearchInputTextChange: onSearchInputTextChange,
        onMultipleDropdownChange: onMultipleDropdownChange,
        onDropdownChange: onDropdownChange,
        onDateRangeChange: onDateRangeChange,
        onGroupButtonClick: onGroupButtonClick,
        onDeleteClick: onDeleteClick,
        onSubmit: onSubmit,
        onUnconfirm: onUnconfirm,
        onRenew: onRenew,
        onTextAreaChange: onTextAreaChange,
        closeRejectReasonModal: closeRejectReasonModal,
        cancelRejectReasonModal: cancelRejectReasonModal,
        onRejectClick: onRejectClick,
        onReject: onReject,
        onApprove: onApprove,
        onAddClick: onAddClick,
        searchGeneralProposalItem: searchGeneralProposalItem,
        onTariffItemTierCloseClick: onTariffItemTierCloseClick,
        onTariffItemTierEidtClick: onTariffItemTierEidtClick,
        onTariffItemISEidtClick: onTariffItemISEidtClick,
        updateSelectedTariffItemTierRows: updateSelectedTariffItemTierRows,
        updateSelectedTariffItemIsRows: updateSelectedTariffItemIsRows,
        onSaveAll: onSaveAll,
        cancelAddOrEdit: cancelAddOrEdit,
        onAddIs: onAddIs,
        onAddTier: onAddTier,
        onTempSaveClick: onTempSaveClick,
        onDeleteTier: onDeleteTier,
        onDeleteIs: onDeleteIs,
    }
}