/* eslint-disable import/extensions */
import { ColorPicker } from 'primereact/colorpicker';
import { InputText } from 'primereact/inputtext';
import styled, { createGlobalStyle, css } from 'styled-components';
import { ColorPickerProps } from 'veronica-ui-component/dist/component/core/ColorPicker/ColorPicker';
import { Background9, HPHGreyDark, HPHGreyLight, HPHRed, HPHSeaBlue, HPHSkyBlue, HPHWhite, HPHWhiteSmoke, MontserratFont } from 'veronica-ui-component/dist/component/core/Colors';
import { NbisInputFieldProps } from '../NbisInputField';

const isCrossIcon = (props: any) => {
  if (props.crossIcon) {
    return css`
      padding-right: 22px;
    `;
  }
};

export const StyledInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  .Icon-cross {
    position: absolute;
    bottom: 1px;
    right: 0;
    height: 30px;
    display: flex;
    background: ${HPHWhite};
    align-items: center;
    > div {
      padding: 2px;
    }
  }
  .p-inputtext:enabled:focus {
    outline: none;
    box-shadow: none;
    border-color: ${HPHSkyBlue};
  }
  .p-inputtext {
    &:enabled:hover {
      border-color: ${HPHSkyBlue};
    }
  }
  .p-inputtext:disabled {
    opacity: 1;
  }
`;

export const StyledOptional = styled.div`
  margin-left: 2px
  span {
    background: rgb(245, 245, 245);
    color: rgb(180, 180, 180);
    font-size: 8px;
    font-weight: 600;
    letter-spacing: 0.025rem;
  }
`;

export const StyledRequired = styled.em`
  color: ${HPHRed};
  font-style: initial;
  margin-left: 2px;
`;
export const StyledInputLabel = styled.label`
  color: ${HPHSeaBlue};
  display: block;
  font-family: ${MontserratFont};
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1.2;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  button {
    margin-left: 3px;
    > div: first-child {
      padding: 0;
      span {
        width: 14px;
        height: 14px;
        opacity: 0.6;
      }
    }
  }
`;

export const StyledInputText = styled(InputText) <NbisInputFieldProps>`
  &.p-inputtext {
    position: relative;
    border: 0;
    border-bottom: 1px solid ${HPHGreyDark};
    border-radius: 0;
    background-color: ${HPHWhite};
    color: ${HPHGreyDark};
    font-size: 1rem;
    font-weight: 400;
    font-family: ${MontserratFont};
    padding: 0.375rem 0;
    height: 31px;
    @media (max-width: 1600px) and (min-width: 1281px) {
      font-size: 0.875rem;
    }
    @media (max-width: 1280px) and (min-width: 1201px) {
      font-size: 0.75rem;
    }
    @media (max-width: 1200px) and (min-width: 1025px) {
      font-size: 0.875rem;
    }
    @media (max-width: 1024px) and (min-width: 961px) {
      font-size: 0.75rem;
    }
    @media (max-width: 960px) and (min-width: 769px) {
      font-size: 1rem;
    }
    @media (max-width: 768px) {
      font-size: 0.875rem;
    }
    ${isCrossIcon}
  }
  &::-webkit-input-placeholder {
    color: ${HPHGreyLight};
    font-size: 1rem;
    font-weight: 400;
    @media (max-width: 1600px) and (min-width: 1281px) {
      font-size: 0.875rem;
    }
    @media (max-width: 1280px) and (min-width: 1201px) {
      font-size: 0.75rem;
    }
    @media (max-width: 1200px) and (min-width: 1025px) {
      font-size: 0.875rem;
    }
    @media (max-width: 1024px) and (min-width: 961px) {
      font-size: 0.75rem;
    }
    @media (max-width: 960px) and (min-width: 769px) {
      font-size: 1rem;
    }
    @media (max-width: 768px) {
      font-size: 0.875rem;
    }
  }
  &:-ms-input-placeholder {
    color: ${HPHGreyLight};
    font-size: 1rem;
    font-weight: 400;
    @media (max-width: 1600px) and (min-width: 1281px) {
      font-size: 0.875rem;
    }
    @media (max-width: 1280px) and (min-width: 1201px) {
      font-size: 0.75rem;
    }
    @media (max-width: 1200px) and (min-width: 1025px) {
      font-size: 0.875rem;
    }
    @media (max-width: 1024px) and (min-width: 961px) {
      font-size: 0.75rem;
    }
    @media (max-width: 960px) and (min-width: 769px) {
      font-size: 1rem;
    }
    @media (max-width: 768px) {
      font-size: 0.875rem;
    }
  }
  &::placeholder {
    color: ${HPHGreyLight};
    font-size: 1rem;
    font-weight: 400;
    @media (max-width: 1600px) and (min-width: 1281px) {
      font-size: 0.875rem;
    }
    @media (max-width: 1280px) and (min-width: 1201px) {
      font-size: 0.75rem;
    }
    @media (max-width: 1200px) and (min-width: 1025px) {
      font-size: 0.875rem;
    }
    @media (max-width: 1024px) and (min-width: 961px) {
      font-size: 0.75rem;
    }
    @media (max-width: 960px) and (min-width: 769px) {
      font-size: 1rem;
    }
    @media (max-width: 768px) {
      font-size: 0.875rem;
    }
  }
  &:disabled {
    border-color: ${HPHGreyLight};
    color: ${HPHGreyLight};
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
`;

export const StyledInputErrorMsg = styled.span`
  color: ${HPHRed};
  display: block;
  font-family: ${MontserratFont};
  font-size: 0.875rem;
  font-style: italic;
  font-weight: 400;
  line-height: 1.2;
  margin-top: 0.25rem;
  position: absolute;
  top: 100%;
  white-space: nowrap;
`;

export const StyledColorPickerWrapper = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0.5rem 0;
  padding: 0;
`;

export const StyledColorPicker = styled(ColorPicker) <ColorPickerProps>`
  padding: 0;
  border: 0;
  width: 35px;
  height: 35px;
  cursor: pointer;
  position: relative;
  top: -5px;
  left: -5px;
  font-family: ${MontserratFont};
  &:disabled {
    pointer-events: none;
  }
`;

interface StyledColorPickerGlobal {
  [x: string]: any;
}

export const ColorPickerGlobalStyles = createGlobalStyle<StyledColorPickerGlobal>`
  .p-colorpicker-overlay-panel {
    height: 205px !important;
    border: 1px solid ${HPHWhiteSmoke} !important;
    box-shadow: 0 0 3px -1px rgb(109 140 171) !important;
    background: ${Background9} !important;
  }
  .p-colorpicker-overlay-panel.p-disabled {
    opacity: 1 !important;
  }
  .p-colorpicker-footer {
    position: absolute;
    bottom: 0;
    padding-bottom: 5px;
    #color-input {
      font-family: ${MontserratFont};
      background: transparent;
      border: none;
      outline: none;
      border-bottom: 1px solid ${HPHGreyDark};
      margin-bottom: 5px;
      margin-left: 8px;
      width: 150px;
      font-size: 0.875rem;
      color: ${HPHGreyDark};
      font-weight: 500;
      padding: 0.375rem 0 0.187rem;
      &::placeholder {
        color: ${HPHGreyLight};
        font-size: 0.875rem;
        font-weight:400;  
      }
      &:enabled:focus{
        border-color:${HPHSkyBlue};
      }
    }
  }
`;
