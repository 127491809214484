import { referenceTypeCheckboxOption } from "presentation/constant/CheckBox/StaticCheckboxOptions";
import { RequisitionFormConstant } from "presentation/constant/RequisitionForm/RequisitionFormConstant";
import { useRequisitionFormVM } from "presentation/hook/RequisitionForm/useRequisitionFormVM";
import { useRequisitionFormMaintenanceTracked } from "presentation/store/RequisitionForm/RequisitionFormMaintenanceProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import NumberInputComponent from "presentation/view/components/NumberInputComponent";
import { useCallback, useMemo, useState } from "react";
import { FieldType, GroupCheckboxList, HPHCheckbox, HPHGroupCheckbox, IFieldValue, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarCaption, SidebarTitle, StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import RequisitionFormEditPanelTitleBar from "../RequisitionFormEditPanelTitleBar";

export const RequisitionFormHeaderFormPanel = () => {
    const [requisitionFormState] = useRequisitionFormMaintenanceTracked();
    const requisitionFormVM = useRequisitionFormVM();

    const REQ_FORM_CONSTANT = RequisitionFormConstant;
    const [isLoading] = useState(false);

    const { currentRequisitionForm, masterState, dynamicOptions } = requisitionFormState;
    const { editingEntity, isAdd, isEditable, isRead, isSaveClicked, allFormState } = masterState;

    const currentEntity = isRead ? currentRequisitionForm : editingEntity;

    const isInv = useCallback(() => {
        if(currentEntity.state === "INV") return true;
        return false;
    }, [currentEntity.state]);

    const onNumberFieldChange = useCallback((e: any, fieldName: string, fieldValue: any) => {
        requisitionFormVM.onFieldChange(fieldName, fieldValue);
    }, [requisitionFormVM]);

    const memoState = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "120px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                readOnlyValue={currentEntity.state ?? ""}
                fieldValue={currentEntity.state ?? ""}
                fieldLabel={REQ_FORM_CONSTANT.COMMON.STATE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'state'}
                isShowMissingError={true}
                maxLength={10}
                requiredFieldList={[]}
                onFieldChange={()=>{}} />
        </div>
        , [allFormState, currentEntity.state, REQ_FORM_CONSTANT.COMMON.STATE, isSaveClicked])

    const memoReqNature = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isInv()}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={dynamicOptions.reqNatureDropdownOptions?.find(item => item.tagLabel === currentEntity.reqNature)?.value ?? ""}
                fieldValue={dynamicOptions.reqNatureDropdownOptions?.find(item => item.tagLabel === currentEntity.reqNature)?.value ?? ""}
                fieldLabel={REQ_FORM_CONSTANT.COMMON.REQ_NATURE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'reqNature'}
                isShowMissingError={true}
                maxLength={10}
                options={dynamicOptions.reqNatureDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    requisitionFormVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, isInv, dynamicOptions.reqNatureDropdownOptions, REQ_FORM_CONSTANT.COMMON.REQ_NATURE, isSaveClicked, currentEntity.reqNature, requisitionFormVM])

    const memoReqMode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!isAdd}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={dynamicOptions.reqModeDropdownOptions?.find(item => item.tagLabel === currentEntity.reqMode)?.value ?? ""}
                fieldValue={dynamicOptions.reqModeDropdownOptions?.find(item => item.tagLabel === currentEntity.reqMode)?.value ?? ""}
                allValues={dynamicOptions.reqModeDropdownOptions?.find(item => item.tagLabel === currentEntity.reqMode)?.value ?? ""}
                fieldLabel={REQ_FORM_CONSTANT.COMMON.REQ_MODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'reqMode'}
                isShowMissingError={true}
                maxLength={10}
                options={dynamicOptions.reqModeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    requisitionFormVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isAdd, dynamicOptions.reqModeDropdownOptions, REQ_FORM_CONSTANT.COMMON.REQ_MODE, isSaveClicked, currentEntity.reqMode, requisitionFormVM])

    const memoRevenCtrlInd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>
            <NbisCommonField
                fieldKey={"revenueControlInd"}
                requiredFieldList={[]}
                isShowOptional={true}
                fieldLabel={REQ_FORM_CONSTANT.COMMON.REVENUE_CTRL_IND}
                fieldType={FieldType.TEXT}
                isReadOnly={true}
                onFieldChange={() => { }} />
            <HPHCheckbox
                errorMessages={allFormState}
                label={''}
                checked={currentEntity?.revenueControlInd === 'Y' ? true : false}
                disabled={isRead || isInv()}
                onChange={(e) => requisitionFormVM.onCheckboxChange(e.checked, "revenueControlInd")}
            />
        </div>
        , [REQ_FORM_CONSTANT.COMMON.REVENUE_CTRL_IND, allFormState, currentEntity?.revenueControlInd, isInv, isRead, requisitionFormVM])

    const memoCurrency = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || (isEditable && currentEntity.reqMode === "AN") || isInv()}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={currentEntity.currencyCode ?? ""}
                fieldValue={currentEntity.currencyCode ?? ""}
                fieldLabel={REQ_FORM_CONSTANT.COMMON.CURRENCY}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'currencyCode'}
                isShowMissingError={true}
                maxLength={10}
                options={dynamicOptions.currencyDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    requisitionFormVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, isEditable, currentEntity.reqMode, currentEntity.currencyCode, isInv, REQ_FORM_CONSTANT.COMMON.CURRENCY, isSaveClicked, dynamicOptions.currencyDropdownOptions, requisitionFormVM])

    const memoTotalAmount = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "120px", marginBottom: "24px" }}>
            <NumberInputComponent
                label={REQ_FORM_CONSTANT.Detail.TOTAL_AMOUNT}
                maxLength={60}
                disabled={isRead || currentEntity.reqMode === "AN" || isInv()}
                width='120px'
                fieldName={"amount"}
                value={currentEntity?.amount ?? ''}
                errorMessage={allFormState?.amount}
                decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
        </div>
        , [REQ_FORM_CONSTANT.Detail.TOTAL_AMOUNT, isRead, currentEntity.reqMode, currentEntity?.amount, isInv, allFormState?.amount, onNumberFieldChange])

    const memoExplanation1 = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "330px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isInv()}
                isShowOptional={false}                
                readOnlyValue={currentEntity.explanation1 ?? ""}
                fieldValue={currentEntity.explanation1 ?? ""}
                allValues={currentEntity.explanation1 ?? ""}
                fieldLabel={REQ_FORM_CONSTANT.Detail.EXPLANATION_1}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'explanation1'}
                isShowMissingError={true}
                maxLength={200}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    requisitionFormVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, isInv, currentEntity.explanation1, REQ_FORM_CONSTANT.Detail.EXPLANATION_1, isSaveClicked, requisitionFormVM])

    const memoExplanation2 = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "330px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isInv()}
                isShowOptional={true}
                isMultipleDropDown={false}
                readOnlyValue={currentEntity.explanation2 ?? ""}
                fieldValue={currentEntity.explanation2 ?? ""}
                allValues={currentEntity.explanation2 ?? ""}
                fieldLabel={REQ_FORM_CONSTANT.Detail.EXPLANATION_2}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'explanation2'}
                isShowMissingError={true}
                maxLength={200}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    requisitionFormVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, isInv, currentEntity.explanation2, REQ_FORM_CONSTANT.Detail.EXPLANATION_2, isSaveClicked, requisitionFormVM])

    const memoExplanation3 = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "330px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isInv()}
                isShowOptional={true}                
                readOnlyValue={currentEntity.explanation3 ?? ""}
                fieldValue={currentEntity.explanation3 ?? ""}
                allValues={currentEntity.explanation3 ?? ""}
                fieldLabel={REQ_FORM_CONSTANT.Detail.EXPLANATION_3}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'explanation3'}
                isShowMissingError={true}
                maxLength={200}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                        requisitionFormVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, isInv, currentEntity.explanation3, REQ_FORM_CONSTANT.Detail.EXPLANATION_3, isSaveClicked, requisitionFormVM])

    const memoExplanation4 = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "330px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isInv()}
                isShowOptional={true}                
                readOnlyValue={currentEntity.explanation4 ?? ""}
                fieldValue={currentEntity.explanation4 ?? ""}
                allValues={currentEntity.explanation4 ?? ""}
                fieldLabel={REQ_FORM_CONSTANT.Detail.EXPLANATION_4}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'explanation4'}
                isShowMissingError={true}
                maxLength={200}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    requisitionFormVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, isInv, currentEntity.explanation4, REQ_FORM_CONSTANT.Detail.EXPLANATION_4, isSaveClicked, requisitionFormVM])

    const memoExplanation5 = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "330px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isInv()}
                isShowOptional={true}                
                readOnlyValue={currentEntity.explanation5 ?? ""}
                fieldValue={currentEntity.explanation5 ?? ""}
                allValues={currentEntity.explanation5 ?? ""}
                fieldLabel={REQ_FORM_CONSTANT.Detail.EXPLANATION_5}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'explanation5'}
                isShowMissingError={true}
                maxLength={200}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    requisitionFormVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, isInv, currentEntity.explanation5, REQ_FORM_CONSTANT.Detail.EXPLANATION_5, isSaveClicked, requisitionFormVM])

    const memoExplanation6 = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "330px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isInv()}
                isShowOptional={true}                
                readOnlyValue={currentEntity.explanation6 ?? ""}
                fieldValue={currentEntity.explanation6 ?? ""}
                allValues={currentEntity.explanation6 ?? ""}
                fieldLabel={REQ_FORM_CONSTANT.Detail.EXPLANATION_6}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'explanation6'}
                isShowMissingError={true}
                maxLength={200}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    requisitionFormVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, isInv, currentEntity.explanation6, REQ_FORM_CONSTANT.Detail.EXPLANATION_6, isSaveClicked, requisitionFormVM])

    const memoReference = useMemo(() =>
        <div className='flex-row-item'>
            <HPHGroupCheckbox
                label={REQ_FORM_CONSTANT.Detail.REFERENCE}
                checkboxData={referenceTypeCheckboxOption}
                selectedValues={currentEntity.referenceType?.split(",")??""}
                orientation={"horizontal"}
                disabled={isRead || isInv()}
                optional={true}
                onChange={(e: (GroupCheckboxList | undefined)[]) => requisitionFormVM.onGroupCheckboxChange(e, "referenceType")}
            />

        </div>
        , [REQ_FORM_CONSTANT.Detail.REFERENCE, currentEntity.referenceType, isInv, isRead, requisitionFormVM])

    const memoSupporting = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "330px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isInv()}
                isShowOptional={true}                
                readOnlyValue={currentEntity.supportingRemarks ?? ""}
                fieldValue={currentEntity.supportingRemarks ?? ""}
                allValues={currentEntity.supportingRemarks ?? ""}
                fieldLabel={REQ_FORM_CONSTANT.Detail.SUPPORTING}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'supportingRemarks'}
                isShowMissingError={true}
                maxLength={300}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    requisitionFormVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, isInv, currentEntity.supportingRemarks, REQ_FORM_CONSTANT.Detail.SUPPORTING, isSaveClicked, requisitionFormVM])

    const memoProblemLogNo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "330px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isInv()}
                isShowOptional={true}                
                readOnlyValue={currentEntity.problemLogNo ?? ""}
                fieldValue={currentEntity.problemLogNo ?? ""}
                allValues={currentEntity.problemLogNo ?? ""}
                fieldLabel={REQ_FORM_CONSTANT.Detail.PROBLEM_LOG_NO}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'problemLogNo'}
                isShowMissingError={true}
                maxLength={300}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    requisitionFormVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, isInv, currentEntity.problemLogNo, REQ_FORM_CONSTANT.Detail.PROBLEM_LOG_NO, isSaveClicked, requisitionFormVM])

    const memoRemarks = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "690px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isInv()}
                isShowOptional={true}
                readOnlyValue={currentEntity.reqRemarks ?? ""}
                fieldValue={currentEntity.reqRemarks ?? ""}
                allValues={currentEntity.reqRemarks ?? ""}
                fieldLabel={REQ_FORM_CONSTANT.Detail.REMARKS}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'reqRemarks'}
                isShowMissingError={true}
                maxLength={300}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    requisitionFormVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, isInv, currentEntity.reqRemarks, REQ_FORM_CONSTANT.Detail.REMARKS, isSaveClicked, requisitionFormVM])

    const memoShortDesc = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "690px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isInv()}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={currentEntity.reqFormDesc ?? ""}
                fieldValue={currentEntity.reqFormDesc ?? ""}
                allValues={currentEntity.reqFormDesc ?? ""}
                fieldLabel={REQ_FORM_CONSTANT.COMMON.SHORT_DESC}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'reqFormDesc'}
                isShowMissingError={true}
                maxLength={300}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    requisitionFormVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, isInv, currentEntity.reqFormDesc, REQ_FORM_CONSTANT.COMMON.SHORT_DESC, isSaveClicked, requisitionFormVM])

    const memoEditPanelTitleBar = useMemo(() => {
        return <RequisitionFormEditPanelTitleBar />
    }, [])

    //if (isLoading) return <Loader Indicator="Stripe" size="Large" />;
    return <StyledSidebar style={{ width: '100%', height: "100%", border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'>
        {/* Header part of add or edit record */}
        {memoEditPanelTitleBar}
        {isLoading && <Loader Indicator="Spinner" size="Medium" />}
        {/* Readable and Editable Form */}
        <div className={'add-edit-form'} style={{ height: 'calc(100vh - 125px)', maxHeight: 'calc(100vh - 125px)', overflow: 'auto' }}>
            <div className="flex-row-auto">
                <div className={'flex-row-item'}>
                    <div className={'im-flex-row'}>
                        <div className={'flex-row-item-stretch'}>
                            {<SidebarTitle>{!!currentEntity?.reqFormNo ? currentEntity?.reqFormNo : "NEW REQUISITION FORM"}</SidebarTitle>}

                            {(isAdd || (isEditable && !isRead)) && <SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>}
                        </div>
                    </div>

                    <CriteriaItemContainer>
                        {memoState}
                        {memoReqNature}
                        {memoReqMode}
                        {memoRevenCtrlInd}
                    </CriteriaItemContainer>
                    <CriteriaItemContainer>
                        {memoTotalAmount}                        
                        {memoCurrency}
                        {memoReference}
                    </CriteriaItemContainer>
                    <CriteriaItemContainer>
                        {memoExplanation1}
                        {memoExplanation2}
                    </CriteriaItemContainer>
                    <CriteriaItemContainer>
                        {memoExplanation3}
                        {memoExplanation4}
                    </CriteriaItemContainer>
                    <CriteriaItemContainer>
                        {memoExplanation5}
                        {memoExplanation6}
                    </CriteriaItemContainer>                    
                    <CriteriaItemContainer>
                        {memoSupporting}
                        {memoProblemLogNo}
                    </CriteriaItemContainer>
                    <CriteriaItemContainer>
                        {memoRemarks}
                    </CriteriaItemContainer>
                    <CriteriaItemContainer>
                        {memoShortDesc}
                    </CriteriaItemContainer>
                </div>
            </div>
        </div>
        {/* Readable and Editable Form */}
    </StyledSidebar>
}