import _ from "lodash";
import { Dispatch, SetStateAction } from "react";
import BaseViewModel from "../BaseViewModel";
import { CompanyRepository } from "domain/repository/Company/CompanyRepo";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import { RecalProcessAndGenMaintenanceModel } from "presentation/model/Recalculation/RecalProcessAndGenMaintenanceModel";
import { EMPTY_RECAL_PROCESS_AND_GEN_SEARCH_CRITERIA, RecalProcessAndGenSearchCriteria } from "domain/entity/Recalculation/RecalProcessAndGenSearchCriteria";
import { Validation } from "presentation/constant/Validation";
import { recalProcessAndGenValidationSchema } from "presentation/constant/Recalculation/RecalProcessAndGenValidationSchema";
import { RecalProcessAndGenRepository } from "domain/repository/Recalculation/RecalProcessAndGenRepo";

interface RecalProcessAndGenVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<RecalProcessAndGenMaintenanceModel>> | ((value: SetStateAction<RecalProcessAndGenMaintenanceModel>) => void),
    ],
    recalProcessAndGenRepo: RecalProcessAndGenRepository,
    companyRepo: CompanyRepository    
}

export const RecalProcessAndGenVM = ({dispatch, recalProcessAndGenRepo, companyRepo}:RecalProcessAndGenVMProps) => {
    const [recalProcessAndGenDispatch] = dispatch;

    const loadDropdownOption = async() => {
        await companyRepo.getAllCompanyForCombobox().then(
            companies => {
                let newCompanies = companies?.filter(company => company.companyType !== 'MASTER' && company.companyType !== 'ALLIANCE');

                let companyDropdownOptions = newCompanies?.map((company) => ({
                    dropdownLabel: company.companyCode,
                    tagLabel: company.id.toString(),
                    value: company.companyCode,
                })) ?? []
                companyDropdownOptions = _.orderBy(companyDropdownOptions, "dropdownLabel");

                recalProcessAndGenDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        chargeOnCompanyDropdownOptions: companyDropdownOptions
                    }
                }))
            }
        );
        
    }    

    const onSearchCriteriaResetClick = () => {
        recalProcessAndGenDispatch(prevState => {
            return {
                ...prevState,
                searchCriteria: {...EMPTY_RECAL_PROCESS_AND_GEN_SEARCH_CRITERIA}
            }
        })
    }

    const onFieldChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        return recalProcessAndGenDispatch(prevState => {
            return {
                ...prevState,
                searchCriteria: {
                    ...prevState.searchCriteria,
                    [fieldKey]: val
                },
                masterState:{
                    ...prevState.masterState,
                    allFormState: {
                        ...prevState.masterState.allFormState,
                        [fieldKey]: '',
                    },
                } 
            }
        });
    }
    
    const onDateRangeChange = (startDate: any, endDate: any,  dateFields:{startField:string, endField: string}) => {
        recalProcessAndGenDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [dateFields.startField]: startDate,
                [dateFields.endField]: endDate,
            },
            masterState:{
                ...prevState.masterState,
                allFormState: {
                    ...prevState.masterState.allFormState,
                    [dateFields.startField]: '',
                    [dateFields.endField]: '',
                },
            }
        }))
    }

    const onRadioChange = (e: any, fieldName: string) => {
        if(e.checked){
            recalProcessAndGenDispatch(prevState => {
                const criteria = {...prevState.searchCriteria};

                return {
                    ...prevState,
                    searchCriteria: {
                        ...criteria,
                        [fieldName]: e.key
                    }
                };                
            })
        }
    };
    
    const onShowLoading = () => {
        recalProcessAndGenDispatch(prevState => {
            return {
                ...prevState,
                isLoading: true,
            }
        })
    }

    const onHideLoading = () => {
        recalProcessAndGenDispatch(prevState => {
            return {
                ...prevState,
                isLoading: false,
            }
        })
    }
    
    const handleSubmitClick = () =>{
        recalProcessAndGenDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    isSubmitClicked: true,
                    allFormState: {},
                }
            }
        })
    }
    
    const onSubmit = async (searchCriteria: RecalProcessAndGenSearchCriteria) => {
        let valHdrResult = await Validation(recalProcessAndGenValidationSchema).ValidateFormOnly(searchCriteria);       

        if (valHdrResult) {
            let validatedResult: { [x: string]: string } = {};
            if (valHdrResult) {
                validatedResult = { ...validatedResult, ...valHdrResult, mandatoryCheckFail: 'Please input the missing value.' };
            }

            recalProcessAndGenDispatch(prevState => {
                return {
                    ...prevState,
                    masterState: {
                        ...prevState.masterState,
                        allFormState: {
                            ...validatedResult
                        },

                    }
                }
            });
            
            return validatedResult;
        } else {            
            let result = await recalProcessAndGenRepo.recalculationSubmit(searchCriteria);
            if (result && result.toString().startsWith("Error:")) { 
                let validatedResult: { [x: string]: string } = {"recalSubmitFailed": result.toString() };

                recalProcessAndGenDispatch(prevState => {                        
                    return {
                        ...prevState,
                        masterState: {
                            ...prevState.masterState,
                            allFormState: {
                                ...validatedResult
                            },
    
                        }
                    }
                });
                return validatedResult;
            }
        }
    }

    return {
        loadDropdownOption: loadDropdownOption,
        onSearchCriteriaResetClick: onSearchCriteriaResetClick,
        onFieldChange: onFieldChange,
        onDateRangeChange: onDateRangeChange, 
        onRadioChange: onRadioChange,
        onShowLoading: onShowLoading,
        onHideLoading: onHideLoading,
        handleSubmitClick: handleSubmitClick,
        onSubmit: onSubmit
    }
}