import { useChargeDetailVM } from "presentation/hook/ChargeDetailMaintenance/useChargeDetailVM";
import { ChargeDetailModel } from "presentation/model/ChargeDetailMaintenance/ChargeDetailModel";
import NbisInputDropDown from "presentation/view/components/NbisInputDropDown";

export const CompanyAccordion = ({ chargeDetailState }: { chargeDetailState: ChargeDetailModel }) => {
    const chargeBatchUpdate = chargeDetailState.chargeBatchUpdate
    const chargeDetailVM = useChargeDetailVM();
    return (
        <>
            <div className={'im-flex-row'} style={{ marginBottom: '24px' }}>
                <div className='im-flex-row-item'>
                    <NbisInputDropDown
                        label='Bill-to Company'
                        width='150px'
                        inputType="freeText"
                        mode={"single"}
                        value={chargeBatchUpdate.billToCompany}
                        onChange={(e: any) => chargeDetailVM.onDropdownChange(e, 'billToCompany', chargeBatchUpdate, 'chargeBatchUpdate')}
                        options={chargeDetailState.dynamicOptions.billToCompDropdownOptions} />
                </div>
                <div className='im-flex-row-item'>
                    <NbisInputDropDown
                        label='Charge-on Company'
                        width='150px'
                        inputType="freeText"
                        value={chargeBatchUpdate.chargeOnCompany}
                        onChange={(e: any) => chargeDetailVM.onDropdownChange(e, 'chargeOnCompany', chargeBatchUpdate, 'chargeBatchUpdate')}
                        options={chargeDetailState.dynamicOptions.chgOnCompDropdownOptions} />
                </div>
            </div>
            <div className={'im-flex-row'} style={{ marginBottom: '24px' }}>
                <div className='im-flex-row-item'>
                    <NbisInputDropDown
                        label='Alterable Bill-to Company'
                        width='150px'
                        inputType="freeText"
                        mode={"single"}
                        value={chargeBatchUpdate.alterableBillToCompany}
                        onChange={(e: any) => chargeDetailVM.onDropdownChange(e, 'alterableBillToCompany', chargeBatchUpdate, 'chargeBatchUpdate')}
                        options={chargeDetailState.dynamicOptions.billToCompDropdownOptions} />
                </div>
            </div>
            <div className={'im-flex-row'} style={{ marginBottom: '24px' }}>
                <div className='im-flex-row-item'>
                    <NbisInputDropDown
                        label='Alterable Charge-on Company'
                        width='150px'
                        inputType="freeText"
                        value={chargeBatchUpdate.alterableChargeOnCompany}
                        onChange={(e: any) => chargeDetailVM.onDropdownChange(e, 'alterableChargeOnCompany', chargeBatchUpdate, 'chargeBatchUpdate')}
                        options={chargeDetailState.dynamicOptions.chgOnCompDropdownOptions} />
                </div>
            </div>
        </>
    )
}