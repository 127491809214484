import { MonthEndClosureConstant } from "presentation/constant/MonthEndClosure/MonthEndClosureConstant";
import { useMonthEndClosureVM } from "presentation/hook/MonthEndClosure/useMonthEndClosureVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { MonthEndClosureModel } from "presentation/model/MonthEndClosure/MonthEndClosureModel";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import NumberInputComponent from "presentation/view/components/NumberInputComponent";
import { memo, useCallback, useMemo } from "react";
import { FieldType, HPHCheckbox, IconButton, IFieldValue } from "veronica-ui-component/dist/component/core";
import { SidebarActionCross, Sidebarheader, SidebarTitle, StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const ReservationItemEditPanel = ({ monthEndClosureState }: { monthEndClosureState: MonthEndClosureModel }) => {
    const MONTH_END_CLOSURE_CONSTANT = MonthEndClosureConstant.Reservation;
    const messageBarVM = useMessageBarVM();
    const monthEndClosureVM = useMonthEndClosureVM();
    const { currentReservationEditRow, isAddReservation, isEditReservation } = monthEndClosureState;


    const handleSave = useCallback(async () => {
        if (!currentReservationEditRow.billToCompany) {
            messageBarVM.showError("Bill To Company is mandatory.");
            return;
        }
        if (!currentReservationEditRow.tariffType) {
            messageBarVM.showError("Tariff Type is mandatory.");
            return;
        }
        if (!currentReservationEditRow.tariffCode) {
            messageBarVM.showError("Tariff Code is mandatory.");
            return;
        }
        if (!currentReservationEditRow.numberOfChargeQty) {
            messageBarVM.showError("Number of Container is mandatory.");
            return;
        }
        if (!currentReservationEditRow.turnOverAmount) {
            messageBarVM.showError("Turnover Amount is mandatory.");
            return;
        }
        monthEndClosureVM.onShowLoading();
        monthEndClosureVM.onSaveReservation(currentReservationEditRow).then((data) => {
            if (data === "success") {
                messageBarVM.showSuccess("Save successful.");
                monthEndClosureVM.onHideLoading();
            } else {
                messageBarVM.showError(data);
                monthEndClosureVM.onHideLoading();
            }
        })
    }, [currentReservationEditRow, messageBarVM, monthEndClosureVM]);

    const handleReset = useCallback(() => {
        monthEndClosureVM.onReservationResetClick(isAddReservation);
    }, [isAddReservation, monthEndClosureVM]);

    const handClose = useCallback(() => {
        monthEndClosureVM.onReservationEditPanelCloseClick();
    }, [monthEndClosureVM]);


    const onNumberFieldChange = useCallback((e: any, fieldName: string, fieldValue: any) => {
        monthEndClosureVM.onDtlFieldChange(fieldName, fieldValue);
    }, [monthEndClosureVM]);

    const memoTariffCodeOptions = useMemo(() => {
        return currentReservationEditRow.tariffType
            ? monthEndClosureState.dynamicOptions.tariffCodeDropdownOptions[currentReservationEditRow.tariffType]
            : [];
    }, [currentReservationEditRow.tariffType, monthEndClosureState.dynamicOptions.tariffCodeDropdownOptions]);

    const memoTariffType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "30px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={false}
                readOnlyValue={currentReservationEditRow?.tariffType || ''}
                fieldValue={currentReservationEditRow?.tariffType}
                fieldLabel={MONTH_END_CLOSURE_CONSTANT.TARIFF_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'tariffType'}
                maxLength={60}
                options={monthEndClosureState.dynamicOptions.tariffTypeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    monthEndClosureVM.onDtlFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [MONTH_END_CLOSURE_CONSTANT.TARIFF_TYPE, currentReservationEditRow?.tariffType, monthEndClosureState.dynamicOptions.tariffTypeDropdownOptions, monthEndClosureVM])

    const memotariffCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "30px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={false}
                readOnlyValue={currentReservationEditRow?.tariffCode || ''}
                fieldValue={currentReservationEditRow?.tariffCode}
                fieldLabel={MONTH_END_CLOSURE_CONSTANT.TARIFF_CODE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'tariffCode'}
                maxLength={60}
                options={memoTariffCodeOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    monthEndClosureVM.onDtlFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [MONTH_END_CLOSURE_CONSTANT.TARIFF_CODE, currentReservationEditRow?.tariffCode, memoTariffCodeOptions, monthEndClosureVM])


    const memoBillToCompany = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "30px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={false}
                readOnlyValue={currentReservationEditRow?.billToCompany ?? ""}
                fieldValue={currentReservationEditRow?.billToCompany}
                fieldLabel={MONTH_END_CLOSURE_CONSTANT.BILL_TO_COMPANY}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'billToCompany'}
                maxLength={60}
                options={monthEndClosureState.dynamicOptions.companyCodeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    monthEndClosureVM.onDtlFieldChange(fieldKey, fieldValue && fieldValue, fFullValue)} />
        </div>
        , [MONTH_END_CLOSURE_CONSTANT.BILL_TO_COMPANY, currentReservationEditRow?.billToCompany, monthEndClosureState.dynamicOptions.companyCodeDropdownOptions, monthEndClosureVM])

    const memoNumberOfChargeQty = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "30px" }}>
            <NumberInputComponent
                label={MONTH_END_CLOSURE_CONSTANT.NUMBER_OF_CONTAINER}
                maxLength={10}
                fieldName={"numberOfChargeQty"}
                value={currentReservationEditRow.numberOfChargeQty ?? ''}
                errorMessage={''}
                disabled={false}
                decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue)}
            />
        </div>
        , [MONTH_END_CLOSURE_CONSTANT.NUMBER_OF_CONTAINER, currentReservationEditRow.numberOfChargeQty, onNumberFieldChange])

    const memoTurnOverAmount = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "30px" }}>
            <NumberInputComponent
                label={MONTH_END_CLOSURE_CONSTANT.TURNOVER_AMOUNT}
                maxLength={10}
                fieldName={"turnOverAmount"}
                value={currentReservationEditRow.turnOverAmount ?? ''}
                errorMessage={''}
                disabled={false}
                decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue)}
            />
        </div>
        , [MONTH_END_CLOSURE_CONSTANT.TURNOVER_AMOUNT, currentReservationEditRow.turnOverAmount, onNumberFieldChange])



    const memoActiveInd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "30px" }}>
            <NbisCommonField
                fieldKey={"activeInd"}
                requiredFieldList={[]}
                fieldLabel={MONTH_END_CLOSURE_CONSTANT.ACTIVE_IND}
                fieldType={FieldType.TEXT}
                isReadOnly={true}
                isShowOptional={false}
                onFieldChange={() => { }} />
            <HPHCheckbox
                label={""}
                checked={currentReservationEditRow?.activeInd === 'Y'}
                disabled={false}
                onChange={(e) => monthEndClosureVM.onDtlCheckboxChange(e.checked, "activeInd")}
            />
        </div>
        , [MONTH_END_CLOSURE_CONSTANT.ACTIVE_IND, currentReservationEditRow?.activeInd, monthEndClosureVM])

    return <>
        <div className='side-form-content-left-wrapper'>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <StyledSidebar style={{ width: '100%', height: "100%", border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'>
                        <Sidebarheader style={{ width: '100%' }}>
                            <SidebarTitle>
                                {isAddReservation && "New Reservation Item"}
                                {isEditReservation && "Edit Reservation Item"}
                            </SidebarTitle>
                            <SidebarActionCross>
                                <>
                                    <IconButton fileName="Icon-reset" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Reset'} onClick={() => handleReset()} />
                                    <IconButton fileName="Icon-tick" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Save'} onClick={() => handleSave()} />
                                    <IconButton fileName="Icon-cross" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement='bottom' toolTipText={'Close'} onClick={() => handClose()} />
                                </>
                            </SidebarActionCross>

                        </Sidebarheader>

                    </StyledSidebar>
                </div>
            </div>
            <div className={'add-edit-form'} style={{ height: '78vh', maxHeight: '78vh', overflow: 'auto' }}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>

                        <CriteriaItemContainer>
                            {memoBillToCompany}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoTariffType}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memotariffCode}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoNumberOfChargeQty}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoTurnOverAmount}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoActiveInd}
                        </CriteriaItemContainer>

                    </div>
                </div>
            </div>

        </div>
    </>
}

export default memo(ReservationItemEditPanel);
