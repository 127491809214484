import { DEFAULT_ENABLED_PORT_SEARCH_CRITERIA, PortEnabledSearchCriteria } from "domain/entity/Port/PortEnabledSearchCriteria";
import { EMPTY_PORT_ENTITY, PortEntity } from "domain/entity/Port/PortEntity";
import { EMPTY_PORT_SEARCH_CRITERIA, PortSearchCriteria } from "domain/entity/Port/PortSearchCriteria";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";

export interface PortDropdownOptions {
    countryCodeDropdownOptions: DropdownProps[],
    provinceCodeDropdownOptions: { [key: string]: DropdownProps[] },
    geographicalIndDropdownOptions: DropdownProps[],
}
export interface PortViewChangeState extends BaseViewChangeSate {
}

export interface PortModel {
    isLoading: boolean,
    isShowEditPanel: boolean,
    isShowSearchPanel: boolean,
    isAdd: boolean,
    isEdit: boolean,
    dynamicOptions: PortDropdownOptions,
    portList: PortEntity[],
    selectedRows: PortEntity[],
    currentRow: PortEntity,
    currentEditRow: PortEntity,
    searchCriteria: PortSearchCriteria,
    enabledSearchCriteria: PortEnabledSearchCriteria,
    forceRefresh: boolean,

}

export const EMPTY_PORT_MODEL: PortModel = {
    isLoading: false,
    isShowEditPanel: false,
    isAdd: false,
    isEdit: false,
    dynamicOptions: {
        countryCodeDropdownOptions: [],
        provinceCodeDropdownOptions: {},
        geographicalIndDropdownOptions: []
    },
    portList: [],
    selectedRows: [],
    currentRow: { ...EMPTY_PORT_ENTITY },
    currentEditRow: { ...EMPTY_PORT_ENTITY },
    searchCriteria: { ...EMPTY_PORT_SEARCH_CRITERIA },
    enabledSearchCriteria: { ...DEFAULT_ENABLED_PORT_SEARCH_CRITERIA },
    isShowSearchPanel: false,
    forceRefresh: false
}