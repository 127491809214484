import { PermissionConstant } from "./PermissionConstant";

const PERMISSION_CONSTANT = PermissionConstant.permission;

export const INITIAL_PERMISSION_COL_DEF: any[] = [
    {
        headerName: PERMISSION_CONSTANT.RESOURCENAME,
        field: 'resource',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 250,
    },
    {
        headerName: PERMISSION_CONSTANT.ALLOWEDCREATE,
        field: 'create',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 80,
    },
    {
        headerName: PERMISSION_CONSTANT.ALLOWEDUPDATE,
        field: 'modify',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 80,
    },
    {
        headerName: PERMISSION_CONSTANT.ALLOWEDREAD,
        field: 'read',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 80,
    },
    {
        headerName: PERMISSION_CONSTANT.ALLOWEDDELETE,
        field: 'delete',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 80,
    },
    {
        headerName: PERMISSION_CONSTANT.ATTRIBUTES,
        field: 'tempAttributes',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
].map((col, index) => {
    if (col.field === "tempAttributes" || col.field === "resource") {
        return {
            ...col,
            header: col.headerName, id: index + 1, dataType: 'text'
        }
    } else {
        return {
            ...col,
            header: col.headerName, id: index + 1, dataType: 'boolean'
        }
    }
});