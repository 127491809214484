import { PrintQueueRepoImpl } from "domain/repository/Common/PrintQueueRepoImpl";
import { CustomerRepoImpl } from "domain/repository/Company/CustomerRepoImpl";
import { ManualChargeHeaderRepoImpl } from "domain/repository/ManualCharge/ManualChargeHeaderRepoImpl";
import { ParameterDetailRepoImpl } from "domain/repository/parameter/ParameterDetailRepoImpl";
import { useNonOpsHeaderTracked } from "presentation/store/NonOps/NonOpsHeaderProvider";
import { NonOpsHeaderVM } from "presentation/viewModel/NonOps/NonOpsHeaderVM";
import { useMemo } from "react";

export const useNonOpsHeaderVM = () => {
    const [, setNonOpsHeaderState] = useNonOpsHeaderTracked();
    const nonOpsHeaderVM = useMemo(() =>
        NonOpsHeaderVM({
            dispatch: [setNonOpsHeaderState],
            parameterDetailRepo: ParameterDetailRepoImpl(),
            manualChargeHeaderRepo: ManualChargeHeaderRepoImpl(),
            customerRepo: CustomerRepoImpl(),
            printQueueRepo: PrintQueueRepoImpl()
        }), [setNonOpsHeaderState])

    return nonOpsHeaderVM
}