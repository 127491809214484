import { ChargeConstant } from "presentation/constant/ChargeHeader/ChargeConstant";
import { useChargeHeaderMaintenanceVM } from "presentation/hook/ChargeHeaderMaintenance/useChargeHeaderMaintenanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useChargeHeaderMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/ChargeHeaderMaintenanceProvider";
import NbisInputDropDown from "presentation/view/components/NbisInputDropDown";
import { useEffect, useMemo, useState } from "react";
import { HPHButton, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarActionBar, SidebarTitle, Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

export const DivertShipPanel = () => {
    const messageBarVM = useMessageBarVM();
    const CHARGE_HEADER_CONSTANT = ChargeConstant.ChargeHeader;
    const chgHdrMainVM = useChargeHeaderMaintenanceVM();
    const [chargeHdrState] = useChargeHeaderMaintenanceTracked();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const divertShipFromDropdownOptions = chargeHdrState.dynamicOptions.divertShipFromDropdownOptions;
    const divertShipFrom = chargeHdrState.divertShipRequest.divertShipFrom;

    useEffect(() => {
        setIsLoading(true);
        chgHdrMainVM.onOpenDivertShipPanel().then(data => {
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
        })
    }, [chgHdrMainVM])

    const onClose = () => {
        chgHdrMainVM.onCloseDivertShipPanel();
    }

    const onApply = (e: any) => {
        if (!divertShipFrom) {
            messageBarVM.showError('Please select the Divert Ship From!')
        } else {
            setIsLoading(true);
            chgHdrMainVM.applyDivertShip(chargeHdrState.divertShipRequest).then(() => {
                messageBarVM.showSuccess('Divert ship successfully.');
                setIsLoading(false);
            }).catch((error) => {
                messageBarVM.showError('Divert ship failure.');
                setIsLoading(false);
            });
        }
    }

    const memoDivertShipFrom = useMemo(() =>
        <div className='im-flex-row-item'>
            <NbisInputDropDown
                label={CHARGE_HEADER_CONSTANT.DIVERT_SHIP_FROM}
                inputType="freeText"
                width="150px"
                sort={false}
                value={divertShipFrom}
                onChange={(e: any) => chgHdrMainVM.onDivertShipDropdownChange(e)}
                options={divertShipFromDropdownOptions} />
        </div>
        , [CHARGE_HEADER_CONSTANT.DIVERT_SHIP_FROM, chgHdrMainVM, divertShipFrom, divertShipFromDropdownOptions]);

    return (
        <div className='side-form-content-wrapper'>
            {isLoading &&
                <Loader Indicator="Spinner" size="Medium" />
            }
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>{CHARGE_HEADER_CONSTANT.DIVERT_SHIP}</SidebarTitle>
                    </Sidebarheader>
                </div>
            </div>
            <div className={'add-edit-form'} style={{ height: '75vh', maxHeight: '75vh', overflow: 'auto' }}>
                <div className="im-flex-row-auto">
                    <div className={'im-flex-row-item'}>
                        {memoDivertShipFrom}
                    </div>
                </div>
            </div>

            <SidebarActionBar>
                <HPHButton label={'Close'} size={'Small'} theme={'Secondary'} onClick={onClose} />
                <HPHButton label={'Apply'} size={'Small'} theme={'Primary'} onClick={onApply} />
            </SidebarActionBar>
        </div>
    )
}