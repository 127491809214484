export interface ChargeDetailSearchCriteria {

    cntrNos?: string[] | null,
    tariffCodes?: string[] | null,
    billToCompanies?: string[] | null,
    chargeOnCompanies?: string[] | null,
    bundleInds?: string[] | null,
    cntrSizes?: string[] | null,
    chargeTypes?: string[] | null,
    subChargeTypes?: string[] | null,
    bundleMasterNo?: string | null,
    cntrTypes?: string[] | null,
    adjTypes?: string[] | null,
    arrivalVesselCode?: string | null,
    arrivalVoyageCode?: string | null,
    statusCOKDs?: string[] | null,
    emptyInd?: string | null,
    operationTypes?: string[] | null,
    departureVesselCode?: string | null,
    departureVoyageCode?: string | null,
    specialHandlingInd?: string[] | null,
    specialHandlingIndD1?: string[] | null,
    cntrStatus?: string[] | null,
    docNo?: string | null,
    modalityArrivals?: string[] | null,
    modalityDepartures?: string[] | null,
    states?: string[] | null,
    opsLines?: string[] | null,
    marshallingTypes: string[] | null,
    displaySequence: number | null,
    handlingTmls?: string[] | null,
    vslCode?: string | null,
    voyCode?: string | null,
    chargeShareType?: string | null,
    chargeHdrId: number | null,

    [key: string]: string[] | string | boolean | number | Date | null | undefined
}

export const EMPTY_CHARGE_DETAIL_SEARCH_CRITERIA: ChargeDetailSearchCriteria = {
    chargeHdrId: 0,
    cntrNos: [],
    tariffCodes: [],
    billToCompanies: [],
    chargeOnCompanies: [],
    bundleInds: [],
    cntrSizes: [],
    chargeTypes: [],
    subChargeTypes: [],
    bundleMasterNo: null,
    cntrTypes: [],
    adjTypes: [],
    arrivalVesselCode: null,
    arrivalVoyageCode: null,
    statusCOKDs: [],
    emptyInd: null,
    operationTypes: [],
    departureVesselCode: null,
    departureVoyageCode: null,
    specialHandlingInd: [],
    specialHandlingIndD1: [],
    cntrStatus: [],
    docNo: null,
    modalityArrivals: [],
    modalityDepartures: [],
    states: [],
    opsLines: [],
    marshallingTypes: [],
    forwarderCodes: [],
    displaySequence: null,
    handlingTmls: [],
    vslCode: null,
    voyCode: null,
    chargeShareType: null,
}