import { ChargeQtyAdjMaintenanceConstant } from "presentation/constant/ChargeQtyAdj/ChargeQtyAdjMaintenanceConstant";
import { useChargeQtyAdjMaintenanceVM } from "presentation/hook/ChargeQtyAdj/useChargeQtyAdjMaintenanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useChargeQtyAdjMaintenanceTracked } from "presentation/store/ChargeQtyAdj/ChargeQtyAdjMaintenanceProvider";
import { memo, useCallback, useState } from "react";
import { HPHBreadcrumb, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
const ChargeQtyAdjEditPanelTitleBar: React.FC = () => {
    const [chargeQtyAdjState] = useChargeQtyAdjMaintenanceTracked();
    const chargeQtyAdjVM = useChargeQtyAdjMaintenanceVM();
    const messageBarVM = useMessageBarVM();
    const { currentSelectedRow, masterState } = chargeQtyAdjState;
    const { editingEntity, isAdd, isEditable, isRead } = masterState;

    const [anainfoState] = useANAInfoTracked();
    const { allowCreate, allowUpdate } = anainfoState;
    const [isLoading, setIsLoading] = useState(false);
    const currentEntity = isRead ? currentSelectedRow : editingEntity;
    const handleReset = useCallback(async () => {
        chargeQtyAdjVM.onReset();
    }, [chargeQtyAdjVM]);
    const handleEdit = useCallback(() => {
        chargeQtyAdjVM.onEdit(currentEntity);
    }, [currentEntity, chargeQtyAdjVM])
    const handleSave = useCallback(async () => {
        if (currentEntity.allowance === null) {
            messageBarVM.showError("Allowance is mandatory.");
            return;
        }
        if (currentEntity.cntrList === null) {
            messageBarVM.showError("Container ID is mandatory.");
            return;
        }
        setIsLoading(true);
        try {
            chargeQtyAdjVM.onSaveClicked();
            const res = await chargeQtyAdjVM.onSave(currentEntity, isAdd);
            if (!!!res || !res.success) {

                messageBarVM.showError(res?.data ?? 'Save data failed.');

            } else {
                messageBarVM.showSuccess("Save successful.");
                chargeQtyAdjVM.onClose();
                setIsLoading(true);
            }
        } catch (error) {

            messageBarVM.showError('Save data failed.');
        } finally {
            setIsLoading(false)
        }

    }, [currentEntity, isAdd, messageBarVM, chargeQtyAdjVM]);
    return <Sidebarheader style={{ width: '100%' }}>
        {isLoading && <Loader Indicator="Spinner" size="Medium" />}
        <Breadcrumb>
            <HPHBreadcrumb breadcrumbData={[{ title: ChargeQtyAdjMaintenanceConstant.CATEGORY },
            { title: ChargeQtyAdjMaintenanceConstant.TITLE }]} onCurrentClick={() => { }}>
            </HPHBreadcrumb>
        </Breadcrumb>
        <StyledAction>
            {(!isAdd && (isEditable && isRead)) &&
                <>
                    {/* Edit Button */}
                    {
                        (
                            (isEditable && allowUpdate)
                        )
                        && <IconButton fileName='Icon-pen' size='medium' toolTipText={'Edit'} toolTipArrow={false} onClick={handleEdit} />
                    }
                    {/* Close Button */}
                    <IconButton fileName='Icon-cross' size='medium' toolTipText={'Close'} toolTipArrow={false} onClick={chargeQtyAdjVM.onClose} />
                </>}
            {(isAdd || (isEditable && !isRead)) &&
                <>
                    {/* Reset Form Button */}
                    <IconButton fileName="Icon-reset" size="medium" toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Reset'} onClick={handleReset} />
                    {/* Close Button */}
                    <IconButton fileName="Icon-cross" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="left" toolTipText={'Close'} onClick={chargeQtyAdjVM.onClose} />
                    {/* Save Button */}
                    {
                        (
                            (isAdd && allowCreate) ||
                            (isEditable && allowUpdate)
                        )
                        && <IconButton fileName="Icon-tick" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Save'} onClick={() => handleSave()} />
                    }
                </>}
        </StyledAction>
    </Sidebarheader>
}
export default memo(ChargeQtyAdjEditPanelTitleBar); 
