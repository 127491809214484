

import { useEmptyPoolExclusionCriteriaVM } from "presentation/hook/EmptyPoolExclusionCriteria/useEmptyPoolExclusionCriteriaVM";
import { useEmptyExclCriteriaTracked } from "presentation/store/EmptyPoolExclusionCriteria/EmptyPoolExclusionCriteriaProvider";
import { useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import { EmptyPoolExclusionCriteriaComponentPanel } from "./Form/EmptyPoolExclusionCriteriaComponentPanel";
import EmptyPoolExclusionCriteriaComponentTablePanel from "./Table/EmptyPoolExclusionCriteriaComponentTablePanel";
export const EmptyPoolExclusionCriteriaMaintenance: React.FC = () => {
    const [criteriaState] = useEmptyExclCriteriaTracked();
    const { isSliderOpen } = criteriaState.masterState;

    const [isLoading, setIsLoading] = useState(false);
    const criteriaVM = useEmptyPoolExclusionCriteriaVM();

    useEffect(() => {
        const initialScreen = async () => {
            setIsLoading(true);
            try {
                const results = await Promise.allSettled([
                    criteriaVM.loadDropdownOption(),
                ]);
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {

                    }
                })
            } catch (error) {
                setIsLoading(false);
            }
        }
        initialScreen().then(async () => {
            criteriaVM.searchEmptyPoolExclusion().then((data) => {
                setIsLoading(false)
            }).catch(error => {
                setIsLoading(false)
            });
        }).catch(error => {
            setIsLoading(false)
        });

    }, [criteriaVM])

    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;
    return <div className={`main-comp-wrapper${isSliderOpen ? '' : ' im-hide-side-form-draggable'}`}>
        <SliderPanel
            isOpen={isSliderOpen}
            leftSectionWidth={isSliderOpen ? '73%' : '100%'}
            rightSectionWidth={isSliderOpen ? '28%' : '0%'}
            draggable={true}
            leftChildren={<EmptyPoolExclusionCriteriaComponentTablePanel />}
            rightChildren={<EmptyPoolExclusionCriteriaComponentPanel />} />
    </div>
}