import { surChargeTypeDropdownOption, uomDropdownOption } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { StandardProposalConstant, standardProposalIsRequiredFieldList, standardProposalTierRequiredFieldList } from "presentation/constant/StandardProposal/StandardProposalConstant";
import { useStandardProposalDetailVM } from "presentation/hook/StandardProposal/useStandardProposalDetailVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { StandardProposalDetailModel } from "presentation/model/StandardProposal/StandardProposalDetailModel";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import NumberInputComponent from "presentation/view/components/NumberInputComponent";
import { memo, useCallback, useMemo } from "react";
import { FieldType, IconButton, IFieldValue } from "veronica-ui-component/dist/component/core";
import { SidebarActionCross, Sidebarheader, SidebarTitle } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";


const AddTariffItemIsEditPanel = ({ standardProposalDtlState }: { standardProposalDtlState: StandardProposalDetailModel }) => {
    //const [standardProposalDtlState] = useStandardProposalDetailTracked();
    const standardProposalDtlVM = useStandardProposalDetailVM();
    const messageBarVM = useMessageBarVM();
    const STANDARD_PROPOSAL_CONSTANT = StandardProposalConstant.Table;
    const { currentSelectItem, currentTariffItemTier, currentTariffItemIs, isAddIs, isAddTier, isEditIs, isEditTier, currentEditTariffItemTier, currentEditTariffItemIs } = standardProposalDtlState;
    const currentTier = (isEditTier || isAddTier) ? currentTariffItemTier : currentEditTariffItemTier;
    const currentIs = (isEditIs || isAddIs) ? currentTariffItemIs : currentEditTariffItemIs;



    const onNumberFieldChange = useCallback((e: any, fieldName: string, fieldValue: any) => {
        standardProposalDtlVM.onTierFieldChange(fieldName, fieldValue);
    }, [standardProposalDtlVM]);

    const onIsNumberFieldChange = useCallback((e: any, fieldName: string, fieldValue: any) => {
        standardProposalDtlVM.onIsFieldChange(fieldName, fieldValue);
    }, [standardProposalDtlVM]);

    const handleCancel = useCallback(() => {
        standardProposalDtlVM.cancelAddOrEdit();
    }, [standardProposalDtlVM]);

    const handleSave = useCallback(async () => {
        if (isAddIs || isEditIs) {
            if (!currentTariffItemIs.shiftCode) {
                messageBarVM.showError("Shift Code is mandatory.");
                return;
            }
            if (currentTariffItemIs.percentage === null) {
                messageBarVM.showError("Surcharge % is mandatory.");
                return;
            }
        }
        if (isAddTier || isEditTier) {
            if (currentTariffItemTier.minimum === null) {
                messageBarVM.showError("MINIMUM is mandatory.");
                return;
            }
            if (currentTariffItemTier.rate === null) {
                messageBarVM.showError("RATE is mandatory.");
                return;
            }
            if (!currentTariffItemTier.uom) {
                messageBarVM.showError("UOM is mandatory.");
                return;
            }
            if (!currentTariffItemTier.forevery) {
                messageBarVM.showError("FOR_EVERY is mandatory.");
                return;
            }
            if (!currentTariffItemTier.cycle) {
                messageBarVM.showError("CYCLE is mandatory.");
                return;
            }
            if (Number(currentTariffItemTier.minimum) > Number(currentTariffItemTier.rate)) {
                messageBarVM.showError("The Minimum should not be larger than Rate.");
                return;
            }
        }
        if (isAddTier) {
            if (currentSelectItem.stdIsList && currentSelectItem.stdIsList.length > 0 && currentSelectItem.tierList && currentSelectItem.tierList.length === 1) {
                messageBarVM.showError("Intensive surcharge does not support multiple charge tier.");
                return;
            }
        }
        if (isEditTier) {
            if (currentSelectItem.stdIsList && currentSelectItem.stdIsList.length > 0 && currentSelectItem.tierList && currentSelectItem.tierList.length !== 1) {
                messageBarVM.showError("Intensive surcharge does not support multiple charge tier.");
                return;
            }
        }
        if (isAddTier || isEditTier) {
            standardProposalDtlVM.onTempSaveTariffItemTier(isAddTier, currentSelectItem, currentTier);
            return;
        }
        if (isAddIs || isEditIs) {
            if (currentSelectItem.tierList && currentSelectItem.tierList.length > 1) {
                messageBarVM.showError("Intensive surcharge does not support multiple charge tier.");
                return;
            }
            standardProposalDtlVM.onTempSaveTariffItemIs(isAddIs, currentSelectItem, currentIs);
            return;
        }
        if (currentSelectItem.tierList && currentSelectItem.tierList.length === 0) {
            messageBarVM.showError("Must add one tariff item tier at least.");
            return;
        }

    }, [isAddIs, isEditIs, isAddTier, isEditTier, currentSelectItem, currentTariffItemIs.shiftCode, currentTariffItemIs.percentage,
        messageBarVM, currentTariffItemTier.minimum, currentTariffItemTier.rate, currentTariffItemTier.uom, currentTariffItemTier.forevery,
        currentTariffItemTier.cycle, standardProposalDtlVM, currentTier, currentIs]);


    const memoCycle = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "10px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={currentTier?.cycle?.toString() || ''}
                fieldValue={currentTier?.cycle?.toString()}
                fieldLabel={STANDARD_PROPOSAL_CONSTANT.CYCLE}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'cycle'}
                maxLength={60}
                requiredFieldList={standardProposalTierRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    standardProposalDtlVM.onTierFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.CYCLE, currentTier?.cycle, standardProposalDtlVM])

    const memoForEvery = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "10px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={currentTier?.forevery?.toString() || ''}
                fieldValue={currentTier?.forevery?.toString()}
                fieldLabel={STANDARD_PROPOSAL_CONSTANT.FOR_EVERY}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'forevery'}
                maxLength={60}
                requiredFieldList={standardProposalTierRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    standardProposalDtlVM.onTierFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.FOR_EVERY, currentTier?.forevery, standardProposalDtlVM])

    const memoUom = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "10px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={currentTier?.uom || ''}
                fieldValue={currentTier?.uom}
                fieldLabel={STANDARD_PROPOSAL_CONSTANT.UOM}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'uom'}
                maxLength={60}
                options={uomDropdownOption}
                requiredFieldList={standardProposalTierRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    standardProposalDtlVM.onTierFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.UOM, currentTier?.uom, standardProposalDtlVM])

    const memoRate = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "10px" }}>
            <NumberInputComponent
                label={STANDARD_PROPOSAL_CONSTANT.RATE}
                maxLength={60}
                fieldName={"rate"}
                value={currentTier?.rate ?? ''}
                errorMessage={''}
                disabled={false}
                decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.RATE, currentTier?.rate, onNumberFieldChange])

    const memoMinimun = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "10px" }}>
            <NumberInputComponent
                label={STANDARD_PROPOSAL_CONSTANT.MINIMUM}
                maxLength={60}
                fieldName={"minimum"}
                value={currentTier?.minimum ?? ''}
                errorMessage={''}
                disabled={false}
                decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.MINIMUM, currentTier?.minimum, onNumberFieldChange])

    const memosurCharge = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "10px" }}>
            <NumberInputComponent
                label={STANDARD_PROPOSAL_CONSTANT.SURCHARGE}
                maxLength={60}
                fieldName={"surcharge"}
                value={currentTier?.surcharge ?? ''}
                errorMessage={''}
                disabled={false}
                decimalPlaces={2}
                optional={true}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.SURCHARGE, currentTier?.surcharge, onNumberFieldChange])

    const memoSurChargeType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "10px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={currentTier?.surchargeType || ''}
                fieldValue={currentTier?.surchargeType}
                fieldLabel={STANDARD_PROPOSAL_CONSTANT.SURCHARGE_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'surchargeType'}
                maxLength={60}
                options={surChargeTypeDropdownOption}
                requiredFieldList={standardProposalTierRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    standardProposalDtlVM.onTierFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.SURCHARGE_TYPE, currentTier?.surchargeType, standardProposalDtlVM])

    const memoAdditionalRate = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "10px" }}>
            <NumberInputComponent
                label={STANDARD_PROPOSAL_CONSTANT.ADDITIONAL_RATE}
                maxLength={60}
                fieldName={"additionalRate"}
                value={currentTier?.additionalRate ?? ''}
                errorMessage={''}
                disabled={false}
                decimalPlaces={2}
                optional={true}
                required={false}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.ADDITIONAL_RATE, currentTier?.additionalRate, onNumberFieldChange])

    const memoAdditionalUom = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "10px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={currentTier?.additionalUom || ''}
                fieldValue={currentTier?.additionalUom}
                fieldLabel={STANDARD_PROPOSAL_CONSTANT.ADDITIONAL_UOM}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'additionalUom'}
                maxLength={60}
                options={uomDropdownOption}
                requiredFieldList={standardProposalTierRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    standardProposalDtlVM.onTierFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.ADDITIONAL_UOM, currentTier?.additionalUom, standardProposalDtlVM])

    const memoShiftCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "10px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={currentIs?.shiftCode || ''}
                fieldValue={currentIs?.shiftCode}
                fieldLabel={STANDARD_PROPOSAL_CONSTANT.SHIFT_CODE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'shiftCode'}
                maxLength={60}
                options={standardProposalDtlState.dynamicOptions.shiftCodeDropdownOptions}
                requiredFieldList={standardProposalIsRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    standardProposalDtlVM.onIsFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.SHIFT_CODE, currentIs?.shiftCode, standardProposalDtlState.dynamicOptions.shiftCodeDropdownOptions, standardProposalDtlVM])

    const memoPercentage = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "10px" }}>
            <NumberInputComponent
                label={STANDARD_PROPOSAL_CONSTANT.SURCHARGE_P}
                maxLength={60}
                fieldName={"percentage"}
                value={currentIs?.percentage ?? ''}
                errorMessage={''}
                disabled={false}
                decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onIsNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.SURCHARGE_P, currentIs?.percentage, onIsNumberFieldChange])



    return <div className="side-form-content-left-wrapper" style={{ height: "40vh", maxHeight: "40vh", overflow: "visible", padding: "5px" }}>

        <div className={'flex-row'}>
            <div className={'flex-row-item flex-row-item-full-width'}>
                <Sidebarheader>
                    <SidebarTitle>
                        {isAddTier && "Add " + STANDARD_PROPOSAL_CONSTANT.TIER_VALUE}
                        {isEditTier && "Edit " + STANDARD_PROPOSAL_CONSTANT.TIER_VALUE}
                        {isAddIs && "Add " + STANDARD_PROPOSAL_CONSTANT.IS_VALUE}
                        {isEditIs && "Edit " + STANDARD_PROPOSAL_CONSTANT.IS_VALUE}
                    </SidebarTitle>
                    <SidebarActionCross>
                        <IconButton
                            fileName="Icon-cross" size="medium" toolTipText='Close' toolTipArrow={false} onClick={handleCancel} />
                        <IconButton
                            fileName="Icon-tick" size="medium" toolTipText='Save' toolTipArrow={false} onClick={handleSave} />
                    </SidebarActionCross>
                </Sidebarheader>
            </div>
        </div>

        <div className={'add-edit-form'} style={{ height: '30vh', maxHeight: '30vh', overflow: 'visible', zIndex: '100' }}>
            <div className="flex-row-auto">
                <div className={'flex-row-item'}>
                    {(isAddTier || isEditTier) &&
                        <><CriteriaItemContainer>
                            {memoCycle}
                            {memoForEvery}
                            
                        </CriteriaItemContainer>
                            <CriteriaItemContainer>
                                {memoRate}
                                {memoMinimun}                                
                            </CriteriaItemContainer>
                            <CriteriaItemContainer>
                                {memoUom}
                                {memosurCharge}
                            </CriteriaItemContainer>
                            <CriteriaItemContainer>
                                {memoAdditionalRate}
                                {memoSurChargeType}                                                                
                            </CriteriaItemContainer>
                            <CriteriaItemContainer>                                
                                {memoAdditionalUom}
                            </CriteriaItemContainer>
                        </>}
                    {(isAddIs || isEditIs) &&
                        <>
                            <CriteriaItemContainer>
                                {memoShiftCode}
                                {memoPercentage}
                            </CriteriaItemContainer>
                        </>}
                </div>
            </div>
        </div>
    </div>;
}

export default memo(AddTariffItemIsEditPanel)