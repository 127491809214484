import { EMPTY_USER_ENTITY, UserEntity } from "domain/entity/User/UserEntity";
import { EMPTY_USER_GROUP_ENTITY, UserGroupEntity } from "domain/entity/userGroup/UserGroupEntity";
import { BuProfileRepository } from "domain/repository/BuProfile/BuProfileRepo";
import { MenuRepository } from "domain/repository/Menu/MenuRepo";
import { UserRepository } from "domain/repository/User/UserRepo";
import { UserGroupRepository } from "domain/repository/UserGroup/UserGroupRepo";
import _ from "lodash";
import { createPermissionValidationSchema } from "presentation/constant/UserGroup/PermissionValidationSchema";
import { createUserGroupValidationSchema } from "presentation/constant/UserGroup/UserGroupValidationSchema";
import { createUserForGroupValidationSchema } from "presentation/constant/UserGroup/UserValidationSchema";
import { Validation } from "presentation/constant/Validation";
import { EMPTY_PERMISSION_ENTITY, PermissionEntity } from "presentation/model/UserGroup/PermissionEntity";
import { EMPTY_USER_GROUP_MODEL, UserGroupModel } from "presentation/model/UserGroup/UserGroupModel";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";

interface UserGroupVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<UserGroupModel>> | ((value: SetStateAction<UserGroupModel>) => void),
    ],
    userGroupRepo: UserGroupRepository,
    menuRepo: MenuRepository,
    userRepo: UserRepository,
    buProfileRepo: BuProfileRepository
}

export const UserGroupVM = ({ dispatch, userGroupRepo, userRepo, menuRepo, buProfileRepo }: UserGroupVMProps) => {
    const [userGroupDispatch] = dispatch;

    //initial
    const loadAllUserGroups = async () => {
        userGroupDispatch(prevState => {
            return {
                ...prevState,
                userGroups: [],
            }
        })
        const result = await userGroupRepo.getAllUserGroups();
        if (result) {
            const filterAdmin = result.filter((group) => group.name !== "ADMIN");
            userGroupDispatch(prevState => {
                return {
                    ...prevState,
                    userGroups: filterAdmin ?? [],
                }
            })
        }
    }

    const loadDropdownOptions = async (currentBu: string) => {
        userRepo.getAllUsers().then(users => {
            let userDropdownOptions = users?.map((user) => ({
                dropdownLabel: `${user.name} - ${user.description}`,
                tagLabel: `${user.name} - ${user.description}`,
                value: user.name,
            })) ?? [];
            userDropdownOptions = _.orderBy(userDropdownOptions, "dropdownLabel");

            userGroupDispatch(prevState => ({
                ...prevState,
                dynamicOptions: {
                    ...prevState.dynamicOptions,
                    userDropdownOptions: userDropdownOptions
                }
            }))
        });

        menuRepo.getMenuByKey("nbisiv-main-menu").then(menus => {
            let flattenNavigations = menus?.navigation?.flatMap(test => test.submenu ?? test)
                .flatMap(test => test.submenu ?? test);
            flattenNavigations = flattenNavigations?.filter(nav => nav.permission !== "" && nav.permission !== null)

            let menuDropdownOptions = flattenNavigations?.map((nav) => ({
                dropdownLabel: `${nav.title}`,
                tagLabel: `${nav.title}`,
                value: nav.permission ?? "",
            })) ?? [];
            menuDropdownOptions = _.orderBy(menuDropdownOptions, "dropdownLabel");

            userGroupDispatch(prevState => ({
                ...prevState,
                dynamicOptions: {
                    ...prevState.dynamicOptions,
                    permissionDropdownOptions: [
                        {
                            dropdownLabel: "System Login Screen",
                            tagLabel: "System Login Screen",
                            value: "LOGIN",
                        },
                        ...menuDropdownOptions
                    ]
                }
            }))
        });

        buProfileRepo.getBuProfileByKey(currentBu).then((buProfile) => {
            let operatingCompanyDropdownOptions = buProfile.buOperatingCompanies?.map((company) => ({
                dropdownLabel: `${company.operatingCompany} - ${company.operatingCompanyName}`,
                tagLabel: `${company.operatingCompany} - ${company.operatingCompanyName}`,
                value: company.operatingCompany ?? "",
            })) ?? [];
            operatingCompanyDropdownOptions = _.orderBy(operatingCompanyDropdownOptions, "dropdownLabel");

            userGroupDispatch(prevState => ({
                ...prevState,
                dynamicOptions: {
                    ...prevState.dynamicOptions,
                    operatingCompanyDropdownOptions: operatingCompanyDropdownOptions
                }
            }))

        })
    }

    const getAllMenuList = async () => {
        return menuRepo.getMenuByKey("nbisiv-main-menu").then(menus => {
            let flattenNavigations = menus?.navigation?.flatMap(test => test.submenu ?? test)
                .flatMap(test => test.submenu ?? test);
            flattenNavigations = flattenNavigations?.filter(nav => nav.permission !== "" && nav.permission !== null)

            let menuDropdownOptions = flattenNavigations?.map((nav) => nav.permission ?? "");
            if (menuDropdownOptions) {
                return _.orderBy(menuDropdownOptions, "dropdownLabel");
            } else {
                return [];
            }
        });
    }

    const onShowLoading = () => {
        userGroupDispatch(prevState => {
            return {
                ...prevState,
                isLoading: true
            }
        })
    }

    const onHideLoading = () => {
        userGroupDispatch(prevState => {
            return {
                ...prevState,
                isLoading: false
            }
        })
    }
    //initial

    //user group
    const onUserGroupAddClick = () => {
        userGroupDispatch(prevState => {
            return {
                ...prevState,
                userGroupState: {
                    ...prevState.userGroupState,
                    isTabularDataActive: false,
                    isAdd: true,
                    isRead: false,
                    isEditable: false,
                    isSaveClicked: false,
                    currentSelectedData: EMPTY_USER_GROUP_ENTITY,
                    lastEditRowId: '',
                    isSliderOpen: true,
                },
                userState: {
                    ...prevState.userState,
                    isTabularDataActive: true,
                    isAdd: false,
                    isRead: true,
                    isEditable: false,
                    isSaveClicked: false,
                    currentSelectedData: EMPTY_USER_ENTITY,
                    lastEditRowId: '',
                    isSliderOpen: false,
                },
                permissionState: {
                    ...prevState.permissionState,
                    isTabularDataActive: true,
                    isAdd: false,
                    isRead: true,
                    isEditable: false,
                    isSaveClicked: false,
                    currentSelectedData: EMPTY_PERMISSION_ENTITY,
                    lastEditRowId: '',
                    isSliderOpen: true,
                }
            }
        })
    }

    //user group renew
    const onUserGroupRenewClick = () => {
        userGroupDispatch(prevState => {
            return {
                ...prevState,
                userGroupState: {
                    ...prevState.userGroupState,
                    isTabularDataActive: false,
                    isAdd: true,
                    isRead: false,
                    isEditable: false,
                    isSaveClicked: false,
                    currentSelectedData: {
                        ...prevState.userGroupState.currentSelectedData,
                        name: "",
                        version: 0
                    },
                    lastEditRowId: '',
                    isSliderOpen: true,
                },
                userState: {
                    ...prevState.userState,
                    isTabularDataActive: true,
                    isAdd: false,
                    isRead: true,
                    isEditable: false,
                    isSaveClicked: false,
                    //currentSelectedData: EMPTY_USER_ENTITY,
                    lastEditRowId: '',
                    isSliderOpen: false,
                },
                permissionState: {
                    ...prevState.permissionState,
                    isTabularDataActive: true,
                    isAdd: false,
                    isRead: true,
                    isEditable: false,
                    isSaveClicked: false,
                    //currentSelectedData: EMPTY_PERMISSION_ENTITY,
                    lastEditRowId: '',
                    isSliderOpen: true,
                }
            }
        })
    }

    const onSelectedUserGroups = (selectedRows: UserGroupEntity[]) => {
        userGroupDispatch(prevState => {
            return {
                ...prevState,
                userGroupState: {
                    ...prevState.userGroupState,
                    currentSelectedData: selectedRows ? selectedRows[0] : { ...EMPTY_USER_GROUP_ENTITY }
                }
            }
        })
    }

    const onUserGroupRowDoubleClick = (userGroup: UserGroupEntity) => {
        const permissionList = userGroup.permissions?.map((permission) => ({
            ...permission,
            tempAttributes: permission.attributes?.join(',')
        })) ?? [];
        userGroup = {
            ...userGroup,
            permissions: permissionList,
        }
        userGroupDispatch(prevState => {
            return {
                ...prevState,
                userGroupState: {
                    ...prevState.userGroupState,
                    isTabularDataActive: false,
                    isAdd: false,
                    isRead: true,
                    isEditable: true,
                    isSaveClicked: false,
                    currentSelectedData: userGroup,
                    lastEditRowId: userGroup.name,
                    isSliderOpen: true,
                },
                userState: {
                    ...prevState.userState,
                    isTabularDataActive: false,
                    isAdd: false,
                    isRead: true,
                    isEditable: true,
                    isSliderOpen: false,
                    isSelected: false,
                },
                permissionState: {
                    ...prevState.permissionState,
                    currentSelectedData: {
                        ...prevState.permissionState.currentSelectedData,

                    },
                    isTabularDataActive: false,
                    isAdd: false,
                    isRead: true,
                    isEditable: true,
                    isSliderOpen: false,
                    isSelected: false,
                }
            }
        })
    }

    const onUserGroupRowDeleteClick = (userGroup: UserGroupEntity) => {
        return userGroupRepo.deleteUserGroup(userGroup.name).then((result) => {
            if (result) {
                userGroupDispatch(prevState => {
                    const filterUers = prevState.userGroups?.filter((curUserGroup) =>
                        curUserGroup.name !== userGroup.name);
                    return {
                        ...prevState,
                        users: filterUers,
                        userGroupState: {
                            ...prevState.userGroupState,
                            allFormState: { "DeleteUserGroupSuccess": "" }
                        }
                    };
                });
            }
        }).catch((error) => {
            return userGroupDispatch(prevState => {
                return {
                    ...prevState,
                    userGroupState: {
                        ...prevState.userGroupState,
                        allFormState: { "DeleteUserGroupFail": error.message }
                    }
                };
            });
        });
    }

    const onCloseClick = () => {
        userGroupDispatch(prevState => {
            return {
                ...prevState,
                userState: {
                    ...prevState.userState,
                    isRead: false,
                    isSliderOpen: false,
                    isEditable: false,
                    isAdd: false,
                    isTabularDataActive: true
                },
                userGroupState: {
                    ...prevState.userGroupState,
                    isRead: false,
                    isSliderOpen: false,
                    isEditable: false,
                    isAdd: false,
                    isTabularDataActive: true
                },
                permissionState: {
                    ...prevState.permissionState,
                    isRead: false,
                    isSliderOpen: false,
                    isEditable: false,
                    isAdd: false,
                }
            }
        });
    }

    const onResetClick = () => {
        userGroupDispatch(prevState => {
            const originalUserGroup = prevState.userGroupState.lastEditRowId === "" ? EMPTY_USER_GROUP_ENTITY :
                prevState.userGroups.filter((userGroup) => userGroup.name === prevState.userGroupState.lastEditRowId)[0];
            return {
                ...prevState,
                userGroupState: {
                    ...prevState.userGroupState,
                    currentSelectedData: originalUserGroup,
                }
            }
        })
    }

    const onEditClick = () => {
        userGroupDispatch(prevState => {
            return {
                ...prevState,
                userGroupState: {
                    ...prevState.userGroupState,
                    isEditable: true,
                    isRead: false,
                },
                userState: {
                    ...prevState.userState,
                    isRead: prevState.userState.isSliderOpen ? false : true,
                    isEditable: false,
                    isSliderOpen: false,
                },
                permissionState: {
                    ...prevState.permissionState,
                    isRead: prevState.permissionState.isSliderOpen ? false : true,
                    isEditable: false,
                    isSliderOpen: false,
                }
            }
        })
    }

    const onUserGroupFieldChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }
        return userGroupDispatch(prevState => {
            return {
                ...prevState,
                userGroupState: {
                    ...prevState.userGroupState,
                    currentSelectedData: {
                        ...prevState.userGroupState.currentSelectedData,
                        [fieldKey]: val
                    }
                }
            }
        });
    }

    const onUserGroupSaveClicked = () => {
        userGroupDispatch(prevState => {
            return {
                ...prevState,
                userGroupState: {
                    ...prevState.userGroupState,
                    isSaveClicked: true
                }
            }
        })
    }

    const onUserGroupSave = async (userGroup: UserGroupEntity, isAdd: boolean) => {
        const res = await Validation(createUserGroupValidationSchema).ValidateFormOnly(userGroup);
        if (res) {
            userGroupDispatch(prevState => {
                return {
                    ...prevState,
                    userGroupState: {
                        ...prevState.userGroupState,
                        allFormState: res
                    }
                }
            });
            return res;
        } else {
            //must save user
            if (isAdd) {
                return userGroupRepo.createNewUserGroup(userGroup).then((result) => {
                    userGroupDispatch(prevState => {
                        return {
                            ...prevState,
                            userState: {
                                ...prevState.userState,
                                isAdd: false,
                                isRead: false,
                                isEditable: false,
                                isSelected: false,
                                isSaveClicked: false,
                                isSliderOpen: false,
                                isTabularDataActive: false,
                                allFormState: {},
                                currentSelectedData: EMPTY_USER_ENTITY
                            },
                            userGroupState: {
                                ...prevState.userGroupState,
                                isSliderOpen: false,
                                isAdd: false,
                                isRead: true,
                                isEditable: false,
                                isTabularDataActive: true,
                                currentSelectedData: EMPTY_USER_GROUP_ENTITY
                            },
                            permissionState: {
                                ...prevState.permissionState,
                                isSliderOpen: false,
                                isAdd: false,
                                isRead: true,
                                isEditable: false,
                                isTabularDataActive: true,
                                currentSelectedData: EMPTY_PERMISSION_ENTITY
                            }
                        };
                    });
                }).catch((error) => {
                    userGroupDispatch(prevState => {
                        return {
                            ...prevState,
                            prevState: EMPTY_USER_GROUP_MODEL,
                            userState: {
                                ...prevState.userState,
                                allFormState: { "SaveUserGroupFail": error.message }
                            }
                        };
                    });

                    return { "SaveUserGroupFail": error.message }
                });
            } else {

                return userGroupRepo.updateUserGroup(userGroup).then((result) => {
                    userGroupDispatch(prevState => {
                        return {
                            ...prevState,
                            userState: {
                                ...prevState.userState,
                                isAdd: false,
                                isRead: false,
                                isEditable: false,
                                isSelected: false,
                                isSaveClicked: false,
                                isSliderOpen: false,
                                isTabularDataActive: true,
                                allFormState: {},
                                currentSelectedData: EMPTY_USER_ENTITY
                            },
                            userGroupState: {
                                ...prevState.userGroupState,
                                isSliderOpen: false,
                                isAdd: false,
                                isRead: true,
                                isEditable: false,
                                isTabularDataActive: true,
                                currentSelectedData: EMPTY_USER_GROUP_ENTITY
                            },
                            permissionState: {
                                ...prevState.permissionState,
                                isSliderOpen: false,
                                isAdd: false,
                                isRead: true,
                                isEditable: false,
                                isTabularDataActive: true,
                                currentSelectedData: EMPTY_PERMISSION_ENTITY
                            }

                        };
                    });
                }).catch((error) => {
                    userGroupDispatch(prevState => {
                        return {
                            ...prevState,
                            prevState: EMPTY_USER_GROUP_MODEL,
                            userState: {
                                ...prevState.userState,
                                allFormState: { "SaveUserGroupFail": error.message }
                            }
                        };
                    });

                    return { "SaveUserGroupFail": error.message }
                });
            }
        }
    }
    //user group

    //user
    const onUserFieldChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }
        userGroupDispatch(prevState => {
            return {
                ...prevState,
                userState: {
                    ...prevState.userState,
                    currentSelectedData: {
                        ...prevState.userState.currentSelectedData,
                        [fieldKey]: val
                    }
                }
            }
        });
    }

    const onUserAddClick = () => {
        userGroupDispatch(prevState => {
            return {
                ...prevState,
                permissionState: {
                    ...prevState.permissionState,
                    isTabularDataActive: false,
                    isAdd: false,
                    isRead: false,
                    isEditable: false,
                    isSliderOpen: true,
                    isSelected: false,
                    currentSelectedData: EMPTY_PERMISSION_ENTITY
                },
                userState: {
                    ...prevState.userState,
                    isTabularDataActive: false,
                    isAdd: true,
                    isRead: false,
                    isEditable: false,
                    isSliderOpen: true,
                    isSelected: false,
                    currentSelectedData: EMPTY_USER_ENTITY
                },
            }
        })
    }

    const onUserCloseClick = () => {
        userGroupDispatch(prevState => {
            return {
                ...prevState,
                userState: {
                    ...prevState.userState,
                    isTabularDataActive: false,
                    isAdd: false,
                    isRead: false,
                    isEditable: false,
                    isSliderOpen: false,
                    isSelected: false,
                    currentSelectedData: EMPTY_USER_ENTITY
                },
            }
        })
    }

    const onUserSave = async (user: UserEntity, isAdd: boolean) => {
        const res = await Validation(createUserForGroupValidationSchema).ValidateFormOnly(user);
        if (res) {
            userGroupDispatch(prevState => {
                return {
                    ...prevState,
                    userState: {
                        ...prevState.userState,
                        allFormState: res
                    }
                }
            })
            return;
        } else {
            userGroupDispatch(prevState => {
                let finallyUsers = [];

                let newUsers: UserEntity[] = [];
                newUsers = user.nameList?.map((name) => ({
                    ...EMPTY_USER_ENTITY,
                    name: name,
                    description: prevState.dynamicOptions.userDropdownOptions?.find(user => user.value === name)?.dropdownLabel
                })) ?? [];

                const existedUsers = prevState.userGroupState.currentSelectedData.users?.filter((user) =>
                    !newUsers.find((newUser) => newUser.name === user.name)
                )
                finallyUsers = [
                    ...existedUsers ?? [],
                    ...newUsers
                ]
                return {
                    ...prevState,
                    userGroupState: {
                        ...prevState.userGroupState,
                        currentSelectedData: {
                            ...prevState.userGroupState.currentSelectedData,
                            users: finallyUsers
                        },
                    },
                    userState: {
                        ...prevState.userState,
                        isAdd: false,
                        isEditable: false,
                        isRead: false,
                        isSliderOpen: false,
                        isSelected: false,
                        isSaveClicked: true,
                    }
                };
            });
        }
    }

    const onUserRowDoubleClick = (user: UserEntity) => {
        userGroupDispatch(prevState => {
            return {
                ...prevState,
                userState: {
                    ...prevState.userState,
                    isTabularDataActive: false,
                    isAdd: false,
                    isRead: (prevState.userGroupState.isAdd || (prevState.userGroupState.isEditable && !prevState.userGroupState.isRead)) ? false : true,
                    isEditable: true,
                    isSliderOpen: true,
                    isSelected: false,
                    currentSelectedData: user,
                    lastEditRowId: user.name
                },
            }
        })
    }

    const onUserRowDeleteClick = (user: UserEntity) => {
        userGroupDispatch(prevState => {
            const newUsers = prevState.userGroupState.currentSelectedData.users?.filter((curUser: any) => user.name !== curUser.name);
            return {
                ...prevState,
                userGroupState: {
                    ...prevState.userGroupState,
                    isTabularDataActive: false,
                    /*isAdd: false,
                    isRead: false,
                    isEditable: true,
                    isSliderOpen: true,
                    isSelected: true,*/
                    currentSelectedData: {
                        ...prevState.userGroupState.currentSelectedData,
                        users: newUsers,    
                    }
                }
            }
        })
    }

    const onUserSaveClicked = () => {
        userGroupDispatch(prevState => {
            return {
                ...prevState,
                userState: {
                    ...prevState.userState,
                    isSaveClicked: true,
                }
            }
        })
    }

    //user

    //permission
    const onPermissionAddClick = () => {
        userGroupDispatch(prevState => {
            return {
                ...prevState,
                permissionState: {
                    ...prevState.permissionState,
                    isTabularDataActive: false,
                    isAdd: true,
                    isRead: false,
                    isEditable: false,
                    isSliderOpen: true,
                    isSelected: false,
                    currentSelectedData: EMPTY_PERMISSION_ENTITY
                },
                userState: {
                    ...prevState.userState,
                    isTabularDataActive: false,
                    isAdd: false,
                    isRead: false,
                    isEditable: false,
                    isSliderOpen: true,
                    isSelected: false,
                    currentSelectedData: EMPTY_USER_ENTITY
                },
            }
        })
    }

    const onPermissiCloseClick = () => {
        userGroupDispatch(prevState => {
            return {
                ...prevState,
                permissionState: {
                    ...prevState.permissionState,
                    isTabularDataActive: false,
                    isAdd: false,
                    isRead: false,
                    isEditable: false,
                    isSliderOpen: false,
                    isSelected: false,
                    currentSelectedData: EMPTY_PERMISSION_ENTITY
                },
                userState: {
                    ...prevState.userState,
                    isSliderOpen: false,
                }
            }
        })
    }

    const onPermissionSave = async (permission: PermissionEntity, isAdd: boolean) => {
        const res = await Validation(createPermissionValidationSchema).ValidateFormOnly(permission);
        if (res) {
            userGroupDispatch(prevState => {
                return {
                    ...prevState,
                    permissionState: {
                        ...prevState.permissionState,
                        allFormState: res
                    }
                }
            })
            return;
        } else {
            let permissionList: any[] = [];
            const { read, create, modify, attributes, tempResource, delete: permissionDelete } = permission;
            permissionList = tempResource?.map((resource) => ({
                resource: resource,
                read: read,
                modify: modify,
                create: create,
                delete: permissionDelete,
                attributes: attributes,
                tempAttributes: attributes.join(',')
            })) ?? [];

            userGroupDispatch(prevState => {
                let newPermissionList = [];
                const existedPermissionList = prevState.userGroupState.currentSelectedData.permissions?.filter((per) =>
                    !permissionList.find((newPer) => per.resource === newPer.resource)
                )
                newPermissionList = [
                    ...existedPermissionList?.slice() ?? [],
                    ...permissionList
                ]

                return {
                    ...prevState,
                    userGroupState: {
                        ...prevState.userGroupState,
                        currentSelectedData: {
                            ...prevState.userGroupState.currentSelectedData,
                            permissions: newPermissionList
                        },
                    },
                    permissionState: {
                        ...prevState.permissionState,
                        isAdd: false,
                        isEditable: false,
                        isRead: false,
                        isSliderOpen: false,
                        isSelected: false,
                        isSaveClicked: true,
                    }
                };
            });
        }
    }

    const onPermissionRowDoubleClick = (permission: PermissionEntity) => {
        userGroupDispatch(prevState => {
            return {
                ...prevState,
                permissionState: {
                    ...prevState.permissionState,
                    isTabularDataActive: false,
                    isAdd: false,
                    isRead: (prevState.userGroupState.isAdd || (prevState.userGroupState.isEditable && !prevState.userGroupState.isRead)) ? false : true,
                    isEditable: true,
                    isSliderOpen: true,
                    isSelected: false,
                    currentSelectedData: permission,
                    lastEditRowId: permission.resource
                },
            }
        })
    }

    const onPermissionRowDeleteClick = (permission: PermissionEntity) => {
        userGroupDispatch(prevState => {
            const newPermissions = prevState.userGroupState.currentSelectedData.permissions?.filter((curPermission: any) => permission.resource !== curPermission.resource);
            return {
                ...prevState,
                userGroupState: {
                    ...prevState.userGroupState,
                    isTabularDataActive: false,
                    /*isAdd: false,
                    isRead: false,
                    isEditable: true,
                    isSliderOpen: true,
                    isSelected: true,*/
                    currentSelectedData: {
                        ...prevState.userGroupState.currentSelectedData,
                        permissions: newPermissions,
                    }
                }
            }
        })
    }

    const onPermissionSaveClicked = () => {
        userGroupDispatch(prevState => {
            return {
                ...prevState,
                permissionState: {
                    ...prevState.permissionState,
                    isSaveClicked: true,
                }
            }
        })
    }

    const onPermissionFieldChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }
        userGroupDispatch(prevState => {
            return {
                ...prevState,
                permissionState: {
                    ...prevState.permissionState,
                    currentSelectedData: {
                        ...prevState.permissionState.currentSelectedData,
                        [fieldKey]: val
                    }
                }
            }
        });
    }
    //permission

    return {
        loadAllUserGroups: loadAllUserGroups,
        loadDropdownOptions: loadDropdownOptions,
        onShowLoading: onShowLoading,
        onHideLoading: onHideLoading,
        onUserGroupAddClick: onUserGroupAddClick,
        onUserGroupRowDoubleClick: onUserGroupRowDoubleClick,
        onUserGroupRowDeleteClick: onUserGroupRowDeleteClick,
        onCloseClick: onCloseClick,
        onResetClick: onResetClick,
        onEditClick: onEditClick,
        onUserGroupFieldChange: onUserGroupFieldChange,
        onUserSaveClicked: onUserSaveClicked,
        onUserGroupSaveClicked: onUserGroupSaveClicked,
        onUserGroupSave: onUserGroupSave,
        onUserFieldChange: onUserFieldChange,
        onUserAddClick: onUserAddClick,
        onUserCloseClick: onUserCloseClick,
        onUserRowDoubleClick: onUserRowDoubleClick,
        onUserRowDeleteClick: onUserRowDeleteClick,
        onPermissionAddClick: onPermissionAddClick,
        onPermissionRowDoubleClick: onPermissionRowDoubleClick,
        onPermissionRowDeleteClick: onPermissionRowDeleteClick,
        onPermissionSaveClicked: onPermissionSaveClicked,
        onUserSave: onUserSave,
        onPermissiCloseClick: onPermissiCloseClick,
        onPermissionSave: onPermissionSave,
        onPermissionFieldChange: onPermissionFieldChange,
        getAllMenuList: getAllMenuList,
        onUserGroupRenewClick: onUserGroupRenewClick,
        onSelectedUserGroups: onSelectedUserGroups
    }
}