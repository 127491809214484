import * as yup from 'yup';

export const commonRecalProcessAndGenValidation: { [x: string]: any; } = {
  consolidationType: yup.string().required("Missing"),
  chargeOnCompany: yup.string().required("Missing"),
  opsDateFrom: yup.string().required("Missing"),
  opsDateTo: yup.string().required("Missing")
};

export const recalProcessAndGenValidation: { [x: string]: any; } = {
  ...commonRecalProcessAndGenValidation,
};

export const recalProcessAndGenValidationSchema = yup.object().shape(recalProcessAndGenValidation);

