import { DocumentApprovalConstant } from "presentation/constant/DocumentApproval/DocumentApprovalConstant";
import { useDocumentApprovalVM } from "presentation/hook/DocumentApproval/useDocumentApprovalVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useDocumentApprovalTracked } from "presentation/store/DocumentApproval/DocumentApprovalProvider";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import { memo, useCallback } from "react";
import { HPHButton, IconButton } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const DocumentApprovalTitleBar: React.FC = () => {
    const DOCUMENT_APPROVAL_CONSTANT = DocumentApprovalConstant.Title;
    const [documentApprovalState] = useDocumentApprovalTracked();
    const documentApprovalVM = useDocumentApprovalVM();
    const messageBarVM = useMessageBarVM();
    const { selectRows } = documentApprovalState.documentApprovalState;
    const [anaInfoState] = useANAInfoTracked();
    const { allowUpdate } = anaInfoState;

    const handleSearch = useCallback(() => {
        documentApprovalVM.onSearchClick();
    }, [documentApprovalVM]);

    const handleApprove = useCallback(() => {
        if (selectRows.length === 0) {
            messageBarVM.showError("Please select a record.");
            return;
        }
        documentApprovalVM.onShowLoading();
        let rows = selectRows.slice();
        const ids: number[] = rows.map(row => row.id);
        documentApprovalVM.onApproveClick(ids).then((data) => {
            if (data) {
                messageBarVM.showSuccess("Approve successful.");
                documentApprovalVM.onSearch(documentApprovalState.searchCriteria).then((data) => {
                    documentApprovalVM.onHideLoading();
                }).catch((error) => {
                    documentApprovalVM.onHideLoading();
                })
            } else {
                messageBarVM.showError("Approve Failed.");
                documentApprovalVM.onHideLoading();
            }
        }).catch((error) => {
            documentApprovalVM.onHideLoading();
            messageBarVM.showError("Approve Failed.");
        })
    }, [documentApprovalState.searchCriteria, documentApprovalVM, messageBarVM, selectRows]);

    const handleReject = useCallback(() => {
        if (selectRows.length === 0) {
            messageBarVM.showError("Please select a record.");
        }
        let rows = selectRows.slice();
        const ids: number[] = rows.map(row => row.id);
        documentApprovalVM.onRejectClick(ids).then((data) => {
            if (data) {
                messageBarVM.showSuccess("Reject successful.");
                documentApprovalVM.onSearch(documentApprovalState.searchCriteria).then((data) => {
                    documentApprovalVM.onHideLoading();
                }).catch((error) => {
                    documentApprovalVM.onHideLoading();
                })
            } else {
                messageBarVM.showError("Reject Failed.");
                documentApprovalVM.onHideLoading();
            }
        }).catch((error) => {
            documentApprovalVM.onHideLoading();
            messageBarVM.showError("Reject Failed.");
        })
    }, [documentApprovalState.searchCriteria, documentApprovalVM, messageBarVM, selectRows]);

    const handlePreviewDocument = useCallback(() => {
        messageBarVM.showError("The current operation is not implemented");
    }, [messageBarVM]);


    const isDisable = () => {
        return documentApprovalState.documentApprovalState.selectRows.length === 0;
    }


    return <Sidebarheader style={{ width: '100%', display: "flex", alignItems: "center" }}>
        <HeaderTitle>{DOCUMENT_APPROVAL_CONSTANT.TITLE}</HeaderTitle>
        <StyledAction className="tm-animated-wrapper">
            <IconButton fileName='Icon-search' disabled={false} size='medium' toolTipText={'Search'} toolTipArrow={false} onClick={handleSearch} />
            {(allowUpdate) && <><div className="add-seperator" />
                <HPHButton label={DOCUMENT_APPROVAL_CONSTANT.PREVIEW_DOCUMENT} disabled={isDisable()} size={'Small'} theme={'Secondary'} onClick={handlePreviewDocument} />
                <div className="add-seperator" />
                <HPHButton label={DOCUMENT_APPROVAL_CONSTANT.REJECT} disabled={isDisable()} size={'Small'} theme={'Secondary'} onClick={handleReject} />
                <HPHButton label={DOCUMENT_APPROVAL_CONSTANT.APPROVE} disabled={isDisable()} size={'Small'} theme={'Primary'} onClick={handleApprove} /></>}
        </StyledAction>
    </Sidebarheader>
}

export default memo(DocumentApprovalTitleBar);