import { CompanyConstant } from "presentation/constant/Company/CompanyConstant";
import { MessageConstant } from "presentation/constant/MessageConstant";
import { useCompanyDetailVM } from "presentation/hook/Company/useCompanyDetailVM";
import { useCompanyTariffNatureVM } from "presentation/hook/Company/useCompanyTariffNatureVM";
import { useCompanyTerminalVM } from "presentation/hook/Company/useCompanyTerminalVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useCompanyDetailTracked } from "presentation/store/Company/CompanyDetailProvider";

import { useCompanyMaintenanceTracked } from "presentation/store/Company/CompanyMaintenanceProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DatePickerComponent from "presentation/view/components/DatePickerComponent";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { memo, useCallback, useMemo, useState } from "react";
import { FieldType, HPHCheckbox, HPHInputTextarea, IconButton, IFieldValue, InputField, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarActionCross, Sidebarheader, SidebarTitle } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const CompanyInfoDetailFormPanel = () => {
    const [companyState] = useCompanyMaintenanceTracked();
    const [companyDetailState] = useCompanyDetailTracked();
    const companyDetailVM = useCompanyDetailVM();
    const companyTerminalVM = useCompanyTerminalVM()
    const companyTariffNatureVM = useCompanyTariffNatureVM();
    const messageBarVM = useMessageBarVM();

    const COMPANY_CONST = CompanyConstant.Header;
    const { currentCompany } = companyState;
    const { companyId } = companyDetailState;
    const [isLoading, setIsLoading] = useState(false);
    const { allFormState, isEditCompDtlInfo } = companyDetailState.viewState
    const { selectedTerminals } = companyDetailState.companyTerminalState
    const { selectedTariffNatures } = companyDetailState.companyTariffNatureState

    const handleEdit = useCallback(() => {
        companyDetailVM.onEdit();
    }, [companyDetailVM])

    const handleReset = useCallback(() => {
        companyDetailVM.onReset();
    }, [companyDetailVM])

    const handleSaveClick = useCallback(async () => {
        if (companyId) {
            setIsLoading(true);

            Promise.allSettled([
                companyTerminalVM.onSave(selectedTerminals, companyId),
                companyTariffNatureVM.onSave(selectedTariffNatures, companyId)
            ]).then(data => {
                setIsLoading(false);
                messageBarVM.showSuccess(MessageConstant.common.UPDATED_DATA_SUCCESSFUL);
                companyDetailVM.onReset();
            }).catch(e => {
                setIsLoading(false);
                messageBarVM.showError('Save company update failed.');
            })
        }
    }, [companyDetailVM, companyId, companyTariffNatureVM, companyTerminalVM, messageBarVM, selectedTariffNatures, selectedTerminals])

    const memoCompanyCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px" }}>
            <InputField
                width='185px'
                maxLength={4}
                label={COMPANY_CONST.COMPANY_CODE}
                disabled={true}
                type="text"
                value={currentCompany?.companyCode ?? ''}
                errorMessage={allFormState ? allFormState["companyCode"] : ""}
                onChange={() => { }} />
        </div>
        , [COMPANY_CONST.COMPANY_CODE, allFormState, currentCompany?.companyCode])

    const memoCompanyName = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "405px", marginBottom: "24px" }}>
            <InputField
                width='405px'
                maxLength={60}
                label={COMPANY_CONST.COMPANY_NAME}
                disabled={true}
                type="text"
                value={currentCompany?.companyName ?? ''}
                onChange={() => { }} />
        </div>
        , [COMPANY_CONST.COMPANY_NAME, currentCompany?.companyName])

    const memoActiveInd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "190px", marginBottom: "24px" }}>
            <NbisCommonField
                fieldKey={"activeInd"}
                requiredFieldList={[]}
                fieldLabel={COMPANY_CONST.ACTIVE_IND}
                fieldType={FieldType.TEXT}
                isReadOnly={true}
                onFieldChange={() => { }} />
            <HPHCheckbox
                label={''}
                checked={currentCompany?.activeInd === 'Y' ? true : false}
                disabled={true}
                onChange={() => { }} />
        </div>
        , [COMPANY_CONST.ACTIVE_IND, currentCompany?.activeInd])

    const memoCompanyType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px" }}>
            <InputField
                width='185px'
                maxLength={30}
                label={COMPANY_CONST.COMPANY_TYPE}
                disabled={true}
                type="text"
                value={currentCompany?.companyType ?? ''}
                onChange={() => { }} />
        </div>
        , [COMPANY_CONST.COMPANY_TYPE, currentCompany?.companyType])

    const memoMasterCompanyCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "190px", marginBottom: "24px" }}>
            <InputField
                width='190px'
                maxLength={4}
                label={COMPANY_CONST.MASTER_COMPANY}
                disabled={true}
                type="text"
                value={currentCompany?.masterCompany?.companyCode ?? ''}
                onChange={() => { }} />
        </div>
        , [COMPANY_CONST.MASTER_COMPANY, currentCompany?.masterCompany?.companyCode])

    const memoAllianceCompanyCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px" }}>
            <InputField
                width='185px'
                maxLength={4}
                label={COMPANY_CONST.ALLIANCE}
                disabled={true}
                type="text"
                value={currentCompany?.alliance?.companyCode ?? ''}
                onChange={() => { }} />
        </div>
        , [COMPANY_CONST.ALLIANCE, currentCompany?.alliance?.companyCode])

    const memoCustomerType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "190px", marginBottom: "24px" }}>
            <InputField
                width='190px'
                maxLength={15}
                label={CompanyConstant.Detail.CUSTOMER_TYPE}
                disabled={true}
                type="text"
                value={currentCompany?.customerType ?? ''}
                onChange={() => { }} />
        </div>
        , [currentCompany?.customerType])

    const memoAddress = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "405px", marginBottom: "24px" }}>
            <HPHInputTextarea
                label={CompanyConstant.Detail.ADDRESS}
                width="405px"
                rows={3}
                disabled={true}
                resizable={false}
                helpIcon={false}
                value={((currentCompany?.address1 ?? '') + '\n' + (currentCompany?.address2 ?? '') + '\n' + (currentCompany?.address3 ?? ''))}
                onChange={() => { }} />
        </div>
        , [currentCompany?.address1, currentCompany?.address2, currentCompany?.address3])

    const memoCountryCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "190px", marginBottom: "24px" }}>
            <InputField
                width='190px'
                maxLength={4}
                label={COMPANY_CONST.COUNTRY_CODE}
                disabled={true}
                type="text"
                value={currentCompany?.country?.countryCode ?? ''}
                onChange={() => { }} />
        </div>
        , [COMPANY_CONST.COUNTRY_CODE, currentCompany?.country?.countryCode])

    const memoSignOnDate = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px" }}>
            <DatePickerComponent
                disabled={true}
                label={CompanyConstant.Detail.SIGN_ON_DATE}
                width="185px"
                date={currentCompany?.signOnDate ?? ''}
                fieldName="signOnDate"
                onDateChange={() => { }} />
        </div>
        , [currentCompany?.signOnDate])

    const memoContractTml = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!isEditCompDtlInfo}
                isShowOptional={true}
                isMultipleDropDown={true}
                disableInput={false}
                readOnlyValue={selectedTerminals?.map((item) => item?.terminalCode).join(',') ?? ''}
                fieldValue={selectedTerminals?.map((item) => item?.terminalCode)}
                fieldLabel={CompanyConstant.Detail.CONTRACT_TML}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'terminalCode'}
                options={companyDetailState.dynamicOptions.contractTmlDropdownOptions}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    companyTerminalVM.onMultipleDropdownChange(fieldKey, fieldValue && fieldValue as string[], fFullValue)} />
        </div>
        , [allFormState, companyDetailState.dynamicOptions.contractTmlDropdownOptions, companyTerminalVM, isEditCompDtlInfo, selectedTerminals])

    const memoTariffNature = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "190px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!isEditCompDtlInfo}
                isShowOptional={true}
                isMultipleDropDown={true}
                disableInput={false}
                readOnlyValue={selectedTariffNatures?.map((item) => item?.tariffNature).join(',') ?? ''}
                fieldValue={selectedTariffNatures?.map((item) => item?.tariffNature)}
                fieldLabel={CompanyConstant.Detail.TARIFF_NATURE}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'tariffNature'}
                options={companyDetailState.dynamicOptions.tariffNatureDropdownOptions}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    companyTariffNatureVM.onMultipleDropdownChange(fieldKey, fieldValue && fieldValue as string[], fFullValue)} />
        </div>
        , [allFormState, companyDetailState.dynamicOptions.tariffNatureDropdownOptions, companyTariffNatureVM, isEditCompDtlInfo, selectedTariffNatures])

    return (
        <div className='side-form-content-left-wrapper'>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>{currentCompany?.companyCode ?? ''}</SidebarTitle>
                        <SidebarActionCross>
                            {
                                <>
                                    {
                                        (
                                            (!isEditCompDtlInfo)
                                        )
                                        && <IconButton fileName='Icon-pen' size='medium' disabled={isEditCompDtlInfo} toolTipText={'Edit'} toolTipArrow={false} onClick={handleEdit} />
                                    }
                                </>}

                            {(isEditCompDtlInfo) &&
                                <>
                                    {<IconButton fileName="Icon-cross" toolTipText="Cancel" disabled={!isEditCompDtlInfo} toolTipArrow={false} toolTipPlacement="bottom" size="medium" onClick={handleReset} />
                                    }
                                    {
                                        <IconButton fileName="Icon-tick" size="medium" disabled={!isEditCompDtlInfo} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Save'} onClick={handleSaveClick} />
                                    }
                                </>}
                        </SidebarActionCross>
                    </Sidebarheader>
                </div>
            </div>

            {isLoading && <Loader Indicator="Spinner" size="Medium" />}

            <div className={'add-edit-form'} style={{ height: '75vh', maxHeight: '75vh', overflow: 'auto' }}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        <CriteriaItemContainer>
                            {memoCompanyCode}
                            {memoActiveInd}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoCompanyName}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoCompanyType}
                            {memoMasterCompanyCode}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoAllianceCompanyCode}
                            {memoCustomerType}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoAddress}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoSignOnDate}
                            {memoCountryCode}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoContractTml}
                            {memoTariffNature}
                        </CriteriaItemContainer>

                    </div>
                </div>
            </div>

            <div className='im-charge-data-search-criteria-buttons'>
                {/*
                    <>
                        <HPHButton label={'Cancel'} size={'Small'} theme={'Secondary'} onClick={()=>handleCancelClick()} />
                        <HPHButton label={isAdd?'Add':isEditable?'Update':'Save'} size={'Small'} theme={'Primary'} onClick={()=>handleSaveClick()} />
                    </>
                */}
            </div>

        </div>
    )
}
export default memo(CompanyInfoDetailFormPanel);