import { EmptyPoolExclusionConstant } from "presentation/constant/EmptyPoolExclusion/EmptyPoolExclusionConstant";
import { useEmptyPoolExclusionCriteriaVM } from "presentation/hook/EmptyPoolExclusionCriteria/useEmptyPoolExclusionCriteriaVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useEmptyExclCriteriaTracked } from "presentation/store/EmptyPoolExclusionCriteria/EmptyPoolExclusionCriteriaProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DatePickerComponent from "presentation/view/components/DatePickerComponent";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { NbisIconButton } from "presentation/view/components/NbisIconButton";
import { useCallback, useMemo, useState } from "react";
import { FieldType, HPHButton, HPHCheckbox, IFieldValue, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarActionCross, SidebarCaption, Sidebarheader, SidebarTitle } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";


export const EmptyPoolExclusionCriteriaComponentPanel = () => {
    const [criteriaState] = useEmptyExclCriteriaTracked();
    const criteriaVM = useEmptyPoolExclusionCriteriaVM();
    const CRITERIA_CONSTANT = EmptyPoolExclusionConstant.Table;
    const [isLoading, setIsLoading] = useState(false);
    const { currentSelectedRow } = criteriaState;
    const { isAdd, isEditable, isRead, isSaveClicked, allFormState, editingCriteriaEntity } = criteriaState.masterState;
    const currentEntity = isRead ? currentSelectedRow : editingCriteriaEntity;
    const messageBarVM = useMessageBarVM();

    const handleCancelClick = () => {
        criteriaVM.onCancel();
    }

    const handleSaveClick = useCallback(async () => {
        let isSave = true;
        if (isAdd && criteriaState.criteriaEntityList.some(item => (item.chargeOnCompany === currentEntity.chargeOnCompany
            && item.cntrType === currentEntity.cntrType && item.opsLine === currentEntity.opsLine && item.cntrStatus === currentEntity.cntrStatus
            && item.block === currentEntity.block && item.stack === currentEntity.stack && item.tier === currentEntity.tier
            && item.locationType === currentEntity.locationType && item.operatingTml === currentEntity.operatingTml
            && item.lane === currentEntity.lane))) {
            isSave = false;
        }
        if (isEditable) {
            if (criteriaState.criteriaEntityList.filter(item => item.id !== currentEntity.id).some(item => (item.chargeOnCompany === currentEntity.chargeOnCompany
                && item.cntrType === currentEntity.cntrType && item.opsLine === currentEntity.opsLine && item.cntrStatus === currentEntity.cntrStatus
                && item.block === currentEntity.block && item.stack === currentEntity.stack && item.tier === currentEntity.tier
                && item.locationType === currentEntity.locationType && item.operatingTml === currentEntity.operatingTml
                && item.lane === currentEntity.lane))) {
                isSave = false;
            }
        }
        if (isSave) {
            setIsLoading(true);
            criteriaVM.onComponentSaveClicked();
            criteriaVM.onSave(currentEntity, isAdd).then(async (res) => {
                if (!res) {
                    setIsLoading(false);
                    criteriaVM.searchEmptyPoolExclusion().then(() => {
                        setIsLoading(false);
                    }).catch((error) => {
                        setIsLoading(false);
                    });
                    messageBarVM.showSuccess('Save successful.');
                } else {
                    setIsLoading(false);
                }
            });
        } else {
            messageBarVM.showError('Duplicate record found, please check the key: Ops Line, Charge on Company, Container Type, Container Status, Block, Stack, Lane, Tier, Location Type, Operating Tml..');
        }
    }, [criteriaVM, currentEntity, isAdd, messageBarVM, criteriaState.criteriaEntityList, isEditable])

    const handleEdit = useCallback(() => {
        criteriaVM.onEdit(currentEntity);
    }, [criteriaVM, currentEntity])

    const memoOpsLine = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "210px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentEntity?.opsLine || ''}
                fieldValue={currentEntity?.opsLine?.toString()}
                fieldLabel={CRITERIA_CONSTANT.OPS_LINE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'opsLine'}
                maxLength={60}
                requiredFieldList={[]}
                options={criteriaState.dynamicOptions.opsLineDropdownOptions}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    criteriaVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [currentEntity?.opsLine, isRead, criteriaVM, criteriaState.dynamicOptions.opsLineDropdownOptions, isSaveClicked, CRITERIA_CONSTANT.OPS_LINE])

    const memoChargeOnCompany = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "210px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentEntity?.chargeOnCompany || ''}
                fieldValue={currentEntity?.chargeOnCompany?.toString()}
                fieldLabel={CRITERIA_CONSTANT.CHARGE_ON_COMPANY}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'chargeOnCompany'}
                maxLength={60}
                requiredFieldList={[]}
                options={criteriaState.dynamicOptions.chargeOnCompanyDropdownOptions}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    criteriaVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [currentEntity?.chargeOnCompany, isRead, criteriaVM, criteriaState.dynamicOptions.chargeOnCompanyDropdownOptions, isSaveClicked, CRITERIA_CONSTANT.CHARGE_ON_COMPANY])

    const memoEffectiveDate = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "210px", marginBottom: "24px" }}>
            <DatePickerComponent
                disabled={!isAdd}
                label={CRITERIA_CONSTANT.EFFECTIVE_DATE}
                width="210px"
                date={currentEntity?.effectiveDate ?? ''}
                fieldName="effectiveDate"
                errorMessage={allFormState?.effectiveDate}
                onDateChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any, objName?: any) =>
                    criteriaVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue, FieldType.DATE_TIME)} />
        </div>
        , [currentEntity?.effectiveDate, criteriaVM, isAdd, CRITERIA_CONSTANT.EFFECTIVE_DATE, allFormState])

    const memoExpiryDate = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "210px", marginBottom: "24px" }}>
            <DatePickerComponent
                disabled={isRead}
                label={CRITERIA_CONSTANT.EXPIRY_DATE}
                width="210px"
                date={currentEntity?.expiryDate ?? ''}
                fieldName="expiryDate"
                errorMessage={''}
                optional={true}
                onDateChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any, objName?: any) =>
                    criteriaVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue, FieldType.DATE_TIME)} />
        </div>
        , [currentEntity?.expiryDate, criteriaVM, isRead, CRITERIA_CONSTANT.EXPIRY_DATE])

    const memoContainerType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "210px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentEntity?.cntrType || ''}
                fieldValue={currentEntity?.cntrType?.toString()}
                fieldLabel={CRITERIA_CONSTANT.CONTAINER_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'cntrType'}
                maxLength={60}
                requiredFieldList={[]}
                options={criteriaState.dynamicOptions.cntrTypeDropdownOptions}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    criteriaVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [currentEntity?.cntrType, isRead, criteriaVM, criteriaState.dynamicOptions.cntrTypeDropdownOptions, isSaveClicked, CRITERIA_CONSTANT.CONTAINER_TYPE])

    const memoContainerStatus = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "210px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentEntity?.cntrStatus || ''}
                fieldValue={currentEntity?.cntrStatus?.toString()}
                fieldLabel={CRITERIA_CONSTANT.CONTAINER_STATUS}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'cntrStatus'}
                maxLength={60}
                requiredFieldList={[]}
                options={criteriaState.dynamicOptions.cntrStatusDropdownOptions}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    criteriaVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [currentEntity?.cntrStatus, isRead, criteriaVM, criteriaState.dynamicOptions.cntrStatusDropdownOptions, isSaveClicked, CRITERIA_CONSTANT.CONTAINER_STATUS])

    const memoLocationType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "210px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentEntity?.locationType || ''}
                fieldValue={currentEntity?.locationType?.toString()}
                fieldLabel={CRITERIA_CONSTANT.LOCATION_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'locationType'}
                maxLength={60}
                requiredFieldList={[]}
                options={criteriaState.dynamicOptions.locationTypeDropdownOptions}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    criteriaVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [currentEntity?.locationType, isRead, criteriaVM, criteriaState.dynamicOptions.locationTypeDropdownOptions, isSaveClicked, CRITERIA_CONSTANT.LOCATION_TYPE])

    const memoOperatingTml = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "210px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentEntity?.operatingTml || ''}
                fieldValue={currentEntity?.operatingTml?.toString()}
                fieldLabel={CRITERIA_CONSTANT.OPERATING_TML}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'operatingTml'}
                maxLength={60}
                requiredFieldList={[]}
                options={criteriaState.dynamicOptions.operatingTmlDropdownOptions}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    criteriaVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [currentEntity?.operatingTml, isRead, criteriaVM, criteriaState.dynamicOptions.operatingTmlDropdownOptions, isSaveClicked, CRITERIA_CONSTANT.OPERATING_TML])

    const memoBlock = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "210px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentEntity?.block || ''}
                fieldValue={currentEntity?.block}
                fieldLabel={CRITERIA_CONSTANT.BLOCK}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'block'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    criteriaVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CRITERIA_CONSTANT.BLOCK, currentEntity?.block, isRead, isSaveClicked, criteriaVM])

    const memoStack = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "210px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentEntity?.stack || ''}
                fieldValue={currentEntity?.stack}
                fieldLabel={CRITERIA_CONSTANT.STACK}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'stack'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    criteriaVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CRITERIA_CONSTANT.STACK, currentEntity?.stack, isRead, isSaveClicked, criteriaVM])

    const memoLane = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "210px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentEntity?.lane || ''}
                fieldValue={currentEntity?.lane}
                fieldLabel={CRITERIA_CONSTANT.LANE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'lane'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    criteriaVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CRITERIA_CONSTANT.LANE, currentEntity?.lane, isRead, isSaveClicked, criteriaVM])

    const memoTier = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "210px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentEntity?.tier || ''}
                fieldValue={currentEntity?.tier}
                fieldLabel={CRITERIA_CONSTANT.TIER}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'tier'}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    criteriaVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CRITERIA_CONSTANT.TIER, currentEntity?.tier, isRead, isSaveClicked, criteriaVM])

    const memoActiveInd = useMemo(() =>
        <>
            <div className='im-flex-row-item' style={{ width: "210px", marginBottom: "24px" }}>
                <NbisCommonField
                    fieldKey={"activeInd"}
                    requiredFieldList={[]}
                    fieldLabel={CRITERIA_CONSTANT.ACTIVE_IND}
                    fieldType={FieldType.TEXT}
                    isReadOnly={true}
                    onFieldChange={() => { }} />
                <HPHCheckbox
                    label={""}
                    checked={currentEntity?.activeInd === 'Y'}
                    disabled={isRead}
                    onChange={(e) => criteriaVM.onCheckboxChange(e.checked, "activeInd")}
                />
            </div>
        </>
        , [currentEntity?.activeInd, isRead, criteriaVM, CRITERIA_CONSTANT.ACTIVE_IND])

    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;
    return (
        <div className='side-form-content-wrapper' style={{ width: '27vw', height: "99vh" }}>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>
                            <SidebarTitle>{isAdd ? 'NEW GROUP' : currentEntity.chargeOnCompany}</SidebarTitle>
                            {(isAdd || (isEditable && !isRead)) && <SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>}
                        </SidebarTitle>
                        <SidebarActionCross>
                            {(!isAdd && (isEditable && isRead)) &&
                                <>
                                    {
                                        (
                                            (isEditable)
                                        )
                                        && <NbisIconButton fileName='Icon-pen' size='medium' toolTipText={'Edit'} toolTipArrow={false} onClick={handleEdit} disabled={false} />
                                    }
                                </>}
                        </SidebarActionCross>
                    </Sidebarheader>
                </div>
            </div>

            {isLoading && <Loader Indicator="Spinner" size="Medium" />}

            <div className={'add-edit-form'} style={{ height: '87vh', maxHeight: '87vh', overflow: 'auto' }}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'} style={{ height: '79vh', maxHeight: '79vh', overflow: 'auto' }}>
                        <CriteriaItemContainer>
                            {memoOpsLine}
                            {memoChargeOnCompany}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoEffectiveDate}
                            {memoExpiryDate}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoContainerType}
                            {memoContainerStatus}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoBlock}
                            {memoStack}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoLane}
                            {memoTier}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoLocationType}
                            {memoOperatingTml}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoActiveInd}
                        </CriteriaItemContainer>
                    </div>
                </div>
                <div className='im-charge-data-search-criteria-buttons' >
                    {
                        <>
                            <HPHButton label={isRead ? 'Close' : 'Cancel'} size={'Small'} theme={'Secondary'} onClick={handleCancelClick} />
                            {!isRead && <HPHButton label={isAdd ? 'Add' : "Update"} size={'Small'} theme={'Primary'} onClick={() => handleSaveClick()} />}
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

