import { ChargeDetailEntity } from "domain/entity/ChargeDetailMaintenance/ChargeDetailEntity";
import { ChargeDetailRepository } from "domain/repository/ChargeDetailMaintenance/ChargeDetailRepo";
import { ManualChargeDetailModel } from "presentation/model/ManualChargeMaintenance/ManualChargeDetailModel";
import { Dispatch, SetStateAction } from "react";
import BaseViewModel from "../BaseViewModel";


interface ManualChargeDetailVesselVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<ManualChargeDetailModel>> | ((value: SetStateAction<ManualChargeDetailModel>) => void),
    ],
    chargeDetailRepo: ChargeDetailRepository

}

export const ManualChargeDetailVesselVM = ({ dispatch, chargeDetailRepo
}: ManualChargeDetailVesselVMProps) => {
    const [manualChargeDetailDispatch] = dispatch;

    const closeCntrCompDialog = () => {
        manualChargeDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowFindVesselPanel: false,
                viewState: {
                    ...prevState.viewState,
                    isRead: !prevState.isShowFindVesselPanel,
                }
            }
        })
    }

    const onShowPanel = (vesselCntrNos: string) => {
        manualChargeDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowFindVesselPanel: true,
                vesselCntrNos: vesselCntrNos,
                viewState: {
                    ...prevState.viewState,
                    isRead: !prevState.isShowFindVesselPanel,
                }
            }
        })
    }

    const initSearch = async (vesselCntrNos: any) => {
        if (vesselCntrNos) {
            return await chargeDetailRepo.getConVslVoy(vesselCntrNos).then(data => {
                if (data && data.toString().startsWith("Error:")) {
                    manualChargeDetailDispatch(prevState => {
                        return {
                            ...prevState,
                            allFormState: { "initManualChargeCntrFail": data.toString() }
                        };
                    });
                    return { "initManualChargeCntrFail": data.toString() };
                } else {
                    manualChargeDetailDispatch(prevState => {
                        return {
                            ...prevState,
                            allFormState: { "initManualChargeCntrSuccess": "successful" },
                            manualChargeDetailVessels: data,
                        };
                    });
                    return { "initManualChargeCntrSuccess": data };
                }
            }).catch(error => {
                manualChargeDetailDispatch(prevState => {
                    return {
                        ...prevState,
                        allFormState: { "initManualChargeCntrFail": error.message }
                    }
                });
                return { "initManualChargeCntrFail": error.message };
            })
        } else {
            manualChargeDetailDispatch(prevState => {
                return {
                    ...prevState,
                    allFormState: { "initManualChargeCntrSuccess": "successful" },
                    manualChargeDetailVessels: [],
                };
            });
        }
    }

    const apply = async (selectedRows: ChargeDetailEntity[]) => {
        manualChargeDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowFindVesselPanel: false,
                viewState: {
                    ...prevState.viewState,
                    editingHeader: {
                        ...prevState.viewState.editingHeader,
                        consortiumCode: selectedRows[0]?.consortiumCode !== undefined ? selectedRows[0]?.consortiumCode : null,
                        vesselCode: selectedRows[0]?.vesselCode !== undefined ? selectedRows[0]?.vesselCode : null,
                        voyageCode: selectedRows[0]?.voyageCode !== undefined ? selectedRows[0]?.voyageCode : null,
                        vesselName: selectedRows[0]?.vesselName !== undefined ? selectedRows[0]?.vesselName : null,
                        handlingTerminal: selectedRows[0]?.handlingTerminal !== undefined ? selectedRows[0]?.handlingTerminal : null,
                        etd: selectedRows[0]?.etd !== undefined ? selectedRows[0]?.etd : null,
                        eta: selectedRows[0]?.eta !== undefined ? selectedRows[0]?.eta : null,
                        etb: selectedRows[0]?.etb !== undefined ? selectedRows[0]?.etb : null,
                        soa: selectedRows[0]?.soa !== undefined ? selectedRows[0]?.soa : null,
                        loa: selectedRows[0]?.loa !== undefined ? selectedRows[0]?.loa : null,
                        serviceCode: selectedRows[0]?.serviceCode !== undefined ? selectedRows[0]?.serviceCode : null,
                        poNo: selectedRows[0]?.poNo !== undefined ? selectedRows[0]?.poNo : null,
                        ourRef: selectedRows[0]?.ourRef !== undefined ? selectedRows[0]?.ourRef : null,
                        yourRef: selectedRows[0]?.yourRef !== undefined ? selectedRows[0]?.yourRef : null,
                        slVesselCode: selectedRows[0]?.slVesselCode !== undefined ? selectedRows[0]?.slVesselCode : null,
                        slIbVoyageCode: selectedRows[0]?.slIbVoyageCode !== undefined ? selectedRows[0]?.slIbVoyageCode : null,
                        slObVoyageCode: selectedRows[0]?.slObVoyageCode !== undefined ? selectedRows[0]?.slObVoyageCode : null,
                        operatingTml: selectedRows[0]?.operatingTml !== undefined ? selectedRows[0]?.operatingTml : null,
                    },
                    isRead: !prevState.isShowFindVesselPanel
                }
            }
        })
    }

    const coVslVoy = async (selectedRows: ChargeDetailEntity[]) => {
        manualChargeDetailDispatch(prevState => {
            return {
                ...prevState,
                viewState: {
                    ...prevState.viewState,
                    editingHeader: {
                        ...prevState.viewState.editingHeader,
                        consortiumCode: selectedRows[0]?.consortiumCode !== undefined ? selectedRows[0]?.consortiumCode : null,
                        vesselCode: selectedRows[0]?.vesselCode !== undefined ? selectedRows[0]?.vesselCode : null,
                        voyageCode: selectedRows[0]?.voyageCode !== undefined ? selectedRows[0]?.voyageCode : null,
                        vesselName: selectedRows[0]?.vesselName !== undefined ? selectedRows[0]?.vesselName : null,
                        handlingTerminal: selectedRows[0]?.handlingTerminal !== undefined ? selectedRows[0]?.handlingTerminal : null,
                        etd: selectedRows[0]?.etd !== undefined ? selectedRows[0]?.etd : null,
                        eta: selectedRows[0]?.eta !== undefined ? selectedRows[0]?.eta : null,
                        etb: selectedRows[0]?.etb !== undefined ? selectedRows[0]?.etb : null,
                        soa: selectedRows[0]?.soa !== undefined ? selectedRows[0]?.soa : null,
                        loa: selectedRows[0]?.loa !== undefined ? selectedRows[0]?.loa : null,
                        serviceCode: selectedRows[0]?.serviceCode !== undefined ? selectedRows[0]?.serviceCode : null,
                        poNo: selectedRows[0]?.poNo !== undefined ? selectedRows[0]?.poNo : null,
                        ourRef: selectedRows[0]?.ourRef !== undefined ? selectedRows[0]?.ourRef : null,
                        yourRef: selectedRows[0]?.yourRef !== undefined ? selectedRows[0]?.yourRef : null,
                        slVesselCode: selectedRows[0]?.slVesselCode !== undefined ? selectedRows[0]?.slVesselCode : null,
                        slIbVoyageCode: selectedRows[0]?.slIbVoyageCode !== undefined ? selectedRows[0]?.slIbVoyageCode : null,
                        slObVoyageCode: selectedRows[0]?.slObVoyageCode !== undefined ? selectedRows[0]?.slObVoyageCode : null,
                        operatingTml: selectedRows[0]?.operatingTml !== undefined ? selectedRows[0]?.operatingTml : null,
                    },
                }
            }
        })
    }

    const updateSelectedManualChargeDetails = (rows: any[]) => {
        manualChargeDetailDispatch(prevState => {
            return {
                ...prevState,
                selectedManualChargeDetailVesselRow: rows
            }
        })
    }

    return {
        onShowPanel: onShowPanel,
        initSearch: initSearch,
        apply: apply,
        coVslVoy: coVslVoy,
        closeCntrCompDialog: closeCntrCompDialog,
        updateSelectedManualChargeDetails: updateSelectedManualChargeDetails
    }
}


