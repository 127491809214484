import { SelectionChangedEvent } from "ag-grid-community";
import { UserGroupEntity } from "domain/entity/userGroup/UserGroupEntity";
import { MessageConstant } from "presentation/constant/MessageConstant";
import { INITIAL_USER_GROUP_COL_DEF } from "presentation/constant/UserGroup/UserGroupColumnDefinition";
import { UserGroupConstant } from "presentation/constant/UserGroup/UserGroupConstant";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useUserGroupVM } from "presentation/hook/UserGroup/useUserGroupVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useUserGroupTracked } from "presentation/store/UserGroup/UserGroupProvider";
import { ConfirmDeleteModal } from "presentation/view/components/ConfirmDeleteModal";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Loader } from "veronica-ui-component/dist/component/core";

const UserGroupTablePanel: React.FC = () => {
    const [userGroupState] = useUserGroupTracked();
    const [anaInfoSate] = useANAInfoTracked();
    const messageBarVM = useMessageBarVM();
    const userGroupVM = useUserGroupVM();
    const USERGROUP_CONSTANT = UserGroupConstant.userGroup;
    const { isTabularDataActive, currentSelectedData, lastEditRowId } = userGroupState.userGroupState;
    const allFormState = userGroupState.userGroupState.allFormState;
    const [isShowDelete, setIsShowDelete] = useState<boolean>(false);
    const [deletedRole, setDeletedRole] = useState<UserGroupEntity>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    let gridRef: any = useRef();

    useEffect(() => {
        if (!onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();        
    }, [onTableSelectionClicked])

    useEffect(() => {        
        if (!onTableSelectionClicked) {
            gridRef.current?.gridRef.current.api?.deselectAll();
        }
    })

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();

        userGroupVM.onSelectedUserGroups(selectedRows);
    }, [userGroupVM])

    const handleAddClick = useCallback(() => {
        userGroupVM.onUserGroupAddClick();
    }, [userGroupVM])

    const handleRowDoubleClick = useCallback((userGroup: UserGroupEntity) => {
        userGroupVM.onUserGroupRowDoubleClick(userGroup);
    }, [userGroupVM])

    const handleDelete = () => {
        setIsLoading(true);
        setIsShowDelete(false);
        if (!deletedRole) return;

        userGroupVM.onUserGroupRowDeleteClick(deletedRole).then((data) => {
            if (allFormState && allFormState["DeleteUserGroupFail"]) {
                messageBarVM.showError(allFormState["DeleteUserGroupFail"]?.toString());
            } else {
                messageBarVM.showSuccess(MessageConstant.common.DELETED_DATA_SUCCESSFUL);
                userGroupVM.loadAllUserGroups().then(data => {
                    setIsLoading(false);
                });
            }
        }).catch((error) => {
            setIsLoading(false);
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const handleRowDeleteClick = useCallback(async (role: UserGroupEntity) => {
        setIsShowDelete(true);
        setDeletedRole(role);
    }, []);

    const handleCancel = () => {
        setIsLoading(true);
        setIsShowDelete(false);
        userGroupVM.loadAllUserGroups().then(data => {
            setIsLoading(false);
        });
    }

    const isCopyBtnDisable = useCallback(() => {
        if (anaInfoSate.allowCreate && currentSelectedData && currentSelectedData.name) return false
        return true;
    },[anaInfoSate.allowCreate, currentSelectedData])

    const handleCopy = useCallback(() => {
        userGroupVM.onUserGroupRenewClick();
    }, [userGroupVM])

    const memoTableBtns = useMemo(() => {
        const tableBtns = [];

        tableBtns.push({
            id: 'onCopyButton',
            icon: 'Icon-copy',
            title: 'Copy Role',
            disable: isCopyBtnDisable
        })
        return tableBtns
    }, [isCopyBtnDisable])

    const memoUserGroupTable = useMemo(() => {
        return <NbisTable
            id='user-group-table'
            columns={INITIAL_USER_GROUP_COL_DEF}
            headerLabel={USERGROUP_CONSTANT.USERGROUP}
            headerActionButtons={memoTableBtns}
            onAddClick={handleAddClick}
            onSelectionChanged={handleSelectionChange}
            onRowDoubleClick={(e: any) => handleRowDoubleClick(e.data)}
            data={userGroupState.userGroups.map((userGroup: UserGroupEntity) => ({
                ...userGroup,
                flag_edit: userGroup.resource === lastEditRowId,
            }))}
            showPaginator={false}
            editable={false}
            showAddIcon={anaInfoSate.allowCreate}
            showDeleteButton={anaInfoSate.allowDelete}
            showActionColumn={true}
            showReloadIcon={false}
            selectionMode={true}
            isScrollHighlighted={true}
            isNewColumnSetting={true}
            isRowHighligted={true}
            onDelete={(deleteUserGroup: UserGroupEntity) => handleRowDeleteClick(deleteUserGroup)}
            onCopyButton={handleCopy}
            gridHeight="customHeight"
            rowSelection={"single"}
            customHeight="calc(100vh - 72px)" />;
    }, [USERGROUP_CONSTANT.USERGROUP, memoTableBtns, handleAddClick, handleSelectionChange, userGroupState.userGroups, anaInfoSate.allowCreate, anaInfoSate.allowDelete, handleCopy, handleRowDoubleClick, lastEditRowId, handleRowDeleteClick])

    useEffect(() => {
        userGroupVM.onShowLoading();
        const initialScreen = async () => {
            try {
                const results = await Promise.allSettled([
                    userGroupVM.loadAllUserGroups(),
                    userGroupVM.loadDropdownOptions(anaInfoSate.operatingUnit)
                ]);
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {

                    } else if (index === 1 && result.status === 'rejected') {

                    }
                })
            } catch (error) {
            }
        }
        initialScreen().then((data) => {
            userGroupVM.onHideLoading();
        }).catch(error => {
            userGroupVM.onHideLoading();
        });
    }, [userGroupVM, anaInfoSate.operatingUnit])

    if (userGroupState.isLoading) return <Loader Indicator="Stripe" size="Large" />;

    return <>{isTabularDataActive && <TableWrapper>{memoUserGroupTable}</TableWrapper>}
        {isLoading && <Loader Indicator="Spinner" size="Medium" />}
        <ConfirmDeleteModal headerText={`${WorkspaceConstant.Common.BUTTON_DELETE} ${UserGroupConstant.userGroup.USERGROUP}`}
            contentText={`${MessageConstant.common.DELETE_ALERT_TITLE}\n${MessageConstant.common.DELETE_ALERT_MESSAGE}`}
            visible={isShowDelete}
            onCancelClick={handleCancel}
            onDeleteClick={handleDelete}
        />
    </>;
}

export default UserGroupTablePanel;