import { ChargeDetailEntity } from "domain/entity/ChargeDetailMaintenance/ChargeDetailEntity";
import { ChargeQtyAdjEntity, EMPTY_CHARGE_QTY_ADJ_ENTITY } from "domain/entity/ChargeQtyAdj/ChargeQtyAdjEntity";
import { ChargeQtyAdjSearchCriteria, EMPTY_CHARGE_QTY_ADJ_SEARCH_CRITERIA } from "domain/entity/ChargeQtyAdj/ChargeQtyAdjSearchCriteria";
import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { MasterDataType } from "domain/entity/MasterData/MasterDataEntity";
import { ChargeQtyAdjRepository } from "domain/repository/ChargeQtyAdj/ChargeQtyAdjRepo";
import { ChargeTypeRepository } from "domain/repository/ChargeType/ChargeTypeRepo";
import { CompanyRepository } from "domain/repository/Company/CompanyRepo";
import { MasterDataRepository } from "domain/repository/MasterData/MasterDataRepo";
import { ParameterDetailRepository } from "domain/repository/parameter/ParameterDetailRepo";
import _ from "lodash";
import { chargeQtyAdjCSVColumnMapping, chargeQtyAdjCSVDateColumnMapping, chargeQtyAdjCSVNumberColumnMapping } from "presentation/constant/ChargeQtyAdj/ChargeQtyAdjMaintenanceConstant";
import { createChargeQtyAdjMaintenanceValidationSchema } from "presentation/constant/ChargeQtyAdj/ChargeQtyAdjMaintenanceValidationSchema";
import { ParameterCodeValue } from "presentation/constant/Parameter/ParameterCodeValue";
import { Validation } from "presentation/constant/Validation";
import { ChargeQtyAdjMaintenanceModel } from "presentation/model/ChargeQtyAdj/ChargeQtyAdjMaintenanceModel";
import { DropdownProps } from "presentation/model/DropdownProps";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";
interface ChargeQtyAdjMaintenanceVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<ChargeQtyAdjMaintenanceModel>> | ((value: SetStateAction<ChargeQtyAdjMaintenanceModel>) => void),
    ],
    chargeTypeRepo: ChargeTypeRepository,
    companyRepo: CompanyRepository,
    repo: ChargeQtyAdjRepository,
    parameterDetailRepo: ParameterDetailRepository,
    masterDataRepo: MasterDataRepository,
}
export const ChargeQtyAdjMaintenanceVM = ({ dispatch, repo, chargeTypeRepo, companyRepo, parameterDetailRepo, masterDataRepo }: ChargeQtyAdjMaintenanceVMProps) => {
    const [chargeQtyAdjMainDispatch] = dispatch;

    const loadDropdownOption = async () => {
        await chargeTypeRepo.searchChargeTypeByCriteria({
            activeInd: 'Y',
            countFspInd: 'Y'
        }).then(
            chargeTypes => {
                let newChargeTypes = _.orderBy(chargeTypes, ["chargeType", "subChargeType"]);
                let chargeTypeDropdownOptions: DropdownProps[] = [];
                let subChargeTypeDropdownOption: { [key: string]: DropdownProps[] } = {};
                newChargeTypes.forEach(chgTypeEty => {
                    const chgTypeExisted = chargeTypeDropdownOptions.find(chgType =>
                        chgType.value === chgTypeEty.chargeType);
                    if (!chgTypeExisted) {
                        chargeTypeDropdownOptions.push({
                            dropdownLabel: chgTypeEty.chargeType,
                            tagLabel: chgTypeEty.chargeType,
                            value: chgTypeEty.chargeType
                        })
                    }
                    if (chgTypeEty.subChargeType) {
                        if (!subChargeTypeDropdownOption[chgTypeEty.chargeType]) {
                            subChargeTypeDropdownOption[chgTypeEty.chargeType] = [];
                        }
                        subChargeTypeDropdownOption[chgTypeEty.chargeType].push({
                            dropdownLabel: chgTypeEty.subChargeType,
                            tagLabel: chgTypeEty.subChargeType,
                            value: chgTypeEty.subChargeType
                        });
                    }
                });

                chargeQtyAdjMainDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        chargeTypeDropdownOptions: chargeTypeDropdownOptions,
                        subChargeTypeDropdownOptions: subChargeTypeDropdownOption
                    },

                }))
            }
        )

        await companyRepo.getAllCompanyForCombobox().then(
            companies => {
                let companyCodeDropdownOptions = companies?.map((company) => ({
                    dropdownLabel: company.companyCode,
                    tagLabel: company.companyCode,
                    value: company.companyCode,
                })) ?? []
                companyCodeDropdownOptions = _.orderBy(companyCodeDropdownOptions, "dropdownLabel");

                chargeQtyAdjMainDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        chargeOnCompDropdownOptions: companyCodeDropdownOptions,
                    }
                }))
            }
        )

        await parameterDetailRepo.getAllParameterDtlsByParameterCode(ParameterCodeValue.HANDLING_TERMINAL).then(
            parDtls => {
                const handlingTmlDropdownOptions = parDtls?.map((parDtl) => ({
                    dropdownLabel: parDtl.parameterDtlCode,
                    tagLabel: parDtl.parameterDtlCode,
                    value: parDtl.parameterDtlCode,
                })) ?? []

                chargeQtyAdjMainDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        handlingTmlDropdownOptions: handlingTmlDropdownOptions
                    }
                }))
            }
        )

        await masterDataRepo.getMasterDataByKey(MasterDataType.CONSORTIUM).then(
            consortiumCodes => {
                const consortiumCodeDropdownOptions = consortiumCodes?.map((cons) => ({
                    dropdownLabel: cons.code,
                    tagLabel: cons.code,
                    value: cons.code,
                })) ?? []

                chargeQtyAdjMainDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        consortiumCodeDropdownOptions: consortiumCodeDropdownOptions
                    }
                }))
            }
        )


    }
    const onSearch = async () => {
        const entities = await repo.getEntities();
        chargeQtyAdjMainDispatch(prevState => ({
            ...prevState,
            tableData: entities?.map(e => ({ ...e, displayCntrNos: e.cntrList?.join(',') })),
            selectedRows: [],
            currentSelectedRow: EMPTY_CHARGE_QTY_ADJ_ENTITY,
            isBackMaster: false,
        }))
    }
    const updateSelectedRows = async (allRows: ChargeQtyAdjEntity[], selecedRows: ChargeQtyAdjEntity[]) => {
        chargeQtyAdjMainDispatch(prevState => {

            return {
                ...prevState,
                tableData: [...allRows],
                selectedRows: selecedRows,
            }
        })
    }
    const onAdd = () => {
        chargeQtyAdjMainDispatch(prevState => {

            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    isAdd: true,
                    isEditable: false,
                    isRead: false,
                    editingEntity: EMPTY_CHARGE_QTY_ADJ_ENTITY,
                }
            }
        })
    }
    const onEdit = (currentEntity: ChargeQtyAdjEntity) => {
        chargeQtyAdjMainDispatch(prevState => {
            return {
                ...prevState,
                currentSelectedRow: currentEntity,
                masterState: {
                    ...prevState.masterState,
                    isAdd: false,
                    isEditable: true,
                    isRead: false,
                    editingEntity: {
                        ...currentEntity
                    },
                }
            }
        })
    }
    const onReset = () => {
        chargeQtyAdjMainDispatch(prevState => {
            const resetEntity = prevState.masterState.isAdd ? EMPTY_CHARGE_QTY_ADJ_ENTITY : prevState.currentSelectedRow;
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    editingEntity: {
                        ...resetEntity
                    },
                }
            }
        })
    }
    const onClose = () => {
        chargeQtyAdjMainDispatch(prevState => {
            return {
                ...prevState,
                currentSelectedRow: EMPTY_CHARGE_QTY_ADJ_ENTITY,
                selectedRows: [],
                masterState: {
                    ...prevState.masterState,
                    isAdd: false,
                    isEditable: false,
                    isRead: true,
                    editingEntity: EMPTY_CHARGE_QTY_ADJ_ENTITY,
                    allFormState: {}
                },
                isBackMaster: true,
            }
        })
    }
    const onSaveClicked = () => {
        chargeQtyAdjMainDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    isSaveClicked: true,
                    allFormState: {},
                }
            }
        })
    }
    const onSave = async (currentEntity: ChargeQtyAdjEntity, isAdd: boolean) => {
        const valResult = await Validation(createChargeQtyAdjMaintenanceValidationSchema).ValidateFormOnly(currentEntity);
        let validatedResult: { [x: string]: string } = {};
        if (valResult) {
            validatedResult = { ...valResult, warningMessage: 'Please input the missing value.' };

            chargeQtyAdjMainDispatch(prevState => {
                return {
                    ...prevState,
                    masterState: {
                        ...prevState.masterState,
                        allFormState: {
                            ...validatedResult
                        },
                    }
                }
            });
            const res: ResponseEntity = {
                code: "",
                success: false,
                msg: null,
                data: 'Please input the missing value.'
            }

            return res;
        }
        if (!_.isEmpty(currentEntity.cntrList)) {

            const counts = _.countBy(currentEntity.cntrList);
            const duplicates = _.filter(_.keys(counts), key => counts[key] > 1);

            if (!_.isEmpty(duplicates)) {

                const res: ResponseEntity = {
                    code: "",
                    success: false,
                    msg: null,
                    data: 'Containers are repeat:' + duplicates.join(',')
                }

                return res;
            }
        }

        if (isAdd) {
            return await repo.createEntity(currentEntity);
        } else {
            return await repo.updateEntity(currentEntity);
        }
    }
    const onCheckboxChange = (checked: boolean, fieldName: string) => {
        chargeQtyAdjMainDispatch(prevState => ({
            ...prevState,
            masterState: {
                ...prevState.masterState,
                editingEntity: {
                    ...prevState.masterState.editingEntity,
                    [fieldName]: checked ? "Y" : "N",
                }
            }
        }))
    }
    const onFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }
        if (fieldKey !== 'etd' && fieldKey !== 'allowance') {
            val = val.toUpperCase()
        }

        chargeQtyAdjMainDispatch(prevState => {
            if (fieldKey === 'allowance') {
                // const posIntReg = /^\d*$/;
                const posIntReg = /^(?:[1-9]\d{0,5}|0)$/;
                if (!val) {
                    val = null;
                } else if (!posIntReg.test(val)) {
                    val = prevState.masterState.editingEntity[fieldKey];
                }
            }
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    editingEntity: {
                        ...prevState.masterState.editingEntity,
                        [fieldKey]: val,
                    },
                    allFormState: {
                        ...prevState.masterState.allFormState,
                        [fieldKey]: '',
                    }
                }
            }
        })
    }

    const onTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>, fieldName: string, toUpperCase: boolean = false) => {
        const val = toUpperCase ? e.target.value.toUpperCase() : e.target.value;

        chargeQtyAdjMainDispatch(prevState => ({
            ...prevState,
            masterState: {
                ...prevState.masterState,
                editingEntity: {
                    ...prevState.masterState.editingEntity,
                    [fieldName]: val === "" ? null : val.split(',')
                },
                allFormState: {
                    ...prevState.masterState.allFormState,
                    [fieldName]: '',
                }

            }
        }))
    };

    const onUploadCntr = async (cntrNos: string[], file: File) => {

        const arrayBuffer = await readFileAsArrayBuffer(file);
        const cntrNoArray = await processFileData(arrayBuffer);
        const sortedList = [...cntrNoArray].sort();
        let tempCntrs = [...cntrNos, ...sortedList];


        chargeQtyAdjMainDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    editingEntity: {
                        ...prevState.masterState.editingEntity,
                        cntrList: tempCntrs,
                    },
                    allFormState: {
                        ...prevState.masterState.allFormState,
                        cntrList: '',
                    }
                }
            }
        })
    }

    const readFileAsArrayBuffer = (file: File) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (e) => resolve(e.target?.result);
            reader.onerror = (e) => reject(e.target?.error);

            reader.readAsArrayBuffer(file);
        });
    }


    const processFileData = async (arrayBuffer: any) => {
        const byteArray = new Uint8Array(arrayBuffer);
        const decoder = new TextDecoder('utf-8');
        const content = decoder.decode(byteArray);
        const BOM = '\uFEFF';
        const contentWithoutBOM = content.startsWith(BOM) ? content.slice(1) : content;
        const lines = contentWithoutBOM.split('\n').map(line => line.trim());
        // const lines = content.split('\n').map(line => line.trim());
        let cntrArr: string[] = [];
        lines.forEach(line => {
            line.split(',').map(cntr => cntr.trim()).forEach(tCntr => {
                cntrArr.push(tCntr)
            })
        });
        return cntrArr;
    }

    const processChargeQtyAdjFileData = async (arrayBuffer: any): Promise<ChargeQtyAdjEntity[]> => {
        const byteArray = new Uint8Array(arrayBuffer);
        const decoder = new TextDecoder('utf-8');
        const content = decoder.decode(byteArray);
        const BOM = '\uFEFF';
        const contentWithoutBOM = content.startsWith(BOM) ? content.slice(1) : content;
        const lines = contentWithoutBOM.split('\n').map(line => line.trim());

        const result: ChargeQtyAdjEntity[] = [];

        lines.forEach(line => {
            const obj: ChargeQtyAdjEntity = { ...EMPTY_CHARGE_QTY_ADJ_ENTITY };
            const lineObjArr = line.split(',');
            for (let i = 0; i < chargeQtyAdjCSVColumnMapping.length && i < lineObjArr.length; i++) {
                const col = chargeQtyAdjCSVColumnMapping[i];
                const val = _.isEmpty(lineObjArr[i]) ? null : lineObjArr[i]
                if (col === 'cntrList' && val) {
                    obj[col] = val.replace(/\s+/g, '').split(";");
                } else if (chargeQtyAdjCSVNumberColumnMapping.includes(col)) {  //Check if it is of type number
                    obj[col] = _.isEmpty(val) || val === null ? null : parseFloat(val.replace(/^"+|"+$/g, ''));
                } else if (chargeQtyAdjCSVDateColumnMapping.includes(col)) {  //Check if it is of type Date
                    const cleanedValue = _.isEmpty(lineObjArr[i]) ? null : lineObjArr[i].replace(/^"+|"+$/g, '');
                    if (cleanedValue === null) {
                        obj[col] = null;
                    } else {
                        try {
                            const date = new Date(Date.parse(cleanedValue.substring(0, 10)));
                            obj[col] = _.isEmpty(date) ? null : date;
                        } catch (error) {
                            obj[col] = null;
                        }
                    }
                } else {
                    obj[col] = val;
                }

            }
            result.push(obj);
        });

        return result;
    }

    const onUpload = async (file: File) => {

        const arrayBuffer = await readFileAsArrayBuffer(file);
        const chargeQtyAdjEntities = await processChargeQtyAdjFileData(arrayBuffer);

        return await repo.uploadData(chargeQtyAdjEntities);
    }

    const onCoVslVoyChange = (inputData: { co?: string, vsl?: string, voy?: string }, fieldName: { co: string, vsl: string, voy: string }) => {
        let coVal: string = "";
        let vslVal: string = "";
        let voyVal: string = "";
        if (inputData?.co) {
            coVal = inputData?.co.toUpperCase().replace(/\s+/g, '');
        }
        if (inputData?.vsl) {
            vslVal = inputData?.vsl.toUpperCase().replace(/\s+/g, '');
        }
        if (inputData?.voy) {
            voyVal = inputData?.voy.toUpperCase();
        }

        chargeQtyAdjMainDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    editingEntity: {
                        ...prevState.masterState.editingEntity,
                        [fieldName.co]: coVal,
                        [fieldName.vsl]: vslVal,
                        [fieldName.voy]: voyVal,
                    }
                }
            }
        })
    }

    const onShowPanel = () => {
        chargeQtyAdjMainDispatch(prevState => {
            return {
                ...prevState,
                isShowFindVesselPanel: true,
            }
        })
    }

    const closeVesselPanel = () => {
        chargeQtyAdjMainDispatch(prevState => {
            return {
                ...prevState,
                isShowFindVesselPanel: false,
                chargeQtyAdjVesselData: [],
                selectVeeselDatas: [],
                vesselSearchCriteria: { ...EMPTY_CHARGE_QTY_ADJ_SEARCH_CRITERIA },
            }
        })
    }
    const initChargeQtyAdjVesselSearch = async (vesselSearchCriteria: ChargeQtyAdjSearchCriteria) => {
        repo.getConVslVoyForChargeQtyAdj(vesselSearchCriteria).then((data) => {
            chargeQtyAdjMainDispatch(prevState => {
                return {
                    ...prevState,
                    chargeQtyAdjVesselData: data,
                    selectVeeselDatas: [],
                }
            })
        })
    }

    const onVesselSearchFieldChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        chargeQtyAdjMainDispatch(prevState => {
            return {
                ...prevState,
                vesselSearchCriteria: {
                    ...prevState.vesselSearchCriteria,
                    [fieldKey]: val,
                },
            }
        })
    }
    const resetVesselSearchCriteria = () => {
        chargeQtyAdjMainDispatch(prevState => {
            return {
                ...prevState,
                vesselSearchCriteria: { ...EMPTY_CHARGE_QTY_ADJ_SEARCH_CRITERIA },
            }
        })
    }
    const updateSelectedVesselData = (rows: any[]) => {
        chargeQtyAdjMainDispatch(prevState => {
            return {
                ...prevState,
                selectVeeselDatas: rows
            }
        })
    }

    const onVesselApply = (vessel: ChargeDetailEntity) => {
        chargeQtyAdjMainDispatch(prevState => {
            return {
                ...prevState,
                chargeQtyAdjVesselData: [],
                selectVeeselDatas: [],
                vesselSearchCriteria: { ...EMPTY_CHARGE_QTY_ADJ_SEARCH_CRITERIA },
                masterState: {
                    ...prevState.masterState,
                    editingEntity: {
                        ...prevState.masterState.editingEntity,
                        consortiumCode: vessel?.consortiumCode ?? '',
                        vesselCode: vessel?.vesselCode ?? '',
                        voyageCode: vessel?.voyageCode ?? '',
                        etd: vessel?.etd ?? null
                    }
                },
                isShowFindVesselPanel: false,
            }
        })
    }


    return {
        onVesselApply: onVesselApply,
        updateSelectedVesselData: updateSelectedVesselData,
        resetVesselSearchCriteria: resetVesselSearchCriteria,
        onVesselSearchFieldChange: onVesselSearchFieldChange,
        initChargeQtyAdjVesselSearch: initChargeQtyAdjVesselSearch,
        closeVesselPanel: closeVesselPanel,
        onShowPanel: onShowPanel,
        onCoVslVoyChange: onCoVslVoyChange,
        loadDropdownOption: loadDropdownOption,
        updateSelectedRows: updateSelectedRows,
        onAdd: onAdd,
        onEdit: onEdit,
        onReset: onReset,
        onClose: onClose,
        onSearch: onSearch,
        onSaveClicked: onSaveClicked,
        onSave: onSave,
        onCheckboxChange: onCheckboxChange,
        onFieldChange: onFieldChange,
        onTextAreaChange: onTextAreaChange,
        onUploadCntr: onUploadCntr,
        onUpload: onUpload,
    }
} 
