import { SelectionChangedEvent } from "ag-grid-community";
import { CompanyChargeSuppleTermsEntity } from "domain/entity/Company/CompanyChargeSuppleTermsEntity";
import { AclType } from "presentation/constant/ANAInfo/NbisivPermission";
import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { INITIAL_CHARGE_SUPPLE_TERMS_COL_DEF, transferRowData } from "presentation/constant/Company/ChargeSuppleTermsColumnDefinition";
import { CompanyConstant } from "presentation/constant/Company/CompanyConstant";
import { useChargeSuppleTermsVM } from "presentation/hook/Company/useChargeSuppleTermsVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useCompanyDetailTracked } from "presentation/store/Company/CompanyDetailProvider";
import { isPermissionExist } from "presentation/utils/permissionUtils";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HPHButton, Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";


const ChargeSuppleTermsTablePanel: React.FC = () => {
    const [companyDtlState] = useCompanyDetailTracked();
    const [anainfoState] = useANAInfoTracked();
    const chargeSuppleTermsVM = useChargeSuppleTermsVM();
    const messageBarVM = useMessageBarVM();

    const [isLoading, setIsLoading] = useState(false);
    const { isAdd, isEditable, isEditCompDtlInfo, isShowFsp, isShowEmptyPool, isShowSuppleTerms, isShowCustAssign, isShowCustInfo, isShowFactor } = companyDtlState.viewState;
    const COMPANY_DTL_CONST = CompanyConstant.Detail;
    // const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    const { selectedDatas } = companyDtlState.chargeSuppleTermsState

    const isReadReject = isPermissionExist(Permission.COMPANY_CHARGE_SUPPLE_TERMS_REJECT, AclType.READ, anainfoState.defaultOperatingCompany, anainfoState.allNbisivPermission);
    const isUpdateReject = isPermissionExist(Permission.COMPANY_CHARGE_SUPPLE_TERMS_REJECT, AclType.UPDATE, anainfoState.defaultOperatingCompany, anainfoState.allNbisivPermission);
    const isReadApprove = isPermissionExist(Permission.COMPANY_CHARGE_SUPPLE_TERMS_APPROVE, AclType.READ, anainfoState.defaultOperatingCompany, anainfoState.allNbisivPermission);
    const isUpdateApprove = isPermissionExist(Permission.COMPANY_CHARGE_SUPPLE_TERMS_APPROVE, AclType.UPDATE, anainfoState.defaultOperatingCompany, anainfoState.allNbisivPermission);

    let gridRef: any = useRef();

    // useEffect(() => {
    //     if (!onTableSelectionClicked) return;

    //     gridRef.current?.gridRef.current.api?.deselectAll();
    // }, [onTableSelectionClicked])

    // useEffect(() => {
    //     //const columnDefs = (INITIAL_CHARGE_SUPPLE_TERMS_COL_DEF.slice());

    //     //gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
    //     if (!onTableSelectionClicked) {
    //         gridRef.current?.gridRef.current.api?.deselectAll();
    //     }
    // })

    const memoTableBtns = useMemo(() => {
        const tableBtns = [];

        tableBtns.push({
            id: 'onRefreshButton',
            icon: 'Icon-reload',
            title: 'Refresh'
        })

        if (isEditCompDtlInfo) {
            if (!(isAdd || isEditable)) {
                tableBtns.push({
                    id: 'onDeleteButton',
                    icon: 'Icon-trash',
                    title: 'Delete'
                })

                tableBtns.push({
                    id: 'onAddButton',
                    icon: 'Icon-add',
                    title: 'Add'
                })
            }
        }

        return tableBtns
    }, [isAdd, isEditCompDtlInfo, isEditable])

    const handleAddClick = useCallback(() => {
        chargeSuppleTermsVM.onAdd();
    }, [chargeSuppleTermsVM])

    const handleEdit = useCallback((row: CompanyChargeSuppleTermsEntity) => {
        /*if (isEditCompDtlInfo && (isAdd || isEditable)) {
            return;
        }*/

        chargeSuppleTermsVM.onEdit(row);
    }, [chargeSuppleTermsVM/*, isAdd, isEditCompDtlInfo, isEditable*/]);

    const handleDelete = useCallback(() => {
        if (selectedDatas.length < 1) {
            messageBarVM.showError("Please select a record(s) to delete.");
            return;
        }

        const isAllowDelete = !(selectedDatas.find(selectedData => (selectedData.status === "FINALIZED" || selectedData.status === "PROPOSED")));
        if (!isAllowDelete) {
            messageBarVM.showError("Record status is PROPOSED/FINALIZED, cannot delete.");
            return;
        }

        chargeSuppleTermsVM.handleDelete();
    }, [chargeSuppleTermsVM, messageBarVM, selectedDatas]);

    const handleRefresh = useCallback(() => {
        setIsLoading(true);
        companyDtlState.companyId && chargeSuppleTermsVM.initialChgSuppleTermsTableData(companyDtlState.companyId).then(() => {
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false)
        })
    }, [chargeSuppleTermsVM, companyDtlState.companyId]);

    /*const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        if(selectedRows && selectedRows.length > 0){
            chargeSuppleTermsVM.onSelectedCompSuppleTerms(selectedRows);
        }
    }, [chargeSuppleTermsVM])*/

    // let performedClicks = 0;
    const handleSelectionChange = useCallback((e: SelectionChangedEvent, delay: number = 250) => {
        // performedClicks++;
        // let clickTimeout = setTimeout(() => {
        //     if (performedClicks === 1) {
        //         // eslint-disable-next-line react-hooks/exhaustive-deps
        //         performedClicks = 0;
        //         // setOnTableSelectionClicked(true);

        //         const selectedRows = e.api.getSelectedRows();
        //         if (selectedRows && selectedRows.length > 0) {
        //             chargeSuppleTermsVM.onSelectedCompSuppleTerms(selectedRows);
        //         }
        //     } else {
        //         performedClicks = 0;
        //     }
        // }, delay);

        // if (performedClicks > 1) {
        //     clearTimeout(clickTimeout);
        // }
        const selectedRows = e.api.getSelectedRows();
        chargeSuppleTermsVM.onSelectedCompSuppleTerms(selectedRows);
    }, [chargeSuppleTermsVM])

    const isDisableSubmit = useCallback(() => {
        if (selectedDatas.length < 1
            || selectedDatas.find(selectedData => (selectedData.status === "FINALIZED"))) {
            return true;
        }

        return false;
    }, [selectedDatas]);

    const isDisableReject = useCallback(() => {
        if (selectedDatas.length < 1
            || selectedDatas.find(selectedData => (
                !((selectedData.status === "FINALIZED" || selectedData.status === "PROPOSED") && selectedData.confirmedDate === null))
            )) {
            return true;
        }

        return false;
    }, [selectedDatas]);

    const isDisableApprove = useCallback(() => {
        if (selectedDatas.length < 1
            || selectedDatas.find(selectedData => (
                !((selectedData.status === "FINALIZED" || selectedData.status === "PROPOSED") && selectedData.confirmedDate === null))
            )) {
            return true;
        }

        return false;
    }, [selectedDatas]);

    const handleSubmit = useCallback(() => {
        if (selectedDatas.length < 1) {
            messageBarVM.showError("Please select a record(s) to submit.");
            return;
        }

        setIsLoading(true);
        chargeSuppleTermsVM.onSubmit(selectedDatas).then(() => {
            companyDtlState.companyId && chargeSuppleTermsVM.initialChgSuppleTermsTableData(companyDtlState.companyId).then(() => {
                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false)
            })
        }).catch((error) => {
            setIsLoading(false)
            messageBarVM.showError(error.message)
        })

    }, [companyDtlState.companyId, chargeSuppleTermsVM, messageBarVM, selectedDatas]);

    const handleReject = useCallback(() => {
        if (selectedDatas.length < 1) {
            messageBarVM.showError("Please select a record(s) to reject.");
            return;
        }

        chargeSuppleTermsVM.handleReject();
    }, [chargeSuppleTermsVM, messageBarVM, selectedDatas]);

    const handleApprove = useCallback(() => {
        if (selectedDatas.length < 1) {
            messageBarVM.showError("Please select a record(s) to approve.");
            return;
        }

        setIsLoading(true);
        chargeSuppleTermsVM.onApprove(selectedDatas).then(() => {
            companyDtlState.companyId && chargeSuppleTermsVM.initialChgSuppleTermsTableData(companyDtlState.companyId).then(() => {
                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false)
            })
        }).catch((error) => {
            setIsLoading(false)
            messageBarVM.showError(error.message)
        })

    }, [companyDtlState.companyId, chargeSuppleTermsVM, messageBarVM, selectedDatas]);

    const isRowSelectable = useCallback((params: any) => {
        if (selectedDatas.length && isShowSuppleTerms) {
            const findRow = selectedDatas.find((row: any) => row.id === params.data.id);
            if (findRow) {
                return true;
            } else {
                return !(isShowFsp || isShowEmptyPool || isShowSuppleTerms || isShowCustAssign || isShowCustInfo || isShowFactor);
            }
        } else {
            return !(isShowFsp || isShowEmptyPool || isShowSuppleTerms || isShowCustAssign || isShowCustInfo || isShowFactor);
        }
    }, [isShowCustAssign, isShowCustInfo, isShowEmptyPool, isShowFactor, isShowFsp, isShowSuppleTerms, selectedDatas]);

    const memoTableTitle = useMemo(() =>
        <Sidebarheader style={{ width: "100%", display: "flex", alignItems: "center" }}>
            <HeaderTitle>{COMPANY_DTL_CONST.CHARGE_SUPPLE_TERMS.CHG_SUPPLE_TERMS}</HeaderTitle>
            <StyledAction className="tm-animated-wrapper">
                {
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <HPHButton label={'Submit'} size={'Small'} disabled={isDisableSubmit() || !isEditCompDtlInfo} theme={'Secondary'} onClick={handleSubmit} />
                        {(isReadReject && isUpdateReject) && <HPHButton label={'Reject'} size={'Small'} disabled={isDisableReject()} theme={'Secondary'} onClick={handleReject} />}
                        {(isReadApprove && isUpdateApprove) && <HPHButton label={'Approve'} size={'Small'} disabled={isDisableApprove()} theme={'Primary'} onClick={handleApprove} />}
                    </div>
                }
            </StyledAction>
        </Sidebarheader>
        , [COMPANY_DTL_CONST.CHARGE_SUPPLE_TERMS.CHG_SUPPLE_TERMS, handleApprove, handleReject, handleSubmit, isDisableApprove, isDisableReject, isDisableSubmit, isEditCompDtlInfo, isReadApprove, isReadReject, isUpdateApprove, isUpdateReject])

    const memoChargeSuppleTermsTable = useMemo(() => {
        return (
            <div style={{ marginTop: "50px" }}>
                <NbisTable
                    id='charge-supple-terms-table'
                    isNewColumnSetting={true}
                    columns={INITIAL_CHARGE_SUPPLE_TERMS_COL_DEF.slice()}
                    data={transferRowData(companyDtlState.compChargeSuppleTerms) ?? []}
                    headerActionButtons={memoTableBtns}
                    showPaginator={false}
                    editable={false}
                    showHeaderIcons={true}
                    showAddIcon={false}
                    showDeleteButton={false}
                    showReloadIcon={false}
                    isScrollHighlighted={true}
                    isRowHighligted={true}
                    selectionMode={false}
                    rowSelection={"multiple"}
                    onSelectionChanged={(e: any) => handleSelectionChange(e, 400)}
                    onRowDoubleClick={(e: any, row: CompanyChargeSuppleTermsEntity) => handleEdit(row)}
                    onAddButton={handleAddClick}
                    onRefreshButton={handleRefresh}
                    onDeleteButton={handleDelete}
                    gridHeight="customHeight"
                    customHeight="calc(100vh - 700px)"
                    ref={gridRef}
                    headerLabel={memoTableTitle}
                    isRowSelectable={isRowSelectable}
                />
            </div>
        );
    }, [companyDtlState.compChargeSuppleTerms, handleAddClick, handleDelete, handleEdit, handleRefresh, handleSelectionChange, memoTableBtns, memoTableTitle, isRowSelectable])

    useEffect(() => {
        if (gridRef.current && gridRef.current.gridRef && gridRef.current.gridRef.current && gridRef.current.gridRef.current.api) {
            if (!isShowSuppleTerms) {
                gridRef.current?.gridRef.current.api?.deselectAll();
            }
            gridRef.current.gridRef.current.api.redrawRows();
        }
    }, [isShowCustAssign, isShowCustInfo, isShowEmptyPool, isShowFactor, isShowFsp, isShowSuppleTerms])

    return <><TableWrapper>{memoChargeSuppleTermsTable}</TableWrapper>
        {isLoading && <Loader Indicator="Spinner" size="Medium" />} </>;
}

export default memo(ChargeSuppleTermsTablePanel);
