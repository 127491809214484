import _ from "lodash";
import { EMPTY_CHARGE_DATA_SEARCH_CRITERIA } from "presentation/constant/ChargeDataEnquiry/ChargeDataSearchCriteria";
import { CriteriaDirectoryChargeCheckboxOption } from "presentation/constant/ChargeDataEnquiry/CriteriaDirectoryCheckboxOption";
import { ChargeConstant } from "presentation/constant/ChargeHeader/ChargeConstant";
import { billingTypeDropdownOption, manSysDropdownOption, stateDropdownOption } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { useChargeDataSearchVM } from "presentation/hook/ChargeDataEnquiry/useChargeDataSearchVM";
import { useChargeDataSearchTracked } from "presentation/store/ChargeDataEnquiry/ChargeDataSearchProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DateRangePickerComponent from "presentation/view/components/DateRangePickerComponent";
import NbisInputDropDown from "presentation/view/components/NbisInputDropDown";
import { useMemo } from "react";
import {
    HPHGroupTitle,
    InputField
} from "veronica-ui-component/dist/component/core";

export const ChargeDataSearchCriteriaEditChargePanel = () => {
    const CONTAINER_CONSTANT = ChargeConstant.Charge;
    const [chargeDataSearchState] = useChargeDataSearchTracked();
    const chargeDataSearchCriteria = chargeDataSearchState.searchCriteria
    const chargeDataSearchVM = useChargeDataSearchVM();
    const enabledCriteria = useMemo(() => CriteriaDirectoryChargeCheckboxOption.find(item => chargeDataSearchState.enabledSearchCriteria[item.key]), [chargeDataSearchState.enabledSearchCriteria])
    const memoSubChgTypeOptions = useMemo(() => {
        const groupOfSubChgTypeOptions = chargeDataSearchCriteria.chgTypeList?.map(chgType => chargeDataSearchState.dynamicOptions.subChgTypeDropdownOptions[chgType])
        const commonSubChgTypeOptions = _.orderBy(_.uniqBy(_.flatMap(_.concat(groupOfSubChgTypeOptions ?? [])), 'value'), 'dropdownLabel');
        return commonSubChgTypeOptions;
    }, [chargeDataSearchCriteria.chgTypeList, chargeDataSearchState.dynamicOptions.subChgTypeDropdownOptions]);



    const memoChgType = useMemo(() =>
        <div className='im-flex-row-item'>
            <NbisInputDropDown
                label={CONTAINER_CONSTANT.CHARGE_TYPE}
                width='150px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={chargeDataSearchCriteria.chgTypeList?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => {
                    chargeDataSearchVM.onMultipleDropdownChange(e, 'chgTypeList')
                    chargeDataSearchVM.onDropdownChange(EMPTY_CHARGE_DATA_SEARCH_CRITERIA.subChgTypeList, 'subChgTypeList')
                }}
                options={chargeDataSearchState.dynamicOptions.chgTypeDropdownOptions} />
        </div>
        , [chargeDataSearchCriteria.chgTypeList, chargeDataSearchState.dynamicOptions.chgTypeDropdownOptions, chargeDataSearchVM, CONTAINER_CONSTANT.CHARGE_TYPE])
    const memoSubChgType = useMemo(() =>
        <div className='im-flex-row-item'>
            <NbisInputDropDown
                label={CONTAINER_CONSTANT.SUB_CHARGE_TYPE}
                width='150px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={chargeDataSearchCriteria.subChgTypeList?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => chargeDataSearchVM.onMultipleDropdownChange(e, 'subChgTypeList')}
                options={memoSubChgTypeOptions} />
        </div>
        , [chargeDataSearchCriteria.subChgTypeList, chargeDataSearchVM, memoSubChgTypeOptions, CONTAINER_CONSTANT.SUB_CHARGE_TYPE])
    const memoBillToCompany = useMemo(() =>
        <div className='im-flex-row-item'>
            <NbisInputDropDown
                label={CONTAINER_CONSTANT.BILL_TO_COMPANY}
                inputType="freeText"
                width="150px"
                mode={'multiple'}
                sort={false}
                value={chargeDataSearchCriteria.billToCompList?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => chargeDataSearchVM.onMultipleDropdownChange(e, 'billToCompList')}
                options={chargeDataSearchState.dynamicOptions.billToCompDropdownOptions} />
        </div>
        , [chargeDataSearchCriteria.billToCompList, chargeDataSearchVM, chargeDataSearchState.dynamicOptions.billToCompDropdownOptions, CONTAINER_CONSTANT.BILL_TO_COMPANY])
    const memoChargeOnCompany = useMemo(() =>
        <div className='im-flex-row-item'>
            <NbisInputDropDown
                label={CONTAINER_CONSTANT.CHARGE_ON_COMPANY}
                width='150px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={chargeDataSearchCriteria.chgOnCompList?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => chargeDataSearchVM.onMultipleDropdownChange(e, 'chgOnCompList')}
                options={chargeDataSearchState.dynamicOptions.chgOnCompDropdownOptions} />
        </div>
        , [chargeDataSearchCriteria.chgOnCompList, chargeDataSearchVM, chargeDataSearchState.dynamicOptions.chgOnCompDropdownOptions, CONTAINER_CONSTANT.CHARGE_ON_COMPANY])
    const memoState = useMemo(() =>
        <div className='im-flex-row-item'>
            <NbisInputDropDown
                label={CONTAINER_CONSTANT.STATE}
                width='150px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={chargeDataSearchCriteria.stateList?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => chargeDataSearchVM.onMultipleDropdownChange(e, 'stateList')}
                options={stateDropdownOption} />
        </div>
        , [chargeDataSearchCriteria.stateList, chargeDataSearchVM, CONTAINER_CONSTANT.STATE])
    const memoTarCode = useMemo(() =>
        <div className='im-flex-row-item'>
            <NbisInputDropDown
                label={CONTAINER_CONSTANT.TARIFF_CODE}
                width='150px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={chargeDataSearchCriteria.tarCodeList?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => chargeDataSearchVM.onMultipleDropdownChange(e, 'tarCodeList')}
                options={chargeDataSearchState.dynamicOptions.tarCodeDropdownOptions} />
        </div>
        , [chargeDataSearchCriteria.tarCodeList, chargeDataSearchVM, chargeDataSearchState.dynamicOptions.tarCodeDropdownOptions, CONTAINER_CONSTANT.TARIFF_CODE])
    const memoBillingType = useMemo(() =>
        <div className='im-flex-row-item'>
            <NbisInputDropDown
                label={CONTAINER_CONSTANT.BILLING_TYPE}
                width='150px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={chargeDataSearchCriteria.billingTypeList?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => chargeDataSearchVM.onMultipleDropdownChange(e, 'billingTypeList')}
                options={billingTypeDropdownOption} />
        </div>
        , [chargeDataSearchCriteria.billingTypeList, chargeDataSearchVM, CONTAINER_CONSTANT.BILLING_TYPE])
    const memoManSysInd = useMemo(() =>
        <div className='im-flex-row-item'>
            <NbisInputDropDown
                label={CONTAINER_CONSTANT.MANUAL_SYSTEM_IND}
                width='150px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={chargeDataSearchCriteria.manSysInd?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => chargeDataSearchVM.onMultipleDropdownChange(e, 'manSysInd')}
                options={manSysDropdownOption} />
        </div>
        , [chargeDataSearchCriteria.manSysInd, chargeDataSearchVM, CONTAINER_CONSTANT.MANUAL_SYSTEM_IND]);
    const memoOpsFromTo = useMemo(() =>
        <div className='im-flex-row-item'>
            <DateRangePickerComponent
                label={CONTAINER_CONSTANT.OPS_DATE_RANGE}
                width="330px"
                fieldNames={{ startField: "opsDateFrom", endField: "opsDateTo" }}
                dateRange={{ startDate: chargeDataSearchCriteria.opsDateFrom, endDate: chargeDataSearchCriteria.opsDateTo }}
                onDatesChange={chargeDataSearchVM.onDateRangeChange}
            />
        </div>
        , [chargeDataSearchCriteria.opsDateFrom, chargeDataSearchCriteria.opsDateTo, chargeDataSearchVM.onDateRangeChange, CONTAINER_CONSTANT.OPS_DATE_RANGE])
    const memoTarCodeDesc = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputField
                width="330px"
                maxLength={999}
                label={CONTAINER_CONSTANT.TARIFF_CODE_DESC}
                type="text"
                value={chargeDataSearchCriteria.tarCodeDesc || ''}
                onChange={(e: any) => chargeDataSearchVM.onInputTextChange(e, 'tarCodeDesc')} />
        </div>
        , [chargeDataSearchCriteria.tarCodeDesc, chargeDataSearchVM, CONTAINER_CONSTANT.TARIFF_CODE_DESC])
    const memoExpMsg = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputField
                width="330px"
                maxLength={999}
                label={CONTAINER_CONSTANT.EXCEPTION_MESSAGE}
                type="text"
                value={chargeDataSearchCriteria.expMsg || ''}
                onChange={(e: any) => chargeDataSearchVM.onInputTextChange(e, 'expMsg')} />
        </div>
        , [chargeDataSearchCriteria.expMsg, chargeDataSearchVM, CONTAINER_CONSTANT.EXCEPTION_MESSAGE])
    const memoBillingCycleCode = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputField
                width='150px'
                maxLength={999}
                label={CONTAINER_CONSTANT.BILLING_CYCLE_CODE}
                type="text"
                value={chargeDataSearchCriteria.billingCycleCode || ''}
                onChange={(e: any) => chargeDataSearchVM.onInputTextChange(e, 'billingCycleCode')} />
        </div>
        , [chargeDataSearchCriteria.billingCycleCode, chargeDataSearchVM, CONTAINER_CONSTANT.BILLING_CYCLE_CODE])

    if (!enabledCriteria) return null;

    return <div className='im-charge-data-search-criteria-group'>
        <HPHGroupTitle titleText={CONTAINER_CONSTANT.CHARGE} />
        <CriteriaItemContainer>
            {chargeDataSearchState.enabledSearchCriteria.opsDate ? <>{memoOpsFromTo}</> : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {chargeDataSearchState.enabledSearchCriteria.stateList ? memoState : null}
            {chargeDataSearchState.enabledSearchCriteria.tarCodeList ? memoTarCode : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {chargeDataSearchState.enabledSearchCriteria.chgOnCompList ? memoChargeOnCompany : null}
            {chargeDataSearchState.enabledSearchCriteria.billToCompList ? memoBillToCompany : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {chargeDataSearchState.enabledSearchCriteria.chgTypeList ? <>{memoChgType}</> : null}
            {chargeDataSearchState.enabledSearchCriteria.subChgTypeList ? <>{memoSubChgType}</> : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {chargeDataSearchState.enabledSearchCriteria.billingTypeList ? memoBillingType : null}
            {chargeDataSearchState.enabledSearchCriteria.billingCycleCode ? memoBillingCycleCode : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {chargeDataSearchState.enabledSearchCriteria.tarCodeDesc ? memoTarCodeDesc : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {chargeDataSearchState.enabledSearchCriteria.expMsg ? memoExpMsg : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {chargeDataSearchState.enabledSearchCriteria.manSysInd ? memoManSysInd : null}
        </CriteriaItemContainer>
    </div>;
};
