import { SelectionChangedEvent } from "ag-grid-community";
import _ from "lodash";
import { INITIAL_GENERAL_PROPOSAL_TARIFF_ITEM_TIER_COL_DEF, transferRowData } from "presentation/constant/GeneralProposal/GeneralProposalTariffItemTierColumnDefinition";
import { useGeneralProposalDetailVM } from "presentation/hook/GeneralProposal/useGeneralProposalDetailVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useGeneralProposalDetailTracked } from "presentation/store/GeneralProposal/GeneralProposalDetailProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useMemo, useRef } from "react";
import { GeneralProposalHeaderFormPanel } from "../Left/GeneralProposalHeaderFormPanel";
import AddTariffItemIsTablePanel from "../Right/AddTariffItemIsTablePanel";

const GeneralProposalDetailTablePanel: React.FC = () => {
    const [generalProposalDetailState] = useGeneralProposalDetailTracked();
    const generalProposalDetailVM = useGeneralProposalDetailVM();
    const { generalProposalItemTierList, currentTariffItemTier, generalProposalItemIsList, tariffItemTierSelectedRows, isShowEditPanel } = generalProposalDetailState;
    let gridRef: any = useRef();
    const messageBarVM = useMessageBarVM();

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();
        generalProposalDetailVM.updateSelectedTariffItemTierRows(selectedRows);

    }, [generalProposalDetailVM])

    const handleAdd = useCallback(() => {
        if (!_.isEmpty(generalProposalItemTierList) && generalProposalItemTierList.length === 1 && !_.isEmpty(generalProposalItemIsList)) {
            messageBarVM.showError("Intensive surcharge does not support multiple charge tier.");
            return;
        }
        generalProposalDetailVM.onAddTier();
    }, [messageBarVM, generalProposalItemTierList, generalProposalItemIsList, generalProposalDetailVM]);

    // const handleClose = useCallback(() => {
    //     generalProposalDetailVM.onTariffItemTierCloseClick();
    // }, [generalProposalDetailVM]);


    const handleEdit = useCallback(() => {
        if (currentTariffItemTier?.tierNo == null) {
            messageBarVM.showError("Please select a Tier record.");
            return;
        }
        generalProposalDetailVM.onTariffItemTierEidtClick();
    }, [currentTariffItemTier?.tierNo, messageBarVM, generalProposalDetailVM]);


    const handleDelete = useCallback(() => {

        if (_.isEmpty(tariffItemTierSelectedRows)) {
            messageBarVM.showError("Please select a Tier record.");
            return;
        }

        if (_.isEmpty(generalProposalItemTierList) || generalProposalItemTierList.length === 1
            || generalProposalItemTierList.length === tariffItemTierSelectedRows.length) {
            messageBarVM.showError('At least one tier is required.')
            return;
        }
        generalProposalDetailVM.onDeleteTier();
    }, [generalProposalDetailVM, generalProposalItemTierList, messageBarVM, tariffItemTierSelectedRows])



    const memoBtns = useMemo(() => {

        const hdrBtns = [];
        if (!isShowEditPanel) {
            hdrBtns.push({
                id: 'onEditButton',
                icon: 'Icon-pen',
                title: 'Edit'
            })
        }

        if (!isShowEditPanel) {
            hdrBtns.push({
                id: 'onDeleteButton',
                icon: 'Icon-trash',
                title: 'Delete'
            })
        }

        // hdrBtns.push(
        //     {
        //         id: 'onCloseButton',
        //         icon: 'Icon-cross',
        //         title: 'Close'
        //     }
        // )

        // if(!_.isEmpty(generalProposalItemTierList) || !_.isEmpty(generalProposalItemIsList)){
        //     hdrBtns.push({
        //         id: 'onTickButton',
        //         icon: 'Icon-tick',
        //         title: 'Save'
        //     })
        // }

        return hdrBtns
    }, [isShowEditPanel])


    const memoGeneralProposalTariffItemTierTable = useMemo(() => {

        return (
            <NbisTable
                id='general-proposal-detail-table'
                isNewColumnSetting={true}
                columns={INITIAL_GENERAL_PROPOSAL_TARIFF_ITEM_TIER_COL_DEF.slice()}
                data={transferRowData(generalProposalItemTierList ?? [])}
                headerActionButtons={memoBtns}
                onEditButton={handleEdit}
                onDeleteButton={handleDelete}
                // onCloseButton={handleClose}
                //onTickButton={handleSave}
                showPaginator={false}
                showAddIcon={!isShowEditPanel}
                onAddClick={handleAdd}
                showUploadIcon={false}
                showReloadIcon={false}
                isScrollHighlighted={true}
                selectionMode={false}
                rowSelection={"multiple"}
                isRowHighligted={true}
                onSelectionChanged={handleSelectionChange}
                gridHeight="customHeight"
                customHeight="calc(100vh - 630px)"
                ref={gridRef}
            />
        );
    }, [generalProposalItemTierList, memoBtns, handleEdit, handleDelete, isShowEditPanel, handleAdd, handleSelectionChange])

    const memoTariffItemISTable = useMemo(() => {
        return <AddTariffItemIsTablePanel />
    }, [])

    // const memoTariffItemIsEditPanel = useMemo(() => {
    //     return <AddTariffItemIsEditPanel />
    // }, [])

    return <><div className="main-comp-wrapper">
        <div style={{height: '25%'}}>
        <GeneralProposalHeaderFormPanel />
        </div>
        <div style={{ height: '40%',}}>
            <TableWrapper>
                {memoGeneralProposalTariffItemTierTable}
            </TableWrapper>
            
            {/* {<div className={`child-div${(isAddIs || isAddTier || isEditIs || isEditTier) ? " child-div-gen-tariff-item-edit-expanded " : ""}`}>
                {memoTariffItemIsEditPanel}
            </div>} */}
        </div>
        <div style={{height: '35%'}}>
            {memoTariffItemISTable}
        </div>
        {/* {
            isEditingProposal && <SidebarActionBar>
            <HPHButton label={WorkspaceConstant.Common.BUTTON_SAVE} size={'Small'} theme={'Primary'} onClick={handleSave} />
        </SidebarActionBar>
        }    */}

    </div></>;
}

export default memo(GeneralProposalDetailTablePanel);
