export interface ShiftEntity {
    id: number,
    priority: number,
    tempPriority: number,
    shiftType: string,
    shiftDay: string | null,
    startTime: Date | null,
    startTimeStr: string | null,
    startTimeHour: number | null,
    startTimeMin: number | null,
    endTime: Date | null,
    endTimeStr: string | null,
    endTimeHour: number | null,
    endTimeMin: number | null,
    shiftCode: string,
    aheadTime: Date | null,
    aheadTimeStr: string | null,
    aheadTimeHour: number | null,
    aheadTimeMin: number | null,
    extendTime: Date | null,
    extendTimeStr: string | null,
    extendTimeHour: number | null,
    extendTimeMin: number | null,
    activeInd: "Y" | "N",
    [key: string]: string | number | Date | null | undefined | boolean
}
export const EMPTY_SHIFT_ENTITY: ShiftEntity = {
    id: 0,
    priority: 0,
    tempPriority: 0,
    fromDate: "",
    toDate: "",
    activeInd: "Y",
    shiftType: "",
    shiftDay: "",
    startTime: null,
    endTime: null,
    shiftCode: "",
    aheadTime: null,
    extendTime: null,
    startTimeHour: null,
    startTimeMin: null,
    endTimeHour: null,
    endTimeMin: null,
    aheadTimeHour: null,
    aheadTimeMin: null,
    extendTimeHour: null,
    extendTimeMin: null,
    startTimeStr: '',
    endTimeStr: '',
    aheadTimeStr: '',
    extendTimeStr: '',
} 
