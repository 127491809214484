import { useGeneralProposalDetailVM } from "presentation/hook/GeneralProposal/useGeneralProposalDetailVM";
import { useGeneralProposalDetailTracked } from "presentation/store/GeneralProposal/GeneralProposalDetailProvider";
import { memo, useCallback, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import { AddTariffItemIsEditPanel } from "./Right/AddTariffItemIsEditPanel";
import GeneralProposalDetailTablePanel from "./Table/GeneralProposalDetailTablePanel";

const GeneralProposalDetailMaintenance: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const generalProposalDtlVM = useGeneralProposalDetailVM();
    const [generalProposalDetailState] = useGeneralProposalDetailTracked();

    const initialScreen = useCallback(async () => {
        setIsLoading(true);
        try {
            const results = await Promise.allSettled([
                generalProposalDtlVM.onPageInit(),
                generalProposalDtlVM.loadDropdownOption(),
            ]);
            results.forEach((result, index) => {
                if (index === 0 && result.status === 'fulfilled') {

                } else if (index === 1 && result.status === 'rejected') {

                }
            });
            setIsLoading(false);
        } catch (error) {
        }
    }, [generalProposalDtlVM]);

    useEffect(() => {
        initialScreen();
    }, [initialScreen])


    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;
    return <>
        <div className={`main-comp-wrapper${(generalProposalDetailState.isShowEditPanel) ? '' : ' im-hide-side-form-draggable'}`}>
            {/* <GeneralProposalDetailTitleBar /> */}
            <SliderPanel
                isOpen={true}
                draggable={false}
                leftSectionWidth={generalProposalDetailState.isShowEditPanel ? "70%" : "100%"}
                rightSectionWidth={generalProposalDetailState.isShowEditPanel ? "30%" : "0%"}
                leftChildren={<GeneralProposalDetailTablePanel />}
                rightChildren={<AddTariffItemIsEditPanel />}
            />
        </div>
    </>
}

export default memo(GeneralProposalDetailMaintenance);