import { useMemo } from "react";
import {
    HPHGroupTitle,
    HPHInputTextarea,
    InputField
} from "veronica-ui-component/dist/component/core";
// import _ from "lodash";
import { CriteriaDirectoryContainerCheckboxOption } from "presentation/constant/ChargeDataEnquiry/CriteriaDirectoryCheckboxOption";
import { ChargeConstant } from "presentation/constant/ChargeHeader/ChargeConstant";
import { bundleIndDropdownOption, endVisitIndDropdownOption, fullEmptyDropdownOption, modalityDropdownOption, operationTypeDropdownOption } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { useChargeDataSearchVM } from "presentation/hook/ChargeDataEnquiry/useChargeDataSearchVM";
import { useChargeDataSearchTracked } from "presentation/store/ChargeDataEnquiry/ChargeDataSearchProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DateRangePickerComponent from "presentation/view/components/DateRangePickerComponent";
import NbisInputDropDown from "presentation/view/components/NbisInputDropDown";

export const ChargeDataSearchCriteriaEditContainerPanel = () => {
    const CHARGE_CONSTANT = ChargeConstant.Charge;
    const [chargeDataSearchState] = useChargeDataSearchTracked();
    const chargeDataSearchCriteria = chargeDataSearchState.searchCriteria
    const chargeDataSearchVM = useChargeDataSearchVM();
    const enabledCriteria = useMemo(() => CriteriaDirectoryContainerCheckboxOption.find(item => chargeDataSearchState.enabledSearchCriteria[item.key]), [chargeDataSearchState.enabledSearchCriteria])
    const memoCntrNo = useMemo(() =>
        <div className='im-flex-row-item'>
            <HPHInputTextarea
                label={CHARGE_CONSTANT.CNTR_NO}
                width="330px"
                rows={3}
                resizable={false}
                helpIcon={true}
                toolTipText={"Use comma ( , ) as delimiter"}
                value={chargeDataSearchCriteria.cntrList?.join(",")}
                onChange={(e: any) => { chargeDataSearchVM.onTextAreaChange(e, "cntrList", true) }} />
        </div>
        , [chargeDataSearchCriteria.cntrList, chargeDataSearchVM, CHARGE_CONSTANT.CNTR_NO])
    const memoHandlingTml = useMemo(() =>
        <div className='im-flex-row-item'>
            <NbisInputDropDown
                label={CHARGE_CONSTANT.HANDLING_TML}
                width='150px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={chargeDataSearchCriteria.handlingTmlList?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => {
                    chargeDataSearchVM.onMultipleDropdownChange(e, 'handlingTmlList')
                }}
                options={chargeDataSearchState.dynamicOptions.handlingTmlDropdownOptions} />
        </div>
        , [chargeDataSearchCriteria.handlingTmlList, chargeDataSearchState.dynamicOptions.handlingTmlDropdownOptions, chargeDataSearchVM, CHARGE_CONSTANT.HANDLING_TML])
    const memoOperationType = useMemo(() =>
        <div className='im-flex-row-item'>
            <NbisInputDropDown
                label={CHARGE_CONSTANT.OPERATION_TYPE}
                width='150px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={chargeDataSearchCriteria.operationTypeList?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => chargeDataSearchVM.onMultipleDropdownChange(e, 'operationTypeList')}
                options={operationTypeDropdownOption} />
        </div>
        , [chargeDataSearchCriteria.operationTypeList, chargeDataSearchVM, CHARGE_CONSTANT.OPERATION_TYPE])
    const memoBundleInd = useMemo(() =>
        <div className='im-flex-row-item'>
            <NbisInputDropDown
                label={CHARGE_CONSTANT.BUNDLE_IND}
                inputType="freeText"
                width="150px"
                mode={'multiple'}
                sort={false}
                value={chargeDataSearchCriteria.bundleIndList?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => chargeDataSearchVM.onMultipleDropdownChange(e, 'bundleIndList')}
                options={bundleIndDropdownOption} />
        </div>
        , [chargeDataSearchCriteria.bundleIndList, chargeDataSearchVM, CHARGE_CONSTANT.BUNDLE_IND])
    const memoCntrVisit = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputField
                width='150px'
                maxLength={999}
                label={CHARGE_CONSTANT.CONTAINER_VISIT}
                type="text"
                value={chargeDataSearchCriteria.cntrVisit || ''}
                onChange={(e: any) => chargeDataSearchVM.onInputTextChange(e, 'cntrVisit')} />
        </div>
        , [chargeDataSearchCriteria.cntrVisit, chargeDataSearchVM, CHARGE_CONSTANT.CONTAINER_VISIT])
    const memoSize = useMemo(() =>
        <div className='im-flex-row-item'>
            <NbisInputDropDown
                label={CHARGE_CONSTANT.SIZE}
                width='150px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={chargeDataSearchCriteria.sizeList?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => chargeDataSearchVM.onMultipleDropdownChange(e, 'sizeList')}
                options={chargeDataSearchState.dynamicOptions.sizeGroupDropdownOptions} />
        </div>
        , [chargeDataSearchCriteria.sizeList, chargeDataSearchVM, chargeDataSearchState.dynamicOptions.sizeGroupDropdownOptions, CHARGE_CONSTANT.SIZE])
    const memoType = useMemo(() =>
        <div className='im-flex-row-item'>
            <NbisInputDropDown
                label={CHARGE_CONSTANT.TYPE}
                width='150px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={chargeDataSearchCriteria.typeList?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => chargeDataSearchVM.onMultipleDropdownChange(e, 'typeList')}
                options={chargeDataSearchState.dynamicOptions.typeDropdownOptions} />
        </div>
        , [chargeDataSearchCriteria.typeList, chargeDataSearchVM, chargeDataSearchState.dynamicOptions.typeDropdownOptions, CHARGE_CONSTANT.TYPE])
    const memoModalityOfArrival = useMemo(() =>
        <div className='im-flex-row-item'>
            <NbisInputDropDown
                label={CHARGE_CONSTANT.MODALITY_OF_ARRIVAL}
                width='150px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={chargeDataSearchCriteria.modalityOfArrivalList?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => chargeDataSearchVM.onMultipleDropdownChange(e, 'modalityOfArrivalList')}
                options={modalityDropdownOption} />
        </div>
        , [chargeDataSearchCriteria.modalityOfArrivalList, chargeDataSearchVM, CHARGE_CONSTANT.MODALITY_OF_ARRIVAL])
    const memoModalityOfDepature = useMemo(() =>
        <div className='im-flex-row-item'>
            <NbisInputDropDown
                label={CHARGE_CONSTANT.MODALITY_OF_DEPATURE}
                width='150px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={chargeDataSearchCriteria.modalityofDepatureList?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => chargeDataSearchVM.onMultipleDropdownChange(e, 'modalityofDepatureList')}
                options={modalityDropdownOption} />
        </div>
        , [chargeDataSearchCriteria.modalityofDepatureList, chargeDataSearchVM, CHARGE_CONSTANT.MODALITY_OF_DEPATURE])
    const memoFullEmpty = useMemo(() =>
        <div className='im-flex-row-item'>
            <NbisInputDropDown
                label={CHARGE_CONSTANT.FULL_EMPTY}
                width='150px'
                inputType="freeText"
                mode={'single'}
                sort={false}
                value={chargeDataSearchCriteria.fullEmptyInd}
                onChange={(e: any) => chargeDataSearchVM.onDropdownChange(e, 'fullEmptyInd')}
                options={fullEmptyDropdownOption} />
        </div>
        , [chargeDataSearchCriteria.fullEmptyInd, chargeDataSearchVM, CHARGE_CONSTANT.FULL_EMPTY])
    const memoGateInFromTo = useMemo(() =>
        <div className='im-flex-row-item'>
            <DateRangePickerComponent
                label={CHARGE_CONSTANT.GATE_IN_RANGE}
                width="330px"
                fieldNames={{ startField: "gateInFrom", endField: "gateInTo" }}
                dateRange={{ startDate: chargeDataSearchCriteria.gateInFrom, endDate: chargeDataSearchCriteria.gateInTo }}
                onDatesChange={chargeDataSearchVM.onDateRangeChange}
            />
        </div>
        , [chargeDataSearchCriteria.gateInFrom, chargeDataSearchCriteria.gateInTo, chargeDataSearchVM, CHARGE_CONSTANT.GATE_IN_RANGE])
    const memoGateOutFromTo = useMemo(() =>
        <div className='im-flex-row-item'>
            <DateRangePickerComponent
                label={CHARGE_CONSTANT.GATE_OUT_RANGE}
                width="330px"
                fieldNames={{ startField: "gateOutFrom", endField: "gateOutTo" }}
                dateRange={{ startDate: chargeDataSearchCriteria.gateOutFrom, endDate: chargeDataSearchCriteria.gateOutTo }}
                onDatesChange={chargeDataSearchVM.onDateRangeChange}
            />
        </div>
        , [chargeDataSearchCriteria.gateOutFrom, chargeDataSearchCriteria.gateOutTo, chargeDataSearchVM, CHARGE_CONSTANT.GATE_OUT_RANGE])
    const memoSpecialHandlingCode = useMemo(() =>
        <div className='im-flex-row-item'>
            <NbisInputDropDown
                label={CHARGE_CONSTANT.SPECIAL_HANDLING_CODE}
                width='150px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={chargeDataSearchCriteria.specHandlingCodeList?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => chargeDataSearchVM.onMultipleDropdownChange(e, 'specHandlingCodeList')}
                options={chargeDataSearchState.dynamicOptions.specHandingCodeDropdownOptions} />
        </div>
        , [chargeDataSearchCriteria.specHandlingCodeList, chargeDataSearchVM, chargeDataSearchState.dynamicOptions.specHandingCodeDropdownOptions, CHARGE_CONSTANT.SPECIAL_HANDLING_CODE])
    const memoSpecialHandlingCodeD1 = useMemo(() =>
        <div className='im-flex-row-item'>
            <NbisInputDropDown
                label={CHARGE_CONSTANT.SPECIAL_HANDLING_CODE_D1}
                width='150px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={chargeDataSearchCriteria.specHandlingCodeD1List?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => chargeDataSearchVM.onMultipleDropdownChange(e, 'specHandlingCodeD1List')}
                options={chargeDataSearchState.dynamicOptions.specHandingCodeD1DropdownOptions} />
        </div>
        , [chargeDataSearchCriteria.specHandlingCodeD1List, chargeDataSearchVM, chargeDataSearchState.dynamicOptions.specHandingCodeD1DropdownOptions, CHARGE_CONSTANT.SPECIAL_HANDLING_CODE_D1])
    const memoEndVisitInd = useMemo(() =>
        <div className='im-flex-row-item'>
            <NbisInputDropDown
                label={CHARGE_CONSTANT.END_VISIT_IND}
                width='150px'
                inputType="freeText"
                mode={'single'}
                sort={false}
                value={chargeDataSearchCriteria.endVisitInd}
                onChange={(e: any) => chargeDataSearchVM.onDropdownChange(e, 'endVisitInd')}
                options={endVisitIndDropdownOption} />
        </div>
        , [chargeDataSearchCriteria.endVisitInd, chargeDataSearchVM, CHARGE_CONSTANT.END_VISIT_IND])
    const memoFromTml = useMemo(() =>
        <div className='im-flex-row-item'>
            <NbisInputDropDown
                label={CHARGE_CONSTANT.FROM_TML}
                width='150px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={chargeDataSearchCriteria.fromTml?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => chargeDataSearchVM.onMultipleDropdownChange(e, 'fromTml')}
                options={chargeDataSearchState.dynamicOptions.fromTmlDropdownOptions} />
        </div>
        , [chargeDataSearchCriteria.fromTml, chargeDataSearchVM, chargeDataSearchState.dynamicOptions.fromTmlDropdownOptions, CHARGE_CONSTANT.FROM_TML])
    const memoToTml = useMemo(() =>
        <div className='im-flex-row-item'>
            <NbisInputDropDown
                label={CHARGE_CONSTANT.TO_TML}
                width='150px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={chargeDataSearchCriteria.toTml?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => chargeDataSearchVM.onMultipleDropdownChange(e, 'toTml')}
                options={chargeDataSearchState.dynamicOptions.toTmlDropdownOptions} />
        </div>
        , [chargeDataSearchCriteria.toTml, chargeDataSearchVM, chargeDataSearchState.dynamicOptions.toTmlDropdownOptions, CHARGE_CONSTANT.TO_TML])


    if (!enabledCriteria) return null;

    return <div className='im-charge-data-search-criteria-group'>
        <HPHGroupTitle titleText={CHARGE_CONSTANT.CONTAINER} />
        <CriteriaItemContainer>
            {chargeDataSearchState.enabledSearchCriteria.cntrList ? <>{memoCntrNo}</> : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {chargeDataSearchState.enabledSearchCriteria.handlingTmlList ? memoHandlingTml : null}
            {chargeDataSearchState.enabledSearchCriteria.operationTypeList ? memoOperationType : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {chargeDataSearchState.enabledSearchCriteria.bundleIndList ? memoBundleInd : null}
            {chargeDataSearchState.enabledSearchCriteria.cntrVisit ? memoCntrVisit : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {chargeDataSearchState.enabledSearchCriteria.sizeList ? memoSize : null}
            {chargeDataSearchState.enabledSearchCriteria.typeList ? memoType : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {chargeDataSearchState.enabledSearchCriteria.modalityOfArrivalList ? memoModalityOfArrival : null}
            {chargeDataSearchState.enabledSearchCriteria.modalityofDepatureList ? memoModalityOfDepature : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {chargeDataSearchState.enabledSearchCriteria.fullEmptyInd ? memoFullEmpty : null}
            {chargeDataSearchState.enabledSearchCriteria.endVisitInd ? memoEndVisitInd : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {chargeDataSearchState.enabledSearchCriteria.gateIn ? memoGateInFromTo : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {chargeDataSearchState.enabledSearchCriteria.gateOut ? memoGateOutFromTo : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {chargeDataSearchState.enabledSearchCriteria.specHandlingCodeList ? memoSpecialHandlingCode : null}
            {chargeDataSearchState.enabledSearchCriteria.specHandlingCodeD1List ? memoSpecialHandlingCodeD1 : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {chargeDataSearchState.enabledSearchCriteria.fromTml ? memoFromTml : null}
            {chargeDataSearchState.enabledSearchCriteria.toTml ? memoToTml : null}
        </CriteriaItemContainer>
    </div>;
};
