import { consolMethodRadioOptions } from "presentation/constant/RadioOptions/StaticRadioOptions";
import { RecalProcessAndGenConstant } from "presentation/constant/Recalculation/RecalProcessAndGenConstant";
import { useRecalProcessAndGenVM } from "presentation/hook/Recalculation/useRecalProcessAndGenVM";
import { useRecalProcessAndGenMaintenanceTracked } from "presentation/store/Recalculation/RecalProcessAndGenMaintenanceProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DateRangePickerComponent from "presentation/view/components/DateRangePickerComponent";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { useMemo, useState } from "react";
import { FieldType, HPHGroupRadioButton, IFieldValue, Loader } from "veronica-ui-component/dist/component/core";
import { StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

export const RecalProcessAndGenFromPanel = () => {
    const [recalProcessAndGenState] = useRecalProcessAndGenMaintenanceTracked();
    const recalProcessAndGenVM = useRecalProcessAndGenVM();

    const RECAL_PROCESS_GEN_CONSTANT = RecalProcessAndGenConstant;
    const [isLoading] = useState(false);

    const { searchCriteria, dynamicOptions, masterState } = recalProcessAndGenState;
    const { isSubmitClicked, allFormState } = masterState;

    const memoMethodGroup = useMemo(() =>
        <div className='im-flex-row-item'>
            <HPHGroupRadioButton
                label="Consolidation Method"
                radioOptions={consolMethodRadioOptions}
                value={searchCriteria.consolidationType ?? ''}
                onChange={(e) => recalProcessAndGenVM.onRadioChange(e, 'consolidationType')}
                checked={consolMethodRadioOptions.find(item => item.key === searchCriteria.consolidationType)?.name}
            />
        </div>
        , [recalProcessAndGenVM, searchCriteria.consolidationType])

    const memoChargeOnCompany = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={false}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={searchCriteria.chargeOnCompany ?? ''}
                fieldValue={searchCriteria.chargeOnCompany ?? ''}
                fieldLabel={RECAL_PROCESS_GEN_CONSTANT.CHARGE_ON_COMPANY}
                isSaveClicked={isSubmitClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'chargeOnCompany'}
                isShowMissingError={true}
                maxLength={10}
                options={dynamicOptions.chargeOnCompanyDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    recalProcessAndGenVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, searchCriteria.chargeOnCompany, RECAL_PROCESS_GEN_CONSTANT.CHARGE_ON_COMPANY, isSubmitClicked, dynamicOptions.chargeOnCompanyDropdownOptions, recalProcessAndGenVM])

    const memoOpsDateRange = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>
            <DateRangePickerComponent
                label={RECAL_PROCESS_GEN_CONSTANT.OPS_DATE_RANGE}
                width='270px'
                fieldNames={{ startField: "opsDateFrom", endField: "opsDateTo" }}
                dateRange={{ startDate: searchCriteria.opsDateFrom, endDate: searchCriteria.opsDateTo }}
                errorMessage={allFormState?.opsDateFrom || allFormState?.opsDateTo}
                onDatesChange={recalProcessAndGenVM.onDateRangeChange}
            />
        </div>
        , [RECAL_PROCESS_GEN_CONSTANT.OPS_DATE_RANGE, searchCriteria.opsDateFrom, searchCriteria.opsDateTo, allFormState?.opsDateFrom, allFormState?.opsDateTo, recalProcessAndGenVM.onDateRangeChange])

    //if (isLoading) return <Loader Indicator="Stripe" size="Large" />;
    return <StyledSidebar style={{ width: '100%', height: "100%", border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'>

        {isLoading && <Loader Indicator="Spinner" size="Medium" />}
        {/* Readable and Editable Form */}
        <div className={'add-edit-form'} style={{ height: 'calc(100vh - 125px)', maxHeight: 'calc(100vh - 125px)', overflow: 'auto' }}>
            <div className="flex-row-auto">
                <div className={'flex-row-item'}>
                    <div className={'im-flex-row'}>
                        <div className={'flex-row-item-stretch'}>
                            {/*<SidebarTitle>{RECAL_PROCESS_GEN_CONSTANT.RECAL_CRITERIA}</SidebarTitle>*/}
                        </div>
                    </div>

                    <CriteriaItemContainer>
                        {memoChargeOnCompany}
                        {memoOpsDateRange}
                    </CriteriaItemContainer>

                    <CriteriaItemContainer>
                        {memoMethodGroup}
                    </CriteriaItemContainer>
                </div>
            </div>
        </div>
        {/* Readable and Editable Form */}
    </StyledSidebar>
}