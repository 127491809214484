import { ChargeDetailConstant } from "presentation/constant/ChargeDetailMaintenance/ChargeDetailConstant";
import { CriteriaDirectoryChargeCheckboxOption } from "presentation/constant/ChargeDetailMaintenance/ChargeDetailCriteriaDirectoryCheckboxOption";
import { cntrTspStatusDropdownOption, emptyIndDropdownOption, rfTempUnitDropdownOption } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { useChargeDetailVM } from "presentation/hook/ChargeDetailMaintenance/useChargeDetailVM";
import { useChargeDetailTracked } from "presentation/store/ChargeDetailMaintenance/ChargeDetailProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DateTimePickerComponent from "presentation/view/components/DateTimePickerComponent";
import NbisInputDropDown from "presentation/view/components/NbisInputDropDown";
import NumberInputComponent from "presentation/view/components/NumberInputComponent";
import { memo, useCallback, useMemo } from "react";
import { InputField } from "veronica-ui-component/dist/component/core";

const CntrInfoForm = () => {
    const CHARGEDETAIL_CONSTANT = ChargeDetailConstant.Charge;
    const [chargeDetailState] = useChargeDetailTracked();
    const chargeBatchUpdate = chargeDetailState.chargeBatchUpdate;
    const chargeDetailVM = useChargeDetailVM();
    const enabledCriteria = useMemo(() => CriteriaDirectoryChargeCheckboxOption.find(item => chargeDetailState.enabledSearchCriteria[item.key]), [chargeDetailState.enabledSearchCriteria]);
    const onNumberFieldChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
        chargeDetailVM.onInputTextChange(event, fieldName, chargeBatchUpdate, 'chargeBatchUpdate');
    }, [chargeDetailVM, chargeBatchUpdate]);

    const memoCntrTspStatus = useMemo(() =>
        <div className='im-flex-row-item'>
            <NbisInputDropDown
                label={CHARGEDETAIL_CONSTANT.CNTR_TSP_STATUS}
                width='150px'
                inputType="freeText"
                mode={'single'}
                sort={false}
                value={chargeBatchUpdate.cntrTspStatus}
                onChange={(e: any) => chargeDetailVM.onDropdownChange(e, 'cntrTspStatus', chargeBatchUpdate, 'chargeBatchUpdate')}
                options={cntrTspStatusDropdownOption} />
        </div>
        , [chargeBatchUpdate, chargeDetailVM, CHARGEDETAIL_CONSTANT.CNTR_TSP_STATUS])

    const memoEmptyInd = useMemo(() =>
        <div className='im-flex-row-item'>
            <NbisInputDropDown
                label={CHARGEDETAIL_CONSTANT.EMPTY_IND}
                width='150px'
                inputType="freeText"
                mode={'single'}
                sort={false}
                value={chargeBatchUpdate.emptyInd}
                onChange={(e: any) => chargeDetailVM.onDropdownChange(e, 'emptyInd', chargeBatchUpdate, 'chargeBatchUpdate')}
                options={emptyIndDropdownOption} />
        </div>
        , [chargeBatchUpdate, chargeDetailVM, CHARGEDETAIL_CONSTANT.EMPTY_IND])

    const memoImcoCode1 = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputField
                width='330px'
                maxLength={50}
                label={CHARGEDETAIL_CONSTANT.IMCO_CODE}
                type="text"
                value={chargeBatchUpdate.imcoCode1 ?? ''}
                onChange={(e: any) => chargeDetailVM.onInputTextChange(e, 'imcoCode1', chargeBatchUpdate, 'chargeBatchUpdate')} />
        </div>
        , [chargeBatchUpdate, chargeDetailVM, CHARGEDETAIL_CONSTANT.IMCO_CODE])

    const memoImcoCode2 = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputField
                width='330px'
                maxLength={50}
                label={CHARGEDETAIL_CONSTANT.IMCO_CODE2}
                type="text"
                value={chargeBatchUpdate.imcoCode2 ?? ''}
                onChange={(e: any) => chargeDetailVM.onInputTextChange(e, 'imcoCode2', chargeBatchUpdate, 'chargeBatchUpdate')} />
        </div>
        , [chargeBatchUpdate, chargeDetailVM, CHARGEDETAIL_CONSTANT.IMCO_CODE2])

    const memoImcoCode3 = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputField
                width='330px'
                maxLength={50}
                label={CHARGEDETAIL_CONSTANT.IMCO_CODE3}
                type="text"
                value={chargeBatchUpdate.imcoCode3 ?? ''}
                onChange={(e: any) => chargeDetailVM.onInputTextChange(e, 'imcoCode3', chargeBatchUpdate, 'chargeBatchUpdate')} />
        </div>
        , [chargeBatchUpdate, chargeDetailVM, CHARGEDETAIL_CONSTANT.IMCO_CODE3])

    const memoOlDimen = useMemo(() =>
        <div className='im-flex-row-item'>
            <NumberInputComponent
                label={CHARGEDETAIL_CONSTANT.OL_DIMEN}
                width='330px'
                fieldName={"olDimen"}
                value={chargeBatchUpdate.olDimen || ''}
                disabled={false}
                decimalPlaces={2}
                onChange={(e: any) => onNumberFieldChange(e, 'olDimen')}
            />
            {/*<InputField
                width='330px'
                maxLength={50}
                label={CHARGEDETAIL_CONSTANT.OL_DIMEN}
                type="text"
                value={chargeBatchUpdate.olDimen ?? ''}
                onChange={(e: any) => chargeDetailVM.onInputTextChange(e, 'olDimen', chargeBatchUpdate, 'chargeBatchUpdate')}/>*/}
        </div>
        , [chargeBatchUpdate, CHARGEDETAIL_CONSTANT.OL_DIMEN, onNumberFieldChange])

    const memoOwDimen = useMemo(() =>
        <div className='im-flex-row-item'>
            <NumberInputComponent
                label={CHARGEDETAIL_CONSTANT.OW_DIMEN}
                width='330px'
                fieldName={"owDimen"}
                value={chargeBatchUpdate.owDimen || ''}
                disabled={false}
                decimalPlaces={2}
                onChange={(e: any) => onNumberFieldChange(e, 'owDimen')}
            />
            {/*<InputField
                width='330px'
                maxLength={50}
                label={CHARGEDETAIL_CONSTANT.OW_DIMEN}
                type="text"
                value={chargeBatchUpdate.owDimen ?? ''}
                onChange={(e: any) => chargeDetailVM.onInputTextChange(e, 'owDimen', chargeBatchUpdate, 'chargeBatchUpdate')}/>*/}
        </div>
        , [chargeBatchUpdate, CHARGEDETAIL_CONSTANT.OW_DIMEN, onNumberFieldChange])

    const memoOhDimen = useMemo(() =>
        <div className='im-flex-row-item'>
            <NumberInputComponent
                label={CHARGEDETAIL_CONSTANT.OH_DIMEN}
                width='330px'
                fieldName={"ohDimen"}
                value={chargeBatchUpdate.ohDimen || ''}
                disabled={false}
                decimalPlaces={2}
                onChange={(e: any) => onNumberFieldChange(e, 'ohDimen')}
            />
            {/*<InputField
                width='330px'
                maxLength={50}
                label={CHARGEDETAIL_CONSTANT.OH_DIMEN}
                type="text"
                value={chargeBatchUpdate.ohDimen ?? ''}
                onChange={(e: any) => chargeDetailVM.onInputTextChange(e, 'ohDimen', chargeBatchUpdate, 'chargeBatchUpdate')}/>*/}
        </div>
        , [chargeBatchUpdate, CHARGEDETAIL_CONSTANT.OH_DIMEN, onNumberFieldChange])

    const memoCntrType = useMemo(() =>
        <div className='im-flex-row-item'>
            <NbisInputDropDown
                label={CHARGEDETAIL_CONSTANT.TYPE}
                width='330px'
                inputType="freeText"
                mode={'single'}
                sort={false}
                value={chargeBatchUpdate.cntrType}
                onChange={(e: any) => chargeDetailVM.onDropdownChange(e, 'cntrType', chargeBatchUpdate, 'chargeBatchUpdate')}
                options={chargeDetailState.dynamicOptions.typeDropdownOptions} />
        </div>
        , [chargeBatchUpdate, chargeDetailState.dynamicOptions.typeDropdownOptions, chargeDetailVM, CHARGEDETAIL_CONSTANT.TYPE])

    const memoRfTemp = useMemo(() =>
        <div className='im-flex-row-item'>
            <NumberInputComponent
                label={CHARGEDETAIL_CONSTANT.RF_TEMP}
                width='150px'
                fieldName={"rfTemp"}
                value={chargeBatchUpdate.rfTemp || ''}
                disabled={false}
                decimalPlaces={2}
                onChange={(e: any) => onNumberFieldChange(e, 'rfTemp')}
            />
            {/*<InputField
                width='150px'
                maxLength={50}
                label={CHARGEDETAIL_CONSTANT.RF_TEMP}
                type="number"
                value={chargeBatchUpdate.rfTemp || ''}
                onChange={(e: any) => chargeDetailVM.onInputTextChange(e, 'rfTemp', chargeBatchUpdate, 'chargeBatchUpdate')}/>*/}
        </div>
        , [chargeBatchUpdate, CHARGEDETAIL_CONSTANT.RF_TEMP, onNumberFieldChange])


    const memoRfTempUnit = useMemo(() =>
        <div className='im-flex-row-item'>
            <NbisInputDropDown
                label={CHARGEDETAIL_CONSTANT.RF_TEMP_UNIT}
                width='150px'
                inputType="freeText"
                mode={'single'}
                sort={false}
                value={chargeBatchUpdate.rfTempUnit}
                onChange={(e: any) => chargeDetailVM.onDropdownChange(e, 'rfTempUnit', chargeBatchUpdate, 'chargeBatchUpdate')}
                options={rfTempUnitDropdownOption} />
        </div>
        , [chargeBatchUpdate, chargeDetailVM, CHARGEDETAIL_CONSTANT.RF_TEMP_UNIT])

    const memoGrossWt = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputField
                width='150px'
                maxLength={50}
                label={CHARGEDETAIL_CONSTANT.GROSS_WT}
                type="number"
                value={chargeBatchUpdate.grossWt ?? ''}
                onChange={(e: any) => chargeDetailVM.onInputTextChange(e, 'grossWt', chargeBatchUpdate, 'chargeBatchUpdate')} />
        </div>
        , [chargeBatchUpdate, chargeDetailVM, CHARGEDETAIL_CONSTANT.GROSS_WT])

    const memoForwarderCode = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputField
                width='150px'
                maxLength={10}
                label={CHARGEDETAIL_CONSTANT.FORWARDER_CODE}
                type="text"
                value={chargeBatchUpdate.forwarderCode1 || ''}
                onChange={(e: any) => chargeDetailVM.onInputTextChange(e, 'forwarderCode1', chargeBatchUpdate, 'chargeBatchUpdate')} />
        </div>
        , [chargeBatchUpdate, chargeDetailVM, CHARGEDETAIL_CONSTANT.FORWARDER_CODE])

    const memoReference = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputField
                width='330px'
                maxLength={50}
                label={CHARGEDETAIL_CONSTANT.REFERENCE_NO}
                type="text"
                value={chargeBatchUpdate.reference || ''}
                onChange={(e: any) => chargeDetailVM.onInputTextChange(e, 'reference', chargeBatchUpdate, 'chargeBatchUpdate')} />
        </div>
        , [chargeBatchUpdate, chargeDetailVM, CHARGEDETAIL_CONSTANT.REFERENCE_NO])

    const memoDischarge = useMemo(() =>
        <div className={'im-flex-row'} style={{ marginBottom: '24px' }}>
            <div className='im-flex-row-item'>
                <DateTimePickerComponent
                    label={CHARGEDETAIL_CONSTANT.DISCHARGE_DATE_TIME}
                    width='330px'
                    date={chargeBatchUpdate.dischargeDatetime}
                    onDateChange={chargeDetailVM.onDatePickerChange}
                    fieldName={"dischargeDatetime"} />
            </div>
        </div>
        , [chargeBatchUpdate, chargeDetailVM, CHARGEDETAIL_CONSTANT.DISCHARGE_DATE_TIME])

    const memoGateOut = useMemo(() =>
        <div className={'im-flex-row'} style={{ marginBottom: '24px' }}>
            <div className='im-flex-row-item'>
                <DateTimePickerComponent
                    label={CHARGEDETAIL_CONSTANT.GATE_OUT_DATE_TIME}
                    width='330px'
                    date={chargeBatchUpdate.gateOutDatetime}
                    onDateChange={chargeDetailVM.onDatePickerChange}
                    fieldName={"gateOutDatetime"} />
            </div>
        </div>
        , [chargeBatchUpdate, chargeDetailVM, CHARGEDETAIL_CONSTANT.GATE_OUT_DATE_TIME])

    const memoDelivery = useMemo(() =>
        <div className={'im-flex-row'} style={{ marginBottom: '24px' }}>
            <div className='im-flex-row-item'>
                <DateTimePickerComponent
                    label={CHARGEDETAIL_CONSTANT.DELIVERY_DATE_TIME}
                    width='330px'
                    date={chargeBatchUpdate.deliveryDatetime}
                    onDateChange={chargeDetailVM.onDatePickerChange}
                    fieldName={"deliveryDatetime"} />
            </div>
        </div>
        , [chargeBatchUpdate, chargeDetailVM, CHARGEDETAIL_CONSTANT.DELIVERY_DATE_TIME])

    const memoReceive = useMemo(() =>
        <div className={'im-flex-row'} style={{ marginBottom: '24px' }}>
            <div className='im-flex-row-item'>
                <DateTimePickerComponent
                    label={CHARGEDETAIL_CONSTANT.RECEIVE_DATE_TIME}
                    width='330px'
                    date={chargeBatchUpdate.receiveDatetime}
                    onDateChange={chargeDetailVM.onDatePickerChange}
                    fieldName={"receiveDatetime"} />
            </div>
        </div>
        , [chargeBatchUpdate, chargeDetailVM, CHARGEDETAIL_CONSTANT.RECEIVE_DATE_TIME])

    const memoReceivalCutoff = useMemo(() =>
        <div className={'im-flex-row'} style={{ marginBottom: '24px' }}>
            <div className='im-flex-row-item'>
                <DateTimePickerComponent
                    label={CHARGEDETAIL_CONSTANT.RECEIVAL_CUTOFF_TIME}
                    width='330px'
                    date={chargeBatchUpdate.receivalCutoffDatetime}
                    onDateChange={chargeDetailVM.onDatePickerChange}
                    fieldName={"receivalCutoffDatetime"} />
            </div>
        </div>
        , [chargeBatchUpdate, chargeDetailVM, CHARGEDETAIL_CONSTANT.RECEIVAL_CUTOFF_TIME])

    const memoGateIn = useMemo(() =>
        <div className={'im-flex-row'} style={{ marginBottom: '24px' }}>
            <div className='im-flex-row-item'>
                <DateTimePickerComponent
                    label={CHARGEDETAIL_CONSTANT.GATE_IN}
                    width='330px'
                    date={chargeBatchUpdate.gateInDatetime}
                    onDateChange={chargeDetailVM.onDatePickerChange}
                    fieldName={"gateInDatetime"} />
            </div>
        </div>
        , [chargeBatchUpdate, chargeDetailVM, CHARGEDETAIL_CONSTANT.GATE_IN])

    const memoLoading = useMemo(() =>
        <div className={'im-flex-row'} style={{ marginBottom: '24px' }}>
            <div className='im-flex-row-item'>
                <DateTimePickerComponent
                    label={CHARGEDETAIL_CONSTANT.LOADING_DATE_TIME}
                    width='330px'
                    date={chargeBatchUpdate.loadingDatetime}
                    onDateChange={chargeDetailVM.onDatePickerChange}
                    fieldName={"loadingDatetime"} />
            </div>
        </div>
        , [chargeBatchUpdate, chargeDetailVM, CHARGEDETAIL_CONSTANT.LOADING_DATE_TIME])

    if (!enabledCriteria) return null;

    return <>
        <CriteriaItemContainer>
            {memoCntrTspStatus}
            {memoEmptyInd}
        </CriteriaItemContainer>

        <CriteriaItemContainer>
            {memoImcoCode1}
        </CriteriaItemContainer>

        <CriteriaItemContainer>
            {memoImcoCode2}
        </CriteriaItemContainer>

        <CriteriaItemContainer>
            {memoImcoCode3}
        </CriteriaItemContainer>

        <CriteriaItemContainer>
            {memoCntrType}
        </CriteriaItemContainer>

        <CriteriaItemContainer>
            {memoRfTemp}
            {memoRfTempUnit}
        </CriteriaItemContainer>

        <CriteriaItemContainer>
            {memoOlDimen}
        </CriteriaItemContainer>

        <CriteriaItemContainer>
            {memoOwDimen}
        </CriteriaItemContainer>

        <CriteriaItemContainer>
            {memoOhDimen}
        </CriteriaItemContainer>

        <CriteriaItemContainer>
            {memoGrossWt}
            {memoForwarderCode}
        </CriteriaItemContainer>

        <CriteriaItemContainer>
            {memoReference}
        </CriteriaItemContainer>

        <CriteriaItemContainer>
            {memoDischarge}
        </CriteriaItemContainer>

        <CriteriaItemContainer>
            {memoGateOut}
        </CriteriaItemContainer>

        <CriteriaItemContainer>
            {memoDelivery}
        </CriteriaItemContainer>

        <CriteriaItemContainer>
            {memoReceive}
        </CriteriaItemContainer>

        <CriteriaItemContainer>
            {memoReceivalCutoff}
        </CriteriaItemContainer>

        <CriteriaItemContainer>
            {memoGateIn}
        </CriteriaItemContainer>

        <CriteriaItemContainer>
            {memoLoading}
        </CriteriaItemContainer>

    </>;
}
export default memo(CntrInfoForm);