import { SelectionChangedEvent } from "ag-grid-community";
import { SpecialHandlingIndMappingEntity } from "domain/entity/SpecialHandlingIndMapping/SpecialHandlingIndMappingEntity";
import _ from "lodash";
import { INITIAL_SPECIAL_HANDLING_IND_MAPPING_COL_DEF } from "presentation/constant/SpecialHandlingIndMapping/SpecialHandlingIndMappingColumnDefinition";
import { SpecialHandlingIndMappingConstant } from "presentation/constant/SpecialHandlingIndMapping/SpecialHandlingIndMappingConstant";
import { useSpecialHandlingIndMappingVM } from "presentation/hook/SpecialHandlingIndMapping/useSpecialHandlingIndMappingVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useSpecialHandlingIndMappingTracked } from "presentation/store/SpecialHandlingIndMapping/SpecialHandlingIndMappingProvider";
import RightClickMenuWithMoveAfter from "presentation/view/components/NbisRightClickMenu/RightClickMenuWithMoveAfter";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { createRef, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Loader } from "veronica-ui-component/dist/component/core";
const SpecialHandlingIndMappingTablePanel = () => {
    const gridRef: any = useRef(null);
    const [specialHandlingIndMappingState] = useSpecialHandlingIndMappingTracked();
    const specialHandlingIndMappingVM = useSpecialHandlingIndMappingVM();
    const messageBarVM = useMessageBarVM();

    const [anainfoState] = useANAInfoTracked();
    const { allowUpdate } = anainfoState;
    const rightClickRef: any = createRef();
    // for right click menu with move after begin
    const [showMoveCursor, setShowMoveCursor] = useState<boolean>(false);
    const [allRows, setAllRows] = useState<SpecialHandlingIndMappingEntity[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [initialAllRows, setInitialAllRows] = useState<boolean>(true);

    const getAllRows = useCallback(() => {
        return allRows;
    }, [allRows]);

    const getSelectedRows = useCallback(() => {
        return specialHandlingIndMappingState.selectedRows;
    }, [specialHandlingIndMappingState.selectedRows]);

    useEffect(() => {
        const handleRightClickMenu = (event: any) => {
            if (_.isEmpty(specialHandlingIndMappingState.selectedRows) || !allowUpdate) {
                return;
            }
            event.preventDefault();
            rightClickRef.current.show(event);
        };
        document.getElementById('myDiv')?.addEventListener("contextmenu", handleRightClickMenu);

        return () => {
            document.getElementById('myDiv')?.removeEventListener("contextmenu", handleRightClickMenu);
        };
    }, [messageBarVM, rightClickRef, allowUpdate, specialHandlingIndMappingState.selectedRows]);

    // for right click menu with move after begin
    const onRefreshRow = useCallback((newAllRows: any[]) => {
        setAllRows(newAllRows);
        specialHandlingIndMappingVM.updateSelectedRows([]);
        setShowMoveCursor(true);
    }, [specialHandlingIndMappingVM]);

    const onMoveCancel = useCallback(() => {
        setInitialAllRows(true);
        setAllRows(specialHandlingIndMappingState.specialHandlingIndMappingList);
        specialHandlingIndMappingVM.updateSelectedRows([]);
        setShowMoveCursor(false);
    }, [specialHandlingIndMappingState.specialHandlingIndMappingList, specialHandlingIndMappingVM]);

    const onSaveMove = useCallback(async (newAllRows: any[]) => {
        if (_.isEmpty(newAllRows)) return;

        setIsLoading(true);

        specialHandlingIndMappingVM.onApply(newAllRows).then((res) => {
            if (!res || !res.success) {
                messageBarVM.showError('Move failed.');
                setInitialAllRows(true);
                setIsLoading(false);

                specialHandlingIndMappingVM.updateSelectedRows([]);
                setShowMoveCursor(false);
            } else {
                specialHandlingIndMappingVM.searchAllspecialHandlingIndMappingList().then((data) => {
                    setInitialAllRows(true);
                    setIsLoading(false);

                    specialHandlingIndMappingVM.updateSelectedRows([]);
                    setShowMoveCursor(false);
                }).catch((e) => {
                    setInitialAllRows(true);
                    setIsLoading(false);

                    specialHandlingIndMappingVM.updateSelectedRows([]);
                    setShowMoveCursor(false);
                })
            }
        })
    }, [messageBarVM, specialHandlingIndMappingVM]);

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();
        specialHandlingIndMappingVM.updateSelectedRows(selectedRows);
    }, [specialHandlingIndMappingVM])

    // const handleRowDrag = useCallback((e: any, updatedRows: SpecialHandlingIndMappingEntity[], movedIndex: number, overIndex: number)=>{
    //     specialHandlingIndMappingVM.onRowDrag(updatedRows);
    // },[specialHandlingIndMappingVM])


    const handleRowDoubleClick = useCallback((entity: SpecialHandlingIndMappingEntity) => {
        // if(updatedRows.length > 0){
        //     messageBarVM.showError("Modified data has not been saved.Please save or refersh the data first."); 
        //     return;
        // }
        specialHandlingIndMappingVM.onRowDoubleClick(entity);
    }, [specialHandlingIndMappingVM])

    const handleAdd = useCallback(() => {
        specialHandlingIndMappingVM.onAddClick();
    }, [specialHandlingIndMappingVM]);

    const memoSpecialHandlingIndMappingTable = useMemo(() => {

        return (
            <>
                {(allowUpdate) && <RightClickMenuWithMoveAfter disabled={_.isEmpty(getSelectedRows())}
                    rightClickRef={rightClickRef} selectedRows={getSelectedRows()} allRows={getAllRows()}
                    onSaveMove={onSaveMove} onCancel={onMoveCancel} onRefreshRow={onRefreshRow}
                    showConfirmMove={showMoveCursor} priorityName="priority" />}
                <div id="myDiv">
                    <NbisTable
                        id='special-Handling-ind-mapping-table'
                        isNewColumnSetting={true}
                        headerLabel={SpecialHandlingIndMappingConstant.Title.TITLE}
                        columns={INITIAL_SPECIAL_HANDLING_IND_MAPPING_COL_DEF}
                        data={allRows ?? []}
                        showPaginator={false}
                        editable={false}
                        showAddIcon={true}
                        showDeleteButton={false}
                        showReloadIcon={false}
                        isScrollHighlighted={true}
                        selectionMode={true}
                        showCutCursor={showMoveCursor}
                        isRowHighligted={true}
                        onSelectionChanged={handleSelectionChange}
                        onRowDoubleClick={(e: any, entity: SpecialHandlingIndMappingEntity) => handleRowDoubleClick(entity)}
                        gridHeight="customHeight"
                        customHeight="calc(100vh - 75px)"
                        ref={gridRef}
                        rowSelection={showMoveCursor ? "single" : "multiple"}
                        onAddClick={handleAdd}
                    />
                </div>
            </>
        );
    }, [allowUpdate, getSelectedRows, rightClickRef, getAllRows, onSaveMove, onMoveCancel, onRefreshRow, showMoveCursor, allRows, handleSelectionChange, handleRowDoubleClick, handleAdd])

    useEffect(() => {
        if (initialAllRows && specialHandlingIndMappingState.specialHandlingIndMappingList && !_.isEmpty(specialHandlingIndMappingState.specialHandlingIndMappingList)) {
            setAllRows(specialHandlingIndMappingState.specialHandlingIndMappingList.map((equipmentCodeIndMappingEntity, index) => ({
                ...equipmentCodeIndMappingEntity,
                index: index || 0,
                flag_showCutCursor: false
            })));
            setInitialAllRows(false);
        }
    }, [allRows, initialAllRows, specialHandlingIndMappingState.specialHandlingIndMappingList]);

    return <><TableWrapper>
        {(isLoading) && <Loader Indicator="Spinner" size="Medium" />}
        {memoSpecialHandlingIndMappingTable}
        {specialHandlingIndMappingState.forceRefresh && <></>}
    </TableWrapper>
    </>;
}

export default memo(SpecialHandlingIndMappingTablePanel);
