import { SaveTariffProposalItemCriteria } from "domain/entity/StandardProposal/SaveTariffProposalItemCriteria";
import { EMPTY_STANDARD_PROPOSAL_ENTITY, StandardProposalEntity } from "domain/entity/StandardProposal/StandardProposalEntity";
import { EMPTY_STANDARD_PROPOSAL_ITEM_ENTITY, StandardProposalItemEntity } from "domain/entity/StandardProposal/StandardProposalItemEntity";
import { EMPTY_STD_PROPOSAL_IS_ENTITY, StdProposalIsEntity } from "domain/entity/StandardProposal/StdProposalIsEntity";
import { EMPTY_STD_PROPOSAL_TARIFF_ITEM_TIER_ENTITY, StdProposalTariffItemTierEntity } from "domain/entity/StandardProposal/StdProposalTariffItemTierEntity";
import { TariffCodeComboxSearchCriteria } from "domain/entity/StandardProposal/TariffCodeComboxSearchCriteria";
import { ExchangeRateRepository } from "domain/repository/ExchangeRate/ExchangeRateRepo";
import { StandardProposalItemRepository } from "domain/repository/StandardProposal/StandardProposalItemRepo";
import { StandardProposalRepository } from "domain/repository/StandardProposal/StandardProposalRepo";
import { StandardTariffCodeRepository } from "domain/repository/TariffCode/StandardTariffCodeRepo";
import { TariffNatureRepository } from "domain/repository/TariffNature/TariffNatureRepo";
import _ from "lodash";
import { DropdownProps } from "presentation/model/DropdownProps";
import { StandardProposalDetailModel } from "presentation/model/StandardProposal/StandardProposalDetailModel";
import { StandardProposalDropdownOptions, StandardProposalModel } from "presentation/model/StandardProposal/StandardProposalModel";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";

interface StandardProposalDetailVMProps extends BaseViewModel {
    itemDispatch: [
        Dispatch<SetStateAction<StandardProposalDetailModel>> | ((value: SetStateAction<StandardProposalDetailModel>) => void),
    ],
    dispatch: [
        Dispatch<SetStateAction<StandardProposalModel>> | ((value: SetStateAction<StandardProposalModel>) => void),
    ],
    standardProposalItemRepo: StandardProposalItemRepository,
    exchangeRateRepo: ExchangeRateRepository,
    tariffNatureRepo: TariffNatureRepository,
    standardTariffCodeRepo: StandardTariffCodeRepository,
    standardProposalRepo: StandardProposalRepository,
}

export const StandardProposalDetailVM = ({ itemDispatch, dispatch, standardProposalItemRepo, standardTariffCodeRepo, standardProposalRepo }: StandardProposalDetailVMProps) => {
    const [standardProposalDetailDispatch] = itemDispatch;
    const [standardProposalDispatch] = dispatch;


    const onPageInit = (dynamicOption: StandardProposalDropdownOptions) => {
        standardProposalDetailDispatch(prevState => {
            return {
                ...prevState,
                currentStandardProposal: { ...EMPTY_STANDARD_PROPOSAL_ENTITY },
                standardProposalItemList: [],
                dynamicOptions: {
                    ...prevState.dynamicOptions,
                    companyCodeDropdownOptions: dynamicOption.companyCodeDropdownOptions,
                    operatingTmlDropdownOptions: dynamicOption.operatingTmlDropdownOptions,
                    tariffTypeDropdownOptions: dynamicOption.tariffTypeDropdownOptions,
                    tariffNatureDropdownOptions: dynamicOption.tariffNatureDropdownOptions,
                    shiftCodeDropdownOptions: dynamicOption.shiftCodeDropdownOptions,
                    currencyCodeDropdownOptions: dynamicOption.currencyCodeDropdownOptions,
                    forwarderCodeDropdownOptions: dynamicOption.forwarderCodeDropdownOptions,
                },
                detailState: {
                    ...prevState.detailState,
                    isAdd: true,
                }
            }
        })
    }

    const onHeaderSingleDropdownChange = (e: any, fieldName: string) => {
        standardProposalDetailDispatch(prevState => {
            return {
                ...prevState,
                currentStandardProposal: {
                    ...prevState.currentStandardProposal,
                    [fieldName]: e.value,
                }
            }
        });
    };

    const onHeaderMultipleDropdownChange = (e: any, fieldName: string) => {
        standardProposalDetailDispatch(prevState => ({
            ...prevState,
            currentStandardProposal: {
                ...prevState.currentStandardProposal,
                [fieldName]: _.uniq(e?.map((item: DropdownProps) => item.value)),
            }
        }));
    };

    const onHeaderFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        standardProposalDetailDispatch(prevState => {
            return {
                ...prevState,
                currentStandardProposal: {
                    ...prevState.currentStandardProposal,
                    [fieldKey]: val
                },
            }
        })
    }

    const onDateRangeChange = (startDate: any, endDate: any, dateFields: { startField: string, endField: string }) => {
        standardProposalDetailDispatch(prevState => ({
            ...prevState,
            currentStandardProposal: {
                ...prevState.currentStandardProposal,
                [dateFields.startField]: startDate,
                [dateFields.endField]: endDate,
            }
        }))
    }

    const onTextAreaChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        return standardProposalDetailDispatch(prevState => {
            return {
                ...prevState,
                currentStandardProposal: {
                    ...prevState.currentStandardProposal,
                    [fieldKey]: val,
                }
            }
        });
    }

    const searchStandardProposalItem = async (entity: StandardProposalEntity) => {
        if (!entity.key) {
            return;
        }
        await standardProposalItemRepo.searchStandardProposalItem(entity).then((data) => {
            standardProposalDetailDispatch(prevState => {
                return {
                    ...prevState,
                    standardProposalItemList: data,
                    selectedRows: [],
                }
            })
        }).catch((error) => {
            return [];
        })
    }

    const updateSelectedRows = async (rows: any[]) => {
        standardProposalDetailDispatch(prevState => {
            return {
                ...prevState,
                currentSelectItemRows: rows,
                currentSelectItem: rows.length === 1 ? rows[0] : { ...EMPTY_STANDARD_PROPOSAL_ITEM_ENTITY },
            }
        })
    }

    const onResetClick = async () => {
        standardProposalDetailDispatch(prevState => {
            return {
                ...prevState,
                standardProposalItemList: [],
                currentStandardProposal: { ...EMPTY_STANDARD_PROPOSAL_ENTITY },
            }
        })
    }

    const onCloseClick = () => {
        standardProposalDispatch(prevState => {
            return {
                ...prevState,
                isShowDetail: false,
                isBackFromDetail: true,
                isAllowAutoSearch: true,
                selectedRows: [],
                currentSelectedRow: { ...EMPTY_STANDARD_PROPOSAL_ENTITY },
            }
        });
    }

    const onAddTariffItemClick = () => {
        standardProposalDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowAddTariffItemPanel: true,
                isAddTariffItem: true,
                currentSelectItem: { ...EMPTY_STANDARD_PROPOSAL_ITEM_ENTITY },
            }
        });
    }

    const onTariffItemFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        standardProposalDetailDispatch(prevState => {
            return {
                ...prevState,
                initTariffCode: "tariffType" === fieldKey && val !== prevState.currentSelectItem.tariffType,
                currentSelectItem: {
                    ...prevState.currentSelectItem,
                    [fieldKey]: val,
                },
            }
        })
    }

    const onAddIs = () => {
        return standardProposalDetailDispatch(prevState => {
            return {
                ...prevState,
                isAddIs: true,
                isAddTier: false,
                currentTariffItemIs: { ...EMPTY_STD_PROPOSAL_IS_ENTITY }
            }
        });
    }

    const onAddTier = () => {
        return standardProposalDetailDispatch(prevState => {
            return {
                ...prevState,
                isAddIs: false,
                isAddTier: true,
                currentTariffItemTier: { ...EMPTY_STD_PROPOSAL_TARIFF_ITEM_TIER_ENTITY }
            }
        });
    }

    const onTariffItemTierCloseClick = () => {
        standardProposalDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowAddTariffItemPanel: false,
                isAddIs: false,
                isAddTier: false,
                currentTariffItem: { ...EMPTY_STANDARD_PROPOSAL_ITEM_ENTITY },
            }
        })
    }


    const onTariffItemTierEidtClick = () => {
        standardProposalDetailDispatch(prevState => {
            return {
                ...prevState,
                isEditTier: true,
                isEditIs: false,
                isAddIs: false,
                isAddTier: false,
                currentTariffItemTier: prevState.currentEditTariffItemTier,
            }
        })
    }

    const onTariffItemISEidtClick = () => {
        standardProposalDetailDispatch(prevState => {
            return {
                ...prevState,
                isEditIs: true,
                isEditTier: false,
                isAddIs: false,
                isAddTier: false,
                currentTariffItemIs: prevState.currentEditTariffItemIs,
            }
        })
    }
    const updateSelectedTariffItemTierRows = async (rows: any[]) => {
        standardProposalDetailDispatch(prevState => {
            return {
                ...prevState,
                tariffItemTierSelectedRows: rows,
                currentEditTariffItemTier: rows.length === 1 ? rows[0] : { ...EMPTY_STD_PROPOSAL_TARIFF_ITEM_TIER_ENTITY },
            }
        })
    }

    const updateSelectedTariffItemIsRows = async (rows: any[]) => {
        standardProposalDetailDispatch(prevState => {
            return {
                ...prevState,
                tariffItemIsSelectedRows: rows,
                currentEditTariffItemIs: rows.length === 1 ? rows[0] : { ...EMPTY_STD_PROPOSAL_IS_ENTITY },
            }
        })
    }

    const onTierFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        standardProposalDetailDispatch(prevState => {
            if (prevState.currentTariffItemTier && (fieldKey === 'cycle' || fieldKey === 'forevery')) {
                var posIntReg = /^\d*$/;
                if (!posIntReg.test(val)) {
                    val = prevState.currentTariffItemTier[fieldKey];
                }
            }
            if (prevState.currentTariffItemTier && (fieldKey === 'rate' || fieldKey === 'minimum' || fieldKey === 'surcharge' || fieldKey === 'additionalRate')) {
                var numberReg = /^\d*(\.\d{0,2})?$/;
                if (!numberReg.test(val)) {
                    val = prevState.currentTariffItemTier[fieldKey];
                }
            }
            return {
                ...prevState,
                currentTariffItemTier: {
                    ...prevState.currentTariffItemTier,
                    [fieldKey]: val
                },
            }
        })
    }

    const onIsFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        standardProposalDetailDispatch(prevState => {
            return {
                ...prevState,
                currentTariffItemIs: {
                    ...prevState.currentTariffItemIs,
                    [fieldKey]: val
                },
            }
        })
    }

    const cancelAddOrEdit = () => {
        return standardProposalDetailDispatch(prevState => {
            return {
                ...prevState,
                isAddIs: false,
                isAddTier: false,
                isEditIs: false,
                isEditTier: false,
                currentEditTariffItemTier: prevState.currentEditTariffItemTier,
                currentEditTariffItemIs: prevState.currentEditTariffItemIs,
            }
        });
    }

    const onSaveClick = (isAddTariffItem: boolean, isAddIs: boolean, isAddTier: boolean) => {
        if (isAddTariffItem) {
            if (isAddIs) {
                return standardProposalDetailDispatch(prevState => {
                    let isList = prevState.currentSelectItem.stdIsList;
                    isList?.push(prevState.currentTariffItemIs);
                    let itemList = prevState.standardProposalItemList;
                    itemList?.push(prevState.currentSelectItem);
                    return {
                        ...prevState,
                        currentSelectItem: {
                            ...prevState.currentSelectItem,
                            stdIsList: isList
                        },
                        isAddIs: false,
                        standardProposalItemList: itemList,
                        currentTariffItemIs: { ...EMPTY_STD_PROPOSAL_IS_ENTITY }
                    }
                });
            }
            if (isAddTier) {
                return standardProposalDetailDispatch(prevState => {
                    let tierList = prevState.currentSelectItem.tierList;
                    tierList?.push(prevState.currentTariffItemTier);
                    let itemList = prevState.standardProposalItemList;
                    itemList?.push(prevState.currentSelectItem);
                    return {
                        ...prevState,
                        currentSelectItem: {
                            ...prevState.currentSelectItem,
                            tierList: tierList
                        },
                        standardProposalItemList: itemList,
                        isAddTier: false,
                        currentTariffItemTier: { ...EMPTY_STD_PROPOSAL_TARIFF_ITEM_TIER_ENTITY }
                    }
                });
            }
            return standardProposalDetailDispatch(prevState => {
                let itemList = prevState.standardProposalItemList;
                itemList?.push(prevState.currentSelectItem);
                return {
                    ...prevState,
                    standardProposalItemList: itemList,
                    isAddTariffItem: false,
                    isShowAddTariffItemPanel: false,
                    currentTariffItemTier: { ...EMPTY_STD_PROPOSAL_TARIFF_ITEM_TIER_ENTITY }
                }
            });
        }
    }

    const initComboBoxTariffCode = async (entity: StandardProposalEntity, tariffType: string) => {
        standardProposalDetailDispatch(prevState => ({
            ...prevState,
            currentSelectItem: {
                ...prevState.currentSelectItem,
                tariffCode: prevState.isAddTariffItem ? "" : prevState.currentSelectItem.tariffCode,
            },
            initTariffCode: false,
            dynamicOptions: {
                ...prevState.dynamicOptions,
                tariffCodeDropdownOptions: [],
            }
        }))
        const criteria: TariffCodeComboxSearchCriteria = {
            stdProposal: entity,
            tariffType: tariffType ?? "all",
        }
        const tariffCodes = await standardTariffCodeRepo.getTariffCodeForStdProposal(criteria);
        let tariffCodeDropdownOptions = tariffCodes?.map((tariffCode) => ({
            dropdownLabel: tariffCode.split(':')[1],
            tagLabel: tariffCode.split(':')[1],
            value: tariffCode.split(':')[1],
        })) ?? []
        standardProposalDetailDispatch(prevState => ({
            ...prevState,
            tariffCodeIds: tariffCodes,
            dynamicOptions: {
                ...prevState.dynamicOptions,
                tariffCodeDropdownOptions: tariffCodeDropdownOptions,
            }
        }))
    }

    const onTempSaveTariffItemTier = async (isAddTier: boolean,
        currentItem: StandardProposalItemEntity, currentTier: StdProposalTariffItemTierEntity) => {
        let newTiers: StdProposalTariffItemTierEntity[] = [];
        if (currentItem.tierList) {
            newTiers = currentItem.tierList.slice();
            if (isAddTier) {
                currentTier = {
                    ...currentTier,
                    tierNo: newTiers.length + 1,
                    newRecord: true,
                }
                newTiers.push(currentTier);
            } else {
                newTiers = newTiers.map(entity => entity.tierNo === currentTier.tierNo ? currentTier : entity);
            }
        } else {
            currentTier = {
                ...currentTier,
                tierNo: newTiers.length + 1,
                newRecord: true,
            }
            newTiers.push(currentTier);
        }
        standardProposalDetailDispatch(prevState => {
            return {
                ...prevState,
                isEditTier: false,
                isAddTier: false,
                currentEditTariffItemTier: currentTier,
                currentTariffItemTier: { ...EMPTY_STD_PROPOSAL_TARIFF_ITEM_TIER_ENTITY },
                currentSelectItem: {
                    ...prevState.currentSelectItem,
                    tierList: newTiers,
                }

            }
        })
    }

    const onTempSaveTariffItemIs = async (isAddIs: boolean,
        currentItem: StandardProposalItemEntity, currentIs: StdProposalIsEntity
    ) => {
        let newIsList: StdProposalIsEntity[] = [];
        if (currentItem.stdIsList) {
            newIsList = currentItem.stdIsList.slice();
            if (isAddIs) {
                newIsList.push(currentIs);
            } else {
                newIsList = newIsList.map(entity => entity.shiftCode === currentIs.shiftCode ? currentIs : entity);
            }
        } else {
            newIsList.push(currentIs);
        }
        standardProposalDetailDispatch(prevState => {
            return {
                ...prevState,
                isEditIs: false,
                isAddIs: false,
                currentEditTariffItemIs: currentIs,
                currentTariffItemIs: { ...EMPTY_STD_PROPOSAL_TARIFF_ITEM_TIER_ENTITY },
                currentSelectItem: {
                    ...prevState.currentSelectItem,
                    stdIsList: newIsList,
                }

            }
        })
    }

    const onTempSaveTariffItem = async (proposalItemList: StandardProposalItemEntity[], proposalItem: StandardProposalItemEntity) => {
        let newItemList: StandardProposalItemEntity[] = [];
        newItemList = proposalItemList.slice();
        newItemList.push(proposalItem);

        standardProposalDetailDispatch(prevState => {
            return {
                ...prevState,
                standardProposalItemList: newItemList,
                isEditIs: false,
                isAddIs: false,
                isAddTariffItem: false,
                isShowAddTariffItemPanel: false,
                currentTariffItemIs: { ...EMPTY_STD_PROPOSAL_IS_ENTITY },
                currentTariffItemTier: { ...EMPTY_STD_PROPOSAL_TARIFF_ITEM_TIER_ENTITY },
                currentSelectItem: { ...EMPTY_STANDARD_PROPOSAL_ITEM_ENTITY },

            }
        })
    }

    const onSaveStdProposal = async (tariffCodeIds: string[], stdProposal: StandardProposalEntity, itemList: StandardProposalItemEntity[]) => {
        let criteria: SaveTariffProposalItemCriteria = {
            tariffProposal: stdProposal,
            tariffCodeIds: tariffCodeIds,
            itemList: itemList,
            deleteItemList: [],
        }
        return await standardProposalItemRepo.saveNewStandardProposal(criteria);
    }

    const onTariffItemDeleteClick = async (deleteItems: StandardProposalItemEntity[]) => {
        standardProposalDetailDispatch(prevState => {
            let itemList = prevState.standardProposalItemList ?? [].slice();
            const newList = itemList.filter(item =>
                !deleteItems.some(delItem => item.tariffType === delItem.tariffType && item.tariffCode === delItem.tariffCode)
            );
            return {
                ...prevState,
                standardProposalItemList: newList,
            }
        })
    }

    const onTariffItemIsDeleteClick = async (deleteIs: StdProposalIsEntity[]) => {
        standardProposalDetailDispatch(prevState => {
            let isList = prevState.currentSelectItem.stdIsList ?? [].slice();
            const newList = isList.filter(is =>
                !deleteIs.some(delIs => is.shiftCode === delIs.shiftCode)
            );
            return {
                ...prevState,
                currentSelectItem: {
                    ...prevState.currentSelectItem,
                    stdIsList: newList,
                },
            }
        })
    }

    const onTariffItemTierDeleteClick = async (deleteTiers: StdProposalTariffItemTierEntity[]) => {
        standardProposalDetailDispatch(prevState => {
            let tierList = prevState.currentSelectItem.tierList ?? [].slice();
            const newList = tierList.filter(tier =>
                !deleteTiers.some(delTier => tier.tierNo === delTier.tierNo)
            ).map((tier, i) => ({ ...tier, tierNo: i + 1 }));
            return {
                ...prevState,
                currentSelectItem: {
                    ...prevState.currentSelectItem,
                    tierList: newList,
                },

            }
        })

    }

    return {
        onTariffItemTierDeleteClick: onTariffItemTierDeleteClick,
        onTariffItemIsDeleteClick: onTariffItemIsDeleteClick,
        onTariffItemDeleteClick: onTariffItemDeleteClick,
        onSaveStdProposal: onSaveStdProposal,
        cancelAddOrEdit: cancelAddOrEdit,
        onIsFieldChange: onIsFieldChange,
        onTierFieldChange: onTierFieldChange,
        updateSelectedTariffItemIsRows: updateSelectedTariffItemIsRows,
        updateSelectedTariffItemTierRows: updateSelectedTariffItemTierRows,
        onTariffItemISEidtClick: onTariffItemISEidtClick,
        onTariffItemTierEidtClick: onTariffItemTierEidtClick,
        onTariffItemTierCloseClick: onTariffItemTierCloseClick,
        onPageInit: onPageInit,
        // loadDropdownOption: loadDropdownOption,
        onHeaderFieldChange: onHeaderFieldChange,
        onDateRangeChange: onDateRangeChange,
        onTextAreaChange: onTextAreaChange,
        searchStandardProposalItem: searchStandardProposalItem,
        updateSelectedRows: updateSelectedRows,
        onCloseClick: onCloseClick,
        onResetClick: onResetClick,
        onHeaderMultipleDropdownChange: onHeaderMultipleDropdownChange,
        onHeaderSingleDropdownChange: onHeaderSingleDropdownChange,
        onAddTariffItemClick: onAddTariffItemClick,
        onTariffItemFieldChange: onTariffItemFieldChange,
        onAddIs: onAddIs,
        onAddTier: onAddTier,
        onSaveClick: onSaveClick,
        initComboBoxTariffCode: initComboBoxTariffCode,
        onTempSaveTariffItemIs: onTempSaveTariffItemIs,
        onTempSaveTariffItemTier: onTempSaveTariffItemTier,
        onTempSaveTariffItem: onTempSaveTariffItem,

    }
}