import { ActiveInd } from "constants/ActiveInd";
import { AdjustmentTypePolicy } from "constants/charge/AdjustmentTypePolicy";
import { ChargeConstantCollection } from "constants/charge/ChargeConstantCollection";
import { ChargeDetailStatePolicy } from "constants/charge/ChargeDetailStatePolicy";
import { useChargeDetailVM } from "presentation/hook/ChargeDetailMaintenance/useChargeDetailVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { ChargeDetailModel } from "presentation/model/ChargeDetailMaintenance/ChargeDetailModel";
import NbisInputDropDown from "presentation/view/components/NbisInputDropDown";
import { memo, useEffect, useState } from "react";
import { InputField, Loader } from "veronica-ui-component/dist/component/core";

const BundleMasterNumberAccordion = ({ chargeDetailState }: { chargeDetailState: ChargeDetailModel }) => {
    const chargeBatchUpdate = chargeDetailState.chargeBatchUpdate
    const messageBarVM = useMessageBarVM();
    const chargeDetailVM = useChargeDetailVM();
    const [disabled, setDisabled] = useState<boolean>(false);
    const [disableVisit, setDisableVisit] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!!!chargeDetailState.selectedChargeDetails) return;

        let tempDisabled = false;
        chargeDetailState?.selectedChargeDetails.map((bean) => {
            if (AdjustmentTypePolicy.ADJ_TYPE_NORMAL !== bean.adjType ||
                ChargeDetailStatePolicy.STATE_C === bean.dtlState ||
                ChargeDetailStatePolicy.STATE_I === bean.dtlState ||
                ChargeDetailStatePolicy.STATE_MT === bean.dtlState ||
                ChargeDetailStatePolicy.STATE_T === bean.dtlState ||
                ChargeDetailStatePolicy.STATE_DI === bean.dtlState ||
                ChargeConstantCollection.bundleInd.M === bean.bundleInd ||
                ActiveInd.Y !== bean.emptyIndicator) {
                tempDisabled = true;
            }
            return bean;
        })
        setDisabled(tempDisabled);
        setDisableVisit(tempDisabled);
    }, [chargeDetailState.selectedChargeDetails]);

    const initVisitDropdown = async () => {
        if (!!!chargeBatchUpdate.bundleMasterNumber) return;

        setIsLoading(true);
        chargeDetailVM.searchMasterBundleVisit(chargeDetailState.selectedChargeDetails, chargeBatchUpdate.bundleMasterNumber ?? "").then((data) => {
            if (data && data["searchMasterBundleFail"]) {
                const err: string = data["searchMasterBundleFail"]?.toString() ?? "";
                messageBarVM.showError(err.replace("Error:", ""));

            }
        }).finally(() => {
            setIsLoading(false);
        })
    }

    return (
        <div className='empty-form' style={{ height: "180px" }}>
            <div className={'im-flex-row'} style={{ marginBottom: '24px', columnGap: "5px", alignItems: "flex-end !important" }}>

                <div className='im-flex-row-item'>
                    <InputField
                        label='Bundle Master No.'
                        width='330px'
                        type="text"
                        disabled={disabled}
                        maxLength={50}
                        value={chargeBatchUpdate.bundleMasterNumber || ''}
                        onChange={(e: any) => {
                            chargeDetailVM.onInputTextChange(e, 'bundleMasterNumber', chargeBatchUpdate, 'chargeBatchUpdate', true);
                        }}
                        errorMessage={chargeBatchUpdate.allFormState?.bundleMasterNumber}
                    // onBlur={initVisitDropdown}
                    />
                </div>
                <div className={"password-eye search"} aria-disabled={!!!chargeBatchUpdate.bundleMasterNumber} onClick={initVisitDropdown}></div>
            </div>
            <div className={'im-flex-row'} style={{ marginBottom: '24px' }}>

                <div className='im-flex-row-item'>
                    <NbisInputDropDown
                        label='Container Visit'
                        width='330px'
                        inputType="freeText"
                        mode={"single"}
                        disabled={disableVisit}
                        value={chargeBatchUpdate.bundleMasterVisit ?? chargeDetailState?.dynamicOptions?.visitNumberOptions[0]?.value}
                        onChange={(e: any) => chargeDetailVM.onDropdownChange(e, 'bundleMasterVisit', chargeBatchUpdate, 'chargeBatchUpdate')}
                        options={chargeDetailState.dynamicOptions.visitNumberOptions}
                        errorMessage={chargeBatchUpdate.allFormState?.bundleMasterVisit}
                    // helpIcon={true}
                    // toolTipText={"For container with multiple visits"}
                    // onFocus={initVisitDropdown}
                    />
                </div>

            </div>

            {isLoading && <Loader Indicator="Spinner" size="Medium" />}
        </div>
    )
}

export default memo(BundleMasterNumberAccordion);