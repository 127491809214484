import { ContainerStatusPolicy } from "constants/charge/ContainerStatusPolicy";
import { VesselChargeSubChargeTypePolicy } from "constants/charge/VesselChargeSubChargeTypePolicy";
import _ from "lodash";
import { ChargeDetailConstant } from "presentation/constant/ChargeDetailMaintenance/ChargeDetailConstant";
import { ChargeConstant } from "presentation/constant/ChargeHeader/ChargeConstant";
import { MessageConstant } from "presentation/constant/MessageConstant";
import { useChargeVesselVM } from "presentation/hook/ChargeDetailMaintenance/useChargeVesselVM copy";
import { useChargeDetailTracked } from "presentation/store/ChargeDetailMaintenance/ChargeDetailProvider";
import NbisInputDropDown from "presentation/view/components/NbisInputDropDown";
import { useState } from "react";
import { HPHGroupRadioButton, HPHInputMask } from "veronica-ui-component/dist/component/core";
import { isMiUptStatusEditable } from "../../BulkEditAssist";
import { CHARGE_TYPE_INBOUND, CHARGE_TYPE_OLD_NEW_DIFF_RESH, CHARGE_TYPE_OUTBOUND, CHARGE_TYPE_TS_INBOUND, CHARGE_TYPE_TS_OUTBOUND, getChangeActionType } from "../../ChargeDetailAssist";

export const ChangeActionOption = ({
    CREATE_DUMMY_VOY_CNTR: "Create Dummy Voy and Cntr as TS 1st Leg",
    CREATE_DUMMY_CNTR: "Create Dummy Voy and Cntr as TS 1st Leg",
    MAP_EXISTING_TS_LEG: "Map Existing TS Leg",
    CHANGE_CNTR_STATUS_ONLY: "Change Cntr Status Only",
});

export const CntrStatusAccordion = () => {
    const [chargeDetailState] = useChargeDetailTracked();
    const chargeBatchUpdate = chargeDetailState.chargeBatchUpdate;
    const chargeVesselVM = useChargeVesselVM();
    const statusDropdownOptions = isMiUptStatusEditable(chargeDetailState.selectedChargeDetails)?.map((status) => ({
        dropdownLabel: status, tagLabel: status, value: status
    }));
    const [showChangeAction, setShowChangeAction] = useState<boolean>(false);
    const [updateStatusType, setUpdateStatusType] = useState<string>("");
    const [, setSubChargeType] = useState<string>("");
    const [showSelectVoy, setShowSelectVoy] = useState<boolean>(false);
    const [allowInputVsl, setAllowInputVsl] = useState<boolean>(false);


    const validateCntrStatus = () => {
        let subChargeType: string[] = [];
        chargeDetailState.selectedChargeDetails?.forEach(function (charge) {
            if (charge.subChargeType && !subChargeType.includes(charge.subChargeType)) {
                subChargeType.push(charge.subChargeType);
            }
        })
        if (subChargeType && subChargeType.length > 1) {
            return MessageConstant.charge.DIFFERENCE_SUB_CHARGE_TYPES;
        }
    }

    const calcShowSelectVsl = (changeActionOption: string) => {
        chargeVesselVM.onFieldChange({ 'changeActionOption': changeActionOption }, chargeBatchUpdate, "chargeBatchUpdate");
        if (CHARGE_TYPE_TS_OUTBOUND === updateStatusType) {
            setShowSelectVoy(true);
            if (changeActionOption !== ChangeActionOption.CHANGE_CNTR_STATUS_ONLY) {
                setAllowInputVsl(true);
            } else {
                setAllowInputVsl(false);
            }
        } else if (CHARGE_TYPE_TS_INBOUND === updateStatusType) {
            setShowSelectVoy(true);
            if (changeActionOption !== ChangeActionOption.CHANGE_CNTR_STATUS_ONLY) {
                setAllowInputVsl(true);
            } else {
                setAllowInputVsl(false);
            }
        }
    };


    const calcShowChangeAction = (newStatus: string) => {
        if (newStatus) {
            const currentUpdateStatusType = getChangeActionType(newStatus ?? "",
                chargeDetailState.selectedChargeDetails) ?? "";

            let subChargeType = chargeDetailState.selectedChargeDetails[0]?.subChargeType;
            let chargeType = chargeDetailState.selectedChargeDetails[0]?.chargeType;
            if (CHARGE_TYPE_OLD_NEW_DIFF_RESH === currentUpdateStatusType) {
                //If sub charge type = CY / YH, newStatus = RE / RM / SH / SM, new sub charge 
                //type = RESH.
                if ((VesselChargeSubChargeTypePolicy.CODE_CY === subChargeType
                    || VesselChargeSubChargeTypePolicy.CODE_YH === subChargeType)
                    && (ContainerStatusPolicy().CNTR_STATUS_RE === newStatus
                        || ContainerStatusPolicy().CNTR_STATUS_RM === newStatus
                        || ContainerStatusPolicy().CNTR_STATUS_SH === newStatus
                        || ContainerStatusPolicy().CNTR_STATUS_SM === newStatus)) {
                    subChargeType = VesselChargeSubChargeTypePolicy.CODE_RESH;
                }

                //If sub charge type = CY-IS / YH-IS, newStatus = RE / RM / SH / SM, new sub  
                //charge type = RESH-IS.
                if ((VesselChargeSubChargeTypePolicy.CODE_CY_IS === subChargeType
                    || VesselChargeSubChargeTypePolicy.CODE_YH_IS === subChargeType)
                    && (ContainerStatusPolicy().CNTR_STATUS_RE === newStatus
                        || ContainerStatusPolicy().CNTR_STATUS_RM === newStatus
                        || ContainerStatusPolicy().CNTR_STATUS_SH === newStatus
                        || ContainerStatusPolicy().CNTR_STATUS_SM === newStatus)) {
                    subChargeType = VesselChargeSubChargeTypePolicy.CODE_RESH_IS;
                }

                //If sub charge type = RESH, newStatus <> RE / RM / SH / SM, new sub charge 
                //type = charge type (CY or YH).
                if ((VesselChargeSubChargeTypePolicy.CODE_RESH === subChargeType)
                    && !(ContainerStatusPolicy().CNTR_STATUS_RE === newStatus
                        || ContainerStatusPolicy().CNTR_STATUS_RM === newStatus
                        || ContainerStatusPolicy().CNTR_STATUS_SH === newStatus
                        || ContainerStatusPolicy().CNTR_STATUS_SM === newStatus)) {
                    subChargeType = chargeType ?? "";
                }
                //If sub charge type = RESH-IS, newStatus <> RE / RM / SH / SM, new sub charge 
                //type = charge type+"IS" (CY-IS or YH-IS).
                if ((VesselChargeSubChargeTypePolicy.CODE_RESH_IS === subChargeType)
                    && !(ContainerStatusPolicy().CNTR_STATUS_RE === newStatus
                        || ContainerStatusPolicy().CNTR_STATUS_RM === newStatus
                        || ContainerStatusPolicy().CNTR_STATUS_SH === newStatus
                        || ContainerStatusPolicy().CNTR_STATUS_SM === newStatus)) {
                    subChargeType = chargeType + "-IS";
                }
                setSubChargeType(subChargeType ?? "");
                setShowChangeAction(false);
            } else if (CHARGE_TYPE_INBOUND !== currentUpdateStatusType
                && VesselChargeSubChargeTypePolicy.CODE_CY === subChargeType) {
                if (CHARGE_TYPE_OUTBOUND !== currentUpdateStatusType) {
                    setShowChangeAction(true);
                    calcShowSelectVsl(ChangeActionOption.CHANGE_CNTR_STATUS_ONLY);
                }
            } else {
                setShowChangeAction(false);
            }
            chargeVesselVM.onFieldChange(
                {
                    "updateStatusType": currentUpdateStatusType,
                    "subChargeType": subChargeType,
                    "changeActionOption": ChangeActionOption.CHANGE_CNTR_STATUS_ONLY,
                    "status": newStatus
                },
                chargeBatchUpdate, 'chargeBatchUpdate'
            )

            setUpdateStatusType(currentUpdateStatusType);
        }
    };

    return (
        <>
            <div className={'im-flex-row'} style={{ marginBottom: '24px' }}>

                <div className='im-flex-row-item'>
                    <NbisInputDropDown
                        label={ChargeDetailConstant.Charge.CNTR_STATUS}
                        width='330px'
                        inputType="freeText"
                        mode={"single"}
                        errorMessage={validateCntrStatus() ?? ""}
                        value={chargeBatchUpdate.status}
                        onChange={(e: any) => {
                            calcShowChangeAction(e && !_.isArray(e) ? e?.value ?? "" : "");
                        }}
                        options={statusDropdownOptions} />
                </div>

            </div>
            <div className={'im-flex-row'} style={{ width: "300px", marginBottom: '24px' }}>

                <div className='im-flex-row-item'>
                    <HPHGroupRadioButton
                        label={ChargeDetailConstant.Charge.CHANGE_ACTION}
                        disabled={!showChangeAction}
                        radioOptions={[
                            {
                                inputId: 1,
                                key: ChangeActionOption.CHANGE_CNTR_STATUS_ONLY,
                                name: ChangeActionOption.CHANGE_CNTR_STATUS_ONLY,
                            },
                            {
                                inputId: 2,
                                key: ChangeActionOption.MAP_EXISTING_TS_LEG,
                                name: ChangeActionOption.MAP_EXISTING_TS_LEG,
                            }
                        ]}
                        onChange={(e: any) => {
                            calcShowSelectVsl(e.key);
                        }}
                        checked={chargeBatchUpdate.changeActionOption ?? ""}
                    />
                </div>

            </div>
            <div className={'im-flex-row'} style={{ marginBottom: '24px' }}>
                <div className='im-flex-row-item'>
                    <HPHInputMask
                        label={ChargeConstant.Charge.CO_VSL_VOY}
                        disabled={!showSelectVoy || !allowInputVsl}
                        optional={true}
                        helpIcon={true}
                        toolTipText={"If Co/Vsl/Voy is not inputted, dummy vessel for TS leg will be created."}
                        width="330px"
                        value={{
                            co: chargeBatchUpdate.consortiumCode ?? '',
                            vsl: chargeBatchUpdate.vesselCode ?? '',
                            voy: chargeBatchUpdate.voyageCode ?? ''
                        }}
                        fieldConfiguration={{
                            co: { maxLength: 60, autoComplete: "off", valueInNormalCase: true, isNumeric: false },
                            vsl: { maxLength: 10, autoComplete: "off", valueInNormalCase: true, isNumeric: false },
                            voy: { maxLength: 10, autoComplete: "off", valueInNormalCase: true, isNumeric: false },
                        }}
                        onDataChange={(e: any) => chargeVesselVM.onCoVslVoyChange(e, {
                            co: 'consortiumCode',
                            vsl: 'vesselCode',
                            voy: 'voyageCode'
                        })} />
                </div>
            </div>
        </>
    )
}