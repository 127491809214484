import { BuProfileConstant } from "presentation/constant/BuProfile/BuProfileConstant";
import { useBuProfileVM } from "presentation/hook/BuProfile/useBuProfileVM";
import { useBuProfileTracked } from "presentation/store/BuProfle/BuProfileProvider";
import { memo, useCallback } from "react";
import { IconButton } from "veronica-ui-component/dist/component/core";

const BuProfileRightPreviewPanel: React.FC = () => {
    const [buProfileState] = useBuProfileTracked();
    const buProfileVM = useBuProfileVM();
    const BUPROFILE_CONSTANT = BuProfileConstant.buProfile;

    const handleClose = useCallback(() => {
        buProfileVM.onCancelCompanyEditPanel();
    }, [buProfileVM]);

    return (
        <div className='sidebar' style={{ width: "30%" }}>
            <div className='sidebar-header'>
                <div className='sidebar-title'>
                    {BUPROFILE_CONSTANT.OPERATING_COMPANY}
                </div>
                <div className='sidebar-action'>
                    <IconButton
                        fileName="Icon-cross"
                        size="medium"
                        toolTipText='Close'
                        onClick={handleClose}
                    />
                </div>
            </div>
            <div className='inner-div'>
                <span className='parent-label' >{BUPROFILE_CONSTANT.COMPANY_CODE}</span>
                <div className='text'>{buProfileState.currentOperatingCompany.operatingCompany}</div>
            </div>
            <div className='inner-div'>
                <span className='parent-label' >{BUPROFILE_CONSTANT.COMPANY_NAME}</span>
                <div className='text'>{buProfileState.currentOperatingCompany.operatingCompanyName}</div>
            </div>
            <div className='inner-div'>
                <span className='parent-label' >{BUPROFILE_CONSTANT.DOC_ABBR}</span>
                <div className='text'>{buProfileState.currentOperatingCompany.operatingCompanyNameAbbr}</div>
            </div>
        </div>
    );
}

export default memo(BuProfileRightPreviewPanel);