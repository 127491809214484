import { EMPTY_SEARCH_MODE_ENTITY } from "domain/entity/ChargeDataEnquiry/SearchModeEntity";
import { ChargeDetailConstant } from "presentation/constant/ChargeDetailMaintenance/ChargeDetailConstant";
import { ChargeConstant } from "presentation/constant/ChargeHeader/ChargeConstant";
import { DeliveryChargeSummarySearchCriteria } from "presentation/constant/ChargeHeader/DeliveryChargeSummary/DeliveryChargeSummarySearchCriteria";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useDeliveryChargeSummaryMaintenanceVM } from "presentation/hook/ChargeHeaderMaintenance/useDeliveryChargeSummaryMaintenanceVM";
import { useDeliveryChargeSummaryMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/DeliveryChargeSummaryMaintenanceProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DateRangePickerComponent from "presentation/view/components/DateRangePickerComponent";
import NbisInputDropDown from "presentation/view/components/NbisInputDropDown";
import { useCallback, useMemo, useState } from "react";
import { HPHButton, IconButton, InputField, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarActionBar, SidebarActionCross, SidebarTitle, Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

export const DeliveryChargeSummarySearchCriteriaPanel = () => {
    const DEL_CHG_SUMMARY_CONSTANT = ChargeConstant.DeliveryChargeSummary;
    const delChgSummaryVM = useDeliveryChargeSummaryMaintenanceVM();
    const [delChgSumState] = useDeliveryChargeSummaryMaintenanceTracked();
    const delChgSearchCriteria = delChgSumState.searchCriteria;
    const [isLoading, setIsLoading] = useState(false);
    const CHARGEDETAIL_CONSTANT = ChargeDetailConstant.Charge;

    const handleSearchClick = useCallback(async (searchCriteria: DeliveryChargeSummarySearchCriteria) => {
        setIsLoading(true);
        await delChgSummaryVM.onSearch(searchCriteria, EMPTY_SEARCH_MODE_ENTITY).then((data) => {
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
        }).finally(() => {
            setIsLoading(false);
        })
    }, [delChgSummaryVM]);

    const memoLoginUser = useMemo(() =>
        <div className='flex-row-item'>
            <InputField
                width="145px"
                maxLength={30}
                label={DEL_CHG_SUMMARY_CONSTANT.LOGIN_USER}
                type="text"
                value={delChgSearchCriteria.loginUser || ''}
                onChange={(e: any) => delChgSummaryVM.onInputTextChange(e, 'loginUser')} />
        </div>
        , [delChgSearchCriteria.loginUser, delChgSummaryVM, DEL_CHG_SUMMARY_CONSTANT.LOGIN_USER])

    const memoVslCode = useMemo(() =>
        <div className='flex-row-item'>
            <InputField
                maxLength={6}
                style={{ "text-transform": "uppercase", width: "145px" }}
                label={DEL_CHG_SUMMARY_CONSTANT.VESSEL_CODE}
                type="text"
                value={delChgSearchCriteria.vesselCode || ''}
                onChange={(e: any) => delChgSummaryVM.onInputTextChange(e, 'vesselCode', true)} />
        </div>
        , [DEL_CHG_SUMMARY_CONSTANT.VESSEL_CODE, delChgSearchCriteria.vesselCode, delChgSummaryVM])

    const memoVoyCode = useMemo(() =>
        <div className='flex-row-item'>
            <InputField
                maxLength={10}
                style={{ "text-transform": "uppercase", width: "145px" }}
                label={DEL_CHG_SUMMARY_CONSTANT.VOYAGE_CODE}
                type="text"
                value={delChgSearchCriteria.voyageCode || ''}
                onChange={(e: any) => delChgSummaryVM.onInputTextChange(e, 'voyageCode', true)} />
        </div>
        , [DEL_CHG_SUMMARY_CONSTANT.VOYAGE_CODE, delChgSearchCriteria.voyageCode, delChgSummaryVM])


    const memoVslName = useMemo(() =>
        <div className='flex-row-item'>
            <InputField
                width="320px"
                maxLength={30}
                label={DEL_CHG_SUMMARY_CONSTANT.VESSEL_NAME}
                type="text"
                value={delChgSearchCriteria.vesselName || ''}
                onChange={(e: any) => delChgSummaryVM.onInputTextChange(e, 'vesselName')} />
        </div>
        , [DEL_CHG_SUMMARY_CONSTANT.VESSEL_NAME, delChgSearchCriteria.vesselName, delChgSummaryVM])


    const memoEtdFromTo = useMemo(() =>
        <div className='flex-row-item'>
            <DateRangePickerComponent
                label={ChargeConstant.Charge.ETD_RANGE}
                width='320px'
                fieldNames={{ startField: "etdDateFrom", endField: "etdDateTo" }}
                dateRange={{ startDate: delChgSearchCriteria.etdDateFrom, endDate: delChgSearchCriteria.etdDateTo }}
                onDatesChange={delChgSummaryVM.onDateRangeChange}
            />
        </div>
        , [delChgSearchCriteria.etdDateFrom, delChgSearchCriteria.etdDateTo, delChgSummaryVM.onDateRangeChange])

    const memoEtaFromTo = useMemo(() =>
        <div className='flex-row-item'>
            <DateRangePickerComponent
                label={ChargeConstant.ChargeHeader.ETA_RANGE}
                width='320px'
                fieldNames={{ startField: "etaDateFrom", endField: "etaDateTo" }}
                dateRange={{ startDate: delChgSearchCriteria.etaDateFrom, endDate: delChgSearchCriteria.etaDateTo }}
                onDatesChange={delChgSummaryVM.onDateRangeChange}
            />
        </div>
        , [delChgSearchCriteria.etaDateFrom, delChgSearchCriteria.etaDateTo, delChgSummaryVM.onDateRangeChange])


    const memoBillingCycle = useMemo(() =>
        <div className='flex-row-item'>
            <NbisInputDropDown
                label={DEL_CHG_SUMMARY_CONSTANT.BILLING_CYCLE}
                inputType="freeText"
                width="145px"
                mode={'multiple'}
                sort={false}
                value={delChgSearchCriteria.billingCycleList?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => delChgSummaryVM.onMultipleDropdownChange(e, 'billingCycleList')}
                options={delChgSumState.dynamicOptions.billingCycleDropdownOptions} />
        </div>
        , [DEL_CHG_SUMMARY_CONSTANT.BILLING_CYCLE, delChgSearchCriteria.billingCycleList, delChgSumState.dynamicOptions.billingCycleDropdownOptions, delChgSummaryVM])

    const memoBillingCycleCode = useMemo(() =>
        <div className='flex-row-item'>
            <InputField
                width="145px"
                maxLength={10}
                label={DEL_CHG_SUMMARY_CONSTANT.BILLING_CYCLE_CODE}
                type="text"
                value={delChgSearchCriteria.billingCycleCode || ''}
                onChange={(e: any) => delChgSummaryVM.onInputTextChange(e, 'billingCycleCode')} />
        </div>
        , [DEL_CHG_SUMMARY_CONSTANT.BILLING_CYCLE_CODE, delChgSearchCriteria.billingCycleCode, delChgSummaryVM])

    const memoSoa = useMemo(() =>
        <div className='flex-row-item'>
            <InputField
                maxLength={6}
                style={{ "text-transform": "uppercase", width: "145px" }}
                label={DEL_CHG_SUMMARY_CONSTANT.SOA}
                type="text"
                value={delChgSearchCriteria.soa || ''}
                onChange={(e: any) => delChgSummaryVM.onInputTextChange(e, 'soa', true)} />
        </div>
        , [DEL_CHG_SUMMARY_CONSTANT.SOA, delChgSearchCriteria.soa, delChgSummaryVM])

    const memoServiceCode = useMemo(() =>
        <div className='flex-row-item'>
            <NbisInputDropDown
                label={DEL_CHG_SUMMARY_CONSTANT.SERVICE_CODE}
                inputType="freeText"
                width="320px"
                mode={'multiple'}
                sort={false}
                value={delChgSearchCriteria.serviceCodeList?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => delChgSummaryVM.onMultipleDropdownChange(e, 'serviceCodeList')}
                options={delChgSumState.dynamicOptions.serviceCodeDropdownOptions} />
        </div>
        , [DEL_CHG_SUMMARY_CONSTANT.SERVICE_CODE, delChgSearchCriteria.serviceCodeList, delChgSumState.dynamicOptions.serviceCodeDropdownOptions, delChgSummaryVM])

    return (
        <div className='side-form-content-left-wrapper'>
            {isLoading && <Loader Indicator="Spinner" size="Medium" />}
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>{CHARGEDETAIL_CONSTANT.SEARCH_CRITERIA}</SidebarTitle>

                        <SidebarActionCross>
                            <IconButton
                                fileName="Icon-cross" size="medium" toolTipText='Close' toolTipArrow={false} onClick={delChgSummaryVM.onSearchClick} />
                        </SidebarActionCross>
                    </Sidebarheader>
                </div>
            </div>

            <>
                <div className={'add-edit-form'} style={{ height: '75vh', maxHeight: '75vh', overflow: 'auto' }}>
                    <div className="flex-row-auto">
                        <div className={'flex-row-item'}>
                            <CriteriaItemContainer>
                                {delChgSumState.enabledSearchCriteria.loginUser ? memoLoginUser : null}
                                {delChgSumState.enabledSearchCriteria.soa ? memoSoa : null}
                            </CriteriaItemContainer>

                            <CriteriaItemContainer>
                                {delChgSumState.enabledSearchCriteria.vesselCode ? memoVslCode : null}
                                {delChgSumState.enabledSearchCriteria.voyageCode ? memoVoyCode : null}
                            </CriteriaItemContainer>

                            <CriteriaItemContainer>
                                {delChgSumState.enabledSearchCriteria.vesselName ? memoVslName : null}
                            </CriteriaItemContainer>

                            <CriteriaItemContainer>
                                {delChgSumState.enabledSearchCriteria.etd ? memoEtdFromTo : null}
                            </CriteriaItemContainer>

                            <CriteriaItemContainer>
                                {delChgSumState.enabledSearchCriteria.eta ? memoEtaFromTo : null}
                            </CriteriaItemContainer>

                            <CriteriaItemContainer>
                                {delChgSumState.enabledSearchCriteria.billingCycleList ? memoBillingCycle : null}
                                {delChgSumState.enabledSearchCriteria.billingCycleCode ? memoBillingCycleCode : null}
                            </CriteriaItemContainer>

                            <CriteriaItemContainer>
                                {delChgSumState.enabledSearchCriteria.serviceCodeList ? memoServiceCode : null}
                            </CriteriaItemContainer>
                        </div>
                    </div>
                </div>
                <SidebarActionBar>
                    <HPHButton label={WorkspaceConstant.Common.BUTTON_RESET} size={'Small'} theme={'Secondary'} onClick={delChgSummaryVM.onRemoveAllSearchCriteria} />
                    <HPHButton label={WorkspaceConstant.Common.BUTTON_SEARCH} size={'Small'} theme={'Primary'} onClick={(e: any) => {
                        handleSearchClick(delChgSearchCriteria);
                    }} />
                </SidebarActionBar></>
        </div>
    )
}