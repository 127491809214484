export interface RequisitionFormPrintCriteria{

    printWithCreChg?: boolean,
    billToCompany?: string | null,
    reqNoFrom?: string | null,
    reqNoTo?: string | null,
    creditChargeNoFrm?: string | null,
    creditChargeNoTo?: string | null,
    printQueueName?: string | null,
    
    [key: string]: string[] | string | boolean | number | Date | null | undefined
}

export const EMPTY_REQUISITION_FORM_PRINT_CRITERIA : RequisitionFormPrintCriteria = {
    
    printWithCreChg: false,
    billToCompany: null,
    reqNoFrom: null,
    reqNoTo: null,
    creditChargeNoFrm: null,
    creditChargeNoTo: null,
    printQueueName: null,
}