import { manHdrStateCheckboxOption } from "presentation/constant/CheckBox/StaticCheckboxOptions";
import { SupplementaryInvoiceConstant } from "presentation/constant/SupplementaryInvoice/SupplementaryInvoiceConstant";
import { useSupplementaryInvoiceVM } from "presentation/hook/SupplementaryInvoice/useSupplementaryInvoiceVM";
import { useSupplementaryInvoiceTracked } from "presentation/store/SupplementaryInvoice/SupplementaryInvoiceProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DateRangePickerComponent from "presentation/view/components/DateRangePickerComponent";
import NbisInputDropDown from "presentation/view/components/NbisInputDropDown";
import { memo, useMemo } from "react";
import { GroupCheckboxList, HPHGroupCheckbox, HPHInputMask, InputField } from "veronica-ui-component/dist/component/core";

const SupplementaryInvoiceSearchForm = () => {
    const [supplementaryInvoiceState] = useSupplementaryInvoiceTracked();
    const SUPPLEMENTARY_INVOICE_CONSTANT = SupplementaryInvoiceConstant.Search;
    const supplementaryInvoiceSearchCriteria = supplementaryInvoiceState.searchCriteria;
    const supplementaryInvoiceVM = useSupplementaryInvoiceVM();

    const memoRefNo = useMemo(() =>
        <div className='flex-row-item'>
            <InputField
                width='180px'
                maxLength={50}
                label={SUPPLEMENTARY_INVOICE_CONSTANT.REF_NO}
                type="text"
                value={supplementaryInvoiceSearchCriteria.refNo || ''}
                onChange={(e: any) => supplementaryInvoiceVM.onSearchInputTextChange(e, 'refNo')} />
        </div>
        , [SUPPLEMENTARY_INVOICE_CONSTANT.REF_NO, supplementaryInvoiceSearchCriteria.refNo, supplementaryInvoiceVM])

    const memoInvoiceCreditNo = useMemo(() =>
        <div className='flex-row-item'>
            <InputField
                width='180px'
                maxLength={50}
                label={SUPPLEMENTARY_INVOICE_CONSTANT.INVOICE_CREDIT_NO}
                type="text"
                value={supplementaryInvoiceSearchCriteria.invoiceCreditNo || ''}
                onChange={(e: any) => supplementaryInvoiceVM.onSearchInputTextChange(e, 'invoiceCreditNo')} />
        </div>
        , [SUPPLEMENTARY_INVOICE_CONSTANT.INVOICE_CREDIT_NO, supplementaryInvoiceSearchCriteria.invoiceCreditNo, supplementaryInvoiceVM])

    // const memoState = useMemo(() =>
    //     <div className='flex-row-item'>
    //         <NbisInputDropDown
    //             label={SUPPLEMENTARY_INVOICE_CONSTANT.STATE}
    //             width='180px'
    //             inputType="freeText"
    //             mode={'multiple'}
    //             sort={false}
    //             value={supplementaryInvoiceSearchCriteria.states?.map((item) => ({
    //                 value: item
    //             }))}
    //             onChange={(e) => {
    //                 supplementaryInvoiceVM.onMultipleDropdownChange(e, 'states')
    //             }}
    //             options={hdrStateDropdownOption}/>
    //     </div>
    // , [SUPPLEMENTARY_INVOICE_CONSTANT.STATE, supplementaryInvoiceSearchCriteria.states, supplementaryInvoiceVM])

    const memoState = useMemo(() =>
        <div className='im-flex-row-item'>
            <HPHGroupCheckbox
                label={SUPPLEMENTARY_INVOICE_CONSTANT.STATE}
                checkboxData={manHdrStateCheckboxOption}
                selectedValues={supplementaryInvoiceSearchCriteria.states}
                orientation={"horizontal"}
                onChange={(e: (GroupCheckboxList | undefined)[]) => supplementaryInvoiceVM.onGroupCheckboxChange(e, supplementaryInvoiceSearchCriteria, "states")}
            />
        </div>
        , [SUPPLEMENTARY_INVOICE_CONSTANT.STATE, supplementaryInvoiceSearchCriteria, supplementaryInvoiceVM])

    const memoBillToCompany = useMemo(() =>
        <div className='flex-row-item'>
            <NbisInputDropDown
                label={SUPPLEMENTARY_INVOICE_CONSTANT.BILL_TO_COMPANY}
                width='180px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={supplementaryInvoiceSearchCriteria.billToCompanies?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => {
                    supplementaryInvoiceVM.onMultipleDropdownChange(e, 'billToCompanies')
                }}
                options={supplementaryInvoiceState.dynamicOptions.billToCompDropdownOptions} />
        </div>
        , [SUPPLEMENTARY_INVOICE_CONSTANT.BILL_TO_COMPANY, supplementaryInvoiceSearchCriteria.billToCompanies, supplementaryInvoiceState.dynamicOptions.billToCompDropdownOptions, supplementaryInvoiceVM])


    const memoOpsDateFromTo = useMemo(() =>
        <div className='im-flex-row-item'>
            <DateRangePickerComponent
                label={SUPPLEMENTARY_INVOICE_CONSTANT.OPS_DATE_RANGE}
                width='390px'
                fieldNames={{ startField: "opsDateFrom", endField: "opsDateTo" }}
                dateRange={{ startDate: supplementaryInvoiceSearchCriteria.opsDateFrom, endDate: supplementaryInvoiceSearchCriteria.opsDateTo }}
                onDatesChange={supplementaryInvoiceVM.onDateRangeChange}
            />
        </div>
        , [SUPPLEMENTARY_INVOICE_CONSTANT.OPS_DATE_RANGE, supplementaryInvoiceSearchCriteria.opsDateFrom, supplementaryInvoiceSearchCriteria.opsDateTo, supplementaryInvoiceVM.onDateRangeChange])

    const memoCreatedDateFromTo = useMemo(() =>
        <div className='im-flex-row-item'>
            <DateRangePickerComponent
                label={SUPPLEMENTARY_INVOICE_CONSTANT.CREATED_DATE_RANGE}
                width='390px'
                fieldNames={{ startField: "createdDateFrom", endField: "createdDateTo" }}
                dateRange={{ startDate: supplementaryInvoiceSearchCriteria.createdDateFrom, endDate: supplementaryInvoiceSearchCriteria.createdDateTo }}
                onDatesChange={supplementaryInvoiceVM.onDateRangeChange}
            />
        </div>
        , [SUPPLEMENTARY_INVOICE_CONSTANT.CREATED_DATE_RANGE, supplementaryInvoiceSearchCriteria.createdDateFrom, supplementaryInvoiceSearchCriteria.createdDateTo, supplementaryInvoiceVM.onDateRangeChange])

    const memoCreatedBys = useMemo(() =>
        <div className='flex-row-item'>
            <NbisInputDropDown
                label={SUPPLEMENTARY_INVOICE_CONSTANT.CREATED_BY}
                width='180px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={supplementaryInvoiceSearchCriteria.createdBys?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => {
                    supplementaryInvoiceVM.onMultipleDropdownChange(e, 'createdBys')
                }}
                options={supplementaryInvoiceState.dynamicOptions.createdByDropdownOptions} />
        </div>
        , [SUPPLEMENTARY_INVOICE_CONSTANT.CREATED_BY, supplementaryInvoiceSearchCriteria.createdBys, supplementaryInvoiceState.dynamicOptions.createdByDropdownOptions, supplementaryInvoiceVM])

    const memoCoVslVoy = useMemo(() =>
        <div className='flex-row-item'>
            <HPHInputMask
                width='390px'
                label={SUPPLEMENTARY_INVOICE_CONSTANT.CO_VSL_VOY}
                value={{
                    co: supplementaryInvoiceSearchCriteria.co ?? '',
                    vsl: supplementaryInvoiceSearchCriteria.vsl ?? '',
                    voy: supplementaryInvoiceSearchCriteria.voy ?? ''
                }}
                fieldConfiguration={{
                    co: { maxLength: 60, autoComplete: "off", valueInNormalCase: true, isNumeric: false },
                    vsl: { maxLength: 10, autoComplete: "off", valueInNormalCase: true, isNumeric: false },
                    voy: { maxLength: 10, autoComplete: "off", valueInNormalCase: true, isNumeric: false },
                }}
                onDataChange={(e: any) => supplementaryInvoiceVM.onSearchCoVslVoyChange(e, {
                    co: 'co',
                    vsl: 'vsl',
                    voy: 'voy'
                })} />
        </div>
        , [SUPPLEMENTARY_INVOICE_CONSTANT.CO_VSL_VOY, supplementaryInvoiceSearchCriteria.co, supplementaryInvoiceSearchCriteria.vsl, supplementaryInvoiceSearchCriteria.voy, supplementaryInvoiceVM])




    return <>


        <CriteriaItemContainer>
            {supplementaryInvoiceState.enabledSearchCriteria.states ? memoState : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {supplementaryInvoiceState.enabledSearchCriteria.coVslVoy ? memoCoVslVoy : null}
        </CriteriaItemContainer>

        <CriteriaItemContainer>
            {supplementaryInvoiceState.enabledSearchCriteria.createdBys ? memoCreatedBys : null}
            {supplementaryInvoiceState.enabledSearchCriteria.billToCompanies ? memoBillToCompany : null}
        </CriteriaItemContainer>

        <CriteriaItemContainer>
            {supplementaryInvoiceState.enabledSearchCriteria.refNo ? memoRefNo : null}
            {supplementaryInvoiceState.enabledSearchCriteria.invoiceCreditNo ? memoInvoiceCreditNo : null}
        </CriteriaItemContainer>

        <CriteriaItemContainer>
            {supplementaryInvoiceState.enabledSearchCriteria.opsDateRange ? memoOpsDateFromTo : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {supplementaryInvoiceState.enabledSearchCriteria.createdDateRange ? memoCreatedDateFromTo : null}
        </CriteriaItemContainer>

    </>;
}

export default memo(SupplementaryInvoiceSearchForm);