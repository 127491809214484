import { memo } from "react";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import ChangePasswordFormPanel from "./ChangePasswordFormPanel";

const ChangePasswordComp: React.FC = () => {

        return <>
                <div className='main-comp-wrapper'>
                        <SliderPanel
                                isOpen={true}
                                leftSectionWidth={'100%'}
                                rightSectionWidth={'0%'}
                                draggable={true}
                                leftChildren={<ChangePasswordFormPanel />}
                                rightChildren={<></>} />
                </div>
        </>
}

export default memo(ChangePasswordComp);