import { UserEntity } from "domain/entity/User/UserEntity";
import { INITIAL_USER_COL_FOR_GROUP_DEF } from "presentation/constant/User/UserColumnDefinition";
import { UserConStant } from "presentation/constant/User/UserConstant";
import { useUserGroupVM } from "presentation/hook/UserGroup/useUserGroupVM";
import { useUserGroupTracked } from "presentation/store/UserGroup/UserGroupProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { useCallback, useMemo, useState } from "react";

const UserTablePanel: React.FC = () => {
    const [userGroupState] = useUserGroupTracked();
    const { isEditable, isRead } = userGroupState.userGroupState;
    const { isEditable: isUserEditable, isAdd: isUserAdd, isTabularDataActive: isUserTabularDataActive } = userGroupState.userState;
    const { isEditable: isPermissionEditable, isAdd: isPermissionAdd } = userGroupState.permissionState;
    const userGroupVM = useUserGroupVM();
    const currentGroup = userGroupState.userGroupState.currentSelectedData;
    const [tableRowSelect, setTableRowSelect] = useState<any>({});
    const tableId = 'user-table';
    const USER_CONSTANT = UserConStant.user;

    const handleAddClick = useCallback(() => {
        userGroupVM.onUserAddClick();
    }, [userGroupVM]);

    //no need at the moment
    // const handleRowDoubleClick = useCallback((user: UserEntity) => {
    //     userGroupVM.onUserRowDoubleClick(user);
    // }, [userGroupVM]);

    const handleDelete = useCallback((user: UserEntity) => {
        userGroupVM.onUserRowDeleteClick(user);
    }, [userGroupVM]);

    const memoGroupTable = useMemo(() => {
        return (
            <NbisTable
                id={tableId}
                columns={INITIAL_USER_COL_FOR_GROUP_DEF}
                headerLabel={USER_CONSTANT.USER}
                onAddClick={handleAddClick}
                // onRowDoubleClick={(e: any, user: UserEntity) => handleRowDoubleClick(user)}
                data={currentGroup.users || []}
                showPaginator={false}
                editable={false}
                onRowClick={(e: any, i: any) => {
                    setTableRowSelect((prevState: any) => ({
                        ...prevState,
                        [tableId]: isUserAdd || isUserEditable ? { ...prevState[tableId] } : { [i]: true },
                    }));
                }}
                isRowHighligted={tableRowSelect[tableId] ? true : false}
                isRowChange={!(isUserAdd || isUserEditable)}
                showAddIcon={!(isEditable && isRead) && !isUserAdd && !isUserEditable && !isPermissionEditable && !isPermissionAdd}
                showUploadIcon={!(isEditable && isRead) && !isUserAdd && !isUserEditable && !isPermissionEditable && !isPermissionAdd}
                showDeleteButton={!(isEditable && isRead) && !isUserAdd && !isUserEditable && !isPermissionEditable && !isPermissionAdd}
                showActionColumn={true}
                showReloadIcon={false}
                selectionMode={false}
                isNewColumnSetting={true}
                isScrollHighlighted={true}
                onDelete={handleDelete}
                gridHeight="customHeight"
                customHeight="calc(100vh - 90px)" />
        );
    }, [currentGroup.users, handleAddClick, handleDelete, isEditable, isRead,
        isUserEditable, isUserAdd, tableRowSelect, USER_CONSTANT.USER, isPermissionEditable, isPermissionAdd])

    return <TableWrapper isHide={!isUserTabularDataActive}>{memoGroupTable}</TableWrapper>
}

export default UserTablePanel;