import { useChargeSuppleTermsVM } from "presentation/hook/Company/useChargeSuppleTermsVM";
import { useCompanyDetailVM } from "presentation/hook/Company/useCompanyDetailVM";
import { useCompanyEmptyPoolVM } from "presentation/hook/Company/useCompanyEmptyPoolVM";
import { useCompanyFspVM } from "presentation/hook/Company/useCompanyFspVM";
import { useCompanyTariffNatureVM } from "presentation/hook/Company/useCompanyTariffNatureVM";
import { useCompanyTerminalVM } from "presentation/hook/Company/useCompanyTerminalVM";
import { useCompanyTeuFactorVM } from "presentation/hook/Company/useCompanyTeuFactorVM";
import { useCustomerAssignmentVM } from "presentation/hook/Company/useCustomerAssignmentVM";
import { useCustomerVM } from "presentation/hook/Company/useCustomerVM";
import { useCompanyMaintenanceTracked } from "presentation/store/Company/CompanyMaintenanceProvider";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import CompanyDetailContent from "./CompanyDetailContent";
import CompanyDetailRejectReasonModal from "./CompanyDetailRejectReasonModal";
import CompanyDetailTitleBar from "./CompanyDetailTitleBar";
import CompanyInfoDetailFormPanel from "./LeftPanel/CompanyInfoDetailFormPanel";

const CompanyDetailMaintenance: React.FC = () => {

    const [companyState] = useCompanyMaintenanceTracked();
    const { currentCompany } = companyState;
    const [isLoading, setIsLoading] = useState(false);
    const [isInitialScreen, setIsInitialScreen] = useState(false);

    const chargeSuppleTermsVM = useChargeSuppleTermsVM();
    const companyFspVM = useCompanyFspVM();
    const companyTeuFactorVM = useCompanyTeuFactorVM();
    const companyEmptyPoolVM = useCompanyEmptyPoolVM();
    const customerAssignmentVM = useCustomerAssignmentVM();
    const companyDetailVM = useCompanyDetailVM();
    const companyTerminalVM = useCompanyTerminalVM()
    const companyTariffNatureVM = useCompanyTariffNatureVM();
    const customerVM = useCustomerVM();

    const memoCompanyDetailTitleBar = useMemo(() => <CompanyDetailTitleBar />, []);
    const memoCompanyInfoDetailFormPanel = useMemo(() => <CompanyInfoDetailFormPanel />, []);
    const memoCompanyDetailContent = useMemo(() => <CompanyDetailContent />, []);

    const initialScreen = useCallback(async () => {
        if (isInitialScreen) return;
        try {
            if (currentCompany.id !== 0) {
                setIsLoading(true);
                await Promise.allSettled([
                    companyDetailVM.loadDropdownOption(currentCompany.id),
                    companyFspVM.initialFspTableData(currentCompany.id),
                    chargeSuppleTermsVM.initialChgSuppleTermsTableData(currentCompany.id),
                    companyEmptyPoolVM.initialEmptyPoolTableData(currentCompany.id),
                    companyTeuFactorVM.initialTeuFactorTableData(currentCompany.id),
                    customerAssignmentVM.initialCustAssignTableData(currentCompany.id),
                    companyTerminalVM.initialTerminalData(currentCompany.id),
                    companyTariffNatureVM.initialTariffNatureData(currentCompany.id),
                    companyTeuFactorVM.loadDropdownOption(currentCompany.id),
                    customerAssignmentVM.loadDropdownOption(),
                    customerVM.loadDropdownOption()
                ]).then(data => {
                    setIsLoading(false);
                    setIsInitialScreen(true);
                }).catch(e => {
                    setIsLoading(false);
                })
            }
        } catch (error) {
        }
    }, [chargeSuppleTermsVM, companyDetailVM, companyEmptyPoolVM, companyFspVM, companyTariffNatureVM, companyTerminalVM, companyTeuFactorVM,
        currentCompany.id, customerAssignmentVM, customerVM, isInitialScreen]);

    useEffect(() => {
        if (companyState.isShowDetail) {
            initialScreen();
        }
    }, [companyState.isShowDetail, initialScreen])

    if (isLoading) return <Loader Indicator="Stripe" size="Large" />


    return <>
        {<div className="main-comp-wrapper">
            {memoCompanyDetailTitleBar}
            <SliderPanel
                isOpen={true}
                leftSectionWidth={"25%"}
                rightSectionWidth={"75%"}
                leftChildren={memoCompanyInfoDetailFormPanel}
                rightChildren={memoCompanyDetailContent}
            />
            <div className='im-charge-data-search-confirm-modal-container'>
                {<CompanyDetailRejectReasonModal />}
            </div>
        </div>}
    </>
}

export default memo(CompanyDetailMaintenance)
