import { useStandardProposalTracked } from "presentation/store/StandardProposal/StandardProposalProvider";
import { memo, useMemo } from "react";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import StandardProposalTablePanel from "../Table/StandardProposalTablePanel";
import TariffItemRightPanel from "./TariffItemRightPanel";

const StandardProposalRightPanel = () => {
    const [standardProposalState] = useStandardProposalTracked();
    // const standardProposalVM = useStandardProposalVM();
    const { isShowTariffItemPanel, isShowCopyPanel, isShowTariffItemTierPanel } = standardProposalState;

    const memoStandardProposalTable = useMemo(() => {
        return <StandardProposalTablePanel />
    }, [])

    const memoTariffItemRightTable = useMemo(() => {
        return <div style={isShowTariffItemPanel ? ((isShowCopyPanel || isShowTariffItemTierPanel) ? { marginLeft: "15px" } : { marginLeft: "0px" }) : { marginLeft: "0px" }}>
            <TariffItemRightPanel />
        </div>
    }, [isShowCopyPanel, isShowTariffItemPanel, isShowTariffItemTierPanel])

    return <>
        <div className={`main-comp-wrapper${(isShowTariffItemPanel) ? '' : ' im-hide-side-form-draggable'}`} >

            <SliderPanel
                isOpen={true}
                draggable={true}
                leftSectionWidth={isShowTariffItemPanel ? ((isShowCopyPanel || isShowTariffItemTierPanel) ? "0%" : "25%") : "100%"}
                rightSectionWidth={isShowTariffItemPanel ? ((isShowCopyPanel || isShowTariffItemTierPanel) ? "100%" : "75%") : "0%"}
                leftChildren={memoStandardProposalTable}
                rightChildren={memoTariffItemRightTable}
            />
        </div>
    </>
}

export default memo(StandardProposalRightPanel);