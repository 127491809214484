import { ManualChargePrintCriteria } from "domain/entity/ManualCharge/ManualChargePrintCriteria"

export interface NonOpsPrintCriteria extends ManualChargePrintCriteria { 
    docDateFrom?: Date | null,
    docDateTo?: Date | null,
    customerCode?: string | null,

    [key: string]: string[] | string | boolean | number | Date | null | undefined
}

export const EMPTY_NON_OPS_PRINT_CRITERIA : NonOpsPrintCriteria = {
    adjInd: null,
    refNoFrom: null,
    refNoTo: null,
    docDateFrom: null,
    docDateTo: null,
    customerCode: null,
    printQueueName: null,
    chargeCategory: "NO"
}