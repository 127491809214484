import { PortEntity } from "domain/entity/Port/PortEntity";
import { MessageConstant } from "presentation/constant/MessageConstant";
import { PortConstant } from "presentation/constant/Port/PortConstant";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { usePortVM } from "presentation/hook/Port/usePortVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { usePortTracked } from "presentation/store/Port/PortProvider";
import { ConfirmDeleteModal } from "presentation/view/components/ConfirmDeleteModal";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import { NbisIconButton } from "presentation/view/components/NbisIconButton";
import { memo, useCallback, useState } from "react";
import { HPHButton, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const PortTitleBar: React.FC = () => {
    const PORT_CONSTANT = PortConstant.Title;
    const portVM = usePortVM();
    const [portState] = usePortTracked();
    const [isLoading, setIsLoading] = useState(false);
    const { selectedRows, searchCriteria } = portState;
    const messageBarVM = useMessageBarVM();
    const [isShowDelete, setIsShowDelete] = useState<boolean>(false);
    const [deletedPort, setDeletedPort] = useState<PortEntity[]>();
    const [anaInfoState] = useANAInfoTracked();
    const { allowDelete } = anaInfoState;
    const handleAdd = useCallback(() => {
        portVM.onAddClick();
    }, [portVM]);

    const handleDelete = useCallback(async () => {
        if (!deletedPort) return;
        setIsLoading(true);
        setIsShowDelete(false);
        portVM.onDelete(deletedPort).then((data) => {
            if (data && data.success) {
                messageBarVM.showSuccess(MessageConstant.common.DELETED_DATA_SUCCESSFUL)
                portVM.searchPortList(searchCriteria).then(data => {
                    setIsLoading(false);
                });
            } else {
                messageBarVM.showError(data.data);
            }
        }).catch((error) => {
            setIsLoading(false);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [deletedPort, portVM, messageBarVM, searchCriteria]);

    const isDeleteDisable = () => {
        if (selectedRows.length > 0) return false
        return true;
    }

    const handleDeleteClick = useCallback(async () => {
        setIsShowDelete(true);
        setDeletedPort(selectedRows);
    }, [selectedRows]);

    const handleSearch = useCallback(async () => {
        portVM.onSearchClick();
    }, [portVM]);

    const handleCancel = () => {
        setIsShowDelete(false);
    }

    return <Sidebarheader style={{ width: '100%', display: "flex", alignItems: "center" }}>
        <HeaderTitle>{PORT_CONSTANT.TITLE}</HeaderTitle>
        {(isLoading) && <Loader Indicator="Spinner" size="Medium" />}
        <StyledAction className="tm-animated-wrapper">
            <IconButton fileName='Icon-search' disabled={false} size='medium' toolTipText={'Search'} toolTipArrow={false} onClick={handleSearch} />
            <div className="add-seperator" />
            <NbisIconButton fileName='Icon-add' disabled={false} size='medium' toolTipText={'Add'} toolTipArrow={false} onClick={handleAdd} />
            <div className="add-seperator" />
            {(allowDelete) && <HPHButton disabled={isDeleteDisable()} label={WorkspaceConstant.Common.BUTTON_DELETE} size={'Small'} theme={'Primary'} onClick={handleDeleteClick} />}
            <ConfirmDeleteModal headerText={`${WorkspaceConstant.Common.BUTTON_DELETE} ${PORT_CONSTANT.TITLE}`}
                contentText={`${MessageConstant.common.DELETE_ALERT_TITLE}\n${MessageConstant.common.DELETE_ALERT_MESSAGE}`}
                visible={isShowDelete}
                onCancelClick={handleCancel}
                onDeleteClick={handleDelete}
            />
        </StyledAction>
        {portState.forceRefresh && <></>}
    </Sidebarheader>
}

export default memo(PortTitleBar);