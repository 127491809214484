import { EMPTY_RECAL_PROCESS_AND_GEN_SEARCH_CRITERIA, RecalProcessAndGenSearchCriteria } from "domain/entity/Recalculation/RecalProcessAndGenSearchCriteria";
import { DropdownProps } from "../DropdownProps";
import { BaseViewChangeSate } from "../BaseViewChangeState";

export interface RecalProcessAndGenDropdownOptions {    
    chargeOnCompanyDropdownOptions: DropdownProps[],    
}

export interface RecalProcessAndGenViewChangeState extends BaseViewChangeSate {
    isSubmitClicked: boolean
}


export interface RecalProcessAndGenMaintenanceModel {
    
    isLoading:boolean,
    isShowCriteriaPanel: boolean,
    searchCriteria: RecalProcessAndGenSearchCriteria,    
    dynamicOptions: RecalProcessAndGenDropdownOptions,
    allFormState?: {[x:string]:string},
    //enabledSearchCriteria: RequisitionFormEnabledSearchCriteria,
    isShowDetail: boolean,
    isBackFromDetail: boolean,
    isAllowAutoSearch: boolean,
    masterState: RecalProcessAndGenViewChangeState,
}

export const EMPTY_RECAL_PROCESS_AND_GEN_MAINTENANCE_MODEL: RecalProcessAndGenMaintenanceModel = {
    isLoading: false,
    isShowCriteriaPanel: false,
    isBackFromDetail: false,
    isAllowAutoSearch: true,
    searchCriteria: { ...EMPTY_RECAL_PROCESS_AND_GEN_SEARCH_CRITERIA },
    dynamicOptions: {
        chargeOnCompanyDropdownOptions: [],        
    },
    
    allFormState: {},
    //enabledSearchCriteria: { ...DEFAULT_ENABLED_REQUISITION_FORM_SEARCH_CRITERIA },
    isShowDetail: false,   
    masterState: {
        isSliderOpen: false,
        isTabularDataActive: false,
        isAdd: false,
        isRead: false,
        isEditable: false,
        isSaveClicked: false,
        lastEditRowId: "",
        isSelected: false,
        isSubmitClicked: false,
        slider: {
            isAdd: false,
            isRead: false,
            isEditable: false
        }
    }, 
    
}