import { DocumentPreviewConstant } from "presentation/constant/DocumentPreview/DocumentPreviewConstant";
import { useDocumentPreviewVM } from "presentation/hook/DocumentPreview/useDocumentPreviewVM";
import { useDocumentPreviewTracked } from "presentation/store/DocumentPreview/DocumentPreviewProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DateRangePickerComponent from "presentation/view/components/DateRangePickerComponent";
import NbisInputDropDown from "presentation/view/components/NbisInputDropDown";
import { memo, useMemo } from "react";
import { InputField } from "veronica-ui-component/dist/component/core";

const DocumentPreviewSearchForm = () => {
    const DOCUMENT_PREVIEW_CONSTANT = DocumentPreviewConstant.Search;
    const [documentPreviewState] = useDocumentPreviewTracked();
    const documentPreviewSearchCriteria = documentPreviewState.searchCriteria;
    const documentPreviewVM = useDocumentPreviewVM();
    const memoBillingType = useMemo(() =>
        <div className='flex-row-item'>
            <InputField
                width='180px'
                maxLength={50}
                label={DOCUMENT_PREVIEW_CONSTANT.BILLING_TYPE}
                type="text"
                value={documentPreviewSearchCriteria.billingType || ''}
                onChange={(e: any) => documentPreviewVM.onSearchInputTextChange(e, 'billingType')} />
        </div>
        , [DOCUMENT_PREVIEW_CONSTANT.BILLING_TYPE, documentPreviewSearchCriteria.billingType, documentPreviewVM])

    const memoBillingCycleCode = useMemo(() =>
        <div className='flex-row-item'>
            <InputField
                width='180px'
                maxLength={50}
                label={DOCUMENT_PREVIEW_CONSTANT.BILLING_CYCLE_CODE}
                type="text"
                value={documentPreviewSearchCriteria.billingCycleCode || ''}
                onChange={(e: any) => documentPreviewVM.onSearchInputTextChange(e, 'billingCycleCode')} />
        </div>
        , [DOCUMENT_PREVIEW_CONSTANT.BILLING_CYCLE_CODE, documentPreviewSearchCriteria.billingCycleCode, documentPreviewVM])

    const memoChargeType = useMemo(() =>
        <div className='flex-row-item'>
            <NbisInputDropDown
                label={DOCUMENT_PREVIEW_CONSTANT.CHARGE_TYPE}
                width='180px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={documentPreviewSearchCriteria.chargeTypeList?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => {
                    documentPreviewVM.onMultipleDropdownChange(e, 'chargeTypeList')
                }}
                options={documentPreviewState.dynamicOptions.chargeTypeDropdownOptions} />
        </div>
        , [DOCUMENT_PREVIEW_CONSTANT.CHARGE_TYPE, documentPreviewSearchCriteria.chargeTypeList, documentPreviewState.dynamicOptions.chargeTypeDropdownOptions, documentPreviewVM])

    const memobillToCompany = useMemo(() =>
        <div className='flex-row-item'>
            <NbisInputDropDown
                label={DOCUMENT_PREVIEW_CONSTANT.BILL_TO_COMPANY}
                width='180px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={documentPreviewSearchCriteria.billToCompList?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => {
                    documentPreviewVM.onMultipleDropdownChange(e, 'billToCompList')
                }}
                options={documentPreviewState.dynamicOptions.billToCompanyDropdownOptions} />
        </div>
        , [DOCUMENT_PREVIEW_CONSTANT.BILL_TO_COMPANY, documentPreviewSearchCriteria.billToCompList, documentPreviewState.dynamicOptions.billToCompanyDropdownOptions, documentPreviewVM])

    const memoVesselName = useMemo(() =>
        <div className='flex-row-item'>
            <InputField
                width='180px'
                maxLength={50}
                label={DOCUMENT_PREVIEW_CONSTANT.VESSEL_NAME}
                type="text"
                value={documentPreviewSearchCriteria.vesselName || ''}
                onChange={(e: any) => documentPreviewVM.onSearchInputTextChange(e, 'vesselName')} />
        </div>
        , [DOCUMENT_PREVIEW_CONSTANT.VESSEL_NAME, documentPreviewSearchCriteria.vesselName, documentPreviewVM])

    const memoVesselCode = useMemo(() =>
        <div className='flex-row-item'>
            <InputField
                width='180px'
                maxLength={50}
                label={DOCUMENT_PREVIEW_CONSTANT.VESSEL_CODE}
                type="text"
                value={documentPreviewSearchCriteria.vesselCode || ''}
                onChange={(e: any) => documentPreviewVM.onSearchInputTextChange(e, 'vesselCode', true)} />
        </div>
        , [DOCUMENT_PREVIEW_CONSTANT.VESSEL_CODE, documentPreviewSearchCriteria.vesselCode, documentPreviewVM])

    const memoVoyageCode = useMemo(() =>
        <div className='flex-row-item'>
            <InputField
                width='180px'
                maxLength={50}
                label={DOCUMENT_PREVIEW_CONSTANT.VOYAGE_CODE}
                type="text"
                value={documentPreviewSearchCriteria.voyageCode || ''}
                onChange={(e: any) => documentPreviewVM.onSearchInputTextChange(e, 'voyageCode', true)} />
        </div>
        , [DOCUMENT_PREVIEW_CONSTANT.VOYAGE_CODE, documentPreviewSearchCriteria.voyageCode, documentPreviewVM])

    const memoOpsDateFromTo = useMemo(() =>
        <div className='im-flex-row-item'>
            <DateRangePickerComponent
                label={DOCUMENT_PREVIEW_CONSTANT.OPS_DATE_RANGE}
                width='390px'
                fieldNames={{ startField: "opsDateFrom", endField: "opsDateTo" }}
                dateRange={{ startDate: documentPreviewSearchCriteria.opsDateFrom, endDate: documentPreviewSearchCriteria.opsDateTo }}
                onDatesChange={documentPreviewVM.onDateRangeChange}
            />
        </div>
        , [DOCUMENT_PREVIEW_CONSTANT.OPS_DATE_RANGE, documentPreviewSearchCriteria.opsDateFrom, documentPreviewSearchCriteria.opsDateTo, documentPreviewVM.onDateRangeChange])

    const memoGenDateFromTo = useMemo(() =>
        <div className='im-flex-row-item'>
            <DateRangePickerComponent
                label={DOCUMENT_PREVIEW_CONSTANT.GEN_DATE_RANGE}
                width='390px'
                fieldNames={{ startField: "genDateFrom", endField: "genDateTo" }}
                dateRange={{ startDate: documentPreviewSearchCriteria.genDateFrom, endDate: documentPreviewSearchCriteria.genDateTo }}
                onDatesChange={documentPreviewVM.onDateRangeChange}
            />
        </div>
        , [DOCUMENT_PREVIEW_CONSTANT.GEN_DATE_RANGE, documentPreviewSearchCriteria.genDateFrom, documentPreviewSearchCriteria.genDateTo, documentPreviewVM.onDateRangeChange])

    const memoGenBy = useMemo(() =>
        <div className='flex-row-item'>
            <InputField
                width='180px'
                maxLength={50}
                label={DOCUMENT_PREVIEW_CONSTANT.GEN_BY}
                type="text"
                value={documentPreviewSearchCriteria.genBy || ''}
                onChange={(e: any) => documentPreviewVM.onSearchInputTextChange(e, 'genBy')} />
        </div>
        , [DOCUMENT_PREVIEW_CONSTANT.GEN_BY, documentPreviewSearchCriteria.genBy, documentPreviewVM])



    return <>

        <CriteriaItemContainer>
            {documentPreviewState.enabledSearchCriteria.billingType ? memoBillingType : null}
            {documentPreviewState.enabledSearchCriteria.billingCycleCode ? memoBillingCycleCode : null}
        </CriteriaItemContainer>

        <CriteriaItemContainer>
            {documentPreviewState.enabledSearchCriteria.chargeTypeList ? memoChargeType : null}
            {documentPreviewState.enabledSearchCriteria.billToCompList ? memobillToCompany : null}
        </CriteriaItemContainer>

        <CriteriaItemContainer>
            {documentPreviewState.enabledSearchCriteria.vesselCode ? memoVesselCode : null}
            {documentPreviewState.enabledSearchCriteria.vesselName ? memoVesselName : null}
        </CriteriaItemContainer>

        <CriteriaItemContainer>
            {documentPreviewState.enabledSearchCriteria.voyageCode ? memoVoyageCode : null}
            {documentPreviewState.enabledSearchCriteria.genBy ? memoGenBy : null}
        </CriteriaItemContainer>

        <CriteriaItemContainer>
            {documentPreviewState.enabledSearchCriteria.opsDateRange ? memoOpsDateFromTo : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {documentPreviewState.enabledSearchCriteria.genDateRange ? memoGenDateFromTo : null}
        </CriteriaItemContainer>

    </>;
}
export default memo(DocumentPreviewSearchForm);