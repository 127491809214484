import { useChargeDetailVM } from "presentation/hook/ChargeDetailMaintenance/useChargeDetailVM";
import { ChargeDetailModel } from "presentation/model/ChargeDetailMaintenance/ChargeDetailModel";
import NbisInputDropDown from "presentation/view/components/NbisInputDropDown";

export const AccountTerminalAccordion = ({ chargeDetailState }: { chargeDetailState: ChargeDetailModel }) => {
    const chargeBatchUpdate = chargeDetailState.chargeBatchUpdate
    const chargeDetailVM = useChargeDetailVM();
    return (
        <>
            <div className={'im-flex-row'} style={{ marginBottom: '24px' }}>
                {/* <div className='im-flex-row-item'>
                    <InputField
                        label='Account Terminal'
                        width='150px'
                        type="text"
                        value={chargeBatchUpdate.accResumeTerminal}
                        onChange={(e:any) => chargeDetailVM.onInputTextChange(e, 'accResumeTerminal', chargeBatchUpdate, 'chargeBatchUpdate')}
                        />
                </div> */}
                <div className='im-flex-row-item'>
                    <NbisInputDropDown
                        label='Reason Code'
                        width='330px'
                        inputType="freeText"
                        mode={"single"}
                        value={chargeBatchUpdate.accResumeTerminalReasonCode}
                        onChange={(e: any) => chargeDetailVM.onDropdownChange(e, 'reasonCode', chargeBatchUpdate, 'chargeBatchUpdate')}
                        options={chargeDetailState.dynamicOptions.reasonCodeDropdownOptions}
                        errorMessage={chargeBatchUpdate?.allFormState?.reasonCode ?? ""}
                    />
                </div>

            </div>
        </>
    )
}