import { useChargeDetailVM } from "presentation/hook/ChargeDetailMaintenance/useChargeDetailVM";
import { ChargeDetailModel } from "presentation/model/ChargeDetailMaintenance/ChargeDetailModel";
import NbisInputDropDown from "presentation/view/components/NbisInputDropDown";
import { InputField } from "veronica-ui-component/dist/component/core";

export const SpecialIndForwarderRefAccordion = ({ chargeDetailState }: { chargeDetailState: ChargeDetailModel }) => {
    const chargeBatchUpdate = chargeDetailState.chargeBatchUpdate
    const chargeDetailVM = useChargeDetailVM();
    return (
        <>
            <div className={'im-flex-row'} style={{ marginBottom: '24px' }}>
                <div className='im-flex-row-item'>
                    <NbisInputDropDown
                        label='Special Ind'
                        width='150px'
                        inputType="freeText"
                        mode={"single"}
                        value={chargeBatchUpdate.specialInd}
                        onChange={(e: any) => chargeDetailVM.onDropdownChange(e, 'specialInd', chargeBatchUpdate, 'chargeBatchUpdate')}
                        options={chargeDetailState.dynamicOptions.specialIndDropdownOptions} />
                </div>
                <div className='im-flex-row-item'>
                    <InputField
                        label='Forwarder Reference.'
                        width='150px'
                        type="text"
                        value={chargeBatchUpdate.forwarderReference || ''}
                        onChange={(e: any) => chargeDetailVM.onInputTextChange(e, 'forwarderReference', chargeBatchUpdate, 'chargeBatchUpdate')} />
                </div>

            </div>
        </>
    )
}