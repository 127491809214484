import { SelectionChangedEvent } from "ag-grid-community";
import { INITIAL_MASTER_HOLD_DETAIL_COL_DEF, transferRowData } from "presentation/constant/MasterHold/MasterHoldDetailColumnDefinition";
import { MasterHoldMaintenanceConstant } from "presentation/constant/MasterHold/MasterHoldMaintenanceConstant";
import { useMasterHoldMaintenanceVM } from "presentation/hook/MasterHold/useMasterHoldMaintenanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useMasterHoldMaintenanceTracked } from "presentation/store/MasterHold/MasterHoldMaintenanceProvider";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HPHButton, Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const MasterHoldDetailTablePanel: React.FC = () => {
    const [masterHoldState] = useMasterHoldMaintenanceTracked();;
    const masterHoldVM = useMasterHoldMaintenanceVM();
    const messageBarVM = useMessageBarVM();

    const { detailState, currentSelectedRow } = masterHoldState;
    const { masterHoldChgInfoList, selectedRows } = detailState;
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    let gridRef: any = useRef();
    const [isLoading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();
    }, [onTableSelectionClicked])

    useEffect(() => {
        // const columnDefs = (INITIAL_MASTER_HOLD_DETAIL_COL_DEF.slice());

        //gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
        if (!onTableSelectionClicked) {
            gridRef.current?.gridRef.current.api?.deselectAll();
        }
    })

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        masterHoldVM.updateSelectedChgInfoRows(masterHoldChgInfoList, selectedRows);
    }, [masterHoldChgInfoList, masterHoldVM])

    const isDisable = useCallback(() => {
        if (selectedRows && selectedRows.length > 0) return false;
        return true;
    }, [selectedRows])

    const handleRelease = useCallback(async () => {
        if (selectedRows && selectedRows.length > 0) {
            for (let i = 0; i < selectedRows.length; i++) {
                const row = selectedRows[i];

                if (!row.id || row.id === 0) {
                    messageBarVM.showError("Missing primary key for hold!")
                    return
                }

                if (row.dtlState !== "MH" && row.dtlState !== "MT") {
                    messageBarVM.showError("Cannot release the detail state is not 'MH' or 'MT'");
                    return
                }
                row.target = currentSelectedRow.target;
            }
        }

        setLoading(true);
        try {
            await masterHoldVM.onRelease(selectedRows);
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }, [currentSelectedRow.target, masterHoldVM, messageBarVM, selectedRows])

    const handleHold = useCallback(async () => {
        if (selectedRows && selectedRows.length > 0) {
            for (let i = 0; i < selectedRows.length; i++) {
                const row = selectedRows[i];

                if (!row.id || row.id === 0) {
                    messageBarVM.showError("Missing primary key for hold!")
                    return
                }

                if (row.dtlState !== "R" && row.dtlState !== "F" && row.dtlState !== "E") {
                    messageBarVM.showError("Cannot hold the detail state is " + row.dtlState);
                    return
                }
                row.target = currentSelectedRow.target;
            }
        }
        setLoading(true);
        try {
            await masterHoldVM.onHold(selectedRows);
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }, [currentSelectedRow.target, masterHoldVM, messageBarVM, selectedRows])

    const memoTableTitle = useMemo(() =>
        <Sidebarheader style={{ width: '57vw', display: "flex", alignItems: "center" }}>
            <HeaderTitle>{MasterHoldMaintenanceConstant.Detail.CHARGE_INFO}</HeaderTitle>
            <StyledAction>
                {
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <HPHButton label={'Release'} size={'Small'} disabled={isDisable()} theme={'Secondary'} onClick={handleRelease} />
                        <HPHButton label={'Hold'} size={'Small'} disabled={isDisable()} theme={'Secondary'} onClick={handleHold} />
                    </div>
                }
            </StyledAction>
        </Sidebarheader>
        , [handleHold, handleRelease, isDisable])

    const memoMasterHoldChgInfoTable = useMemo(() => {
        return <NbisTable
            id='master-hold-charge-info-table'
            headerLabel={memoTableTitle}
            columns={INITIAL_MASTER_HOLD_DETAIL_COL_DEF.slice()}
            data={transferRowData(masterHoldChgInfoList ?? [])}
            showPaginator={false}
            editable={false}
            showHeaderIcons={true}
            showAddIcon={false}
            showDeleteButton={false}
            showReloadIcon={false}
            isScrollHighlighted={false}
            selectionMode={false}
            rowSelection={"multiple"}
            onSelectionChanged={handleSelectionChange}
            gridHeight="customHeight"
            customHeight="calc(100vh - 125px)"
            ref={gridRef} />;
    }, [memoTableTitle, masterHoldChgInfoList, handleSelectionChange])

    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;
    return <>
        {<TableWrapper>{memoMasterHoldChgInfoTable}</TableWrapper>}
    </>;
}

export default memo(MasterHoldDetailTablePanel);