import { useChangeKeyDataTracked } from "presentation/store/ChangeKeyData/ChangeKeyDataProvider";
import { memo, useMemo } from "react";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import ChangeKeyDataTablePanel from "../Table/ChangeKeyDataTablePanel";
import { ChangeKeyDataChargeInfoTablePanel } from "./ChangeKeyDataChargeInfoTablePanel";
import ChangeKeyDataEditPanel from "./ChangeKeyDataEditPanel";

const ChangeKeyDataRightPanel = () => {
    const [changeKeyDataState] = useChangeKeyDataTracked();
    const { isShowChargeInfoPanel, isShowEditPanel } = changeKeyDataState;

    const memoChangeKeyDataTable = useMemo(() => {
        return <ChangeKeyDataTablePanel />
    }, [])
    const memoChangeKeyDataEditTable = useMemo(() => {
        return <ChangeKeyDataEditPanel />
    }, [])

    const memoChangeKeyDataChargeInfoTablePanel = useMemo(() => {
        return <div style={{ marginLeft: "15px" }}><ChangeKeyDataChargeInfoTablePanel /></div>
    }, [])

    const rightPanel = useMemo(() => {
        if (isShowChargeInfoPanel) {
            return memoChangeKeyDataChargeInfoTablePanel;
        }
        if (isShowEditPanel) {
            return memoChangeKeyDataEditTable;
        }
    }, [isShowChargeInfoPanel, isShowEditPanel, memoChangeKeyDataChargeInfoTablePanel, memoChangeKeyDataEditTable]);

    return <>
        <div className={`main-comp-wrapper${(isShowChargeInfoPanel || isShowEditPanel) ? '' : ' im-hide-side-form-draggable'}`}>

            <SliderPanel
                isOpen={true}
                draggable={true}
                // leftSectionWidth={isShowChargeInfoPanel?"60%":"100%"}
                leftSectionWidth={isShowChargeInfoPanel ? "60%" : (isShowEditPanel ? "80%" : "100%")}
                rightSectionWidth={isShowChargeInfoPanel ? "40%" : (isShowEditPanel ? "20%" : "0%")}
                leftChildren={memoChangeKeyDataTable}
                rightChildren={rightPanel}
            />
        </div>
    </>
}

export default memo(ChangeKeyDataRightPanel);