import { ChargeDetailEntity } from "domain/entity/ChargeDetailMaintenance/ChargeDetailEntity"

export interface ChargeBatchUpdateEntity {
    allFormState?: { [key: string]: string },

    accResumeTerminalInd?: string | null,
    accResumeTerminalActive?: boolean | null,
    accResumeTerminalEnable?: boolean | null,
    reasonCode?: string | null,
    holdInd?: string | null,
    holdActive?: boolean | null,
    holdEnable?: boolean | null,
    billToCompany?: string | null,
    billToCompanyActive?: boolean | null,
    billToCompanyEnable?: boolean | null,
    chargeOnCompany?: string | null,
    chargeOnCompanyActive?: boolean | null,
    chargeOnCompanyEnable?: boolean | null,
    alterableBillToCompany?: string | null,
    alterableBillChgCompanyActive?: boolean | null,
    alterableBillToCompanyEnable?: boolean | null,
    alterableChargeOnCompany?: string | null,
    alterableChargeOnCompanyActive?: boolean | null,
    alterableChargeOnCompanyEnable?: boolean | null,
    poo?: string | null,
    pooActive?: boolean | null,
    pooEnable?: boolean | null,
    pol?: string | null,
    polActive?: boolean | null,
    polEnable?: boolean | null,
    pod?: string | null,
    podActive?: boolean | null,
    podEnable?: boolean | null,
    fd?: string | null,
    fdActive?: boolean | null,
    fdEnable?: boolean | null,
    size?: number | null,
    sizeActive?: boolean | null,
    sizeEnable?: boolean | null,
    status?: string | null,
    statusActive?: boolean | null,
    statusEnable?: boolean | null,
    billingType?: string | null,
    billingTypeActive?: boolean | null,
    billingTypeEnable?: boolean | null,
    emptyInd?: string | null,
    emptyIndActive?: boolean | null,
    emptyIndEnable?: boolean | null,
    rate?: number | null,
    oneOffRateInd?: string | null,
    oneOffRateActive?: boolean | null,
    oneOffRateEnable?: boolean | null,
    oneOffRateReasonCode?: string | null,
    unBundleMasterNoInd?: string | null,
    unBundleMasterNoActive?: boolean | null,
    unBundleMasterNoEnable?: boolean | null,
    operatingTml?: string | null,
    operatingTmlActive?: boolean | null,
    operatingTmlEnable?: boolean | null,
    manualTsLegChargeInd?: string | null,
    manualTsLegChargeIndActive?: boolean | null,
    manualTsLegChargeIndEnable?: boolean | null,
    ignoreCntrStatusValid?: string | null,
    ignoreCntrStatusValidActive?: boolean | null,
    ignoreCntrStatusValidEnable?: boolean | null,
    adjustmentType?: string | null,
    adjustmentActive?: boolean | null,
    adjustmentEnable?: boolean | null,
    adjustmentReasonCode?: string | null
    cntrNo: string | null,
    rfTempUnit?: string | null,
    rfTemp?: number | null,
    rfTempActive?: boolean | null,
    rfTempEnable?: boolean | null,
    ohDimen?: number | null,
    olDimen?: number | null,
    owDimen?: number | null,
    grossWt?: number | null,
    forwarderCode1?: string | null,
    reference?: string | null,
    dischargeDatetime?: Date | null,
    gateOutDatetime?: Date | null,
    deliveryDatetime?: Date | null,
    receiveDatetime?: Date | null,
    gateInDatetime?: Date | null,
    receivalCutoffDatetime?: Date | null,
    loadingDatetime?: Date | null,
    cntrType?: string | null,
    cntrTypeActive?: boolean | null,
    cntrTypeEnable?: boolean | null,
    cntrTspStatus?: string | null,
    imcoCode1?: string | null,
    imcoCode2?: string | null,
    imcoCode3?: string | null,
    dgContent?: string | null,
    dgContentActive?: boolean | null,
    dgContentEnable?: boolean | null,
    duContent?: string | null,
    duContentActive?: boolean | null,
    duContentEnable?: boolean | null,
    specialInd?: string | null,
    specialIndtActive?: boolean | null,
    specialIndEnable?: boolean | null,
    forwarderReference?: string | null,
    forwarderReferencetActive?: boolean | null,
    forwarderReferenceEnable?: boolean | null,
    releaseInd?: string | null,
    releaseActive?: boolean | null,
    releaseEnable?: boolean | null,
    consortiumCode?: string | null,
    vesselCode?: string | null,
    voyageCode?: string | null,
    vesselName?: string | null,
    uptAbsolutelyCommonAcActive?: boolean | null,
    uptAbsolutelyCommonAcEnable?: boolean | null,
    qty?: number | null,
    copyCommonAcInd?: string | null,
    companyCode?: string | null,
    copyCommonAcActive?: boolean | null,
    copyCommonAcEnable?: boolean | null,
    seq?: number | null,
    soNumber?: string | null,
    waiverNumber?: string | null,
    soNumberActive?: boolean | null,
    soNumberEnable?: boolean | null,
    manualAdjChargeQty?: number | null,
    manualAdjChargeQtyUom?: string | null,
    manualAdjActive?: boolean | null,
    manualAdjEnable?: boolean | null,
    cmAcFixQty?: number | null,
    updateVslVoyEnable?: boolean | null,
    seqEnable?: boolean | null,
    assCommonAccEnable?: boolean | null,
    reverseCommonAccEnable?: boolean | null,
    redirectCommonAccEnable?: boolean | null,
    dischargeIndActive?: boolean | null,
    dischargeIndEnable?: boolean | null,
    dischargeInd?: string | null,
    seclegSpecialIndActive?: boolean | null,
    seclegSpecialIndEnable?: boolean | null,
    seclegSpecialInd?: string | null,
    seclegOperatingTmlActive?: boolean | null,
    seclegOperatingTmlEnable?: boolean | null,
    seclegOperatingTml?: string | null,
    seclegDischargeIndActive?: boolean | null,
    seclegDischargeIndEnable?: boolean | null,
    seclegDischargeInd?: string | null,
    status2Active?: boolean | null,
    status2Enable?: boolean | null,
    status2?: string | null,
    cntrDtlEnable?: boolean | null,
    waiverNoActive?: boolean | null,
    waiverNoEnable?: boolean | null,
    permitNoActive?: boolean | null,
    permitNoEnable?: boolean | null,
    permitNo?: string | null,
    rfInfoActive?: boolean | null,
    rfInfoEnable?: boolean | null,
    noOfLift?: number | null,
    createDatetime?: Date | null,
    reqPickupDatetime?: Date | null,
    plugOnDatetime?: Date | null,
    plugOffDatetime?: Date | null,
    consStartDatetime?: Date | null,
    consEndDatetime?: Date | null,
    emptyChargeQtyActive?: boolean | null,
    emptyChargeQtyEnable?: boolean | null,
    empty20Qty?: number | null,
    empty40Qty?: number | null,
    empty45Qty?: number | null,
    empty48Qty?: number | null,
    empty53Qty?: number | null,
    chargeUom?: string | null,
    poolUom?: string | null,
    assParticipationRateEnable?: boolean | null,
    changeActionOption?: string | null,
    changeReceiveDatetime?: Date | null,
    otherLegCharge?: ChargeDetailEntity | null,
    updateStatusType?: string | null,
    subChargeType?: string | null,
    bundleMasterNumber?: string | null,
    bundleMasterVisit?: string | null,
    bundleMasterNoActive?: boolean | null,
    bundleMasterNoEnable?: boolean | null,
    tierFspActive?: boolean | null,
    tierFspEnable?: boolean | null,
    firstTierFsp?: string | null,
    secondTierFsp?: string | null,
    voltageActive?: boolean | null,
    voltageEnable?: boolean | null,
    voltage?: number | null,
    offStdMeasurementModeActive?: boolean | null,
    offStdMeasurementModeEnable?: boolean | null,
    offStdMeasurementMode?: string | null,
    marshallingTypeActive?: boolean | null,
    marshallingTypeEnable?: boolean | null,
    marshallingType?: string | null,
    specialHandlingCodeActive?: boolean | null,
    specialHandlingCodeEnable?: boolean | null,
    specialHandlingCode?: string | null,
    vesselInfoActive?: boolean | null,
    vesselInfoEnable?: boolean | null,
    voyOperatingTml?: string | null,
    voyServiceCode?: string | null,
    firstDischargeDatetime?: Date | null,
    lastDischargeDatetime?: Date | null,
    firstLoadingDatetime?: Date | null,
    lastLoadingDatetime?: Date | null,
    tsVesselInfoActive?: boolean | null,
    tsVesselInfoEnable?: boolean | null,
    otherFirstDischargeDatetime?: Date | null,
    otherLastDischargeDatetime?: Date | null,
    otherDischargeDatetime?: Date | null,
    otherArrDatetime?: Date | null,
    otherDepDatetime?: Date | null,
    accResumeTerminalReasonCode: string | null,

    bulkEditAction: string | null,

    [key: string]: string | number | boolean | null | undefined | {}
}

export const EMPTY_CHARGE_BATCH_UPDATE_ENTITY = {
    accResumeTerminalInd: "",
    accResumeTerminalActive: false,
    accResumeTerminalEnable: false,
    reasonCode: null,
    holdInd: "",
    holdActive: false,
    holdEnable: false,
    billToCompany: null,
    billToCompanyActive: false,
    billToCompanyEnable: false,
    chargeOnCompany: null,
    chargeOnCompanyActive: false,
    chargeOnCompanyEnable: false,
    alterableBillToCompany: null,
    alterableBillToCompanyActive: false,
    alterableBillToCompanyEnable: false,
    alterableChargeOnCompany: null,
    alterableChargeOnCompanyActive: false,
    alterableChargeOnCompanyEnable: false,
    poo: null,
    pooActive: false,
    pooEnable: false,
    pol: null,
    polActive: false,
    polEnable: false,
    pod: null,
    podActive: false,
    podEnable: false,
    fd: null,
    fdActive: false,
    fdEnable: false,
    size: 0,
    sizeActive: false,
    sizeEnable: false,
    status: null,
    statusActive: false,
    statusEnable: false,
    billingType: null,
    billingTypeActive: false,
    billingTypeEnable: false,
    emptyInd: null,
    emptyIndActive: false,
    emptyIndEnable: false,
    rate: null,
    oneOffRateInd: "",
    oneOffRateActive: false,
    oneOffRateEnable: false,
    unBundleMasterNoInd: null,
    unBundleMasterNoActive: false,
    unBundleMasterNoEnable: false,
    oneOffRateReasonCode: null,
    operatingTml: null,
    operatingTmlActive: false,
    operatingTmlEnable: false,
    manualTsLegChargeInd: null,
    manualTsLegChargeIndActive: false,
    manualTsLegChargeIndEnable: false,
    ignoreCntrStatusValid: null,
    ignoreCntrStatusValidActive: false,
    ignoreCntrStatusValidEnable: false,
    adjustmentType: null,
    adjustmentReasonCode: null,
    adjustmentActive: false,
    adjustmentEnable: false,
    cntrNo: null,
    rfTempUnit: null,
    rfTemp: null,
    rfTempActive: false,
    rfTempEnable: false,
    ohDimen: null,
    olDimen: null,
    owDimen: null,
    grossWt: null,
    forwarderCode1: null,
    reference: null,
    dischargeDatetime: null,
    gateOutDatetime: null,
    deliveryDatetime: null,
    receiveDatetime: null,
    gateInDatetime: null,
    receivalCutoffDatetime: null,
    loadingDatetime: null,
    cntrType: null,
    cntrTypeActive: false,
    cntrTypeEnable: false,
    cntrTspStatus: null,
    imcoCode1: null,
    imcoCode2: null,
    imcoCode3: null,
    dgContent: null,
    dgContentActive: false,
    dgContentEnable: false,
    duContent: null,
    duContentActive: false,
    duContentEnable: false,
    specialInd: null,
    specialIndActive: false,
    specialIndEnable: false,
    forwardereference: null,
    forwardereferenceActive: false,
    forwardereferenceEnable: false,
    releaseInd: "",
    releaseActive: false,
    releaseEnable: false,
    consortiumCode: null,
    vesselCode: null,
    voyageCode: null,
    vesselName: null,
    uptAbsolutelyCommonAcActive: false,
    uptAbsolutelyCommonAcEnable: false,
    qty: null,
    copyCommonAcInd: "",
    companyCode: null,
    copyCommonAcActive: false,
    copyCommonAcEnable: false,
    seq: null,
    soNumber: null,
    waiverNumber: null,
    soNumberActive: false,
    soNumberEnable: false,
    manualAdjChargeQty: null,
    manualAdjChargeQtyUom: null,
    manualAdjActive: false,
    manualAdjEnable: false,
    cmAcFixQty: null,
    updateVslVoyEnable: false,
    seqEnable: false,
    assCommonAccEnable: false,
    reverseCommonAccEnable: false,
    redirectCommonAccEnable: false,
    dischargeIndActive: false,
    dischargeIndEnable: false,
    dischargeInd: null,
    seclegSpecialIndActive: false,
    seclegSpecialIndEnable: false,
    seclegSpecialInd: null,
    seclegOperatingTmlActive: false,
    seclegOperatingTmlEnable: false,
    seclegOperatingTml: null,
    seclegDischargeIndActive: false,
    seclegDischargeIndEnable: false,
    seclegDischargeInd: null,
    status2Active: false,
    status2Enable: false,
    status2: null,
    cntrDtlEnable: false,
    waiverNoActive: false,
    waiverNoEnable: false,
    permitNoActive: false,
    permitNoEnable: false,
    permitNo: null,
    rfInfoActive: false,
    rfInfoEnbale: false,
    noOfLift: null,
    createDateTime: null,
    plugOnDatetime: null,
    plugOffDatetime: null,
    consStartDatetime: null,
    consEndDatetime: null,
    emptyChargeQtyActive: false,
    emptyChargeQtyEnable: false,
    empty20Qty: null,
    empty40Qty: null,
    empty45Qty: null,
    empty48Qty: null,
    empty53Qty: null,
    chargeUom: null,
    poolUom: null,
    assParticipationRateEnable: false,
    bulkEditAction: "",
    changeActionOption: null,
    changeReceiveDatetime: null,
    otherLegCharge: null,
    updateStatusType: "",
    subChargeType: "",
    bundleMasterNumber: null,
    bundleMasterVisit: null,
    bundleMasterNoActive: false,
    bundleMasterNoEnable: false,
    tierFspActive: false,
    tierFspEnable: false,
    voltageActive: false,
    voltageEnable: false,
    voltage: null,
    offStdMeasurementModeActive: false,
    offStdMeasurementModeEnable: false,
    offStdMeasurementMode: null,
    marshallingTypeActive: false,
    marshallingTypeEnable: false,
    marshallingType: null,
    specialHandlingCodeActive: false,
    specialHandlingCodeEnable: false,
    specialHandlingCode: null,
    vesselInfoActive: false,
    vesselInfoEnable: false,
    voyOperatingTml: null,
    voyServiceCode: null,
    firstDischargeDatetime: null,
    lastDischargeDatetime: null,
    firstLoadingDatetime: null,
    lastLoadingDatetime: null,
    tsVesselInfoActive: false,
    tsVesselInfoEnable: false,
    otherFirstDischargeDatetime: null,
    otherLastDischargeDatetime: null,
    otherDischargeDatetime: null,
    otherArrDatetime: null,
    otherDepDatetime: null,
    accResumeTerminalReasonCode: null,
}

export const EMPTY_CNTR_INFO_CRITERIA: ChargeBatchUpdateEntity = {
    cntrNo: null,
    rfTempUnit: null,
    rfTemp: null,
    ohDimen: null,
    olDimen: null,
    owDimen: null,
    cntrType: null,
    cntrTspStatus: null,
    imcoCode1: null,
    imcoCode2: null,
    imcoCode3: null,
    bulkEditAction: "",
    accResumeTerminalReasonCode: null,
}