import { docTypeDropdownOption, vatPaymentReferenceDateDropdownOption } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { TaxMappingConstant, taxMappingRequiredFieldList } from "presentation/constant/TaxMapping/TaxMappingConstant";
import { useTaxMappingVM } from "presentation/hook/TaxMapping/useTaxMappingVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useTaxMappingTracked } from "presentation/store/TaxMapping/TaxMappingProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DatePickerComponent from "presentation/view/components/DatePickerComponent";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { NbisIconButton } from "presentation/view/components/NbisIconButton";
import NumberInputComponent from "presentation/view/components/NumberInputComponent";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { FieldType, HPHBreadcrumb, HPHCheckbox, IconButton, IFieldValue, Loader } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, SidebarCaption, Sidebarheader, SidebarTitle, StyledAction, StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const TaxMappingEditPanel = () => {
    const TAX_MAPPING_CONSTANT = TaxMappingConstant.Table;
    const [isLoading, setIsLoading] = useState(false);
    const [taxMappingState] = useTaxMappingTracked();
    const taxMappingVM = useTaxMappingVM();
    const { currentEditRow, isAdd, isEdit } = taxMappingState;
    const messageBarVM = useMessageBarVM();


    const handleSave = useCallback(async () => {
        if (currentEditRow.percentage === null) {
            messageBarVM.showError("Tax % is mandatory.");
            return;
        }
        if (!currentEditRow.effectiveDate) {
            messageBarVM.showError("Effective Date is mandatory.");
            return;
        }
        if (currentEditRow.paymentTerm === null) {
            messageBarVM.showError("Tax Payment Term(Invoice) is mandatory.");
            return;
        }
        if (currentEditRow.paymentTermCredit === null) {
            messageBarVM.showError("Tax Payment Term(Credit) is mandatory.");
            return;
        }
        if (currentEditRow.paymentTermDebit === null) {
            messageBarVM.showError("Tax Payment Term(Debit) is mandatory.");
            return;
        }
        if (!currentEditRow.statementCode) {
            messageBarVM.showError("Article Statement Code is mandatory.");
            return;
        }
        if (!currentEditRow.paymentReferenceDate) {
            messageBarVM.showError("Tax Payment Reference Date(Invoice) is mandatory.");
            return;
        }
        if (!currentEditRow.paymentReferenceDateCredit) {
            messageBarVM.showError("Tax Payment Reference Date(Credit) is mandatory.");
            return;
        }
        if (!currentEditRow.paymentReferenceDateDebit) {
            messageBarVM.showError("Tax Payment Reference Date(Debit) is mandatory.");
            return;
        }
        if (!currentEditRow.tariffType) {
            messageBarVM.showError("Tax Tariff Type is mandatory.");
            return;
        }
        if (!currentEditRow.tariffCode) {
            messageBarVM.showError("Tax Tariff Code is mandatory.");
            return;
        }
        if (!currentEditRow.activeInd) {
            messageBarVM.showError("Active Ind is mandatory.");
            return;
        }
        setIsLoading(true);
        const data = await taxMappingVM.onSave(currentEditRow);
        if (data && data.success) {
            messageBarVM.showSuccess("Save successful.");
            taxMappingVM.searchAllTaxMappingList().then((data) => {
                setIsLoading(false)
            }).catch(error => {
                setIsLoading(false)
            });
        } else {
            setIsLoading(false)
            messageBarVM.showError(data.data);
            return;
        }
    }, [taxMappingVM, currentEditRow, messageBarVM]);

    const handClose = useCallback(() => {
        taxMappingVM.onCloseEidtPanel();
    }, [taxMappingVM]);

    const onNumberFieldChange = useCallback((e: any, fieldName: string, fieldValue: any) => {
        taxMappingVM.onHeaderFieldChange(fieldName, fieldValue);
    }, [taxMappingVM]);

    useEffect(() => {
        const refreshTariffDesc = async () => {
            taxMappingVM.onTariffCodeItemChanged(currentEditRow.chargeTariffType ?? "", currentEditRow.chargeTariffCode ?? "", 'chargeTariffCodeDesc');
        }
        if ((isAdd || isEdit) && currentEditRow.chargeTariffType && currentEditRow.chargeTariffCode) {
            refreshTariffDesc();
        }
    }, [currentEditRow.chargeTariffType, currentEditRow.chargeTariffCode, taxMappingVM, isAdd, isEdit])

    useEffect(() => {
        const refreshTariffDesc = async () => {
            taxMappingVM.onTariffCodeItemChanged(currentEditRow.tariffType ?? "", currentEditRow.tariffCode ?? "", 'vatTariffCodeDesc');
        }
        if ((isAdd || isEdit) && currentEditRow.tariffType && currentEditRow.tariffCode) {
            refreshTariffDesc();
        }
    }, [currentEditRow.tariffType, currentEditRow.tariffCode, taxMappingVM, isAdd, isEdit])

    useEffect(() => {
        const refreshTransactionType = async () => {
            ((isAdd || isEdit) && currentEditRow.taxCode) && taxMappingVM.onTaxCodeItemChanged(currentEditRow.taxCode, 'transactionType');
        }
        refreshTransactionType();
    }, [currentEditRow.taxCode, isAdd, isEdit, taxMappingVM])

    useEffect(() => {
        const refreshVatArticleStatement = async () => {
            ((isAdd || isEdit) && currentEditRow.statementCode) && taxMappingVM.onArticleStatementCodeItemChanged(currentEditRow.statementCode, 'articleStatement');
        }
        refreshVatArticleStatement();
    }, [currentEditRow.statementCode, isAdd, isEdit, taxMappingVM])

    useEffect(() => {
        const refreshDefaultValue = async () => {
            taxMappingVM.onRefreshDefaultValue();
        }
        isAdd && refreshDefaultValue();
    }, [isAdd, taxMappingVM])


    const memoSubChgTypeOptions = useMemo(() => {
        const subChgTypeOptions = currentEditRow.chargeType ? taxMappingState.dynamicOptions.subChargeTypeDropdownOptions[currentEditRow.chargeType] : [];
        return subChgTypeOptions;
    }, [currentEditRow.chargeType, taxMappingState.dynamicOptions.subChargeTypeDropdownOptions])

    const memoTariffCodeOptions = useMemo(() => {
        return currentEditRow.tariffType
            ? taxMappingState.dynamicOptions.tariffCodeDropdownOptions[currentEditRow.tariffType]
            : [];
    }, [currentEditRow.tariffType, taxMappingState.dynamicOptions.tariffCodeDropdownOptions]);

    const memochargeTariffCodeOptions = useMemo(() => {
        return currentEditRow.chargeTariffType
            ? taxMappingState.dynamicOptions.chargeTariffCodeDropdownOptions[currentEditRow.chargeTariffType]
            : [];
    }, [currentEditRow.chargeTariffType, taxMappingState.dynamicOptions.chargeTariffCodeDropdownOptions]);

    const memoTariffType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "350px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={false}
                readOnlyValue={currentEditRow?.tariffType || ''}
                fieldValue={currentEditRow?.tariffType}
                fieldLabel={TAX_MAPPING_CONSTANT.TAX_TARIFF_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'tariffType'}
                options={taxMappingState.dynamicOptions.tariffTypeDropdownOptions}
                maxLength={60}
                requiredFieldList={taxMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    taxMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [isAdd, isEdit, currentEditRow?.tariffType, TAX_MAPPING_CONSTANT.TAX_TARIFF_TYPE, taxMappingState.dynamicOptions.tariffTypeDropdownOptions, taxMappingVM])

    const memoTariffCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "350px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={false}
                readOnlyValue={currentEditRow?.tariffCode || ''}
                fieldValue={currentEditRow?.tariffCode}
                fieldLabel={TAX_MAPPING_CONSTANT.TAX_TARIFF_CODE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'tariffCode'}
                options={memoTariffCodeOptions}
                maxLength={60}
                requiredFieldList={taxMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    taxMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [isAdd, isEdit, currentEditRow?.tariffCode, TAX_MAPPING_CONSTANT.TAX_TARIFF_CODE, memoTariffCodeOptions, taxMappingVM])

    const memoChargeTariffType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "350px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.chargeTariffType || ''}
                fieldValue={currentEditRow?.chargeTariffType}
                fieldLabel={TAX_MAPPING_CONSTANT.CHARGE_TAR_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'chargeTariffType'}
                options={taxMappingState.dynamicOptions.chargeTariffTypeDropdownOptions}
                maxLength={60}
                requiredFieldList={taxMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    taxMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [isAdd, isEdit, currentEditRow?.chargeTariffType, TAX_MAPPING_CONSTANT.CHARGE_TAR_TYPE, taxMappingState.dynamicOptions.chargeTariffTypeDropdownOptions, taxMappingVM])

    const memoChargeTariffCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "350px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.chargeTariffCode || ''}
                fieldValue={currentEditRow?.chargeTariffCode}
                fieldLabel={TAX_MAPPING_CONSTANT.CHARGE_TAR_CODE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'chargeTariffCode'}
                options={memochargeTariffCodeOptions}
                maxLength={60}
                requiredFieldList={taxMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    taxMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [isAdd, isEdit, currentEditRow?.chargeTariffCode, TAX_MAPPING_CONSTANT.CHARGE_TAR_CODE, memochargeTariffCodeOptions, taxMappingVM])

    const memoChargeType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "350px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.chargeType || ''}
                fieldValue={currentEditRow?.chargeType}
                fieldLabel={TAX_MAPPING_CONSTANT.CHARGE_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'chargeType'}
                maxLength={60}
                options={taxMappingState.dynamicOptions.chargeTypeDropdownOptions}
                requiredFieldList={taxMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    taxMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TAX_MAPPING_CONSTANT.CHARGE_TYPE, taxMappingState.dynamicOptions.chargeTypeDropdownOptions, taxMappingVM, currentEditRow?.chargeType, isAdd, isEdit])

    const memoSubChargeType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "350px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.subChargeType || ''}
                fieldValue={currentEditRow?.subChargeType}
                fieldLabel={TAX_MAPPING_CONSTANT.SUB_CHARGE_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'subChargeType'}
                maxLength={60}
                options={memoSubChgTypeOptions}
                requiredFieldList={taxMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    taxMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TAX_MAPPING_CONSTANT.SUB_CHARGE_TYPE, taxMappingVM, currentEditRow?.subChargeType, isAdd, isEdit, memoSubChgTypeOptions])

    const memoBillToCompany = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "350px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.billToCompanyCode || ''}
                fieldValue={currentEditRow?.billToCompanyCode}
                fieldLabel={TAX_MAPPING_CONSTANT.BILL_TO_COMPANY}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'billToCompanyCode'}
                maxLength={60}
                sort={false}
                options={taxMappingState.dynamicOptions.companyCodeDropdownOptions}
                requiredFieldList={taxMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    taxMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [isAdd, isEdit, currentEditRow?.billToCompanyCode, TAX_MAPPING_CONSTANT.BILL_TO_COMPANY, taxMappingState.dynamicOptions.companyCodeDropdownOptions, taxMappingVM])

    const memoServiceCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "350px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.serviceCode || ''}
                fieldValue={currentEditRow?.serviceCode}
                fieldLabel={TAX_MAPPING_CONSTANT.SERVICE_CODE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'serviceCode'}
                maxLength={60}
                options={taxMappingState.dynamicOptions.serviceCodeDropdownOptions}
                requiredFieldList={taxMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    taxMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TAX_MAPPING_CONSTANT.SERVICE_CODE, taxMappingState.dynamicOptions.serviceCodeDropdownOptions, taxMappingVM, currentEditRow?.serviceCode, isAdd, isEdit])

    const memoPercentage = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "350px", marginBottom: "20px" }}>
            <NumberInputComponent
                label={TAX_MAPPING_CONSTANT.TAX}
                maxLength={60}
                fieldName={"percentage"}
                value={currentEditRow?.percentage ?? ''}
                errorMessage={''}
                disabled={!(isAdd || isEdit)}
                decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
        </div>
        , [TAX_MAPPING_CONSTANT.TAX, currentEditRow?.percentage, isAdd, isEdit, onNumberFieldChange])

    const memoPaymentTerm = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "350px", marginBottom: "20px" }}>
            <NumberInputComponent
                label={TAX_MAPPING_CONSTANT.TAX_PAYMENT_TERMINVOICE}
                maxLength={60}
                fieldName={"paymentTerm"}
                value={currentEditRow?.paymentTerm ?? ''}
                errorMessage={''}
                disabled={!(isAdd || isEdit)}
                decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
        </div>
        , [TAX_MAPPING_CONSTANT.TAX_PAYMENT_TERMINVOICE, currentEditRow?.paymentTerm, isAdd, isEdit, onNumberFieldChange])

    const memoPaymentTermCredit = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "350px", marginBottom: "20px" }}>
            <NumberInputComponent
                label={TAX_MAPPING_CONSTANT.TAX_PAYMENT_TERMCREDIT}
                maxLength={60}
                fieldName={"paymentTermCredit"}
                value={currentEditRow?.paymentTermCredit ?? ''}
                errorMessage={''}
                disabled={!(isAdd || isEdit)}
                decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
        </div>
        , [TAX_MAPPING_CONSTANT.TAX_PAYMENT_TERMCREDIT, currentEditRow?.paymentTermCredit, isAdd, isEdit, onNumberFieldChange])

    const memoPaymentTermDebit = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "350px", marginBottom: "20px" }}>
            <NumberInputComponent
                label={TAX_MAPPING_CONSTANT.TAX_PAYMENT_TERMDEBIT}
                maxLength={60}
                fieldName={"paymentTermDebit"}
                value={currentEditRow?.paymentTermDebit ?? ''}
                errorMessage={''}
                disabled={!(isAdd || isEdit)}
                decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
        </div>
        , [TAX_MAPPING_CONSTANT.TAX_PAYMENT_TERMDEBIT, currentEditRow?.paymentTermDebit, isAdd, isEdit, onNumberFieldChange])

    const memoEffectiveDate = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "350px", marginBottom: "20px" }}>
            <DatePickerComponent
                disabled={!(isAdd || isEdit)}
                label={TAX_MAPPING_CONSTANT.EFFECTIVE_DATE}
                width="350px"
                date={currentEditRow?.effectiveDate}
                fieldName="effectiveDate"
                errorMessage={""}
                onDateChange={taxMappingVM.onHeaderFieldChange} />
        </div>
        , [TAX_MAPPING_CONSTANT.EFFECTIVE_DATE, currentEditRow?.effectiveDate, isAdd, isEdit, taxMappingVM.onHeaderFieldChange])

    const memoConsortiumCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "350px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.consortiumCode || ''}
                fieldValue={currentEditRow?.consortiumCode}
                fieldLabel={TAX_MAPPING_CONSTANT.CONSORTIUM_CODE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'consortiumCode'}
                maxLength={60}
                options={taxMappingState.dynamicOptions.consortiumCodeDropdownOptions}
                requiredFieldList={taxMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    taxMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TAX_MAPPING_CONSTANT.CONSORTIUM_CODE, currentEditRow?.consortiumCode, isAdd, isEdit, taxMappingState.dynamicOptions.consortiumCodeDropdownOptions, taxMappingVM])

    const memoVatArticleStatement = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "350px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.articleStatement || ''}
                fieldValue={currentEditRow?.articleStatement}
                allValues={currentEditRow?.articleStatement ?? ""}
                fieldLabel={TAX_MAPPING_CONSTANT.ARTICLE_STATEMENT}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'articleStatement'}
                maxLength={60}
                requiredFieldList={taxMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    taxMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TAX_MAPPING_CONSTANT.ARTICLE_STATEMENT, currentEditRow?.articleStatement, taxMappingVM])

    const memoTransactionType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "350px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.transactionType || ''}
                fieldValue={currentEditRow?.transactionType}
                fieldLabel={TAX_MAPPING_CONSTANT.TRANSACTION_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'transactionType'}
                maxLength={60}
                requiredFieldList={taxMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    taxMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TAX_MAPPING_CONSTANT.TRANSACTION_TYPE, currentEditRow?.transactionType, taxMappingVM])

    const memoVatTariffCodeDesc = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "350px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.vatTariffCodeDesc || ''}
                fieldValue={currentEditRow?.vatTariffCodeDesc}
                fieldLabel={TAX_MAPPING_CONSTANT.TAX_TARIFF_CODE_DESC}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'vatTariffCodeDesc'}
                maxLength={60}
                requiredFieldList={taxMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    taxMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TAX_MAPPING_CONSTANT.TAX_TARIFF_CODE_DESC, currentEditRow?.vatTariffCodeDesc, taxMappingVM])

    const memoChargeTariffCodeDesc = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "350px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.chargeTariffCodeDesc || ''}
                fieldValue={currentEditRow?.chargeTariffCodeDesc}
                fieldLabel={TAX_MAPPING_CONSTANT.CHARGE_TAR_CODE_DESC}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'chargeTariffCodeDesc'}
                maxLength={60}
                requiredFieldList={taxMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    taxMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TAX_MAPPING_CONSTANT.CHARGE_TAR_CODE_DESC, currentEditRow?.chargeTariffCodeDesc, taxMappingVM])

    const memoCountry = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "350px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.country || ''}
                fieldValue={currentEditRow?.country}
                fieldLabel={TAX_MAPPING_CONSTANT.COUNTRY}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'country'}
                maxLength={60}
                options={taxMappingState.dynamicOptions.countryDropdownOptions}
                requiredFieldList={taxMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    taxMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TAX_MAPPING_CONSTANT.COUNTRY, currentEditRow?.country, isAdd, isEdit, taxMappingState.dynamicOptions.countryDropdownOptions, taxMappingVM])

    const memoDocType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "350px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.docType || ''}
                fieldValue={currentEditRow?.docType}
                fieldLabel={TAX_MAPPING_CONSTANT.DOC_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'docType'}
                maxLength={60}
                options={docTypeDropdownOption}
                requiredFieldList={taxMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    taxMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TAX_MAPPING_CONSTANT.DOC_TYPE, currentEditRow?.docType, isAdd, isEdit, taxMappingVM])

    const memoTaxCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "350px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.taxCode || ''}
                fieldValue={currentEditRow?.taxCode}
                fieldLabel={TAX_MAPPING_CONSTANT.TAX_CODE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'taxCode'}
                maxLength={60}
                options={taxMappingState.dynamicOptions.taxCodeDropdownOptions}
                requiredFieldList={taxMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    taxMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TAX_MAPPING_CONSTANT.TAX_CODE, currentEditRow?.taxCode, isAdd, isEdit, taxMappingState.dynamicOptions.taxCodeDropdownOptions, taxMappingVM])

    const memoVatArticleStatementCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "350px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.statementCode || ''}
                fieldValue={currentEditRow?.statementCode}
                fieldLabel={TAX_MAPPING_CONSTANT.ARTICLE_STATEMENT_CODE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'statementCode'}
                maxLength={60}
                options={taxMappingState.dynamicOptions.vatArticleStatementCodeDropdownOptions}
                requiredFieldList={taxMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    taxMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TAX_MAPPING_CONSTANT.ARTICLE_STATEMENT_CODE, currentEditRow?.statementCode, isAdd, isEdit, taxMappingState.dynamicOptions.vatArticleStatementCodeDropdownOptions, taxMappingVM])

    const memoPaymentReferenceDate = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "350px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.paymentReferenceDate || ''}
                fieldValue={currentEditRow?.paymentReferenceDate}
                fieldLabel={TAX_MAPPING_CONSTANT.TAX_PAYMENT_REFERENCE_DATEINVOICE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'paymentReferenceDate'}
                maxLength={60}
                options={vatPaymentReferenceDateDropdownOption}
                requiredFieldList={taxMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    taxMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TAX_MAPPING_CONSTANT.TAX_PAYMENT_REFERENCE_DATEINVOICE, currentEditRow?.paymentReferenceDate, isAdd, isEdit, taxMappingVM])

    const memoPaymentReferenceDateCredit = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "350px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.paymentReferenceDateCredit || ''}
                fieldValue={currentEditRow?.paymentReferenceDateCredit}
                fieldLabel={TAX_MAPPING_CONSTANT.TAX_PAYMENT_REFERENCE_DATECREDIT}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'paymentReferenceDateCredit'}
                maxLength={60}
                options={vatPaymentReferenceDateDropdownOption}
                requiredFieldList={taxMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    taxMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TAX_MAPPING_CONSTANT.TAX_PAYMENT_REFERENCE_DATECREDIT, currentEditRow?.paymentReferenceDateCredit, isAdd, isEdit, taxMappingVM])

    const memoPaymentReferenceDateDebit = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "350px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.paymentReferenceDateDebit || ''}
                fieldValue={currentEditRow?.paymentReferenceDateDebit}
                fieldLabel={TAX_MAPPING_CONSTANT.TAX_PAYMENT_REFERENCE_DATEDEBIT}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'paymentReferenceDateDebit'}
                maxLength={60}
                options={vatPaymentReferenceDateDropdownOption}
                requiredFieldList={taxMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    taxMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TAX_MAPPING_CONSTANT.TAX_PAYMENT_REFERENCE_DATEDEBIT, currentEditRow?.paymentReferenceDateDebit, isAdd, isEdit, taxMappingVM])

    const memoRegion = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "350px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.region || ''}
                fieldValue={currentEditRow?.region}
                fieldLabel={TAX_MAPPING_CONSTANT.REGION}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'region'}
                maxLength={60}
                options={taxMappingState.dynamicOptions.regionDropdownOptions}
                requiredFieldList={taxMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    taxMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TAX_MAPPING_CONSTANT.REGION, currentEditRow?.region, isAdd, isEdit, taxMappingState.dynamicOptions.regionDropdownOptions, taxMappingVM])

    const memoGroupId = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "350px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.groupId || ''}
                fieldValue={currentEditRow?.groupId}
                fieldLabel={TAX_MAPPING_CONSTANT.GROUP_ID}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'groupId'}
                maxLength={60}
                options={taxMappingState.dynamicOptions.groupDropdownOptions}
                requiredFieldList={taxMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    taxMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TAX_MAPPING_CONSTANT.GROUP_ID, currentEditRow?.groupId, isAdd, isEdit, taxMappingState.dynamicOptions.groupDropdownOptions, taxMappingVM])


    const memoActiveInd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "350px", marginBottom: "20px" }}>
            <NbisCommonField
                fieldKey={"activeInd"}
                requiredFieldList={[]}
                fieldLabel={TAX_MAPPING_CONSTANT.ACTIVE_IND}
                fieldType={FieldType.TEXT}
                isReadOnly={true}
                onFieldChange={() => { }} />
            <HPHCheckbox
                label={""}
                checked={currentEditRow?.activeInd === 'Y'}
                disabled={!(isAdd || isEdit)}
                onChange={(e) => taxMappingVM.onCheckboxChange(e.checked, "activeInd")}
            />
        </div>
        , [TAX_MAPPING_CONSTANT.ACTIVE_IND, taxMappingVM, currentEditRow?.activeInd, isAdd, isEdit])

    const handleEdit = useCallback(() => {
        taxMappingVM.onEditClick();
    }, [taxMappingVM]);

    const handleReset = useCallback(() => {
        taxMappingVM.onResetClick(isAdd);
    }, [taxMappingVM, isAdd]);

    return <>
        <StyledSidebar style={{ width: '100%', height: "100%", border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'>
            <Sidebarheader style={{ width: '100%' }}>
                {isLoading && <Loader Indicator="Spinner" size="Medium" />}
                <Breadcrumb>
                    <HPHBreadcrumb breadcrumbData={[{ title: TaxMappingConstant.Title.CATEGORY },
                    { title: TaxMappingConstant.Title.TITLE }]} onCurrentClick={() => { }}>
                    </HPHBreadcrumb>
                </Breadcrumb>
                <StyledAction>
                    <>
                        {!(isAdd || isEdit) && <NbisIconButton fileName='Icon-pen' size='medium' disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Edit'} onClick={handleEdit} />}
                        {(isAdd || isEdit) && <IconButton fileName="Icon-reset" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Reset'} onClick={() => handleReset()} />}
                        <IconButton fileName="Icon-cross" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement='bottom' toolTipText={'Close'} onClick={() => handClose()} />
                        {(isAdd || isEdit) && <IconButton fileName="Icon-tick" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Save'} onClick={() => handleSave()} />}
                    </>
                </StyledAction>
            </Sidebarheader>

            <div className={'add-edit-form'} style={{ height: 'calc(100vh - 125px)', maxHeight: 'calc(100vh - 125px)', overflow: 'auto' }}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        <div className={'im-flex-row'}>
                            <div className={'flex-row-item-stretch'}>
                                <SidebarTitle>
                                    {isAdd && "New Tax"}
                                    {!isAdd && (currentEditRow.priority)}
                                </SidebarTitle>
                                {<SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>}
                            </div>
                        </div>
                        <br />
                        <CriteriaItemContainer>
                            {memoPercentage}
                            {memoPaymentTerm}
                            {memoPaymentTermCredit}
                            {memoPaymentTermDebit}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoEffectiveDate}
                            {memoTaxCode}
                            {memoTransactionType}
                            {memoActiveInd}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoBillToCompany}
                            {memoChargeType}
                            {memoSubChargeType}
                            {memoDocType}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoConsortiumCode}
                            {memoServiceCode}
                            {memoCountry}
                            {memoVatArticleStatementCode}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoRegion}
                            {memoChargeTariffType}
                            {memoChargeTariffCode}
                            {memoChargeTariffCodeDesc}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoPaymentReferenceDate}
                            {memoPaymentReferenceDateCredit}
                            {memoPaymentReferenceDateDebit}
                            {memoVatArticleStatement}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoGroupId}
                            {memoTariffType}
                            {memoTariffCode}
                            {memoVatTariffCodeDesc}
                        </CriteriaItemContainer>
                    </div>
                </div>
            </div>
        </StyledSidebar>
    </>
}

export default memo(TaxMappingEditPanel);
