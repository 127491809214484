import { RequisitionFormEntity } from "domain/entity/RequisitionForm/RequisitionFormEntity";
import { axiosGetData, axiosPostData, axiosPutData } from "../axios/AxiosBasicImpl";
import chargeAxiosInstance from "../axios/chargeAxiosInstance";
import { RequisitionFormRepository } from "./RequisitionFormRepo";
import { RequisitionFormSearchCriteria } from "domain/entity/RequisitionForm/RequisitionFormSearchCriteria";
import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { RequisitionFormPrintCriteria } from "domain/entity/RequisitionForm/RequisitionFormPrintCriteria";

export const RequisitionFormRepoImpl = ():RequisitionFormRepository => {
    const url = '/v1/getRequisitionFormForComboBox'
    const getRequisitionFormForComboBox = async(currencyCode: string) : Promise<RequisitionFormEntity[]> => {
        return axiosGetData(chargeAxiosInstance, `${url}/${currencyCode}`,[]).then(res => {
            return res.data;
        }).catch(err => {
            return [];
        })
    }

    const searchRequisitionForm = async(searchCriteria: RequisitionFormSearchCriteria) : Promise<RequisitionFormEntity[]> => {
        const newUrl = '/v1/requisitionForm/search'
        return axiosPostData(chargeAxiosInstance, `${newUrl}`, searchCriteria).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }

    const deleteReqFormEntity= async(reqFormIds: number[]): Promise<ResponseEntity> => {
        const newUrl = '/v1/requisitionForm/delete'
        return await axiosPostData(chargeAxiosInstance, `${newUrl}`, reqFormIds);
    }

    const getReqFormByReqFormNo = async(reqFormNo: string) : Promise<RequisitionFormEntity> => {
        const newUrl = '/v1/requisitionForm/search'
        return axiosGetData(chargeAxiosInstance, `${newUrl}/${reqFormNo}`,[]).then(res => {
            return res.data;
        }).catch(err => {
            return [];
        })
    }

    const addReqFormEntity= async(newData: RequisitionFormEntity): Promise<ResponseEntity> => {
        const newUrl = '/v1/requisitionForm/add'
        return await axiosPostData(chargeAxiosInstance, `${newUrl}`, newData);
    }

    const updateReqFormEntity = async(updatedData: RequisitionFormEntity): Promise<ResponseEntity> => {
        const newUrl = '/v1/requisitionForm/update'
        return await axiosPutData(chargeAxiosInstance, `${newUrl}`, updatedData);
    }
    
    const reprintReport= async(printCriteria: RequisitionFormPrintCriteria): Promise<ResponseEntity> => {
        const newUrl = '/v1/requisitionForm/reprintReport'
        return await axiosPostData(chargeAxiosInstance, `${newUrl}`, printCriteria);
    }

    const previewReport = async (printCriteria: RequisitionFormPrintCriteria): Promise<any> => {
        const newUrl = "/v1/requisitionForm/previewReport";

        return axiosPostData(chargeAxiosInstance, `${newUrl}`, printCriteria).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }

    return{
        getRequisitionFormForComboBox: getRequisitionFormForComboBox,
        searchRequisitionForm: searchRequisitionForm,
        deleteReqFormEntity: deleteReqFormEntity,
        getReqFormByReqFormNo: getReqFormByReqFormNo,
        updateReqFormEntity: updateReqFormEntity,
        addReqFormEntity: addReqFormEntity,
        reprintReport: reprintReport,
        previewReport: previewReport
    }
}