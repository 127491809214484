import { CreditNoteHeaderSearchCriteria, EMPTY_CREDIT_NOTE_HEADER_SEARCH_CRITERIA } from "domain/entity/CreditNote/CreditNoteHeaderSearchCriteria";
import moment from "moment";
import { useCreditNoteHeaderVM } from "presentation/hook/CreditNote/useCreditNoteHeaderVM";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useCreditNoteHeaderMaintenanceTracked } from "presentation/store/CreditNote/CreditNoteHeaderMaintenanceProvider";
import { memo, useCallback, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import CreditNoteHeaderTitleBar from "./CreditNoteHeaderTitleBar";
import CreditNoteHeaderSearchPanel from "./Header/LeftPanel/CreditNoteHeaderSearchPanel";
import CreditNoteHeaderTablePanel from "./Header/Table/CreditNoteHeaderTablePanel";
import { DEFAULT_DATE_RANGE } from "domain/repository/apiUtil";
import { customEventListener, E_Custom_Dispatch_Event, E_Type_Of_Event } from "helpers/Events";
import { RequisitionFormEntity } from "domain/entity/RequisitionForm/RequisitionFormEntity";

const CreditNoteHeaderMaintenance: React.FC = () => {
    const [ creditNoteHeaderState ] = useCreditNoteHeaderMaintenanceTracked();
    const { isShowCriteriaPanel,isAllowAutoSearch,isBackFromDetail,searchCriteria } = creditNoteHeaderState;
    const creditNoteHeaderVM = useCreditNoteHeaderVM();
    const [isLoading, setIsLoading] = useState(false);
    const [anaInfoState] = useANAInfoTracked();
    
    const initialScreen = useCallback(async() => {            
            setIsLoading(true);
            try {
                const results = await Promise.allSettled([
                    creditNoteHeaderVM.loadDropdownOption(),
                ]);
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {
    
                      } else if (index === 1 && result.status === 'rejected') {
    
                      }
                });                
            } catch(error) {
            } finally {
                setIsLoading(false);
            }
        }, [creditNoteHeaderVM]);

    const initialRedirectScreen = useCallback(async(reqFormEty: RequisitionFormEntity) => {
            if (!reqFormEty) return;
            setIsLoading(true);
    
            let searchCriteria: CreditNoteHeaderSearchCriteria = {...EMPTY_CREDIT_NOTE_HEADER_SEARCH_CRITERIA, 
                reqNoFrom:reqFormEty.reqFormNo, reqNoTo:reqFormEty.reqFormNo};
            await Promise.allSettled([                
                creditNoteHeaderVM.loadDropdownOption(),
                creditNoteHeaderVM.getCreditNoteHeaders({...searchCriteria}).then((data) => {            
                    setIsLoading(false)
                }).catch(error => {            
                    setIsLoading(false)
                }).finally(() => {                        
                    localStorage.removeItem(E_Custom_Dispatch_Event.REDIRECT_CREDIT_NOTE_HEADER_MAINTENANCE);
                })
            ]).catch(error => {            
                setIsLoading(false);
                //setInitial(false);            
                localStorage.removeItem(E_Custom_Dispatch_Event.REDIRECT_CREDIT_NOTE_HEADER_MAINTENANCE);
            }).finally(() => {                        
                setIsLoading(false);
            });
            
            
        }, [creditNoteHeaderVM])
    
        useEffect(() => {
            const onCreditNoteMaintRedirect = async(e:any) => {
                switch (e.detail.action) {
                    case E_Custom_Dispatch_Event.REDIRECT_CREDIT_NOTE_HEADER_MAINTENANCE:                    
                        const searchCriteria = (e.detail.data.data as RequisitionFormEntity);
                        localStorage.setItem(E_Custom_Dispatch_Event.REDIRECT_CREDIT_NOTE_HEADER_MAINTENANCE, JSON.stringify(searchCriteria));
                        //setInitial(true);
    
                        break;
                }
            }
    
            customEventListener(E_Type_Of_Event.CREDIT_NOTE_MAINT_REDIRECT_EVENT_INITIAL, onCreditNoteMaintRedirect);
        }, []);
    
        useEffect(() => {
            const tempSearchCriteria = localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CREDIT_NOTE_HEADER_MAINTENANCE)?
                JSON.parse(localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CREDIT_NOTE_HEADER_MAINTENANCE)??"") as RequisitionFormEntity :
                null;
            
            if (!tempSearchCriteria) {
                isAllowAutoSearch && initialScreen().then((data) => {  
                    let newSearchCriteria = null;
                        if(isBackFromDetail){
                            newSearchCriteria = {...searchCriteria};
                        }else{            
                            newSearchCriteria = {...EMPTY_CREDIT_NOTE_HEADER_SEARCH_CRITERIA} 
                            newSearchCriteria = { ...newSearchCriteria, 
                                createdDateFrom: moment().subtract(DEFAULT_DATE_RANGE, "days").startOf("day").toDate(),
                                createdDateTo: moment().endOf("day").toDate(), 
                                createdBy: anaInfoState.userName,
                            };
                        }
                        creditNoteHeaderVM.getCreditNoteHeaders(newSearchCriteria).then((data) => {            
                            setIsLoading(false)
                        }).catch(error => {            
                            setIsLoading(false)
                        });
                    setIsLoading(false)
                }).catch(error => {            
                    setIsLoading(false)
                });
            } else {
                initialRedirectScreen(tempSearchCriteria);
            }    
        }, [initialRedirectScreen, initialScreen, isAllowAutoSearch, isBackFromDetail, creditNoteHeaderVM, searchCriteria, anaInfoState.userName])
    
    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;
    
    return <>

            <div className={`main-comp-wrapper${isShowCriteriaPanel ? '' : ' im-hide-side-form-draggable'}`}>
                <CreditNoteHeaderTitleBar/>

                    <SliderPanel
                        isOpen={true}
                        draggable={false}
                        leftSectionWidth={isShowCriteriaPanel?"25%":"0%"}
                        rightSectionWidth={isShowCriteriaPanel?"75%":"100%"}
                        leftChildren={<CreditNoteHeaderSearchPanel/>}
                        rightChildren={<CreditNoteHeaderTablePanel/>} 
                        />
            </div>
        </>
}
export default memo(CreditNoteHeaderMaintenance);