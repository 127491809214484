import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { CreditNoteHeaderEntity, EMPTY_CREDIT_NOTE_HEADER_ENTITY } from "domain/entity/CreditNote/CreditNoteHeaderEntity";
import { CreditNoteHeaderSearchCriteria, EMPTY_CREDIT_NOTE_HEADER_SEARCH_CRITERIA } from "domain/entity/CreditNote/CreditNoteHeaderSearchCriteria";
import { CompanyRepository } from "domain/repository/Company/CompanyRepo";
import { CreditNoteHeaderRepository } from "domain/repository/CreditNote/CreditNoteHeaderRepo";
import { UserRepository } from "domain/repository/User/UserRepo";
import _ from "lodash";
import { CreditNoteHeaderMaintenanceModel } from "presentation/model/CreditNote/CreditNoteHeaderMaintenanceModel";
import { DropdownProps } from "presentation/model/DropdownProps";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import BaseViewModel from "../BaseViewModel";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import { CreditNoteHeaderPrintCriteria, EMPTY_CREDIT_NOTE_HEADER_PRINT_CRITERIA } from "domain/entity/CreditNote/CreditNoteHeaderPrintCriteria";
import { PrintQueueRepository } from "domain/repository/Common/PrintQueue";

interface CreditNoteHeaderVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<CreditNoteHeaderMaintenanceModel>> | ((value: SetStateAction<CreditNoteHeaderMaintenanceModel>) => void),
    ],
    creditNoteHeaderRepo: CreditNoteHeaderRepository,
    // creditNoteDetailRepo: CreditNoteDetailRepository
    userRepo: UserRepository,
    companyRepo: CompanyRepository,
    printQueueRepo: PrintQueueRepository
}

export const CreditNoteHeaderVM = ({ dispatch, creditNoteHeaderRepo, userRepo, companyRepo, printQueueRepo }: CreditNoteHeaderVMProps) => {
    const [creditNoteHeaderDispatch] = dispatch;

    const loadDropdownOption = async () => {
        await userRepo.getAllUsers().then(users => {
            let newUsers = _.orderBy(users, ["name"]);
            const createdByDropdownOption = newUsers?.map((user) => ({
                dropdownLabel: user.name,
                tagLabel: user.name,
                value: user.name,
            })) ?? []

            creditNoteHeaderDispatch(prevState => ({
                ...prevState,
                selectedCreditNoteHeaderRows: [],
                dynamicOptions: {
                    ...prevState.dynamicOptions,
                    createdByDropdownOptions: createdByDropdownOption,
                }
            }))
        });

        await companyRepo.getAllCompanyForCombobox().then(
            companies => {
                let newCompanies = _.orderBy(companies, ["companyCode"]);
                const companyCodeDropdownOptions = newCompanies?.map((company) => ({
                    dropdownLabel: company.companyCode,
                    tagLabel: company.companyCode,
                    value: company.companyCode,
                })) ?? []

                creditNoteHeaderDispatch(prevState => ({
                    ...prevState,
                    selectedCreditNoteHeaderRows: [],
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        billToCompDropdownOptions: companyCodeDropdownOptions,
                    }
                }))
            }
        );

        await printQueueRepo.getPrintQueueForCombox().then(
            printQueues => {
                let printQueueDropdownOptions = printQueues?.map((printQueue) => ({
                    dropdownLabel: printQueue.printQueueName,
                    tagLabel: printQueue.printQueueName,
                    value: printQueue.printQueueName,
                })) ?? []
                printQueueDropdownOptions = _.orderBy(printQueueDropdownOptions, "dropdownLabel");

                creditNoteHeaderDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        printerDropdownOptions: printQueueDropdownOptions,
                    },
                    printCriteria: {
                        ...prevState.printCriteria,
                        printQueueName: printQueueDropdownOptions.length > 0 ? printQueueDropdownOptions[0].value : '',
                    }
                }))
            }
        )

    }

    const getCreditNoteHeaders = async (searchCriteria: CreditNoteHeaderSearchCriteria) => {
        creditNoteHeaderDispatch(prevState => {
            return {
                ...prevState,
                creditNoteHeaders: [],
                selectedCreditNoteHeaderRows: [],
            }
        })
        await creditNoteHeaderRepo.getCreditNoteHeaders(searchCriteria).then((data) => {
            creditNoteHeaderDispatch(prevState => {
                return {
                    ...prevState,
                    creditNoteHeaders: data,
                    searchCriteria: searchCriteria,
                    selectedCreditNoteHeaderRows: [],
                    isAllowAutoSearch: false,
                }
            })
        }).catch((error) => {
            return [];
        })
    }

    const onCloseClick = () => {
        creditNoteHeaderDispatch(prevState => {
            return {
                ...prevState,
                isShowCriteriaPanel: false
            }
        });
    }

    const onSearchClick = () => {
        creditNoteHeaderDispatch(prevState => {
            return {
                ...prevState,
                isShowCriteriaPanel: !prevState.isShowCriteriaPanel
            }
        });
    }

    const updateSelectedCharges = (rows: any[]) => {
        creditNoteHeaderDispatch(prevState => {
            return {
                ...prevState,
                selectedCreditNoteHeaderRows: rows,
                forceRefresh: !prevState.forceRefresh
            }
        })
    }

    const onSearchCriteriaResetClick = () => {
        creditNoteHeaderDispatch(prevState => {
            return {
                ...prevState,
                searchCriteria: { ...EMPTY_CREDIT_NOTE_HEADER_SEARCH_CRITERIA }
            }
        })
    }

    const onSingleDropdownChange = (e: any, fieldName: string) => {
        creditNoteHeaderDispatch(prevState => {
            return {
                ...prevState,
                searchCriteria: {
                    ...prevState.searchCriteria,
                    [fieldName]: e.value,
                }
            }
        });
    };

    const onMultipleDropdownChange = (e: any, fieldName: string) => {
        creditNoteHeaderDispatch(prevState => {
            return {
                ...prevState,
                searchCriteria: {
                    ...prevState.searchCriteria,
                    [fieldName]: _.uniq(e?.map((item: DropdownProps) => item.value)),
                }
            }
        });
    };

    const onSearchCoVslVoyChange = (inputData: { co?: string, vsl?: string, voy?: string }, fieldName: { co: string, vsl: string, voy: string }) => {
        let coVal: string = "";
        let vslVal: string = "";
        let voyVal: string = "";
        if (inputData?.co) {
            coVal = inputData?.co.toUpperCase().replace(/\s+/g, '');
        }
        if (inputData?.vsl) {
            vslVal = inputData?.vsl.toUpperCase().replace(/\s+/g, '');
        }
        if (inputData?.voy) {
            voyVal = inputData?.voy.toUpperCase();
        }

        creditNoteHeaderDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName.co]: coVal,
                [fieldName.vsl]: vslVal,
                [fieldName.voy]: voyVal,
            }
        }))
    }

    const onSearchInputTextChange = (e: ChangeEvent<HTMLInputElement>, fieldName: string) => {
        let val: string = e.target.value;
        val = val.toUpperCase().replace(/\s+/g, '');
        creditNoteHeaderDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: val,
            }
        }))
    };

    const onDateRangeChange = (startDate: any, endDate: any, dateFields: { startField: string, endField: string }) => {
        creditNoteHeaderDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [dateFields.startField]: startDate,
                [dateFields.endField]: endDate,
            }
        }))
    }

    const onAdd = () => {
        creditNoteHeaderDispatch(prevState => {
            return {
                ...prevState,
                isShowDetail: !prevState.isShowDetail,
                // searchCriteria: {...EMPTY_CREDIT_NOTE_HEADER_SEARCH_CRITERIA},
                currentCreditNoteHeader: { ...EMPTY_CREDIT_NOTE_HEADER_ENTITY },
                creditNoteHeaderState: {
                    ...prevState.creditNoteHeaderState,
                    isAdd: true,
                    isRead: false,
                }
            }
        });
    }

    const onRowDoubleClick = (creditNoteHdr: CreditNoteHeaderEntity) => {

        creditNoteHeaderDispatch(prevState => {
            return {
                ...prevState,
                isShowDetail: true,
                currentCreditNoteHdr: creditNoteHdr,
                isGoToDetail: true,
                creditNoteHeaderState: {
                    ...prevState.creditNoteHeaderState,
                    isRead: true,
                    isEditable: true,
                    isAdd: false,
                }
            }
        });

    }

    const onConfirm = async (ids: number[]) => {
        return await creditNoteHeaderRepo.confirm(ids);
    }

    const onUnConfirm = async (ids: number[]) => {
        return await creditNoteHeaderRepo.unConfirm(ids);
    }

    const onShowLoading = () => {
        creditNoteHeaderDispatch(prevState => {
            return {
                ...prevState,
                isLoading: true,
            }
        })
    }

    const onHideLoading = () => {
        creditNoteHeaderDispatch(prevState => {
            return {
                ...prevState,
                isLoading: false,
            }
        })
    }
    const onPageInit = (loginUser: string) => {
        creditNoteHeaderDispatch(prevState => {

            return { ...prevState, searchCriteria: { ...prevState.searchCriteria, createdBy: loginUser } };
        })
    }

    const onDelete = async (creHdrIds: number[]): Promise<ResponseEntity> => {
        return await creditNoteHeaderRepo.deleteCreditHdrs(creHdrIds);
    }

    const onPrintCriteriaFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        creditNoteHeaderDispatch(prevState => {
            return {
                ...prevState,
                printCriteria: {
                    ...prevState.printCriteria,
                    [fieldKey]: val
                }
            }
        })
    }

    const onShowPrintModal = () => {
        creditNoteHeaderDispatch(prevState => ({
            ...prevState,
            isShowPrintModal: true
        }))
    };

    const onClosePrintModal = () => {
        creditNoteHeaderDispatch(prevState => ({
            ...prevState,
            isShowPrintModal: false,
            printCriteria: {
                ...EMPTY_CREDIT_NOTE_HEADER_PRINT_CRITERIA,
                printQueueName: prevState.dynamicOptions.printerDropdownOptions.length > 0 ? prevState.dynamicOptions.printerDropdownOptions[0].value : '',
            }
        }))
    };

    const onReprint = async (printCriteria: CreditNoteHeaderPrintCriteria) => {
        let message;
        await creditNoteHeaderRepo.reprintReport(printCriteria).then((res) => {
            if (!res.success) {
                message = "Print/Send failed.";
            }
        }).catch((error) => {
            message = "Print/Send failed."
        })
        onClosePrintModal();
        return { "printReportFail": message }
    }

    const onPreview = async (printCriteria: CreditNoteHeaderPrintCriteria) => {
        let message;
        await creditNoteHeaderRepo.previewReport(printCriteria).then((data) => {

            const isPreviewRpt = prevOrDownloadRpt(data, false);
            if (!isPreviewRpt) {
                message = "The Manual Credit Charge Checklist report cannot be found.";
            }

        }).catch((error) => {
            message = "Print Manual Credit Charge Checklist report failed."
        })

        onClosePrintModal();
        return { "printReportFail": message }
    }

    const prevOrDownloadRpt = (data: any, isDownloadRpt: boolean) => {
        if (data != null) {
            const tempData = data as unknown as string;

            let base64 = tempData
            let bstr = atob(base64)
            let n = bstr.length
            let u8arr = new Uint8Array(n)
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n)
            }
            const blob = new Blob([u8arr], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);

            window.open(url, '_blank');
            URL.revokeObjectURL(url);

            return true;
        }

        return false;
    }

    return {
        loadDropdownOption: loadDropdownOption,
        getCreditNoteHeaders: getCreditNoteHeaders,
        onCloseClick: onCloseClick,
        onSearchClick: onSearchClick,
        updateSelectedCharges: updateSelectedCharges,
        onSearchCriteriaResetClick: onSearchCriteriaResetClick,
        onMultipleDropdownChange: onMultipleDropdownChange,
        onSingleDropdownChange: onSingleDropdownChange,
        onSearchInputTextChange: onSearchInputTextChange,
        onDateRangeChange: onDateRangeChange,
        onAdd: onAdd,
        onRowDoubleClick: onRowDoubleClick,
        onConfirm: onConfirm,
        onUnConfirm: onUnConfirm,
        onShowLoading: onShowLoading,
        onHideLoading: onHideLoading,
        onPageInit: onPageInit,
        onDelete: onDelete,
        onSearchCoVslVoyChange: onSearchCoVslVoyChange,
        onPrintCriteriaFieldChange: onPrintCriteriaFieldChange,
        onShowPrintModal: onShowPrintModal,
        onClosePrintModal: onClosePrintModal,
        onReprint: onReprint,
        onPreview: onPreview
    }
}