export const ChargeQtyAdjMaintenanceRequiredFieldList: string[] = [
    'chargeOnCompany',
    'allowance',
    'waiverNo',
    'cntrIdStr',
    'uom',
];
export const ChargeQtyAdjMaintenanceConstant = {
    CATEGORY: "Charge",
    TITLE: "Storage Charge Quantity Adjustment",
    SUB_TITLE: "Adjustment",
    CHARGE_TYPE: "Charge Type",
    SUB_CHARGE_TYPE: "Sub Charge Type",
    CHARGEON_COMPANY: "Charge-on Company",
    CONSORTIUM_CODE: "Consortium Code",
    VESSEL_CODE: "Vessel Code",
    VOYAGE_CODE: "Voyage Code",
    HANDLING_TML: "Handling Tml.",
    ETD: "ETD",
    CONTAINER_ID: "Container ID",
    ALLOWANCE: "Allowance",
    UOM: "UOM",
    WAIVER_NO: "Waiver No.",
    BILL_OF_LADING_NO: "Bill of Lading No.",
    CUSTOMER_REF_NO: "Customer Ref. No.",
    CFS_SERVICE_TYPE: "CFS Service Type",
    SO_NUMBER: "So Number",
    ACTIVE_IND: "Active Ind.",
    CO_VSL_VOY: 'Co/Vsl/Voy',

    BILLTO_COMPANY: "Bill-to Company",
    VESSEL_NAME: "Vessel Name",
    ARR_DATETIME: "Arr Date/Time",
    DEP_DATETIME: "Dep Date/Time",
    SERVICE_CODE: "Service Code",
    SL_VESSEL_CODE: "S/L Vessel Code",
    SL_IB_VOYAGE_CODE: "S/L IB Voyage Code",
    SL_OB_VOYAGE_CODE: "S/L OB Voyage Code",
    SOA: "SOA",
    LOA: "LOA",
    OPERATING_TML: "Operating Tml",

}

export const chargeQtyAdjCSVColumnMapping: string[] = [
    'chargeType',
    'subChargeType',
    'chargeOnCompany',
    'consortiumCode',
    'vesselCode',
    'voyageCode',
    'etd',
    'cntrList',
    'allowance',
    'uom',
    'waiverNo',
    'blNo',
    'customReferenceNo',
    'serviceType',
    'soNo',
    'activeInd',
];

export const chargeQtyAdjCSVNumberColumnMapping: string[] = [
    'allowance',
];

export const chargeQtyAdjCSVDateColumnMapping: string[] = [
    'etd',
];