import { SupplementaryInvoiceEntity } from "domain/entity/SupplementaryInvoice/SupplementaryInvoiceEntity";
import { EMPTY_SUPPLEMENTARY_INVOICE_SEARCH_CRITERIA, SupplementaryInvoiceSearchCriteria } from "domain/entity/SupplementaryInvoice/SupplementaryInvoiceSearchCriteria";
import { CompanyRepository } from "domain/repository/Company/CompanyRepo";
import { SupplementaryInvoiceRepository } from "domain/repository/SupplementaryInvoice/SupplementaryInvoiceRepo";
import { UserRepository } from "domain/repository/User/UserRepo";
import _ from "lodash";
import { DropdownProps } from "presentation/model/DropdownProps";
import { SupplementaryInvoiceModel } from "presentation/model/SupplementaryInvoice/SupplementaryInvoiceModel";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { GroupCheckboxList } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";

interface SupplementaryInvoiceVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<SupplementaryInvoiceModel>> | ((value: SetStateAction<SupplementaryInvoiceModel>) => void),
    ],
    supplementaryInvoiceRepo: SupplementaryInvoiceRepository,
    companyRepo: CompanyRepository,
    userRepo: UserRepository,
}

export const SupplementaryInvoiceVM = ({ dispatch, supplementaryInvoiceRepo, companyRepo, userRepo }: SupplementaryInvoiceVMProps) => {
    const [supplementaryInvoiceDispatch] = dispatch;


    const loadDropdownOption = async () => {

        await userRepo.getAllUsers().then(users => {
            let newUsers = _.orderBy(users, ["name"]);
            const createdByDropdownOption = newUsers?.map((user) => ({
                dropdownLabel: user.name,
                tagLabel: user.name,
                value: user.name,
            })) ?? []

            supplementaryInvoiceDispatch(prevState => ({
                ...prevState,
                dynamicOptions: {
                    ...prevState.dynamicOptions,
                    createdByDropdownOptions: createdByDropdownOption,
                }
            }))
        });

        await companyRepo.getAllCompanyForCombobox().then(
            companies => {
                let newCompanies = _.orderBy(companies, ["companyCode"]);
                const companyCodeDropdownOptions = newCompanies?.map((company) => ({
                    dropdownLabel: company.companyCode,
                    tagLabel: company.companyCode,
                    value: company.companyCode,
                })) ?? []

                supplementaryInvoiceDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        billToCompDropdownOptions: companyCodeDropdownOptions,
                    }
                }))
            }
        );
    }

    const onShowLoading = () => {
        supplementaryInvoiceDispatch(prevState => {
            return {
                ...prevState,
                isLoading: true,
            }
        })
    }

    const onHideLoading = () => {
        supplementaryInvoiceDispatch(prevState => {
            return {
                ...prevState,
                isLoading: false,
            }
        })
    }

    const updateSelectedCharges = (rows: any[]) => {
        supplementaryInvoiceDispatch(prevState => {
            return {
                ...prevState,
                supplementaryInvoiceState: {
                    ...prevState.supplementaryInvoiceState,
                    selectRows: rows
                },
                forceRefresh: !prevState.forceRefresh
            }
        })
    }

    const onDateRangeChange = (startDate: any, endDate: any, dateFields: { startField: string, endField: string }) => {
        supplementaryInvoiceDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [dateFields.startField]: startDate,
                [dateFields.endField]: endDate,
            }
        }))
    }

    const onSearchInputTextChange = (e: ChangeEvent<HTMLInputElement>, fieldName: string) => {
        let val: string = e.target.value;
        val = val.toUpperCase().replace(/\s+/g, '');
        supplementaryInvoiceDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: val,
            }
        }))
    };

    const onMultipleDropdownChange = (e: any, fieldName: string) => {
        supplementaryInvoiceDispatch(prevState => {
            return {
                ...prevState,
                searchCriteria: {
                    ...prevState.searchCriteria,
                    [fieldName]: _.uniq(e?.map((item: DropdownProps) => item.value)),
                }
            }
        });
    };

    const onGroupCheckboxChange = (e: (GroupCheckboxList | undefined)[], searchCriteria: SupplementaryInvoiceSearchCriteria, fieldName: string) => {
        let selectedValue: string[] = [];
        if (e) {
            e.forEach(function (value, index) {
                if (value) {
                    selectedValue.push(value.key);
                }
            });
        }
        searchCriteria = { ...searchCriteria, [fieldName]: selectedValue }
        supplementaryInvoiceDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                ...searchCriteria,
            }
        }));
    };

    const onSearchCriteriaResetClick = () => {
        supplementaryInvoiceDispatch(prevState => {
            return {
                ...prevState,
                searchCriteria: { ...EMPTY_SUPPLEMENTARY_INVOICE_SEARCH_CRITERIA }
            }
        })
    }

    const onSearchClick = () => {
        supplementaryInvoiceDispatch(prevState => {
            return {
                ...prevState,
                isShowCriteriaPanel: !prevState.isShowCriteriaPanel
            }
        });
    }
    const onSearch = async (searchCriteria: SupplementaryInvoiceSearchCriteria) => {
        supplementaryInvoiceDispatch(prevState => {
            return {
                ...prevState,
                searchCriteria: searchCriteria,
                SupplementaryInvoiceList: [],
                isAllowAutoSearch: false,
                supplementaryInvoiceState: {
                    ...prevState.supplementaryInvoiceState,
                    selectRows: []
                },
            }
        })

        await supplementaryInvoiceRepo.searchSupplementaryInvoice(searchCriteria).then((data) => {
            if (data) {
                supplementaryInvoiceDispatch(prevState => {
                    return {
                        ...prevState,
                        SupplementaryInvoiceList: data,
                        searchCriteria: searchCriteria,
                        isAllowAutoSearch: false,
                        supplementaryInvoiceState: {
                            ...prevState.supplementaryInvoiceState,
                            selectRows: [],
                        },
                    }
                })
            }
        }).catch((error) => {
            return [];
        })
    }

    const onSearchCoVslVoyChange = (inputData: { co?: string, vsl?: string, voy?: string }, fieldName: { co: string, vsl: string, voy: string }) => {
        supplementaryInvoiceDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName.co]: inputData?.co?.toUpperCase().replace(/\s+/g, ''),
                [fieldName.vsl]: inputData?.vsl?.toUpperCase().replace(/\s+/g, ''),
                [fieldName.voy]: inputData?.voy?.toUpperCase().replace(/\s+/g, ''),

            }
        }))
    }

    const onDelete = async (selectRows: SupplementaryInvoiceEntity[]) => {
        return await supplementaryInvoiceRepo.deleteSupplementaryInvoice(selectRows);
    }
    const onConfirm = async (selectRows: SupplementaryInvoiceEntity[]) => {
        return await supplementaryInvoiceRepo.supplementaryInvoiceConfirm(selectRows);
    }
    const onUnConfirm = async (selectRows: SupplementaryInvoiceEntity[]) => {
        return await supplementaryInvoiceRepo.supplementaryInvoiceUnConfirm(selectRows);
    }

    const onRowDoubleClick = (hdr: SupplementaryInvoiceEntity) => {
        supplementaryInvoiceDispatch(prevState => {
            return {
                ...prevState,
                isShowDetail: true,
                currentSuppInvoice: hdr,
                isGoToDetail: true,
            }
        });
    }

    return {
        loadDropdownOption: loadDropdownOption,
        onHideLoading: onHideLoading,
        onShowLoading: onShowLoading,
        onSearchInputTextChange: onSearchInputTextChange,
        onMultipleDropdownChange: onMultipleDropdownChange,
        onDateRangeChange: onDateRangeChange,
        updateSelectedCharges: updateSelectedCharges,
        onSearchCriteriaResetClick: onSearchCriteriaResetClick,
        onSearchClick: onSearchClick,
        onSearch: onSearch,
        onSearchCoVslVoyChange: onSearchCoVslVoyChange,
        onDelete: onDelete,
        onUnConfirm: onUnConfirm,
        onConfirm: onConfirm,
        onRowDoubleClick: onRowDoubleClick,
        onGroupCheckboxChange: onGroupCheckboxChange,

    }
}
