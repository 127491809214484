import { ChargeConstantCollection } from "constants/charge/ChargeConstantCollection";
import { DocumentConst } from "constants/document/DocumentConst";
import { ReportConst } from "constants/document/ReportConst";
import _ from "lodash";
import { RadioProps } from "presentation/model/RadioProps";
import { GroupRadioList } from "veronica-ui-component/dist/component/core/GroupRadioButton/GroupRadioButton";

const hdrGroupList: { [key: string]: string; } = ChargeConstantCollection.chargeHeaderGroup;
export const chargeHeadergGroupRadioOption: RadioProps[] = _.map(
    _.toPairs(hdrGroupList),
    ([key, value], index) => ({
        inputId: index,
        key: key,
        name: value,
    })
);

export const nonOpsTypes: GroupRadioList[] = [{
    inputId: 1,
    key: "NONOPS",
    name: "Non-Ops",
},
{
    inputId: 2,
    key: "STDBILL",
    name: "Std-Bill",
}
]

export const adjIndOptions: GroupRadioList[] = [{
    inputId: 1,
    key: "O",
    name: "Normal",
},
{
    inputId: 2,
    key: "A",
    name: "Adjustment",
}
]

export const yesOrNoOptions = {
    Y: 'Yes',
    N: 'No'
}

export const yesOrNoRadioOptions: GroupRadioList[] = _.map(
    _.toPairs(yesOrNoOptions),
    ([key, value], index) => ({
        inputId: index,
        key: key,
        name: value,
    })
);

export const ediExportTypeRadioOptions: GroupRadioList[] = _.map(
    _.toPairs(DocumentConst.ediExportType),
    ([key, value], index) => ({
        inputId: index,
        key: key,
        name: value,
    })
);

export const billingTypeForSOARadioOptions: GroupRadioList[] = _.map(
    _.toPairs(DocumentConst.billingTypeForSOAPolicy),
    ([key, value], index) => ({
        inputId: index,
        key: key,
        name: value,
    })
);

export const ediItemCodeTypeRadioOptions: GroupRadioList[] = _.map(
    _.toPairs(DocumentConst.ediItemCodeTypePolicy),
    ([key, value], index) => ({
        inputId: index,
        key: value,
        name: value,
    })
);

export const consolMethodRadioOptions: GroupRadioList[] = _.map(
    _.toPairs(DocumentConst.consolMethodPolicy),
    ([key, value], index) => ({
        inputId: index,
        key: (index + 1).toString(),
        name: value,
    })
);

export const proposalTypeRadioOptions: GroupRadioList[] = _.map(
    _.toPairs(ReportConst.proposalType),
    ([key, value], index) => ({
        inputId: index,
        key: value,
        name: value,
    })
);

export const versionTypeRadioOptions: GroupRadioList[] = _.map(
    _.toPairs(ReportConst.versionType),
    ([key, value], index) => ({
        inputId: index,
        key: value,
        name: value,
    })
);



