import { useStandardProposalTracked } from "presentation/store/StandardProposal/StandardProposalProvider";
import { memo, useMemo } from "react";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import TariffItemIsEditPanel from "./TariffItemIsEditPanel";
import TariffItemTierTablePanel from "./TariffItemTierTablePanel";


const TariffItemTierTableRightPanel: React.FC = () => {
    const [standardProposalState] = useStandardProposalTracked();
    const { isAddIs, isAddTier, isEditIs, isEditTier } = standardProposalState;




    const memoTariffItemTierTable = useMemo(() => {
        return <TariffItemTierTablePanel />
    }, [])


    const memoTariffItemIsEditPanel = useMemo(() => {
        return <TariffItemIsEditPanel />
    }, [])


    return <>
        <div className={`main-comp-wrapper${((isAddIs || isAddTier || isEditIs || isEditTier)) ? '' : ' im-hide-side-form-draggable'}`}>
            <div style={{ width: '100%', height: '100%', maxHeight: '85%' }}>
                <SliderPanel
                    isOpen={true}
                    draggable={true}
                    leftSectionWidth={(isAddIs || isAddTier || isEditIs || isEditTier) ? "60%" : "100%"}
                    rightSectionWidth={(isAddIs || isAddTier || isEditIs || isEditTier) ? "40%" : "0%"}
                    leftChildren={memoTariffItemTierTable}
                    rightChildren={memoTariffItemIsEditPanel}
                />
            </div>

            {/* {<HPHButton disabled={isDisable()} label={WorkspaceConstant.Common.BUTTON_SAVE} size={'Small'} theme={'Primary'} onClick={handleSave} />} */}
        </div>
    </>;
}

export default memo(TariffItemTierTableRightPanel);
