import { ChargeHeaderCriteriaDirectoryDropdownOption } from "presentation/constant/ChargeHeader/ChargeHeaderCriteriaDirectoryDropdownOption";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useChargeHeaderMaintenanceVM } from "presentation/hook/ChargeHeaderMaintenance/useChargeHeaderMaintenanceVM";
import { useChargeHeaderMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/ChargeHeaderMaintenanceProvider";
import NbisInputDropDown from "presentation/view/components/NbisInputDropDown";
import { useMemo } from "react";
import { HPHButton } from "veronica-ui-component/dist/component/core";
import { SidebarActionBar, SidebarTitle, Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import { ChargeHeaderSearchCriteriaCheckboxList } from "./ChargeHeaderSearchCriteriaCheckboxList";

export const ChargeHeaderSearchCriteriaDirectoryPanel = () => {
    const chargeDataSearchVM = useChargeHeaderMaintenanceVM();
    const [chargeDataSearchState] = useChargeHeaderMaintenanceTracked();
    const workspaceConstant = WorkspaceConstant.Common;

    const memoDirectoryFrom = useMemo(() =>
        <>
            <div className='flex-row-item'>
                <NbisInputDropDown
                    field='dropdownLabel'
                    mode='single'
                    label=''
                    onChange={(e: any) => chargeDataSearchVM.onCriteriaDirectoryDropdown(e)}
                    options={ChargeHeaderCriteriaDirectoryDropdownOption}
                    value={chargeDataSearchState.highlightedCheckboxKey}
                    width={' calc(100% - 35px)'} />
                <ChargeHeaderSearchCriteriaCheckboxList />
            </div>
            <div className='flex-row-item'>
                <NbisInputDropDown
                    field='dropdownLabel'
                    mode='single'
                    label=''
                    onChange={(e: any) => chargeDataSearchVM.onCriteriaDirectoryDropdown(e)}
                    options={ChargeHeaderCriteriaDirectoryDropdownOption}
                    value={chargeDataSearchState.highlightedCheckboxKey}
                    width={' calc(100% - 35px)'} />
                <ChargeHeaderSearchCriteriaCheckboxList />
            </div>
        </>
        , [chargeDataSearchState.highlightedCheckboxKey, chargeDataSearchVM]);

    return <div className='side-form-content-wrapper'>
        <div className={'im-flex-row'}>
            <div className={'flex-row-item flex-row-item-full-width'}>
                <Sidebarheader>
                    <SidebarTitle>{workspaceConstant.CRITERIA_DIRECTORY}</SidebarTitle>
                </Sidebarheader>
            </div>
        </div>
        <div className={'add-edit-form'} style={{ maxHeight: '74vh', overflow: 'auto' }}>
            <div className="flex-row-auto">
                <div className={'flex-row-item'}>
                    {memoDirectoryFrom}
                </div>
            </div>
        </div>

        <SidebarActionBar>
            <HPHButton label={'Reset'} size={'Small'} theme={'Secondary'} onClick={chargeDataSearchVM.onResetCheckboxValue} />
            <HPHButton label={'Confirm'} size={'Small'} theme={'Primary'} onClick={chargeDataSearchVM.onSaveCheckboxValue} />
        </SidebarActionBar>
    </div>
};