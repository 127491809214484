import { StandardProposalDetailModel } from "presentation/model/StandardProposal/StandardProposalDetailModel";
import { memo, useMemo } from "react";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import AddTariffItemTierTablePanel from "./AddTariffItemTierTablePanel";
import AddTariffItemIsEditPanel from "./AddTariffItemIsEditPanel";



const AddTariffItemTierTableRightPanel = ({ standardProposalDtlState }: { standardProposalDtlState: StandardProposalDetailModel }) => {
    const { isAddIs, isAddTier, isEditIs, isEditTier } = standardProposalDtlState;

    const memoAddTariffItemTierTable = useMemo(() => {
            return <AddTariffItemTierTablePanel standardProposalDtlState={standardProposalDtlState} />
    }, [standardProposalDtlState])

    const memoAddTariffItemIsEditPanel = useMemo(() => {
            return <AddTariffItemIsEditPanel standardProposalDtlState={standardProposalDtlState} />
    }, [standardProposalDtlState])

    return <>
        <div className={`main-comp-wrapper${((isAddIs || isAddTier || isEditIs || isEditTier)) ? '' : ' im-hide-side-form-draggable'}`}>
            <div style={{ width: '100%', height: '100%', maxHeight: '85%' }}>
                <SliderPanel
                    isOpen={true}
                    draggable={true}
                    leftSectionWidth={(isAddIs || isAddTier || isEditIs || isEditTier) ? "65%" : "100%"}
                    rightSectionWidth={(isAddIs || isAddTier || isEditIs || isEditTier) ? "35%" : "0%"}
                    leftChildren={memoAddTariffItemTierTable}
                    rightChildren={memoAddTariffItemIsEditPanel}
                />
            </div>
        </div>
    </>;
}

export default memo(AddTariffItemTierTableRightPanel);
