import { ChargeConstant } from "presentation/constant/ChargeHeader/ChargeConstant";
import { RequisitionFormConstant } from "presentation/constant/RequisitionForm/RequisitionFormConstant";
import { useRequisitionFormVM } from "presentation/hook/RequisitionForm/useRequisitionFormVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useRequisitionFormMaintenanceTracked } from "presentation/store/RequisitionForm/RequisitionFormMaintenanceProvider";
import { memo, useCallback, useState } from "react";
import { HPHBreadcrumb, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const RequisitionFormEditPanelTitleBar: React.FC = () => {
    const [requisitionFormState] = useRequisitionFormMaintenanceTracked();
    const requisitionFormVM = useRequisitionFormVM();
    const messageBarVM = useMessageBarVM();
    const { currentRequisitionForm, masterState } = requisitionFormState;
    const { editingEntity, isAdd, isEditable, isRead } = masterState;

    const [anainfoState] = useANAInfoTracked();
    const { allowCreate, allowUpdate } = anainfoState;
    const [isLoading, setIsLoading] = useState(false);
    const currentEntity = isRead ? currentRequisitionForm : editingEntity;

    const handleReset = useCallback(async () => {
        requisitionFormVM.onResetClick();
    }, [requisitionFormVM]);

    const handleEdit = useCallback(() => {
        requisitionFormVM.onEdit(currentEntity);
    }, [currentEntity, requisitionFormVM])

    const handleSave = useCallback(async () => {
        if (currentEntity && currentEntity.reqMode !== "AN"
            && !(currentEntity.customers && currentEntity.customers.length > 0)) {
            messageBarVM.showError("Please add a customer at least.");
            return;
        }
        setIsLoading(true);
        try {
            requisitionFormVM.onSaveClicked();
            const res = await requisitionFormVM.onSave(currentEntity, isAdd);
            if (res && res["mandatoryCheckFail"]) {
                messageBarVM.showError(res["mandatoryCheckFail"]?.toString());
                return;
            } else if (res) {
                messageBarVM.showError(res);
                return;
            }
        } catch (error) {

        } finally {
            setIsLoading(false)
        }

    }, [currentEntity, isAdd, messageBarVM, requisitionFormVM]);

    const isShowEdit = useCallback(() => {
        if (currentEntity && currentEntity.state === 'INV') return false;
        return true;
    }, [currentEntity])

    return <Sidebarheader style={{ width: '100%' }}>
        {isLoading && <Loader Indicator="Spinner" size="Medium" />}
        <Breadcrumb>
            <HPHBreadcrumb breadcrumbData={[{ title: ChargeConstant.Charge.CHARGE },
            { title: RequisitionFormConstant.TITLE }]} onCurrentClick={() => { }}></HPHBreadcrumb>
        </Breadcrumb>
        <StyledAction>
            {(!isAdd && (isEditable && isRead)) &&
                <>
                    {/* Edit Button */}
                    {
                        (
                            (isEditable && allowUpdate && isShowEdit())
                        )
                        && <IconButton fileName='Icon-pen' size='medium' toolTipText={'Edit'} toolTipArrow={false} onClick={handleEdit} />
                    }
                    {/* Close Button */}
                    <IconButton fileName='Icon-cross' size='medium' toolTipText={'Close'} toolTipArrow={false} onClick={requisitionFormVM.onCloseClick} />
                </>}
            {(isAdd || (isEditable && !isRead)) &&
                <>
                    {/* Reset Form Button */}
                    <IconButton fileName="Icon-reset" size="medium" toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Reset'} onClick={handleReset} />
                    {/* Close Button */}
                    <IconButton fileName="Icon-cross" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="left" toolTipText={'Close'} onClick={requisitionFormVM.onCloseClick} />
                    {/* Save Button */}
                    {
                        (
                            (isAdd && allowCreate) ||
                            (isEditable && allowUpdate)
                        )
                        && <IconButton fileName="Icon-tick" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Save'} onClick={() => handleSave()} />
                    }
                </>}
        </StyledAction>
    </Sidebarheader>
}

export default memo(RequisitionFormEditPanelTitleBar);