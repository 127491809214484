import { EMPTY_SEARCH_MODE_ENTITY } from "domain/entity/ChargeDataEnquiry/SearchModeEntity";
import { ChargeDataSearchCriteria, EMPTY_CHARGE_DATA_SEARCH_CRITERIA } from "presentation/constant/ChargeDataEnquiry/ChargeDataSearchCriteria";
import { useChargeDataSearchVM } from "presentation/hook/ChargeDataEnquiry/useChargeDataSearchVM";
import { useChargeDataSearchTracked } from "presentation/store/ChargeDataEnquiry/ChargeDataSearchProvider";
import { useCallback, useEffect, useState } from "react";
import { HPHButton, Loader } from "veronica-ui-component/dist/component/core";
import { isSearchCriteriaSet } from "../../ChargeDataSearchComp";
import { ChargeDataSearchCriteriaEditChargePanel } from "./ChargeDataSearchCriteriaEditPanel/ChargeDataSearchCriteriaEditChargePanel";
import { ChargeDataSearchCriteriaEditContainerPanel } from "./ChargeDataSearchCriteriaEditPanel/ChargeDataSearchCriteriaEditContainerPanel";
import { ChargeDataSearchCriteriaEditDocumentPanel } from "./ChargeDataSearchCriteriaEditPanel/ChargeDataSearchCriteriaEditDocumentPanel";
import { ChargeDataSearchCriteriaEditVoyagePanel } from "./ChargeDataSearchCriteriaEditPanel/ChargeDataSearchCriteriaEditVoyagePanel";
import { ChargeDataSearchCriteriaPreviewChargePanel } from "./ChargeDataSearchCriteriaPreviewPanel/ChargeDataSearchCriteriaPreviewChargePanel";
import { ChargeDataSearchCriteriaPreviewContainerPanel } from "./ChargeDataSearchCriteriaPreviewPanel/ChargeDataSearchCriteriaPreviewContainerPanel";
import { ChargeDataSearchCriteriaPreviewDocumentPanel } from "./ChargeDataSearchCriteriaPreviewPanel/ChargeDataSearchCriteriaPreviewDocumentPanel";
import { ChargeDataSearchCriteriaPreviewVoyagePanel } from "./ChargeDataSearchCriteriaPreviewPanel/ChargeDataSearchCriteriaPreviewVoyagePanel";
import { useMessageBarVM } from "presentation/hook/useMessageBar";

export const ChargeDataSearchCriteriaPanel = () => {
    const [chargeDataSearchState] = useChargeDataSearchTracked();
    const chargeDataSearchVM = useChargeDataSearchVM();
    const isEditSearchCriteria = chargeDataSearchState.isEditSearchCriteria;
    const searchCriteria = chargeDataSearchState.searchCriteria;
    const [isLoading, setIsLoading] = useState(false);
    const messageBarVM = useMessageBarVM();

    const handleResetClick = () => {
        chargeDataSearchVM.onRemoveAllSearchCriteria();
    }

    const handleSearchClick = useCallback(async (searchCriteria: ChargeDataSearchCriteria, counter: number) => {
        setIsLoading(true);
        chargeDataSearchVM.onSearch();

        await chargeDataSearchVM.fetchTableData(searchCriteria, EMPTY_SEARCH_MODE_ENTITY).then((data) => {
            setIsLoading(false);
            if (data && data["chargeDataSearchFail"]) {
                const err: string = data["chargeDataSearchFail"]?.toString() ?? "";
                messageBarVM.showError(err.replace("Error:", ""), true);
            }
        }).catch((error) => {
            setIsLoading(false);
        }).finally(() => {
        })
    }, [chargeDataSearchVM, messageBarVM]);

    useEffect(() => {
        let timer: any = null;
        const cb = () => {
            const comField: any = document.querySelector('.im-charge-data-search-criteria-content input[name="co"]');
            if (comField) {
                comField.focus();
                timer && clearInterval(timer);
            }
        };

        timer = setInterval(cb, 0);
    }, []);

    return <div className='im-charge-data-search-criteria-panel'>
        <div className='im-charge-data-search-criteria-content'>
            {isLoading && <Loader Indicator="Spinner" size="Medium" />}

            {/* Search criteria form */}
            {
                isEditSearchCriteria &&
                <>
                    <ChargeDataSearchCriteriaEditVoyagePanel />
                    <ChargeDataSearchCriteriaEditChargePanel />
                    <ChargeDataSearchCriteriaEditContainerPanel />
                    <ChargeDataSearchCriteriaEditDocumentPanel />
                </>
            }

            {/* Search criteria preview panel */}
            {
                !isEditSearchCriteria && !isSearchCriteriaSet(chargeDataSearchState.searchCriteria, EMPTY_CHARGE_DATA_SEARCH_CRITERIA) &&
                <>
                    <ChargeDataSearchCriteriaPreviewVoyagePanel />
                    <ChargeDataSearchCriteriaPreviewChargePanel />
                    <ChargeDataSearchCriteriaPreviewContainerPanel />
                    <ChargeDataSearchCriteriaPreviewDocumentPanel />
                </>
            }
            {/* Search criteria no criteria selected */}
            {!isEditSearchCriteria && isSearchCriteriaSet(chargeDataSearchState.searchCriteria, EMPTY_CHARGE_DATA_SEARCH_CRITERIA) && <h4 style={{ margin: '0px' }}>No Criteria.</h4>}
        </div>
        <div className='im-charge-data-search-criteria-buttons'>
            <>
                {isEditSearchCriteria &&
                    <HPHButton label={'Reset'} size={'Small'} theme={'Secondary'} onClick={handleResetClick} />}
                <HPHButton label={'Search'} size={'Small'} theme={'Primary'} onClick={(e: any) => {
                    handleSearchClick(searchCriteria, chargeDataSearchState.searchCounter);
                }} />
            </>
        </div>
    </div>
};
