import { useTariffCodeVM } from "presentation/hook/TariffCode/useTariffCodeVM";
import { useTariffCodeTracked } from "presentation/store/TariffCode/TariffCodeProvider";
import { memo, useEffect } from "react";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import { TariffCodeComponentPanel } from "./Form/TariffCodeComponentPanel";
import TariffCodeComponentTablePanel from "./Table/TariffCodeComponentTablePanel";

export const TariffCodeEditPanel: React.FC = () => {
    const [tariffCodeState] = useTariffCodeTracked();
    const tariffCodeVM = useTariffCodeVM();
    const { isSliderOpen } = tariffCodeState.componentState;
    const tarCodeEty = tariffCodeState.currentSelectedRow;

    useEffect(() => {
        tarCodeEty?.id && tariffCodeVM.onSearchComponent(tarCodeEty.id)
    }, [tarCodeEty.id, tariffCodeVM])

    return <div className={`main-comp-wrapper${isSliderOpen ? '' : ' im-hide-side-form-draggable'}`}>
        <SliderPanel
            isOpen={isSliderOpen}
            leftSectionWidth={'100%'}
            rightSectionWidth={'60%'}
            draggable={true}
            leftChildren={<div style={{ marginLeft: "15px" }}><TariffCodeComponentTablePanel /></div>}
            rightChildren={<TariffCodeComponentPanel />} />
    </div>
}

export default memo(TariffCodeEditPanel);