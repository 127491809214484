import { EMPTY_SUPPLEMENTARY_INVOICE_ENTITY, SupplementaryInvoiceEntity } from "domain/entity/SupplementaryInvoice/SupplementaryInvoiceEntity"
import { EMPTY_SUPPLEMENTARY_INVOICE_SEARCH_CRITERIA, SupplementaryInvoiceSearchCriteria } from "domain/entity/SupplementaryInvoice/SupplementaryInvoiceSearchCriteria"
import { DEFAULT_ENABLED_SUPPLEMENTARY_INVOICE_SEARCH_CRITERIA, SupplementaryInvoiceEnabledSearchCriteria } from "presentation/constant/SupplementaryInvoice/SupplementaryInvoiceEnabledSearchCriteria"
import { BaseViewChangeSate } from "../BaseViewChangeState"
import { DropdownProps } from "../DropdownProps"

export interface SupplementaryInvoiceDropdownOptions {
    billToCompDropdownOptions: DropdownProps[],
    hdrStateDropdownOptions: DropdownProps[],
    createdByDropdownOptions: DropdownProps[],
}

export interface SupplementaryInvoiceChangeState extends BaseViewChangeSate {
    selectRows: SupplementaryInvoiceEntity[],
}

export interface SupplementaryInvoiceModel {
    isLoading: boolean,
    dynamicOptions: SupplementaryInvoiceDropdownOptions,
    searchCriteria: SupplementaryInvoiceSearchCriteria,
    enabledSearchCriteria: SupplementaryInvoiceEnabledSearchCriteria,
    isShowCriteriaPanel: boolean,
    supplementaryInvoiceState: SupplementaryInvoiceChangeState,
    SupplementaryInvoiceList: SupplementaryInvoiceEntity[],
    currentSuppInvoice: SupplementaryInvoiceEntity,
    isShowDetail: boolean,
    isBackFromDetail: boolean,
    isAllowAutoSearch: boolean,
    allFormState?: { [x: string]: string },
    forceRefresh: boolean,
}


export const EMPTY_SUPPLEMENTARY_INVOICE_MODEL: SupplementaryInvoiceModel = {
    searchCriteria: { ...EMPTY_SUPPLEMENTARY_INVOICE_SEARCH_CRITERIA },
    isLoading: false,
    dynamicOptions: {
        billToCompDropdownOptions: [],
        hdrStateDropdownOptions: [],
        createdByDropdownOptions: []
    },
    enabledSearchCriteria: { ...DEFAULT_ENABLED_SUPPLEMENTARY_INVOICE_SEARCH_CRITERIA },
    isShowCriteriaPanel: false,
    supplementaryInvoiceState: {
        isSliderOpen: false,
        isTabularDataActive: false,
        isAdd: false,
        isRead: false,
        isEditable: false,
        isSaveClicked: false,
        lastEditRowId: "",
        isSelected: false,
        slider: {
            isAdd: false,
            isRead: false,
            isEditable: false
        },
        selectRows: []
    },
    SupplementaryInvoiceList: [],
    isShowDetail: false,
    isBackFromDetail: false,
    isAllowAutoSearch: true,
    currentSuppInvoice: { ...EMPTY_SUPPLEMENTARY_INVOICE_ENTITY },
    forceRefresh: false
}