import { DeliveryChargeSummaryEntity } from "domain/entity/ChargeHeader/DeliveryChargeSummaryEntity";
import { E_Custom_Dispatch_Event, E_Type_Of_Event, customDispatchEvent } from "helpers/Events";
import { INITIAL_DELIVERY_CHARGE_SUMMARY_HEADER_COL_DEF, transferRowData } from "presentation/constant/ChargeHeader/DeliveryChargeSummary/DeliveryChargeSummaryColumnDefinition";
import { MessageConstant } from "presentation/constant/MessageConstant";
import { useChargeDetailVM } from "presentation/hook/ChargeDetailMaintenance/useChargeDetailVM";
import { useDeliveryChargeSummaryMaintenanceVM } from "presentation/hook/ChargeHeaderMaintenance/useDeliveryChargeSummaryMaintenanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useDeliveryChargeSummaryMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/DeliveryChargeSummaryMaintenanceProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { useCallback, useMemo } from "react";


export const DeliveryChargeSummaryTablePanel: React.FC = () => {
    const [ibCntrSummaryState] = useDeliveryChargeSummaryMaintenanceTracked();
    const chargeDetailVM = useChargeDetailVM();
    const messageBarVM = useMessageBarVM();
    const delChgSummaryVM = useDeliveryChargeSummaryMaintenanceVM();

    const tableDoubleClicked = useCallback(async (row: any) => {
        if (row) {
            const summaryRow: DeliveryChargeSummaryEntity = row.data as DeliveryChargeSummaryEntity;
            if (!summaryRow.billingCycleCode) {
                messageBarVM.showError(MessageConstant.charge.DELIVERY_RECORD_MISSING_BILLING_CYCLE_CODE);
                return;
            }
            chargeDetailVM.getChargeDetailsForDeliverySummary(row.data as DeliveryChargeSummaryEntity).then(resData => {
                if (resData === "Missing CY/YH Charges") {
                    messageBarVM.showError(MessageConstant.charge.DELIVERY_RECORD_MISSING_CY_YH);
                    return;
                }
                customDispatchEvent(E_Type_Of_Event.CHARGE_DETAIL_REDIRECT_EVENT,
                    E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_DELIVERY_SUMMARY,
                    {
                        data:
                        {
                            ...resData,
                            redirectType: "doubleClick",
                        }
                    }
                );
            });
        }
    }, [chargeDetailVM, messageBarVM]);

    const tbBtns = useMemo(() => {
        let btns = [];
        btns.push({
            id: 'onSearch',
            icon: 'Icon-search',
            title: 'Search'
        });
        return btns;
    }, [])

    const handleSearchClick = useCallback(() => {
        delChgSummaryVM.onSearchClick();
    }, [delChgSummaryVM]);

    const memoGroupTable = useMemo(() => {
        return (
            <NbisTable
                id={'Inbound Container Summary Table'}
                isNewColumnSetting={true}
                columns={INITIAL_DELIVERY_CHARGE_SUMMARY_HEADER_COL_DEF}
                // headerLabel={IB_CNTR_SUMMARY_CONSTANT.DELIVERY_CHARGE_SUMMARY}
                data={transferRowData(ibCntrSummaryState.deliveryChargeSummaryDataList)}
                headerActionButtons={tbBtns}
                onRowDoubleClick={tableDoubleClicked}
                showPaginator={false}
                editable={false}
                showAddIcon={false}
                showDeleteButton={false}
                showReloadIcon={false}
                isScrollHighlighted={true}
                isRowHighligted={true}
                selectionMode={false}
                gridHeight="customHeight"
                customHeight="calc(100vh - 125px)"
                onSearch={handleSearchClick}
            // onRowClick={(row: any)=>delChgSummaryVM.onSelectedDeliveryChargeData(row.data)}
            />
        );
    }, [ibCntrSummaryState.deliveryChargeSummaryDataList, tableDoubleClicked, handleSearchClick, tbBtns])

    return <TableWrapper>{memoGroupTable}</TableWrapper>
}


