import { SelectionChangedEvent } from "ag-grid-community";
import { CompanyEntity } from "domain/entity/Company/CompanyEntity";
import _ from "lodash";
import { MessageConstant } from "presentation/constant/MessageConstant";
import { TariffCodeConstant } from "presentation/constant/TariffCode/TariffCodeConstant";
import { INITIAL_TARIFF_CODE_BY_COMP_COL_DEF } from "presentation/constant/TariffCodeByCompany/TariffCodeByCompanyColumnDefinition";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useTariffCodeByCompanyVM } from "presentation/hook/TariffCodeByCompany/useTariffCodeByCompanyVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useTariffCodeByCompanyTracked } from "presentation/store/TariffCodeByCompany/TariffCodeByCompanyProvider";
import { ConfirmDeleteModal } from "presentation/view/components/ConfirmDeleteModal";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Loader } from "veronica-ui-component/dist/component/core";


const TariffCodeByCompanyTablePanel: React.FC = () => {
    const [tariffCodeByCompanyState] = useTariffCodeByCompanyTracked();
    const tariffCodeByCompanyVM = useTariffCodeByCompanyVM();
    const gridRef: any = useRef(null);
    const { isShowAddPanel, isShowDetail, companyEntityList, selectedRows, forceRefresh } = tariffCodeByCompanyState;
    // const [anainfoState] = useANAInfoTracked();
    // const {allowUpdate} = anainfoState;
    const messageBarVM = useMessageBarVM();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isShowDelete, setIsShowDelete] = useState<boolean>(false);
    const [deletedRow, setDeletedRow] = useState<CompanyEntity[]>();


    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();

        tariffCodeByCompanyVM.updateSelectedRows(companyEntityList, selectedRows);
    }, [companyEntityList, tariffCodeByCompanyVM])

    const handleRowDoubleClick = useCallback(async (entity: CompanyEntity) => {
        setIsLoading(true);
        tariffCodeByCompanyVM.onSearchCompanyTariffCode(entity).then((data) => {
            setIsLoading(false);
        }).catch((e) => {
            setIsLoading(false);
        })
    }, [tariffCodeByCompanyVM])


    const handleAddClick = useCallback(() => {
        tariffCodeByCompanyVM.onAddClick();
    }, [tariffCodeByCompanyVM])

    const handleDeleteClick = useCallback(async () => {
        if (_.isEmpty(selectedRows)) {
            messageBarVM.showError("Please select a record");
            return;
        }
        setIsShowDelete(true);
        setDeletedRow(selectedRows);
    }, [messageBarVM, selectedRows]);

    const handleCancel = () => {
        setIsShowDelete(false);
    }

    const handleDelete = useCallback(async () => {
        setIsShowDelete(false);
        setIsLoading(true);
        if (!deletedRow) return;
        await tariffCodeByCompanyVM.onDelete(deletedRow).then((res) => {
            if (res && res.success) {
                tariffCodeByCompanyVM.onSearchCompany().then((data) => {
                    messageBarVM.showSuccess("The record(s) is deleted successfully.")
                    setIsLoading(false);                    
                }).catch((e) => {
                    setIsLoading(false);
                });
            } else {
                messageBarVM.showError(res.data ?? "Failed to delete company.")    
            }            
        }).catch((e) => {
            setIsLoading(false);
        });

    }, [deletedRow, messageBarVM, tariffCodeByCompanyVM])

    const tbBtns = useMemo(() => {
        let btns = [];
        if (!isShowAddPanel && !isShowDetail) {
            btns.push({
                id: 'onDeleteButton',
                icon: 'Icon-trash',
                title: 'Delete'
            })
        }
        return btns;
    }, [isShowAddPanel, isShowDetail])

    const isRowSelectable = useCallback((params: any) => {
        if (selectedRows.length) {
            const findRow = selectedRows.find((row: any) => row.id === params.data.id);
            if (findRow) {
                return true;
            } else {
                return !isShowDetail;
            }
        } else {
            return !isShowDetail;
        }
    }, [isShowDetail, selectedRows]);

    const memoTariffCodeTable = useMemo(() => {
        return (
            <>
                <NbisTable
                    id='tariff-code-by-company-table'
                    headerLabel={TariffCodeConstant.CompanyTariffCode.TABLE_TITLE}
                    headerActionButtons={tbBtns}
                    isNewColumnSetting={true}
                    columns={INITIAL_TARIFF_CODE_BY_COMP_COL_DEF.slice()}
                    data={tariffCodeByCompanyState.companyEntityList ?? []}
                    showPaginator={false}
                    editable={false}
                    showAddIcon={!isShowAddPanel && !isShowDetail}
                    onAddClick={handleAddClick}
                    onDeleteButton={handleDeleteClick}
                    showDeleteButton={false}
                    showReloadIcon={false}
                    isScrollHighlighted={true}
                    isRowHighligted={true}
                    rowSelection="multiple"
                    onSelectionChanged={handleSelectionChange}
                    onRowDoubleClick={(e: any) => handleRowDoubleClick(e.data)}
                    gridHeight="customHeight"
                    customHeight="calc(100vh - 90px)"
                    ref={gridRef}
                    isRowSelectable={isRowSelectable}
                />
                {forceRefresh && <></>}
            </>
        );
    }, [tbBtns, tariffCodeByCompanyState.companyEntityList, isShowAddPanel, isShowDetail, handleAddClick, handleDeleteClick, handleSelectionChange, isRowSelectable, forceRefresh, handleRowDoubleClick])

    useEffect(() => {
        if (gridRef.current && gridRef.current.gridRef && gridRef.current.gridRef.current && gridRef.current.gridRef.current.api) {
            gridRef.current.gridRef.current.api.redrawRows();
        }
    }, [isShowDetail]);

    return <>
        {isLoading && <Loader Indicator="Spinner" size="Medium" />}
        <TableWrapper>
            {memoTariffCodeTable}
        </TableWrapper>

        <ConfirmDeleteModal headerText={`${WorkspaceConstant.Common.BUTTON_DELETE} ${TariffCodeConstant.CompanyTariffCode.TABLE_TITLE}`}
            contentText={`${MessageConstant.common.DELETE_ALERT_TITLE}\n${MessageConstant.common.DELETE_ALERT_MESSAGE}`}
            visible={isShowDelete}
            onCancelClick={handleCancel}
            onDeleteClick={handleDelete}
        />
    </>;
}

export default memo(TariffCodeByCompanyTablePanel);

