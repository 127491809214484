import { ManualChargeHeaderEntity } from "domain/entity/ManualCharge/ManualChargeHeaderEntity";
import { EMPTY_NON_OPS_SEARCH_CRITERIA, NonOpsSearchCriteria } from "presentation/constant/NonOps/NonOpsSearchCriteria";
import { RefObject } from "react";
import { DropdownProps } from "../DropdownProps";
import { EMPTY_NON_OPS_PRINT_CRITERIA, NonOpsPrintCriteria } from "presentation/constant/NonOps/NonOpsPrintCriteria";


export interface NonOpsHeaderDropdownOptions {
    billingCycleDropdownOptions: DropdownProps[],
    stateDropdownOptions: DropdownProps[],
    customerCodeDropdownOptions: DropdownProps[],
    printerDropdownOptions: DropdownProps[]
}

export interface NonOpsHeaderModel {
    isLoading: boolean,
    isShowRightPanel: boolean,
    isShowPrintModal: boolean,
    searchCounter: number,
    searchCriteria: NonOpsSearchCriteria,
    printCriteria: NonOpsPrintCriteria,
    currentTableRef?: RefObject<any>,
    dynamicOptions: NonOpsHeaderDropdownOptions,
    isShowDetail: boolean,
    manualChargeHeaders: ManualChargeHeaderEntity[],
    currentManualChargeHeader: ManualChargeHeaderEntity | null,
    selectedManualChargeHeaderRows: ManualChargeHeaderEntity[],
    isShowCriteriaPanel: boolean,
    isBackFromDetail: boolean,
    isAllowAutoSearch: boolean,
    forceRefresh: boolean,
}

export const EMPTY_NON_OPS_HEADER_MODEL: NonOpsHeaderModel = {
    isLoading: false,
    isShowRightPanel: false,
    searchCounter: 0,
    searchCriteria: { ...EMPTY_NON_OPS_SEARCH_CRITERIA },
    dynamicOptions: {
        billingCycleDropdownOptions: [],
        stateDropdownOptions: [],
        customerCodeDropdownOptions: [],
        printerDropdownOptions: []
    },
    isShowDetail: false,
    manualChargeHeaders: [],
    currentManualChargeHeader: null,
    selectedManualChargeHeaderRows: [],
    isShowCriteriaPanel: false,
    isBackFromDetail: false,
    isAllowAutoSearch: true,
    forceRefresh: false,
    isShowPrintModal: false,
    printCriteria: {...EMPTY_NON_OPS_PRINT_CRITERIA}
}