import _ from "lodash";
import { CompanyConstant } from "presentation/constant/Company/CompanyConstant";
import { useChargeSuppleTermsVM } from "presentation/hook/Company/useChargeSuppleTermsVM";
import { useCompanyDetailVM } from "presentation/hook/Company/useCompanyDetailVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useCompanyDetailTracked } from "presentation/store/Company/CompanyDetailProvider";

import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DatePickerComponent from "presentation/view/components/DatePickerComponent";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import NumberInputComponent from "presentation/view/components/NumberInputComponent";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { FieldType, HPHButton, HPHCheckbox, HPHInputTextarea, IFieldValue, InputField, Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, SidebarTitle } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const ChargeSuppleTermsFormPanel = () => {
    const [companyDtlState] = useCompanyDetailTracked();
    const companyDtlVM = useCompanyDetailVM();
    const chargeSuppleTermsVM = useChargeSuppleTermsVM();
    const messageBarVM = useMessageBarVM();

    const COMPANY_DTL_CONST = CompanyConstant.Detail;
    const [isLoading, setIsLoading] = useState(false);
    const { dynamicOptions, companyId } = companyDtlState;
    const { isAdd, isEditable, isShowSuppleTerms, isEditCompDtlInfo, allFormState, isSaveClicked } = companyDtlState.viewState
    const { currentCompChgSuppleTerm, isDisableChargeInfo, isDisableCntrInfo, isDisableModality, isDisableSuppleTermRef, validateSchemaType } = companyDtlState.chargeSuppleTermsState;


    useEffect(() => {
        if (companyDtlState.viewState.isShowSuppleTerms) {
            setIsLoading(true);
            Promise.allSettled([
                chargeSuppleTermsVM.loadDropdownOption(companyDtlState.companyId),

            ]).then(data => {
                if (isAdd) {
                    chargeSuppleTermsVM.onInitDefaultValue()
                }
                setIsLoading(false);
            }).catch(error => {
                setIsLoading(false);
            });
        }
    }, [companyDtlState.isShowRightPanel, chargeSuppleTermsVM, companyDtlState.viewState.isShowSuppleTerms, isAdd, companyDtlState.companyId]);

    useEffect(() => {
        if (isEditable || isAdd || isShowSuppleTerms) {
            chargeSuppleTermsVM.onSuppleTermsChange("supplementaryTerms", currentCompChgSuppleTerm?.supplementaryTerms ?? '');
        }
    }, [chargeSuppleTermsVM, currentCompChgSuppleTerm?.supplementaryTerms, isAdd, isEditable, isShowSuppleTerms]);

    const memoSubChgTypeOptions = useMemo(() => {
        const groupOfSubChgTypeOptions = companyDtlState.dynamicOptions.subChargeTypeDropdownOptions[currentCompChgSuppleTerm.chargeType ?? ""];
        const commonSubChgTypeOptions = _.orderBy(_.uniqBy(_.flatMap(_.concat(groupOfSubChgTypeOptions ?? [])), 'value'), 'dropdownLabel');
        return commonSubChgTypeOptions;
    }, [companyDtlState.dynamicOptions.subChargeTypeDropdownOptions, currentCompChgSuppleTerm.chargeType]);

    const handleCancelClick = useCallback(() => {
        companyDtlVM.onCancel();
    }, [companyDtlVM])

    const isDisabled = useCallback(() => {
        if (!isEditCompDtlInfo || currentCompChgSuppleTerm?.status === "FINALIZED" || currentCompChgSuppleTerm?.status === "PROPOSED") return true;

        return false;
    }, [currentCompChgSuppleTerm?.status, isEditCompDtlInfo])

    const handleSaveClick = useCallback(() => {
        if (currentCompChgSuppleTerm) {
            setIsLoading(true);

            if (isAdd) {
                currentCompChgSuppleTerm.companyId = companyId;
            }

            companyDtlVM.onSaveClicked();
            chargeSuppleTermsVM.onSave(currentCompChgSuppleTerm, validateSchemaType, isAdd).then((data) => {
                if (data && data["saveChgSuppleTermsFailed"]) {
                    setIsLoading(false);
                    messageBarVM.showError(data["saveChgSuppleTermsFailed"]?.toString());
                    return;
                }

                companyId && chargeSuppleTermsVM.initialChgSuppleTermsTableData(companyId).then(() => {
                    setIsLoading(false);
                }).catch((error) => {
                    setIsLoading(false);
                });
            }).catch(() => {
                setIsLoading(false);
                messageBarVM.showError('Save Data Failed.');
            });
        }
    }, [chargeSuppleTermsVM, companyDtlVM, companyId, currentCompChgSuppleTerm, isAdd, messageBarVM, validateSchemaType])

    const onNumberFieldChange = useCallback((e: any, fieldName: string, fieldValue: any) => {
        chargeSuppleTermsVM.onFieldChange(fieldName, fieldValue);
    }, [chargeSuppleTermsVM]);

    const handleTextAreaChange = useCallback((event: any, fieldName: string) => {
        const fieldValue = event.target.value;
        chargeSuppleTermsVM.onFieldChange(fieldName, fieldValue);
    }, [chargeSuppleTermsVM])

    const supplementaryTermsRefVal = useCallback(() => {
        return dynamicOptions.suppleTermsRefDropdownOptions.find(item =>
            item.tagLabel === currentCompChgSuppleTerm?.supplementaryTermsRef)?.value ?? '';
    }, [currentCompChgSuppleTerm?.supplementaryTermsRef, dynamicOptions.suppleTermsRefDropdownOptions])

    const memoEffectiveDate = //useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "245px", marginBottom: "24px" }}>
            <DatePickerComponent
                disabled={isDisabled()}
                label={COMPANY_DTL_CONST.EFFECTIVE_DATE}
                width="245px"
                date={currentCompChgSuppleTerm?.effectiveDate ?? null}
                fieldName="effectiveDate"
                errorMessage={allFormState ? allFormState["effectiveDate"] : ""}
                onDateChange={chargeSuppleTermsVM.onFieldChange} />
        </div>
    //, [COMPANY_DTL_CONST.EFFECTIVE_DATE, allFormState, chargeSuppleTermsVM.onFieldChange, currentCompChgSuppleTerm?.effectiveDate, isDisabled])

    const memoOperatingTml = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "205px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isDisabled()}
                isShowOptional={true}
                isMultipleDropDown={false}
                disableInput={false}
                readOnlyValue={currentCompChgSuppleTerm?.operatingTml ?? ''}
                fieldValue={currentCompChgSuppleTerm?.operatingTml ?? ''}
                fieldLabel={COMPANY_DTL_CONST.OPERATING_TML}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'operatingTml'}
                options={dynamicOptions.operatingTmlDropdownOptions}
                maxLength={60}
                sort={false}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    chargeSuppleTermsVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [COMPANY_DTL_CONST.OPERATING_TML, allFormState, chargeSuppleTermsVM, currentCompChgSuppleTerm?.operatingTml, dynamicOptions.operatingTmlDropdownOptions, isDisabled])

    const memoSuppleTerms = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "245px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isDisabled()}
                isShowOptional={false}
                isMultipleDropDown={false}
                disableInput={false}
                readOnlyValue={currentCompChgSuppleTerm?.supplementaryTerms ?? ''}
                fieldValue={currentCompChgSuppleTerm?.supplementaryTerms ?? ''}
                fieldLabel={COMPANY_DTL_CONST.CHARGE_SUPPLE_TERMS.SUPPLEMENTARY_TERMS}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'supplementaryTerms'}
                options={dynamicOptions.suppleTermsDropdownOptions}
                isSaveClicked={isSaveClicked}
                maxLength={60}
                sort={false}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    chargeSuppleTermsVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [COMPANY_DTL_CONST.CHARGE_SUPPLE_TERMS.SUPPLEMENTARY_TERMS, allFormState, chargeSuppleTermsVM, currentCompChgSuppleTerm?.supplementaryTerms, dynamicOptions.suppleTermsDropdownOptions, isDisabled, isSaveClicked])

    const memoSuppleTermsRef = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "245px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isDisabled() || isDisableSuppleTermRef}
                isShowOptional={(validateSchemaType === "1" ? true : false)}
                isMultipleDropDown={false}
                disableInput={false}
                readOnlyValue={supplementaryTermsRefVal()}
                fieldValue={supplementaryTermsRefVal()}
                fieldLabel={COMPANY_DTL_CONST.CHARGE_SUPPLE_TERMS.SUPPLEMENTARY_TERMS_REF}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'supplementaryTermsRef'}
                options={dynamicOptions.suppleTermsRefDropdownOptions}
                isSaveClicked={isSaveClicked}
                maxLength={60}
                sort={false}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    chargeSuppleTermsVM.onSupplementaryTermsRefChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [COMPANY_DTL_CONST.CHARGE_SUPPLE_TERMS.SUPPLEMENTARY_TERMS_REF, allFormState, chargeSuppleTermsVM, dynamicOptions.suppleTermsRefDropdownOptions, isDisableSuppleTermRef, isDisabled, isSaveClicked, supplementaryTermsRefVal, validateSchemaType])

    const memoValue = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "205px", marginBottom: "24px" }}>
            <NumberInputComponent
                label={COMPANY_DTL_CONST.CHARGE_SUPPLE_TERMS.VALUE}
                maxLength={4}
                width="200px"
                fieldName={"value"}
                value={currentCompChgSuppleTerm?.value ?? ''}
                errorMessage={allFormState?.value}
                disabled={isDisabled()}
                optional={(validateSchemaType === "2" ? true : false)}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
        </div>
        , [COMPANY_DTL_CONST.CHARGE_SUPPLE_TERMS.VALUE, allFormState?.value, currentCompChgSuppleTerm?.value, isDisabled, onNumberFieldChange, validateSchemaType])

    const memoStaus = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputField
                width='205px'
                maxLength={4}
                label={COMPANY_DTL_CONST.STATUS}
                type="text"
                value={currentCompChgSuppleTerm?.status ?? 'NEW'}
                disabled={true}
                onChange={(e) => { }} />
        </div>
        , [COMPANY_DTL_CONST.STATUS, currentCompChgSuppleTerm?.status])

    const memoCntrMovement = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "245px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isDisabled() || isDisableCntrInfo}
                isShowOptional={true}
                isMultipleDropDown={false}
                disabled={false}
                readOnlyValue={currentCompChgSuppleTerm?.cntrMovement ?? ''}
                fieldValue={currentCompChgSuppleTerm?.cntrMovement ?? ''}
                fieldLabel={COMPANY_DTL_CONST.CNTR_MOVEMENT}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'cntrMovement'}
                options={dynamicOptions.cntrMovementDropdownOptions}
                maxLength={60}
                sort={false}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    chargeSuppleTermsVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [COMPANY_DTL_CONST.CNTR_MOVEMENT, allFormState, chargeSuppleTermsVM, currentCompChgSuppleTerm?.cntrMovement, dynamicOptions.cntrMovementDropdownOptions, isDisableCntrInfo, isDisabled])

    const memoCntrStatus = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "205px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isDisabled() || isDisableCntrInfo}
                isShowOptional={true}
                isMultipleDropDown={false}
                disabled={false}
                readOnlyValue={currentCompChgSuppleTerm?.cntrStatus ?? ''}
                fieldValue={currentCompChgSuppleTerm?.cntrStatus ?? ''}
                fieldLabel={COMPANY_DTL_CONST.CNTR_STATUS}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'cntrStatus'}
                options={dynamicOptions.cntrStatusDropdownOptions}
                maxLength={60}
                sort={false}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    chargeSuppleTermsVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [COMPANY_DTL_CONST.CNTR_STATUS, allFormState, chargeSuppleTermsVM, currentCompChgSuppleTerm?.cntrStatus, dynamicOptions.cntrStatusDropdownOptions, isDisableCntrInfo, isDisabled])

    const memoChargeType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "245px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isDisabled() || isDisableChargeInfo}
                isShowOptional={true}
                isMultipleDropDown={false}
                disabled={false}
                readOnlyValue={currentCompChgSuppleTerm?.chargeType ?? ''}
                fieldValue={currentCompChgSuppleTerm?.chargeType ?? ''}
                fieldLabel={COMPANY_DTL_CONST.CHARGE_TYPE}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'chargeType'}
                options={dynamicOptions.chargeTypeDropdownOptions}
                maxLength={60}
                sort={false}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    chargeSuppleTermsVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [COMPANY_DTL_CONST.CHARGE_TYPE, allFormState, chargeSuppleTermsVM, currentCompChgSuppleTerm?.chargeType, dynamicOptions.chargeTypeDropdownOptions, isDisableChargeInfo, isDisabled])

    const memoSubChargeType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "205px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isDisabled() || isDisableChargeInfo}
                isShowOptional={true}
                isMultipleDropDown={false}
                disabled={false}
                readOnlyValue={currentCompChgSuppleTerm?.subChargeType ?? ''}
                fieldValue={currentCompChgSuppleTerm?.subChargeType ?? ''}
                fieldLabel={COMPANY_DTL_CONST.SUB_CHARGE_TYPE}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'subChargeType'}
                options={memoSubChgTypeOptions}
                maxLength={60}
                sort={false}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    chargeSuppleTermsVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [COMPANY_DTL_CONST.SUB_CHARGE_TYPE, allFormState, chargeSuppleTermsVM, currentCompChgSuppleTerm?.subChargeType, isDisableChargeInfo, isDisabled, memoSubChgTypeOptions])

    const memoModality = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "245px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isDisabled() || isDisableModality}
                isShowOptional={true}
                isMultipleDropDown={false}
                disabled={false}
                readOnlyValue={currentCompChgSuppleTerm?.modality ?? ''}
                fieldValue={currentCompChgSuppleTerm?.modality ?? ''}
                fieldLabel={COMPANY_DTL_CONST.CHARGE_SUPPLE_TERMS.MODALITY}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'modality'}
                options={dynamicOptions.modalityDropdownOptions}
                maxLength={60}
                sort={false}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    chargeSuppleTermsVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [COMPANY_DTL_CONST.CHARGE_SUPPLE_TERMS.MODALITY, allFormState, chargeSuppleTermsVM, currentCompChgSuppleTerm?.modality, dynamicOptions.modalityDropdownOptions, isDisableModality, isDisabled])

    const memoRemarks = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "460px", marginBottom: "24px" }}>
            <HPHInputTextarea
                label={COMPANY_DTL_CONST.REMARKS}
                width="460px"
                rows={3}
                optional={true}
                disabled={isDisabled()}
                resizable={false}
                helpIcon={false}
                value={currentCompChgSuppleTerm?.remarks ?? ''}
                onChange={(e) => handleTextAreaChange(e, "remarks")} />
        </div>
        , [COMPANY_DTL_CONST.REMARKS, currentCompChgSuppleTerm?.remarks, handleTextAreaChange, isDisabled])

    const memoRejectReason = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "460px", marginBottom: "24px" }}>
            <HPHInputTextarea
                label={COMPANY_DTL_CONST.REJECT_REASON}
                width="460px"
                rows={3}
                disabled={true}
                resizable={false}
                helpIcon={false}
                value={currentCompChgSuppleTerm?.rejectReason ?? ''}
                onChange={() => { }} />
        </div>
        , [COMPANY_DTL_CONST.REJECT_REASON, currentCompChgSuppleTerm?.rejectReason])

    const memoActiveInd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "205px", marginBottom: "24px" }}>
            <NbisCommonField
                fieldKey={"activeInd"}
                requiredFieldList={[]}
                fieldLabel={COMPANY_DTL_CONST.ACTIVE_IND}
                fieldType={FieldType.TEXT}
                isReadOnly={true}
                onFieldChange={() => { }} />
            <HPHCheckbox
                errorMessages={allFormState}
                label={''}
                checked={currentCompChgSuppleTerm?.activeInd === 'Y' ? true : false}
                disabled={!isEditCompDtlInfo}
                onChange={(e) => chargeSuppleTermsVM.onCheckboxChange(e.checked, "activeInd")} />
        </div>
        , [COMPANY_DTL_CONST.ACTIVE_IND, allFormState, chargeSuppleTermsVM, currentCompChgSuppleTerm?.activeInd, isEditCompDtlInfo])

    return (
        <div className='side-form-content-wrapper'>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>{'Charge Supplementary Terms'}</SidebarTitle>
                    </Sidebarheader>
                </div>
            </div>

            {isLoading && <Loader Indicator="Spinner" size="Medium" />}

            <div className={'add-edit-form'} style={{ height: '75vh', maxHeight: '75vh', overflow: 'auto' }}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        <CriteriaItemContainer>
                            {memoEffectiveDate}
                            {memoOperatingTml}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoModality}
                            {memoStaus}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoCntrMovement}
                            {memoCntrStatus}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoChargeType}
                            {memoSubChargeType}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoSuppleTerms}
                            {memoValue}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoSuppleTermsRef}
                            {memoActiveInd}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoRemarks}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoRejectReason}
                        </CriteriaItemContainer>

                    </div>
                </div>
            </div>

            <div className='im-charge-data-search-criteria-buttons'>
                {
                    <>
                        <HPHButton label={(!isAdd && !isEditable) ? 'Close' : 'Cancel'} size={'Small'} theme={'Secondary'} onClick={() => handleCancelClick()} />
                        {(isAdd || isEditable) && <HPHButton label={isAdd ? 'Add' : isEditable ? 'Update' : 'Save'} size={'Small'} theme={'Primary'} onClick={() => handleSaveClick()} />}
                    </>
                }
            </div>

        </div>
    )
}
export default memo(ChargeSuppleTermsFormPanel);