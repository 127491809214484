import { EdiItemCodeMappingMaintenanceConstant } from "presentation/constant/EdiItemCodeMapping/EdiItemCodeMappingMaintenanceConstant";
import { useEdiItemCodeMappingMaintenanceVM } from "presentation/hook/EdiItemCodeMapping/useEdiItemCodeMappingMaintenanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useEdiItemCodeMappingMaintenanceTracked } from "presentation/store/EdiItemCodeMapping/EdiItemCodeMappingMaintenanceProvider";
import { memo, useCallback, useState } from "react";
import { HPHBreadcrumb, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const EdiItemCodeMappingEditPanelTitleBar: React.FC = () => {
    const [ediItemCodeMappingState] = useEdiItemCodeMappingMaintenanceTracked();
    const ediItemCodeMappingVM = useEdiItemCodeMappingMaintenanceVM();
    const messageBarVM = useMessageBarVM();
    const { currentSelectedRow, masterState } = ediItemCodeMappingState;
    const { editingEntity, isAdd, isEditable, isRead } = masterState;

    const [anainfoState] = useANAInfoTracked();
    const { allowCreate, allowUpdate } = anainfoState;
    const [isLoading, setIsLoading] = useState(false);
    const currentEntity = isRead ? currentSelectedRow : editingEntity;

    const handleReset = useCallback(async () => {
        ediItemCodeMappingVM.onReset();
    }, [ediItemCodeMappingVM]);

    const handleEdit = useCallback(() => {
        ediItemCodeMappingVM.onEditClicked();
    }, [ediItemCodeMappingVM])

    const handleSave = useCallback(async () => {
        setIsLoading(true);
        ediItemCodeMappingVM.onSaveClicked();
        const data = await ediItemCodeMappingVM.onSave(currentEntity, isAdd);
        if (data && data.success) {
            messageBarVM.showSuccess("Save successful.");
            ediItemCodeMappingVM.onSearch().then((data) => {
                setIsLoading(false);
            }).catch(error => {
                setIsLoading(false)
            });
        } else {
            messageBarVM.showError(data?.data ?? 'Save data failed.');
            setIsLoading(false);
            return;
        }


    }, [currentEntity, isAdd, messageBarVM, ediItemCodeMappingVM]);

    return <Sidebarheader style={{ width: '100%' }}>
        {isLoading && <Loader Indicator="Spinner" size="Medium" />}
        <Breadcrumb>
            <HPHBreadcrumb breadcrumbData={[{ title: EdiItemCodeMappingMaintenanceConstant.CATEGORY },
            { title: EdiItemCodeMappingMaintenanceConstant.TITLE }]} onCurrentClick={() => { }}>
            </HPHBreadcrumb>
        </Breadcrumb>
        <StyledAction>
            {!(isEditable || isAdd) &&
                <>
                    {/* Edit Button */}
                    {
                        (
                            (!(isEditable || isAdd) && allowUpdate)
                        )
                        && <IconButton fileName='Icon-pen' size='medium' toolTipText={'Edit'} toolTipArrow={false} onClick={handleEdit} />
                    }
                    {/* Close Button */}
                    <IconButton fileName='Icon-cross' size='medium' toolTipText={'Close'} toolTipArrow={false} onClick={ediItemCodeMappingVM.onClose} />
                </>}
            {(isEditable || isAdd) &&
                <>
                    {/* Reset Form Button */}
                    <IconButton fileName="Icon-reset" size="medium" toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Reset'} onClick={handleReset} />
                    {/* Close Button */}
                    <IconButton fileName="Icon-cross" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="left" toolTipText={'Close'} onClick={ediItemCodeMappingVM.onClose} />
                    {/* Save Button */}
                    {
                        (
                            (isAdd && allowCreate) ||
                            (isEditable && allowUpdate)
                        )
                        && <IconButton fileName="Icon-tick" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Save'} onClick={() => handleSave()} />
                    }
                </>}
        </StyledAction>
    </Sidebarheader>
}

export default memo(EdiItemCodeMappingEditPanelTitleBar);