import _ from "lodash";
import { RequisitionFormConstant } from "presentation/constant/RequisitionForm/RequisitionFormConstant";
import { useRequisitionFormVM } from "presentation/hook/RequisitionForm/useRequisitionFormVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useRequisitionFormMaintenanceTracked } from "presentation/store/RequisitionForm/RequisitionFormMaintenanceProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { useCallback, useMemo, useState } from "react";
import { FieldType, HPHButton, IFieldValue, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarCaption, Sidebarheader, SidebarTitle } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

export const RequisitionFormCustFormPanel = () => {
    const [requisitionFormState] = useRequisitionFormMaintenanceTracked();
    const requisitionFormVM = useRequisitionFormVM();
    const messageBarVM = useMessageBarVM();

    const REQ_FORM_CONSTANT = RequisitionFormConstant;
    const [isLoading, setIsLoading] = useState(false);

    const { detailState, dynamicOptions } = requisitionFormState;
    const { currentReqFormCust, reqFormCusts, isAdd, isEditable, isRead, isSaveClicked, allFormState } = detailState;

    const handleCancelClick = () => {
        requisitionFormVM.onReqFormCustCancel();
    }

    const validAddCust = useCallback(() => {
        if (isAdd && !_.isEmpty(reqFormCusts) && reqFormCusts.find(item =>
            item.billToCompany === currentReqFormCust.billToCompany && item.customerCode === currentReqFormCust.customerCode
        )) {
            return false;
        }

        return true;
    }, [currentReqFormCust.billToCompany, currentReqFormCust.customerCode, isAdd, reqFormCusts])

    const validEditCust = useCallback(() => {
        if (isEditable && !_.isEmpty(reqFormCusts) && reqFormCusts.find(item =>
            item.billToCompany === currentReqFormCust.billToCompany && item.customerCode === currentReqFormCust.customerCode
            && item.id !== currentReqFormCust.id
        )) {
            return false;
        }

        return true;
    }, [currentReqFormCust.billToCompany, currentReqFormCust.customerCode, currentReqFormCust.id, isEditable, reqFormCusts])

    const handleSaveClick = useCallback(async () => {
        if (!validAddCust() || !validEditCust()) {
            messageBarVM.showError('Duplicate record found, please check the key: Bill To Company, Customer Code');
            return;
        }

        setIsLoading(true);
        requisitionFormVM.onReqFormCustSaveClicked();
        const res = await requisitionFormVM.onTempSaveReqFormCust(currentReqFormCust, isAdd);
        setIsLoading(false);
        if (res) {
            messageBarVM.showError(res["mandatoryCheckFail"]);
        }
    }, [currentReqFormCust, isAdd, messageBarVM, requisitionFormVM, validAddCust, validEditCust])

    const memoBillToCompany = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={currentReqFormCust.billToCompany ?? ""}
                fieldValue={currentReqFormCust.billToCompany ?? ""}
                fieldLabel={REQ_FORM_CONSTANT.COMMON.BILL_TO_COMPANY}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'billToCompany'}
                isShowMissingError={true}
                maxLength={10}
                options={dynamicOptions.billToCompanyDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    requisitionFormVM.onReqFormCustFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentReqFormCust.billToCompany, REQ_FORM_CONSTANT.COMMON.BILL_TO_COMPANY, isSaveClicked, dynamicOptions.billToCompanyDropdownOptions, requisitionFormVM])

    const memoCustomerCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={currentReqFormCust.customerCode ?? ""}
                fieldValue={currentReqFormCust.customerCode ?? ""}
                fieldLabel={REQ_FORM_CONSTANT.COMMON.CUSTOMER_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'customerCode'}
                isShowMissingError={true}
                maxLength={10}
                options={dynamicOptions.customerCodeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    requisitionFormVM.onReqFormCustFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentReqFormCust.customerCode, REQ_FORM_CONSTANT.COMMON.CUSTOMER_CODE, isSaveClicked, dynamicOptions.customerCodeDropdownOptions, requisitionFormVM])

    return <>
        <div className='side-form-content-wrapper' style={{ position: 'relative', height: "100vh-128px" }}>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>
                            <SidebarTitle>{isAdd ? 'NEW REQUISITION FORM CUSTOMER' : ''}</SidebarTitle>
                            {(isAdd || (isEditable && !isRead)) && <SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>}
                        </SidebarTitle>
                    </Sidebarheader>
                </div>
            </div>

            {isLoading && <Loader Indicator="Spinner" size="Medium" />}

            <div className={'add-edit-form'} style={{ height: '50vh', maxHeight: '50vh', overflow: 'auto' }}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        <CriteriaItemContainer>
                            {memoBillToCompany}
                            {memoCustomerCode}
                        </CriteriaItemContainer>
                    </div>
                </div>
                <div className='im-charge-data-search-criteria-buttons' >
                    {
                        <>
                            <HPHButton label={isRead ? 'Close' : 'Cancel'} size={'Small'} theme={'Secondary'} onClick={handleCancelClick} />
                            {!isRead && <HPHButton label={isAdd ? 'Add' : "Update"} size={'Small'} theme={'Primary'} onClick={handleSaveClick} />}
                        </>
                    }
                </div>
            </div>
        </div>
    </>
}