import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { CompanyEntity, EMPTY_COMPANY_ENTITY } from "domain/entity/Company/CompanyEntity";
import { CompanyTariffCodeEntity, EMPTY_COMPANY_TARIFF_CODE_ENTITY } from "domain/entity/TariffCode/CompanyTariffCodeEntity";
import { EMPTY_TARIFF_CODE_COMPANY_REQUEST_ENTITY, TariffCodeCompanyRequestEntity } from "domain/entity/TariffCode/TariffCodeCompanyRequestEntity";
import { CompanyRepository } from "domain/repository/Company/CompanyRepo";
import { CompanyTariffCodeRepository } from "domain/repository/TariffCode/CompanyTariffCodeRepo";
import { StandardTariffCodeRepository } from "domain/repository/TariffCode/StandardTariffCodeRepo";
import _ from "lodash";
import { tariffCodePositiveIntegerNumberFieldList } from "presentation/constant/TariffCode/TariffCodeConstant";
import { createTariffCodeByCompanyDetailValidationSchema, createTariffCodeByCompanyValidationSchema } from "presentation/constant/TariffCodeByCompany/TariffCodeValidationSchema";
import { Validation } from "presentation/constant/Validation";
import { DropdownProps } from "presentation/model/DropdownProps";
import { TariffCodeByCompanyModel } from "presentation/model/TariffCodeByCompany/TariffCodeByCompanyModel";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";

interface TariffCodeByCompanyVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<TariffCodeByCompanyModel>> | ((value: SetStateAction<TariffCodeByCompanyModel>) => void),
    ],
    companyTariffCodeRepo: CompanyTariffCodeRepository,
    standardTariffCodeRepo: StandardTariffCodeRepository,
    companyRepo: CompanyRepository,
}

export const TariffCodeByCompanyVM = ({ dispatch, companyTariffCodeRepo, standardTariffCodeRepo, companyRepo }: TariffCodeByCompanyVMProps) => {
    const [tariffCodeByCompanyDispatch] = dispatch;

    const loadDropdownOption = async () => {
        await standardTariffCodeRepo.getAllStandardTariffCodes().then(
            tariffs => {

                let newTariffs = _.orderBy(tariffs, ["tariffType", "tariffCode"]);
                let tariffTypeDropdownOptions: DropdownProps[] = [];
                let tariffCodeDropdownOptions: { [key: string]: DropdownProps[] } = {};

                newTariffs.forEach(tariff => {
                    const isTariffTypeExisted = tariffTypeDropdownOptions.find(t =>
                        t.value === tariff.tariffType);
                    if (!isTariffTypeExisted) {
                        tariffTypeDropdownOptions.push({
                            dropdownLabel: tariff.tariffType,
                            tagLabel: tariff.tariffType,
                            value: tariff.tariffType,
                        })
                    }

                    if (tariff.tariffCode) {
                        if (!tariffCodeDropdownOptions[tariff.tariffType]) {
                            tariffCodeDropdownOptions[tariff.tariffType] = [];
                        }
                        tariffCodeDropdownOptions[tariff.tariffType].push({
                            dropdownLabel: tariff.tariffCode,
                            tagLabel: tariff.tariffCode,
                            value: tariff.tariffCode,
                        })
                    }
                })

                tariffCodeByCompanyDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        parentTariffCodeDropdownOptions: tariffCodeDropdownOptions,
                    }
                }))
            }
        )

        await companyRepo.getAllCompanyForCombobox().then(
            companies => {

                const companyDropdownOptions = companies?.map((company) => ({
                    dropdownLabel: company.companyCode,
                    tagLabel: company.companyCode,
                    value: company.companyCode,
                })) ?? [];

                tariffCodeByCompanyDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        companyDropdownOptions: companyDropdownOptions
                    }
                }))
            }
        )
    }

    const onSearchCompany = async () => {
        await companyTariffCodeRepo.findTariffCodeCompany().then((companies) => {
            tariffCodeByCompanyDispatch(prevState => ({
                ...prevState,
                isShowAddPanel: false,
                isShowDetail: false,
                companyEntityList: companies,
                companyTariffCodes: [],
                forceRefresh: !prevState.forceRefresh,
            }))
        }).catch((e) => {
            tariffCodeByCompanyDispatch(prevState => ({
                ...prevState,
                isShowAddPanel: false,
                isShowDetail: false,
                companyEntityList: [],
                companyTariffCodes: [],
            }))
        })
    }



    const updateSelectedRows = async (allRows: CompanyEntity[], selecedRows: CompanyEntity[]) => {
        tariffCodeByCompanyDispatch(prevState => {

            return {
                ...prevState,
                companyEntityList: [...allRows],
                selectedRows: selecedRows,
                // companyTariffCodes: [],
            }
        })
    }

    const onSearchCompanyTariffCode = async (entity: CompanyEntity) => {
        await companyTariffCodeRepo.findCompanyTariffCodeByCompanyId(entity.id).then((data) => {
            tariffCodeByCompanyDispatch(prevState => ({
                ...prevState,
                isShowAddPanel: false,
                isShowDetail: true,
                companyTariffCodes: data,
                currentSelectedRow: entity,
                detailState: {
                    ...prevState.detailState,
                    isEditable: false,
                    isRead: true,
                    isSliderOpen: false,
                    updatedRows: [],
                }
            }))

        });


    }

    const updateSelectedDetailRows = async (rows: CompanyTariffCodeEntity[]) => {
        tariffCodeByCompanyDispatch(prevState => {

            return {
                ...prevState,
                // companyTariffCodes:[...allRows],
                forceRefresh: !prevState.forceRefresh,
                detailState: {
                    ...prevState.detailState,
                    // companyTariffCodes:[...allRows],
                    selectedRows: rows,
                }
            }
        })
    }

    const onHeaderMultipleDropdownChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }
        tariffCodeByCompanyDispatch(prevState => ({
            ...prevState,
            masterState: {
                ...prevState.masterState,
                tariffCompanyRequest: {
                    ...prevState.masterState.tariffCompanyRequest,
                    [fieldKey]: val,
                },
                allFormState: {
                    ...prevState.masterState.allFormState,
                    [fieldKey]: '',
                }
            }
        }));
    };

    const onAddClick = () => {
        tariffCodeByCompanyDispatch(prevState => {

            return {
                ...prevState,
                isShowAddPanel: true,
                isShowDetail: false,
                companyTariffCodes: [],
                masterState: {
                    ...prevState.masterState,
                    tariffCompanyRequest: EMPTY_TARIFF_CODE_COMPANY_REQUEST_ENTITY,
                }
            }
        })
    }

    const onCancel = () => {
        tariffCodeByCompanyDispatch(prevState => {

            return {
                ...prevState,
                isShowAddPanel: false,
                masterState: {
                    ...prevState.masterState,
                    tariffCompanyRequest: EMPTY_TARIFF_CODE_COMPANY_REQUEST_ENTITY,
                    allFormState: {}
                }
            }
        })
    }

    const onSaveClicked = () => {
        tariffCodeByCompanyDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    isSaveClicked: true,
                    allFormState: {},
                }
            }
        })
    }

    const onSave = async (tariffCompanyRequest: TariffCodeCompanyRequestEntity) => {
        const valResult = await Validation(createTariffCodeByCompanyValidationSchema).ValidateFormOnly(tariffCompanyRequest);
        let validatedResult: { [x: string]: string } = {};
        if (valResult) {

            validatedResult = { ...validatedResult, ...valResult, warningMessage: 'Please input the missing value.' };



            tariffCodeByCompanyDispatch(prevState => {
                return {
                    ...prevState,
                    masterState: {
                        ...prevState.masterState,
                        allFormState: {
                            ...validatedResult
                        },
                    }
                }
            });

            const res: ResponseEntity = {
                code: "",
                success: false,
                msg: null,
                data: 'Please input the missing value.'
            }

            return res;
        }
        return await companyTariffCodeRepo.saveMultipleTariffCompany(tariffCompanyRequest.companines as string[]);
    }

    const onDelete = async (selectedRows: CompanyEntity[]) => {

        return await companyTariffCodeRepo.deleteMultipleTariffCompanines(selectedRows);
    }


    const onDetailRowDoubleClick = (row: CompanyTariffCodeEntity) => {
        tariffCodeByCompanyDispatch(prevState => {

            return {
                ...prevState,
                detailState: {
                    ...prevState.detailState,
                    currentSelectItem: row,
                    editingItem: {
                        ...row,
                        parentTariffType: row.parentTariffType ?? row.tariffType
                    },
                    isEditable: true,
                    isRead: false,
                    isSliderOpen: true,
                }
            }
        })
    }

    const onCloseDetail = () => {
        tariffCodeByCompanyDispatch(prevState => {
            return {
                ...prevState,
                isShowAddPanel: false,
                isShowDetail: false,
                companyTariffCodes: [],
                currentSelectedRow: EMPTY_COMPANY_ENTITY,
                isBackFromDetail: true,
                detailState: {
                    ...prevState.detailState,
                    currentSelectItem: EMPTY_COMPANY_TARIFF_CODE_ENTITY,
                    editingItem: EMPTY_COMPANY_TARIFF_CODE_ENTITY,
                    isEditable: false,
                    isRead: true,
                    isSliderOpen: false,
                    updatedRows: [],
                }
            }
        })
    }

    const onDetailEditCancel = () => {
        tariffCodeByCompanyDispatch(prevState => {

            return {
                ...prevState,
                forceRefresh: !prevState.forceRefresh,
                detailState: {
                    ...prevState.detailState,
                    currentSelectItem: EMPTY_COMPANY_TARIFF_CODE_ENTITY,
                    editingItem: EMPTY_COMPANY_TARIFF_CODE_ENTITY,
                    isEditable: false,
                    isRead: true,
                    isSliderOpen: false,
                }
            }
        })
    }

    const onDetailSaveClicked = () => {
        tariffCodeByCompanyDispatch(prevState => {
            return {
                ...prevState,
                detailState: {
                    ...prevState.detailState,
                    isSaveClicked: true,
                    allFormState: {},
                }
            }
        })
    }


    const onDetailSave = async (editingItem: CompanyTariffCodeEntity) => {

        const valResult = await Validation(createTariffCodeByCompanyDetailValidationSchema).ValidateFormOnly(editingItem);
        let validatedResult: { [x: string]: string } = {};
        if (valResult) {

            validatedResult = { ...validatedResult, ...valResult, warningMessage: 'Please input the missing value.' };



            tariffCodeByCompanyDispatch(prevState => {
                return {
                    ...prevState,
                    detailState: {
                        ...prevState.detailState,
                        allFormState: {
                            ...validatedResult
                        },
                    }
                }
            });

            return validatedResult;
        }

        const res = await companyTariffCodeRepo.replaceCompanyTariffCode(editingItem);
        if (!res.success) {
            return { ...validatedResult, warningMessage: res.data as string };
        }

        return tariffCodeByCompanyDispatch(prevState => {

            return {
                ...prevState,
                detailState: {
                    ...prevState.detailState,
                    currentSelectItem: EMPTY_COMPANY_TARIFF_CODE_ENTITY,
                    editingItem: EMPTY_COMPANY_TARIFF_CODE_ENTITY,
                    isEditable: false,
                    isRead: true,
                    isSliderOpen: false,
                }
            }
        })
    }

    const onDetailFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        tariffCodeByCompanyDispatch(prevState => {

            if (tariffCodePositiveIntegerNumberFieldList.includes(fieldKey)) {
                var posIntReg = /^\d*$/;
                if (!posIntReg.test(val)) {
                    val = prevState.detailState.editingItem[fieldKey];
                }
            }

            return {
                ...prevState,
                detailState: {
                    ...prevState.detailState,
                    editingItem: {
                        ...prevState.detailState.editingItem,
                        [fieldKey]: val,
                    },
                    allFormState: {
                        ...prevState.masterState.allFormState,
                        [fieldKey]: '',
                    }
                }
            }
        })
    }

    const onDetailRowDrag = (rows: CompanyTariffCodeEntity[]) => {
        tariffCodeByCompanyDispatch(prevState => {

            const prevRows = prevState.companyTariffCodes;
            const toChangedRows = rows.map((e, index) => ({ ...e, tempSeq: prevRows[index].seq })).filter(e => e.tempSeq !== e.seq);

            return ({
                ...prevState,
                companyTariffCodes: rows,
                detailState: {
                    ...prevState.detailState,
                    // companyTariffCodes: rows,
                    updatedRows: toChangedRows,
                }
            })
        })
    }

    const onDetailApply = async (updatedRows: CompanyTariffCodeEntity[]) => {


        return await companyTariffCodeRepo.onApply(updatedRows);
    }


    return {
        loadDropdownOption: loadDropdownOption,
        onSearchCompanyTariffCode: onSearchCompanyTariffCode,
        updateSelectedRows: updateSelectedRows,
        onHeaderMultipleDropdownChange: onHeaderMultipleDropdownChange,
        onAddClick: onAddClick,
        onCancel: onCancel,
        onSaveClicked: onSaveClicked,
        onSave: onSave,
        onDelete: onDelete,
        onSearchCompany: onSearchCompany,
        updateSelectedDetailRows: updateSelectedDetailRows,
        onDetailRowDoubleClick: onDetailRowDoubleClick,
        onCloseDetail: onCloseDetail,
        onDetailEditCancel: onDetailEditCancel,
        onDetailSaveClicked: onDetailSaveClicked,
        onDetailSave: onDetailSave,
        onDetailFieldChange: onDetailFieldChange,
        onDetailRowDrag: onDetailRowDrag,
        onDetailApply: onDetailApply,
    }
}