import moment from 'moment';
import * as yup from 'yup';
import { MessageConstant, formatMessage } from '../MessageConstant';
import { UserConStant } from './UserConstant';

export const commonUserValidation: { [x: string]: any; } = {
  name: yup.string().required("Missing"),
  validityFromDate: yup.date().required("Missing"),
  validityToDate: yup.date()
    .required("Missing")
    .when('validityFromDate', (validityFromDate, schema) => {
      if (validityFromDate) {
        return schema.test('is-after-from-date',
          formatMessage(MessageConstant.common.TO_GRATER_FROM,
            {
              "{fromDate}": UserConStant.user.VALIDITYDATEFROM,
              "{toDate}": UserConStant.user.VALIDITYDATETO
            }),
          function (value) {
            const fromDate = this.parent.validityFromDate;
            const toDate = moment(value);

            if (fromDate && toDate.isValid()) {
              return toDate.isSameOrAfter(moment(fromDate));
            }
            return false;
          });
      }
      return schema;
    }),
  emailAddress: yup.string()
    .required("Missing")
    .email(),
  defaultEntryPoint: yup.string().required("Missing"),
};

export const createUserValidation: { [x: string]: any; } = {
  ...commonUserValidation,
};

export const updateUserValidation: { [x: string]: any; } = {
  ...commonUserValidation,
};

export const createUserValidationSchema = yup.object().shape(createUserValidation);
export const updateUserValidationSchema = yup.object().shape(updateUserValidation);