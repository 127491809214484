import { EMPTY_SEARCH_MODE_ENTITY } from "domain/entity/ChargeDataEnquiry/SearchModeEntity";
import { ChargeDetailEntity } from "domain/entity/ChargeDetailMaintenance/ChargeDetailEntity";
import { E_Custom_Dispatch_Event, E_Type_Of_Event, customDispatchEvent } from "helpers/Events";
import { EMPTY_CHARGE_BATCH_UPDATE_ENTITY, type ChargeBatchUpdateEntity } from "presentation/constant/ChargeDetailMaintenance/ChargeBatchUpdateEntity";
import { ChargeDetailConstant } from "presentation/constant/ChargeDetailMaintenance/ChargeDetailConstant";
import { EMPTY_CHARGE_HEADER_SEARCH_CRITERIA } from "presentation/constant/ChargeHeader/ChargeHeaderSearchCriteria";
import { MessageConstant } from "presentation/constant/MessageConstant";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useChargeDetailVM } from "presentation/hook/ChargeDetailMaintenance/useChargeDetailVM";
import { useChargeHeaderMaintenanceVM } from "presentation/hook/ChargeHeaderMaintenance/useChargeHeaderMaintenanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useChargeDetailTracked } from "presentation/store/ChargeDetailMaintenance/ChargeDetailProvider";
import { useChargeHeaderMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/ChargeHeaderMaintenanceProvider";
import { ConfirmDeleteModal } from "presentation/view/components/ConfirmDeleteModal";
import { HeaderWithBackButton } from "presentation/view/components/HeaderWithBackButton/HeaderWithBackButton";
import { OverflowMenuButton } from "presentation/view/components/OverflowMenuButton";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { HPHBreadcrumb, HPHButton, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import { validateApply, validateSelectedSubChargeType } from "./ChargeDetailAssist";


const ChargeDetailTitleBar: React.FC = () => {
    const chargeDetailVM = useChargeDetailVM();
    const messageBarVM = useMessageBarVM();
    const chargeHeaderVM = useChargeHeaderMaintenanceVM();
    const [chargeDetailState, setChargeDetailState] = useChargeDetailTracked();
    const { isShowSummaryPanel, isShowBatchUpdatePanel, isShowCntrInfoPanel, isShowUpdateVesselVoyageMasterSearchPanel,
        isShowUpdateVesselVoyageMasterTablePanel, isShowAssignSeqNoPanel, selectedChargeDetails, isDisableAdjustment,
        isDisableApply, isDisableConfirm, isDisableDelete, isDisableUnconfirm, isShowUpdateStatus } = chargeDetailState;
    const { searchSeqCriteria } = chargeDetailState;
    const [chargeHeaderState] = useChargeHeaderMaintenanceTracked();
    const currentChargeHeader = chargeHeaderState?.currentChargeHeader ?? chargeDetailState?.currentChargeHeader;
    const CHARGEDETAIL_CONSTANT = ChargeDetailConstant.Charge;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [anainfoState] = useANAInfoTracked();
    const { allowUpdate, allowDelete } = anainfoState;
    const [isShowDelete, setIsShowDelete] = useState<boolean>(false);

    // const showCloseButton = localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_CHARGE_ITEM_SEARCH) ||
    //     localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_CHANGE_KEY_DATA) ||
    //     localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_INBOUND_SUMMARY) ||
    //     localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_VESSEL_SUMMARY);

    const handleClose = useCallback(() => {
        chargeDetailVM.onCloseClick();
    }, [chargeDetailVM]);

    //add buik edit check
    const handleEdit = useCallback(() => {
        if (selectedChargeDetails.length === 0) {
            messageBarVM.showError(MessageConstant.common.SELECT_A_RECORD_AT_LEAST);
        } else {
            let stateArr: string[] = ["I", "DI", "T", "MT", "H", "MH", "C"];
            let bool = false;
            for (let charge of selectedChargeDetails) {
                if (charge.dtlState && stateArr.includes(charge.dtlState)) {
                    bool = true;
                    break;
                }
            }
            if (bool) {
                messageBarVM.showError(stateArr + ' state charge can not do current operation!');
            } else {
                chargeDetailVM.onBulkEditClick(selectedChargeDetails);
            }
        }
    }, [chargeDetailVM, messageBarVM, selectedChargeDetails]);

    const handleUpdateCntrInfo = useCallback(() => {
        if (selectedChargeDetails.length === 0) {
            messageBarVM.showError(MessageConstant.common.SELECT_A_RECORD_AT_LEAST);
        } else {
            chargeDetailVM.onUpdateCntrInfoClick();
        }
    }, [chargeDetailVM, messageBarVM, selectedChargeDetails.length]);


    const handleUpdateVslVoySearch = useCallback(() => {
        if (selectedChargeDetails.length === 0) {
            messageBarVM.showError(MessageConstant.common.SELECT_A_RECORD_AT_LEAST);
        } else {
            chargeDetailVM.onUpdateVslVoySearchClick();
        }
    }, [chargeDetailVM, messageBarVM, selectedChargeDetails.length]);

    const handleAssignSeqNo = useCallback(() => {
        if (selectedChargeDetails.length === 0) {
            messageBarVM.showError(MessageConstant.common.SELECT_A_RECORD_AT_LEAST);
        } else {
            chargeDetailVM.initSeqCriteria(searchSeqCriteria, selectedChargeDetails);
            // chargeDetailVM.loadChargeDetailSeqList(searchSeqCriteria);
            chargeDetailVM.onAssignSeqNoClick(searchSeqCriteria);
        }
    }, [chargeDetailVM, messageBarVM, selectedChargeDetails, searchSeqCriteria]);

    const handleUpdateCntrStatus = useCallback(() => {
        if (selectedChargeDetails.length === 0) {
            messageBarVM.showError(MessageConstant.common.SELECT_A_RECORD_AT_LEAST);
        } else {
            const subChargeTypeValidation = validateSelectedSubChargeType(selectedChargeDetails);
            if (subChargeTypeValidation) {
                messageBarVM.showError(subChargeTypeValidation);
                return;
            }


            chargeDetailVM.onUpdateCntrStatusClick();
        }
    }, [chargeDetailVM, messageBarVM, selectedChargeDetails]);

    const handleApplyAll = useCallback(() => {
        setIsLoading(true);
        if (!currentChargeHeader) {
            chargeDetailVM.onHideLoading();
            return;
        }
        let newSearchCriteria = chargeDetailState.searchCriteria;
        newSearchCriteria = { ...newSearchCriteria, chargeHdrId: Number(chargeDetailState?.currentChargeHeader?.id ?? chargeDetailState?.currentChargeHeader?.id ?? chargeDetailState?.currentChargeHeader?.id ?? chargeHeaderState?.currentChargeHeader?.id) ?? 0 }
        chargeDetailVM.handleApplyAll(currentChargeHeader).then((data) => {
            Promise.allSettled([
                chargeDetailVM.getChargeDetails(newSearchCriteria),
                chargeHeaderVM.onSearch({
                    ...EMPTY_CHARGE_HEADER_SEARCH_CRITERIA,
                    id: chargeDetailState?.currentChargeHeader?.id ?? chargeHeaderState?.currentChargeHeader?.id
                }, EMPTY_SEARCH_MODE_ENTITY, true, true).then(data => {
                    if (data) {
                        setChargeDetailState(prevState => ({
                            ...prevState,
                            currentChargeHeader: data[0],
                        }));
                    }
                }),
            ]).finally(() => {
                if (data && data["applyAllChargeFail"]) {
                    const err: string = data["applyAllChargeFail"]?.toString() ?? "";
                    messageBarVM.showError(err.replace("Error:", ""));
                } else {
                    messageBarVM.showSuccess(MessageConstant.charge.APPLYALL_SUCCESSFUL);
                }

                setIsLoading(false);
            });
        }).catch((_error) => {
            setIsLoading(false);
        })
    }, [chargeDetailVM, currentChargeHeader, chargeDetailState.searchCriteria, chargeDetailState?.currentChargeHeader?.id, chargeHeaderState?.currentChargeHeader?.id,
        messageBarVM, chargeHeaderVM, setChargeDetailState]);

    const handleConfirmAll = useCallback(() => {
        setIsLoading(true);
        if (!currentChargeHeader) {
            chargeDetailVM.onHideLoading();
            return;
        }
        let newSearchCriteria = chargeDetailState.searchCriteria;
        newSearchCriteria = { ...newSearchCriteria, chargeHdrId: Number(chargeDetailState?.currentChargeHeader?.id ?? chargeHeaderState?.currentChargeHeader?.id) ?? 0 }
        chargeDetailVM.handleConfirmAll(currentChargeHeader).then((data) => {
            Promise.allSettled([
                chargeDetailVM.getChargeDetails(newSearchCriteria),
                chargeHeaderVM.onSearch({
                    ...EMPTY_CHARGE_HEADER_SEARCH_CRITERIA,
                    id: chargeDetailState?.currentChargeHeader?.id ?? chargeHeaderState?.currentChargeHeader?.id
                }, EMPTY_SEARCH_MODE_ENTITY, true, true).then(data => {
                    if (data) {
                        setChargeDetailState(prevState => ({
                            ...prevState,
                            currentChargeHeader: data[0],
                        }));
                    }
                }),
            ]).finally(() => {
                if (data && data["confirmAllChargeFail"]) {
                    const err: string = data["confirmAllChargeFail"]?.toString() ?? "";
                    messageBarVM.showError(err.replace("Error:", ""));
                } else {
                    messageBarVM.showSuccess(MessageConstant.charge.CONFIRMALL_SUCCESSFUL);
                }
                setIsLoading(false);
            });
        }).catch((_error) => {
            chargeDetailVM.onHideLoading();
            setIsLoading(false);
        })
    }, [chargeDetailVM, currentChargeHeader, chargeDetailState.searchCriteria, chargeDetailState?.currentChargeHeader?.id,
        chargeHeaderState?.currentChargeHeader?.id,
        messageBarVM, chargeHeaderVM, setChargeDetailState]);

    const handleApply = useCallback(() => {
        if (selectedChargeDetails.length === 0) {
            messageBarVM.showError(MessageConstant.common.SELECT_A_RECORD_AT_LEAST);
        } else {
            setIsLoading(true);
            const validationMessage = validateApply(selectedChargeDetails);
            if (validationMessage) {
                messageBarVM.showError(validationMessage);
                setIsLoading(false);
                return;
            }

            let newSearchCriteria = chargeDetailState.searchCriteria;
            newSearchCriteria = { ...newSearchCriteria, chargeHdrId: Number(chargeDetailState?.currentChargeHeader?.id ?? chargeHeaderState?.currentChargeHeader?.id) ?? 0 }
            chargeDetailVM.handleApply(selectedChargeDetails).then((data) => {
                Promise.allSettled([
                    chargeDetailVM.getChargeDetails(newSearchCriteria),
                    chargeHeaderVM.onSearch({
                        ...EMPTY_CHARGE_HEADER_SEARCH_CRITERIA,
                        id: chargeDetailState?.currentChargeHeader?.id ?? chargeHeaderState?.currentChargeHeader?.id
                    }, EMPTY_SEARCH_MODE_ENTITY, true, true).then(data => {
                        if (data) {
                            setChargeDetailState(prevState => ({
                                ...prevState,
                                currentChargeHeader: data[0],
                            }));
                        }
                    }),
                ]).finally(() => {
                    if (data && data["applyChargeFail"]) {
                        const err: string = data["applyChargeFail"]?.toString() ?? "";
                        messageBarVM.showError(err.replace("Error:", ""));
                    } else {
                        messageBarVM.showSuccess(MessageConstant.charge.APPLY_SUCCESSFUL);
                    }

                    setIsLoading(false);
                });
            }).catch((_error) => {
                setIsLoading(false);
            })
        }
    }, [chargeDetailState, chargeDetailVM, chargeHeaderState, messageBarVM, selectedChargeDetails,
        chargeHeaderVM, setChargeDetailState,]);

    const handleConfirm = useCallback(() => {
        if (selectedChargeDetails.length === 0) {
            messageBarVM.showError(MessageConstant.common.SELECT_A_RECORD_AT_LEAST);
        } else {
            let newSearchCriteria = chargeDetailState.searchCriteria;
            newSearchCriteria = { ...newSearchCriteria, chargeHdrId: Number(chargeDetailState?.currentChargeHeader?.id ?? chargeHeaderState?.currentChargeHeader?.id) ?? 0 }
            setIsLoading(true);
            chargeDetailVM.handleConfirm(selectedChargeDetails).then((data) => {
                Promise.allSettled([
                    chargeDetailVM.getChargeDetails(newSearchCriteria),
                    chargeHeaderVM.onSearch({
                        ...EMPTY_CHARGE_HEADER_SEARCH_CRITERIA,
                        id: chargeDetailState?.currentChargeHeader?.id ?? chargeHeaderState?.currentChargeHeader?.id
                    }, EMPTY_SEARCH_MODE_ENTITY, true, true).then(data => {
                        if (data) {
                            setChargeDetailState(prevState => ({
                                ...prevState,
                                currentChargeHeader: data[0],
                            }));
                        }
                    }),
                ]).finally(() => {
                    if (data && data["confirmChargeFail"]) {
                        const err: string = data["confirmChargeFail"]?.toString() ?? "";
                        messageBarVM.showError(err.replace("Error:", ""));
                    } else {
                        messageBarVM.showSuccess(MessageConstant.charge.CONFIRM_SUCCESSFUL);
                    }

                    setIsLoading(false);
                });
                setIsLoading(false);
            }).catch((_error) => {
                setIsLoading(false);
            })
        }
    }, [selectedChargeDetails, chargeDetailState, chargeHeaderState, chargeDetailVM, messageBarVM,
        chargeHeaderVM, setChargeDetailState]);

    const handleUnconfirm = useCallback(() => {
        if (selectedChargeDetails.length === 0) {
            messageBarVM.showError(MessageConstant.common.SELECT_A_RECORD_AT_LEAST);
        } else {
            let newSearchCriteria = chargeDetailState.searchCriteria;
            newSearchCriteria = { ...newSearchCriteria, chargeHdrId: Number(chargeDetailState?.currentChargeHeader?.id ?? chargeHeaderState?.currentChargeHeader?.id) ?? 0 }
            setIsLoading(true);
            chargeDetailVM.handleUnconfirm(selectedChargeDetails).then((data) => {
                Promise.allSettled([
                    chargeDetailVM.getChargeDetails(newSearchCriteria),
                    chargeHeaderVM.onSearch({
                        ...EMPTY_CHARGE_HEADER_SEARCH_CRITERIA,
                        id: chargeDetailState?.currentChargeHeader?.id ?? chargeHeaderState?.currentChargeHeader?.id
                    }, EMPTY_SEARCH_MODE_ENTITY, true, true).then(data => {
                        if (data) {
                            setChargeDetailState(prevState => ({
                                ...prevState,
                                currentChargeHeader: data[0],
                            }));
                        }
                    }),
                ]).finally(() => {
                    if (data && data["unconfirmChargeFail"]) {
                        const err: string = data["unconfirmChargeFail"]?.toString() ?? "";
                        messageBarVM.showError(err.replace("Error:", ""));
                    } else {
                        messageBarVM.showSuccess(MessageConstant.charge.UNCONFIRM_SUCCESSFUL);
                    }

                    setIsLoading(false);
                });
            }).catch((_error) => {
                setIsLoading(false);
            })
        }
    }, [chargeDetailState, chargeDetailVM, chargeHeaderState, messageBarVM, selectedChargeDetails,
        chargeHeaderVM, setChargeDetailState]);

    const handleUnconfirmAll = useCallback(() => {
        setIsLoading(true);
        if (!currentChargeHeader) {
            chargeDetailVM.onHideLoading();
            return;
        }
        let newSearchCriteria = chargeDetailState.searchCriteria;
        newSearchCriteria = { ...newSearchCriteria, chargeHdrId: Number(chargeDetailState?.currentChargeHeader?.id ?? chargeHeaderState?.currentChargeHeader?.id) ?? 0 }
        chargeDetailVM.handleUnconfirmAll(currentChargeHeader).then((data) => {
            Promise.allSettled([
                chargeDetailVM.getChargeDetails(newSearchCriteria),
                chargeHeaderVM.onSearch({
                    ...EMPTY_CHARGE_HEADER_SEARCH_CRITERIA,
                    id: chargeDetailState?.currentChargeHeader?.id ?? chargeHeaderState?.currentChargeHeader?.id
                }, EMPTY_SEARCH_MODE_ENTITY, true, true).then(data => {
                    if (data) {
                        setChargeDetailState(prevState => ({
                            ...prevState,
                            currentChargeHeader: data[0],
                        }));
                    }
                }),
            ]).finally(() => {
                if (data && data["unconfirmAllChargeFail"]) {
                    const err: string = data["unconfirmAllChargeFail"]?.toString() ?? "";
                    messageBarVM.showError(err.replace("Error:", ""));
                } else {
                    messageBarVM.showSuccess(MessageConstant.charge.UNCONFIRMALL_SUCCESSFUL);
                }

                setIsLoading(false);
            });
            setIsLoading(false);
        }).catch((_error) => {
            setIsLoading(false);
        })
    }, [chargeDetailVM, currentChargeHeader, chargeDetailState.searchCriteria, chargeDetailState?.currentChargeHeader?.id,
        chargeHeaderState?.currentChargeHeader?.id, messageBarVM,
        chargeHeaderVM, setChargeDetailState]);

    const handleAdjustment = useCallback(() => {
        if (selectedChargeDetails.length === 0) {
            messageBarVM.showError(MessageConstant.common.SELECT_A_RECORD_AT_LEAST);
        } else {
            chargeDetailVM.onAdjustmentClick(selectedChargeDetails);
        }
    }, [chargeDetailVM, messageBarVM, selectedChargeDetails]);

    const handleSummary = useCallback(() => {
        if (isShowSummaryPanel) {
            chargeDetailVM.onCloseSummary();
            return;
        }
        chargeDetailVM.onSummryClick();
    }, [chargeDetailVM, isShowSummaryPanel]);

    const handleLegend = useCallback(() => {
        chargeDetailVM.handleLegend();
    }, [chargeDetailVM]);

    const handleSearch = useCallback(() => {
        chargeDetailVM.onSearchClick();
    }, [chargeDetailVM]);

    const handleDelete = useCallback(() => {
        setIsLoading(true);
        setIsShowDelete(false);

        let newSearchCriteria = chargeDetailState.searchCriteria;
        newSearchCriteria = { ...newSearchCriteria, chargeHdrId: Number(chargeDetailState?.currentChargeHeader?.id ?? chargeHeaderState?.currentChargeHeader?.id) ?? 0 }

        chargeDetailVM.handleDeleteCharge(selectedChargeDetails).then((data) => {
            Promise.allSettled([
                chargeDetailVM.getChargeDetails(newSearchCriteria),
                chargeHeaderVM.onSearch({
                    ...EMPTY_CHARGE_HEADER_SEARCH_CRITERIA,
                    id: chargeDetailState?.currentChargeHeader?.id ?? chargeHeaderState?.currentChargeHeader?.id
                }, EMPTY_SEARCH_MODE_ENTITY, true, true).then(data => {
                    if (data) {
                        setChargeDetailState(prevState => ({
                            ...prevState,
                            currentChargeHeader: data[0],
                        }));
                    }
                }),
            ]).finally(() => {
                if (data && data["deleteChargeFail"]) {
                    const err: string = data["deleteChargeFail"]?.toString() ?? "";
                    messageBarVM.showError(err.replace("Error:", ""));
                } else {
                    messageBarVM.showSuccess(MessageConstant.common.DELETED_DATA_SUCCESSFUL);
                }
                setIsLoading(false);
            });
            setIsLoading(false);
        }).catch((_error) => {
            setIsLoading(false);
        })
    }, [chargeDetailVM, messageBarVM, selectedChargeDetails, chargeDetailState.searchCriteria, chargeDetailState?.currentChargeHeader?.id,
        chargeHeaderState?.currentChargeHeader?.id, chargeHeaderVM, setChargeDetailState]);

    const handleDeleteClick = useCallback(() => {
        setIsShowDelete(true);
    }, []);

    const handleCancel = () => {
        setIsShowDelete(false);
    }

    //add accTml/resume action
    const handleAccount = useCallback(async () => {
        if (selectedChargeDetails.length === 0) {
            messageBarVM.showError(MessageConstant.common.SELECT_A_RECORD_AT_LEAST);
        } else {
            let stateArr: string[] = ["I", "DI", "H", "MH", "C"];
            let bool = false;
            let isResume = 0;
            for (let charge of selectedChargeDetails) {
                if (charge.dtlState && stateArr.includes(charge.dtlState)) {
                    bool = true;
                    break;
                }
                if (charge.dtlState === "MT" || charge.dtlState === "T") {
                    isResume++;
                }
            }
            if (bool) {
                messageBarVM.showError(stateArr + ' state charge can not do current operation!');
            } else {
                if (selectedChargeDetails.length === isResume) {
                    setIsLoading(true);

                    let newSearchCriteria = chargeDetailState.searchCriteria;
                    newSearchCriteria = { ...newSearchCriteria, chargeHdrId: Number(chargeDetailState?.currentChargeHeader?.id ?? chargeHeaderState?.currentChargeHeader?.id) ?? 0 }

                    chargeDetailVM.onBatchUpdateSave(
                        {
                            ...EMPTY_CHARGE_BATCH_UPDATE_ENTITY,
                            accResumeTerminalInd: "N",
                            accResumeTerminalActive: true,
                        }, selectedChargeDetails).then(data => {
                            Promise.allSettled([
                                chargeDetailVM.getChargeDetails(newSearchCriteria),
                                chargeHeaderVM.onSearch({
                                    ...EMPTY_CHARGE_HEADER_SEARCH_CRITERIA,
                                    id: chargeDetailState?.currentChargeHeader?.id ?? chargeHeaderState?.currentChargeHeader?.id
                                }, EMPTY_SEARCH_MODE_ENTITY, true, true).then(data => {
                                    if (data) {
                                        setChargeDetailState(prevState => ({
                                            ...prevState,
                                            currentChargeHeader: data[0],
                                        }));
                                    }
                                }),
                            ]).finally(() => {
                                if (data && data["batchUpdteFail"]) {
                                    const err: string = data["batchUpdteFail"]?.toString() ?? "";
                                    messageBarVM.showError(err.replace("Error:", ""));
                                } else {
                                    messageBarVM.showSuccess(MessageConstant.charge.ACC_RESUME_TML_SUCCESSFUL);
                                }

                                setIsLoading(false);
                            });
                        }).catch(error => {
                            messageBarVM.showError(error.message);
                            setIsLoading(false);
                        })
                }
                if (isResume === 0) {
                    chargeDetailVM.onAccTmlClick();
                }
            }
        }
    }, [selectedChargeDetails, messageBarVM, chargeDetailVM,
        chargeDetailState.searchCriteria, chargeDetailState?.currentChargeHeader?.id, chargeHeaderState?.currentChargeHeader?.id,
        chargeHeaderVM, setChargeDetailState]);

    //add hold/unHold action
    const handleHold = useCallback(async () => {
        if (selectedChargeDetails.length === 0) {
            messageBarVM.showError(MessageConstant.common.SELECT_A_RECORD_AT_LEAST);
        } else {
            let stateArr: string[] = ["I", "DI", "T", "MT", "C"];
            let bool = false;
            for (let charge of selectedChargeDetails) {
                if (charge.dtlState && stateArr.includes(charge.dtlState)) {
                    bool = true;
                    break;
                }
            }
            if (bool) {
                messageBarVM.showError(stateArr + ' state charge can not do current operation!');
            } else {
                setIsLoading(true);
                let holdArr: ChargeDetailEntity[] = [];
                let unHoldArr: ChargeDetailEntity[] = [];
                for (let charge of selectedChargeDetails) {
                    if (charge.dtlState === "MH" || charge.dtlState === "H") {
                        unHoldArr.push(charge);
                    } else {
                        holdArr.push(charge);
                    }
                }
                let batchUpdate = EMPTY_CHARGE_BATCH_UPDATE_ENTITY;

                setIsLoading(true);

                let newSearchCriteria = chargeDetailState.searchCriteria;
                newSearchCriteria = { ...newSearchCriteria, chargeHdrId: Number(chargeDetailState?.currentChargeHeader?.id ?? chargeHeaderState?.currentChargeHeader?.id) ?? 0 }

                await Promise.allSettled([
                    unHoldArr && chargeDetailVM.onBatchUpdateSave({ ...batchUpdate, holdInd: "N", holdActive: true }, unHoldArr).then(data => {
                        if (data && data["batchUpdteFail"]) {
                            const err: string = data["batchUpdteFail"]?.toString() ?? "";
                            messageBarVM.showError(err.replace("Error:", ""));
                        }
                    }),
                    holdArr && chargeDetailVM.onBatchUpdateSave({ ...batchUpdate, holdInd: "Y", holdActive: true }, holdArr).then(data => {
                        if (data && data["batchUpdteFail"]) {
                            const err: string = data["batchUpdteFail"]?.toString() ?? "";
                            messageBarVM.showError(err.replace("Error:", ""));
                        }
                    })
                ]).then(_data => {
                    Promise.allSettled([
                        chargeDetailVM.getChargeDetails(newSearchCriteria),
                        chargeHeaderVM.onSearch({
                            ...EMPTY_CHARGE_HEADER_SEARCH_CRITERIA,
                            id: chargeDetailState?.currentChargeHeader?.id ?? chargeHeaderState?.currentChargeHeader?.id
                        }, EMPTY_SEARCH_MODE_ENTITY, true, true).then(data => {
                            if (data) {
                                setChargeDetailState(prevState => ({
                                    ...prevState,
                                    currentChargeHeader: data[0],
                                }));
                            }
                        }),
                    ]).finally(() => {
                        messageBarVM.showSuccess(MessageConstant.charge.HOLD_UNHOLD_SUCCESSFUL);
                        setIsLoading(false);
                    });
                }).catch(error => {
                    messageBarVM.showError(error.message);
                    setIsLoading(false);
                });
            }
        }
    }, [selectedChargeDetails, messageBarVM, chargeDetailVM,
        chargeDetailState.searchCriteria, chargeDetailState?.currentChargeHeader?.id,
        chargeHeaderState?.currentChargeHeader?.id, chargeHeaderVM, setChargeDetailState
    ]);

    //add Release Master Hold action
    const handleReleaseMasterHold = useCallback(async () => {
        if (selectedChargeDetails.length === 0) {
            messageBarVM.showError(MessageConstant.common.SELECT_A_RECORD_AT_LEAST);
        } else {
            let stateArr: string[] = ["I", "DI", "T", "MT", "C"];
            let bool = false;
            for (let charge of selectedChargeDetails) {
                if (charge.dtlState && stateArr.includes(charge.dtlState)) {
                    bool = true;
                    break;
                }
            }
            if (bool) {
                messageBarVM.showError(stateArr + ' state charge can not do current operation!');
            } else {
                setIsLoading(true);
                let batchUpdate = EMPTY_CHARGE_BATCH_UPDATE_ENTITY;

                let newSearchCriteria = chargeDetailState.searchCriteria;
                newSearchCriteria = { ...newSearchCriteria, chargeHdrId: Number(chargeDetailState?.currentChargeHeader?.id ?? chargeHeaderState?.currentChargeHeader?.id) ?? 0 }

                chargeDetailVM.onBatchUpdateSave({ ...batchUpdate, releaseInd: "Y", releaseActive: true }, selectedChargeDetails).then(data => {
                    Promise.allSettled([
                        chargeDetailVM.getChargeDetails(newSearchCriteria),
                        chargeHeaderVM.onSearch({
                            ...EMPTY_CHARGE_HEADER_SEARCH_CRITERIA,
                            id: chargeDetailState?.currentChargeHeader?.id ?? chargeHeaderState?.currentChargeHeader?.id
                        }, EMPTY_SEARCH_MODE_ENTITY, true, true).then(data => {
                            if (data) {
                                setChargeDetailState(prevState => ({
                                    ...prevState,
                                    currentChargeHeader: data[0],
                                }));
                            }
                        }),
                    ]).finally(() => {
                        if (data && data["batchUpdteFail"]) {
                            const err: string = data["batchUpdteFail"]?.toString() ?? "";
                            messageBarVM.showError(err.replace("Error:", ""));
                        } else {
                            messageBarVM.showSuccess(MessageConstant.charge.RELEASE_MASTER_HOLD_SUCCESSFUL);
                        }

                        setIsLoading(false);
                    });
                }).catch(error => {
                    messageBarVM.showError(error.message);
                    setIsLoading(false);
                })
            }
        }
    }, [chargeDetailVM, messageBarVM, selectedChargeDetails,
        chargeDetailState.searchCriteria, chargeDetailState?.currentChargeHeader?.id,
        chargeHeaderState?.currentChargeHeader?.id, chargeHeaderVM, setChargeDetailState
    ]);

    //add assign/resume one-off rate action
    const handleOneOffRate = useCallback(async () => {
        if (selectedChargeDetails.length === 0) {
            messageBarVM.showError(MessageConstant.common.SELECT_A_RECORD_AT_LEAST);
        } else {
            let stateArr: string[] = ["I", "DI", "T", "MT", "H", "MH", "C"];
            let bool = false;
            let isResume = 0;
            for (let charge of selectedChargeDetails) {
                if (charge.dtlState && stateArr.includes(charge.dtlState)) {
                    bool = true;
                    break;
                }
                if (charge.dtlState === "F" && charge.rateType === "ONEOFF") {
                    isResume++;
                }
            }
            if (bool) {
                messageBarVM.showError(stateArr + ' state charge can not do current operation!');
            } else {
                if (selectedChargeDetails.length === isResume) {
                    setIsLoading(true);
                    let batchUpdate = EMPTY_CHARGE_BATCH_UPDATE_ENTITY;

                    let newSearchCriteria = chargeDetailState.searchCriteria;
                    newSearchCriteria = { ...newSearchCriteria, chargeHdrId: Number(chargeDetailState?.currentChargeHeader?.id ?? chargeHeaderState?.currentChargeHeader?.id) ?? 0 }

                    chargeDetailVM.onBatchUpdateSave({ ...batchUpdate, oneOffRateInd: "N", oneOffRateActive: true }, selectedChargeDetails).then(data => {
                        Promise.allSettled([
                            chargeDetailVM.getChargeDetails(newSearchCriteria),
                            chargeHeaderVM.onSearch({
                                ...EMPTY_CHARGE_HEADER_SEARCH_CRITERIA,
                                id: chargeDetailState?.currentChargeHeader?.id ?? chargeHeaderState?.currentChargeHeader?.id
                            }, EMPTY_SEARCH_MODE_ENTITY, true, true).then(data => {
                                if (data) {
                                    setChargeDetailState(prevState => ({
                                        ...prevState,
                                        currentChargeHeader: data[0],
                                    }));
                                }
                            }),
                        ]).finally(() => {
                            if (data && data["batchUpdteFail"]) {
                                const err: string = data["batchUpdteFail"]?.toString() ?? "";
                                messageBarVM.showError(err.replace("Error:", ""));
                            } else {
                                messageBarVM.showSuccess(MessageConstant.charge.ASSIGN_ONE_OFF_RATE_SUCCESSFUL);
                            }

                            setIsLoading(false);
                        });
                    }).catch(error => {
                        messageBarVM.showError(error.message);
                        setIsLoading(false);
                    })
                }
                if (isResume === 0) {
                    chargeDetailVM.onOneOffRateClick();
                }
            }
        }
    }, [selectedChargeDetails, messageBarVM, chargeDetailVM,
        chargeDetailState.searchCriteria, chargeDetailState?.currentChargeHeader?.id,
        chargeHeaderState?.currentChargeHeader?.id, chargeHeaderVM, setChargeDetailState
    ]);

    const validateBundleMaster = useCallback(async (newBatchUpdate: ChargeBatchUpdateEntity) => {
        //if (!!!chargeDetailState.chargeBatchUpdate.bundleMasterNumber) return true;

        setIsLoading(true);
        return chargeDetailVM.validateMasterBundle(chargeDetailState.selectedChargeDetails,
            newBatchUpdate.bundleMasterNumber ?? "", newBatchUpdate.bundleMasterVisit ?? "").then((data) => {
                if (data && data["validateMasterBundleFail"]) {
                    const err: string = data["validateMasterBundleFail"]?.toString() ?? "";
                    messageBarVM.showError(err.replace("Error:", ""));
                    return false;
                }
                return true;
            }).catch((_error) => {
                return false;
            })
    }, [chargeDetailState.selectedChargeDetails, chargeDetailVM, messageBarVM]);

    const batchUpdateAction = useCallback(async (keepBatchUpdatePanel: boolean = false, newBatchUpdate: ChargeBatchUpdateEntity) => {
        return await chargeDetailVM.onBatchUpdateSave(newBatchUpdate, selectedChargeDetails, keepBatchUpdatePanel).then((data) => {
            let newSearchCriteria = chargeDetailState.searchCriteria;
            newSearchCriteria = { ...newSearchCriteria, chargeHdrId: Number(chargeDetailState?.currentChargeHeader?.id ?? chargeHeaderState?.currentChargeHeader?.id) ?? 0 }

            Promise.allSettled([
                chargeDetailVM.getChargeDetails(newSearchCriteria),
                chargeHeaderVM.onSearch({
                    ...chargeDetailState.searchCriteria,
                    id: chargeDetailState?.currentChargeHeader?.id ?? chargeHeaderState?.currentChargeHeader?.id
                }, EMPTY_SEARCH_MODE_ENTITY, true, true).then(data => {
                    if (data) {
                        setChargeDetailState(prevState => ({
                            ...prevState,
                            currentChargeHeader: data[0]
                        }));
                    }
                })
            ]).finally(() => {
                if (data && data["batchUpdteFail"]) {
                    messageBarVM.showError(data["batchUpdteFail"]?.toString());
                } else {
                    chargeDetailState.chargeBatchUpdate.emptyIndActive = false;
                    chargeDetailState.chargeBatchUpdate.emptyIndEnable = false;
                    messageBarVM.showSuccess(MessageConstant.common.OPERATION_DONE_SUCCESSFUL.replace(
                        "{operationDone}", ChargeDetailConstant.Charge.BULK_EDIT));
                }

                setIsLoading(false);
                chargeDetailVM.onBatchUpdateCloseClick();
            });
        }).catch((_error) => {
            setIsLoading(false);
            chargeDetailVM.onBatchUpdateCloseClick();
        }).finally(() => {
            setIsLoading(false);
            chargeDetailVM.onBatchUpdateCloseClick();
        });
    }, [chargeDetailState.chargeBatchUpdate, chargeDetailState?.currentChargeHeader?.id, chargeDetailState.searchCriteria, chargeDetailVM, chargeHeaderState?.currentChargeHeader?.id, chargeHeaderVM, messageBarVM, selectedChargeDetails, setChargeDetailState]);

    const handleUnBundleMasterNo = useCallback(async () => {
        setIsLoading(true);
        /*await chargeDetailVM.onUnbundleClick().then(() => {
            validateBundleMaster().then((data) => {
                if (data) {
                    batchUpdateAction(true);
                } else {
                    setIsLoading(false);
                }
            });
        });*/
        // await chargeDetailVM.onUnbundleClick();
        const newBatchUpdate = {
            ...EMPTY_CHARGE_BATCH_UPDATE_ENTITY,
            unBundleMasterNoActive: true,
            bundleMasterNumber: "",
            bundleMasterVisit: ""
        }
        await validateBundleMaster(newBatchUpdate).then((data) => {
            if (data) {
                batchUpdateAction(true, newBatchUpdate);
            }
            else {
                setIsLoading(false)
            };
        });
        //await 

    }, [batchUpdateAction, validateBundleMaster]);

    //add  AssignCommonAccount action
    const handleAssignCommonAccount = useCallback(async () => {
        if (selectedChargeDetails.length === 0) {
            messageBarVM.showError(MessageConstant.common.SELECT_A_RECORD_AT_LEAST);
        } else {
            let ids: string[] = [];
            let filterList: ChargeDetailEntity[] = [];
            for (let charge of selectedChargeDetails) {
                if (valAssignComonAc(charge)) {
                    ids.push(charge.key);
                } else {
                    filterList.push(charge);
                }
            }
            if (filterList.length > 0) {
                let dialogChgMsgStr = dialogChgMsgForAssignCommonAc(filterList);
                let confirmMsgStr: string = "";
                confirmMsgStr = "Existed can't assign common account in the selected charge(s), are you sure to continue?";
                if ("" !== dialogChgMsgStr) {
                    confirmMsgStr = confirmMsgStr + "\n" + dialogChgMsgStr;
                }
                messageBarVM.showError(confirmMsgStr);
                // let result: boolean = window.confirm(confirmMsgStr);
                // if (!result) {
                //     return;
                // }
            }
            setIsLoading(true);

            let newSearchCriteria = chargeDetailState.searchCriteria;
            newSearchCriteria = { ...newSearchCriteria, chargeHdrId: Number(chargeDetailState?.currentChargeHeader?.id ?? chargeHeaderState?.currentChargeHeader?.id) ?? 0 }

            chargeDetailVM.assignCommonAccount(ids).then(data => {
                Promise.allSettled([
                    chargeDetailVM.getChargeDetails(newSearchCriteria),
                    chargeHeaderVM.onSearch({
                        ...EMPTY_CHARGE_HEADER_SEARCH_CRITERIA,
                        id: chargeDetailState?.currentChargeHeader?.id ?? chargeHeaderState?.currentChargeHeader?.id
                    }, EMPTY_SEARCH_MODE_ENTITY, true, true).then(data => {
                        if (data) {
                            setChargeDetailState(prevState => ({
                                ...prevState,
                                currentChargeHeader: data[0],
                            }));
                        }
                    }),
                ]).finally(() => {
                    if (data && data["assignCommonAccountFail"]) {
                        const err: string = data["assignCommonAccountFail"]?.toString() ?? "";
                        messageBarVM.showError(err.replace("Error:", ""));
                    } else {
                        messageBarVM.showSuccess(MessageConstant.charge.ASSIGN_COMMON_ACCOUNT_SUCCESSFUL);
                    }

                    setIsLoading(false);
                });
            }).catch(error => {
                messageBarVM.showError(error.message);
                setIsLoading(false);
            });
        }

    }, [chargeDetailVM, messageBarVM, selectedChargeDetails,
        chargeDetailState.searchCriteria, chargeDetailState?.currentChargeHeader?.id,
        chargeHeaderState?.currentChargeHeader?.id, chargeHeaderVM, setChargeDetailState]);

    //check the charge is storage
    function valAssignComonAc(charge: ChargeDetailEntity): boolean {
        if ("DAY" === charge.chargeQtyUom
            && (charge.storageFromDate === null
                || charge.storageToDate === null)) {
            return false;
        }
        return true;
    }

    // append the confirm dialog message
    function dialogChgMsgForAssignCommonAc(charges: ChargeDetailEntity[]): string {
        let msgList: string[] = [];
        if (charges.length > 0) {

            let isStopLoop: boolean = false;
            let msgStr: string = "";
            for (let i = 0; i < charges.length; i++) {
                msgStr = "";
                msgStr = charges[i].cntrNo + "(" + charges[i].chargeType + "-" + charges[i].subChargeType + ")";
                if (i >= 10) {
                    msgStr = "...";
                    isStopLoop = true;
                }
                msgList.push(msgStr);
                if (isStopLoop) {
                    break;
                }
            }
        }
        if (msgList.length > 0) {
            return msgList.join(" ");
        }
        return "";
    }

    //add  ReverseCommonAccount action
    const handleReverseCommonAccount = useCallback(async () => {
        let confirmMsgStr: string = "";
        confirmMsgStr = "Are you sure to reverse selected and related common account charge(s)?";
        let result: boolean = window.confirm(confirmMsgStr);
        if (!result) {
            return;
        }
        let commonAcSeqs: Object[] = [];
        for (let charge of selectedChargeDetails) {
            if (charge.commonAcSeq && !commonAcSeqs.includes(charge.commonAcSeq)) {
                commonAcSeqs.push(charge.commonAcSeq);
            }
        }
        setIsLoading(true);

        let newSearchCriteria = chargeDetailState.searchCriteria;
        newSearchCriteria = { ...newSearchCriteria, chargeHdrId: Number(chargeDetailState?.currentChargeHeader?.id ?? chargeHeaderState?.currentChargeHeader?.id) ?? 0 }

        chargeDetailVM.reverseCommonAccount(commonAcSeqs).then(data => {
            Promise.allSettled([
                chargeDetailVM.getChargeDetails(newSearchCriteria),
                chargeHeaderVM.onSearch({
                    ...EMPTY_CHARGE_HEADER_SEARCH_CRITERIA,
                    id: chargeDetailState?.currentChargeHeader?.id ?? chargeHeaderState?.currentChargeHeader?.id
                }, EMPTY_SEARCH_MODE_ENTITY, true, true).then(data => {
                    if (data) {
                        setChargeDetailState(prevState => ({
                            ...prevState,
                            currentChargeHeader: data[0],
                        }));
                    }
                }),
            ]).finally(() => {
                if (data && data["reverseCommonAccountFail"]) {
                    const err: string = data["reverseCommonAccountFail"]?.toString() ?? "";
                    messageBarVM.showError(err.replace("Error:", ""));
                } else {
                    messageBarVM.showSuccess(MessageConstant.charge.REVERSE_COMMON_ACCOUNT_SUCCESSFUL);
                }

                setIsLoading(false);
            });
        }).catch(error => {
            messageBarVM.showError(error.message);
            setIsLoading(false);
        });
    }, [chargeDetailVM, selectedChargeDetails, messageBarVM,
        chargeDetailState.searchCriteria, chargeDetailState?.currentChargeHeader?.id,
        chargeHeaderState?.currentChargeHeader?.id, chargeHeaderVM, setChargeDetailState]);

    //add  RedirectCommonAccount action
    const handleRedirectCommonAccount = useCallback(async () => {
        // let confirmMsgStr: string = "";        
        // confirmMsgStr= "Are you sure to re-direct selected common account charge(s)?";
        // let result: boolean = window.confirm(confirmMsgStr);
        // if (!result) {
        //     return;
        // }
        let ids: string[] = [];
        for (let charge of selectedChargeDetails) {
            ids.push(charge.key);
        }

        let newSearchCriteria = chargeDetailState.searchCriteria;
        newSearchCriteria = { ...newSearchCriteria, chargeHdrId: Number(chargeDetailState?.currentChargeHeader?.id ?? chargeHeaderState?.currentChargeHeader?.id) ?? 0 }

        setIsLoading(true);
        chargeDetailVM.redirectCommonAccount(ids).then(data => {
            Promise.allSettled([
                chargeDetailVM.getChargeDetails(newSearchCriteria),
                chargeHeaderVM.onSearch({
                    ...EMPTY_CHARGE_HEADER_SEARCH_CRITERIA,
                    id: chargeDetailState?.currentChargeHeader?.id ?? chargeHeaderState?.currentChargeHeader?.id
                }, EMPTY_SEARCH_MODE_ENTITY, true, true).then(data => {
                    if (data) {
                        setChargeDetailState(prevState => ({
                            ...prevState,
                            currentChargeHeader: data[0],
                        }));
                    }
                }),
            ]).finally(() => {
                if (data && data["redirectCommonAccountFail"]) {
                    const err: string = data["redirectCommonAccountFail"]?.toString() ?? "";
                    messageBarVM.showError(err.replace("Error:", ""));
                } else {
                    messageBarVM.showSuccess(MessageConstant.charge.REDIRECT_COMMON_ACCOUNT_SUCCESSFUL);
                }

                setIsLoading(false);
            });
        }).catch(error => {
            messageBarVM.showError(error.message);
            setIsLoading(false);
        });

    }, [chargeDetailVM, selectedChargeDetails, messageBarVM,
        chargeDetailState.searchCriteria, chargeDetailState?.currentChargeHeader?.id,
        chargeHeaderState?.currentChargeHeader?.id, chargeHeaderVM, setChargeDetailState]);

    // add update common account action
    const handleUpdateCommonAccount = useCallback(() => {
        if (selectedChargeDetails.length === 0) {
            messageBarVM.showError(MessageConstant.common.SELECT_A_RECORD_AT_LEAST);
        } else {
            chargeDetailVM.updateCommonAccountClick();
        }

    }, [selectedChargeDetails.length, messageBarVM, chargeDetailVM]);

    // add copy common account action
    const handleCopyCommonAccount = useCallback(() => {
        if (selectedChargeDetails.length === 0) {
            messageBarVM.showError(MessageConstant.common.SELECT_A_RECORD_AT_LEAST);
        } else {
            chargeDetailVM.copyCommonAccountClick();
        }
    }, [chargeDetailVM, messageBarVM, selectedChargeDetails.length]);

    const memoCommonAccountMenu = useMemo(() => {
        return [
            {
                title: ChargeDetailConstant.Charge.UNCONFIRM_BUT,
                disabled: isDisableUnconfirm,
                command: () => {
                    handleUnconfirm();
                }
            },
            {
                title: ChargeDetailConstant.Charge.UNCONFIRM_ALL_BUT,
                command: () => {
                    handleUnconfirmAll();
                }
            },
            {
                title: ChargeDetailConstant.Charge.ADJUSTMENT_BUT,
                disabled: isDisableAdjustment,
                command: () => {
                    handleAdjustment();
                }
            },
            {
                title: ChargeDetailConstant.Charge.DELETE_BUT,
                disabled: (!allowDelete) || isDisableDelete,
                command: () => {
                    handleDeleteClick();
                }
            },
            {
                title: ChargeDetailConstant.Charge.ACC_RESUME_TML_BUT,
                disabled: !chargeDetailState.chargeBatchUpdate.accResumeTerminalEnable,
                command: () => {
                    handleAccount();
                }
            },
            {
                title: ChargeDetailConstant.Charge.HOLD_UNHOLD_BUT,
                disabled: !chargeDetailState.chargeBatchUpdate.holdEnable,
                command: () => {
                    handleHold();
                }
            },
            {
                title: ChargeDetailConstant.Charge.RELEASE_MASTER_HOLD_BUT,
                disabled: !chargeDetailState.chargeBatchUpdate.releaseEnable,
                command: () => {
                    handleReleaseMasterHold();
                }
            },
            {
                title: ChargeDetailConstant.Charge.ASS_RESUME_ONEOFF_BUT,
                disabled: !chargeDetailState.chargeBatchUpdate.oneOffRateEnable,
                command: () => {
                    handleOneOffRate();
                }
            },
            {
                title: ChargeDetailConstant.Charge.UN_BUNDLE_MASTER_NO,
                disabled: !chargeDetailState.chargeBatchUpdate.unBundleMasterNoEnable,
                command: () => {
                    handleUnBundleMasterNo();
                }
            },
            {
                title: ChargeDetailConstant.Charge.COMMON_ACCOUNT_BUT,
                disabled: !chargeDetailState.chargeBatchUpdate.copyCommonAcEnable &&
                    !chargeDetailState.chargeBatchUpdate.uptAbsolutelyCommonAcEnable &&
                    !chargeDetailState.chargeBatchUpdate.redirectCommonAccEnable &&
                    !chargeDetailState.chargeBatchUpdate.reverseCommonAccEnable &&
                    !chargeDetailState.chargeBatchUpdate.assCommonAccEnable,
                submenu: [
                    {
                        title: ChargeDetailConstant.Charge.ASSING_COMMON_ACCOUNT_BUT,
                        disabled: !chargeDetailState.chargeBatchUpdate.assCommonAccEnable,
                        command: () => {
                            handleAssignCommonAccount();
                        }
                    },
                    {
                        title: ChargeDetailConstant.Charge.REVERSE_COMMON_ACCOUNT_BUT,
                        disabled: !chargeDetailState.chargeBatchUpdate.reverseCommonAccEnable,
                        command: () => {
                            handleReverseCommonAccount();
                        }
                    },
                    {
                        title: ChargeDetailConstant.Charge.REDIRECT_COMMON_ACCOUNT_BUT,
                        disabled: !chargeDetailState.chargeBatchUpdate.redirectCommonAccEnable,
                        command: () => {
                            handleRedirectCommonAccount();
                        }
                    },
                    {
                        title: ChargeDetailConstant.Charge.UPDATE_100_COMMON_ACCOUNT_BUT,
                        disabled: !chargeDetailState.chargeBatchUpdate.uptAbsolutelyCommonAcEnable,
                        command: () => {
                            handleUpdateCommonAccount();
                        }
                    },
                    {
                        title: ChargeDetailConstant.Charge.COPY_COMMON_ACCOUNT_TO_BUT,
                        disabled: !chargeDetailState.chargeBatchUpdate.copyCommonAcEnable,
                        command: () => {
                            handleCopyCommonAccount();
                        }
                    }
                ]
            },
        ]
    }, [handleAccount, handleHold, handleReleaseMasterHold, handleOneOffRate, handleAssignCommonAccount, handleReverseCommonAccount,
        handleRedirectCommonAccount, handleUpdateCommonAccount, handleCopyCommonAccount, handleAdjustment, handleDeleteClick,
        handleUnconfirm, handleUnconfirmAll, handleUnBundleMasterNo, isDisableAdjustment, isDisableDelete, isDisableUnconfirm,
        chargeDetailState.chargeBatchUpdate.accResumeTerminalEnable, chargeDetailState.chargeBatchUpdate.assCommonAccEnable,
        chargeDetailState.chargeBatchUpdate.copyCommonAcEnable, chargeDetailState.chargeBatchUpdate.holdEnable,
        chargeDetailState.chargeBatchUpdate.oneOffRateEnable, chargeDetailState.chargeBatchUpdate.redirectCommonAccEnable,
        chargeDetailState.chargeBatchUpdate.releaseEnable, chargeDetailState.chargeBatchUpdate.reverseCommonAccEnable,
        chargeDetailState.chargeBatchUpdate.uptAbsolutelyCommonAcEnable, chargeDetailState.chargeBatchUpdate.unBundleMasterNoEnable, allowDelete]);



    const memoEditMenu = useMemo(() => {
        return [
            {
                title: ChargeDetailConstant.Charge.BULK_EDIT,
                disabled: !chargeDetailState.chargeBatchUpdate.billToCompanyEnable && !chargeDetailState.chargeBatchUpdate.chargeOnCompanyEnable &&
                    !chargeDetailState.chargeBatchUpdate.alterableBillToCompanyEnable && !chargeDetailState.chargeBatchUpdate.alterableChargeOnCompanyEnable &&
                    !chargeDetailState.chargeBatchUpdate.sizeEnable &&
                    !chargeDetailState.chargeBatchUpdate.cntrTypeEnable && !chargeDetailState.chargeBatchUpdate.billingTypeEnable &&
                    !chargeDetailState.chargeBatchUpdate.pooEnable && !chargeDetailState.chargeBatchUpdate.polEnable &&
                    !chargeDetailState.chargeBatchUpdate.podEnable && !chargeDetailState.chargeBatchUpdate.fdEnable &&
                    !chargeDetailState.chargeBatchUpdate.emptyIndEnable &&
                    !chargeDetailState.chargeBatchUpdate.operatingTmlEnable && !chargeDetailState.chargeBatchUpdate.manualTsLegChargeIndEnable &&
                    !chargeDetailState.chargeBatchUpdate.ignoreCntrStatusValidEnable && !chargeDetailState.chargeBatchUpdate.dgContentEnable &&
                    !chargeDetailState.chargeBatchUpdate.specialIndEnable && !chargeDetailState.chargeBatchUpdate.rfTempEnable &&
                    !chargeDetailState.chargeBatchUpdate.manualAdjEnable && !chargeDetailState.chargeBatchUpdate.soNumberEnable &&
                    !chargeDetailState.chargeBatchUpdate.waiverNoEnable &&
                    !chargeDetailState.chargeBatchUpdate.dischargeIndEnable && !chargeDetailState.chargeBatchUpdate.seclegDischargeIndEnable &&
                    !chargeDetailState.chargeBatchUpdate.seclegOperatingTmlEnable && !chargeDetailState.chargeBatchUpdate.seclegSpecialIndEnable &&
                    !chargeDetailState.chargeBatchUpdate.permitNoEnable && !chargeDetailState.chargeBatchUpdate.rfInfoEnable &&
                    !chargeDetailState.chargeBatchUpdate.emptyChargeQtyEnable && !chargeDetailState.chargeBatchUpdate.bundleMasterNoEnable &&
                    !chargeDetailState.chargeBatchUpdate.tierFspEnable && !chargeDetailState.chargeBatchUpdate.voltageEnable &&
                    !chargeDetailState.chargeBatchUpdate.offStdMeasurementModeEnable && !chargeDetailState.chargeBatchUpdate.marshallingTypeEnable &&
                    !chargeDetailState.chargeBatchUpdate.specialHandlingCodeEnable && !chargeDetailState.chargeBatchUpdate.vesselInfoEnable &&
                    !chargeDetailState.chargeBatchUpdate.tsVesselInfoEnable,
                command: () => {
                    handleEdit();
                }
            },
            {
                title: ChargeDetailConstant.Charge.UPDATE_CONTAINER_INFO,
                disabled: !chargeDetailState.chargeBatchUpdate.cntrDtlEnable,
                command: () => {
                    handleUpdateCntrInfo();
                }
            },
            {
                title: ChargeDetailConstant.Charge.UPDATE_VESSEL_VOYAGE_BUT,
                disabled: !chargeDetailState.chargeBatchUpdate.updateVslVoyEnable,
                command: () => {
                    handleUpdateVslVoySearch();
                }
            },
            {
                title: ChargeDetailConstant.Charge.ASSIGN_SEQ_NO,
                disabled: !chargeDetailState.chargeBatchUpdate.seqEnable,
                command: () => {
                    handleAssignSeqNo();
                }
            },
            {
                title: ChargeDetailConstant.Charge.UPDATE_CNTR_STATUS_BUT,
                disabled: !chargeDetailState.chargeBatchUpdate.statusEnable && !chargeDetailState.chargeBatchUpdate.status2Enable,
                command: () => {
                    handleUpdateCntrStatus();
                }
            },
        ]
    }, [handleAssignSeqNo, handleEdit, handleUpdateCntrInfo, handleUpdateVslVoySearch, chargeDetailState.chargeBatchUpdate.seqEnable,
        chargeDetailState.chargeBatchUpdate.cntrDtlEnable, chargeDetailState.chargeBatchUpdate.updateVslVoyEnable,
        chargeDetailState.chargeBatchUpdate.statusEnable, handleUpdateCntrStatus,
        chargeDetailState.chargeBatchUpdate.alterableBillToCompanyEnable,
        chargeDetailState.chargeBatchUpdate.alterableChargeOnCompanyEnable, chargeDetailState.chargeBatchUpdate.billToCompanyEnable,
        chargeDetailState.chargeBatchUpdate.billingTypeEnable, chargeDetailState.chargeBatchUpdate.chargeOnCompanyEnable,
        chargeDetailState.chargeBatchUpdate.cntrTypeEnable,
        chargeDetailState.chargeBatchUpdate.dgContentEnable, chargeDetailState.chargeBatchUpdate.dischargeIndEnable,
        chargeDetailState.chargeBatchUpdate.emptyChargeQtyEnable, chargeDetailState.chargeBatchUpdate.emptyIndEnable,
        chargeDetailState.chargeBatchUpdate.fdEnable, chargeDetailState.chargeBatchUpdate.ignoreCntrStatusValidEnable,
        chargeDetailState.chargeBatchUpdate.manualAdjEnable, chargeDetailState.chargeBatchUpdate.manualTsLegChargeIndEnable,
        chargeDetailState.chargeBatchUpdate.operatingTmlEnable,
        chargeDetailState.chargeBatchUpdate.permitNoEnable, chargeDetailState.chargeBatchUpdate.podEnable,
        chargeDetailState.chargeBatchUpdate.polEnable, chargeDetailState.chargeBatchUpdate.pooEnable,
        chargeDetailState.chargeBatchUpdate.rfInfoEnable, chargeDetailState.chargeBatchUpdate.rfTempEnable,
        chargeDetailState.chargeBatchUpdate.seclegDischargeIndEnable, chargeDetailState.chargeBatchUpdate.seclegOperatingTmlEnable,
        chargeDetailState.chargeBatchUpdate.seclegSpecialIndEnable, chargeDetailState.chargeBatchUpdate.sizeEnable,
        chargeDetailState.chargeBatchUpdate.soNumberEnable, chargeDetailState.chargeBatchUpdate.specialIndEnable,
        chargeDetailState.chargeBatchUpdate.waiverNoEnable, chargeDetailState.chargeBatchUpdate.status2Enable,
        chargeDetailState.chargeBatchUpdate.bundleMasterNoEnable, chargeDetailState.chargeBatchUpdate.tierFspEnable,
        chargeDetailState.chargeBatchUpdate.voltageEnable, chargeDetailState.chargeBatchUpdate.offStdMeasurementModeEnable,
        chargeDetailState.chargeBatchUpdate.marshallingTypeEnable, chargeDetailState.chargeBatchUpdate.specialHandlingCodeEnable,
        chargeDetailState.chargeBatchUpdate.vesselInfoEnable, chargeDetailState.chargeBatchUpdate.tsVesselInfoEnable]);

    const isDisable = () => {
        if (isShowBatchUpdatePanel || isShowCntrInfoPanel || isShowUpdateVesselVoyageMasterSearchPanel ||
            isShowUpdateVesselVoyageMasterTablePanel || isShowAssignSeqNoPanel || isShowUpdateStatus) {
            return true;
        } else {
            return false;
        }
    }

    function addLegendToParent(pDivId: string, cDivId: string, posBox: { left: number, top: number }) {
        const timer = setInterval(() => {
            const pDiv = document.querySelector(pDivId);
            const cDiv = document.querySelector(cDivId);
            const cls: string = cDiv?.getAttribute('class')?.toString() || '';

            if (cls.indexOf('added') >= 0) clearInterval(timer);

            if (pDiv && cDiv && cls.indexOf('added') < 0) {
                const pos = pDiv?.getBoundingClientRect();
                cDiv.setAttribute('class', `${cls} added`);
                cDiv.setAttribute('style', `left:${pos && (pos.right - posBox.left)}px;top:${pos && (pos.y + posBox.top)}px;`);
            }
        }, 200);
    }

    useEffect(() => {
        addLegendToParent('#Icon-help-charge-detail-table', '#charge-detail-legend', { left: 160 + 40 + 2 + 15, top: 35 });
    }, [chargeDetailState.isShowLegend]);

    const memoMoreIconButton = useMemo(() => {
        let disabled: boolean = false;
        if (isShowBatchUpdatePanel || isShowCntrInfoPanel || isShowUpdateVesselVoyageMasterSearchPanel || isShowUpdateVesselVoyageMasterTablePanel ||
            isShowAssignSeqNoPanel || isShowUpdateStatus || !memoCommonAccountMenu.find(menu => menu.disabled !== true)
        ) {
            disabled = true;
        }
        return <OverflowMenuButton buttonLabel={WorkspaceConstant.Common.BUTTON_MORE} disabled={disabled}
            toolTipText={WorkspaceConstant.Common.BUTTON_MORE} navigationList={memoCommonAccountMenu} buttonSize={"Small"} buttonTheme={"Secondary"} />
    }, [memoCommonAccountMenu, isShowBatchUpdatePanel, isShowCntrInfoPanel, isShowUpdateVesselVoyageMasterSearchPanel,
        isShowUpdateVesselVoyageMasterTablePanel, isShowAssignSeqNoPanel, isShowUpdateStatus])

    const memoEditIconButton = useMemo(() => {
        let disabled: boolean = false;
        if (isShowBatchUpdatePanel || isShowCntrInfoPanel || isShowUpdateVesselVoyageMasterSearchPanel || isShowUpdateVesselVoyageMasterTablePanel ||
            isShowAssignSeqNoPanel || isShowUpdateStatus || !memoEditMenu.find(menu => menu.disabled !== true)) {
            disabled = true;
        }
        return <OverflowMenuButton icon='Icon-pen' disabled={disabled} toolTipText={ChargeDetailConstant.Charge.BULK_EDIT} navigationList={memoEditMenu} />
    }, [memoEditMenu, isShowBatchUpdatePanel, isShowCntrInfoPanel, isShowUpdateVesselVoyageMasterSearchPanel,
        isShowUpdateVesselVoyageMasterTablePanel, isShowAssignSeqNoPanel, isShowUpdateStatus])

    const onBackAction = useCallback(async () => {
        const vesselData = localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_VESSEL_SUMMARY) ?
            JSON.parse(localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_VESSEL_SUMMARY) ?? "") :
            null;
        const inboundData = localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_INBOUND_SUMMARY) ?
            JSON.parse(localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_INBOUND_SUMMARY) ?? "") :
            null;
        const changeKeyData = localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_CHANGE_KEY_DATA) ?
            JSON.parse(localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_CHANGE_KEY_DATA) ?? "") :
            null;
        const deliveryData = localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_DELIVERY_SUMMARY) ?
            JSON.parse(localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_DELIVERY_SUMMARY) ?? "") :
            null;
        //open charge header screen        
        if (vesselData) {
            customDispatchEvent(E_Type_Of_Event.CHARGE_HEADER_REDIRECT_EVENT,
                E_Custom_Dispatch_Event.REDIRECT_CHARGE_HEADER_FROM_VESSEL_SUMMARY,
                {
                    userName: anainfoState.userName,
                    data: vesselData,
                });
        } else if (inboundData) {
            customDispatchEvent(E_Type_Of_Event.CHARGE_HEADER_REDIRECT_EVENT,
                E_Custom_Dispatch_Event.REDIRECT_CHARGE_HEADER_FROM_INBOUND_SUMMARY,
                {
                    userName: anainfoState.userName,
                    data: inboundData,
                });
        } else if (changeKeyData) {
            customDispatchEvent(E_Type_Of_Event.CHANGE_KEY_DATA_REDIRECT_EVENT,
                E_Custom_Dispatch_Event.REDIRECT_CHANGE_KEY_DATA_FROM_CHARGE_DETAIL,
                {
                    userName: anainfoState.userName,
                    data: changeKeyData,
                });
        } else if (deliveryData) {
            customDispatchEvent(E_Type_Of_Event.CHARGE_HEADER_REDIRECT_EVENT,
                E_Custom_Dispatch_Event.REDIRECT_CHARGE_HEADER_FROM_DELIVERY_SUMMARY,
                {
                    userName: anainfoState.userName,
                    data: deliveryData,
                });
        }

        //remove the redirect cache
        localStorage.removeItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_VESSEL_SUMMARY)
        localStorage.removeItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_CHANGE_KEY_DATA)
        localStorage.removeItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_INBOUND_SUMMARY);
        localStorage.removeItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_DELIVERY_SUMMARY);
    }, [anainfoState.userName]);

    const showBackButtonWithTitle =
        localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_VESSEL_SUMMARY) ||
        localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_CHANGE_KEY_DATA) ||
        localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_INBOUND_SUMMARY) ||
        localStorage.getItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_DELIVERY_SUMMARY);

    return <Sidebarheader style={{ width: '100%' }}>
        {isLoading && <Loader Indicator="spinner" size="Medium" />}
        <HeaderWithBackButton callback={(chargeDetailState.isShowCloseIcon ? handleClose : onBackAction)} isShowBackButton={(showBackButtonWithTitle ? true : false) || chargeDetailState.isShowCloseIcon}>
            <Breadcrumb>
                <HPHBreadcrumb breadcrumbData={[{ title: CHARGEDETAIL_CONSTANT.CHARGE_TITLE }, { title: CHARGEDETAIL_CONSTANT.CHARGEPROCESSING_TITLE }]} onCurrentClick={handleClose}></HPHBreadcrumb>
            </Breadcrumb>
        </HeaderWithBackButton>
        <StyledAction className={"tm-animated-wrapper"}>
            {/* <IconButton id="Icon-help-charge-detail-table" fileName='Icon-help' disabled={isDisable()} size='medium' toolTipText={'Legend'} toolTipArrow={false} onClick={handleLegend} />
            <div className="add-seperator"/> */}
            <IconButton fileName='Icon-search' disabled={isDisable()} size='medium' toolTipText={'Search'} toolTipArrow={false} onClick={handleSearch} />
            <IconButton fileName='Icon-table-view' disabled={isDisable()} size='medium' toolTipText={'Summary'} toolTipArrow={false} onClick={handleSummary} />
            {(allowUpdate) && <><div className="add-seperator" />
                <HPHButton label={WorkspaceConstant.Common.BUTTON_UPDATE} disabled={isDisable() || isDisableApply} size={'Small'} theme={'Secondary'} onClick={handleApply} />
                <HPHButton label={WorkspaceConstant.Common.BUTTON_UPDATEALL} disabled={isDisable()} size={'Small'} theme={'Secondary'} onClick={handleApplyAll} />
                <HPHButton label={CHARGEDETAIL_CONSTANT.CONFIRM_BUT} disabled={isDisable() || isDisableConfirm} size={'Small'} theme={'Secondary'} onClick={handleConfirm} />
                <HPHButton label={CHARGEDETAIL_CONSTANT.CONFIRM_ALL_BUT} disabled={isDisable()} size={'Small'} theme={'Primary'} onClick={handleConfirmAll} />
                <div className="add-seperator" />
                {memoEditIconButton}
                {memoMoreIconButton}
            </>}
            {/* {chargeDetailState.isShowCloseIcon && 
             <IconButton fileName="Icon-cross" size="medium" disabled={isDisable()} toolTipArrow={false} toolTipPlacement="left" toolTipText={'Close'} onClick={handleClose} />} */}

            {isShowDelete && <ConfirmDeleteModal headerText={`${WorkspaceConstant.Common.BUTTON_DELETE} ${ChargeDetailConstant.Charge.CHARGEPROCESSING_TITLE}`}
                contentText={`${MessageConstant.common.DELETE_ALERT_TITLE}\n${MessageConstant.common.DELETE_ALERT_MESSAGE}`}
                visible={isShowDelete}
                onCancelClick={handleCancel}
                onDeleteClick={handleDelete}
            />}
        </StyledAction>
        {chargeDetailState.isShowLegend && <div id='charge-detail-legend' className='detail-legend'>
            <ul>
                <li><p className='first'><span>{CHARGEDETAIL_CONSTANT.CHARGE_DTL_STATE_LEGEND}</span></p><IconButton fileName="Icon-cross-small" size="small" tooltipDisable={true} onClick={handleLegend} /></li>
                <li><p className='red'><span>{CHARGEDETAIL_CONSTANT.STATE_RED}</span></p></li>
                <li><p className='grey'><span>{CHARGEDETAIL_CONSTANT.STATE_GREY}</span></p></li>
                <li><p className='green'><span>{CHARGEDETAIL_CONSTANT.STATE_GREEN}</span></p></li>
                <li><p className='blue'><span>{CHARGEDETAIL_CONSTANT.STATE_BLUE}</span></p></li>
            </ul>
        </div>}
        {chargeDetailState.forceRefresh && <></>}
    </Sidebarheader>
}

export default memo(ChargeDetailTitleBar);