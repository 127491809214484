import { ChargeOnCompanyMappingConstant, chargeOnCompanyMappingRequiredFieldList } from "presentation/constant/ChargeOnCompanyMapping/ChargeOnCompanyMappingConstant";
import { statusDropdownOptions } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { useChargeOnCompanyMappingVM } from "presentation/hook/ChargeOnCompanyMapping/useChargeOnCompanyMappingVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useChargeOnCompanyMappingTracked } from "presentation/store/ChargeOnCompanyMapping/ChargeOnCompanyMappingProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { NbisIconButton } from "presentation/view/components/NbisIconButton";
import { memo, useCallback, useMemo, useState } from "react";
import { FieldType, HPHBreadcrumb, HPHCheckbox, HPHGroupTitle, IconButton, IFieldValue, Loader } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, SidebarCaption, Sidebarheader, SidebarTitle, StyledAction, StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const ChargeOnCompanyMappingEditPanel = () => {
    const CHARGE_ON_COMPANY_MAPPING_CONSTANT = ChargeOnCompanyMappingConstant.Table;
    const [isLoading, setIsLoading] = useState(false);
    const [chargeOnCompanyMappingState] = useChargeOnCompanyMappingTracked();
    const chargeOnCompanyMappingVM = useChargeOnCompanyMappingVM();
    const { currentEditRow, isAdd, isEdit } = chargeOnCompanyMappingState;
    const messageBarVM = useMessageBarVM();


    const handleSave = useCallback(async () => {
        if (!currentEditRow.chargeOnCompanyCode) {
            messageBarVM.showError("Charge On Company is mandatory.");
            return;
        }
        if (!currentEditRow.activeInd) {
            messageBarVM.showError("Active Ind is mandatory.");
            return;
        }
        setIsLoading(true);
        const data = await chargeOnCompanyMappingVM.onSave(currentEditRow);
        if (data && data.success) {
            messageBarVM.showSuccess("Save successful.");
            chargeOnCompanyMappingVM.searchAllChargeOnCompanyMappingList().then((data) => {
                setIsLoading(false)
            }).catch(error => {
                setIsLoading(false)
            });
        } else {
            setIsLoading(false)
            messageBarVM.showError(data.data);
            return;
        }
    }, [chargeOnCompanyMappingVM, currentEditRow, messageBarVM]);

    const handClose = useCallback(() => {
        chargeOnCompanyMappingVM.onCloseEidtPanel();
    }, [chargeOnCompanyMappingVM]);

    const memoSubChgTypeOptions = useMemo(() => {
        const subChgTypeOptions = currentEditRow.chargeType ? chargeOnCompanyMappingState.dynamicOptions.subChargeTypeDropdownOptions[currentEditRow.chargeType] : [];
        return subChgTypeOptions;
    }, [currentEditRow.chargeType, chargeOnCompanyMappingState.dynamicOptions.subChargeTypeDropdownOptions])

    const memoChargeType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "280px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.chargeType || ''}
                fieldValue={currentEditRow?.chargeType}
                fieldLabel={CHARGE_ON_COMPANY_MAPPING_CONSTANT.CHARGE_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'chargeType'}
                maxLength={60}
                options={chargeOnCompanyMappingState.dynamicOptions.chargeTypeDropdownOptions}
                requiredFieldList={chargeOnCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    chargeOnCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CHARGE_ON_COMPANY_MAPPING_CONSTANT.CHARGE_TYPE, chargeOnCompanyMappingState.dynamicOptions.chargeTypeDropdownOptions, chargeOnCompanyMappingVM, currentEditRow?.chargeType, isAdd, isEdit])

    const memoSubChargeType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "280px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.subChargeType || ''}
                fieldValue={currentEditRow?.subChargeType}
                fieldLabel={CHARGE_ON_COMPANY_MAPPING_CONSTANT.SUB_CHARGE_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'subChargeType'}
                maxLength={60}
                options={memoSubChgTypeOptions}
                requiredFieldList={chargeOnCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    chargeOnCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CHARGE_ON_COMPANY_MAPPING_CONSTANT.SUB_CHARGE_TYPE, chargeOnCompanyMappingVM, currentEditRow?.subChargeType, isAdd, isEdit, memoSubChgTypeOptions])

    const memoSoa = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "280px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.soa || ''}
                fieldValue={currentEditRow?.soa}
                fieldLabel={CHARGE_ON_COMPANY_MAPPING_CONSTANT.SOA}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'soa'}
                maxLength={60}
                options={chargeOnCompanyMappingState.dynamicOptions.soaDropdownOptions}
                requiredFieldList={chargeOnCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    chargeOnCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CHARGE_ON_COMPANY_MAPPING_CONSTANT.SOA, chargeOnCompanyMappingState.dynamicOptions.soaDropdownOptions, chargeOnCompanyMappingVM, currentEditRow?.soa, isAdd, isEdit])

    const memoServiceCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "280px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.serviceCode || ''}
                fieldValue={currentEditRow?.serviceCode}
                fieldLabel={CHARGE_ON_COMPANY_MAPPING_CONSTANT.SERVICE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'serviceCode'}
                maxLength={60}
                options={chargeOnCompanyMappingState.dynamicOptions.serviceCodeDropdownOptions}
                requiredFieldList={chargeOnCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    chargeOnCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CHARGE_ON_COMPANY_MAPPING_CONSTANT.SERVICE, chargeOnCompanyMappingState.dynamicOptions.serviceCodeDropdownOptions, chargeOnCompanyMappingVM, currentEditRow?.serviceCode, isAdd, isEdit])

    const memoOpsLine = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "280px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.owner || ''}
                fieldValue={currentEditRow?.owner}
                fieldLabel={CHARGE_ON_COMPANY_MAPPING_CONSTANT.OPS_LINE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'owner'}
                maxLength={60}
                options={chargeOnCompanyMappingState.dynamicOptions.opsLineDropdownOptions}
                requiredFieldList={chargeOnCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    chargeOnCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CHARGE_ON_COMPANY_MAPPING_CONSTANT.OPS_LINE, chargeOnCompanyMappingState.dynamicOptions.opsLineDropdownOptions, chargeOnCompanyMappingVM, currentEditRow?.owner, isAdd, isEdit])

    const memoForwarderReference = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "280px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.forwarderReference || ''}
                fieldValue={currentEditRow?.forwarderReference}
                fieldLabel={CHARGE_ON_COMPANY_MAPPING_CONSTANT.FORWARDER_REF}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'forwarderReference'}
                maxLength={60}
                requiredFieldList={chargeOnCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    chargeOnCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CHARGE_ON_COMPANY_MAPPING_CONSTANT.FORWARDER_REF, chargeOnCompanyMappingVM, currentEditRow?.forwarderReference, isAdd, isEdit])

    const memoCntrStatus = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "280px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.cntrStatus || ''}
                fieldValue={currentEditRow?.cntrStatus}
                fieldLabel={CHARGE_ON_COMPANY_MAPPING_CONSTANT.CONTAINER_STATUS}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'cntrStatus'}
                maxLength={60}
                options={statusDropdownOptions}
                requiredFieldList={chargeOnCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    chargeOnCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CHARGE_ON_COMPANY_MAPPING_CONSTANT.CONTAINER_STATUS, chargeOnCompanyMappingVM, currentEditRow?.cntrStatus, isAdd, isEdit])

    const memoArrivalServiceCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "280px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.arrivalServiceCode || ''}
                fieldValue={currentEditRow?.arrivalServiceCode}
                fieldLabel={CHARGE_ON_COMPANY_MAPPING_CONSTANT.SERVICE_ARRIVAL}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'arrivalServiceCode'}
                maxLength={60}
                options={chargeOnCompanyMappingState.dynamicOptions.serviceCodeDropdownOptions}
                requiredFieldList={chargeOnCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    chargeOnCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CHARGE_ON_COMPANY_MAPPING_CONSTANT.SERVICE_ARRIVAL, chargeOnCompanyMappingState.dynamicOptions.serviceCodeDropdownOptions, chargeOnCompanyMappingVM, currentEditRow?.arrivalServiceCode, isAdd, isEdit])

    const memoChargeOnCompanyCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "280px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.chargeOnCompanyCode || ''}
                fieldValue={currentEditRow?.chargeOnCompanyCode}
                fieldLabel={CHARGE_ON_COMPANY_MAPPING_CONSTANT.CHARGEON_COMPANY}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'chargeOnCompanyCode'}
                maxLength={60}
                sort={false}
                options={chargeOnCompanyMappingState.dynamicOptions.chargeOnCompanyDropdownOptions}
                requiredFieldList={chargeOnCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    chargeOnCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CHARGE_ON_COMPANY_MAPPING_CONSTANT.CHARGEON_COMPANY, chargeOnCompanyMappingState.dynamicOptions.chargeOnCompanyDropdownOptions, chargeOnCompanyMappingVM, currentEditRow?.chargeOnCompanyCode, isAdd, isEdit])

    const memoMarshallingType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "280px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.marshallingType || ''}
                fieldValue={currentEditRow?.marshallingType}
                fieldLabel={CHARGE_ON_COMPANY_MAPPING_CONSTANT.MARSHALLING_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'marshallingType'}
                maxLength={60}
                options={chargeOnCompanyMappingState.dynamicOptions.marshallingTypeDropdownOptions}
                requiredFieldList={chargeOnCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    chargeOnCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CHARGE_ON_COMPANY_MAPPING_CONSTANT.MARSHALLING_TYPE, chargeOnCompanyMappingState.dynamicOptions.marshallingTypeDropdownOptions, chargeOnCompanyMappingVM, currentEditRow?.marshallingType, isAdd, isEdit])

    const memoSpecialHandlingInd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "280px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.specialHandlingInd || ''}
                fieldValue={currentEditRow?.specialHandlingInd}
                fieldLabel={CHARGE_ON_COMPANY_MAPPING_CONSTANT.SPECIAL_HANDLING_IND}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'specialHandlingInd'}
                maxLength={60}
                options={chargeOnCompanyMappingState.dynamicOptions.specHandingIndDropdownOptions}
                requiredFieldList={chargeOnCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    chargeOnCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CHARGE_ON_COMPANY_MAPPING_CONSTANT.SPECIAL_HANDLING_IND, chargeOnCompanyMappingState.dynamicOptions.specHandingIndDropdownOptions, chargeOnCompanyMappingVM, currentEditRow?.specialHandlingInd, isAdd, isEdit])

    const memoSpecialHandlingIndD1 = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "280px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.specialHandlingIndD1 || ''}
                fieldValue={currentEditRow?.specialHandlingIndD1}
                fieldLabel={CHARGE_ON_COMPANY_MAPPING_CONSTANT.SPECIAL_HANDLING_IND_D1}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'specialHandlingIndD1'}
                maxLength={60}
                options={chargeOnCompanyMappingState.dynamicOptions.specHandingIndDropdownOptions}
                requiredFieldList={chargeOnCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    chargeOnCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CHARGE_ON_COMPANY_MAPPING_CONSTANT.SPECIAL_HANDLING_IND_D1, chargeOnCompanyMappingState.dynamicOptions.specHandingIndDropdownOptions, chargeOnCompanyMappingVM, currentEditRow?.specialHandlingIndD1, isAdd, isEdit])

    const memoVesselType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "280px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.vesselType || ''}
                fieldValue={currentEditRow?.vesselType}
                fieldLabel={CHARGE_ON_COMPANY_MAPPING_CONSTANT.VESSEL_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'vesselType'}
                maxLength={60}
                options={chargeOnCompanyMappingState.dynamicOptions.vesselTypeDropdownOptions}
                requiredFieldList={chargeOnCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    chargeOnCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CHARGE_ON_COMPANY_MAPPING_CONSTANT.VESSEL_TYPE, chargeOnCompanyMappingState.dynamicOptions.vesselTypeDropdownOptions, chargeOnCompanyMappingVM, currentEditRow?.vesselType, isAdd, isEdit])

    const memoConsortiumCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "280px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.consortiumCode || ''}
                fieldValue={currentEditRow?.consortiumCode}
                fieldLabel={CHARGE_ON_COMPANY_MAPPING_CONSTANT.CONSORTIUM}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'consortiumCode'}
                maxLength={60}
                options={chargeOnCompanyMappingState.dynamicOptions.consortiumCodeDropdownOptions}
                requiredFieldList={chargeOnCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    chargeOnCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CHARGE_ON_COMPANY_MAPPING_CONSTANT.CONSORTIUM, chargeOnCompanyMappingState.dynamicOptions.consortiumCodeDropdownOptions, chargeOnCompanyMappingVM, currentEditRow?.consortiumCode, isAdd, isEdit])

    const memoForwarderCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "280px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.forwarderCode || ''}
                fieldValue={currentEditRow?.forwarderCode}
                fieldLabel={CHARGE_ON_COMPANY_MAPPING_CONSTANT.FORWARDER_CODE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'forwarderCode'}
                maxLength={60}
                options={chargeOnCompanyMappingState.dynamicOptions.forwarderCodeDropdownOptions}
                requiredFieldList={chargeOnCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    chargeOnCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CHARGE_ON_COMPANY_MAPPING_CONSTANT.FORWARDER_CODE, chargeOnCompanyMappingState.dynamicOptions.forwarderCodeDropdownOptions, chargeOnCompanyMappingVM, currentEditRow?.forwarderCode, isAdd, isEdit])

    const memoOperatingTml = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "280px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.operatingTml || ''}
                fieldValue={currentEditRow?.operatingTml}
                fieldLabel={CHARGE_ON_COMPANY_MAPPING_CONSTANT.OPERATING_TML}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'operatingTml'}
                maxLength={60}
                options={chargeOnCompanyMappingState.dynamicOptions.operatingTmlDropdownOptions}
                requiredFieldList={chargeOnCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    chargeOnCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CHARGE_ON_COMPANY_MAPPING_CONSTANT.OPERATING_TML, chargeOnCompanyMappingState.dynamicOptions.operatingTmlDropdownOptions, chargeOnCompanyMappingVM, currentEditRow?.operatingTml, isAdd, isEdit])

    const memoChargeCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "280px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.chargeCode || ''}
                fieldValue={currentEditRow?.chargeCode}
                fieldLabel={CHARGE_ON_COMPANY_MAPPING_CONSTANT.CHARGE_CODE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'chargeCode'}
                maxLength={60}
                options={chargeOnCompanyMappingState.dynamicOptions.chargeCodeDropdownOptions}
                requiredFieldList={chargeOnCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    chargeOnCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CHARGE_ON_COMPANY_MAPPING_CONSTANT.CHARGE_CODE, chargeOnCompanyMappingState.dynamicOptions.chargeCodeDropdownOptions, chargeOnCompanyMappingVM, currentEditRow?.chargeCode, isAdd, isEdit])

    const memoDepartureServiceCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "280px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.departureServiceCode || ''}
                fieldValue={currentEditRow?.departureServiceCode}
                fieldLabel={CHARGE_ON_COMPANY_MAPPING_CONSTANT.SERVICE_DEPARTURE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'departureServiceCode'}
                maxLength={60}
                options={chargeOnCompanyMappingState.dynamicOptions.serviceCodeDropdownOptions}
                requiredFieldList={chargeOnCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    chargeOnCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CHARGE_ON_COMPANY_MAPPING_CONSTANT.SERVICE_DEPARTURE, chargeOnCompanyMappingState.dynamicOptions.serviceCodeDropdownOptions, chargeOnCompanyMappingVM, currentEditRow?.departureServiceCode, isAdd, isEdit])

    const memoMarshalFrom = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "280px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.marshalFrom || ''}
                fieldValue={currentEditRow?.marshalFrom}
                fieldLabel={CHARGE_ON_COMPANY_MAPPING_CONSTANT.MARSHAL_FROM}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'marshalFrom'}
                maxLength={60}
                options={chargeOnCompanyMappingState.dynamicOptions.marshalCodeDropdownOptions}
                requiredFieldList={chargeOnCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    chargeOnCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CHARGE_ON_COMPANY_MAPPING_CONSTANT.MARSHAL_FROM, chargeOnCompanyMappingState.dynamicOptions.marshalCodeDropdownOptions, chargeOnCompanyMappingVM, currentEditRow?.marshalFrom, isAdd, isEdit])

    const memoMarshalTo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "280px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.marshalTo || ''}
                fieldValue={currentEditRow?.marshalTo}
                fieldLabel={CHARGE_ON_COMPANY_MAPPING_CONSTANT.MARSHAL_TO}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'marshalTo'}
                maxLength={60}
                options={chargeOnCompanyMappingState.dynamicOptions.marshalCodeDropdownOptions}
                requiredFieldList={chargeOnCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    chargeOnCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CHARGE_ON_COMPANY_MAPPING_CONSTANT.MARSHAL_TO, chargeOnCompanyMappingState.dynamicOptions.marshalCodeDropdownOptions, chargeOnCompanyMappingVM, currentEditRow?.marshalTo, isAdd, isEdit])

    const memoModalityOfArrival = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "280px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.modalityOfArrival || ''}
                fieldValue={currentEditRow?.modalityOfArrival}
                fieldLabel={CHARGE_ON_COMPANY_MAPPING_CONSTANT.MODALITY_OF_ARRIVAL}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'modalityOfArrival'}
                maxLength={60}
                options={chargeOnCompanyMappingState.dynamicOptions.modalityDropdownOptions}
                requiredFieldList={chargeOnCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    chargeOnCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CHARGE_ON_COMPANY_MAPPING_CONSTANT.MODALITY_OF_ARRIVAL, chargeOnCompanyMappingState.dynamicOptions.modalityDropdownOptions, chargeOnCompanyMappingVM, currentEditRow?.modalityOfArrival, isAdd, isEdit])

    const memoModalityOfDeparture = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "280px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.modalityOfDeparture || ''}
                fieldValue={currentEditRow?.modalityOfDeparture}
                fieldLabel={CHARGE_ON_COMPANY_MAPPING_CONSTANT.MODALITY_OF_DEPARTURE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'modalityOfDeparture'}
                maxLength={60}
                options={chargeOnCompanyMappingState.dynamicOptions.modalityDropdownOptions}
                requiredFieldList={chargeOnCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    chargeOnCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CHARGE_ON_COMPANY_MAPPING_CONSTANT.MODALITY_OF_DEPARTURE, chargeOnCompanyMappingState.dynamicOptions.modalityDropdownOptions, chargeOnCompanyMappingVM, currentEditRow?.modalityOfDeparture, isAdd, isEdit])

    const memoArrivalVesselCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "280px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.arrivalVesselCode || ''}
                fieldValue={currentEditRow?.arrivalVesselCode}
                fieldLabel={CHARGE_ON_COMPANY_MAPPING_CONSTANT.VESSEL_CODEARRIVAL}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'arrivalVesselCode'}
                maxLength={60}
                requiredFieldList={chargeOnCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    chargeOnCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CHARGE_ON_COMPANY_MAPPING_CONSTANT.VESSEL_CODEARRIVAL, chargeOnCompanyMappingVM, currentEditRow?.arrivalVesselCode, isAdd, isEdit])

    const memoDepartureVesselCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "280px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.departureVesselCode || ''}
                fieldValue={currentEditRow?.departureVesselCode}
                fieldLabel={CHARGE_ON_COMPANY_MAPPING_CONSTANT.VESSEL_CODEDEPARTURE}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'departureVesselCode'}
                maxLength={60}
                requiredFieldList={chargeOnCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    chargeOnCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CHARGE_ON_COMPANY_MAPPING_CONSTANT.VESSEL_CODEDEPARTURE, chargeOnCompanyMappingVM, currentEditRow?.departureVesselCode, isAdd, isEdit])

    const memoAlterableChargeOnCompany = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "280px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.alterableChargeOnCompany || ''}
                fieldValue={currentEditRow?.alterableChargeOnCompany}
                fieldLabel={CHARGE_ON_COMPANY_MAPPING_CONSTANT.ALTERABLE_CHARGEON_COMPANY}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'alterableChargeOnCompany'}
                maxLength={60}
                sort={false}
                options={chargeOnCompanyMappingState.dynamicOptions.chargeOnCompanyDropdownOptions}
                requiredFieldList={chargeOnCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    chargeOnCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CHARGE_ON_COMPANY_MAPPING_CONSTANT.ALTERABLE_CHARGEON_COMPANY, chargeOnCompanyMappingState.dynamicOptions.chargeOnCompanyDropdownOptions, chargeOnCompanyMappingVM, currentEditRow?.alterableChargeOnCompany, isAdd, isEdit])

    const memoArrivalSOA = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "280px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.arrivalSOA || ''}
                fieldValue={currentEditRow?.arrivalSOA}
                fieldLabel={CHARGE_ON_COMPANY_MAPPING_CONSTANT.SOAARRIVAL}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'arrivalSOA'}
                maxLength={60}
                options={chargeOnCompanyMappingState.dynamicOptions.soaDropdownOptions}
                requiredFieldList={chargeOnCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    chargeOnCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CHARGE_ON_COMPANY_MAPPING_CONSTANT.SOAARRIVAL, chargeOnCompanyMappingState.dynamicOptions.soaDropdownOptions, chargeOnCompanyMappingVM, currentEditRow?.arrivalSOA, isAdd, isEdit])

    const memoDepartureSOA = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "280px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.departureSOA || ''}
                fieldValue={currentEditRow?.departureSOA}
                fieldLabel={CHARGE_ON_COMPANY_MAPPING_CONSTANT.SOADEPARTURE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'departureSOA'}
                maxLength={60}
                options={chargeOnCompanyMappingState.dynamicOptions.soaDropdownOptions}
                requiredFieldList={chargeOnCompanyMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    chargeOnCompanyMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CHARGE_ON_COMPANY_MAPPING_CONSTANT.SOADEPARTURE, chargeOnCompanyMappingState.dynamicOptions.soaDropdownOptions, chargeOnCompanyMappingVM, currentEditRow?.departureSOA, isAdd, isEdit])

    const memoActiveInd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "280px", marginBottom: "24px" }}>
            <NbisCommonField
                fieldKey={"activeInd"}
                requiredFieldList={chargeOnCompanyMappingRequiredFieldList}
                fieldLabel={CHARGE_ON_COMPANY_MAPPING_CONSTANT.ACTIVE_IND}
                fieldType={FieldType.TEXT}
                isReadOnly={true}
                onFieldChange={() => { }} />
            <HPHCheckbox
                label={""}
                checked={currentEditRow?.activeInd === 'Y'}
                disabled={!(isAdd || isEdit)}
                onChange={(e) => chargeOnCompanyMappingVM.onCheckboxChange(e.checked, "activeInd")}
            />
        </div>
        , [CHARGE_ON_COMPANY_MAPPING_CONSTANT.ACTIVE_IND, chargeOnCompanyMappingVM, currentEditRow?.activeInd, isAdd, isEdit])

    const handleEdit = useCallback(() => {
        chargeOnCompanyMappingVM.onEditClick();
    }, [chargeOnCompanyMappingVM]);

    const handleReset = useCallback(() => {
        chargeOnCompanyMappingVM.onResetClick(isAdd);
    }, [chargeOnCompanyMappingVM, isAdd]);

    return <>
        <StyledSidebar style={{ width: '100%', height: "100%", border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'>
            <Sidebarheader style={{ width: '100%' }}>
                {isLoading && <Loader Indicator="Spinner" size="Medium" />}
                <Breadcrumb>
                    <HPHBreadcrumb breadcrumbData={[{ title: ChargeOnCompanyMappingConstant.Title.CATEGORY },
                    { title: ChargeOnCompanyMappingConstant.Title.TITLE }]} onCurrentClick={() => { }}>
                    </HPHBreadcrumb>
                </Breadcrumb>
                <StyledAction>
                    <>
                        {!(isAdd || isEdit) && <NbisIconButton fileName='Icon-pen' size='medium' disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Edit'} onClick={handleEdit} />}
                        {(isAdd || isEdit) && <IconButton fileName="Icon-reset" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Reset'} onClick={() => handleReset()} />}
                        <IconButton fileName="Icon-cross" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement='bottom' toolTipText={'Close'} onClick={() => handClose()} />
                        {(isAdd || isEdit) && <IconButton fileName="Icon-tick" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Save'} onClick={() => handleSave()} />}
                    </>
                </StyledAction>
            </Sidebarheader>

            <div className={'add-edit-form'} style={{ height: 'calc(100vh - 125px)', maxHeight: 'calc(100vh - 125px)', overflow: 'auto' }}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        <div className={'im-flex-row'}>
                            <div className={'flex-row-item-stretch'}>
                                <SidebarTitle>
                                    {isAdd && "New Charge On Company Setting"}
                                    {!isAdd && (currentEditRow.priority + "/" + currentEditRow.chargeOnCompanyCode)}
                                </SidebarTitle>
                                {<SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>}
                            </div>
                        </div>
                        <br />
                        <HPHGroupTitle titleText={ChargeOnCompanyMappingConstant.Title.MAPPING_FROM} />
                        <CriteriaItemContainer>
                            {memoChargeType}
                            {memoSubChargeType}
                            {memoAlterableChargeOnCompany}
                        </CriteriaItemContainer>

                        <label>CONTAINER</label>
                        <CriteriaItemContainer>
                            {memoOpsLine}
                            {memoCntrStatus}
                            {memoForwarderCode}
                            {memoForwarderReference}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoChargeCode}
                            {memoMarshallingType}
                            {memoMarshalFrom}
                            {memoMarshalTo}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoSpecialHandlingInd}
                            {memoSpecialHandlingIndD1}
                            {memoModalityOfArrival}
                            {memoModalityOfDeparture}
                        </CriteriaItemContainer>

                        <label>VOYAGE</label>
                        <CriteriaItemContainer>
                            {memoConsortiumCode}
                            {memoOperatingTml}
                            {memoArrivalVesselCode}
                            {memoDepartureVesselCode}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoServiceCode}
                            {memoArrivalServiceCode}
                            {memoDepartureServiceCode}
                            {memoVesselType}
                        </CriteriaItemContainer>


                        <CriteriaItemContainer>
                            {memoSoa}
                            {memoArrivalSOA}
                            {memoDepartureSOA}
                        </CriteriaItemContainer>


                        <HPHGroupTitle titleText={ChargeOnCompanyMappingConstant.Title.MAPPING_TO} />
                        <CriteriaItemContainer>
                            {memoChargeOnCompanyCode}
                            {memoActiveInd}
                        </CriteriaItemContainer>
                    </div>
                </div>
            </div>
        </StyledSidebar>
    </>
}

export default memo(ChargeOnCompanyMappingEditPanel);
