import { FC } from "react";
import { DialogModal, HPHButton } from "veronica-ui-component/dist/component/core";

export interface DialogModalProps {
    headerText: string
    contentText: any
    visible: boolean
    onCancelClick: any
    onConfirmClick: any

    [x: string]: any
}


export const NbisConfirmModal: FC<DialogModalProps> = ({
    headerText = 'headerText',
    contentText = 'contextText',
    visible = false,
    onCancelClick,
    onConfirmClick
}) => {

    return (
        <DialogModal
            appendTo='self'
            showButton={false}
            visible={visible}
            positions="center"
            header={
                <>
                    <div className="modal-header">
                        <span className="name">{headerText}</span>
                    </div>
                    <div className="text">
                        <p style={{ whiteSpace: 'pre-line' }}>{contentText}</p>
                    </div>
                </>}
            className="delete-modal"
            dialogContent={<></>}
            footer={
                <div className="action-button">
                    <HPHButton
                        label='Cancel'
                        theme='Secondary'
                        disableBadge={true}
                        onClick={onCancelClick}
                    />
                    <HPHButton
                        label='Confirm'
                        theme='Alert'
                        disableBadge={true}
                        onClick={onConfirmClick}
                    />
                </div>
            }
        />
    )
}
    ;
