import { CompanyTariffNatureEntity, EMPTY_COMPANY_TARIFF_NATURE_ENTITY } from "domain/entity/Company/CompanyTariffNatureEntity";
import { TariffNatureEntity } from "domain/entity/TariffNature/TariffNatureEntity";
import { CompanyTariffNatureRepository } from "domain/repository/Company/CompanyTariffNatureRepo";
import _ from "lodash";
import { CompanyDetailModel } from "presentation/model/Company/CompanyDetailModel";

import BaseViewModel from "presentation/viewModel/BaseViewModel";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";

interface CompanyTariffNatureVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<CompanyDetailModel>> | ((value: SetStateAction<CompanyDetailModel>) => void),
    ]
    companyTariffNatureRepo: CompanyTariffNatureRepository
}

export const CompanyTariffNatureVM = ({ dispatch, companyTariffNatureRepo }: CompanyTariffNatureVMProps) => {
    const [companyDetailDispatch] = dispatch;

    const onMultipleDropdownChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        return companyDetailDispatch(prevState => {
            const dropdownOptions = prevState.dynamicOptions.tariffNatureDropdownOptions;

            const selectedTariffNatures: TariffNatureEntity[] = [];
            dropdownOptions.forEach(item => {
                if (val && val.length > 0) {
                    const isExisted = val.some((value: string) => value === item.value);
                    if (isExisted) {
                        let tariffNatureEty: TariffNatureEntity = {
                            id: Number(item.tagLabel),
                            tariffNature: item.value,
                            tariffNatureDesc: "",
                            activeInd: "Y"
                        };

                        selectedTariffNatures.push(tariffNatureEty);
                    }
                }
            });

            return {
                ...prevState,
                companyTariffNatureState: {
                    ...prevState.companyTariffNatureState,
                    selectedTariffNatures: selectedTariffNatures
                }
            }
        });
    };

    const onSave = async (selectedTariffNatures: TariffNatureEntity[], companyId: number) => {
        const saveCompTariffNatures: CompanyTariffNatureEntity[] = [];
        if (selectedTariffNatures && selectedTariffNatures.length > 0) {
            selectedTariffNatures.forEach(tariffNature => {
                let compTariffNature: CompanyTariffNatureEntity = {
                    id: 0,
                    versionIdentifier: {},
                    companyId: null,
                    tariffNatureId: tariffNature.id,
                    company: null,
                    tariffNature: null
                };

                saveCompTariffNatures.push(compTariffNature);
            })
        } else {
            saveCompTariffNatures.push(EMPTY_COMPANY_TARIFF_NATURE_ENTITY);
        }
        if (saveCompTariffNatures) {
            saveCompTariffNatures.map(tariffNature => tariffNature.companyId = companyId);
        }

        await companyTariffNatureRepo.saveCompanyTariffNature(saveCompTariffNatures).then((data) => {
            if (data && data.toString().startsWith("Error:")) {
                companyDetailDispatch(prevState => {
                    return {
                        ...prevState,
                        allFormState: { "saveCompTariffNatureFailed": data.toString() }
                    };
                });
                return { "saveCompTariffNatureFailed": data.toString() };
            } else {
                return companyDetailDispatch(prevState => {
                    return {
                        ...prevState,
                        isShowRightPanel: false,
                        viewState: {
                            ...prevState.viewState,
                            isAdd: false,
                            isRead: true,
                            isEditable: false,
                            isSaveClicked: false,
                            lastEditRowId: '',
                            isSliderOpen: false,
                            allFormState: {},

                            isShowFsp: false,
                            isShowEmptyPool: false,
                            isShowSuppleTerms: false,
                            isShowCustAssign: false,
                            isShowFactor: false,
                            isShowCustInfo: false,
                            isEditCompDtlInfo: false,
                            isShowRejectModal: false,
                            rejectReason: "",
                            dataType: "",
                        }
                    }
                });
            }
        })
    }

    const initialTariffNatureData = async (compnayId: number) => {
        companyTariffNatureRepo.searchCompanyTariffNature(compnayId).then(data => {
            const tariffNatures: TariffNatureEntity[] = [];
            for (let i = 0; i < data?.length; i++) {
                if (data[i].tariffNature) {
                    tariffNatures.push(data[i].tariffNature as TariffNatureEntity);
                }
            }

            companyDetailDispatch(prevState => {
                return {
                    ...prevState,
                    companyTariffNatures: data,

                    companyTariffNatureState: {
                        ...prevState.companyTariffNatureState,
                        selectedTariffNatures: tariffNatures
                    }
                }
            })
        })
    }

    return {
        onMultipleDropdownChange: onMultipleDropdownChange,
        onSave: onSave,
        initialTariffNatureData: initialTariffNatureData
    }
}