import { SelectionChangedEvent } from "ag-grid-community";
import { MonthEndStatusPolicy } from "constants/monthEnd/MonthEndStatusPolicy";
import { MonthEndClosureEntity } from "domain/entity/MonthEndClosure/MonthEndClosureEntity";
import _ from "lodash";
import { MessageConstant } from "presentation/constant/MessageConstant";
import { INITIAL_MONTH_END_CLOSURE_COL_DEF, transferRowData } from "presentation/constant/MonthEndClosure/MonthEndClosureColumnDefinition";
import { MonthEndClosureConstant } from "presentation/constant/MonthEndClosure/MonthEndClosureConstant";
import { useMonthEndClosureVM } from "presentation/hook/MonthEndClosure/useMonthEndClosureVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useMonthEndClosureTracked } from "presentation/store/MonthEndClosure/MonthEndClosureProvider";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import { NbisConfirmModal } from "presentation/view/components/NbisConfirmModal";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { HPHButton, Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const MonthEndClosureTablePanel = () => {
    const monthEndClosureVM = useMonthEndClosureVM();
    const [monthEndClosureState] = useMonthEndClosureTracked();
    const MONTH_END_CLOSURE_CONSTANT = MonthEndClosureConstant.Title;
    const { selectedRows, isEdit } = monthEndClosureState;
    const [isLoading, setIsLoading] = useState(false);
    const messageBarVM = useMessageBarVM();
    const [isShowConfirmModal, setIsShowConfirmModal] = useState<string>("");
    const [isShowConfirmMsg, setIsShowConfirmMsg] = useState<string>("");
    const [initialAllRows, setInitialAllRows] = useState<boolean>(true);
    const [allRows, setAllRows] = useState<MonthEndClosureEntity[]>([]);

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();
        monthEndClosureVM.updateSelectedRows(selectedRows);
    }, [monthEndClosureVM])

    const handleRowDoubleClick = useCallback((entity: MonthEndClosureEntity) => {
        monthEndClosureVM.onRowDoubleClick(entity);
    }, [monthEndClosureVM])

    const handleStartMonthClick = useCallback(() => {
        if (selectedRows.length === 1) {
            setIsShowConfirmMsg("Are you sure to start the month " + selectedRows[0].month + "-" + selectedRows[0].year + "?");
            setIsShowConfirmModal(MONTH_END_CLOSURE_CONSTANT.BUTTON_START_MONTH);
        } else {
            messageBarVM.showError("Please select a record.");
        }
    }, [MONTH_END_CLOSURE_CONSTANT.BUTTON_START_MONTH, messageBarVM, selectedRows]);

    const handleStartMonth = useCallback(async () => {
        setIsLoading(true);
        await monthEndClosureVM.onStartMonthClick(selectedRows[0]).then(async data => {
            setIsShowConfirmModal("");
            if (data.success) {
                await monthEndClosureVM.initMonthEndClosureList().then(data => {
                    setInitialAllRows(true);
                    setIsLoading(false);
                    messageBarVM.showSuccess("Start Month Successfully.");
                })
            } else {
                messageBarVM.showError(data.data ?? "Error When Start Month.");
                setIsLoading(false);
            }
        });
    }, [messageBarVM, monthEndClosureVM, selectedRows]);

    const handleCloseMonthClick = useCallback(() => {
        if (selectedRows.length === 1) {
            setIsShowConfirmMsg("Are you sure to close the month " + selectedRows[0].month + "-" + selectedRows[0].year + "?");
            setIsShowConfirmModal(MONTH_END_CLOSURE_CONSTANT.BUTTON_CLOSE_MONTH);
        } else {
            messageBarVM.showError("Please select a record.");
        }
    }, [MONTH_END_CLOSURE_CONSTANT.BUTTON_CLOSE_MONTH, messageBarVM, selectedRows]);

    const handleCloseMonth = useCallback(async () => {
        setIsLoading(true);
        await monthEndClosureVM.onCloseMonthClick(selectedRows[0]).then(async data => {
            setIsShowConfirmModal("");
            if (data.success) {
                await monthEndClosureVM.initMonthEndClosureList().then(data => {
                    setInitialAllRows(true);
                    setIsLoading(false);
                    messageBarVM.showSuccess("Close Month Successfully.");
                })
            } else {
                messageBarVM.showError(data.data ?? "Error When Close Month.");
                setIsLoading(false);
            }
        });
    }, [messageBarVM, monthEndClosureVM, selectedRows]);

    const handleMonthlyRevenue = useCallback(() => {
        setIsLoading(true);
        monthEndClosureVM.onMonthlyRevenueClick(selectedRows).then(data => {
            if (!data) {
                messageBarVM.showError("Failed to view Monthly Revenue Report");
            }
            setIsLoading(false);
        })
    }, [messageBarVM, monthEndClosureVM, selectedRows]);

    const handleTurnoverCheck = useCallback(() => {
        setIsLoading(true);
        monthEndClosureVM.onTurnoverCheckClick(selectedRows).then(data => {
            if (!data) {
                messageBarVM.showError("Failed to view Turnover Check Report");
            }
            setIsLoading(false);
        })
    }, [messageBarVM, monthEndClosureVM, selectedRows]);

    const handleFinalizeMonthClick = useCallback(() => {
        if (selectedRows.length === 1) {
            if (selectedRows[0].activeInd === "N") {
                messageBarVM.showError("Active Ind is 'N',Can not do Finalize Month");
                return;
            }
            setIsShowConfirmMsg("Are you sure to finalize the month?");
            setIsShowConfirmModal(MONTH_END_CLOSURE_CONSTANT.BUTTON_FINALIZE_MONTH);
        } else {
            messageBarVM.showError("Please select a record.");
        }
    }, [MONTH_END_CLOSURE_CONSTANT.BUTTON_FINALIZE_MONTH, messageBarVM, selectedRows]);

    const handleFinalizeMonth = useCallback(async () => {
        setIsLoading(true);
        await monthEndClosureVM.onFinalizeMonthClick(selectedRows[0]).then(async data => {
            setIsShowConfirmModal("");
            if (data.success) {
                setAllRows([]);
                await monthEndClosureVM.initMonthEndClosureList().then(data => {
                    setInitialAllRows(true);
                    setIsLoading(false);
                    messageBarVM.showSuccess("Finalize Successfully.");
                })
            } else {
                messageBarVM.showError(data.data ?? "Error When finalize month end.");
                setIsLoading(false);
            }
        })
    }, [messageBarVM, monthEndClosureVM, selectedRows]);

    const handleReservation = useCallback(() => {
        if (selectedRows.length === 1) {
            setIsLoading(true);
            monthEndClosureVM.onReservationClick(selectedRows[0]).then(data => {
                setIsLoading(false);
            })
        } else {
            messageBarVM.showError("Please select a record.");
        }
    }, [messageBarVM, monthEndClosureVM, selectedRows]);

    const handleActivityLog = useCallback(async () => {
        if (selectedRows.length === 1) {
            setIsLoading(true);
            await monthEndClosureVM.onActivityLogClick().then(data => {
                setIsLoading(false);
            })
        } else {
            messageBarVM.showError("Please select a record.");
        }
    }, [messageBarVM, monthEndClosureVM, selectedRows.length]);

    const isCloseMonthDisable = () => {
        if (!isEdit && selectedRows.length > 0 && selectedRows.every(entity => entity.status === MonthEndStatusPolicy.STATUS_WIP
            || entity.status === MonthEndStatusPolicy.STATUS_CLOSED
            || entity.status === MonthEndStatusPolicy.STATUS_GENERATED)) return false;
        return true;
    }

    const isStartMonthDisable = () => {
        if (!isEdit && selectedRows.length > 0 && selectedRows.every(entity => entity.status === MonthEndStatusPolicy.STATUS_ERROR
            || entity.status === MonthEndStatusPolicy.STATUS_WIP
            || entity.status === MonthEndStatusPolicy.STATUS_NEW
            || entity.status === MonthEndStatusPolicy.STATUS_GENERATED)) return false;
        return true;
    }

    const isFinalizeDisable = () => {
        if (!isEdit && selectedRows.length > 0 && selectedRows.every(entity => entity.status === MonthEndStatusPolicy.STATUS_WIP || entity.status === MonthEndStatusPolicy.STATUS_CLOSED)) return false;
        return true;
    }

    const isActivityLogDisable = () => {
        if (!isEdit && selectedRows.length > 0 && selectedRows.every(entity => entity.status !== MonthEndStatusPolicy.STATUS_FINALIZED)) return false;
        return true;
    }

    const handleCancel = () => {
        setIsShowConfirmModal("");
        setIsShowConfirmMsg("");
    }

    const handleConfirm = () => {
        switch (isShowConfirmModal) {
            case MONTH_END_CLOSURE_CONSTANT.BUTTON_START_MONTH:
                handleStartMonth();
                break;
            case MONTH_END_CLOSURE_CONSTANT.BUTTON_CLOSE_MONTH:
                handleCloseMonth();
                break;
            case MONTH_END_CLOSURE_CONSTANT.BUTTON_FINALIZE_MONTH:
                handleFinalizeMonth();
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        if (initialAllRows && monthEndClosureState.monthEndClosureList && !_.isEmpty(monthEndClosureState.monthEndClosureList)) {
            setAllRows(monthEndClosureState.monthEndClosureList);
            setInitialAllRows(false);
        }
    }, [initialAllRows, messageBarVM, monthEndClosureState.monthEndClosureList]);

    const handRefresh = useCallback(async () => {
        setIsLoading(true);
        await monthEndClosureVM.initMonthEndClosureList().then(data => {
            setInitialAllRows(true);
            setIsLoading(false);
        })
    }, [monthEndClosureVM]);

    const memoBtns = useMemo(() => {

        const hdrBtns = [];
        hdrBtns.push({
            id: 'onRefreshButton',
            icon: 'Icon-reload',
            title: 'Refresh'
        })

        return hdrBtns
    }, [])

    const memoMonthEndClosureTable = useMemo(() => {

        return (
            <>
                <NbisTable
                    id='month-end-closure-table'
                    isNewColumnSetting={true}
                    columns={INITIAL_MONTH_END_CLOSURE_COL_DEF}
                    data={transferRowData(allRows ?? [])}
                    showPaginator={false}
                    headerActionButtons={memoBtns}
                    onRefreshButton={handRefresh}
                    editable={false}
                    showAddIcon={false}
                    showDeleteButton={false}
                    showReloadIcon={false}
                    isScrollHighlighted={true}
                    selectionMode={false}
                    isRowHighligted={true}
                    rowSelection={"multiple"}
                    onSelectionChanged={handleSelectionChange}
                    onRowDoubleClick={(e: any, entity: MonthEndClosureEntity) => handleRowDoubleClick(entity)}
                    gridHeight="customHeight"
                    customHeight="calc(100vh - 125px)"
                />
            </>
        );
    }, [allRows, memoBtns, handRefresh, handleSelectionChange, handleRowDoubleClick])


    return <>
        <Sidebarheader style={{ width: '100%', display: "flex", alignItems: "center" }}>
            <HeaderTitle>{MONTH_END_CLOSURE_CONSTANT.TITLE}</HeaderTitle>
            {(isLoading) && <Loader Indicator="Spinner" size="Medium" />}
            <StyledAction className="tm-animated-wrapper">
                <HPHButton label={MONTH_END_CLOSURE_CONSTANT.BUTTON_START_MONTH} size={"Small"} theme={"Secondary"} disabled={isStartMonthDisable()} onClick={handleStartMonthClick} />
                <HPHButton label={MONTH_END_CLOSURE_CONSTANT.BUTTON_CLOSE_MONTH} size={"Small"} theme={"Secondary"} disabled={isCloseMonthDisable()} onClick={handleCloseMonthClick} />
                <div className="add-seperator" />
                <HPHButton label={MONTH_END_CLOSURE_CONSTANT.BUTTON_RESERVATION} size={"Small"} theme={"Secondary"} disabled={isActivityLogDisable()} onClick={handleReservation} />
                <div className="add-seperator" />
                <HPHButton label={MONTH_END_CLOSURE_CONSTANT.BUTTON_MONTHLY_REVENUE} size={"Small"} theme={"Secondary"} disabled={isActivityLogDisable()} onClick={handleMonthlyRevenue} />
                <HPHButton label={MONTH_END_CLOSURE_CONSTANT.BUTTON_TURNOVER_CHECK} size={"Small"} theme={"Secondary"} disabled={isActivityLogDisable()} onClick={handleTurnoverCheck} />
                <div className="add-seperator" />
                <HPHButton label={MONTH_END_CLOSURE_CONSTANT.BUTTON_FINALIZE_MONTH} size={"Small"} theme={"Secondary"} disabled={isFinalizeDisable()} onClick={handleFinalizeMonthClick} />
                <div className="add-seperator" />
                <HPHButton label={MONTH_END_CLOSURE_CONSTANT.BUTTON_ACTIVITY_LOG} size={"Small"} theme={"Primary"} disabled={isActivityLogDisable()} onClick={handleActivityLog} />
                <NbisConfirmModal headerText={`${isShowConfirmModal}`}
                    contentText={`${isShowConfirmMsg}\n${MessageConstant.common.DELETE_ALERT_MESSAGE}`}
                    visible={isShowConfirmModal ? true : false}
                    onCancelClick={handleCancel}
                    onConfirmClick={handleConfirm}
                />
            </StyledAction>
            {monthEndClosureState.forceRefresh && <></>}
        </Sidebarheader>
        <TableWrapper>
            {monthEndClosureState.isLoading && <Loader Indicator="Spinner" size="Medium" />}
            {memoMonthEndClosureTable}
        </TableWrapper>
    </>;

}

export default memo(MonthEndClosureTablePanel);