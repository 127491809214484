import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useMemo } from "react";
import ChargeSuppleTermsTablePanel from "./ChargeSuppleTermsTablePanel";
import CompanyEmptyPoolTablePanel from "./CompanyEmptyPoolTablePanel";
import CompanyFspTablePanel from "./CompanyFspTablePanel";
import CompanyTeuFactorTablePanel from "./CompanyTeuFactorTablePanel";
import CustomerAssignmentTablePanel from "./CustomerAssignmentTablePanel";


const CompanyDetailTablePanel: React.FC = () => {

    const memoCompanyFspTable = useMemo(() => {
        return <CompanyFspTablePanel />
    }, [])

    const memoChargeSuppleTermsTable = useMemo(() => {
        return <ChargeSuppleTermsTablePanel />
    }, [])

    const memoCompanyEmptyPoolTable = useMemo(() => {
        return <CompanyEmptyPoolTablePanel />
    }, [])

    const memoCompanyTeuFactorTable = useMemo(() => {
        return <CompanyTeuFactorTablePanel />
    }, [])

    const memoCustomerAssignMentTable = useMemo(() => {
        return <CustomerAssignmentTablePanel />
    }, [])

    return <><div className="main-comp-wrapper">
        <div style={{ width: '100%', height: '100%', maxHeight: '95%', overflow: 'auto' }}>
            <TableWrapper> {memoCompanyFspTable} </TableWrapper>
            <TableWrapper> {memoCompanyEmptyPoolTable} </TableWrapper>
            <TableWrapper> {memoChargeSuppleTermsTable} </TableWrapper>
            <TableWrapper> {memoCustomerAssignMentTable} </TableWrapper>
            <TableWrapper> {memoCompanyTeuFactorTable} </TableWrapper>
        </div>
    </div></>;
}

export default memo(CompanyDetailTablePanel);
