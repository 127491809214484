import { SearchPreferenceEntity } from "domain/entity/Common/SearchPreferenceEntity";
import { ManualChargeHeaderEntity } from "domain/entity/ManualCharge/ManualChargeHeaderEntity";
import { EMPTY_MANUAL_CHARGE_HEADER_SEARCH_CRITERIA, ManualChargeHeaderSearchCriteria } from "presentation/constant/ManualCharge/ManualChargeHeaderSearchCriteria";
import { DEFAULT_ENABLED_MANUAL_CHARGE_HEADER_SEARCH_CRITERIA, ManualChargeHeaderEnabledSearchCriteria } from "presentation/constant/ManualCharge/ManualHeaderEnabledSearchCriteria";
import { RefObject } from "react";
import { DropdownProps } from "../DropdownProps";
import { EMPTY_MANUAL_CHARGE_HEADER_PRINT_CRITERIA, ManualChargeHeaderPrintCriteria } from "presentation/constant/ManualCharge/ManualChargeHeaderPrintCriteria";


export interface ManualChargeHeaderMaintenanceDropdownOptions {
    billToCompDropdownOptions: DropdownProps[],
    stateDropdownOptions: DropdownProps[],
    chargeTypeDropdownOptions: DropdownProps[],
    printerDropdownOptions: DropdownProps[],
}

export interface ManualChargeHeaderMaintenanceModel {
    isLoading: boolean,
    isShowRightPanel: boolean,
    isFilterSearchCriteria: boolean,
    isEditSearchCriteria: boolean,
    checkboxRef: { [key: string]: HTMLElement | null },
    enabledSearchCriteria: ManualChargeHeaderEnabledSearchCriteria,
    checkboxValue: ManualChargeHeaderEnabledSearchCriteria,
    searchCriteria: ManualChargeHeaderSearchCriteria,
    printCriteria: ManualChargeHeaderPrintCriteria,
    highlightedCheckboxKey?: string | null
    currentTableRef?: RefObject<any>,
    searchCounter: number,
    dynamicOptions: ManualChargeHeaderMaintenanceDropdownOptions,
    documentActive: boolean,
    isShowSaveNewConfirmModal: boolean,
    isShowDeleteConfirmModal: boolean,
    userEmail: string,
    searchPreferences: SearchPreferenceEntity<ManualChargeHeaderSearchCriteria, ManualChargeHeaderEnabledSearchCriteria>[],
    currentSearchPreference: SearchPreferenceEntity<ManualChargeHeaderSearchCriteria, ManualChargeHeaderEnabledSearchCriteria> | null,
    isShowDetail: boolean,
    manualChargeHeaders: ManualChargeHeaderEntity[],
    currentManualChargeHeader: ManualChargeHeaderEntity | null,
    selectedManualChargeHeaderRows: ManualChargeHeaderEntity[],
    isShowCriteriaPanel: boolean,
    isShowPrintModal: boolean,
    isBackFromDetail: boolean,
    isAllowAutoSearch: boolean,
    forceRefresh: boolean,
}

export const EMPTY_MANUAL_CHARGE_HEADER_MAINTENANCE_MODEL: ManualChargeHeaderMaintenanceModel = {
    isLoading: false,
    isShowRightPanel: false,
    isFilterSearchCriteria: false,
    isEditSearchCriteria: false,
    checkboxRef: {},
    enabledSearchCriteria: { ...DEFAULT_ENABLED_MANUAL_CHARGE_HEADER_SEARCH_CRITERIA },
    checkboxValue: { ...DEFAULT_ENABLED_MANUAL_CHARGE_HEADER_SEARCH_CRITERIA },
    searchCriteria: { ...EMPTY_MANUAL_CHARGE_HEADER_SEARCH_CRITERIA },
    printCriteria: { ...EMPTY_MANUAL_CHARGE_HEADER_PRINT_CRITERIA},
    searchCounter: 0,
    dynamicOptions: {
        billToCompDropdownOptions: [],
        stateDropdownOptions: [],
        chargeTypeDropdownOptions: [],
        printerDropdownOptions: []
    },
    documentActive: false,
    isShowSaveNewConfirmModal: false,
    isShowDeleteConfirmModal: false,
    userEmail: "",
    searchPreferences: [],
    currentSearchPreference: null,
    isShowDetail: false,
    manualChargeHeaders: [],
    currentManualChargeHeader: null,
    selectedManualChargeHeaderRows: [],
    isShowCriteriaPanel: false,
    isShowPrintModal: false,
    isBackFromDetail: false,
    isAllowAutoSearch: true,
    forceRefresh: false
}