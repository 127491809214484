import { TariffCodeConstant } from "presentation/constant/TariffCode/TariffCodeConstant"
import { useTariffCodeByCompanyVM } from "presentation/hook/TariffCodeByCompany/useTariffCodeByCompanyVM"
import { useMessageBarVM } from "presentation/hook/useMessageBar"
import { useTariffCodeByCompanyTracked } from "presentation/store/TariffCodeByCompany/TariffCodeByCompanyProvider"
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer"
import { NbisCommonField } from "presentation/view/components/NbisCommonField"
import { useCallback, useMemo, useState } from "react"
import { FieldType, HPHButton, IFieldValue, Loader } from "veronica-ui-component/dist/component/core"
import { SidebarCaption, Sidebarheader, SidebarTitle } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled"

export const TariffCodeByCompanyFormPanel = () => {
    const [tariffCodeByCompanyState] = useTariffCodeByCompanyTracked();
    const tariffCodeByCompanyVM = useTariffCodeByCompanyVM();
    const messageBarVM = useMessageBarVM();
    const { masterState, dynamicOptions } = tariffCodeByCompanyState;
    const { isRead, isSaveClicked, allFormState, tariffCompanyRequest } = masterState;
    const [isLoading, setIsLoading] = useState(false);

    const handleCancelClick = () => {
        tariffCodeByCompanyVM.onCancel();
    }

    const handleSaveClick = useCallback(async () => {
        tariffCodeByCompanyVM.onSaveClicked();
        setIsLoading(true);
        const res = await tariffCodeByCompanyVM.onSave(tariffCompanyRequest);
        setIsLoading(false);
        if (!!!res || !res.success) {
            /*if (res.data === "Duplicate record found.") {
                messageBarVM.showError('Duplicate record found, please check the key: Company Code.');
                return;
            }*/
            messageBarVM.showError(res.data ?? 'Save data failed.');
        } else {
            tariffCodeByCompanyVM.onCancel();
            setIsLoading(true);
            await tariffCodeByCompanyVM.onSearchCompany();
            messageBarVM.showSuccess('Save successful.');
        }
        setIsLoading(false);
    }, [tariffCompanyRequest, tariffCodeByCompanyVM, messageBarVM])

    const memoNature = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "400px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={false}
                isShowOptional={false}
                readOnlyValue={''}
                fieldValue={tariffCompanyRequest?.companines ?? []}
                fieldLabel={TariffCodeConstant.Search.COMPANY_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'companines'}
                maxLength={600}
                isMultipleDropDown={true}
                options={dynamicOptions.companyDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffCodeByCompanyVM.onHeaderMultipleDropdownChange(fieldKey, fieldValue && fieldValue as string[], fFullValue)} />

        </div>
        , [allFormState, tariffCompanyRequest?.companines, isSaveClicked, dynamicOptions.companyDropdownOptions, tariffCodeByCompanyVM])

    return (
        <div className='side-form-content-wrapper' style={{ position: 'relative', height: "100vh-128px" }}>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>
                            <SidebarTitle>{'NEW COMPANY'}</SidebarTitle>
                            <SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>
                        </SidebarTitle>
                    </Sidebarheader>
                </div>
            </div>

            {isLoading && <Loader Indicator="Spinner" size="Medium" />}

            <div className={'add-edit-form'} style={{ height: '50vh', maxHeight: '50vh', overflow: 'auto' }}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        <CriteriaItemContainer>
                            {memoNature}
                        </CriteriaItemContainer>
                    </div>
                </div>
                <div className='im-charge-data-search-criteria-buttons' >
                    {
                        <>
                            <HPHButton label={isRead ? 'Close' : 'Cancel'} size={'Small'} theme={'Secondary'} onClick={handleCancelClick} />
                            <HPHButton label={'Add'} size={'Small'} theme={'Primary'} onClick={() => handleSaveClick()} />
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

