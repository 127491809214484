import { TariffComponentMaintenanceConstant } from "presentation/constant/TariffComponent/TariffComponentMaintenanceConstant";
import { useTariffComponentMaintenanceVM } from "presentation/hook/TariffComponent/useTariffComponentMaintenanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useTariffComponentMaintenanceTracked } from "presentation/store/TariffComponent/TariffComponentMaintenanceProvider";
import { memo, useCallback, useState } from "react";
import { HPHBreadcrumb, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const TariffComponentDetailTitleBar: React.FC = () => {
    const [tariffComponentState] = useTariffComponentMaintenanceTracked();
    const tariffComponentVM = useTariffComponentMaintenanceVM();
    const messageBarVM = useMessageBarVM();
    const { currentSelectedRow, masterState, detailState } = tariffComponentState;
    const { editingEntity, isAdd, isEditable, isRead } = masterState;
    const { isAdd: isDetailAdd, isEditable: isDetailEdit, detailList } = detailState;

    const [anainfoState] = useANAInfoTracked();
    const { allowCreate, allowUpdate } = anainfoState;
    const [isLoading, setIsLoading] = useState(false);
    const currentEntity = isRead ? currentSelectedRow : editingEntity;

    const isDisable = () => {
        if (isDetailAdd || (!isDetailAdd && isDetailEdit)) return true;

        return false;
    }

    const handleReset = useCallback(async () => {
        tariffComponentVM.onReset();
    }, [tariffComponentVM]);

    const handleEdit = useCallback(() => {
        tariffComponentVM.onEdit(currentEntity);
    }, [currentEntity, tariffComponentVM])

    const handleSave = useCallback(async () => {
        setIsLoading(true);
        try {
            tariffComponentVM.onSaveClicked();
            const res = await tariffComponentVM.onSave({ ...currentEntity, componentValues: detailList }, isAdd);
            if (!!!res || !res.success) {
                messageBarVM.showError(res?.data ?? 'Save data failed.');
            } else {
                tariffComponentVM.onClose();
                setIsLoading(true);
            }
        } catch (error) {
            messageBarVM.showError('Save data failed.');
        } finally {
            setIsLoading(false)
        }

    }, [currentEntity, detailList, isAdd, messageBarVM, tariffComponentVM]);

    return <Sidebarheader style={{ width: '100%' }}>
        {isLoading && <Loader Indicator="Spinner" size="Medium" />}
        <Breadcrumb>
            <HPHBreadcrumb breadcrumbData={[{ title: TariffComponentMaintenanceConstant.CATEGORY }, { title: TariffComponentMaintenanceConstant.TITLE }]} onCurrentClick={() => { }}></HPHBreadcrumb>
        </Breadcrumb>
        <StyledAction>
            {(!isAdd && !isEditable) &&
                <>
                    {/* Edit Button */}
                    {
                        (
                            (!(isAdd && isEditable) && allowUpdate)
                        )
                        && <IconButton fileName='Icon-pen' size='medium' toolTipText={'Edit'} toolTipArrow={false} onClick={handleEdit} />
                    }
                    {/* Close Button */}
                    <IconButton fileName='Icon-cross' size='medium' toolTipText={'Close'} toolTipArrow={false} onClick={tariffComponentVM.onClose} />
                </>}
            {(isAdd || isEditable) &&
                <>
                    {/* Reset Form Button */}
                    <IconButton fileName="Icon-reset" size="medium" toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Reset'} onClick={handleReset} />
                    {/* Close Button */}
                    <IconButton fileName="Icon-cross" size="medium" disabled={isDisable()} toolTipArrow={false} toolTipPlacement="left" toolTipText={'Close'} onClick={tariffComponentVM.onClose} />
                    {/* Save Button */}
                    {
                        (
                            (isAdd && allowCreate) ||
                            (isEditable && allowUpdate)
                        )
                        && <IconButton fileName="Icon-tick" size="medium" disabled={isDisable()} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Save'} onClick={() => handleSave()} />
                    }
                </>}
        </StyledAction>
    </Sidebarheader>
}

export default memo(TariffComponentDetailTitleBar);