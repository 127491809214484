import { BuOperatingCompanyEntity, BuProfileEntity, EMPTY_BU_OPERATING_COMPANY_ENTITY, EMPTY_BU_PROFILE_ENTITY } from "domain/entity/BuProfile/BuProfileEntity";
import { DomainCodeList } from "domain/entity/Domain/DomainEntity";
import { MasterDataType } from "domain/entity/MasterData/MasterDataEntity";
import { BuProfileRepository } from "domain/repository/BuProfile/BuProfileRepo";
import { DomainRepository } from "domain/repository/Domain/DomainRepo";
import { ExchangeRateRepository } from "domain/repository/ExchangeRate/ExchangeRateRepo";
import { MasterDataRepository } from "domain/repository/MasterData/MasterDataRepo";
import _ from "lodash";
import { BuProfileModel } from "presentation/model/BuProfile/BuProfileModel";
import { DropdownProps } from "presentation/model/DropdownProps";
import BaseViewModel from "presentation/viewModel/BaseViewModel";
import { ChangeEvent, Dispatch, SetStateAction } from "react";

interface BuProfileVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<BuProfileModel>> | ((value: SetStateAction<BuProfileModel>) => void),
    ]
    buProfileRepo: BuProfileRepository,
    exchangeRateRepo: ExchangeRateRepository,
    masterDataRepo: MasterDataRepository,
    domainRepo: DomainRepository
}

export const BuProfileVM = ({ dispatch, buProfileRepo, exchangeRateRepo, masterDataRepo, domainRepo }: BuProfileVMProps) => {
    const [buProfileDispatch] = dispatch;

    const addBuProfile = async (buProfile: BuProfileEntity | null) => {
        let saveSuccess = true;
        let result: BuProfileEntity | null = null;
        if (buProfile?.buCode) {
            try {
                result = await buProfileRepo.createNewBuProfile(buProfile);
                if (_.isEmpty(result)) {
                    saveSuccess = false
                }
            } catch (e: any) {
                saveSuccess = false
            }
        };
        buProfileDispatch(prevState => {
            const obj: BuProfileModel = {
                ...prevState,
                ...saveSuccess && {
                    isEditBuProfile: false,
                    buProfile: result ?? EMPTY_BU_PROFILE_ENTITY
                }
            };
            return { ...obj };
        });
        loadBuProfile(buProfile?.buCode ?? "");
    }

    const updateBuProfile = async (buProfile: BuProfileEntity | null) => {
        if (buProfile?.buCode) {
            try {
                await buProfileRepo.updateBuProfile(buProfile).then(data => {
                    buProfileDispatch(prevState => {
                        const obj: BuProfileModel = {
                            ...prevState,
                            isEditBuProfile: false,
                            buProfile: data ?? EMPTY_BU_PROFILE_ENTITY
                        };
                        return { ...obj };
                    });
                    loadBuProfile(buProfile?.buCode ?? "");
                })
            } catch (e: any) {
            }
        };
    }

    const addOperatingCompany = async () => {
        buProfileDispatch(prevState => {
            let operatingCompanies = prevState.buProfile.buOperatingCompanies;
            if (prevState.currentOperatingCompany?.operatingCompany) {
                const isOperatingCompanyExist = _.find(prevState.buProfile.buOperatingCompanies, (operatingCompany) => {
                    return operatingCompany.operatingCompany === prevState.currentOperatingCompany.operatingCompany;
                });
                if (!isOperatingCompanyExist) {
                    operatingCompanies = _.concat(prevState.buProfile.buOperatingCompanies ?? [], prevState.currentOperatingCompany);
                }
            }
            return {
                ...prevState,
                isShowCompanyEditPanel: false,
                buProfile: {
                    ...prevState.buProfile,
                    buOperatingCompanies: operatingCompanies,
                }
            };
        })
    }

    const updateOperatingCompany = async () => {
        buProfileDispatch(prevState => {
            let filteredProfiles = prevState.buProfile.buOperatingCompanies?.filter((profile) =>
                profile.operatingCompany !== prevState.currentOperatingCompany.operatingCompany);
            let operatingCompanies = _.concat(filteredProfiles ?? [], prevState.currentOperatingCompany);
            return {
                ...prevState,
                isShowCompanyEditPanel: false,
                buProfile: {
                    ...prevState.buProfile,
                    buOperatingCompanies: operatingCompanies
                }
            };
        })
    }

    const cleanBase64 = (base64: string): string => {
        return base64.replace(/[^A-Za-z0-9+/=]/g, '');
    };

    const decodeBase64 = (base64: string): string => {
        try {
            const cleanedBase64 = cleanBase64(base64);
            return atob(cleanedBase64);
        } catch (error) {
            console.error('Failed to decode Base64 string:', error);
            throw error;
        }
    };

    const loadBuProfile = async (key: string) => {
        await buProfileRepo.getBuProfileByKey(key).then((data) => {
            if (data) {
                if (data.loginPageLogo) {
                    const fileName = data.loginPageLogo;
                    buProfileRepo.loadBuProfileImages(fileName).then((data) => {
                        if (data) {
                            let base64 = data
                            let bstr = decodeBase64(base64)
                            let n = bstr.length
                            let u8arr = new Uint8Array(n)
                            while (n--) {
                                u8arr[n] = bstr.charCodeAt(n)
                            }
                            const blob = new Blob([u8arr], { type: 'image/jpg' });
                            const url = URL.createObjectURL(blob);
                            buProfileDispatch(prevState => {
                                return {
                                    ...prevState,
                                    buProfile: {
                                        ...prevState.buProfile,
                                        loginPageLogoUrl: url,
                                    }
                                };
                            })
                        }
                    });
                }

                if (data.loginPageWallpaper) {
                    const fileName = data.loginPageWallpaper;
                    buProfileRepo.loadBuProfileImages(fileName).then((data) => {
                        if (data) {
                            let base64 = data
                            let bstr = decodeBase64(base64)
                            let n = bstr.length
                            let u8arr = new Uint8Array(n)
                            while (n--) {
                                u8arr[n] = bstr.charCodeAt(n)
                            }
                            const blob = new Blob([u8arr], { type: 'image/jpg' });
                            const url = URL.createObjectURL(blob);
                            buProfileDispatch(prevState => {
                                return {
                                    ...prevState,
                                    buProfile: {
                                        ...prevState.buProfile,
                                        loginPageWallpaperUrl: url,
                                    }
                                };
                            })
                        }
                    });
                }
                buProfileDispatch(prevState => {
                    return {
                        ...prevState,
                        buProfile: {
                            ...data,
                        }
                    };
                })
            }
        });
    }

    const loadDropdownOptions = () => {
        masterDataRepo.getMasterDataByKey(MasterDataType.COUNTRY).then(
            countries => {
                let countryDropdownOptions = countries?.map((country) => ({
                    dropdownLabel: country.code,
                    tagLabel: country.code,
                    value: country.code,
                })) ?? []
                countryDropdownOptions = _.orderBy(countryDropdownOptions, "dropdownLabel");

                buProfileDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        countryDropdownOptions: countryDropdownOptions,
                    }
                }))
            }
        );
        exchangeRateRepo.getAllCurrencies().then(
            currencies => {
                let currencyDropdownOptions = currencies?.map((currency) => ({
                    dropdownLabel: currency.currencyCode,
                    tagLabel: currency.currencyCode,
                    value: currency.currencyCode,
                })) ?? []
                currencyDropdownOptions = _.orderBy(currencyDropdownOptions, "dropdownLabel");

                buProfileDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        currencyCodeDropdownOptions: currencyDropdownOptions,
                    }
                }))
            }
        );
        domainRepo.getDomainByKey(DomainCodeList.TIMEZONE).then(
            domain => {
                let timezones = domain?.sysDomainValues;
                let timezoneDropdownOptions = timezones?.map((timezone) => ({
                    dropdownLabel: timezone.description ?? "",
                    tagLabel: timezone.description ?? "",
                    value: timezone.userInputCode ?? "",
                })) ?? []
                timezoneDropdownOptions = _.orderBy(timezoneDropdownOptions, "dropdownLabel");

                buProfileDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        timezoneDropdownOptions: timezoneDropdownOptions,
                    }
                }))
            }
        );
    }

    const handleLogoChange = (event: any) => {
        const { files } = event;
        const file = files[0];
        // get file name without file sub type
        //const fileName = file.name.split(".")[0];
        buProfileRepo.uploadBuProfileImages(file).then(fileName => {
            buProfileDispatch((prevState) => ({
                ...prevState,
                buProfile: {
                    ...prevState.buProfile,
                    loginPageLogo: fileName,
                    logoFile: file,
                }
            }));

        });
    };

    const handleWallpaperChange = async (event: any) => {
        if (!event.target?.files) return;

        const files = event.target?.files;
        const file = files[0];
        // get file name without file sub type
        //const fileName = file.name.split(".")[0];
        await buProfileRepo.uploadBuProfileImages(file).then(async (fileName) => {
            await buProfileRepo.loadBuProfileImages(fileName).then((data) => {
                if (data) {
                    let base64 = data
                    let bstr = atob(base64)
                    let n = bstr.length
                    let u8arr = new Uint8Array(n)
                    while (n--) {
                        u8arr[n] = bstr.charCodeAt(n)
                    }
                    const blob = new Blob([u8arr], { type: 'image/jpg' });
                    const url = URL.createObjectURL(blob);
                    buProfileDispatch(prevState => {
                        return {
                            ...prevState,
                            buProfile: {
                                ...prevState.buProfile,
                                loginPageWallpaper: fileName,
                                loginPageWallpaperUrl: url,
                            }
                        };
                    })
                }
            });
        });
    };

    const onCloseScreen = () => {
        document.dispatchEvent(new CustomEvent('closeBuProfile'))
    }

    const onCancelCompanyEditPanel = () => {
        buProfileDispatch(prevState => {
            return {
                ...prevState,
                currentOperatingCompany: EMPTY_BU_OPERATING_COMPANY_ENTITY,
                isShowCompanyEditPanel: false,
                isAddOperatingCompany: false
            };
        });
    }

    const onEditCompanyEditPanel = (data: BuOperatingCompanyEntity) => {
        buProfileDispatch(prevState => {
            return {
                ...prevState,
                currentOperatingCompany: data,
                isShowCompanyEditPanel: true,
                isAddOperatingCompany: false
            };
        });
    }

    const onAddCompanyEditPanel = () => {
        buProfileDispatch(prevState => {
            return {
                ...prevState,
                currentOperatingCompany: EMPTY_BU_OPERATING_COMPANY_ENTITY,
                isShowCompanyEditPanel: true,
                isAddOperatingCompany: true
            };
        });
    }

    const onDeleteCompanyEditPanel = (data: BuOperatingCompanyEntity) => {
        buProfileDispatch(prevState => {
            const operatingCompanies = prevState.buProfile.buOperatingCompanies?.filter((comp) =>
                comp.operatingCompany !== data.operatingCompany);
            return {
                ...prevState,
                currentOperatingCompany: EMPTY_BU_OPERATING_COMPANY_ENTITY,
                buProfile: {
                    ...prevState.buProfile,
                    buOperatingCompanies: operatingCompanies ?? []
                }
            };
        });
    }

    // Open search criteria panel not preview panel
    const onEditBuProfile = () => {
        buProfileDispatch(prevState => {
            return {
                ...prevState,
                isEditBuProfile: true
            }
        });
    }

    // Open directory criteria panel not search criteria preview panel
    const onCloseEditBuProfile = (buProfile: BuProfileEntity | null) => {
        buProfileDispatch(prevState => {
            return {
                ...prevState,
                isEditBuProfile: false,
                isAddOperatingCompany: false
            };
        });
        loadBuProfile(buProfile?.buCode ?? "");
    }

    const onDropdownChange = (e: any, fieldName: string) => {
        buProfileDispatch(prevState => ({
            ...prevState,
            buProfile: {
                ...prevState.buProfile,
                [fieldName]: e?.value ?? (_.isEmpty(e) ? '' : e),
            }
        }))
    }

    const onDatePickerChange = (value: Date, fieldName: string) => {
        buProfileDispatch(prevState => ({
            ...prevState,
            buProfile: {
                ...prevState.buProfile,
                [fieldName]: value,
            }
        }))
    }

    const onTimePickerChange = (value: Date, hourName: string, minName: string) => {
        const hours = value.getHours();
        const mins = value.getMinutes();
        buProfileDispatch(prevState => ({
            ...prevState,
            buProfile: {
                ...prevState.buProfile,
                [hourName]: hours,
                [minName]: mins
            }
        }))
    }

    const onDateRangeChange = (inputData: Date[], fieldName: { date1: string, date2: string, }) => {
        buProfileDispatch(prevState => ({
            ...prevState,
            buProfile: {
                ...prevState.buProfile,
                [fieldName.date1]: inputData[0] ?? null,
                [fieldName.date2]: inputData[1] ?? null,
            }
        }))
    }

    const onMultipleDropdownChange = (e: any, fieldName: string) => {
        buProfileDispatch(prevState => {
            return {
                ...prevState,
                buProfile: {
                    ...prevState.buProfile,
                    [fieldName]: _.uniq(e?.map((item: DropdownProps) => item.value)),
                }
            }
        });
    };

    const onInputTextChange = (e: ChangeEvent<HTMLInputElement>, fieldName: string) => {
        buProfileDispatch(prevState => ({
            ...prevState,
            buProfile: {
                ...prevState.buProfile,
                [fieldName]: fieldName === 'uniqueCntrNo' ? e.target.value.toString().toUpperCase() : e.target.value,
            }
        }))
    };

    const onInputCompanyTextChange = (e: ChangeEvent<HTMLInputElement>, fieldName: string) => {
        buProfileDispatch(prevState => ({
            ...prevState,
            currentOperatingCompany: {
                ...prevState.currentOperatingCompany,
                [fieldName]: e.target.value
            }
        }))
    };

    const onTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>, fieldName: string) => {
        buProfileDispatch(prevState => ({
            ...prevState,
            buProfile: {
                ...prevState.buProfile,
                [fieldName]: fieldName === 'cntrList' ?
                    (e.target.value && e.target.value.toString().includes(",") ? e.target.value.toString().split(",") : [e.target.value.toString()]) :
                    e.target.value.toString(),
            }
        }))
    };

    return {
        addBuProfile: addBuProfile,
        updateBuProfile: updateBuProfile,
        loadBuProfile: loadBuProfile,
        onCloseEditBuProfile: onCloseEditBuProfile,
        onCloseScreen: onCloseScreen,
        onCancelCompanyEditPanel: onCancelCompanyEditPanel,
        onEditBuProfile: onEditBuProfile,
        onDatePickerChange: onDatePickerChange,
        onTimePickerChange: onTimePickerChange,
        onDateRangeChange: onDateRangeChange,
        onDropdownChange: onDropdownChange,
        onMultipleDropdownChange: onMultipleDropdownChange,
        onInputTextChange: onInputTextChange,
        onInputCompanyTextChange: onInputCompanyTextChange,
        onTextAreaChange: onTextAreaChange,
        onEditCompanyEditPanel: onEditCompanyEditPanel,
        onAddCompanyEditPanel: onAddCompanyEditPanel,
        onDeleteCompanyEditPanel: onDeleteCompanyEditPanel,
        loadDropdownOptions: loadDropdownOptions,
        handleLogoChange: handleLogoChange,
        handleWallpaperChange: handleWallpaperChange,
        addOperatingCompany: addOperatingCompany,
        updateOperatingCompany: updateOperatingCompany
    }
}