import { SelectionChangedEvent } from "ag-grid-community";
import { CriteriaEntity } from "domain/entity/Criteria/CriteriaEntity";
import { CriteriaRequestEntity } from "domain/entity/Criteria/CriteriaRequestEntity";
import _ from "lodash";
import { INITIAL_CHARGE_CRITERIA_COL_DEF } from "presentation/constant/Criteria/ChargeCriteriaColumnDefinition";
import { INITIAL_CHARGE_EXCL_CRITERIA_COL_DEF } from "presentation/constant/Criteria/ChargeExclusionCriteriaColumnDefinition";
import { CriteriaConstant } from "presentation/constant/Criteria/CriteriaConstant";
import { INITIAL_GENERAL_CHARGE_CRITERIA_COL_DEF } from "presentation/constant/Criteria/GeneralChargeCriteriaColumnDefinition";
import { useCriteriaVM } from "presentation/hook/Criteria/useCriteriaVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useCriteriaTracked } from "presentation/store/Criteria/CriteriaProvider";
import RightClickMenuWithMoveAfter from "presentation/view/components/NbisRightClickMenu/RightClickMenuWithMoveAfter";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { createRef, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Loader } from "veronica-ui-component/dist/component/core";


const CriteriaTablePanel: React.FC = () => {
    const [criteriaState] = useCriteriaTracked();
    const criteriaVM = useCriteriaVM();
    const gridRef: any = useRef(null);
    const rightClickRef: any = createRef();
    const { selectedRows, entrypoint } = criteriaState;
    const [anainfoState] = useANAInfoTracked();
    const { allowUpdate } = anainfoState;
    const messageBarVM = useMessageBarVM();
    const [showMoveCursor, setShowMoveCursor] = useState<boolean>(false);
    const [allRows, setAllRows] = useState<CriteriaEntity[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [initialAllRows, setInitialAllRows] = useState<boolean>(true);
    const CRITERIA_CONSTANT = CriteriaConstant.Title;

    const getCurrentColDefs = useCallback(() => {
        let colDefs;
        if (entrypoint === 'CHARGE') {
            colDefs = INITIAL_CHARGE_CRITERIA_COL_DEF;
        } else if (entrypoint === 'GCGEN') {
            colDefs = INITIAL_GENERAL_CHARGE_CRITERIA_COL_DEF;
        } else if (entrypoint === 'CHGEXL' || entrypoint === 'DOCSEP' || entrypoint === 'DOCGROUP') {
            colDefs = INITIAL_CHARGE_EXCL_CRITERIA_COL_DEF;
        } else {
            colDefs = INITIAL_CHARGE_CRITERIA_COL_DEF;
        }
        return colDefs;
    }, [entrypoint]);


    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();

        criteriaVM.updateSelectedRows(selectedRows);
    }, [criteriaVM])

    const handleAdd = useCallback(() => {
        criteriaVM.onAddClick();
    }, [criteriaVM]);

    const handleRowDoubleClick = useCallback((entity: CriteriaEntity) => {
        criteriaVM.onRowDoubleClick(entity);
    }, [criteriaVM])

    useEffect(() => {
        const handleRightClickMenu = (event: any) => {
            if (_.isEmpty(selectedRows) || !allowUpdate) {
                return;
            }
            event.preventDefault();
            rightClickRef.current.show(event);
        };
        document.getElementById('myDiv')?.addEventListener("contextmenu", handleRightClickMenu);

        return () => {
            document.getElementById('myDiv')?.removeEventListener("contextmenu", handleRightClickMenu);
        };
    }, [messageBarVM, rightClickRef, allowUpdate, selectedRows])



    const getSelectedRows = useCallback(() => {
        return selectedRows;
    }, [selectedRows]);

    const getAllRows = useCallback(() => {
        return allRows;
    }, [allRows]);

    const onSaveMove = useCallback(async (newAllRows: any[]) => {
        if (_.isEmpty(newAllRows)) return;
        let criterRequestEntity: CriteriaRequestEntity = {};
        criterRequestEntity = { criteriaList: newAllRows };

        setIsLoading(true);

        criteriaVM.onApply(criterRequestEntity).then((res) => {
            if (!res || !res.success) {
                messageBarVM.showError('Move failed.');
                setInitialAllRows(true);
                setIsLoading(false);

                criteriaVM.updateSelectedRows([]);
                setShowMoveCursor(false);
            } else {
                criteriaVM.searchCriteria(entrypoint).then((data) => {
                    setInitialAllRows(true);
                    setIsLoading(false);

                    criteriaVM.updateSelectedRows([]);
                    setShowMoveCursor(false);
                }).catch((e) => {
                    setInitialAllRows(true);
                    setIsLoading(false);

                    criteriaVM.updateSelectedRows([]);
                    setShowMoveCursor(false);
                })
            }
        })
    }, [criteriaVM, messageBarVM, entrypoint]);

    const onRefreshRow = useCallback((newAllRows: any[]) => {
        setAllRows(newAllRows);
        criteriaVM.updateSelectedRows([]);
        setShowMoveCursor(true);
    }, [criteriaVM]);

    const onMoveCancel = useCallback(() => {
        setInitialAllRows(true);
        setAllRows(criteriaState.criteriaEntityList);
        criteriaVM.updateSelectedRows([]);
        setShowMoveCursor(false);
    }, [criteriaState.criteriaEntityList, criteriaVM]);

    const setHeaderLabel = useCallback(() => {
        switch (criteriaState.entrypoint) {
            case 'CHARGE':
                return CRITERIA_CONSTANT.CHARGE_CRITERIA_MAIN;
            case 'GCGEN':
                return CRITERIA_CONSTANT.GENERAL_CHARGE_CRITERIA_MAIN;
            case 'CHGEXL':
                return CRITERIA_CONSTANT.CHARGE_EXCLUSION_CRITERIA_MAIN;
            case 'DOCSEP':
                return CRITERIA_CONSTANT.DOCUMENT_SEPARATION_CRITERIA_MAIN;
            case 'DOCGROUP':
                return CRITERIA_CONSTANT.DOCUMENT_GROUPING_CRITERIA_MAIN;
            default:
                return "";
        }
        // { criteriaState.entrypoint === 'CHARGE' && CRITERIA_CONSTANT.CHARGE_CRITERIA_MAIN }
        // { criteriaState.entrypoint === 'GCGEN' && CRITERIA_CONSTANT.GENERAL_CHARGE_CRITERIA_MAIN }
        // { criteriaState.entrypoint === 'CHGEXL' && CRITERIA_CONSTANT.CHARGE_EXCLUSION_CRITERIA_MAIN }
        // { criteriaState.entrypoint === 'DOCSEP' && CRITERIA_CONSTANT.DOCUMENT_SEPARATION_CRITERIA_MAIN }
        // { criteriaState.entrypoint === 'DOCGROUP' && CRITERIA_CONSTANT.DOCUMENT_GROUPING_CRITERIA_MAIN }
    }, [CRITERIA_CONSTANT.CHARGE_CRITERIA_MAIN, CRITERIA_CONSTANT.CHARGE_EXCLUSION_CRITERIA_MAIN, CRITERIA_CONSTANT.DOCUMENT_GROUPING_CRITERIA_MAIN, CRITERIA_CONSTANT.DOCUMENT_SEPARATION_CRITERIA_MAIN, CRITERIA_CONSTANT.GENERAL_CHARGE_CRITERIA_MAIN, criteriaState.entrypoint]);

    const memoCriteriaTable = useMemo(() => {

        return (
            <>
                {(allowUpdate) && <RightClickMenuWithMoveAfter disabled={_.isEmpty(getSelectedRows())}
                    rightClickRef={rightClickRef} selectedRows={getSelectedRows()} allRows={getAllRows()}
                    onSaveMove={onSaveMove} onCancel={onMoveCancel} onRefreshRow={onRefreshRow}
                    showConfirmMove={showMoveCursor} priorityName="priority" />}
                <div id="myDiv">
                    <NbisTable
                        id={`criteria-table-${entrypoint}`}
                        key={`criteria-table-${entrypoint}`}
                        isNewColumnSetting={true}
                        headerLabel={setHeaderLabel()}
                        columns={getCurrentColDefs()}
                        data={allRows ?? []}
                        showPaginator={false}
                        editable={false}
                        showAddIcon={true}
                        showDeleteButton={false}
                        showReloadIcon={false}
                        isScrollHighlighted={true}
                        selectionMode={true}
                        showCutCursor={showMoveCursor}
                        isRowHighligted={true}
                        onAddClick={handleAdd}
                        onSelectionChanged={handleSelectionChange}
                        onRowDoubleClick={(e: any, entity: CriteriaEntity) => handleRowDoubleClick(entity)}
                        gridHeight="customHeight"
                        customHeight="calc(100vh - 75px)"
                        ref={gridRef}
                        rowSelection={showMoveCursor ? "single" : "multiple"}

                    />
                </div>
            </>
        );
    }, [allRows, allowUpdate, entrypoint, getAllRows, getCurrentColDefs, getSelectedRows, handleAdd, handleRowDoubleClick, handleSelectionChange, onMoveCancel, onRefreshRow, onSaveMove, rightClickRef, setHeaderLabel, showMoveCursor])

    useEffect(() => {
        if (initialAllRows && criteriaState.criteriaEntityList && !_.isEmpty(criteriaState.criteriaEntityList)) {
            setAllRows(criteriaState.criteriaEntityList.map((criteriaEntity, index) => ({
                ...criteriaEntity,
                index: index || 0,
                flag_showCutCursor: false
            })));
            setInitialAllRows(false);
        }
    }, [allRows, initialAllRows, criteriaState.criteriaEntityList]);

    return <><TableWrapper>
        {(isLoading) && <Loader Indicator="Spinner" size="Medium" />}
        {memoCriteriaTable}
        {criteriaState.forceRefresh && <></>}
    </TableWrapper>
    </>;
}

export default memo(CriteriaTablePanel);
