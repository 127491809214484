import { CompanyRepoImpl } from "domain/repository/Company/CompanyRepoImpl";
import { RecalProcessAndGenRepoImpl } from "domain/repository/Recalculation/RecalProcessAndGenRepoImpl";
import { useRecalProcessAndGenMaintenanceTracked } from "presentation/store/Recalculation/RecalProcessAndGenMaintenanceProvider";
import { RecalProcessAndGenVM } from "presentation/viewModel/Recalculation/RecalProcessAndGenVM";
import { useMemo } from "react";

export const useRecalProcessAndGenVM = () => {
    const [, setRecalProcessAndGenState] = useRecalProcessAndGenMaintenanceTracked();
    const recalProcessAndGenVM = useMemo(() =>
        RecalProcessAndGenVM({
            dispatch: [setRecalProcessAndGenState],
            recalProcessAndGenRepo: RecalProcessAndGenRepoImpl(),
            companyRepo: CompanyRepoImpl(),            
        }), [setRecalProcessAndGenState])

    return recalProcessAndGenVM
}

