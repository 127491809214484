import _ from "lodash";
import { ChargeConfirmedConstant } from "presentation/constant/ChargeConfirmed/ChargeConfirmedConstant";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useChargeConfirmedVM } from "presentation/hook/ChargeConfirmed/useChargeConfirmedVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useChargeConfirmedTracked } from "presentation/store/ChargeConfirmed/ChargeConfirmedProvider";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import { memo, useState } from "react";
import { HPHButton, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const ChargeConfirmedTitleBar: React.FC = () => {
    const [chargeConfirmedState] = useChargeConfirmedTracked();
    const chargeConfirmedVM = useChargeConfirmedVM();
    const messageBarVM = useMessageBarVM();
    const [isLoading, setIsLoading] = useState(false);
    const { isShowAll, selectedRows, isShowRightPanel } = chargeConfirmedState;
    const [anainfoState] = useANAInfoTracked();
    const { allowUpdate } = anainfoState;

    // const handleShowAll = () => {
    //     setIsLoading(true);
    //     chargeConfirmedVM.onToggle();
    //     chargeConfirmedVM.onSearch().then(()=>{
    //         setIsLoading(false);
    //     }).catch(()=>{
    //         setIsLoading(false);
    //     });
    // }

    // const handleHideOthers = () => {
    //     setIsLoading(true);
    //     chargeConfirmedVM.onToggle();
    //     chargeConfirmedVM.onSearch(anaInfoState.userName).then(()=>{
    //         setIsLoading(false);
    //     }).catch(()=>{
    //         setIsLoading(false);
    //     });
    // }

    const handleToggleData = () => {
        chargeConfirmedVM.onToggle();
    }

    const handleRefresh = () => {
        setIsLoading(true);
        chargeConfirmedVM.onSearch().then(() => {
            setIsLoading(false);
        }).catch(() => {
            setIsLoading(false);
        });
    }

    const handleGenDraftInvoice = async () => {
        if (_.isEmpty(selectedRows)) {
            messageBarVM.showError('Please select a record.');
            return;
        }
        setIsLoading(true);
        try {
            const res = await chargeConfirmedVM.onSumbit(selectedRows);
            setIsLoading(false);

            if (!res.success) {
                messageBarVM.showError(res.data);
            } else {
                messageBarVM.showSuccess('Draft invoice generated successfully.');
            }

        } catch (error) {
            setIsLoading(false);
            messageBarVM.showError('Failed to generate draft invoice.');
        }

        handleRefresh();
    }

    const isDisable = () => {
        if (selectedRows != null && selectedRows.length > 0 && !isShowRightPanel) {
            return false;
        }
        return true;
    }


    return <Sidebarheader style={{ width: '100%', display: "flex", alignItems: "center" }}>
        <HeaderTitle>{ChargeConfirmedConstant.Header.CHG_CONF_LIST}</HeaderTitle>
        <StyledAction className="tm-animated-wrapper">
            <IconButton fileName='Icon-reload' disabled={false} size='medium' toolTipText={'Refresh'} onClick={handleRefresh} />
            <div className="add-seperator" />
            <HPHButton label={isShowAll ? WorkspaceConstant.Common.BUTTON_HIDEOTHERS : WorkspaceConstant.Common.BUTTON_SHOWALL} size={'Small'} theme={'Secondary'} onClick={handleToggleData} />
            {(allowUpdate) && <><div className="add-seperator" />
                <HPHButton label={WorkspaceConstant.Common.BUTTON_GENDRAFTINVOICE} disabled={isDisable()} size={'Small'} theme={'Primary'} onClick={handleGenDraftInvoice} /></>}
        </StyledAction>
        {isLoading && <Loader Indicator="Spinner" size="Medium" />}
        {chargeConfirmedState.forceRefresh && <></>}
    </Sidebarheader>
}

export default memo(ChargeConfirmedTitleBar);