import type { SystemPreferenceEntity } from "domain/entity/SystemPreference/SystemPreferenceEntity";
import { useSystemPreferenceVM } from "presentation/hook/SystemPreference/useSystemPreferenceVM";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useSystemPreferenceTracked } from "presentation/store/SystemPreference/SystemPreferenceProvider";
import { OverflowMenuButton } from "presentation/view/components/OverflowMenuButton";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import type { OverflowingSubMenu } from "veronica-ui-component/dist/component/core";

export interface IPreferenceMenuProps {
    entryPoint: string;
    tableId: string;
    gridRef: any;
    showSaveModal?: () => void;
    showDeleteModal?: () => void;
    columns: any[];
}

const PreferenceMenu: React.FC<IPreferenceMenuProps> = ({ entryPoint, tableId, gridRef, showSaveModal, showDeleteModal, columns }: IPreferenceMenuProps) => {
    const systemPreferenceVM = useSystemPreferenceVM();
    const [anaInfoState] = useANAInfoTracked();
    const { userName } = anaInfoState;
    const [systemPreferenceState] = useSystemPreferenceTracked();
    const [preferenceMenu, setPreferenceMenu] = useState<OverflowingSubMenu[]>([]);
    const [initialPreferenceState, setInitialPreferenceState] = useState<boolean>(false);

    useEffect(() => {
        if (initialPreferenceState || !entryPoint) return;
        systemPreferenceVM.getTableSystemPreferences(entryPoint, tableId, userName).then(data => {
            setInitialPreferenceState(true);
        }).catch((e) => {
        })
    }, [entryPoint, userName, systemPreferenceVM, tableId, initialPreferenceState]);

    const onColumnSettingClick = useCallback(() => {
        let isSidebarOpen = gridRef.current?.api?.isSideBarVisible();

        if (isSidebarOpen) {
            gridRef.current?.api?.setSideBarVisible(false);
            gridRef.current?.api?.closeToolPanel();
        }
    }, [gridRef]);

    const activeSystemPreference = useCallback(async (preference: SystemPreferenceEntity) => {
        if (preference && gridRef && gridRef.current &&
            JSON.stringify(preference) !== JSON.stringify(systemPreferenceState.activeSystemPreference)
        ) {
            onColumnSettingClick();
            const inactiveSystemPreference: SystemPreferenceEntity | undefined = systemPreferenceState.activeSystemPreference ? {
                ...systemPreferenceState.activeSystemPreference,
                updatedUser: userName,
                effectiveInd: 'N'
            } : undefined;

            const activeSystemPreference: SystemPreferenceEntity = {
                ...preference,
                updatedUser: userName,
                effectiveInd: 'Y'
            };

            await Promise.allSettled([
                inactiveSystemPreference && systemPreferenceVM.onCreateSystemPreference(inactiveSystemPreference, false),
                activeSystemPreference && systemPreferenceVM.onCreateSystemPreference(activeSystemPreference, false),
            ]).finally(() => {
                systemPreferenceVM.getTableSystemPreferences(entryPoint, tableId, anaInfoState.userName);
            });
        }
    }, [anaInfoState.userName, entryPoint, gridRef, onColumnSettingClick, systemPreferenceState.activeSystemPreference, systemPreferenceVM, tableId, userName]);

    const handleSaveSystemPreferenceClick = useCallback(() => {
        // systemPreferenceVM.onCreateSystemPreferenceClick();
        if (showSaveModal) {
            onColumnSettingClick();
            showSaveModal();
        }
    }, [onColumnSettingClick, showSaveModal]);

    const handleResetSystemPreferenceClick = useCallback(() => {
        if (systemPreferenceState.activeSystemPreference) {
            onColumnSettingClick();
            const inactivePreference: SystemPreferenceEntity = {
                ...systemPreferenceState.activeSystemPreference,
                updatedUser: userName,
                effectiveInd: 'N'

            }
            systemPreferenceVM.onCreateSystemPreference(inactivePreference, false).finally(() => {
                gridRef?.current?.api?.resetColumnState();
                systemPreferenceVM.getTableSystemPreferences(entryPoint, tableId, anaInfoState.userName);
            });
        }
    }, [anaInfoState.userName, entryPoint, gridRef, onColumnSettingClick, systemPreferenceState.activeSystemPreference, systemPreferenceVM, tableId, userName]);

    const overrideSystemPreference = useCallback(async (preference: SystemPreferenceEntity) => {
        if (JSON.stringify(preference.contentForScrene.columnState) ===
            JSON.stringify(gridRef?.current?.api.getColumnState())) return;
        onColumnSettingClick();

        const newPreference: SystemPreferenceEntity = {
            ...preference,
            content: JSON.stringify({
                columnState: gridRef?.current?.api.getColumnState(),
            }),
            contentForScrene: {
                columnState: gridRef?.current?.api.getColumnState(),
            },
            updatedUser: userName,
        }

        await Promise.allSettled([
            systemPreferenceVM.onCreateSystemPreference(newPreference, false),
        ]).finally(() => {
            systemPreferenceVM.getTableSystemPreferences(entryPoint, tableId, anaInfoState.userName);
        });

    }, [anaInfoState.userName, entryPoint, gridRef, onColumnSettingClick, systemPreferenceVM, tableId, userName]);

    const handleDeleteSystemPreferenceClick = useCallback(() => {
        // systemPreferenceVM.onDeleteSystemPreferenceClick();
        if (showDeleteModal) {
            onColumnSettingClick();
            showDeleteModal();
        }
    }, [onColumnSettingClick, showDeleteModal]);

    useEffect(() => {
        if (initialPreferenceState) {
            if (systemPreferenceState.activeSystemPreference) {
                gridRef?.current?.api?.applyColumnState({
                    state: systemPreferenceState.activeSystemPreference?.contentForScrene.columnState,
                    applyOrder: true,
                });
            } else if (columns) {
                gridRef?.current?.api?.setGridOption("columnDefs", columns);
                gridRef?.current?.api?.redrawRows();
            }
        }
    }, [gridRef, initialPreferenceState, systemPreferenceState.activeSystemPreference, columns]);


    useEffect(() => {
        if (!initialPreferenceState) return;

        const menuItemArray: OverflowingSubMenu[] = []
        if (systemPreferenceState.allGlobalSystemPreferences?.length) {
            menuItemArray.push({
                title: 'Global Preference',
                submenu: systemPreferenceState.allGlobalSystemPreferences?.map(preference => ({
                    title: preference.name,
                    icon: systemPreferenceState.activeSystemPreference?.name === preference.name ? 'Icon-masterbookmark-fill' : '',
                    command: () => activeSystemPreference(preference),
                }))
            })
        }

        if (systemPreferenceState.allUserSystemPreferences?.length) {
            menuItemArray.push({
                title: 'My Preference',
                submenu: systemPreferenceState.allUserSystemPreferences?.map(preference => ({
                    title: preference.name,
                    icon: systemPreferenceState.activeSystemPreference?.name === preference.name ? 'Icon-masterbookmark-fill' : '',
                    command: () => activeSystemPreference(preference),
                }))
            })
        }

        if (systemPreferenceState.activeSystemPreference?.userId === userName) {
            menuItemArray.push({
                title: 'Save',
                command: () => systemPreferenceState.activeSystemPreference && overrideSystemPreference(systemPreferenceState.activeSystemPreference),
            })
        }

        menuItemArray.push({
            title: 'Save As ...',
            command: handleSaveSystemPreferenceClick,
        });

        const globalPreferences = systemPreferenceState.allGlobalSystemPreferences?.filter((preference) => preference.userId === userName);
        const userPreferences = systemPreferenceState.allUserSystemPreferences?.filter((preference) => preference.userId === userName);
        if (globalPreferences?.length || userPreferences?.length) {
            menuItemArray.push({
                title: 'Delete',
                command: handleDeleteSystemPreferenceClick,
            })
        }
        if (systemPreferenceState.activeSystemPreference) {
            menuItemArray.push({
                title: 'Reset',
                command: handleResetSystemPreferenceClick,
            });
        }

        setPreferenceMenu(menuItemArray);
    }, [activeSystemPreference, handleDeleteSystemPreferenceClick, handleResetSystemPreferenceClick, handleSaveSystemPreferenceClick,
        initialPreferenceState, overrideSystemPreference, systemPreferenceState.activeSystemPreference,
        systemPreferenceState.allGlobalSystemPreferences,
        systemPreferenceState.allSystemPreferences, systemPreferenceState.allUserSystemPreferences, userName]);

    const memoOverflowmenuButton = useMemo(() => {
        if (!initialPreferenceState) return;

        return <OverflowMenuButton icon="Icon-masterbookmark" navigationList={preferenceMenu ?? []} />
    }, [initialPreferenceState, preferenceMenu]);

    return <>
        {memoOverflowmenuButton}

    </>
}

export default memo(PreferenceMenu);