import { INITIAL_MONTH_END_ACTIVITY_LOG_COL_DEF, transferRowData } from "presentation/constant/MonthEndClosure/MonthEndActivityLogColumnDefinition";
import { useMonthEndClosureTracked } from "presentation/store/MonthEndClosure/MonthEndClosureProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { useMemo } from "react";

export const MonthEndActivityLogTablePanel = () => {

    const [monthEndClosureState] = useMonthEndClosureTracked();
    const { activityLogList } = monthEndClosureState;

    const memoMonthEndActivityLogTable = useMemo(() => {

        return (
            <>
                <NbisTable
                    id='month-end-activity-log-table'
                    isNewColumnSetting={true}
                    columns={INITIAL_MONTH_END_ACTIVITY_LOG_COL_DEF}
                    data={transferRowData(activityLogList ?? [])}
                    showPaginator={false}
                    editable={false}
                    showAddIcon={false}
                    showDeleteButton={false}
                    showReloadIcon={false}
                    isScrollHighlighted={true}
                    selectionMode={false}
                    isRowHighligted={true}
                    gridHeight="customHeight"
                    customHeight="calc(100vh - 380px)"
                />
            </>
        );
    }, [activityLogList])


    return <><TableWrapper>
        {memoMonthEndActivityLogTable}
    </TableWrapper>
    </>;
}