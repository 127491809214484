import { surChargeTypeDropdownOption, uomDropdownOption } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { GeneralProposalConstant, generalProposalIsRequiredFieldList, generalProposalTierRequiredFieldList } from "presentation/constant/GeneralProposal/GeneralProposalConstant";
import { useGeneralProposalDetailVM } from "presentation/hook/GeneralProposal/useGeneralProposalDetailVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useGeneralProposalDetailTracked } from "presentation/store/GeneralProposal/GeneralProposalDetailProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import NumberInputComponent from "presentation/view/components/NumberInputComponent";
import { useCallback, useMemo } from "react";
import { FieldType, IconButton, IFieldValue } from "veronica-ui-component/dist/component/core";
import { SidebarActionCross, Sidebarheader, SidebarTitle } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";


export const AddTariffItemIsEditPanel = () => {
    const [generalProposalDetailState] = useGeneralProposalDetailTracked();
    const generalProposalDetailVM = useGeneralProposalDetailVM();
    const messageBarVM = useMessageBarVM();
    const GENERAL_PROPOSAL_CONSTANT = GeneralProposalConstant.Table;
    const { generalProposalItemIsList, currentGeneralProposal, currentEditTariffItemTier, currentEditTariffItemIs, isAddIs, isAddTier, isEditIs, isEditTier } = generalProposalDetailState;

    const handleSave = useCallback(() => {
        if (currentEditTariffItemIs) {
            if (isAddIs || isEditIs) {
                if (isAddIs && currentGeneralProposal.tarItemTierList && currentGeneralProposal.tarItemTierList.length > 1) {
                    messageBarVM.showError("Intensive surcharge does not support multiple charge tier.");
                    return;
                }
                if (!currentEditTariffItemIs.shiftCode) {
                    messageBarVM.showError("Shift Code is mandatory.");
                    return;
                }
                if (currentEditTariffItemIs.percentage === null) {
                    messageBarVM.showError("Surcharge % is mandatory.");
                    return;
                }
                if (generalProposalItemIsList && generalProposalItemIsList.length > 0 && generalProposalItemIsList.find(entity => entity.shiftCode === currentEditTariffItemIs.shiftCode && (entity.id !== currentEditTariffItemIs.id || entity.tempKey !== currentEditTariffItemIs.tempKey))) {
                    messageBarVM.showError("Duplicate record found, please check the key: Shift Code.");
                    return;
                }
            }
        }

        if (currentEditTariffItemTier) {
            if (isAddTier || isEditTier) {
                if (isAddTier && currentGeneralProposal.tarItemIsList && currentGeneralProposal.tarItemIsList.length > 0 && currentGeneralProposal.tarItemTierList && currentGeneralProposal.tarItemTierList.length === 1) {
                    messageBarVM.showError("Intensive surcharge does not support multiple charge tier.");
                    return;
                }
                if (currentEditTariffItemTier.minimum === null) {
                    messageBarVM.showError("MINIMUM RATE is mandatory.");
                    return;
                }
                if (currentEditTariffItemTier.rate === null) {
                    messageBarVM.showError("RATE is mandatory.");
                    return;
                }
                if (!currentEditTariffItemTier.uom) {
                    messageBarVM.showError("UOM is mandatory.");
                    return;
                }
                if (!currentEditTariffItemTier.forevery) {
                    messageBarVM.showError("FOR EVERY is mandatory.");
                    return;
                }
                if (!currentEditTariffItemTier.cycle) {
                    messageBarVM.showError("CYCLE is mandatory.");
                    return;
                }
                if (Number(currentEditTariffItemTier.minimum) > Number(currentEditTariffItemTier.rate)) {
                    messageBarVM.showError("The Minimum should not be larger than Rate.");
                    return;
                }
            }
        }


        generalProposalDetailVM.onTempSaveClick((isAddIs || isEditIs) ? "generalProposalItemIsList" : "generalProposalItemTierList",
            (isAddIs || isEditIs) ? generalProposalDetailState.currentEditTariffItemIs : generalProposalDetailState.currentEditTariffItemTier)

    }, [currentEditTariffItemIs, currentEditTariffItemTier, currentGeneralProposal.tarItemIsList, currentGeneralProposal.tarItemTierList, generalProposalDetailState.currentEditTariffItemIs, generalProposalDetailState.currentEditTariffItemTier, generalProposalDetailVM, generalProposalItemIsList, isAddIs, isAddTier, isEditIs, isEditTier, messageBarVM]);

    const handleCancel = useCallback(() => {
        generalProposalDetailVM.cancelAddOrEdit();
    }, [generalProposalDetailVM]);

    const onNumberFieldChange = useCallback((e: any, fieldName: string, fieldValue: any) => {
        generalProposalDetailVM.onTierFieldChange(fieldName, fieldValue);
    }, [generalProposalDetailVM]);

    const memoCycle = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "200px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={currentEditTariffItemTier?.cycle?.toString() || ''}
                fieldValue={currentEditTariffItemTier?.cycle?.toString()}
                fieldLabel={GENERAL_PROPOSAL_CONSTANT.CYCLE}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'cycle'}
                maxLength={60}
                requiredFieldList={generalProposalTierRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    generalProposalDetailVM.onTierFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [GENERAL_PROPOSAL_CONSTANT.CYCLE, currentEditTariffItemTier?.cycle, generalProposalDetailVM])

    const memoForEvery = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "200px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={currentEditTariffItemTier?.forevery?.toString() || ''}
                fieldValue={currentEditTariffItemTier?.forevery?.toString()}
                fieldLabel={GENERAL_PROPOSAL_CONSTANT.FOR_EVERY}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'forevery'}
                maxLength={60}
                requiredFieldList={generalProposalTierRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    generalProposalDetailVM.onTierFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [GENERAL_PROPOSAL_CONSTANT.FOR_EVERY, currentEditTariffItemTier?.forevery, generalProposalDetailVM])

    const memoUom = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "200px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={currentEditTariffItemTier?.uom || ''}
                fieldValue={currentEditTariffItemTier?.uom}
                fieldLabel={GENERAL_PROPOSAL_CONSTANT.UOM}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'uom'}
                maxLength={60}
                options={uomDropdownOption}
                requiredFieldList={generalProposalTierRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    generalProposalDetailVM.onTierFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [GENERAL_PROPOSAL_CONSTANT.UOM, currentEditTariffItemTier?.uom, generalProposalDetailVM])

    const memoRate = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "200px", marginBottom: "24px" }}>
            <NumberInputComponent
                label={GENERAL_PROPOSAL_CONSTANT.RATE}
                maxLength={60}
                fieldName={"rate"}
                value={currentEditTariffItemTier?.rate ?? ''}
                errorMessage={''}
                disabled={false}
                decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
        </div>
        , [GENERAL_PROPOSAL_CONSTANT.RATE, currentEditTariffItemTier?.rate, onNumberFieldChange])

    const memoMinimun = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "200px", marginBottom: "24px" }}>
            <NumberInputComponent
                label={GENERAL_PROPOSAL_CONSTANT.MINIMUM}
                maxLength={60}
                fieldName={"minimum"}
                value={currentEditTariffItemTier?.minimum ?? ''}
                errorMessage={''}
                disabled={false}
                decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
        </div>
        , [GENERAL_PROPOSAL_CONSTANT.MINIMUM, currentEditTariffItemTier?.minimum, onNumberFieldChange])

    const memosurCharge = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "200px", marginBottom: "24px" }}>
            <NumberInputComponent
                label={GENERAL_PROPOSAL_CONSTANT.SURCHARGE}
                maxLength={60}
                fieldName={"surcharge"}
                value={currentEditTariffItemTier?.surcharge ?? ''}
                errorMessage={''}
                disabled={false}
                decimalPlaces={2}
                optional={true}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
        </div>
        , [GENERAL_PROPOSAL_CONSTANT.SURCHARGE, currentEditTariffItemTier?.surcharge, onNumberFieldChange])

    const memoSurChargeType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "200px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={currentEditTariffItemTier?.surchargeType || ''}
                fieldValue={currentEditTariffItemTier?.surchargeType}
                fieldLabel={GENERAL_PROPOSAL_CONSTANT.SURCHARGE_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'surchargeType'}
                maxLength={60}
                options={surChargeTypeDropdownOption}
                requiredFieldList={generalProposalTierRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    generalProposalDetailVM.onTierFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [GENERAL_PROPOSAL_CONSTANT.SURCHARGE_TYPE, currentEditTariffItemTier?.surchargeType, generalProposalDetailVM])

    const memoAdditionalRate = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "200px", marginBottom: "24px" }}>
            <NumberInputComponent
                label={GENERAL_PROPOSAL_CONSTANT.ADDITIONAL_RATE}
                maxLength={60}
                fieldName={"additionalRate"}
                value={currentEditTariffItemTier?.additionalRate ?? ''}
                errorMessage={''}
                disabled={false}
                decimalPlaces={2}
                optional={true}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
        </div>
        , [GENERAL_PROPOSAL_CONSTANT.ADDITIONAL_RATE, currentEditTariffItemTier?.additionalRate, onNumberFieldChange])

    const memoAdditionalUom = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "200px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={currentEditTariffItemTier?.additionalUom || ''}
                fieldValue={currentEditTariffItemTier?.additionalUom}
                fieldLabel={GENERAL_PROPOSAL_CONSTANT.ADDITIONAL_UOM}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'additionalUom'}
                maxLength={60}
                options={uomDropdownOption}
                requiredFieldList={generalProposalTierRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    generalProposalDetailVM.onTierFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [GENERAL_PROPOSAL_CONSTANT.ADDITIONAL_UOM, currentEditTariffItemTier?.additionalUom, generalProposalDetailVM])

    const memoShiftCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "200px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={false}
                readOnlyValue={currentEditTariffItemIs?.shiftCode || ''}
                fieldValue={currentEditTariffItemIs?.shiftCode}
                fieldLabel={GENERAL_PROPOSAL_CONSTANT.SHIFT_CODE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'shiftCode'}
                maxLength={60}
                options={generalProposalDetailState.dynamicOptions.shiftCodeDropdownOptions}
                requiredFieldList={generalProposalIsRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    generalProposalDetailVM.onIsFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [GENERAL_PROPOSAL_CONSTANT.SHIFT_CODE, currentEditTariffItemIs?.shiftCode, generalProposalDetailState.dynamicOptions.shiftCodeDropdownOptions, generalProposalDetailVM])

    const memoPercentage = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "200px", marginBottom: "24px" }}>
            <NumberInputComponent
                label={GENERAL_PROPOSAL_CONSTANT.SURCHARGE_P}
                maxLength={60}
                fieldName={"percentage"}
                value={currentEditTariffItemIs?.percentage ?? ''}
                errorMessage={''}
                disabled={false}
                decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) => generalProposalDetailVM.onIsFieldChange(fieldKey, fieldValue && fieldValue.toString())}
            />
        </div>
        , [GENERAL_PROPOSAL_CONSTANT.SURCHARGE_P, currentEditTariffItemIs?.percentage, generalProposalDetailVM])



    return <div className="side-form-content-wrapper" style={{ overflow: "visible" }}>

        <div className={'flex-row'}>
            <div className={'flex-row-item flex-row-item-full-width'}>
                <Sidebarheader>
                    <SidebarTitle>
                        {isAddTier && "Add " + GENERAL_PROPOSAL_CONSTANT.TIER_VALUE}
                        {isEditTier && "Update " + GENERAL_PROPOSAL_CONSTANT.TIER_VALUE}
                        {isAddIs && "Add " + GENERAL_PROPOSAL_CONSTANT.IS_VALUE}
                        {isEditIs && "Update " + GENERAL_PROPOSAL_CONSTANT.IS_VALUE}
                    </SidebarTitle>
                    <SidebarActionCross>
                        <IconButton
                            fileName="Icon-cross" size="medium" toolTipText='Close' toolTipArrow={false} onClick={handleCancel} />
                        <IconButton
                            fileName="Icon-tick" size="medium" toolTipText='Save' toolTipArrow={false} onClick={handleSave} />
                    </SidebarActionCross>
                </Sidebarheader>
            </div>
        </div>

        <div className={'add-edit-form'} style={{ height: '27vh', maxHeight: '27vh', overflow: 'visible', zIndex: '100' }}>
            <div className="flex-row-auto">
                <div className={'flex-row-item'}>
                    {(isAddTier || isEditTier) &&
                        <><CriteriaItemContainer>
                            {memoCycle}
                            {memoForEvery}                            
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoUom}
                            {memoRate}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoMinimun}
                            {memosurCharge}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoSurChargeType}
                            {memoAdditionalRate}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoAdditionalUom}                            
                        </CriteriaItemContainer>
                        </>}
                    {(isAddIs || isEditIs) &&
                        <>
                            <CriteriaItemContainer>
                                {memoShiftCode}
                                {memoPercentage}
                            </CriteriaItemContainer>
                        </>}
                </div>
            </div>
        </div>

        {/*
            <StyledAction className="tm-animated-wrapper">
                {<HPHButton disabled={false} label={WorkspaceConstant.Common.BUTTON_SAVE} size={'Small'} theme={'Primary'} onClick={handleSave} />}
            </StyledAction>
        */}
    </div>;
}

