import { RequisitionFormConstant } from "presentation/constant/RequisitionForm/RequisitionFormConstant";
import { useRequisitionFormVM } from "presentation/hook/RequisitionForm/useRequisitionFormVM";
import { useRequisitionFormMaintenanceTracked } from "presentation/store/RequisitionForm/RequisitionFormMaintenanceProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { useCallback, useMemo, useState } from "react";
import { FieldType, IFieldValue, Loader } from "veronica-ui-component/dist/component/core";
import DateTimePickerComponent from "presentation/view/components/DateTimePickerComponent";
import { SidebarCaption, SidebarTitle } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

export const RequisitionFormMgrFormPanel = () => {
    const [requisitionFormState] = useRequisitionFormMaintenanceTracked();
    const requisitionFormVM = useRequisitionFormVM();
    //const messageBarVM = useMessageBarVM();

    const REQ_FORM_CONSTANT = RequisitionFormConstant;
    const [isLoading] = useState(false);

    const { currentRequisitionForm, masterState, dynamicOptions } = requisitionFormState;
    const { editingEntity, isAdd, isEditable, isRead, isSaveClicked, allFormState } = masterState;
    //const SCREEN_CONSTANT = DocumentIssuanceConstant;

    const currentEntity = isRead ? currentRequisitionForm : editingEntity;

    const isInv = useCallback(() => {
        if(currentEntity.state === "INV") return true;
        return false;
    }, [currentEntity.state]);

    const memoManagerInCharge = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "200px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isInv()}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={currentEntity.mgrs?.find(mgr => mgr.mgrPosition === "MGR_IC")?.mgrName ?? ""}
                fieldValue={currentEntity.mgrs?.find(mgr => mgr.mgrPosition === "MGR_IC")?.mgrName ?? ""}
                fieldLabel={REQ_FORM_CONSTANT.Detail.MANAGER_IN_CHARGE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'mgrIcName'}
                isShowMissingError={true}
                maxLength={10}
                options={dynamicOptions.managerInChgDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                        requisitionFormVM.onReqFormMgrFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, isInv, currentEntity.mgrs, REQ_FORM_CONSTANT.Detail.MANAGER_IN_CHARGE, isSaveClicked, dynamicOptions.managerInChgDropdownOptions, requisitionFormVM])

    const memoMgrIcConfirmTime = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "220px", marginBottom: "24px" }}>
            <DateTimePickerComponent
                disabled={isRead || isInv()}
                label={REQ_FORM_CONSTANT.Detail.CONFIRM_DATE_TIME}
                width="220px"
                date={currentEntity.mgrs?.find(mgr => mgr.mgrPosition === "MGR_IC")?.confirmDatetime ?? ""}
                fieldName="mgrIcConfirmDatetime"
                optional={true}
                errorMessage={''}
                onDateChange={requisitionFormVM.onReqFormMgrDateTimeChange} />
        </div>
        , [REQ_FORM_CONSTANT.Detail.CONFIRM_DATE_TIME, currentEntity.mgrs, isInv, isRead, requisitionFormVM.onReqFormMgrDateTimeChange])

    const memoAccountManager = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "200px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isInv()}
                isShowOptional={true}
                isMultipleDropDown={false}
                readOnlyValue={currentEntity.mgrs?.find(mgr => mgr.mgrPosition === "MGR_AC")?.mgrName ?? ""}
                fieldValue={currentEntity.mgrs?.find(mgr => mgr.mgrPosition === "MGR_AC")?.mgrName ?? ""}
                fieldLabel={REQ_FORM_CONSTANT.Detail.ACCOUNT_MANAGER}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'mgrAcName'}
                isShowMissingError={true}
                maxLength={10}
                options={dynamicOptions.accountManagerDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    requisitionFormVM.onReqFormMgrFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, isInv, currentEntity.mgrs, REQ_FORM_CONSTANT.Detail.ACCOUNT_MANAGER, isSaveClicked, dynamicOptions.accountManagerDropdownOptions, requisitionFormVM])

    const memoMgrAcConfirmTime = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "220px", marginBottom: "24px" }}>
            <DateTimePickerComponent
                disabled={isRead || isInv()}
                label={REQ_FORM_CONSTANT.Detail.CONFIRM_DATE_TIME}
                width="220px"
                date={currentEntity.mgrs?.find(mgr => mgr.mgrPosition === "MGR_AC")?.confirmDatetime ?? ""}
                fieldName="mgrAcConfirmDatetime"
                optional={true}
                errorMessage={''}
                onDateChange={requisitionFormVM.onReqFormMgrDateTimeChange} />
        </div>
        , [REQ_FORM_CONSTANT.Detail.CONFIRM_DATE_TIME, currentEntity.mgrs, isInv, isRead, requisitionFormVM.onReqFormMgrDateTimeChange])

    const memoFIN = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "200px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isInv()}
                isShowOptional={true}
                isMultipleDropDown={false}
                readOnlyValue={currentEntity.mgrs?.find(mgr => mgr.mgrPosition === "FIN")?.mgrName ?? ""}
                fieldValue={currentEntity.mgrs?.find(mgr => mgr.mgrPosition === "FIN")?.mgrName ?? ""}
                fieldLabel={REQ_FORM_CONSTANT.COMMON.FIN}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'finName'}
                isShowMissingError={true}
                maxLength={10}
                options={dynamicOptions.finDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    requisitionFormVM.onReqFormMgrFieldChange(fieldKey, fieldValue && fieldValue as string[], fFullValue)} />
        </div>
        , [allFormState, isRead, isInv, currentEntity.mgrs, REQ_FORM_CONSTANT.COMMON.FIN, isSaveClicked, dynamicOptions.finDropdownOptions, requisitionFormVM])

    const memoFinConfirmTime = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "220px", marginBottom: "24px" }}>
            <DateTimePickerComponent
                disabled={isRead || isInv()}
                label={REQ_FORM_CONSTANT.Detail.CONFIRM_DATE_TIME}
                width="220px"
                date={currentEntity.mgrs?.find(mgr => mgr.mgrPosition === "FIN")?.confirmDatetime ?? ""}
                fieldName="finConfirmDatetime"
                optional={true}
                errorMessage={''}
                onDateChange={requisitionFormVM.onReqFormMgrDateTimeChange} />
        </div>
        , [REQ_FORM_CONSTANT.Detail.CONFIRM_DATE_TIME, currentEntity.mgrs, isInv, isRead, requisitionFormVM.onReqFormMgrDateTimeChange])

    const memoCOM = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "200px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isInv()}
                isShowOptional={true}
                isMultipleDropDown={false}
                readOnlyValue={currentEntity.mgrs?.find(mgr => mgr.mgrPosition === "COM")?.mgrName ?? ""}
                fieldValue={currentEntity.mgrs?.find(mgr => mgr.mgrPosition === "COM")?.mgrName ?? ""}
                fieldLabel={REQ_FORM_CONSTANT.COMMON.COM}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'comName'}
                isShowMissingError={true}
                maxLength={10}
                options={dynamicOptions.comDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    requisitionFormVM.onReqFormMgrFieldChange(fieldKey, fieldValue && fieldValue as string[], fFullValue)} />
        </div>
        , [REQ_FORM_CONSTANT.COMMON.COM, allFormState, currentEntity.mgrs, dynamicOptions.comDropdownOptions, isInv, isRead, isSaveClicked, requisitionFormVM])

    const memoComConfirmTime = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "220px", marginBottom: "24px" }}>
            <DateTimePickerComponent
                disabled={isRead || isInv()}
                label={REQ_FORM_CONSTANT.Detail.CONFIRM_DATE_TIME}
                width="220px"
                date={currentEntity.mgrs?.find(mgr => mgr.mgrPosition === "COM")?.confirmDatetime ?? ""}
                fieldName="comConfirmDatetime"
                optional={true}
                errorMessage={''}
                onDateChange={requisitionFormVM.onReqFormMgrDateTimeChange} />
        </div>
        , [REQ_FORM_CONSTANT.Detail.CONFIRM_DATE_TIME, currentEntity.mgrs, isInv, isRead, requisitionFormVM.onReqFormMgrDateTimeChange])

    const memoMD = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "200px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isInv()}
                isShowOptional={true}
                isMultipleDropDown={false}
                readOnlyValue={currentEntity.mgrs?.find(mgr => mgr.mgrPosition === "MD")?.mgrName ?? ""}
                fieldValue={currentEntity.mgrs?.find(mgr => mgr.mgrPosition === "MD")?.mgrName ?? ""}
                fieldLabel={REQ_FORM_CONSTANT.COMMON.MD}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'mdName'}
                isShowMissingError={true}
                maxLength={10}
                options={dynamicOptions.mdDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    requisitionFormVM.onReqFormMgrFieldChange(fieldKey, fieldValue && fieldValue as string[], fFullValue)} />
        </div>
        , [allFormState, isRead, isInv, currentEntity.mgrs, REQ_FORM_CONSTANT.COMMON.MD, isSaveClicked, dynamicOptions.mdDropdownOptions, requisitionFormVM])

    const memoMdConfirmTime = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "220px", marginBottom: "24px" }}>
            <DateTimePickerComponent
                disabled={isRead || isInv()}
                label={REQ_FORM_CONSTANT.Detail.CONFIRM_DATE_TIME}
                width="220px"
                date={currentEntity.mgrs?.find(mgr => mgr.mgrPosition === "MD")?.confirmDatetime ?? ""}
                fieldName="mdConfirmDatetime"
                optional={true}
                errorMessage={''}
                onDateChange={requisitionFormVM.onReqFormMgrDateTimeChange} />
        </div>
        , [REQ_FORM_CONSTANT.Detail.CONFIRM_DATE_TIME, currentEntity.mgrs, isInv, isRead, requisitionFormVM.onReqFormMgrDateTimeChange])

    const memoGMD = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "200px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isInv()}
                isShowOptional={true}
                isMultipleDropDown={false}
                readOnlyValue={currentEntity.mgrs?.find(mgr => mgr.mgrPosition === "GMD")?.mgrName ?? ""}
                fieldValue={currentEntity.mgrs?.find(mgr => mgr.mgrPosition === "GMD")?.mgrName ?? ""}
                fieldLabel={REQ_FORM_CONSTANT.COMMON.GMD}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'gmdName'}
                isShowMissingError={true}
                maxLength={10}
                options={dynamicOptions.gmdDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    requisitionFormVM.onReqFormMgrFieldChange(fieldKey, fieldValue && fieldValue as string[], fFullValue)} />
        </div>
        , [allFormState, isRead, isInv, currentEntity.mgrs, REQ_FORM_CONSTANT.COMMON.GMD, isSaveClicked, dynamicOptions.gmdDropdownOptions, requisitionFormVM])

    const memoGmdConfirmTime = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "220px", marginBottom: "24px" }}>
            <DateTimePickerComponent
                disabled={isRead || isInv()}
                label={REQ_FORM_CONSTANT.Detail.CONFIRM_DATE_TIME}
                width="220px"
                date={currentEntity.mgrs?.find(mgr => mgr.mgrPosition === "GMD")?.confirmDatetime ?? ""}
                fieldName="gmdConfirmDatetime"
                optional={true}
                errorMessage={''}
                onDateChange={requisitionFormVM.onReqFormMgrDateTimeChange} />
        </div>
        , [REQ_FORM_CONSTANT.Detail.CONFIRM_DATE_TIME, currentEntity.mgrs, isInv, isRead, requisitionFormVM.onReqFormMgrDateTimeChange])


    return <>
        {isLoading && <Loader Indicator="Spinner" size="Medium" />}
        <div className={'add-edit-form'} style={{ height: 'calc(100vh - 600px)', maxHeight: 'calc(100vh - 600px)', overflow: 'auto', marginLeft:"10px" }}>
            <div className="flex-row-auto">
                <div className={'flex-row-item'}>
                    <div className={'im-flex-row'}>
                        <div className={'flex-row-item-stretch'}>
                            {<SidebarTitle>{REQ_FORM_CONSTANT.Detail.MANAGER_APPROVAL}</SidebarTitle>}

                            {(isAdd || (isEditable && !isRead)) && <SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>}
                        </div>
                    </div>

                    <CriteriaItemContainer>
                        {memoManagerInCharge}
                        {memoMgrIcConfirmTime}
                        {memoCOM}
                        {memoComConfirmTime}
                    </CriteriaItemContainer>
                    <CriteriaItemContainer>
                        {memoAccountManager}
                        {memoMgrAcConfirmTime}
                        {memoMD}
                        {memoMdConfirmTime}
                    </CriteriaItemContainer>
                    <CriteriaItemContainer>
                        {memoFIN}
                        {memoFinConfirmTime}
                        {memoGMD}
                        {memoGmdConfirmTime}
                    </CriteriaItemContainer>
                </div>
            </div>
        </div>

    </>
}