import { EMPTY_RECAL_PROCESS_AND_GEN_MAINTENANCE_MODEL } from "presentation/model/Recalculation/RecalProcessAndGenMaintenanceModel";
import { useState } from "react";
import { createContainer } from "react-tracked";

const {
    Provider: RecalProcessAndGenMaintenanceProvider,
    useTracked: useRecalProcessAndGenMaintenanceTracked
} = createContainer(() => useState(EMPTY_RECAL_PROCESS_AND_GEN_MAINTENANCE_MODEL), {concurrentMode: true});
export { RecalProcessAndGenMaintenanceProvider, useRecalProcessAndGenMaintenanceTracked };

