import { ChargeDetailConstant } from "presentation/constant/ChargeDetailMaintenance/ChargeDetailConstant";
import { adjTypeDropdownOption } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { MessageConstant } from "presentation/constant/MessageConstant";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useManualChargeDetailVM } from "presentation/hook/ManualCharge/useManualChargeDetailVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useManualChargeDetailTracked } from "presentation/store/ManualCharge/ManualChargeDetailProvider";
import NbisInputDropDown from "presentation/view/components/NbisInputDropDown";
import { useState } from "react";
import { HPHButton, Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, SidebarTitle } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

export const ManualChargeAdjustmentPanel = () => {
    const [manualChargeDetailState] = useManualChargeDetailTracked();
    const manualChargeDetailVM = useManualChargeDetailVM();
    const { adjustmentEntity, allFormState, selectedManualChargeDetailRows, currentManualChargeHeader } = manualChargeDetailState;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const messageBarVM = useMessageBarVM();

    const handleCancelClick = () => {
        manualChargeDetailVM.handleAdjustmentButtonClick();
    }

    const handleApply = async () => {
        setIsLoading(true);

        try {
            await manualChargeDetailVM.onValidationBeforeAdjustment(adjustmentEntity).then(data => {
                if (data) {
                    manualChargeDetailVM.onAdjustment(selectedManualChargeDetailRows, adjustmentEntity).then(data => {
                        Promise.allSettled([
                            manualChargeDetailVM.onPageInit(currentManualChargeHeader),
                            currentManualChargeHeader.id && manualChargeDetailVM.onSearch(currentManualChargeHeader.id),
                        ]).finally(() => {
                            if (data && data["adjustmentManualChargeFail"]) {
                                messageBarVM.showError(data["adjustmentManualChargeFail"]?.toString());
                            } else {
                                messageBarVM.showSuccess(MessageConstant.common.OPERATION_DONE_SUCCESSFUL.replace(
                                    "{operationDone}", ChargeDetailConstant.Charge.AJDUSTMENT));
                            }

                            setIsLoading(false);
                            manualChargeDetailVM.handleAdjustmentButtonClick();
                        });
                    }).catch((error) => {
                        setIsLoading(false);
                        manualChargeDetailVM.handleAdjustmentButtonClick();
                    });
                } else {
                    setIsLoading(false);
                }
            })
        } catch (error) {
            setIsLoading(false);
            manualChargeDetailVM.handleAdjustmentButtonClick();
        }
    }

    return (
        <div className='side-form-content-wrapper'>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>{ChargeDetailConstant.Charge.AJDUSTMENT}</SidebarTitle>
                    </Sidebarheader>
                </div>
            </div>

            {isLoading && <Loader Indicator="Spinner" size="Medium" />}

            <div className={'add-edit-form'} style={{ height: '75vh', maxHeight: '75vh', overflow: 'auto' }}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        <div className={'im-flex-row'} style={{ marginBottom: '24px' }}>
                            <div className='im-flex-row-item'>
                                <NbisInputDropDown
                                    label='Adjustment Type'
                                    width='330px'
                                    inputType="freeText"
                                    mode={"single"}
                                    value={adjustmentEntity?.adjustmentType}
                                    onChange={(e: any) => manualChargeDetailVM.onDropdownChange(e, 'adjustmentType', adjustmentEntity, 'adjustmentEntity')}
                                    options={adjTypeDropdownOption.filter(type => (type.value !== "NORMAL" && type.value !== "RECAL" && type.value !== "CANCEL_REBILL_TO_OTHER_COMPANY"))}
                                    errorMessage={allFormState?.adjType ?? ""}
                                />
                            </div>
                        </div>

                        <div className={'im-flex-row'} style={{ marginBottom: '24px' }}>
                            <div className='im-flex-row-item'>
                                <NbisInputDropDown
                                    label='Reason Code'
                                    width='330px'
                                    inputType="freeText"
                                    mode={"single"}
                                    value={adjustmentEntity?.reasonCode}
                                    onChange={(e: any) => manualChargeDetailVM.onDropdownChange(e, 'reasonCode', adjustmentEntity, 'adjustmentEntity')}
                                    options={manualChargeDetailState.dynamicOptions.reasonCodeDropdownOptions}
                                    errorMessage={allFormState?.reasonCode ?? ""}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='im-charge-data-search-criteria-buttons'>
                {
                    <>
                        <HPHButton label={WorkspaceConstant.Common.BUTTON_CANCEL} size={'Small'} theme={'Secondary'} onClick={handleCancelClick} />
                        <HPHButton label={WorkspaceConstant.Common.BUTTON_UPDATE} size={'Small'} theme={'Primary'} onClick={handleApply} />
                    </>
                }
            </div>
        </div>
    )
}