import { isAuthUsingKong, isChargeStandAloneUsingKong, isChargeUsingKong, isCompanyUsingKong, isDocumentUsingKong, isReferenceUsingKong, isReportUsingKong, isTariffUsingKong, isRecalUsingKong } from "shared/utils/isDevelopmentEnv";

export const CHARGE_STANDALONE_API_CONTEXT_PATH = isChargeStandAloneUsingKong ? process.env.REACT_APP_KONG_GATEWAY_CHARGE_STANDALONE_MODEL_API_URI : process.env.REACT_APP_CHARGE_STANDALONE_MODEL_API_URI;
export const REFERENCE_API_CONTEXT_PATH = isReferenceUsingKong ? process.env.REACT_APP_KONG_GATEWAY_REFERENCE_MODEL_API_URI : process.env.REACT_APP_REFERENCE_MODEL_API_URI;
export const AUTH_API_CONTEXT_PATH = isAuthUsingKong ? process.env.REACT_APP_KONG_GATEWAY_AUTH_MODEL_API_URI : process.env.REACT_APP_AUTH_MODEL_API_URI;
export const COMPANY_API_CONTEXT_PATH = isCompanyUsingKong ? process.env.REACT_APP_KONG_GATEWAY_COMPANY_MODEL_API_URI : process.env.REACT_APP_COMPANY_MODEL_API_URI;
export const TARIFF_API_CONTEXT_PATH = isTariffUsingKong ? process.env.REACT_APP_KONG_GATEWAY_TARIFF_MODEL_API_URI : process.env.REACT_APP_TARIFF_MODEL_API_URI;
export const CHARGE_API_CONTEXT_PATH = isChargeUsingKong ? process.env.REACT_APP_KONG_GATEWAY_CHARGE_MODEL_API_URI : process.env.REACT_APP_CHARGE_MODEL_API_URI;
export const DOCUMENT_API_CONTEXT_PATH = isDocumentUsingKong ? process.env.REACT_APP_KONG_GATEWAY_DOCUMENT_MODEL_API_URI : process.env.REACT_APP_DOCUMENT_MODEL_API_URI;
export const REPORT_API_CONTEXT_PATH = isReportUsingKong ? process.env.REACT_APP_KONG_GATEWAY_REPORT_MODEL_API_URI : process.env.REACT_APP_REPORT_MODEL_API_URI;
export const RECAL_API_CONTEXT_PATH = isRecalUsingKong ? process.env.REACT_APP_KONG_GATEWAY_RECAL_MODEL_API_URI : process.env.REACT_APP_RECAL_MODEL_API_URI;