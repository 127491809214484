import { useCompanyDetailTracked } from "presentation/store/Company/CompanyDetailProvider";
import { useCompanyMaintenanceTracked } from "presentation/store/Company/CompanyMaintenanceProvider";
import { memo, useEffect, useMemo } from "react";
import ChargeSuppleTermsFormPanel from "./ChargeSuppleTermsFormPanel";
import CompanyEmptyPoolFormPanel from "./CompanyEmptyPoolFormPanel";
import CompanyFspFormPanel from "./CompanyFspFormPanel";
import CompanyTeuFactorFormPanel from "./CompanyTeuFactorFormPanel";
import CustomerAssignmentFormPanel from "./CustomerAssignmentFormPanel";
import CustomerInformationFormPanel from "./CustomerInformationFormPanel";

const CompanyDetailFormPanel = () => {
    const [, setCompanyDtlState] = useCompanyDetailTracked();
    const [companyDtlState] = useCompanyDetailTracked();
    const [companyState] = useCompanyMaintenanceTracked();
    const { isShowDetail } = companyState
    const { isShowCustAssign, isShowFactor, isShowFsp, isShowEmptyPool, isShowSuppleTerms, isShowCustInfo } = companyDtlState.viewState

    useEffect(() => {
        if (isShowDetail || isShowFsp || isShowSuppleTerms || isShowCustAssign || isShowEmptyPool || isShowFactor || isShowCustInfo) {
            setCompanyDtlState(prevState => ({
                ...prevState,
                companyId: companyState.currentCompany.id
            }))
        }
    }, [companyState.currentCompany.id, isShowCustAssign, isShowCustInfo, isShowDetail, isShowEmptyPool, isShowFactor, isShowFsp, isShowSuppleTerms, setCompanyDtlState])

    const memoCompanyFspForm = useMemo(() => {
        return <CompanyFspFormPanel />
    }, [])

    const memoChgSuppleTermsForm = useMemo(() => {
        return <ChargeSuppleTermsFormPanel />
    }, [])

    const memoEmptyPoolForm = useMemo(() => {
        return <CompanyEmptyPoolFormPanel />
    }, [])

    const memoTeuFactorForm = useMemo(() => {
        return <CompanyTeuFactorFormPanel />
    }, [])

    const memoCustomerAssignForm = useMemo(() => {
        return <CustomerAssignmentFormPanel />
    }, [])

    const memoCustomerInfoForm = useMemo(() => {
        return <CustomerInformationFormPanel />
    }, [])

    return (
        <>
            {isShowFsp && <>{memoCompanyFspForm}</>}
            {isShowSuppleTerms && <>{memoChgSuppleTermsForm}</>}
            {isShowCustAssign && <>{memoCustomerAssignForm}</>}
            {isShowEmptyPool && <>{memoEmptyPoolForm}</>}
            {isShowFactor && <>{memoTeuFactorForm}</>}
            {isShowCustInfo && <>{memoCustomerInfoForm}</>}
        </>
    )
}
export default memo(CompanyDetailFormPanel);
