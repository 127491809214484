import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { memo, useCallback, useMemo, useState } from "react";
import { DialogModal, FieldType, HPHButton, HPHCheckbox, IconButton, IFieldValue, Loader } from "veronica-ui-component/dist/component/core";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { RequisitionFormConstant } from "presentation/constant/RequisitionForm/RequisitionFormConstant";
import { useRequisitionFormMaintenanceTracked } from "presentation/store/RequisitionForm/RequisitionFormMaintenanceProvider";
import { useRequisitionFormVM } from "presentation/hook/RequisitionForm/useRequisitionFormVM";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";

const RequisitionFormPrintModal: React.FC = () => {
    const [reqFormState] = useRequisitionFormMaintenanceTracked();
    const reqFormVM = useRequisitionFormVM();
    const messageBarVM = useMessageBarVM();

    const REQ_FORM_CONSTANT = RequisitionFormConstant;
    const { isShowPrintModal, reqFormPrintCriteria, dynamicOptions } = reqFormState;    
    const { billToCompanyDropdownOptions, printerDropdownOptions } = dynamicOptions;
    const [isLoading, setIsLoading] = useState(false);

    const handleReprint = useCallback(async () => {  
        if(!(reqFormPrintCriteria?.printQueueName)){
            messageBarVM.showError("Please select a Printer.", true);
            return;
        }      
        setIsLoading(true);
 
        reqFormVM.onReprint(reqFormPrintCriteria).then((data) => {
            if (data && data["printReportFail"]) {
                messageBarVM.showError(data["printReportFail"], true);
                return;
            }

            messageBarVM.showSuccess("Report is sent to printers successfully.", true);
        }).catch((error) => {            
            setIsLoading(false);
            messageBarVM.showError(error.message)
        }).finally(() => {
            setIsLoading(false);
        });
    }, [messageBarVM, reqFormPrintCriteria, reqFormVM]);
    
    const handlePreview = useCallback(async () => {
        if(!(reqFormPrintCriteria?.printQueueName)){
            messageBarVM.showError("Please select a Printer.", true);
            return;
        }
        setIsLoading(true);

        reqFormVM.onPreview(reqFormPrintCriteria).then((data) => {
            if (data && data["printReportFail"]) {
                messageBarVM.showError(data["printReportFail"], true);
                return;
            }

            messageBarVM.showSuccess("Preview report successfully.", true);
        }).catch((error) => {
            setIsLoading(false);
            messageBarVM.showError(error.message)
        }).finally(() => {
            setIsLoading(false);
        });
    }, [messageBarVM, reqFormPrintCriteria, reqFormVM]);

    const refreshModal = () => {
        if (document) {
            const myModal = document.getElementById("requisitionFormPrintModal");
            if (myModal) {
                const myContent = document.querySelector(".p-dialog-content");
                if (myContent) {
                    (myContent as HTMLElement).style.overflow = "visible";
                }
            }
        }
    }

    const memoWithCreChg = useMemo(() =>
        <>
            <div className='im-flex-row-item'>
                <HPHCheckbox
                    label={REQ_FORM_CONSTANT.Print.WITH_CRE_CHG_CHECK_LIST}
                    selectedValues={reqFormPrintCriteria.printWithCreChg}
                    onChange={(e) => reqFormVM.onPrintModalCheckboxChange(e.checked,"printWithCreChg")}
                />
            </div>
        </>
        , [REQ_FORM_CONSTANT.Print.WITH_CRE_CHG_CHECK_LIST, reqFormVM, reqFormPrintCriteria.printWithCreChg])

    const memoBillToCompany = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}>
            <NbisCommonField
                //errorMessages={allFormState}
                isReadOnly={false}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={reqFormPrintCriteria.billToCompany ?? ""}
                fieldValue={reqFormPrintCriteria.billToCompany ?? ""}
                fieldLabel={REQ_FORM_CONSTANT.COMMON.BILL_TO_COMPANY}
                //isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'billToCompany'}
                isShowMissingError={true}
                maxLength={10}
                options={billToCompanyDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    reqFormVM.onPrintCriteriaFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [REQ_FORM_CONSTANT.COMMON.BILL_TO_COMPANY, billToCompanyDropdownOptions, reqFormPrintCriteria.billToCompany, reqFormVM])

    const memoReqNoFrom = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}>
            <NbisCommonField
                //errorMessages={allFormState}
                isReadOnly={false}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={reqFormPrintCriteria.reqNoFrom ?? ""}
                fieldValue={reqFormPrintCriteria.reqNoFrom ?? ""}
                fieldLabel={REQ_FORM_CONSTANT.COMMON.REQ_NO_FROM}
                //isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'reqNoFrom'}
                isShowMissingError={true}
                maxLength={10}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    reqFormVM.onPrintCriteriaFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [REQ_FORM_CONSTANT.COMMON.REQ_NO_FROM, reqFormPrintCriteria.reqNoFrom, reqFormVM])

    const memoReqNoTo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}>
            <NbisCommonField
                //errorMessages={allFormState}
                isReadOnly={false}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={reqFormPrintCriteria.reqNoTo ?? ""}
                fieldValue={reqFormPrintCriteria.reqNoTo ?? ""}
                fieldLabel={REQ_FORM_CONSTANT.COMMON.REQ_NO_TO}
                //isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'reqNoTo'}
                isShowMissingError={true}
                maxLength={10}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    reqFormVM.onPrintCriteriaFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [REQ_FORM_CONSTANT.COMMON.REQ_NO_TO, reqFormPrintCriteria.reqNoTo, reqFormVM])

    const memoCreChgNoFrom = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}>
            <NbisCommonField
                isReadOnly={false}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={reqFormPrintCriteria.creditChargeNoFrm ?? ""}
                fieldValue={reqFormPrintCriteria.creditChargeNoFrm ?? ""}
                fieldLabel={REQ_FORM_CONSTANT.Print.CREDIT_CHARGE_NO_FROM}
                fieldType={FieldType.TEXT}
                fieldKey={'creditChargeNoFrm'}
                isShowMissingError={true}
                maxLength={12}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    reqFormVM.onPrintCriteriaFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [REQ_FORM_CONSTANT.Print.CREDIT_CHARGE_NO_FROM, reqFormPrintCriteria.creditChargeNoFrm, reqFormVM])

    const memoCreChgNoTo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}>
            <NbisCommonField
                isReadOnly={false}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={reqFormPrintCriteria.creditChargeNoTo ?? ""}
                fieldValue={reqFormPrintCriteria.creditChargeNoTo ?? ""}
                fieldLabel={REQ_FORM_CONSTANT.Print.CREDIT_CHARGE_NO_TO}
                fieldType={FieldType.TEXT}
                fieldKey={'creditChargeNoTo'}
                isShowMissingError={true}
                maxLength={12}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    reqFormVM.onPrintCriteriaFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [REQ_FORM_CONSTANT.Print.CREDIT_CHARGE_NO_TO, reqFormPrintCriteria.creditChargeNoTo, reqFormVM])

    const memoPrinter = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}>
            <NbisCommonField
                //errorMessages={allFormState}
                isReadOnly={false}
                isShowOptional={false}
                isMultipleDropDown={false}
                readOnlyValue={reqFormPrintCriteria.printQueueName ?? ""}
                fieldValue={reqFormPrintCriteria.printQueueName ?? ""}
                fieldLabel={REQ_FORM_CONSTANT.Print.PRINTER}
                //isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'printQueueName'}
                isShowMissingError={true}
                maxLength={10}
                options={printerDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    reqFormVM.onPrintCriteriaFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [REQ_FORM_CONSTANT.Print.PRINTER, printerDropdownOptions, reqFormPrintCriteria.printQueueName, reqFormVM])

    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;
    
    return <DialogModal style={{ minWidth: '32vw', minHeight: '45vh' }}
        appendTo='self'
        showButton={false}
        visible={isShowPrintModal}
        positions="center"
        resizable={false}
        header={
            <div className="im-charge-data-search-confirm-modal-header">
                <span className="name">{REQ_FORM_CONSTANT.Print.TITLE}</span>
                <IconButton fileName='Icon-cross' size="medium" onClick={reqFormVM.onClosePrintModal} tooltipDisable={true} />
            </div>
        }
        dialogContent={
            <div className="im-charge-data-search-confirm-modal-content">

                <CriteriaItemContainer>
                    {memoWithCreChg}
                </CriteriaItemContainer>

                <CriteriaItemContainer>
                    {memoBillToCompany}                    
                    {memoReqNoFrom}
                    {memoReqNoTo}
                </CriteriaItemContainer>

                <CriteriaItemContainer>
                    {memoPrinter}
                    {memoCreChgNoFrom}
                    {memoCreChgNoTo}
                </CriteriaItemContainer>
            </div>
        }
        className="im-charge-data-search-confirm-modal"
        id="requisitionFormPrintModal"
        onShow={refreshModal}
        footer={
            <div className="im-charge-data-search-confirm-modal-footer">
                <HPHButton label={WorkspaceConstant.Common.BUTTON_CANCEL} size={'Small'} theme={'Secondary'} onClick={reqFormVM.onClosePrintModal} />
                <HPHButton label={"Preview"} size={'Small'} theme={'Secondary'} onClick={handlePreview} />
                <HPHButton label={WorkspaceConstant.Common.BUTTON_PRINT} size={'Small'} theme={'Primary'} onClick={handleReprint} />
            </div>
        }
    />;
};

export default memo(RequisitionFormPrintModal);