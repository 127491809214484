import { DynamicTableEntity } from "domain/entity/Common/DynamicTableEntity";
import { DocumentPreviewDetailEntity } from "domain/entity/DocumentPreview/DocumentPreviewDetailEntity";
import { DocumentPreviewEntity } from "domain/entity/DocumentPreview/DocumentPreviewEntity";
import { DocumentPreviewSearchCriteria, EMPTY_DOCUMENT_PREVIEW_SEARCH_CRITERIA } from "domain/entity/DocumentPreview/DocumentPreviewSearchCriteria";
import { DEFAULT_ENABLED_DOCUMENT_PREVIEW_SEARCH_CRITERIA, DocumentPreviewEnabledSearchCriteria } from "presentation/constant/DocumentPreview/DocumentPreviewEnabledSearchCriteria";
import { DocumentPreviewFirstPrintCriteria, EMPTY_DOCUMENT_PREVIEW_FIRST_PRINT_CRITERIA } from "presentation/constant/DocumentPreview/DocumentPreviewFirstPrintCriteria";
import { RefObject } from "react";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";

export interface DocumentPreviewDropdownOptions {
    chargeTypeDropdownOptions: DropdownProps[],
    billToCompanyDropdownOptions: DropdownProps[],
    finalizedByDropdownOptions: DropdownProps[],
    fileLocationDropdownOptions: DropdownProps[],
    printQueueDropdownOptions: DropdownProps[],
}
export interface DocumentPreviewChangeState extends BaseViewChangeSate {
    //currentSelectedData: EmptyPoolStorageEntity
}

export interface DocumentPreviewModel {
    isLoading: boolean,
    isShowRightPanel: boolean,
    isFilterSearchCriteria: boolean,
    isEditSearchCriteria: boolean,
    checkboxRef: { [key: string]: HTMLElement | null },
    enabledSearchCriteria: DocumentPreviewEnabledSearchCriteria,
    //checkboxValue: EmptyPoolStorageGenEnabledSearchCriteria,
    searchCriteria: DocumentPreviewSearchCriteria,
    highlightedCheckboxKey?: string | null
    currentTableRef?: RefObject<any>,
    searchCounter: number,
    dynamicOptions: DocumentPreviewDropdownOptions,
    isShowDetail: boolean,
    //emptyPoolDatas: EmptyPoolStorageEntity[],    
    //selectedEmptyPoolDatas: EmptyPoolStorageEntity[],
    isShowCriteriaPanel: boolean,
    allFormState?: { [x: string]: string },
    dynamicTableColumns: DynamicTableEntity[],
    documentPreviewList: DocumentPreviewEntity[],
    allDocumentPreviewList: DocumentPreviewEntity[],
    documentPreviewDtlList: DocumentPreviewDetailEntity[],
    selectedRows: DocumentPreviewEntity[],
    currentSelectedData: DocumentPreviewEntity,
    isBackFromDetail: boolean,
    isAllowAutoSearch: boolean,
    isWaitForApproval: boolean,
    isDisapproved: boolean,
    isReadyForFinalize: boolean,
    isWaitForApprovalCount: number,
    isDisapprovedCount: number,
    isReadyForFinalizeCount: number,
    searchStatus: string,
    poNo: string,
    isShowEditPanel: boolean,
    isShowPrintPad: boolean,
    isPrinting: boolean,
    docFirstPrintCriteria: DocumentPreviewFirstPrintCriteria,
    forceRefresh: boolean,
    defaultInvPrinter: string,
    defaultSuppPrinter: string,
}

export const EMPTY_DOCUMENT_PREVIEW_MODEL: DocumentPreviewModel = {
    isLoading: false,
    isShowRightPanel: false,
    isFilterSearchCriteria: false,
    isEditSearchCriteria: false,
    checkboxRef: {},
    enabledSearchCriteria: { ...DEFAULT_ENABLED_DOCUMENT_PREVIEW_SEARCH_CRITERIA },
    //checkboxValue: { ...DEFAULT_ENABLED_EMPTY_POOL_STORAGE_GEN_SEARCH_CRITERIA },
    searchCriteria: { ...EMPTY_DOCUMENT_PREVIEW_SEARCH_CRITERIA },
    searchCounter: 0,
    dynamicOptions: {
        chargeTypeDropdownOptions: [],
        billToCompanyDropdownOptions: [],
        finalizedByDropdownOptions: [],
        fileLocationDropdownOptions: [],
        printQueueDropdownOptions: []
    },
    isShowDetail: false,
    //emptyPoolDatas: [],    
    //selectedEmptyPoolDatas: [],
    isShowCriteriaPanel: false,
    allFormState: {},
    dynamicTableColumns: [],
    documentPreviewList: [],
    allDocumentPreviewList: [],
    documentPreviewDtlList: [],
    selectedRows: [],
    currentSelectedData: {},
    isBackFromDetail: false,
    isAllowAutoSearch: true,
    isWaitForApproval: false,
    isDisapproved: false,
    isReadyForFinalize: false,
    searchStatus: "",
    isWaitForApprovalCount: 0,
    isDisapprovedCount: 0,
    isReadyForFinalizeCount: 0,
    poNo: "",
    isShowEditPanel: false,
    isShowPrintPad: false,
    isPrinting: false,
    docFirstPrintCriteria: { ...EMPTY_DOCUMENT_PREVIEW_FIRST_PRINT_CRITERIA },
    forceRefresh: false,
    defaultInvPrinter: "",
    defaultSuppPrinter: ""
}