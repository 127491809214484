import { ConstraintEntity } from "domain/entity/Criteria/ConstraintEntity"
import { CriteriaConstant } from "presentation/constant/Criteria/CriteriaConstant"
import { useCriteriaVM } from "presentation/hook/Criteria/useCriteriaVM"
import { useMessageBarVM } from "presentation/hook/useMessageBar"
import { useCriteriaTracked } from "presentation/store/Criteria/CriteriaProvider"
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer"
import { NbisCommonField } from "presentation/view/components/NbisCommonField"
import { memo, useCallback, useMemo, useState } from "react"
import { DialogModal, FieldType, HPHButton, HPHGroupRadioButton, IFieldValue, Loader } from "veronica-ui-component/dist/component/core"
import { SidebarCaption, Sidebarheader, SidebarTitle } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled"
import ConstraintGroupPanel from "./ConstraintGroupPanel"


const CriteriaComponentPanel = () => {
    const [criteriaState] = useCriteriaTracked();
    const criteriaVM = useCriteriaVM();
    const { componentState, } = criteriaState;
    const { isAdd, isEditable, isRead, isSaveClicked, allFormState } = componentState;
    const currentConsGrpComp = componentState.currentSelectItem;
    const CRITERIA_CONSTANT = CriteriaConstant.Table;
    const [isLoading, setIsLoading] = useState(false);
    const { currentSelectedRow } = criteriaState;
    const messageBarVM = useMessageBarVM();
    const [isconDefLoading, setIsconDefLoading] = useState(false);
    const [groupType, setGroupType] = useState<any>();
    const [groupTypeName, setGroupTypeName] = useState<string>();


    const handleCancelClick = () => {
        criteriaVM.onComponentCancel();
    }

    const onRadioChange = useCallback(async (e: any, fieldName: string) => {
        if (e.checked && e.key !== currentConsGrpComp.constraintGroupDefName) {
            setIsconDefLoading(true);
            setGroupType(e);
            setGroupTypeName(fieldName);
        }
    }, [currentConsGrpComp.constraintGroupDefName])

    const handleYesClick = () => {
        criteriaVM.onRadioChange(groupType, groupTypeName ?? '');
        setIsconDefLoading(false);
    }

    const handleNoClick = () => {
        setIsconDefLoading(false);
    }
    const hasDuplicateConstraintNames = (constraintEntities: ConstraintEntity[]) => {
        const namesSet = new Set<string>();

        for (const entity of constraintEntities) {
            if (entity.constraintDef.constraintDefName) {
                if (namesSet.has(entity.constraintDef.constraintDefName)) {
                    return true;
                }
                namesSet.add(entity.constraintDef.constraintDefName);
            }
        }
        return false;
    }

    const handleSaveClick = useCallback(async () => {
        setIsLoading(true);
        try {
            let isSave = true;
            if (currentConsGrpComp.constraints.length < 1) {
                messageBarVM.showError('In group [' + currentConsGrpComp.constraintGroupName + '], Constraint mandatory fields input is invalid.');
                return;
            }
            if (currentConsGrpComp.constraints && hasDuplicateConstraintNames(currentConsGrpComp.constraints)) {
                messageBarVM.showError('Constraint is already existed.');
                return;
            }
            if (currentConsGrpComp.id === null && componentState.consGrpList?.some(item => item.constraintGroupName === currentConsGrpComp.constraintGroupName)) {
                isSave = false;
            }
            if (currentConsGrpComp.id !== null) {
                const constraint = componentState.consGrpList?.filter(item => item.constraintGroupName === currentConsGrpComp.constraintGroupName);
                if (constraint?.some(item => item.id !== currentConsGrpComp.id)) {
                    isSave = false;
                }
            }

            if (isSave) {
                criteriaVM.onComponentSaveClicked();
                const consGrpDef = criteriaState.consGrpsTypeDropdownOptions.find(item => item.key === componentState.currentSelectItem.constraintGroupDefName)?.inputId;
                let consGrp = {
                    ...currentConsGrpComp,
                    constraintGroupDefId: Number(consGrpDef) ?? null
                }
                const res = await criteriaVM.onTempSaveDetail(consGrp, currentSelectedRow.id)
                if (res && res["mandatoryCheckFail"]) {
                    messageBarVM.showError(res["mandatoryCheckFail"]?.toString());
                } else {
                    messageBarVM.showSuccess('Save successful!');
                }
            } else {
                messageBarVM.showError('"' + currentConsGrpComp.constraintGroupName + '" already exists. Pls rename it.');
            }
        } catch (error) {

        } finally {
            setIsLoading(false)
        }
    }, [componentState, criteriaState, criteriaVM, currentConsGrpComp, currentSelectedRow.id, messageBarVM])

    const memoGroupName = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "330px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!isAdd}
                isShowOptional={false}
                readOnlyValue={currentConsGrpComp?.constraintGroupName || ''}
                fieldValue={currentConsGrpComp?.constraintGroupName}
                fieldLabel={CRITERIA_CONSTANT.GROUP_NAME}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'constraintGroupName'}
                maxLength={60}
                isShowMissingError={true}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    criteriaVM.onComponentFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CRITERIA_CONSTANT.GROUP_NAME, allFormState, currentConsGrpComp?.constraintGroupName, isSaveClicked, criteriaVM, isAdd])

    const memoType = useMemo(() =>
        <div className='im-flex-row-item'>
            <HPHGroupRadioButton
                label="Type"
                disabled={isRead}
                radioOptions={criteriaState.consGrpsTypeDropdownOptions}
                value={currentConsGrpComp.constraintGroupDefName ?? ""}
                onChange={(e) => onRadioChange(e, 'constraintGroupDefName')}
                checked={criteriaState.consGrpsTypeDropdownOptions.find(item => item.key === currentConsGrpComp.constraintGroupDefName)?.name}
            />
        </div>
        , [currentConsGrpComp?.constraintGroupDefName, isRead, criteriaState.consGrpsTypeDropdownOptions, onRadioChange])


    return (
        <div className='side-form-content-wrapper' style={{ width: '100%', height: "100vh" }}>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>
                            <SidebarTitle>{isAdd ? "NEW GROUP" : currentConsGrpComp?.constraintGroupName}</SidebarTitle>
                            {(isAdd || (isEditable && !isRead)) && <SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>}
                        </SidebarTitle>
                    </Sidebarheader>
                </div>
            </div>

            {isLoading && <Loader Indicator="Spinner" size="Medium" />}

            <div className={'add-edit-form'} style={{ height: '75vh', maxHeight: '75vh', overflow: "visible" }}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'} style={{ height: '75vh', maxHeight: '75vh' }}>
                        <CriteriaItemContainer>
                            {memoGroupName}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoType}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            <ConstraintGroupPanel criteriaState={criteriaState} />
                        </CriteriaItemContainer>
                    </div>
                </div>
            </div>

            <div id={"CriteriaComponentPanel_button"} className='im-charge-data-search-criteria-buttons'>
                {
                    <>
                        <HPHButton label={isRead ? 'Close' : 'Cancel'} size={'Small'} theme={'Secondary'} onClick={handleCancelClick} />
                        {!isRead && <HPHButton label={isAdd ? 'Add' : "Update"} size={'Small'} theme={'Primary'} onClick={handleSaveClick} />}
                    </>
                }
            </div>
            {<DialogModal
                appendTo='self'
                showButton={false}
                visible={isconDefLoading}
                positions="center"
                header={
                    <>
                        <div className="modal-header">
                            <span className="name">{'Update Group Type'}</span>
                        </div>
                        <div className="text">
                            <p style={{ whiteSpace: 'pre-line' }}>{'If you select "Yes", the current group criteria data will be lost!'}</p>
                        </div>
                    </>
                }
                dialogContent={<></>}
                className="delete-modal"
                footer={
                    <div className="action-button">
                        <HPHButton
                            label='Yes'
                            theme='Alert'
                            disableBadge={true}
                            onClick={handleYesClick}
                        />
                        <HPHButton
                            label='No'
                            theme='Secondary'
                            disableBadge={true}
                            onClick={handleNoClick}
                        />
                    </div>
                }
            />}
        </div>
    )
}

export default memo(CriteriaComponentPanel);