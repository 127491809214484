import { ChargeConstant } from "presentation/constant/ChargeHeader/ChargeConstant";
import { ChargeHeaderCriteriaDirectoryVoyageCheckboxOption } from "presentation/constant/ChargeHeader/ChargeHeaderCriteriaDirectoryCheckboxOption";
import { chargeHeadergGroupRadioOption } from "presentation/constant/RadioOptions/StaticRadioOptions";
import { useChargeHeaderMaintenanceVM } from "presentation/hook/ChargeHeaderMaintenance/useChargeHeaderMaintenanceVM";
import { useChargeHeaderMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/ChargeHeaderMaintenanceProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DateRangePickerComponent from "presentation/view/components/DateRangePickerComponent";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import NbisInputDropDown from "presentation/view/components/NbisInputDropDown";
import { useMemo } from "react";
import {
    FieldType,
    GroupCheckboxList,
    HPHCheckbox,
    HPHGroupCheckbox,
    HPHGroupRadioButton,
    InputField
} from "veronica-ui-component/dist/component/core";


export const ChargeHeaderSearchCriteriaEditVoyagePanel = () => {
    const [chargeDataSearchState] = useChargeHeaderMaintenanceTracked();
    const CHARGE_HEADER_CONSTANT = ChargeConstant.ChargeHeader;
    const chargeDataSearchCriteria = chargeDataSearchState.searchCriteria;
    const chargeDataSearchVM = useChargeHeaderMaintenanceVM();
    const enabledCriteria = useMemo(() => ChargeHeaderCriteriaDirectoryVoyageCheckboxOption.find(item => chargeDataSearchState.enabledSearchCriteria[item.key]), [chargeDataSearchState.enabledSearchCriteria]);
    const selectedHdrGroup = chargeDataSearchCriteria.tempHdrGroupName;

    const memoHeaderGroup = useMemo(() =>
        <div className='im-flex-row-item'>
            <HPHGroupRadioButton
                label="Charge Group"
                radioOptions={chargeHeadergGroupRadioOption}
                value={chargeDataSearchCriteria.tempHdrGroupName ?? ""}
                onChange={(e: any) => chargeDataSearchVM.onRadioChange(e, 'tempHdrGroupName')}
                checked={chargeHeadergGroupRadioOption.find(item => item.key === chargeDataSearchCriteria.tempHdrGroupName)?.name}
            />
        </div>
        , [chargeDataSearchCriteria.tempHdrGroupName, chargeDataSearchVM])

    const memoLoginUser = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputField
                width={selectedHdrGroup !== "DOMESTIC" ? '150px' : "330px"}
                maxLength={999}
                label={CHARGE_HEADER_CONSTANT.LOGIN_USER}
                type="text"
                value={chargeDataSearchCriteria.loginUser || ''}
                onChange={(e: any) => chargeDataSearchVM.onInputTextChange(e, 'loginUser')} />
        </div>
        , [CHARGE_HEADER_CONSTANT.LOGIN_USER, chargeDataSearchCriteria.loginUser, chargeDataSearchVM, selectedHdrGroup])

    const memoVslCode = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputField
                maxLength={6}
                style={{ "text-transform": "uppercase", width: "150px" }}
                label={CHARGE_HEADER_CONSTANT.VESSEL_CODE}
                type="text"
                value={chargeDataSearchCriteria.vesselCode || ''}
                onChange={(e: any) => chargeDataSearchVM.onInputTextChange(e, 'vesselCode', true)} />
        </div>
        , [CHARGE_HEADER_CONSTANT.VESSEL_CODE, chargeDataSearchCriteria.vesselCode, chargeDataSearchVM])

    const memoVoyCode = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputField
                maxLength={10}
                style={{ "text-transform": "uppercase", width: "150px" }}
                label={CHARGE_HEADER_CONSTANT.VOYAGE_CODE}
                type="text"
                value={chargeDataSearchCriteria.voyageCode || ''}
                onChange={(e: any) => chargeDataSearchVM.onInputTextChange(e, 'voyageCode', true)} />
        </div>
        , [CHARGE_HEADER_CONSTANT.VOYAGE_CODE, chargeDataSearchCriteria.voyageCode, chargeDataSearchVM])


    const memoVslName = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputField
                width='330px'
                maxLength={30}
                label={CHARGE_HEADER_CONSTANT.VESSEL_NAME}
                type="text"
                value={chargeDataSearchCriteria.vesselName || ''}
                onChange={(e: any) => chargeDataSearchVM.onInputTextChange(e, 'vesselName')} />
        </div>
        , [CHARGE_HEADER_CONSTANT.VESSEL_NAME, chargeDataSearchCriteria.vesselName, chargeDataSearchVM])


    const memoEtdFromTo = useMemo(() =>
        <div className='im-flex-row-item'>
            <DateRangePickerComponent
                label={ChargeConstant.Charge.ETD_RANGE}
                width='330px'
                fieldNames={{ startField: "etdDateFrom", endField: "etdDateTo" }}
                dateRange={{ startDate: chargeDataSearchCriteria.etdDateFrom, endDate: chargeDataSearchCriteria.etdDateTo }}
                onDatesChange={chargeDataSearchVM.onDateRangeChange}
            />
        </div>
        , [chargeDataSearchCriteria.etdDateFrom, chargeDataSearchCriteria.etdDateTo, chargeDataSearchVM.onDateRangeChange])

    const memoEtaFromTo = useMemo(() =>
        <div className='im-flex-row-item'>
            <DateRangePickerComponent
                label={CHARGE_HEADER_CONSTANT.ETA_RANGE}
                width='330px'
                fieldNames={{ startField: "etaDateFrom", endField: "etaDateTo" }}
                dateRange={{ startDate: chargeDataSearchCriteria.etaDateFrom, endDate: chargeDataSearchCriteria.etaDateTo }}
                onDatesChange={chargeDataSearchVM.onDateRangeChange}
            />
        </div>
        , [CHARGE_HEADER_CONSTANT.ETA_RANGE, chargeDataSearchCriteria.etaDateFrom, chargeDataSearchCriteria.etaDateTo, chargeDataSearchVM.onDateRangeChange])

    const memoBillToCompany = useMemo(() =>
        <div className='im-flex-row-item'>
            <NbisInputDropDown
                label={CHARGE_HEADER_CONSTANT.BILL_TO_COMPANY}
                inputType="freeText"
                width="330px"
                mode={'multiple'}
                sort={false}
                // disabled={selectedHdrGroup === "SHIPBOUNDEDINPERIOD" || selectedHdrGroup === "SHIPBOUNDED"}
                value={chargeDataSearchCriteria.billToCompList?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => chargeDataSearchVM.onMultipleDropdownChange(e, 'billToCompList')}
                options={chargeDataSearchState.dynamicOptions.billToCompDropdownOptions} />
        </div>
        , [CHARGE_HEADER_CONSTANT.BILL_TO_COMPANY, chargeDataSearchCriteria.billToCompList, chargeDataSearchState.dynamicOptions.billToCompDropdownOptions, chargeDataSearchVM])

    const memoBillingCycle = useMemo(() =>
        <div className='im-flex-row-item'>
            <HPHGroupCheckbox
                label={CHARGE_HEADER_CONSTANT.BILLING_CYCLE}
                checkboxData={[
                    { key: "MONTHLY", name: "MONTHLY", disabled: false },
                    { key: "WEEKLY", name: "WEEKLY", disabled: false },
                ]}
                // disabled={selectedHdrGroup === "SHIPBOUNDED"}
                selectedValues={chargeDataSearchCriteria.billingCycleList}
                orientation={"horizontal"}
                onChange={(e: (GroupCheckboxList | undefined)[]) => chargeDataSearchVM.onGroupCheckboxChange(e, chargeDataSearchState.searchCriteria, "billingCycleList")}
            />
        </div>
        , [CHARGE_HEADER_CONSTANT.BILLING_CYCLE, chargeDataSearchCriteria.billingCycleList, chargeDataSearchState.searchCriteria,
            chargeDataSearchVM])

    const memoBillingCycleCode = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputField
                width='330px'
                maxLength={999}
                label={CHARGE_HEADER_CONSTANT.BILLING_CYCLE_CODE}
                type="text"
                // disabled={selectedHdrGroup === "SHIPBOUNDED"}
                value={chargeDataSearchCriteria.billingCycleCode || ''}
                onChange={(e: any) => chargeDataSearchVM.onInputTextChange(e, 'billingCycleCode')} />
        </div>
        , [CHARGE_HEADER_CONSTANT.BILLING_CYCLE_CODE, chargeDataSearchCriteria.billingCycleCode, chargeDataSearchVM])

    const memoSoa = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputField
                maxLength={6}
                style={{ "text-transform": "uppercase", width: "150px" }}
                label={CHARGE_HEADER_CONSTANT.SOA}
                type="text"
                value={chargeDataSearchCriteria.soa || ''}
                onChange={(e: any) => chargeDataSearchVM.onInputTextChange(e, 'soa', true)} />
        </div>
        , [CHARGE_HEADER_CONSTANT.SOA, chargeDataSearchCriteria.soa, chargeDataSearchVM])

    const memoVslType = useMemo(() =>
        <div className='im-flex-row-item'>
            <HPHGroupCheckbox
                label={CHARGE_HEADER_CONSTANT.VESSEL_TYPE}
                checkboxData={[
                    { key: "D", name: "DEEPSEA", disabled: false },
                    { key: "F", name: "FEEDER", disabled: false },
                    { key: "L", name: "BARGE", disabled: false },
                    { key: "S", name: "SHORTSEA", disabled: false }
                ]}
                selectedValues={chargeDataSearchState.searchCriteria.vesselTypeList}
                orientation={"horizontal"}
                onChange={(e: (GroupCheckboxList | undefined)[]) => chargeDataSearchVM.onGroupCheckboxChange(e, chargeDataSearchState.searchCriteria, "vesselTypeList")}
            />
        </div>
        , [CHARGE_HEADER_CONSTANT.VESSEL_TYPE, chargeDataSearchState.searchCriteria, chargeDataSearchVM])

    const memoServiceCode = useMemo(() =>
        <div className='im-flex-row-item'>
            <NbisInputDropDown
                label={CHARGE_HEADER_CONSTANT.SERVICE_CODE}
                inputType="freeText"
                width="330px"
                mode={'multiple'}
                sort={false}
                value={chargeDataSearchCriteria.serviceCodeList?.map((item) => ({
                    value: item
                }))}
                onChange={(e: any) => chargeDataSearchVM.onMultipleDropdownChange(e, 'serviceCodeList')}
                options={chargeDataSearchState.dynamicOptions.serviceCodeDropdownOptions} />
        </div>
        , [CHARGE_HEADER_CONSTANT.SERVICE_CODE, chargeDataSearchCriteria.serviceCodeList, chargeDataSearchState.dynamicOptions.serviceCodeDropdownOptions, chargeDataSearchVM])

    const memoState = useMemo(() =>
        <>
            <div className='im-flex-row-item'>
                <HPHGroupCheckbox
                    label={CHARGE_HEADER_CONSTANT.HEADER_STATE}
                    checkboxData={[
                        { key: "NIL", name: "NIL", disabled: false },
                        { key: "CFM", name: "CFM", disabled: false },
                        { key: "TML", name: "TML", disabled: false },
                        { key: "INV", name: "INV", disabled: false }
                    ]}
                    selectedValues={chargeDataSearchState.searchCriteria.stateList}
                    orientation={"horizontal"}
                    onChange={(e: (GroupCheckboxList | undefined)[]) => chargeDataSearchVM.onGroupCheckboxChange(e, chargeDataSearchState.searchCriteria, "stateList")}
                />
            </div>
            {/*<div className='im-flex-row-item'>
            <NbisInputDropDown
                label={CHARGE_HEADER_CONSTANT.HEADER_STATE}
                inputType="freeText"
                width="150px"
                mode={'multiple'}
                sort={false}
                value={chargeDataSearchCriteria.stateList?.map((item) => ({
                    value: item
                }))}
                onChange={(e:any) => chargeDataSearchVM.onMultipleDropdownChange(e, 'stateList')}
                options={hdrStateDropdownOption}/>
        </div>*/}
        </>
        , [CHARGE_HEADER_CONSTANT.HEADER_STATE, chargeDataSearchVM, chargeDataSearchState.searchCriteria])

    const memoToBill = useMemo(() =>
        <div className='im-flex-row-item'>
            <NbisCommonField
                fieldKey={"toBill"}
                requiredFieldList={[]}
                fieldLabel={CHARGE_HEADER_CONSTANT.TO_BILL}
                fieldType={FieldType.TEXT}
                isReadOnly={true}
                onFieldChange={() => { }} />
            <HPHCheckbox
                label={""}
                checked={chargeDataSearchCriteria.toBill ?? false}
                onChange={(e: any) => chargeDataSearchVM.onCheckboxChange(e.checked, "toBill")}
            />
        </div>
        , [CHARGE_HEADER_CONSTANT.TO_BILL, chargeDataSearchCriteria.toBill, chargeDataSearchVM])


    return (
        enabledCriteria ? <>
            <CriteriaItemContainer>
                {memoHeaderGroup}
            </CriteriaItemContainer>

            <CriteriaItemContainer>
                {chargeDataSearchState.enabledSearchCriteria.stateList ? memoState : null}
                {chargeDataSearchState.enabledSearchCriteria.toBill ? memoToBill : null}
            </CriteriaItemContainer>

            <CriteriaItemContainer>
                {chargeDataSearchState.enabledSearchCriteria.loginUser ? memoLoginUser : null}
                {selectedHdrGroup !== "DOMESTIC" ? memoSoa : null}
            </CriteriaItemContainer>

            <CriteriaItemContainer>
                {selectedHdrGroup !== "DOMESTIC" ? memoVslCode : null}
                {selectedHdrGroup !== "DOMESTIC" ? memoVoyCode : null}
            </CriteriaItemContainer>

            <CriteriaItemContainer>
                {selectedHdrGroup !== "DOMESTIC" ? memoVslName : null}
            </CriteriaItemContainer>

            <CriteriaItemContainer>
                {(!(selectedHdrGroup === "SHIPBOUNDEDINPERIOD" || selectedHdrGroup === "SHIPBOUNDED")) ? memoBillToCompany : null}
            </CriteriaItemContainer>

            <CriteriaItemContainer>
                {selectedHdrGroup !== "DOMESTIC" ? memoServiceCode : null}
            </CriteriaItemContainer>

            <CriteriaItemContainer>
                {selectedHdrGroup !== "DOMESTIC" ? memoEtdFromTo : null}
            </CriteriaItemContainer>
            <CriteriaItemContainer>
                {selectedHdrGroup !== "DOMESTIC" ? memoEtaFromTo : null}
            </CriteriaItemContainer>

            <CriteriaItemContainer>
                {selectedHdrGroup !== "SHIPBOUNDED" ? memoBillingCycle : null}
            </CriteriaItemContainer>

            <CriteriaItemContainer>
                {selectedHdrGroup !== "SHIPBOUNDED" ? memoBillingCycleCode : null}
            </CriteriaItemContainer>

            <CriteriaItemContainer>
                {selectedHdrGroup !== "DOMESTIC" ? memoVslType : null}
            </CriteriaItemContainer>


        </> : null
    );
};
