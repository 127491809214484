import { SelectionChangedEvent } from "ag-grid-community";
import { CostCenterCodeMappingEntity } from "domain/entity/CostCenterCodeMapping/CostCenterCodeMappingEntity";
import _ from "lodash";
import { INITIAL_COST_CENTER_CODE_MAPPING_COL_DEF } from "presentation/constant/CostCenterCodeMapping/CostCenterCodeMappingMaintenanceColumnDefinition";
import { CostCenterCodeMappingMaintenanceConstant } from "presentation/constant/CostCenterCodeMapping/CostCenterCodeMappingMaintenanceConstant";
import { useCostCenterCodeMappingMaintenanceVM } from "presentation/hook/CostCenterCodeMapping/useCostCenterCodeMappingMaintenanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useCostCenterCodeMappingMaintenanceTracked } from "presentation/store/CostCenterCodeMapping/CostCenterCodeMappingMaintenanceProvider";
import RightClickMenuWithMoveAfter from "presentation/view/components/NbisRightClickMenu/RightClickMenuWithMoveAfter";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { createRef, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Loader } from "veronica-ui-component/dist/component/core";


const CostCenterCodeMappingTablePanel: React.FC = () => {
    const [costCenterCodeMappingState] = useCostCenterCodeMappingMaintenanceTracked();
    const costCenterCodeMappingVM = useCostCenterCodeMappingMaintenanceVM();
    const gridRef: any = useRef(null);
    // const rightClickRef: any = createRef();
    // const [anainfoState] = useANAInfoTracked();
    // const {allowUpdate} = anainfoState;
    const messageBarVM = useMessageBarVM();

    const [anainfoState] = useANAInfoTracked();
    const { allowUpdate } = anainfoState;
    const rightClickRef: any = createRef();
    // for right click menu with move after begin
    const [showMoveCursor, setShowMoveCursor] = useState<boolean>(false);
    const [allRows, setAllRows] = useState<CostCenterCodeMappingEntity[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [initialAllRows, setInitialAllRows] = useState<boolean>(true);

    const getAllRows = useCallback(() => {
        return allRows;
    }, [allRows]);

    const getSelectedRows = useCallback(() => {
        return costCenterCodeMappingState.selectedRows;
    }, [costCenterCodeMappingState.selectedRows]);

    useEffect(() => {
        const handleRightClickMenu = (event: any) => {
            if (_.isEmpty(costCenterCodeMappingState.selectedRows) || !allowUpdate) {
                return;
            }
            event.preventDefault();
            rightClickRef.current.show(event);
        };
        document.getElementById('myDiv')?.addEventListener("contextmenu", handleRightClickMenu);

        return () => {
            document.getElementById('myDiv')?.removeEventListener("contextmenu", handleRightClickMenu);
        };
    }, [rightClickRef, allowUpdate, costCenterCodeMappingState.selectedRows]);

    // for right click menu with move after begin
    const onRefreshRow = useCallback((newAllRows: any[]) => {
        setAllRows(newAllRows);
        costCenterCodeMappingVM.updateSelectedRows([]);
        setShowMoveCursor(true);
    }, [costCenterCodeMappingVM]);

    const onMoveCancel = useCallback(() => {
        setInitialAllRows(true);
        setAllRows(costCenterCodeMappingState.tableData);
        costCenterCodeMappingVM.updateSelectedRows([]);
        setShowMoveCursor(false);
    }, [costCenterCodeMappingState.tableData, costCenterCodeMappingVM]);

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();
        costCenterCodeMappingVM.updateSelectedRows(selectedRows);
    }, [costCenterCodeMappingVM])

    const handleAddClick = useCallback(() => {
        costCenterCodeMappingVM.onAdd();
    }, [costCenterCodeMappingVM])

    const handleRowDoubleClick = useCallback((entity: CostCenterCodeMappingEntity) => {
        costCenterCodeMappingVM.onEdit(entity);
    }, [costCenterCodeMappingVM])

    const onSaveMove = useCallback(async (newAllRows: any[]) => {
        if (_.isEmpty(newAllRows)) return;

        setIsLoading(true);

        costCenterCodeMappingVM.onApply(newAllRows).then((res) => {
            if (!res || !res.success) {
                messageBarVM.showError('Move failed.');
                setInitialAllRows(true);
                setIsLoading(false);

                costCenterCodeMappingVM.updateSelectedRows([]);
                setShowMoveCursor(false);
            } else {
                costCenterCodeMappingVM.onSearch().then((data) => {
                    setInitialAllRows(true);
                    setIsLoading(false);

                    costCenterCodeMappingVM.updateSelectedRows([]);
                    setShowMoveCursor(false);
                }).catch((e) => {
                    setInitialAllRows(true);
                    setIsLoading(false);

                    costCenterCodeMappingVM.updateSelectedRows([]);
                    setShowMoveCursor(false);
                })
            }
        })
    }, [costCenterCodeMappingVM, messageBarVM]);

    const memoTable = useMemo(() => {
        return (
            <>
                {(allowUpdate) && <RightClickMenuWithMoveAfter disabled={_.isEmpty(getSelectedRows())}
                    rightClickRef={rightClickRef} selectedRows={getSelectedRows()} allRows={getAllRows()}
                    onSaveMove={onSaveMove} onCancel={onMoveCancel} onRefreshRow={onRefreshRow}
                    showConfirmMove={showMoveCursor} priorityName="priority" />}
                <div id="myDiv">
                    <NbisTable
                        id='cost-center-code-mapping-table'
                        headerLabel={CostCenterCodeMappingMaintenanceConstant.TITLE}
                        isNewColumnSetting={true}
                        columns={INITIAL_COST_CENTER_CODE_MAPPING_COL_DEF}
                        data={allRows ?? []}
                        showPaginator={false}
                        editable={false}
                        showAddIcon={true}
                        onAddClick={handleAddClick}
                        showDeleteButton={false}
                        showReloadIcon={false}
                        isScrollHighlighted={true}
                        showCutCursor={showMoveCursor}
                        selectionMode={true}
                        isRowHighligted={true}
                        onSelectionChanged={handleSelectionChange}
                        onRowDoubleClick={(e: any, entity: CostCenterCodeMappingEntity) => handleRowDoubleClick(entity)}
                        gridHeight="customHeight"
                        customHeight="calc(100vh - 75px)"
                        ref={gridRef}
                        rowSelection={showMoveCursor ? "single" : "multiple"}
                    />
                </div>
            </>
        );
    }, [allowUpdate, getSelectedRows, rightClickRef, getAllRows, onSaveMove, onMoveCancel, onRefreshRow, showMoveCursor, allRows, handleAddClick, handleSelectionChange, handleRowDoubleClick])

    useEffect(() => {
        if (initialAllRows && costCenterCodeMappingState.tableData && !_.isEmpty(costCenterCodeMappingState.tableData)) {
            setIsLoading(true);
            setAllRows(costCenterCodeMappingState.tableData.map((costCenterCodeMappingEntity, index) => ({
                ...costCenterCodeMappingEntity,
                index: index || 0,
                flag_showCutCursor: false
            })));
            setInitialAllRows(false);
        }

        setIsLoading(false);
    }, [allRows, initialAllRows, costCenterCodeMappingState.tableData]);

    //if (isLoading) return <Loader Indicator="Spinner" size="Medium" />;
    return <><TableWrapper>
        {(isLoading) && <Loader Indicator="Spinner" size="Medium" />}
        {memoTable}
        {costCenterCodeMappingState.forceRefresh && <></>}
    </TableWrapper>
    </>;
}

export default memo(CostCenterCodeMappingTablePanel);
