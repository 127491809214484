import { CompanyTerminalEntity, EMPTY_COMPANY_TERMINAL_ENTITY } from "domain/entity/Company/CompanyTerminalEntity";
import { TerminalEntity } from "domain/entity/Terminal/TerminalEntity";
import { CompanyTerminalRepository } from "domain/repository/Company/CompanyTerminalRepo";
import _ from "lodash";
import { CompanyDetailModel } from "presentation/model/Company/CompanyDetailModel";

import BaseViewModel from "presentation/viewModel/BaseViewModel";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";

interface CompanyTerminalVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<CompanyDetailModel>> | ((value: SetStateAction<CompanyDetailModel>) => void),
    ]
    companyTerminalRepo: CompanyTerminalRepository
}

export const CompanyTerminalVM = ({ dispatch, companyTerminalRepo }: CompanyTerminalVMProps) => {
    const [companyDetailDispatch] = dispatch;

    const onMultipleDropdownChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        return companyDetailDispatch(prevState => {   //only apple       
            const dropdownOptions = prevState.dynamicOptions.contractTmlDropdownOptions;

            const selectedTerminals: TerminalEntity[] = [];
            dropdownOptions.forEach(item => {
                if (val && val.length > 0) {
                    const isExisted = val.some((value: string) => value === item.value);
                    if (isExisted) {
                        let terminalEty: TerminalEntity = {
                            id: Number(item.tagLabel),
                            terminalCode: item.value,
                            terminalDesc: "",
                            activeInd: "Y"
                        };

                        selectedTerminals.push(terminalEty);
                    }
                }
            });

            return {
                ...prevState,
                companyTerminalState: {
                    ...prevState.companyTerminalState,
                    selectedTerminals: selectedTerminals
                }
            }
        });
    };

    const onSave = async (selectedTerminals: TerminalEntity[], companyId: number) => {
        const saveCompTerminals: CompanyTerminalEntity[] = [];
        if (selectedTerminals && selectedTerminals.length > 0) {
            selectedTerminals.forEach(terminal => {
                let compTerminal: CompanyTerminalEntity = {
                    id: 0,
                    versionIdentifier: {},
                    companyId: null,
                    operatingTmlId: terminal.id,
                    company: null,
                    terminal: null
                };

                saveCompTerminals.push(compTerminal);
            })
        } else {
            saveCompTerminals.push(EMPTY_COMPANY_TERMINAL_ENTITY);
        }
        if (saveCompTerminals) {
            saveCompTerminals.map(terminal => terminal.companyId = companyId);
        }

        await companyTerminalRepo.saveCompanyTerminal(saveCompTerminals).then((data) => {
            if (data && data.toString().startsWith("Error:")) {
                companyDetailDispatch(prevState => {
                    return {
                        ...prevState,
                        allFormState: { "saveCompContractTmlFailed": data.toString() }
                    };
                });
                return { "saveCompContractTmlFailed": data.toString() };
            } else {
                return companyDetailDispatch(prevState => {
                    return {
                        ...prevState,
                        isShowRightPanel: false,
                        viewState: {
                            ...prevState.viewState,
                            isAdd: false,
                            isRead: true,
                            isEditable: false,
                            isSaveClicked: false,
                            lastEditRowId: '',
                            isSliderOpen: false,
                            allFormState: {},

                            isShowFsp: false,
                            isShowEmptyPool: false,
                            isShowSuppleTerms: false,
                            isShowCustAssign: false,
                            isShowCustInfo: false,
                            isShowFactor: false,
                            isEditCompDtlInfo: false,
                            isShowRejectModal: false,
                            rejectReason: "",
                            dataType: "",
                        }
                    }
                });
            }
        })

    }

    const initialTerminalData = async (compnayId: number) => {
        companyTerminalRepo.searchCompanyTerminal(compnayId).then(data => {
            const terminals: TerminalEntity[] = [];
            for (let i = 0; i < data?.length; i++) {
                if (data[i].terminal) {
                    terminals.push(data[i].terminal as TerminalEntity);
                }
            }

            companyDetailDispatch(prevState => {
                return {
                    ...prevState,
                    companyTerminals: data,

                    companyTerminalState: {
                        ...prevState.companyTerminalState,
                        selectedTerminals: terminals
                    }
                }
            })
        })
    }

    return {
        onMultipleDropdownChange: onMultipleDropdownChange,
        onSave: onSave,
        initialTerminalData: initialTerminalData
    }
}