import { ChargeDetailConstant } from "presentation/constant/ChargeDetailMaintenance/ChargeDetailConstant";
import { CriteriaDirectoryChargeCheckboxOption } from "presentation/constant/ChargeDetailMaintenance/ChargeDetailCriteriaDirectoryCheckboxOption";
import { useChargeDetailVM } from "presentation/hook/ChargeDetailMaintenance/useChargeDetailVM";
import { useChargeDetailTracked } from "presentation/store/ChargeDetailMaintenance/ChargeDetailProvider";
import NbisInputDropDown from "presentation/view/components/NbisInputDropDown";
import { memo, useMemo } from "react";
import { InputField } from "veronica-ui-component/dist/component/core";

const AssignSeqNoForm = () => {
    const CHARGEDETAIL_CONSTANT = ChargeDetailConstant.Charge;
    const [chargeDetailState] = useChargeDetailTracked();
    const selectedChargeDetails = chargeDetailState.selectedChargeDetails;
    const searchSeqCriteria = chargeDetailState.searchSeqCriteria;
    const chargeDetailVM = useChargeDetailVM();

    const enabledCriteria = useMemo(() => CriteriaDirectoryChargeCheckboxOption.find(item => chargeDetailState.enabledSearchCriteria[item.key]), [chargeDetailState.enabledSearchCriteria]);


    const memoBillToCompany = useMemo(() =>
        <div className='flex-row-item'>
            <InputField
                width='330px'
                maxLength={50}
                label={CHARGEDETAIL_CONSTANT.BILL_TO_COMPANY}
                type="text"
                value={selectedChargeDetails[0]?.billToCompany ?? ''}
            />
        </div>
        , [selectedChargeDetails, CHARGEDETAIL_CONSTANT.BILL_TO_COMPANY])

    const memoToSeq = useMemo(() =>
        <div className='flex-row-item'>
            <NbisInputDropDown
                label={CHARGEDETAIL_CONSTANT.TO_SEQ}
                width='330px'
                inputType="freeText"
                mode={'single'}
                sort={false}
                value={searchSeqCriteria.toSeq}
                onChange={(e: any) => {
                    chargeDetailVM.onSeqDropdownChange(e, 'toSeq', searchSeqCriteria, 'searchSeqCriteria')

                }
                }
                options={chargeDetailState.dynamicOptions.seqDropdownOptions}
            />
        </div>
        , [searchSeqCriteria, chargeDetailVM, chargeDetailState.dynamicOptions.seqDropdownOptions, CHARGEDETAIL_CONSTANT.TO_SEQ])

    const memoYourRef = useMemo(() =>
        <div className='flex-row-item'>
            <InputField
                width='330px'
                maxLength={50}
                label={CHARGEDETAIL_CONSTANT.YOUR_REF}
                type="text"
                value={searchSeqCriteria.yourRef ?? ''}
                onChange={(e: any) => chargeDetailVM.onInputTextChange(e, 'yourRef', searchSeqCriteria, 'searchSeqCriteria')} />
        </div>
        , [searchSeqCriteria, chargeDetailVM, CHARGEDETAIL_CONSTANT.YOUR_REF])

    const memoOurRef = useMemo(() =>
        <div className='flex-row-item'>
            <InputField
                width='330px'
                maxLength={50}
                label={CHARGEDETAIL_CONSTANT.OUR_REF}
                type="text"
                value={searchSeqCriteria.ourRef ?? ''}
                onChange={(e: any) => chargeDetailVM.onInputTextChange(e, 'ourRef', searchSeqCriteria, 'searchSeqCriteria')} />
        </div>
        , [searchSeqCriteria, chargeDetailVM, CHARGEDETAIL_CONSTANT.OUR_REF])

    const memoPoNo = useMemo(() =>
        <div className='flex-row-item'>
            <InputField
                width='330px'
                maxLength={50}
                label={CHARGEDETAIL_CONSTANT.PO_NO}
                type="text"
                value={searchSeqCriteria.poNo ?? ''}
                onChange={(e: any) => chargeDetailVM.onInputTextChange(e, 'poNo', searchSeqCriteria, 'searchSeqCriteria')} />
        </div>
        , [searchSeqCriteria, chargeDetailVM, CHARGEDETAIL_CONSTANT.PO_NO])

    const memoSlVesselCode = useMemo(() =>
        <div className='flex-row-item'>
            <InputField
                width='330px'
                maxLength={6}
                style={{ "text-transform": "uppercase" }}
                label={CHARGEDETAIL_CONSTANT.SL_VESSEL_CODE}
                type="text"
                value={searchSeqCriteria.slVesselCode ?? ''}
                onChange={(e: any) => chargeDetailVM.onInputTextChange(e, 'slVesselCode', searchSeqCriteria, 'searchSeqCriteria', true)} />
        </div>
        , [searchSeqCriteria, chargeDetailVM, CHARGEDETAIL_CONSTANT.SL_VESSEL_CODE])

    const memoSlIbVoyageCode = useMemo(() =>
        <div className='flex-row-item'>
            <InputField
                width='330px'
                maxLength={10}
                style={{ "text-transform": "uppercase" }}
                label={CHARGEDETAIL_CONSTANT.SL_IB_VOYAGE_CODE}
                type="text"
                value={searchSeqCriteria.slIbVoyageCode ?? ''}
                onChange={(e: any) => chargeDetailVM.onInputTextChange(e, 'slIbVoyageCode', searchSeqCriteria, 'searchSeqCriteria', true)} />
        </div>
        , [searchSeqCriteria, chargeDetailVM, CHARGEDETAIL_CONSTANT.SL_IB_VOYAGE_CODE])

    const memoSlObVoyageCode = useMemo(() =>
        <div className='flex-row-item'>
            <InputField
                width='330px'
                maxLength={10}
                style={{ "text-transform": "uppercase" }}
                label={CHARGEDETAIL_CONSTANT.SL_OB_VOYAGE_CODE}
                type="text"
                value={searchSeqCriteria.slObVoyageCode ?? ''}
                onChange={(e: any) => chargeDetailVM.onInputTextChange(e, 'slObVoyageCode', searchSeqCriteria, 'searchSeqCriteria', true)} />
        </div>
        , [searchSeqCriteria, chargeDetailVM, CHARGEDETAIL_CONSTANT.SL_OB_VOYAGE_CODE])


    if (!enabledCriteria) return null;

    return <>
        <div className={'flex-row field-row-item'}>
            <div className='flex-row-item'>
                {memoBillToCompany}
            </div>
        </div>
        <div className={'flex-row field-row-item'}>
            <div className='flex-row-item'>
                {memoToSeq}
            </div>
        </div>
        <div className={'flex-row field-row-item'}>
            <div className='flex-row-item'>
                {memoYourRef}
            </div>
            <div className='flex-row-item'>
                {memoOurRef}
            </div>
        </div>
        <div className={'flex-row field-row-item'}>
            <div className='flex-row-item'>
                {memoPoNo}
            </div>
            <div className='flex-row-item'>
                {memoSlVesselCode}
            </div>
        </div>
        <div className={'flex-row field-row-item'}>
            <div className='flex-row-item'>
                {memoSlIbVoyageCode}
            </div>
            <div className='flex-row-item'>
                {memoSlObVoyageCode}
            </div>
        </div>
    </>;
}

export default memo(AssignSeqNoForm);