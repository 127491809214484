import { BillingTypeMappingEntity, EMPTY_BILLING_TYPE_MAPPING_ENTITY } from "domain/entity/BillingTypeMapping/BillingTypeMappingEntity";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";

export interface BillingTypeMappingDropdownOptions {
    chargeTypeDropdownOptions: DropdownProps[],
    subChargeTypeDropdownOptions: { [key: string]: DropdownProps[] },
    opsLineDropdownOptions: DropdownProps[],
    companyCodeDropdownOptions: DropdownProps[],
    cntrSizeDropdownOptions: DropdownProps[],
    cntrTypeDropdownOptions: DropdownProps[],
    shipmentTypeDropdownOptions: DropdownProps[],
    serviceCodeDropdownOptions: DropdownProps[],
    modalityDropdownOptions: DropdownProps[],
    marshalCodeDropdownOptions: DropdownProps[],
    terminalCodeDropdownOptions: DropdownProps[],
    marshallingTypeDropdownOptions: DropdownProps[],
    chargeIndDropdownOptions: DropdownProps[],
    specHandingIndDropdownOptions: DropdownProps[],
    operationTypeDropdownOptions: DropdownProps[],
}
export interface BillingTypeMappingViewChangeState extends BaseViewChangeSate {
}

export interface BillingTypeMappingModel {
    isLoading: boolean,
    isShowEditPanel: boolean,
    isAdd: boolean,
    isEdit: boolean,
    dynamicOptions: BillingTypeMappingDropdownOptions,
    billingTypeMappingList: BillingTypeMappingEntity[],
    selectedRows: BillingTypeMappingEntity[],
    updatedRows: BillingTypeMappingEntity[],
    currentRow: BillingTypeMappingEntity,
    currentEditRow: BillingTypeMappingEntity,
    forceRefresh: boolean,

}

export const EMPTY_BILLING_TYPE_MAPPING_MODEL: BillingTypeMappingModel = {
    isLoading: false,
    isShowEditPanel: false,
    isAdd: false,
    isEdit: false,
    dynamicOptions: {
        chargeTypeDropdownOptions: [],
        subChargeTypeDropdownOptions: {},
        opsLineDropdownOptions: [],
        companyCodeDropdownOptions: [],
        cntrSizeDropdownOptions: [],
        cntrTypeDropdownOptions: [],
        shipmentTypeDropdownOptions: [],
        serviceCodeDropdownOptions: [],
        modalityDropdownOptions: [],
        marshalCodeDropdownOptions: [],
        terminalCodeDropdownOptions: [],
        marshallingTypeDropdownOptions: [],
        chargeIndDropdownOptions: [],
        specHandingIndDropdownOptions: [],
        operationTypeDropdownOptions: []
    },
    billingTypeMappingList: [],
    selectedRows: [],
    updatedRows: [],
    currentRow: { ...EMPTY_BILLING_TYPE_MAPPING_ENTITY },
    currentEditRow: { ...EMPTY_BILLING_TYPE_MAPPING_ENTITY },
    forceRefresh: false
}