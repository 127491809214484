import React, { forwardRef, useState } from 'react';
import type { DynamicProp } from 'veronica-ui-component/dist/common/commonInterface';
import { HPHBadge } from 'veronica-ui-component/dist/component/core/Badge/Badge';
import { HPHRed } from 'veronica-ui-component/dist/component/core/Colors';
import { IconButton } from 'veronica-ui-component/dist/component/core/IconButtons/IconButtons';
import { Lozenges } from 'veronica-ui-component/dist/component/core/Lozenges/Lozenges';
import { StyledInputErrorMsg, StyledInputLabel, StyledInputText, StyledInputWrapper, StyledOptional, StyledRequired } from './styled/NbisInputField.styled';

export interface NbisInputFieldProps extends DynamicProp {
  placeholder?: string;
  type?: 'number' | 'text' | 'password';
  fieldName?: string;
  label?: string;
  helpIcon?: boolean;
  systemIndicator?: boolean;
  required?: boolean;
  optional?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  toolTipText?: string;
  size?: string;
  className?: string;
  width?: string;
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
  onCrossClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  onDataChange?: (fieldName: string, date: any, obj?: any, objName?: string) => void,
  maxLength?: number;
  minLength?: number;
  value?: string | number;
  crossIcon?: boolean;
  id?: string;
  showPassword?: boolean;
}

const NbisInputField = forwardRef<HTMLInputElement, NbisInputFieldProps>(({
  type = 'number',
  fieldName,
  placeholder,
  className,
  label,
  disabled,
  helpIcon,
  systemIndicator,
  required,
  optional,
  maxLength,
  minLength,
  errorMessage,
  toolTipText,
  width,
  onChange,
  onCrossClick,
  onDataChange,
  value,
  crossIcon,
  id,
  showPassword = false,
  ...rest
}, ref) => {
  const [, setActualValue] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActualValue(event.target.value);
    if (onDataChange) {
      onDataChange(fieldName ?? "", event.target.value);
    }
  };

  return <StyledInputWrapper className={className}>
    <StyledInputLabel>
      {label}
      {required && !optional && <StyledRequired>*</StyledRequired>}
      {optional && !required && <StyledOptional><Lozenges variation={'Optional Label'} /></StyledOptional>}
      {helpIcon && (
        <IconButton
          fileName={'Icon-help'}
          size={'small'}
          idIcon={`Icon-help-${id}`}
          toolTipPlacement={'right'}
          toolTipArrow={false}
          disabled={false}
          toolTipText={toolTipText}
          {...rest}
        />
      )}
      {systemIndicator && <HPHBadge value='s' color='Aqua-Green' />}
    </StyledInputLabel>
    <StyledInputText
      ref={ref}
      placeholder={placeholder}
      type={(!showPassword && type === 'password') ? 'password' : ((showPassword && type === 'password') ? 'text' : type)}
      disabled={disabled}
      value={value}
      onChange={handleChange}
      maxLength={maxLength}
      minLength={minLength}
      style={{ width, ...(errorMessage ? { borderColor: HPHRed } : {}) }}
      autoComplete="off"
      {...rest}
    />
    {crossIcon && (
      <IconButton
        className='Icon-cross'
        disabled={disabled}
        tooltipDisable
        fileName='Icon-cross'
        size='small'
        onClick={onCrossClick}
      />
    )}
    <StyledInputErrorMsg>{errorMessage}</StyledInputErrorMsg>
  </StyledInputWrapper>
});

export { NbisInputField };

