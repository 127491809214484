export enum E_ArpitaOperation {
  NEW = 'new_template',
  NEW_DATATABLE = 'create_datatable',
  NEW_TEMPLATE_FROM_SELECTION = 'create_template_from_selection',
  MORE = 'more',
  OPEN = 'OPEN',
};

export enum E_Type_Of_Event {
  MODAL_EVENT = 'onModalEventChange',
  ARPITA_EVENT = 'onArpitaTemplateChange',
  CHARGE_DATA_SEARCH_EVENT = 'onChargeDataSearchChange',
  CHARGE_HEADER_SEARCH_EVENT = 'onChargeHeaderSearchChange',
  DOCUMENT_SEARCH_EVENT = 'onDocumentSearchChange',
  DOCUMENT_ITEM_SEARCH_EVENT = 'onDocumentItemSearchChange',
  DOCUMENT_ENQUIRY_EVENT = 'onDocumentEnquiryChange',
  PROPOSAL_ENQUIRY_EVENT = 'onProposalEnquiryChange',
  NOTIFICATION_MESSAGE_EVENT = 'onNotificationMessageChange',
  MAIN_VIEW_CHANGE_EVENT = 'onMainViewChange',
  BU_PROFILE_CHANGE_EVENT = 'onBuProfileChange',
  CHARGE_HEADER_DETAIL_EVENT = 'goToChargeDetail',
  CHARGE_DETAIL_REDIRECT_EVENT = 'onChargeDetailRedirect',
  CHARGE_DETAIL_REDIRECT_EVENT_INITIAL = 'onChargeDetailRedirectInitial',
  MANUAL_CHARGE_DETAIL_REDIRECT_EVENT_INITIAL = 'onManualChargeDetailRedirectInitial',
  NON_OPS_DETAIL_REDIRECT_EVENT_INITIAL = 'onNonOpsDetailRedirectInitial',
  DELIVERY_SUMMARY_REDIRECT_EVENT = 'onDeliverySummaryRedirect',
  CHARGE_ITEM_REDIRECT_EVENT_INITIAL = 'onChargeItemRedirectInitial',
  CHARGE_HEADER_REDIRECT_EVENT = 'onChargeHeaderRedirect',
  CHARGE_HEADER_REDIRECT_EVENT_INITIAL = 'onChargeHeaderRedirectInitial',
  CHANGE_KEY_DATA_REDIRECT_EVENT = 'onChangeKeyDataRedirect',
  DOCUMENT_ENQUIRY_REDIRECT_EVENT = 'onDocumentEnquiryDetailRedirect',
  DOCUMENT_ENQUIRY_DETAIL_REDIRECT_EVENT_INITIAL = 'onDocumentEnquiryDetailRedirectInitial',
  PROPOSAL_ENQUIRY_REFRESH_EVENT = 'onProposalEnquiryTableRefresh',
  CREDIT_NOTE_MAINT_REDIRECT_EVENT = 'onCreditNoteMaintRedirect',
  CREDIT_NOTE_MAINT_REDIRECT_EVENT_INITIAL = 'onCreditNoteMaintRedirectInitial',
}

export enum E_Custom_Dispatch_Event {
  // Modal Action
  MODAL_OPEN = 'MODAL_OPEN',
  MODAL_CLOSE = 'MODAL_CLOSE',
  MODAL_FULL_SCREEN_MODE = 'MODAL_FULL_SCREEN_MODE',

  TEMPLATE_SHOW = 'TEMPLATE_SHOW',
  TEMPLATE_LIST_SHOW = 'TEMPLATE_LIST_SHOW',
  CLOSE_TEMPLATE = 'CLOSE_TEMPLATE',
  SAVE_UPDATE_TEMPLATE = 'SAVE_UPDATE_TEMPLATE',
  OPEN_FROM_MORE = 'OPEN_FROM_MORE',
  AUTO_REFRESH = 'AUTO_REFRESH',
  CELL_UPDATE_ONLY = 'CELL_UPDATE_ONLY',
  ARPITA_CREATE_DATATABLE = 'ARPITA_CREATE_DATATABLE',
  ARPITA_CREATE_TEMPLATE_FROM_SELECTTION = 'ARPITA_CREATE_TEMPLATE_FROM_SELECTTION',

  // CHARGE DATA Event Action
  CHARGE_DATA_MODAL_MAXIMIZE = 'CHARGE_DATA_MODAL_MAXIMIZE',
  CHARGE_DATA_MODAL_BACK_TO_LIST = 'CHARGE_DATA_MODAL_BACK_TO_LIST',
  CHARGE_DATA_EDIT_CRITERIA = 'CHARGE_DATA_EDIT_CRITERIA',
  CHARGE_DATA_EDIT_CRITERIA_BACK = 'CHARGE_DATA_EDIT_CRITERIA_BACK',
  CHARGE_DATA_HIDE_RIGHT_PANEL = 'CHARGE_DATA_HIDE_RIGHT_PANEL',
  CHARGE_DATA_PREFERENCE_ACTION = 'CHARGE_DATA_PREFERENCE_ACTION',
  CHARGE_DATA_PREFERENCE_GET = 'CHARGE_DATA_PREFERENCE_GET',
  CHARGE_DATA_OPEN_DIRECTORY = 'CHARGE_DATA_OPEN_DIRECTORY',
  CHARGE_DATA_PREFERENCE_OPEN = 'CHARGE_DATA_PREFERENCE_OPEN',
  CHARGE_DATA_PREFERENCE_DELETE = 'CHARGE_DATA_PREFERENCE_DELETE',
  CHARGE_DATA_PREFERENCE_SAVE_NEW = 'CHARGE_DATA_PREFERENCE_SAVE_NEW',
  CHARGE_DATA_PREFERENCE_SAVE_SEARCH = 'CHARGE_DATA_PREFERENCE_SAVE_SEARCH',
  CHARGE_DATA_PREFERENCE_CURRENT_UPDATE = 'CHARGE_DATA_PREFERENCE_CURRENT_UPDATE',
  CONTAINER_DETAIL_SHOW = 'CONTAINER_DETAIL_SHOW',
  CONTAINER_DETAIL_HIDE = 'CONTAINER_DETAIL_HIDE',
  CONTAINER_DETAIL_EDIT_MODE_ON = 'CONTAINER_DETAIL_EDIT_MODE_ON',
  CONTAINER_DETAIL_EDIT_MODE_OFF = 'CONTAINER_DETAIL_EDIT_MODE_OFF',
  CONTAINER_DETAIL_SHOW_DETAIL = 'CONTAINER_DETAIL_SHOW_DETAIL',

  CHARGE_HEADER_MODAL_MAXIMIZE = 'CHARGE_HEADER_MODAL_MAXIMIZE',
  CHARGE_HEADER_MODAL_BACK_TO_LIST = 'CHARGE_HEADER_MODAL_BACK_TO_LIST',
  CHARGE_HEADER_EDIT_CRITERIA = 'CHARGE_HEADER_EDIT_CRITERIA',
  CHARGE_HEADER_EDIT_CRITERIA_BACK = 'CHARGE_HEADER_EDIT_CRITERIA_BACK',
  CHARGE_HEADER_HIDE_RIGHT_PANEL = 'CHARGE_HEADER_HIDE_RIGHT_PANEL',
  CHARGE_HEADER_PREFERENCE_ACTION = 'CHARGE_HEADER_PREFERENCE_ACTION',
  CHARGE_HEADER_PREFERENCE_GET = 'CHARGE_HEADER_PREFERENCE_GET',
  CHARGE_HEADER_OPEN_DIRECTORY = 'CHARGE_HEADER_OPEN_DIRECTORY',
  CHARGE_HEADER_PREFERENCE_OPEN = 'CHARGE_HEADER_PREFERENCE_OPEN',
  CHARGE_HEADER_PREFERENCE_DELETE = 'CHARGE_HEADER_PREFERENCE_DELETE',
  CHARGE_HEADER_PREFERENCE_SAVE_NEW = 'CHARGE_HEADER_PREFERENCE_SAVE_NEW',
  CHARGE_HEADER_PREFERENCE_SAVE_SEARCH = 'CHARGE_HEADER_PREFERENCE_SAVE_SEARCH',
  CHARGE_HEADER_PREFERENCE_CURRENT_UPDATE = 'CHARGE_HEADER_PREFERENCE_CURRENT_UPDATE',

  // Charge Header Maintenance Action
  CHARGE_HEADER_GO_TO_DETAIL = 'CHARGE_HEADER_GO_TO_DETAIL',

  // Notification Message Action
  NOTIFICATION_MESSAGE_SHOW = 'NOTIFICATION_MESSAGE_SHOW',

  //common event action
  MAIN_VIEW_SHOW_DETAIL = "MAIN_VIEW_SHOW_DETAIL",
  MAIN_VIEW_HIDE_DETAIL = "MAIN_VIEW_HIDE_DETAIL",
  MAIN_VIEW_SHOW_EDIT = "MAIN_VIEW_SHOW_EDIT",
  MAIN_VIEW_CLOSE_EDIT = "MAIN_VIEW_CLOSE_EDIT",
  MAIN_VIEW_SHOW_ADDITIONAL_EDIT = 'MAIN_VIEW_SHOW_ADDITIONAL_EDIT',

  //BU Profile
  BU_PROFILE_SAVE = "BU_PROFILE_SAVE",
  BU_PROFILE_GET = "BU_PROFILE_GET",
  BU_PROFILE_COMPANY_UPDATE = "BU_PROFILE_COMPANY_UPDATE",

  //redirect charge detail from charge data
  REDIRECT_CHARGE_DETAIL_FROM_CHARGE_ITEM_SEARCH = "REDIRECT_CHARGE_DETAIL_FROM_CHARGE_ITEM_SEARCH",
  REDIRECT_MANUAL_CHARGE_DETAIL_FROM_CHARGE_ITEM_SEARCH = "REDIRECT_MANUAL_CHARGE_DETAIL_FROM_CHARGE_ITEM_SEARCH",
  REDIRECT_NON_OPS_DETAIL_FROM_CHARGE_ITEM_SEARCH = "REDIRECT_NON_OPS_DETAIL_FROM_CHARGE_ITEM_SEARCH",
  REDIRECT_CHARGE_ITEM_FROM_DELIVERY_SUMMARY = "REDIRECT_CHARGE_ITEM_FROM_DELIVERY_SUMMARY",
  REDIRECT_CHARGE_DETAIL_FROM_DELIVERY_SUMMARY = "REDIRECT_CHARGE_DETAIL_FROM_DELIVERY_SUMMARY",
  REDIRECT_CHARGE_DETAIL_FROM_VESSEL_SUMMARY = "REDIRECT_CHARGE_DETAIL_FROM_VESSEL_SUMMARY",
  REDIRECT_CHARGE_DETAIL_FROM_INBOUND_SUMMARY = "REDIRECT_CHARGE_DETAIL_FROM_INBOUND_SUMMARY",
  REDIRECT_CHARGE_HEADER_FROM_VESSEL_SUMMARY = "REDIRECT_CHARGE_HEADER_FROM_VESSEL_SUMMARY",
  REDIRECT_CHARGE_HEADER_FROM_INBOUND_SUMMARY = "REDIRECT_CHARGE_HEADER_FROM_INBOUND_SUMMARY",
  REDIRECT_CHARGE_DETAIL_FROM_CHANGE_KEY_DATA = "REDIRECT_CHARGE_DETAIL_FROM_CHANGE_KEY_DATA",
  REDIRECT_CHARGE_HEADER_FROM_DELIVERY_SUMMARY = "REDIRECT_CHARGE_HEADER_FROM_DELIVERY_SUMMARY",
  REDIRECT_CHANGE_KEY_DATA_FROM_CHARGE_DETAIL = "REDIRECT_CHANGE_KEY_DATA_FROM_CHARGE_DETAIL",
  REDIRECT_CREDIT_NOTE_HEADER_MAINTENANCE = "REDIRECT_CREDIT_NOTE_HEADER_MAINTENANCE",

  // DOC
  REDIRECT_DOCUMENT_ENQUIRY_DETAIL_FROM_HEADER = "REDIRECT_DOCUMENT_ENQUIRY_DETAIL_FROM_HEADER",


  // DOCUMENT ENQUIRY Event Action
  DOCUMENT_ENQUIRY_MODAL_MAXIMIZE = 'DOCUMENT_ENQUIRY_MODAL_MAXIMIZE',
  DOCUMENT_ENQUIRY_MODAL_BACK_TO_LIST = 'DOCUMENT_ENQUIRY_MODAL_BACK_TO_LIST',
  DOCUMENT_ENQUIRY_EDIT_CRITERIA = 'DOCUMENT_ENQUIRY_EDIT_CRITERIA',
  DOCUMENT_ENQUIRY_EDIT_CRITERIA_BACK = 'DOCUMENT_ENQUIRY_EDIT_CRITERIA_BACK',
  DOCUMENT_ENQUIRY_HIDE_RIGHT_PANEL = 'DOCUMENT_ENQUIRY_HIDE_RIGHT_PANEL',
  DOCUMENT_ENQUIRY_PREFERENCE_ACTION = 'DOCUMENT_ENQUIRY_PREFERENCE_ACTION',
  DOCUMENT_ENQUIRY_PREFERENCE_GET = 'DOCUMENT_ENQUIRY_PREFERENCE_GET',
  DOCUMENT_ENQUIRY_OPEN_DIRECTORY = 'DOCUMENT_ENQUIRY_OPEN_DIRECTORY',
  DOCUMENT_ENQUIRY_PREFERENCE_OPEN = 'DOCUMENT_ENQUIRY_PREFERENCE_OPEN',
  DOCUMENT_ENQUIRY_PREFERENCE_DELETE = 'DOCUMENT_ENQUIRY_PREFERENCE_DELETE',
  DOCUMENT_ENQUIRY_PREFERENCE_SAVE_NEW = 'DOCUMENT_ENQUIRY_PREFERENCE_SAVE_NEW',
  DOCUMENT_ENQUIRY_PREFERENCE_SAVE_SEARCH = 'DOCUMENT_ENQUIRY_PREFERENCE_SAVE_SEARCH',
  DOCUMENT_ENQUIRY_PREFERENCE_CURRENT_UPDATE = 'DOCUMENT_ENQUIRY_PREFERENCE_CURRENT_UPDATE',
  DOCUMENT_ENQUIRY_DETAIL_SHOW = 'DOCUMENT_ENQUIRY_DETAIL_SHOW',
  DOCUMENT_ENQUIRY_DETAIL_HIDE = 'DOCUMENT_ENQUIRY_DETAIL_HIDE',
  DOCUMENT_ENQUIRY_DETAIL_EDIT_MODE_ON = 'DOCUMENT_ENQUIRY_DETAIL_EDIT_MODE_ON',
  DOCUMENT_ENQUIRY_DETAIL_EDIT_MODE_OFF = 'DOCUMENT_ENQUIRY_DETAIL_EDIT_MODE_OFF',
  DOCUMENT_ENQUIRY_DETAIL_SHOW_DETAIL = 'DOCUMENT_ENQUIRY_DETAIL_SHOW_DETAIL',
  DOCUMENT_ENQUIRY_PRINT = 'DOCUMENT_ENQUIRY_PRINT',
  DOCUMENT_ENQUIRY_ENABLE_PRINT = 'DOCUMENT_ENQUIRY_ENABLE_PRINT',

  //Proposal Enquiry
  PROPOSAL_ENQUIRY_EDIT_CRITERIA = 'PROPOSAL_ENQUIRY_EDIT_CRITERIA',
  PROPOSAL_ENQUIRY_OPEN_DIRECTORY = 'PROPOSAL_ENQUIRY_OPEN_DIRECTORY',
  PROPOSAL_ENQUIRY_UPDATE = 'PROPOSAL_ENQUIRY_UPDATE',
  PROPOSAL_ENQUIRY_DETAIL_SHOW = 'PROPOSAL_ENQUIRY_DETAIL_SHOW',
  PROPOSAL_ENQUIRY_DETAIL_HIDE = 'PROPOSAL_ENQUIRY_DETAIL_HIDE',
  PROPOSAL_ENQUIRY_EDIT_CRITERIA_BACK = 'PROPOSAL_ENQUIRY_EDIT_CRITERIA_BACK',
  PROPOSAL_ENQUIRY_MODAL_BACK_TO_LIST = 'PROPOSAL_ENQUIRY_MODAL_BACK_TO_LIST',
  PROPOSAL_ENQUIRY_HIDE_RIGHT_PANEL = 'PROPOSAL_ENQUIRY_HIDE_RIGHT_PANEL',
  PROPOSAL_ENQUIRY_ENABLE_ACTIVATE = 'PROPOSAL_ENQUIRY_ENABLE_ACTIVATE',
  PROPOSAL_ENQUIRY_ENABLE_DEACTIVATE = 'PROPOSAL_ENQUIRY_ENABLE_DEACTIVATE',
  PROPOSAL_ENQUIRY_ENABLE_ACTIVATE_BUTTON = 'PROPOSAL_ENQUIRY_ENABLE_ACTIVATE_BUTTON',

  PROPOSAL_ENQUIRY_PREFERENCE_ACTION = 'PROPOSAL_ENQUIRY_PREFERENCE_ACTION',
  PROPOSAL_ENQUIRY_PREFERENCE_GET = 'PROPOSAL_ENQUIRY_PREFERENCE_GET',
  PROPOSAL_ENQUIRY_PREFERENCE_OPEN = 'PROPOSAL_ENQUIRY_PREFERENCE_OPEN',
  PROPOSAL_ENQUIRY_PREFERENCE_DELETE = 'PROPOSAL_ENQUIRY_PREFERENCE_DELETE',
  PROPOSAL_ENQUIRY_PREFERENCE_SAVE_NEW = 'PROPOSAL_ENQUIRY_PREFERENCE_SAVE_NEW',
  PROPOSAL_ENQUIRY_PREFERENCE_SAVE_SEARCH = 'PROPOSAL_ENQUIRY_PREFERENCE_SAVE_SEARCH',
  PROPOSAL_ENQUIRY_PREFERENCE_CURRENT_UPDATE = 'PROPOSAL_ENQUIRY_PREFERENCE_CURRENT_UPDATE',
}

export function customDispatchEvent(typeOfAction: E_Type_Of_Event, action: E_Custom_Dispatch_Event, data: any) {
  document.dispatchEvent(new CustomEvent(typeOfAction, { detail: { action, data } }));
}

export function customEventListener(eventType: E_Type_Of_Event, listener: EventListener) {
  document.addEventListener(eventType, listener);

  return () => {
    document.removeEventListener(eventType, listener);
  };
}