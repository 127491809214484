import { useChargeDetailVM } from "presentation/hook/ChargeDetailMaintenance/useChargeDetailVM";
import { ChargeDetailModel } from "presentation/model/ChargeDetailMaintenance/ChargeDetailModel";
import NbisInputDropDown from "presentation/view/components/NbisInputDropDown";

export const OperatingTmlAccordion = ({ chargeDetailState }: { chargeDetailState: ChargeDetailModel }) => {
    const chargeBatchUpdate = chargeDetailState.chargeBatchUpdate
    const chargeDetailVM = useChargeDetailVM();
    return (
        <>
            <div className={'im-flex-row'} style={{ marginBottom: '24px' }}>
                <div className='im-flex-row-item'>
                    <NbisInputDropDown
                        label='Operating Tml'
                        width='330px'
                        inputType="freeText"
                        mode={"single"}
                        value={chargeBatchUpdate.operatingTml}
                        onChange={(e: any) => chargeDetailVM.onDropdownChange(e, 'operatingTml', chargeBatchUpdate, 'chargeBatchUpdate')}
                        options={chargeDetailState.dynamicOptions.operatingTmlDropdownOptions} />
                </div>

            </div>
        </>
    )
}