import { useRequisitionFormVM } from "presentation/hook/RequisitionForm/useRequisitionFormVM";
import { useRequisitionFormMaintenanceTracked } from "presentation/store/RequisitionForm/RequisitionFormMaintenanceProvider";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useEffect, useMemo, useState } from "react";
import { Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import { RequisitionFormMgrFormPanel } from "./Form/RequisitionFormMgrFormPanel";
import RequisitionFormDetailTablePanel from "./Table/RequisitionFormDetailTablePanel";


const RequisitionFormDetailRightPanel: React.FC = () => {
    const [requisitionFormState] = useRequisitionFormMaintenanceTracked();
    const requisitionFormVM = useRequisitionFormVM();

    const { currentRequisitionForm } = requisitionFormState;
    const [isLoading, setIsLoading] = useState(false);
    const { isSliderOpen/*isAdd, isEditable*/ } = requisitionFormState.masterState;

    useEffect(() => {
        if (isSliderOpen) {
            const initialScreen = async () => {
                setIsLoading(true);
                try {
                    const results = await Promise.allSettled([
                        requisitionFormVM.loadDetailDropdownOption(),
                        currentRequisitionForm?.reqFormNo && requisitionFormVM.getReqFormByReqFormNo(currentRequisitionForm.reqFormNo),
                    ]);
                    results.forEach((result, index) => {
                        if (index === 0 && result.status === 'fulfilled') {

                        }
                    })
                } catch (error) {
                    setIsLoading(false);
                }
            }
            initialScreen().then(async () => {
                setIsLoading(false)
            }).catch(error => {
                setIsLoading(false)
            });
        }

    }, [currentRequisitionForm.reqFormNo, isSliderOpen, requisitionFormVM])

    const memoReqFormCustTable = useMemo(() => {
        return <div style={{ marginLeft: "15px" }}><RequisitionFormDetailTablePanel /></div>
    }, [])

    const memoReqFormMgrFormPanel = useMemo(() => {
        return <RequisitionFormMgrFormPanel />
    }, [])

    return <><div className="main-comp-wrapper">
        {(isLoading) && <Loader Indicator="Spinner" size="Medium" />}
        <TableWrapper>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader style={{ width: '100%', marginTop: "10px" }}>
                        {memoReqFormMgrFormPanel}
                    </Sidebarheader>
                </div>
            </div>

            {memoReqFormCustTable}
        </TableWrapper>
    </div></>;
}

export default memo(RequisitionFormDetailRightPanel);
