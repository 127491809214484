export const StandardProposalConstant = {
    Agreement:{
        AGREEMENT: "Agreement",
    },
    Title: {
        STANDARD_PROPOSAL_ITEM: "Standard Proposal Item",
        STANDARD_PROPOSAL_LIST: "Standard Proposal List",
        STANDARD_PROPOSAL: "Standard Proposal",
        COPY_BUT: "Copy",
        RENEW_BUT: "Renew",
        DELETE_BUT: "Delete",
        SUBMIT_BUT: "Submit",
        UNCONFIRM_BUT: "Unconfirm",
        REJECT_BUT: "Reject",
        APPROVE_BUT: "Approve",
        REJECT_REASON: "Reject Reason",

    },

    Table: {
        ALL_BUT: "All",
        REJECTED_BUT: "Rejected",
        PENDING_APPROVAL_BUT: "Pending Approval",
        COMPANY_CODE: "Company Code",
        COPY_TO_COMPANY_CODE: "Copy To Company Code",
        PROPOSAL_NO: "Proposal No.",
        OPERATING_TML: "Operating Tml.",
        EFFECTIVE_DATE: "Effective Date",
        EFFECTIVE_DATE_RANGE: "Effective Date Range",
        EXPIRY_DATE: "Expiry Date",
        CURRENCY: "Currency",
        PROPOSAL_BY: "Proposal By",
        TARIFF_NATURE: "Tariff Nature",
        CONFIRMED_DATE: "Confirmed Date",
        STATUS: "Status",
        REJECT_REASON: "Reject Reason",
        REMARKS: "Remarks",
        SEQ: "Seq",
        TARIFF_TYPE: "Tariff Type",
        TARIFF_CODE: "Tariff Code",
        FORWARDER_CODE: "Forwarder Code",
        MASTER: "Master",
        TARIFF_CODE_DESC: "Tariff Code Desc",
        TIER_NO: "TIER NO",
        CYCLE: "CYCLE",
        FOR_EVERY: "FOR EVERY",
        UOM: "UOM",
        RATE: "RATE",
        MINIMUM: "MINIMUM RATE",
        SURCHARGE: "SURCHARGE",
        SURCHARGE_TYPE: "SURCHARGE TYPE",
        ADDITIONAL_UOM: "ADDITIONAL UOM",
        ADDITIONAL_RATE: "ADDITIONAL RATE",
        SHIFT_CODE: "Shift Code",
        SURCHARGE_P: "Surcharge %",
        TIER_VALUE: "Tier",
        IS_VALUE: "IS",
        CHARGE_PERCENTAGE: "% Change",
        ROUND_METHOD: "Rounding Method",
        DECIMAL_PLACE: "Decimal Place",
    },

    Search: {
        SEARCH_CRITERIA: "Search Criteria",
        COMPANY_CODE: "Company Code",
        PROPOSAL_NO: "Proposal No.",
        OPERATING_TML: "Operating Tml.",
        STATUS: "Status",
        EFFECTIVE_DATE_RANGE: "Effective Date Range",
    }

}

export const standardProposalRequiredFieldList: string[] = [
    'companyCode',
    'effectiveDate',
    'expiryDate',
    'currencyCode',
    'proposalCreatedByMethod',
    'tariffNature',
    'tariffNatureList',
    'tariffType',
    'tariffCode',
];

export const standardProposalTariffItemRequiredFieldList: string[] = [
    'tariffType',
    'tariffCode',
];

export const standardProposalTierRequiredFieldList: string[] = [
    'cycle',
    'forevery',
    'uom',
    'rate',
    'minimum',
];

export const standardProposalIsRequiredFieldList: string[] = [
    'shiftCode',
    'percentage',
];