import { ChargeConstant } from "presentation/constant/ChargeHeader/ChargeConstant";
import { useVesselSummaryMaintenanceVM } from "presentation/hook/ChargeHeaderMaintenance/useVesselSummaryMaintenanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useChargeHeaderMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/ChargeHeaderMaintenanceProvider";
import { useVesselSummaryMaintenanceTracked } from "presentation/store/ChargeHeaderMaintenance/VesselSummaryMaintenanceProvider";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { useCallback, useMemo } from "react";
import { FieldType, HPHButton, HPHInputTextarea, InputField } from "veronica-ui-component/dist/component/core";
import { SidebarTitle, Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

export const VesselSummaryTDRPanel = () => {
    const messageBarVM = useMessageBarVM();
    const [chargeHdrState] = useChargeHeaderMaintenanceTracked();
    const VSL_SUMMARY_CONSTANT = ChargeConstant.VesselSummary;
    const vslSummaryVM = useVesselSummaryMaintenanceVM();
    const [vslSumMainState] = useVesselSummaryMaintenanceTracked();
    const tdrData = vslSumMainState.tdrData;
    const editingHdr = chargeHdrState.currentChargeHeader;

    const dsFullInNbis = vslSumMainState.vesselSummaryData?.dischargeFullTotal;
    const dsEmptyInNbis = vslSumMainState.vesselSummaryData?.dischargeEmptyTotal;
    const ldFullInNbis = vslSumMainState.vesselSummaryData?.loadingFullTotal;
    const ldEmptyInNbis = vslSumMainState.vesselSummaryData?.loadingEmptyTotal;
    const shiftInNbis = vslSumMainState.currentSummaryHeader.shiftCount;
    const restowInNbis = chargeHdrState.searchCriteria.hdrGroupName !== 'SHIPBOUNDEDINPERIOD' ? 0 : vslSumMainState.currentSummaryHeader.restowCount;

    const dsFullTotal = (Number(tdrData.dischargeFullSize20) || 0)
        + (Number(tdrData.dischargeFullSize40) || 0)
        + (Number(tdrData.dischargeFullSize45) || 0)
        + (Number(tdrData.dischargeFullSize48) || 0)
        + (Number(tdrData.dischargeFullSize53) || 0);

    const dsEmptyTotal = (Number(tdrData.dischargeEmptySize20) || 0)
        + (Number(tdrData.dischargeEmptySize40) || 0)
        + (Number(tdrData.dischargeEmptySize45) || 0)
        + (Number(tdrData.dischargeEmptySize48) || 0)
        + (Number(tdrData.dischargeEmptySize53) || 0);

    const ldFullTotal = (Number(tdrData.loadingFullSize20) || 0)
        + (Number(tdrData.loadingFullSize40) || 0)
        + (Number(tdrData.loadingFullSize45) || 0)
        + (Number(tdrData.loadingFullSize48) || 0)
        + (Number(tdrData.loadingFullSize53) || 0);

    const ldEmptyTotal = (Number(tdrData.loadingEmptySize20) || 0)
        + (Number(tdrData.loadingEmptySize40) || 0)
        + (Number(tdrData.loadingEmptySize45) || 0)
        + (Number(tdrData.loadingEmptySize48) || 0)
        + (Number(tdrData.loadingEmptySize53) || 0);

    const rsTotal = (Number(tdrData.restowSize20) || 0)
        + (Number(tdrData.restowSize40) || 0)
        + (Number(tdrData.restowSize45) || 0)
        + (Number(tdrData.restowSize48) || 0)
        + (Number(tdrData.restowSize53) || 0);

    const shiftTotal = (Number(tdrData.shiftSize20) || 0)
        + (Number(tdrData.shiftSize40) || 0)
        + (Number(tdrData.shiftSize45) || 0)
        + (Number(tdrData.shiftSize48) || 0)
        + (Number(tdrData.shiftSize53) || 0);


    const dsFullDiff = (Number(dsFullInNbis) || 0) - dsFullTotal;
    const dsEmptyDiff = (Number(dsEmptyInNbis) || 0) - dsEmptyTotal;
    const ldFullDiff = (Number(ldFullInNbis) || 0) - ldFullTotal;
    const ldEmptyDiff = (Number(ldEmptyInNbis) || 0) - ldEmptyTotal;
    const restowDiff = (Number(restowInNbis) || 0) - rsTotal;
    const shiftDiff = (Number(shiftInNbis) || 0) - shiftTotal;


    const memoDsFull20 = useMemo(() =>
        <div className='flex-row-item' style={{ width: '60px' }}>
            <InputField
                width='60px'
                maxLength={999}
                label={VSL_SUMMARY_CONSTANT.SIZE_20}
                type="number"
                value={tdrData.dischargeFullSize20 ?? ''}
                disabled={false}
                onChange={(e) => vslSummaryVM.onInputTextChange(e, 'dischargeFullSize20')}
            />
        </div>
        , [VSL_SUMMARY_CONSTANT.SIZE_20, tdrData.dischargeFullSize20, vslSummaryVM])


    const memoDsFull40 = useMemo(() =>
        <div className='flex-row-item' style={{ width: '60px' }}>
            <InputField
                width='60px'
                maxLength={999}
                label={VSL_SUMMARY_CONSTANT.SIZE_40}
                type="number"
                value={tdrData.dischargeFullSize40 ?? ''}
                disabled={false}
                onChange={(e) => vslSummaryVM.onInputTextChange(e, 'dischargeFullSize40')}
            />
        </div>
        , [VSL_SUMMARY_CONSTANT.SIZE_40, tdrData.dischargeFullSize40, vslSummaryVM])


    const memoDsFull45 = useMemo(() =>
        <div className='flex-row-item' style={{ width: '60px' }}>
            <InputField
                width='60px'
                maxLength={999}
                label={VSL_SUMMARY_CONSTANT.SIZE_45}
                type="number"
                value={tdrData.dischargeFullSize45 ?? ''}
                disabled={false}
                onChange={(e) => vslSummaryVM.onInputTextChange(e, 'dischargeFullSize45')}
            />
        </div>
        , [VSL_SUMMARY_CONSTANT.SIZE_45, tdrData.dischargeFullSize45, vslSummaryVM])


    const memoDsFull48 = useMemo(() =>
        <div className='flex-row-item' style={{ width: '60px' }}>
            <InputField
                width='60px'
                maxLength={999}
                label={VSL_SUMMARY_CONSTANT.SIZE_48}
                type="number"
                value={tdrData.dischargeFullSize48 ?? ''}
                disabled={false}
                onChange={(e) => vslSummaryVM.onInputTextChange(e, 'dischargeFullSize48')}
            />
        </div>
        , [VSL_SUMMARY_CONSTANT.SIZE_48, tdrData.dischargeFullSize48, vslSummaryVM])


    const memoDsFull53 = useMemo(() =>
        <div className='flex-row-item' style={{ width: '60px' }}>
            <InputField
                width='60px'
                maxLength={999}
                label={VSL_SUMMARY_CONSTANT.SIZE_53}
                type="number"
                value={tdrData.dischargeFullSize53 ?? ''}
                disabled={false}
                onChange={(e) => vslSummaryVM.onInputTextChange(e, 'dischargeFullSize53')}
            />
        </div>
        , [VSL_SUMMARY_CONSTANT.SIZE_53, tdrData.dischargeFullSize53, vslSummaryVM])


    // const memoDsFullTotal = useMemo(() =>
    //     <div className='flex-row-item' style={{width: '60px'}}>
    //         <InputField 
    //             width='60px'
    //             maxLength={999}
    //             label={VSL_SUMMARY_CONSTANT.TOTAL}
    //             type="number"
    //             value={dsFullTotal} 
    //             disabled={true}
    //             />
    //     </div>
    // , [VSL_SUMMARY_CONSTANT.TOTAL, dsFullTotal])


    // const memoDsFullInNbis = useMemo(() =>
    //     <div className='flex-row-item' style={{width: '60px'}}>
    //         <InputField 
    //             width='60px'
    //             maxLength={999}
    //             label={VSL_SUMMARY_CONSTANT.IN_NBIS}
    //             type="number"
    //             value={dsFullInNbis??0} 
    //             disabled={true}
    //             />
    //     </div>
    // , [VSL_SUMMARY_CONSTANT.IN_NBIS, dsFullInNbis])


    // const memoDsFullDiff = useMemo(() =>
    //     <div className='flex-row-item' style={{width: '60px'}}>
    //         <InputField 
    //             width='60px'
    //             maxLength={999}
    //             label={VSL_SUMMARY_CONSTANT.DIFFERENT}
    //             type="number"
    //             value={dsFullDiff} 
    //             disabled={true}
    //             />
    //     </div>
    // , [VSL_SUMMARY_CONSTANT.DIFFERENT, dsFullDiff])

    const memoDsFullTotal = useMemo(() =>
        <div className='flex-row-item' style={{ width: "60px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={dsFullTotal.toString()}
                fieldValue={dsFullTotal}
                fieldLabel={VSL_SUMMARY_CONSTANT.TOTAL}
                isSaveClicked={true}
                fieldType={FieldType.TEXT}
                fieldKey={''}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={() => { }} />
        </div>
        , [VSL_SUMMARY_CONSTANT.TOTAL, dsFullTotal])

    const memoDsFullInNbis = useMemo(() =>
        <div className='flex-row-item' style={{ width: "60px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={dsFullInNbis?.toString()}
                fieldValue={dsFullInNbis ?? 0}
                fieldLabel={VSL_SUMMARY_CONSTANT.IN_NBIS}
                isSaveClicked={true}
                fieldType={FieldType.TEXT}
                fieldKey={''}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={() => { }} />
        </div>
        , [VSL_SUMMARY_CONSTANT.IN_NBIS, dsFullInNbis])

    const memoDsFullDiff = useMemo(() =>
        <div className='flex-row-item' style={{ width: "60px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={dsFullDiff.toString()}
                fieldValue={dsFullDiff.toString()}
                fieldLabel={VSL_SUMMARY_CONSTANT.DIFFERENT}
                isSaveClicked={true}
                fieldType={FieldType.TEXT}
                fieldKey={''}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={() => { }} />
        </div>
        , [VSL_SUMMARY_CONSTANT.DIFFERENT, dsFullDiff])


    // Discharge Empty Row
    const memoDsEmpty20 = useMemo(() =>
        <div className='flex-row-item' style={{ width: '60px' }}>
            <InputField
                width='60px'
                maxLength={999}
                label={''}
                type="number"
                value={tdrData.dischargeEmptySize20 ?? ''}
                disabled={false}
                onChange={(e) => vslSummaryVM.onInputTextChange(e, 'dischargeEmptySize20')}
            />
        </div>
        , [tdrData.dischargeEmptySize20, vslSummaryVM])


    const memoDsEmpty40 = useMemo(() =>
        <div className='flex-row-item' style={{ width: '60px' }}>
            <InputField
                width='60px'
                maxLength={999}
                label={''}
                type="number"
                value={tdrData.dischargeEmptySize40 ?? ''}
                disabled={false}
                onChange={(e) => vslSummaryVM.onInputTextChange(e, 'dischargeEmptySize40')}
            />
        </div>
        , [tdrData.dischargeEmptySize40, vslSummaryVM])


    const memoDsEmpty45 = useMemo(() =>
        <div className='flex-row-item' style={{ width: '60px' }}>
            <InputField
                width='60px'
                maxLength={999}
                label={''}
                type="number"
                value={tdrData.dischargeEmptySize45 ?? ''}
                disabled={false}
                onChange={(e) => vslSummaryVM.onInputTextChange(e, 'dischargeEmptySize45')}
            />
        </div>
        , [tdrData.dischargeEmptySize45, vslSummaryVM])


    const memoDsEmpty48 = useMemo(() =>
        <div className='flex-row-item' style={{ width: '60px' }}>
            <InputField
                width='60px'
                maxLength={999}
                label={''}
                type="number"
                value={tdrData.dischargeEmptySize48 ?? ''}
                disabled={false}
                onChange={(e) => vslSummaryVM.onInputTextChange(e, 'dischargeEmptySize48')}
            />
        </div>
        , [tdrData.dischargeEmptySize48, vslSummaryVM])


    const memoDsEmpty53 = useMemo(() =>
        <div className='flex-row-item' style={{ width: '60px' }}>
            <InputField
                width='60px'
                maxLength={999}
                label={''}
                type="number"
                value={tdrData.dischargeEmptySize53 ?? ''}
                disabled={false}
                onChange={(e) => vslSummaryVM.onInputTextChange(e, 'dischargeEmptySize53')}
            />
        </div>
        , [tdrData.dischargeEmptySize53, vslSummaryVM])


    // const memoDsEmptyTotal = useMemo(() =>
    //     <div className='flex-row-item' style={{width: '60px'}}>
    //         <InputField 
    //             width='60px'
    //             maxLength={999}
    //             label={''}
    //             type="number"
    //             value={dsEmptyTotal} 
    //             disabled={true}
    //             />
    //     </div>
    // , [dsEmptyTotal])


    // const memoDsEmptyInNbis = useMemo(() =>
    //     <div className='flex-row-item' style={{width: '60px'}}>
    //         <InputField 
    //             width='60px'
    //             maxLength={999}
    //             label={''}
    //             type="number"
    //             value={dsEmptyInNbis??0} 
    //             disabled={true}
    //             />
    //     </div>
    // , [dsEmptyInNbis])


    // const memoDsEmptyDiff = useMemo(() =>
    //     <div className='flex-row-item' style={{width: '60px'}}>
    //         <InputField 
    //             width='60px'
    //             maxLength={999}
    //             label={''}
    //             type="number"
    //             value={dsEmptyDiff} 
    //             disabled={true}
    //             />
    //     </div>
    // , [dsEmptyDiff])

    const memoDsEmptyTotal = useMemo(() =>
        <div className='flex-row-item' style={{ width: "60px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={dsEmptyTotal.toString()}
                fieldValue={dsEmptyTotal}
                fieldLabel={""}
                isSaveClicked={true}
                fieldType={FieldType.TEXT}
                fieldKey={''}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={() => { }} />
        </div>
        , [dsEmptyTotal])

    const memoDsEmptyInNbis = useMemo(() =>
        <div className='flex-row-item' style={{ width: "60px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={dsEmptyInNbis?.toString()}
                fieldValue={dsEmptyInNbis ?? 0}
                fieldLabel={""}
                isSaveClicked={true}
                fieldType={FieldType.TEXT}
                fieldKey={''}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={() => { }} />
        </div>
        , [dsEmptyInNbis])

    const memoDsEmptyDiff = useMemo(() =>
        <div className='flex-row-item' style={{ width: "60px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={dsEmptyDiff.toString()}
                fieldValue={dsEmptyDiff.toString()}
                fieldLabel={""}
                isSaveClicked={true}
                fieldType={FieldType.TEXT}
                fieldKey={''}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={() => { }} />
        </div>
        , [dsEmptyDiff])

    // Loading Full Row
    const memoLdFull20 = useMemo(() =>
        <div className='flex-row-item' style={{ width: '60px' }}>
            <InputField
                width='60px'
                maxLength={999}
                label={''}
                type="number"
                value={tdrData.loadingFullSize20 ?? ''}
                disabled={false}
                onChange={(e) => vslSummaryVM.onInputTextChange(e, 'loadingFullSize20')}
            />
        </div>
        , [tdrData.loadingFullSize20, vslSummaryVM])


    const memoLdFull40 = useMemo(() =>
        <div className='flex-row-item' style={{ width: '60px' }}>
            <InputField
                width='60px'
                maxLength={999}
                label={''}
                type="number"
                value={tdrData.loadingFullSize40 ?? ''}
                disabled={false}
                onChange={(e) => vslSummaryVM.onInputTextChange(e, 'loadingFullSize40')}
            />
        </div>
        , [tdrData.loadingFullSize40, vslSummaryVM])


    const memoLdFull45 = useMemo(() =>
        <div className='flex-row-item' style={{ width: '60px' }}>
            <InputField
                width='60px'
                maxLength={999}
                label={''}
                type="number"
                value={tdrData.loadingFullSize45 ?? ''}
                disabled={false}
                onChange={(e) => vslSummaryVM.onInputTextChange(e, 'loadingFullSize45')}
            />
        </div>
        , [tdrData.loadingFullSize45, vslSummaryVM])


    const memoLdFull48 = useMemo(() =>
        <div className='flex-row-item' style={{ width: '60px' }}>
            <InputField
                width='60px'
                maxLength={999}
                label={''}
                type="number"
                value={tdrData.loadingFullSize48 ?? ''}
                disabled={false}
                onChange={(e) => vslSummaryVM.onInputTextChange(e, 'loadingFullSize48')}
            />
        </div>
        , [tdrData.loadingFullSize48, vslSummaryVM])


    const memoLdFull53 = useMemo(() =>
        <div className='flex-row-item' style={{ width: '60px' }}>
            <InputField
                width='60px'
                maxLength={999}
                label={''}
                type="number"
                value={tdrData.loadingFullSize53 ?? ''}
                disabled={false}
                onChange={(e) => vslSummaryVM.onInputTextChange(e, 'loadingFullSize53')}
            />
        </div>
        , [tdrData.loadingFullSize53, vslSummaryVM])


    // const memoLdFullTotal = useMemo(() =>
    //     <div className='flex-row-item' style={{width: '60px'}}>
    //         <InputField 
    //             width='60px'
    //             maxLength={999}
    //             label={''}
    //             type="number"
    //             value={ldFullTotal} 
    //             disabled={true}
    //             />
    //     </div>
    // , [ldFullTotal])


    // const memoLdFullInNbis = useMemo(() =>
    //     <div className='flex-row-item' style={{width: '60px'}}>
    //         <InputField 
    //             width='60px'
    //             maxLength={999}
    //             label={''}
    //             type="number"
    //             value={ldFullInNbis??0} 
    //             disabled={true}
    //             />
    //     </div>
    // , [ldFullInNbis])


    // const memoLdFullDiff = useMemo(() =>
    //     <div className='flex-row-item' style={{width: '60px'}}>
    //         <InputField 
    //             width='60px'
    //             maxLength={999}
    //             label={''}
    //             type="number"
    //             value={ldFullDiff} 
    //             disabled={true}
    //             />
    //     </div>
    // , [ldFullDiff])

    const memoLdFullTotal = useMemo(() =>
        <div className='flex-row-item' style={{ width: "60px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={ldFullTotal.toString()}
                fieldValue={ldFullTotal}
                fieldLabel={""}
                isSaveClicked={true}
                fieldType={FieldType.TEXT}
                fieldKey={''}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={() => { }} />
        </div>
        , [ldFullTotal])

    const memoLdFullInNbis = useMemo(() =>
        <div className='flex-row-item' style={{ width: "60px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={ldFullInNbis?.toString()}
                fieldValue={ldFullInNbis ?? 0}
                fieldLabel={""}
                isSaveClicked={true}
                fieldType={FieldType.TEXT}
                fieldKey={''}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={() => { }} />
        </div>
        , [ldFullInNbis])

    const memoLdFullDiff = useMemo(() =>
        <div className='flex-row-item' style={{ width: "60px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={ldFullDiff.toString()}
                fieldValue={ldFullDiff.toString()}
                fieldLabel={""}
                isSaveClicked={true}
                fieldType={FieldType.TEXT}
                fieldKey={''}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={() => { }} />
        </div>
        , [ldFullDiff])

    // Loading Empty Row
    const memoLdEmpty20 = useMemo(() =>
        <div className='flex-row-item' style={{ width: '60px' }}>
            <InputField
                width='60px'
                maxLength={999}
                label={''}
                type="number"
                value={tdrData.loadingEmptySize20 ?? ''}
                disabled={false}
                onChange={(e) => vslSummaryVM.onInputTextChange(e, 'loadingEmptySize20')}
            />
        </div>
        , [tdrData.loadingEmptySize20, vslSummaryVM])


    const memoLdEmpty40 = useMemo(() =>
        <div className='flex-row-item' style={{ width: '60px' }}>
            <InputField
                width='60px'
                maxLength={999}
                label={''}
                type="number"
                value={tdrData.loadingEmptySize40 ?? ''}
                disabled={false}
                onChange={(e) => vslSummaryVM.onInputTextChange(e, 'loadingEmptySize40')}
            />
        </div>
        , [tdrData.loadingEmptySize40, vslSummaryVM])


    const memoLdEmpty45 = useMemo(() =>
        <div className='flex-row-item' style={{ width: '60px' }}>
            <InputField
                width='60px'
                maxLength={999}
                label={''}
                type="number"
                value={tdrData.loadingEmptySize45 ?? ''}
                disabled={false}
                onChange={(e) => vslSummaryVM.onInputTextChange(e, 'loadingEmptySize45')}
            />
        </div>
        , [tdrData.loadingEmptySize45, vslSummaryVM])


    const memoLdEmpty48 = useMemo(() =>
        <div className='flex-row-item' style={{ width: '60px' }}>
            <InputField
                width='60px'
                maxLength={999}
                label={''}
                type="number"
                value={tdrData.loadingEmptySize48 ?? ''}
                disabled={false}
                onChange={(e) => vslSummaryVM.onInputTextChange(e, 'loadingEmptySize48')}
            />
        </div>
        , [tdrData.loadingEmptySize48, vslSummaryVM])


    const memoLdEmpty53 = useMemo(() =>
        <div className='flex-row-item' style={{ width: '60px' }}>
            <InputField
                width='60px'
                maxLength={999}
                label={''}
                type="number"
                value={tdrData.loadingEmptySize53 ?? ''}
                disabled={false}
                onChange={(e) => vslSummaryVM.onInputTextChange(e, 'loadingEmptySize53')}
            />
        </div>
        , [tdrData.loadingEmptySize53, vslSummaryVM])


    // const memoLdEmptyTotal = useMemo(() =>
    //     <div className='flex-row-item' style={{width: '60px'}}>
    //         <InputField 
    //             width='60px'
    //             maxLength={999}
    //             label={''}
    //             type="number"
    //             value={ldEmptyTotal} 
    //             disabled={true}
    //             />
    //     </div>
    // , [ldEmptyTotal])


    // const memoLdEmptyInNbis = useMemo(() =>
    //     <div className='flex-row-item' style={{width: '60px'}}>
    //         <InputField 
    //             width='60px'
    //             maxLength={999}
    //             label={''}
    //             type="number"
    //             value={ldEmptyInNbis??0} 
    //             disabled={true}
    //             />
    //     </div>
    // , [ldEmptyInNbis])


    // const memoLdEmptyDiff = useMemo(() =>
    //     <div className='flex-row-item' style={{width: '60px'}}>
    //         <InputField 
    //             width='60px'
    //             maxLength={999}
    //             label={''}
    //             type="number"
    //             value={ldEmptyDiff} 
    //             disabled={true}
    //             />
    //     </div>
    // , [ldEmptyDiff])

    const memoLdEmptyTotal = useMemo(() =>
        <div className='flex-row-item' style={{ width: "60px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={ldEmptyTotal.toString()}
                fieldValue={ldEmptyTotal}
                fieldLabel={""}
                isSaveClicked={true}
                fieldType={FieldType.TEXT}
                fieldKey={''}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={() => { }} />
        </div>
        , [ldEmptyTotal])

    const memoLdEmptyInNbis = useMemo(() =>
        <div className='flex-row-item' style={{ width: "60px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={ldEmptyInNbis?.toString()}
                fieldValue={ldEmptyInNbis ?? 0}
                fieldLabel={""}
                isSaveClicked={true}
                fieldType={FieldType.TEXT}
                fieldKey={''}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={() => { }} />
        </div>
        , [ldEmptyInNbis])

    const memoLdEmptyDiff = useMemo(() =>
        <div className='flex-row-item' style={{ width: "60px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={ldEmptyDiff.toString()}
                fieldValue={ldEmptyDiff.toString()}
                fieldLabel={""}
                isSaveClicked={true}
                fieldType={FieldType.TEXT}
                fieldKey={''}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={() => { }} />
        </div>
        , [ldEmptyDiff])


    // Restow Row
    const memoRs20 = useMemo(() =>
        <div className='flex-row-item' style={{ width: '60px' }}>
            <InputField
                width='60px'
                maxLength={999}
                label={''}
                type="number"
                value={tdrData.restowSize20 ?? ''}
                disabled={false}
                onChange={(e) => vslSummaryVM.onInputTextChange(e, 'restowSize20')}
            />
        </div>
        , [tdrData.restowSize20, vslSummaryVM])


    const memoRs40 = useMemo(() =>
        <div className='flex-row-item' style={{ width: '60px' }}>
            <InputField
                width='60px'
                maxLength={999}
                label={''}
                type="number"
                value={tdrData.restowSize40 ?? ''}
                disabled={false}
                onChange={(e) => vslSummaryVM.onInputTextChange(e, 'restowSize40')}
            />
        </div>
        , [tdrData.restowSize40, vslSummaryVM])


    const memoRs45 = useMemo(() =>
        <div className='flex-row-item' style={{ width: '60px' }}>
            <InputField
                width='60px'
                maxLength={999}
                label={''}
                type="number"
                value={tdrData.restowSize45 ?? ''}
                disabled={false}
                onChange={(e) => vslSummaryVM.onInputTextChange(e, 'restowSize45')}
            />
        </div>
        , [tdrData.restowSize45, vslSummaryVM])


    const memoRs48 = useMemo(() =>
        <div className='flex-row-item' style={{ width: '60px' }}>
            <InputField
                width='60px'
                maxLength={999}
                label={''}
                type="number"
                value={tdrData.restowSize48 ?? ''}
                disabled={false}
                onChange={(e) => vslSummaryVM.onInputTextChange(e, 'restowSize48')}
            />
        </div>
        , [tdrData.restowSize48, vslSummaryVM])


    const memoRs53 = useMemo(() =>
        <div className='flex-row-item' style={{ width: '60px' }}>
            <InputField
                width='60px'
                maxLength={999}
                label={''}
                type="number"
                value={tdrData.restowSize53 ?? ''}
                disabled={false}
                onChange={(e) => vslSummaryVM.onInputTextChange(e, 'restowSize53')}
            />
        </div>
        , [tdrData.restowSize53, vslSummaryVM])


    // const memoRsTotal = useMemo(() =>
    //     <div className='flex-row-item' style={{width: '60px'}}>
    //         <InputField 
    //             width='60px'
    //             maxLength={999}
    //             label={''}
    //             type="number"
    //             value={rsTotal} 
    //             disabled={true}
    //             />
    //     </div>
    // , [rsTotal])


    // const memoRsInNbis = useMemo(() =>
    //     <div className='flex-row-item' style={{width: '60px'}}>
    //         <InputField 
    //             width='60px'
    //             maxLength={999}
    //             label={''}
    //             type="number"
    //             value={restowInNbis??0} 
    //             disabled={true}
    //             />
    //     </div>
    // , [restowInNbis])


    // const memoRsDiff = useMemo(() =>
    //     <div className='flex-row-item' style={{width: '60px'}}>
    //         <InputField 
    //             width='60px'
    //             maxLength={999}
    //             label={''}
    //             type="number"
    //             value={restowDiff} 
    //             disabled={true}
    //             />
    //     </div>
    // , [restowDiff])

    const memoRsTotal = useMemo(() =>
        <div className='flex-row-item' style={{ width: "60px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={rsTotal.toString()}
                fieldValue={rsTotal}
                fieldLabel={""}
                isSaveClicked={true}
                fieldType={FieldType.TEXT}
                fieldKey={''}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={() => { }} />
        </div>
        , [rsTotal])

    const memoRsInNbis = useMemo(() =>
        <div className='flex-row-item' style={{ width: "60px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={restowInNbis?.toString()}
                fieldValue={restowInNbis ?? 0}
                fieldLabel={""}
                isSaveClicked={true}
                fieldType={FieldType.TEXT}
                fieldKey={''}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={() => { }} />
        </div>
        , [restowInNbis])

    const memoRsDiff = useMemo(() =>
        <div className='flex-row-item' style={{ width: "60px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={restowDiff.toString()}
                fieldValue={restowDiff.toString()}
                fieldLabel={""}
                isSaveClicked={true}
                fieldType={FieldType.TEXT}
                fieldKey={''}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={() => { }} />
        </div>
        , [restowDiff])

    // Shift Row
    const memoShift20 = useMemo(() =>
        <div className='flex-row-item' style={{ width: '60px' }}>
            <InputField
                width='60px'
                maxLength={999}
                label={''}
                type="number"
                value={tdrData.shiftSize20 ?? ''}
                disabled={false}
                onChange={(e) => vslSummaryVM.onInputTextChange(e, 'shiftSize20')}
            />
        </div>
        , [tdrData.shiftSize20, vslSummaryVM])


    const memoShift40 = useMemo(() =>
        <div className='flex-row-item' style={{ width: '60px' }}>
            <InputField
                width='60px'
                maxLength={999}
                label={''}
                type="number"
                value={tdrData.shiftSize40 ?? ''}
                disabled={false}
                onChange={(e) => vslSummaryVM.onInputTextChange(e, 'shiftSize40')}
            />
        </div>
        , [tdrData.shiftSize40, vslSummaryVM])


    const memoShift45 = useMemo(() =>
        <div className='flex-row-item' style={{ width: '60px' }}>
            <InputField
                width='60px'
                maxLength={999}
                label={''}
                type="number"
                value={tdrData.shiftSize45 ?? ''}
                disabled={false}
                onChange={(e) => vslSummaryVM.onInputTextChange(e, 'shiftSize45')}
            />
        </div>
        , [tdrData.shiftSize45, vslSummaryVM])


    const memoShift48 = useMemo(() =>
        <div className='flex-row-item' style={{ width: '60px' }}>
            <InputField
                width='60px'
                maxLength={999}
                label={''}
                type="number"
                value={tdrData.shiftSize48 ?? ''}
                disabled={false}
                onChange={(e) => vslSummaryVM.onInputTextChange(e, 'shiftSize48')}
            />
        </div>
        , [tdrData.shiftSize48, vslSummaryVM])


    const memoShift53 = useMemo(() =>
        <div className='flex-row-item' style={{ width: '60px' }}>
            <InputField
                width='60px'
                maxLength={999}
                label={''}
                type="number"
                value={tdrData.shiftSize53 ?? ''}
                disabled={false}
                onChange={(e) => vslSummaryVM.onInputTextChange(e, 'shiftSize53')}
            />
        </div>
        , [tdrData.shiftSize53, vslSummaryVM])


    // const memoShiftTotal = useMemo(() =>
    //     <div className='flex-row-item' style={{width: '60px'}}>
    //         <InputField 
    //             width='60px'
    //             maxLength={999}
    //             label={''}
    //             type="number"
    //             value={shiftTotal} 
    //             disabled={true}
    //             />
    //     </div>
    // , [shiftTotal])


    // const memoShiftInNbis = useMemo(() =>
    //     <div className='flex-row-item' style={{width: '60px'}}>
    //         <InputField 
    //             width='60px'
    //             maxLength={999}
    //             label={''}
    //             type="number"
    //             value={shiftInNbis??0} 
    //             disabled={true}
    //             />
    //     </div>
    // , [shiftInNbis])


    // const memoShiftDiff = useMemo(() =>
    //     <div className='flex-row-item' style={{width: '60px'}}>
    //         <InputField 
    //             width='60px'
    //             maxLength={999}
    //             label={''}
    //             type="number"
    //             value={shiftDiff} 
    //             disabled={true}
    //             />
    //     </div>
    // , [shiftDiff])


    const memoShiftTotal = useMemo(() =>
        <div className='flex-row-item' style={{ width: "60px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={shiftTotal.toString()}
                fieldValue={shiftTotal.toString()}
                fieldLabel={""}
                isSaveClicked={true}
                fieldType={FieldType.TEXT}
                fieldKey={''}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={() => { }} />
        </div>
        , [shiftTotal])

    const memoShiftInNbis = useMemo(() =>
        <div className='flex-row-item' style={{ width: "60px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={shiftInNbis?.toString()}
                fieldValue={shiftInNbis ?? 0}
                fieldLabel={""}
                isSaveClicked={true}
                fieldType={FieldType.TEXT}
                fieldKey={''}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={() => { }} />
        </div>
        , [shiftInNbis])

    const memoShiftDiff = useMemo(() =>
        <div className='flex-row-item' style={{ width: "60px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={shiftDiff.toString()}
                fieldValue={shiftDiff.toString()}
                fieldLabel={""}
                isSaveClicked={true}
                fieldType={FieldType.TEXT}
                fieldKey={''}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={() => { }} />
        </div>
        , [shiftDiff])


    // Remark
    const memoRemark = useMemo(() =>
        <div className='flex-row-item' style={{ width: '60px' }}>
            <HPHInputTextarea
                label={""}
                width="690px"
                rows={3}
                resizable={false}
                value={tdrData.remark ?? ''}
                onChange={(e) => { vslSummaryVM.onTextAreaChange(e, "remark") }} />
        </div>
        , [tdrData.remark, vslSummaryVM])

    const handleApply = useCallback(async (e: any) => {
        if (editingHdr && tdrData) {
            vslSummaryVM.onApply(editingHdr, tdrData).then(() => {
                messageBarVM.showSuccess('Save successful!');
            }).catch(() => {
                messageBarVM.showError('Save data failure!');
            });
        }
    }, [editingHdr, messageBarVM, tdrData, vslSummaryVM])

    return (
        <div className='side-form-content-wrapper'>
            <div className={'im-flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>{'TDR'}</SidebarTitle>
                    </Sidebarheader>
                </div>
            </div>

            {/* Discharge Full Row*/}
            <div className={'flex-row field-row-item'}>
                {/* {memoLabelBlank} */}
                <div className="TDRLabel" >{VSL_SUMMARY_CONSTANT.DS_FULL}</div>
                {memoDsFull20}
                {memoDsFull40}
                {memoDsFull45}
                {memoDsFull48}
                {memoDsFull53}
                {memoDsFullTotal}
                {memoDsFullInNbis}
                {memoDsFullDiff}
            </div>

            {/* Discharge Empty Row*/}
            <div className={'flex-row field-row-item'}>
                {/* {memoLabelDsFull} */}
                <div className="TDRLabel" >{VSL_SUMMARY_CONSTANT.DS_EMPTY}</div>
                {memoDsEmpty20}
                {memoDsEmpty40}
                {memoDsEmpty45}
                {memoDsEmpty48}
                {memoDsEmpty53}
                {memoDsEmptyTotal}
                {memoDsEmptyInNbis}
                {memoDsEmptyDiff}
            </div>

            {/* Loading Full Row*/}
            <div className={'flex-row field-row-item'}>
                <div className="TDRLabel" >{VSL_SUMMARY_CONSTANT.LD_FULL}</div>
                {memoLdFull20}
                {memoLdFull40}
                {memoLdFull45}
                {memoLdFull48}
                {memoLdFull53}
                {memoLdFullTotal}
                {memoLdFullInNbis}
                {memoLdFullDiff}
            </div>

            {/* Loading Empty Row*/}
            <div className={'flex-row field-row-item'}>
                <div className="TDRLabel" >{VSL_SUMMARY_CONSTANT.LD_EMPTY}</div>
                {memoLdEmpty20}
                {memoLdEmpty40}
                {memoLdEmpty45}
                {memoLdEmpty48}
                {memoLdEmpty53}
                {memoLdEmptyTotal}
                {memoLdEmptyInNbis}
                {memoLdEmptyDiff}
            </div>

            {/* Restow Row*/}
            <div className={'flex-row field-row-item'}>
                <div className="TDRLabel" >{VSL_SUMMARY_CONSTANT.RESTOW}</div>
                {memoRs20}
                {memoRs40}
                {memoRs45}
                {memoRs48}
                {memoRs53}
                {memoRsTotal}
                {memoRsInNbis}
                {memoRsDiff}
            </div>

            {/* Shift Row*/}
            <div className={'flex-row field-row-item'}>
                <div className="TDRLabel" >{VSL_SUMMARY_CONSTANT.SHIFT}</div>
                {memoShift20}
                {memoShift40}
                {memoShift45}
                {memoShift48}
                {memoShift53}
                {memoShiftTotal}
                {memoShiftInNbis}
                {memoShiftDiff}
            </div>

            {/* Remark Row */}
            <div className={'flex-row field-row-item'}>
                <div className="TDRLabel" >{VSL_SUMMARY_CONSTANT.REMARK}</div>
                {memoRemark}
            </div>

            <div className='im-charge-data-search-criteria-buttons'>
                {
                    <>
                        <HPHButton label={'Close'} size={'Small'} theme={'Secondary'} onClick={vslSummaryVM.onClose} />
                        <HPHButton label={'Apply'} size={'Small'} theme={'Primary'} onClick={handleApply} />
                    </>
                }
            </div>
        </div>
    )
}