import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { ChargeDataSearchProvider } from "presentation/store/ChargeDataEnquiry/ChargeDataSearchProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import { ChargeDataSearchHeader } from "presentation/view/section/ChargeDataEnquiry/ChargeDataSearchHeader";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

export const ChargeDataEnquiryHeader = () => {
    return <ANAInfoWrapper permission={Permission.LOGIN}>
        <MessageBarWrapper>
            <ChargeDataSearchProvider>
                <GridStyles />
                <ChargeDataSearchHeader />
            </ChargeDataSearchProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>;
};