import type { CustomCellRendererProps } from "ag-grid-react";
import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { TariffComponentMaintenanceConstant } from "./TariffComponentMaintenanceConstant";
import { INTEGER } from "presentation/utils/numberUtil";

const SCREEN_CONSTANT = TariffComponentMaintenanceConstant;
let numberFieldList: string[] = [];
export const INITIAL_TARIFF_COMPONENT_COL_DEF: any[] = [
    {
        headerName: SCREEN_CONSTANT.SEQ,
        field: "seq",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 100,
        pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        dataType: INTEGER,
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: SCREEN_CONSTANT.TARIFF_COMPONENT,
        field: "tariffCompCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,

    },
    {
        headerName: SCREEN_CONSTANT.TARIFF_COMPONENT_DESC,
        field: "tariffCompDesc",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 250,
    },
    {
        headerName: SCREEN_CONSTANT.ACTIVE_IND,
        field: "activeInd",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
    },
].map((col, index) => {
    const cellRenderers: { [key: string]: ((params: CustomCellRendererProps) => {}) } = {};

    return defineColumn(col, index, [], [], [], cellRenderers, numberFieldList);
});

export const transferRowData = (data: any[]) => {
    const externalFnctions: { [key: string]: ((fieldName: string, row: any) => {}) } = {};

    return transferRowDataInternal(data, [], [], [], externalFnctions, numberFieldList);
}
