import { type SystemPreferenceEntity } from "domain/entity/SystemPreference/SystemPreferenceEntity";
import { useSystemPreferenceVM } from "presentation/hook/SystemPreference/useSystemPreferenceVM";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useSystemPreferenceTracked } from "presentation/store/SystemPreference/SystemPreferenceProvider";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { useState } from "react";
import { DialogModal, FieldType, HPHButton, HPHCheckbox, IconButton, InputField } from "veronica-ui-component/dist/component/core";

export interface IPreferenceModal {
    gridRef: any;
    tableId: string;
    entryPoint: string;
    visible: boolean;
    autoMarginLeft?: boolean;
    showSaveModal?: () => void;
    showDeleteModal?: () => void;
}

export const SaveNewPreferenceModal: React.FC<IPreferenceModal> = (props: IPreferenceModal) => {
    const { visible } = props;
    const [preferenceName, setPreferenceName] = useState<string>('');
    const [isGlobal, setIsGlobal] = useState<boolean>(false);
    const systemPreferenceVM = useSystemPreferenceVM();
    const [anaInfoState] = useANAInfoTracked();
    const [systemPreferenceState] = useSystemPreferenceTracked();

    const onConfirmClicked = async () => {
        const preference: SystemPreferenceEntity = {
            category: isGlobal ? 'GLOBAL' : 'USER',
            content: JSON.stringify({
                columnState: props.gridRef?.current?.api?.getColumnState(),
            }),
            contentForScrene: {
                columnState: props.gridRef?.current?.api?.getColumnState(),
            },
            effectiveInd: 'Y',
            key: `${props.entryPoint}/${props.tableId}`,
            name: preferenceName,
            type: 'Table',
            userId: anaInfoState.userName,
            updatedUser: anaInfoState.userName,
        }

        let updateSystemPreference: SystemPreferenceEntity | undefined = systemPreferenceState.activeSystemPreference ? {
            ...systemPreferenceState.activeSystemPreference,
            effectiveInd: 'N',
            updatedUser: anaInfoState.userName,
        } : undefined;


        await Promise.allSettled([
            systemPreferenceVM.onCreateSystemPreference(preference, true),
            updateSystemPreference && systemPreferenceVM.onCreateSystemPreference(updateSystemPreference, false),
        ]).finally(() => {
            systemPreferenceVM.getTableSystemPreferences(props.entryPoint, props.tableId, anaInfoState.userName);
        });

        if (props.showSaveModal) {
            props.showSaveModal();
        }
        setPreferenceName('');
        setIsGlobal(false);
    }

    return <DialogModal
        appendTo='self'
        showButton={false}
        visible={visible}
        positions="center"
        resizable={false}
        header={
            <div className="im-charge-data-search-confirm-modal-header">
                <span className="name">NEW PREFERENCE</span>
                <IconButton fileName='Icon-cross' size="medium" onClick={() => props.showSaveModal && props.showSaveModal()} tooltipDisable={true} />
            </div>
        }
        dialogContent={
            <div className="im-charge-data-search-confirm-modal-content" style={{ marginLeft: `${props.autoMarginLeft ? "auto" : "-25px"}` }}>
                <div className='im-flex-row'>
                    <div className='im-flex-row-item'>
                        <InputField maxLength={999} width={"330px"} label="Name" type="text" value={preferenceName} onChange={(e) => setPreferenceName(e.target.value)} />
                    </div>
                </div>
                <div className='im-flex-row' style={{ marginTop: "10px" }}>
                    <div className="im-flex-row-item">
                        <NbisCommonField
                            fieldKey={"toBill"}
                            requiredFieldList={[]}
                            fieldLabel={'Global'}
                            fieldType={FieldType.TEXT}
                            isReadOnly={true}
                            onFieldChange={() => { }} />
                        <HPHCheckbox label="" checked={isGlobal} onChange={(e) => setIsGlobal(e.checked)} />
                    </div>
                </div>
            </div>
        }
        className="im-charge-data-search-confirm-modal"
        footer={
            <div className="im-charge-data-search-confirm-modal-footer">
                <HPHButton label={'Confirm'} size={'Small'} theme={'Primary'} onClick={onConfirmClicked} />
            </div>
        }
    />;
};

