import { axiosPostData } from "../axios/AxiosBasicImpl";
import recalAxiosInstance from "../axios/recalAxiosInstance";
import { RecalProcessAndGenSearchCriteria } from "domain/entity/Recalculation/RecalProcessAndGenSearchCriteria";
import { RecalProcessAndGenRepository } from "./RecalProcessAndGenRepo";

export const RecalProcessAndGenRepoImpl = ():RecalProcessAndGenRepository => {
    const url = '/v1/recalculation'

    const recalculationSubmit = async(searchCriteria: RecalProcessAndGenSearchCriteria) : Promise<any> => {
        const newUrl = url + '/submit'
        return axiosPostData(recalAxiosInstance, `${newUrl}`, searchCriteria).then(res => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "Failed to recalculation submit."
                if (res.data && typeof (res.data) === "string") {
                    errorMessage = res.data;
                }
                return "Error:" + errorMessage; 
            }
        }).catch(err => {
            return "Error:" + err.Message;
        });
    }

    return{        
        recalculationSubmit: recalculationSubmit,        
    }
}