import { EMPTY_MONTH_END_CLOSURE_ENTITY, MonthEndClosureEntity } from "domain/entity/MonthEndClosure/MonthEndClosureEntity";
import { EMPTY_RESERVATION_ITEM_ENTITY, ReservationItemEntity } from "domain/entity/MonthEndClosure/ReservationItemEntity";
import { CompanyRepository } from "domain/repository/Company/CompanyRepo";
import { MonthEndClosureRepository } from "domain/repository/MonthEndClosure/MonthEndClosureRepo";
import { StandardTariffCodeRepository } from "domain/repository/TariffCode/StandardTariffCodeRepo";
import { TariffTypeRepository } from "domain/repository/TariffCode/TariffTypeRepo";
import _ from "lodash";
import { DropdownProps } from "presentation/model/DropdownProps";
import { MonthEndClosureModel } from "presentation/model/MonthEndClosure/MonthEndClosureModel";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";

interface MonthEndClosureVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<MonthEndClosureModel>> | ((value: SetStateAction<MonthEndClosureModel>) => void),
    ],
    monthEndClosureRepo: MonthEndClosureRepository,
    standardTariffCodeRepo: StandardTariffCodeRepository,
    tariffTypeRepo: TariffTypeRepository,
    companyRepo: CompanyRepository,
}

export const MonthEndClosureVM = ({ dispatch, monthEndClosureRepo, standardTariffCodeRepo, tariffTypeRepo, companyRepo }: MonthEndClosureVMProps) => {
    const [monthEndClosureDispatch] = dispatch;

    const loadDropdownOption = async () => {

        await companyRepo.getAllCompanyForCombobox().then(
            companies => {
                let companyCodeDropdownOption = companies?.map((company) => ({
                    dropdownLabel: company.companyCode,
                    tagLabel: company.companyCode,
                    value: company.companyCode,
                })) ?? []
                companyCodeDropdownOption = _.orderBy(companyCodeDropdownOption, "dropdownLabel");

                monthEndClosureDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        companyCodeDropdownOptions: companyCodeDropdownOption,
                    }
                }))
            }
        )

        await tariffTypeRepo.getAllActiveTariffTypes().then(
            tariffs => {
                let newTariffs = _.orderBy(tariffs, ["tariffType"]);
                let tariffTypeDropdownOption = newTariffs?.map((tariff) => ({
                    dropdownLabel: tariff.tariffType,
                    tagLabel: tariff.tariffType,
                    value: tariff.tariffType,
                })) ?? []
                monthEndClosureDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        tariffTypeDropdownOptions: tariffTypeDropdownOption,
                    }
                }))
            }
        );

        await standardTariffCodeRepo.getAllStandardTariffCodes().then(
            tariffs => {

                let newTariffs = _.orderBy(tariffs, ["tariffType", "tariffCode"]);
                let tariffCodeDropdownOptions: { [key: string]: DropdownProps[] } = {};

                newTariffs.forEach(tariff => {

                    if (tariff.tariffCode) {
                        if (!tariffCodeDropdownOptions[tariff.tariffType]) {
                            tariffCodeDropdownOptions[tariff.tariffType] = [];
                        }
                        tariffCodeDropdownOptions[tariff.tariffType].push({
                            dropdownLabel: tariff.tariffCode,
                            tagLabel: tariff.tariffCode,
                            value: tariff.tariffCode,
                        })

                    }
                })

                monthEndClosureDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        tariffCodeDropdownOptions: tariffCodeDropdownOptions,
                    }
                }))
            }
        )
    }

    const initMonthEndClosureList = async () => {
        monthEndClosureDispatch(prevState => {
            return {
                ...prevState,
                currentSelectedRow: { ...EMPTY_MONTH_END_CLOSURE_ENTITY },
                selectedRows: [],
                monthEndClosureList: [],
            }
        })
        await monthEndClosureRepo.getMonthEndClosureEntities().then(data => {
            if (data) {
                monthEndClosureDispatch(prevState => {
                    return {
                        ...prevState,
                        monthEndClosureList: data,
                    }
                })
            } else {
                monthEndClosureDispatch(prevState => {
                    return {
                        ...prevState,
                        monthEndClosureList: [],
                    }
                })
            }
        })
    }

    const updateSelectedRows = async (rows: MonthEndClosureEntity[]) => {
        monthEndClosureDispatch(prevState => {
            return {
                ...prevState,
                selectedRows: rows,
                currentSelectedRow: rows.length > 0 ? rows[0] : { ...EMPTY_MONTH_END_CLOSURE_ENTITY },
                forceRefresh: !prevState.forceRefresh
            }
        })
    }

    const updateReservationSelectedRows = async (rows: ReservationItemEntity[]) => {
        monthEndClosureDispatch(prevState => {
            return {
                ...prevState,
                selectedReservationRows: rows,
            }
        })
    }

    const onRowDoubleClick = async (row: MonthEndClosureEntity) => {
        monthEndClosureDispatch(prevState => {
            return {
                ...prevState,
                currentSelectedRow: row,
                isShowUpdatePanel: true,
            }
        })
    }

    const onReservationAddClick = async (hdrId: number) => {
        let newEty = { ...EMPTY_RESERVATION_ITEM_ENTITY };
        newEty = {
            ...newEty,
            tempKey: _.uniqueId(),
            monthEndHdrId: hdrId,
        }
        monthEndClosureDispatch(prevState => {
            return {
                ...prevState,
                currentReservationEditRow: newEty,
                isAddReservation: true,
            }
        })
    }

    const onReservationRowDoubleClick = async (row: ReservationItemEntity) => {
        monthEndClosureDispatch(prevState => {
            return {
                ...prevState,
                currentReservationEditRow: row,
                currentReservationSelectedRow: row,
                isEditReservation: true,
            }
        })
    }

    const onHeaderFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;

        monthEndClosureDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: {
                    ...prevState.currentEditRow,
                    [fieldKey]: val,
                },
            }
        })
    }

    const onDtlFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (fieldKey === 'tariffType') {
            monthEndClosureDispatch(prevState => {
                return {
                    ...prevState,
                    currentReservationEditRow: {
                        ...prevState.currentReservationEditRow,
                        tariffCode: val === prevState.currentReservationEditRow.tariffType ? prevState.currentReservationEditRow.tariffCode : ''
                    },
                }
            })
        }
        monthEndClosureDispatch(prevState => {
            return {
                ...prevState,
                currentReservationEditRow: {
                    ...prevState.currentReservationEditRow,
                    [fieldKey]: val,
                },
            }
        })
    }

    const onCheckboxChange = (checked: boolean, fieldName: string) => {
        monthEndClosureDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: {
                    ...prevState.currentEditRow,
                    [fieldName]: checked ? "Y" : "N",
                },
            }
        })
    }

    const onDtlCheckboxChange = (checked: boolean, fieldName: string) => {
        monthEndClosureDispatch(prevState => {
            return {
                ...prevState,
                currentReservationEditRow: {
                    ...prevState.currentReservationEditRow,
                    [fieldName]: checked ? "Y" : "N",
                },
            }
        })
    }

    const onCloseClick = async () => {
        monthEndClosureDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: { ...EMPTY_MONTH_END_CLOSURE_ENTITY },
                isShowUpdatePanel: false,
                isEdit: false,
            }
        })
    }
    const onCloseDtlClick = async () => {
        monthEndClosureDispatch(prevState => {
            return {
                ...prevState,
                reservationItemList: [],
                selectedRows: [],
                isShowDtlPanel: false,
                isEditReservation: false,
            }
        })
    }

    const onReservationEditPanelCloseClick = async () => {
        monthEndClosureDispatch(prevState => {
            return {
                ...prevState,
                currentReservationEditRow: { ...EMPTY_RESERVATION_ITEM_ENTITY },
                isAddReservation: false,
                isEditReservation: false,
            }
        })
    }

    const onReservationResetClick = async (isAdd: boolean) => {
        monthEndClosureDispatch(prevState => {
            return {
                ...prevState,
                currentReservationEditRow: isAdd ? { ...EMPTY_RESERVATION_ITEM_ENTITY } : prevState.currentReservationSelectedRow,
            }
        })
    }

    const onResetClick = async () => {
        monthEndClosureDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: prevState.currentSelectedRow,
            }
        })
    }

    const onEditClick = async () => {
        monthEndClosureDispatch(prevState => {
            return {
                ...prevState,
                isEdit: true,
                currentEditRow: prevState.currentSelectedRow
            }
        })
    }

    const onSave = async (row: MonthEndClosureEntity) => {
        return await monthEndClosureRepo.saveMonthEnd(row).then(data => {
            if (data && data.success) {
                monthEndClosureDispatch(prevState => {
                    return {
                        ...prevState,
                        isShowUpdatePanel: false,
                        isEdit: false,
                        currentEditRow: { ...EMPTY_MONTH_END_CLOSURE_ENTITY }
                    }
                })
                return "success";
            } else {
                return data.data ?? "Update Failed.";
            }
        });
    }

    const onSaveReservation = async (row: ReservationItemEntity) => {
        return await monthEndClosureRepo.saveMonthReservation(row).then(async data => {
            if (data && data.success) {
                monthEndClosureDispatch(prevState => {
                    return {
                        ...prevState,
                        reservationItemList: [],
                        isAddReservation: false,
                        isEditReservation: false,
                        currentReservationEditRow: { ...EMPTY_RESERVATION_ITEM_ENTITY }
                    }
                })
                await monthEndClosureRepo.getMonthEndReservationItemList(row.monthEndHdrId).then(data => {
                    monthEndClosureDispatch(prevState => {
                        return {
                            ...prevState,
                            reservationItemList: data,

                        }
                    })
                })
                return "success";
            } else {
                return data.data ?? "Save Failed.";
            }
        });
    }

    const onActivityLogClick = async () => {
        return await monthEndClosureRepo.searchActivityLogForLastOstChgProcess().then(data => {
            monthEndClosureDispatch(prevState => {
                return {
                    ...prevState,
                    activityLogList: data,
                    isShowActivityLogPanel: true,
                }
            })
        })
    }

    const onReservationClick = async (row: MonthEndClosureEntity) => {
        await monthEndClosureRepo.getMonthEndReservationItemList(row.id).then(data => {
            monthEndClosureDispatch(prevState => {
                return {
                    ...prevState,
                    reservationItemList: data,
                    currentSelectedRow: row,
                    isShowDtlPanel: true,
                }
            })
        })
    }

    const onFinalizeMonthClick = async (row: MonthEndClosureEntity) => {
        return await monthEndClosureRepo.onFinalizeMonth(row);
    }

    const onShowLoading = () => {
        monthEndClosureDispatch(prevState => {
            return {
                ...prevState,
                isLoading: true,
            }
        })
    }

    const onHideLoading = () => {
        monthEndClosureDispatch(prevState => {
            return {
                ...prevState,
                isLoading: false,
            }
        })
    }

    const closeActivityLogPanel = () => {
        monthEndClosureDispatch(prevState => {
            return {
                ...prevState,
                isShowActivityLogPanel: false,
                activityLogList: []
            }
        })
    }

    const onMonthlyRevenueClick = async (rows: MonthEndClosureEntity[]) => {
        return await monthEndClosureRepo.getMonthlyRevenueReport(rows).then(data => {
            if (data && data.success) {
                const res = data.data;
                for (const key in res) {
                    if (res[key]) {
                        const tempData = res[key] as string;
                        let base64 = tempData;
                        let bstr = atob(base64);
                        let n = bstr.length;
                        let u8arr = new Uint8Array(n);
                        while (n--) {
                            u8arr[n] = bstr.charCodeAt(n)
                        }
                        const blob = new Blob([u8arr], { type: 'application/pdf' });
                        const url = URL.createObjectURL(blob);
                        window.open(url, '_blank');
                        URL.revokeObjectURL(url);
                    }
                }
                return true;
            } else {
                return false;
            }
        })
    }

    const onTurnoverCheckClick = async (rows: MonthEndClosureEntity[]) => {
        return await monthEndClosureRepo.getTurnoverCheckReport(rows).then(data => {
            if (data && data.success) {
                const res = data.data;
                for (const key in res) {
                    if (res[key]) {
                        const tempData = res[key] as string;
                        let base64 = tempData;
                        let bstr = atob(base64);
                        let n = bstr.length;
                        let u8arr = new Uint8Array(n);
                        while (n--) {
                            u8arr[n] = bstr.charCodeAt(n)
                        }
                        const blob = new Blob([u8arr], { type: 'application/pdf' });
                        const url = URL.createObjectURL(blob);
                        window.open(url, '_blank');
                        URL.revokeObjectURL(url);
                    }
                }
                return true;
            } else {
                return false;

            }
        })
    }

    const onStartMonthClick = async (row: MonthEndClosureEntity) => {
        return await monthEndClosureRepo.onStartMonth(row);
    }

    const onCloseMonthClick = async (row: MonthEndClosureEntity) => {
        return await monthEndClosureRepo.onCloseMonth(row);
    }


    return {
        onCloseMonthClick: onCloseMonthClick,
        onFinalizeMonthClick: onFinalizeMonthClick,
        onStartMonthClick: onStartMonthClick,
        onTurnoverCheckClick: onTurnoverCheckClick,
        onMonthlyRevenueClick: onMonthlyRevenueClick,
        closeActivityLogPanel: closeActivityLogPanel,
        onShowLoading: onShowLoading,
        onHideLoading: onHideLoading,
        onReservationClick: onReservationClick,
        onSaveReservation: onSaveReservation,
        onReservationAddClick: onReservationAddClick,
        onReservationResetClick: onReservationResetClick,
        onReservationEditPanelCloseClick: onReservationEditPanelCloseClick,
        onDtlFieldChange: onDtlFieldChange,
        onActivityLogClick: onActivityLogClick,
        updateReservationSelectedRows: updateReservationSelectedRows,
        onReservationRowDoubleClick: onReservationRowDoubleClick,
        onSave: onSave,
        onResetClick: onResetClick,
        onCloseClick: onCloseClick,
        onCloseDtlClick: onCloseDtlClick,
        onDtlCheckboxChange: onDtlCheckboxChange,
        onCheckboxChange: onCheckboxChange,
        onRowDoubleClick: onRowDoubleClick,
        updateSelectedRows: updateSelectedRows,
        onHeaderFieldChange: onHeaderFieldChange,
        loadDropdownOption: loadDropdownOption,
        initMonthEndClosureList: initMonthEndClosureList,
        onEditClick: onEditClick,
    }
}