import { CompanyConstant, companyRequiredFieldList } from "presentation/constant/Company/CompanyConstant";
import { ActiveIndDroOpts } from "presentation/constant/DropDownOptions/Common/ActiveIndDroOpts";
import { useCompanyMaintenanceVM } from "presentation/hook/Company/useCompanyMaintenanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";

import { useCompanyMaintenanceTracked } from "presentation/store/Company/CompanyMaintenanceProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DatePickerComponent from "presentation/view/components/DatePickerComponent";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { FieldType, HPHButton, IFieldValue, InputField, Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, SidebarTitle } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const CompanyFormPanel = () => {
    const [companyState] = useCompanyMaintenanceTracked();
    const companyMaintenanceVM = useCompanyMaintenanceVM();
    const messageBarVM = useMessageBarVM();
    const COMPANY_CONST = CompanyConstant.Header;
    const currentCompany = companyState.currentCompany;
    const [isLoading, setIsLoading] = useState(false);
    const { dynamicOptions } = companyState
    const { isAdd, isEditable, isRead, allFormState, isSaveClicked } = companyState.viewState

    useEffect(() => {
        if (companyState.viewState.isAdd) {
            companyMaintenanceVM.onInitDefaultValue();
        }
    }, [companyMaintenanceVM, companyState.viewState.isAdd]);

    const onFieldChange = useCallback((e: any, fieldName: string) => {
        const fieldValue = e.target.value;
        companyMaintenanceVM.onFieldChange(fieldName, fieldValue);
    }, [companyMaintenanceVM]);

    const handleCancelClick = useCallback(() => {
        companyMaintenanceVM.onCancel();
    }, [companyMaintenanceVM])

    const handleSaveClick = useCallback(() => {
        companyMaintenanceVM.onSave(currentCompany, isAdd).then((data) => {
            if (data && data["saveCompanyFailed"]) {
                setIsLoading(false);
                messageBarVM.showError(data["saveCompanyFailed"]?.toString());
                return;
            }

            companyMaintenanceVM.onSearch(companyState.searchCriteria).then(() => {
                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false);
            });
        }).catch(() => {
            setIsLoading(false);
            messageBarVM.showError('Save Data Failed.');
        });
    }, [companyMaintenanceVM, companyState.searchCriteria, currentCompany, isAdd, messageBarVM])

    const memoCompanyCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>
            <InputField
                width='180px'
                maxLength={4}
                label={COMPANY_CONST.COMPANY_CODE}
                disabled={!isAdd}
                type="text"
                value={currentCompany?.companyCode ?? ''}
                errorMessage={allFormState ? allFormState["companyCode"] : ""}
                onChange={(e) => onFieldChange(e, 'companyCode')} />
        </div>
        , [COMPANY_CONST.COMPANY_CODE, allFormState, currentCompany?.companyCode, isAdd, onFieldChange])

    const memoCompanyName = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>
            <InputField
                width='180px'
                maxLength={50}
                label={COMPANY_CONST.COMPANY_NAME}
                disabled={isRead}
                type="text"
                optional={true}
                value={currentCompany?.companyName ?? ''}
                onChange={(e) => onFieldChange(e, 'companyName')} />
        </div>
        , [COMPANY_CONST.COMPANY_NAME, currentCompany?.companyName, isRead, onFieldChange])

    const memoCompanyType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                disableInput={false}
                isSaveClicked={isSaveClicked}
                readOnlyValue={currentCompany?.companyType ?? ''}
                fieldValue={currentCompany?.companyType ?? ''}
                fieldLabel={COMPANY_CONST.COMPANY_TYPE}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'companyType'}
                options={dynamicOptions.companyTypeDropdownOptions}
                maxLength={60}
                requiredFieldList={companyRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue) =>
                    companyMaintenanceVM.onFieldChange(fieldKey, fieldValue)} />
        </div>
        , [COMPANY_CONST.COMPANY_TYPE, allFormState, companyMaintenanceVM, currentCompany?.companyType, dynamicOptions.companyTypeDropdownOptions, isRead, isSaveClicked])

    const memoMasterCompanyCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                isMultipleDropDown={false}
                disableInput={false}
                readOnlyValue={currentCompany?.masterCompany?.companyCode ?? ''
                    /*dynamicOptions.masterCompanyDropdownOptions.find(item => currentCompany.masterCompanyId === Number(item.tagLabel))?.value??""*/
                }
                fieldValue={/*currentCompany?.masterCompany?.companyCode??''*/
                    dynamicOptions.masterCompanyDropdownOptions.find(item => currentCompany.masterCompanyId === Number(item.tagLabel))?.value ?? ""
                }
                fieldLabel={COMPANY_CONST.MASTER_COMPANY}
                //isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'masterCompanyId'}
                options={dynamicOptions.masterCompanyDropdownOptions}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue) =>
                    companyMaintenanceVM.onDropdownKeyChange(fieldKey, fieldValue)} />
        </div>
        , [COMPANY_CONST.MASTER_COMPANY, allFormState, companyMaintenanceVM, currentCompany?.masterCompany?.companyCode, currentCompany.masterCompanyId, dynamicOptions.masterCompanyDropdownOptions, isRead])

    const memoAllianceCompanyCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                isMultipleDropDown={false}
                disableInput={false}
                readOnlyValue={currentCompany?.alliance?.companyCode ?? ''
                    /*dynamicOptions.allianceCompanyDropdownOptions.find(item => currentCompany.allianceCompanyId === Number(item.tagLabel))?.value??""*/
                }
                fieldValue={/*currentCompany?.alliance?.companyCode??''*/
                    dynamicOptions.allianceCompanyDropdownOptions.find(item => currentCompany.allianceCompanyId === Number(item.tagLabel))?.value ?? ""
                }
                fieldLabel={COMPANY_CONST.ALLIANCE}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'allianceCompanyId'}
                options={dynamicOptions.allianceCompanyDropdownOptions}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue) =>
                    companyMaintenanceVM.onDropdownKeyChange(fieldKey, fieldValue)} />
        </div>
        , [COMPANY_CONST.ALLIANCE, allFormState, companyMaintenanceVM, currentCompany?.alliance?.companyCode, currentCompany.allianceCompanyId, dynamicOptions.allianceCompanyDropdownOptions, isRead])

    const memoAddress1 = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>
            <InputField
                width='390px'
                maxLength={50}
                disabled={isRead}
                label={COMPANY_CONST.ADDRESS_1}
                type="text"
                optional={true}
                value={currentCompany?.address1 ?? ''}
                onChange={(e) => onFieldChange(e, 'address1')} />
        </div>
        , [COMPANY_CONST.ADDRESS_1, currentCompany?.address1, isRead, onFieldChange])

    const memoAddress2 = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>
            <InputField
                width='390px'
                maxLength={50}
                label={COMPANY_CONST.ADDRESS_2}
                disabled={isRead}
                type="text"
                optional={true}
                value={currentCompany?.address2 ?? ''}
                onChange={(e) => onFieldChange(e, 'address2')} />
        </div>
        , [COMPANY_CONST.ADDRESS_2, currentCompany?.address2, isRead, onFieldChange])

    const memoAddress3 = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>
            <InputField
                width='390px'
                maxLength={50}
                disabled={isRead}
                label={COMPANY_CONST.ADDRESS_3}
                type="text"
                optional={true}
                value={currentCompany?.address3 ?? ''}
                onChange={(e) => onFieldChange(e, 'address3')} />
        </div>
        , [COMPANY_CONST.ADDRESS_3, currentCompany?.address3, isRead, onFieldChange])

    const memoCountryCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={false}
                disableInput={false}
                isSaveClicked={isSaveClicked}
                readOnlyValue={/*currentCompany?.country?.countryCode??''*/
                    dynamicOptions.countryCodeDropdownOptions.find(item => currentCompany.countryCodeId === Number(item.tagLabel))?.value ?? ""
                }
                fieldValue={/*currentCompany?.country?.countryCode??''*/
                    dynamicOptions.countryCodeDropdownOptions.find(item => currentCompany.countryCodeId === Number(item.tagLabel))?.value ?? ""
                }
                fieldLabel={COMPANY_CONST.COUNTRY_CODE}
                //isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'countryCodeId'}
                options={dynamicOptions.countryCodeDropdownOptions}
                maxLength={60}
                requiredFieldList={companyRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue) =>
                    companyMaintenanceVM.onDropdownKeyChange(fieldKey, fieldValue)} />
        </div>
        , [COMPANY_CONST.COUNTRY_CODE, allFormState, companyMaintenanceVM, currentCompany.countryCodeId, dynamicOptions.countryCodeDropdownOptions, isRead, isSaveClicked])

    const memoSignOnDate = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>
            <DatePickerComponent
                disabled={isRead}
                label={COMPANY_CONST.SIGN_ON_DATE}
                width="180px"
                date={currentCompany?.signOnDate ?? null}
                fieldName="signOnDate"
                errorMessage={allFormState ? allFormState["signOnDate"] : ""}
                onDateChange={(fieldKey: string, fieldValue: IFieldValue) =>
                    companyMaintenanceVM.onFieldChange(fieldKey, fieldValue)/*(e) => onFieldChange(e, 'signOnDate')*/} />
        </div>
        , [COMPANY_CONST.SIGN_ON_DATE, allFormState, companyMaintenanceVM, currentCompany?.signOnDate, isRead])

    const memoActiveInd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={false}
                isMultipleDropDown={false}
                disableInput={true}
                isSaveClicked={isSaveClicked}
                readOnlyValue={currentCompany?.activeInd ?? ''}
                fieldValue={currentCompany?.activeInd ?? ''}
                fieldLabel={COMPANY_CONST.ACTIVE_IND}
                //isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'activeInd'}
                options={ActiveIndDroOpts().getDroOptsModel()}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue) =>
                    companyMaintenanceVM.onFieldChange(fieldKey, fieldValue)} />
        </div>
        , [COMPANY_CONST.ACTIVE_IND, allFormState, companyMaintenanceVM, currentCompany?.activeInd, isRead, isSaveClicked])

    return (
        <div className='side-form-content-wrapper'>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>{'Company Information'}</SidebarTitle>
                    </Sidebarheader>
                </div>
            </div>

            {isLoading && <Loader Indicator="Spinner" size="Medium" />}

            <div className={'add-edit-form'} style={{ height: '75vh', maxHeight: '75vh', overflow: 'auto' }}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        <CriteriaItemContainer>
                            {memoCompanyCode}
                            {memoCompanyName}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoMasterCompanyCode}
                            {memoAllianceCompanyCode}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoCompanyType}
                            {memoCountryCode}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoAddress1}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoAddress2}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoAddress3}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoSignOnDate}
                            {memoActiveInd}
                        </CriteriaItemContainer>

                    </div>
                </div>
            </div>

            <div className='im-charge-data-search-criteria-buttons'>
                {
                    <>
                        <HPHButton label={'Cancel'} size={'Small'} theme={'Secondary'} onClick={() => handleCancelClick()} />
                        {(isAdd || isEditable) && <HPHButton label={isAdd ? 'Add' : isEditable ? 'Update' : 'Save'} size={'Small'} theme={'Primary'} onClick={() => handleSaveClick()} />}
                    </>
                }
            </div>

        </div>
    )
}
export default memo(CompanyFormPanel);