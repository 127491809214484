import { TariffTypeMaintenanceConstant } from "presentation/constant/TariffType/TariffTypeMaintenanceConstant";
import { useTariffTypeMaintenanceVM } from "presentation/hook/TariffType/useTariffTypeMaintenanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useTariffTypeMaintenanceTracked } from "presentation/store/TariffType/TariffTypeMaintenanceProvider";
import { NbisIconButton } from "presentation/view/components/NbisIconButton";
import { memo, useCallback, useState } from "react";
import { HPHBreadcrumb, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const TariffTypeEditPanelTitleBar: React.FC = () => {
    const [tariffTypeState] = useTariffTypeMaintenanceTracked();
    const tariffTypeVM = useTariffTypeMaintenanceVM();
    const messageBarVM = useMessageBarVM();
    const { currentSelectedRow, masterState } = tariffTypeState;
    const { editingEntity, isAdd, isEditable, isRead } = masterState;

    const [isLoading, setIsLoading] = useState(false);
    const currentEntity = isRead ? currentSelectedRow : editingEntity;

    const handleReset = useCallback(async () => {
        tariffTypeVM.onReset();
    }, [tariffTypeVM]);

    const handleEdit = useCallback(() => {
        tariffTypeVM.onEditClick(currentEntity);
    }, [currentEntity, tariffTypeVM])

    const handleSave = useCallback(async () => {
        setIsLoading(true);
        try {
            tariffTypeVM.onSaveClicked();
            const res = await tariffTypeVM.onSave(currentEntity, isAdd);
            if (!!!res || !res.success) {
                messageBarVM.showError(res?.data ?? 'Save data failed.');
            } else {
                tariffTypeVM.onClose();
                setIsLoading(true);
            }
        } catch (error) {

        } finally {
            setIsLoading(false)
        }

    }, [currentEntity, isAdd, messageBarVM, tariffTypeVM]);

    return <Sidebarheader style={{ width: '100%' }}>
        {isLoading && <Loader Indicator="Spinner" size="Medium" />}
        <Breadcrumb>
            <HPHBreadcrumb breadcrumbData={[{ title: TariffTypeMaintenanceConstant.CATEGORY },
            { title: TariffTypeMaintenanceConstant.TITLE }]} onCurrentClick={() => { }}>
            </HPHBreadcrumb>
        </Breadcrumb>
        <StyledAction>
            {!(isAdd || isEditable) && <NbisIconButton fileName='Icon-pen' size='medium' toolTipText={'Edit'} toolTipArrow={false} onClick={handleEdit} disabled={false} />}
            {(isAdd || isEditable) && <IconButton fileName="Icon-reset" size="medium" toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Reset'} onClick={handleReset} />}
            {<IconButton fileName="Icon-cross" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="left" toolTipText={'Close'} onClick={tariffTypeVM.onClose} />}
            {(isAdd || isEditable) && <IconButton fileName="Icon-tick" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Save'} onClick={() => handleSave()} />}
        </StyledAction>
    </Sidebarheader>
}

export default memo(TariffTypeEditPanelTitleBar);