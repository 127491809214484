
import { EdiMonitoringEntity } from "domain/entity/EdiMonitoring/EdiMonitoringEntity";
import { EdiMonitoringSearchCriteria, EMPTY_EDI_MONITORING_SEARCH_CRITERIA } from "presentation/constant/EdiMonitoring/EdiMonitoringSearchCriteria";
import { DropdownProps } from "../DropdownProps";


export interface EdiMonitoringDropdownOptions {
    chargeTypeDropdownOptions: DropdownProps[],
    billToCompDropdownOptions: DropdownProps[],
    statusDropdownOptions: DropdownProps[],
    issuedByDropdownOptions: DropdownProps[],
    createdByDropdownOptions: DropdownProps[],
}

export interface EdiMonitoringModel {
    isShowCriteriaPanel: boolean,
    searchCriteria: EdiMonitoringSearchCriteria,
    dynamicOptions: EdiMonitoringDropdownOptions,
    ediMonitorings: EdiMonitoringEntity[],
    selectedEdiMonitoringRows: EdiMonitoringEntity[],
    forceRefresh: boolean,
}

export const EMPTY_EDI_MONITORING_MODEL: EdiMonitoringModel = {
    isShowCriteriaPanel: false,
    searchCriteria: EMPTY_EDI_MONITORING_SEARCH_CRITERIA,
    dynamicOptions: {
        chargeTypeDropdownOptions: [],
        billToCompDropdownOptions: [],
        statusDropdownOptions: [],
        issuedByDropdownOptions: [],
        createdByDropdownOptions: [],
    },
    ediMonitorings: [],
    selectedEdiMonitoringRows: [],
    forceRefresh: false
}