
import { useCompanyMaintenanceVM } from "presentation/hook/Company/useCompanyMaintenanceVM";
import { useCompanyMaintenanceTracked } from "presentation/store/Company/CompanyMaintenanceProvider";
import { memo, useCallback, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import CompanyContent from "./CompanyContent";
import CompanyTitleBar from "./CompanyTitleBar";
import CompanySearchPanel from "./LeftPanel/CompanySearchPanel";


const CompanyMaintenance: React.FC = () => {
    const [companyState] = useCompanyMaintenanceTracked();
    const { isShowCriteriaPanel, isBackFromDetail, searchCriteria, isAllowAutoSearch } = companyState;
    const companyMaintenanceVM = useCompanyMaintenanceVM();
    const [isLoading, setIsLoading] = useState(true);
    const [isInitialScreen, setIsInitialScreen] = useState<boolean>(false);

    const initialScreen = useCallback(async () => {
        setIsLoading(true);
        await Promise.allSettled([
            companyMaintenanceVM.loadDropdownOption(),
            isAllowAutoSearch && companyMaintenanceVM.onSearch(searchCriteria),
        ]).then(data => {
            setIsLoading(false);
            setIsInitialScreen(true);
        }).catch(e => {
            setIsLoading(false);
        })
    }, [companyMaintenanceVM, isAllowAutoSearch, searchCriteria]);

    useEffect(() => {
        if (isInitialScreen) return;

        initialScreen();
    })

    useEffect(() => {
        if (isBackFromDetail && isInitialScreen) {
            setIsInitialScreen(false);
        }
    }, [initialScreen, isBackFromDetail, isInitialScreen])


    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;
    return <>
        <div className={`main-comp-wrapper${isShowCriteriaPanel ? '' : ' im-hide-side-form-draggable'}`}>
            {<CompanyTitleBar />}

            {<SliderPanel
                isOpen={true}
                draggable={false}
                leftSectionWidth={isShowCriteriaPanel ? "25%" : "0%"}
                rightSectionWidth={isShowCriteriaPanel ? "75%" : "100%"}
                leftChildren={<CompanySearchPanel />}
                rightChildren={<CompanyContent />}
            />}
        </div>
    </>
}

export default memo(CompanyMaintenance);