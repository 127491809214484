import { useSupplementaryInvoiceDetailVM } from "presentation/hook/SupplementaryInvoice/useSupplementaryInvoiceDetailVM";
import { useSupplementaryInvoiceTracked } from "presentation/store/SupplementaryInvoice/SupplementaryInvoiceProvider";
import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import { SupplementaryInvoiceHeaderFieldPanel } from "./LeftPanel/SupplementaryInvoiceHeaderFieldPanel";
import SupplementaryInvoiceDetailTitleBar from "./SupplementaryInvoiceDetailTitleBar";
import SupplementaryInvoiceDetailTablePanel from "./Table/SupplementaryInvoiceDetailTablePanel";

const SupplementaryInvoiceDetailMaintenance: React.FC = () => {
    const [supplementaryInvoiceState] = useSupplementaryInvoiceTracked();
    const supplementaryInvoiceDetailVM = useSupplementaryInvoiceDetailVM();
    const { currentSuppInvoice } = supplementaryInvoiceState;
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const initialScreen = async () => {
            try {
                const results = await Promise.allSettled([
                    supplementaryInvoiceDetailVM.onPageInit(currentSuppInvoice),
                ]);
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {

                    } else if (index === 1 && result.status === 'rejected') {

                    }
                })
            } catch (error) {
                setIsLoading(false)
            }
        }
        initialScreen().then((data) => {
            setIsLoading(false)
        }).catch(error => {
            setIsLoading(false)
        });
    }, [currentSuppInvoice, supplementaryInvoiceDetailVM])


    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;

    return <>
        <div className={`main-comp-wrapper`}>
            <SupplementaryInvoiceDetailTitleBar />
            <SliderPanel
                isOpen={true}
                draggable={true}
                leftSectionWidth={"35%"}
                rightSectionWidth={"65%"}
                leftChildren={<SupplementaryInvoiceHeaderFieldPanel />}
                rightChildren={<div style={{ marginLeft: "15px" }}><SupplementaryInvoiceDetailTablePanel /></div>}
            />
        </div>
    </>
}
export default memo(SupplementaryInvoiceDetailMaintenance);